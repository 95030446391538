import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import { ApiMypageCommentHistory } from 'api/Mypage';
import { ApiCommentCommentDelete } from 'api/Comment';
import { ApiCommonWriterReject } from 'api/Common';
import useConfirmModal from 'component/common/modal/useConfirmModal';
import { actionSetShowGiftToast } from 'action';
import { SetState } from 'utils/State';

import GiftToast from 'component/common/toast/GiftToast';
import Header from 'component/common/header/Header';
import Footer from 'component/common/footer/Footer';

import SubNav from "component/common/nav/SubNav";

import style from './style/Mypage.module.scss';

import img_login_naver_icon from 'assets/images/user/login_naver_icon.svg';
import img_login_kakao_icon from 'assets/images/user/login_kakao_icon.svg';
import img_login_google_icon from 'assets/images/user/login_google_icon.svg';
import img_login_facebook_icon from 'assets/images/user/login_facebook_icon.svg';
import img_login_apple_icon from 'assets/images/user/login_apple_icon.svg';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import useAlertModal from 'component/common/modal/useAlertModal';
import useConfirmCustomModal from 'component/common/modal/useConfirmCustomModal';
import { useIsMobile } from 'utils/Common';


function MypageComment(){

    const isMobile = useIsMobile();

    const navigate = useBookpalNavigate();
    const dispatch = useDispatch();
    const contentRef = useRef(null);
    const setState = SetState();

    const [commentId, setCommentId] = useState(null);

    const [AlertModal, alert] = useAlertModal();
    const [ConfirmModal, confirm] = useConfirmCustomModal();
    
    // const handlerShowToast = (data)=>{
    //     const title = data?.title ?? null;
    //     const body = data?.body ?? null;
    //     const date = data?.date ?? "";
    //     dispatch(actionSetShowGiftToast({
    //         title:title,
    //         body:body,
    //         date:date,
    //     }));
    // }
    
    const [data, dataSubmit, dataInit, paging, setData] = ApiMypageCommentHistory({
        bottomRef:contentRef,
        onSuccess:(data)=>{
            console.log(data);
        },
        onError:(e)=>{
            console.log(e);
        }
    });


    const handlerRejectOrDeclare = ()=>{
        confirm({
            title:"신고/차단하기",
            successBtnTitle:"신고하기",
            successBtnTitle2:"차단하기",
            cancleBtnTitle:"취소",
        });
    }

    const rejectSubmit = ApiCommonWriterReject({
        onSuccess:(_data)=>{

        },
        onError:(e)=>{

        },
    })


    const deleteSubmit = ApiCommentCommentDelete({
        onSuccess:(_data)=>{
            console.log(_data);
            if(_data?.status === true){
                // handlerShowToast({
                //     title:"삭제되었습니다."
                // });

                setState.setToast({
                    title:"삭제되었습니다."
                });

                const n_data = [];
                data.map((item)=>{
                    if(item?.comment_id == commentId){
                        n_data.push({
                            ...item,
                            delete:true,
                        });
                    } else {
                        n_data.push(item);
                    }
                });
                setData(n_data);
            }
        },
        onError:(e)=>{
            console.log(e);
        }
    });

    const [Modal, showModal] = useConfirmModal({
        title:"삭제 안내",
        content:"삭제한 글은 복구할 수 없습니다.",
        btnTitle:"삭제하기",
        action:()=>{
            deleteSubmit({
                comment_id:commentId
            })
        }
    });

    const showModalWithSelectCommendId = (e)=>{
        const commendId = e.target.getAttribute('data-commend-id');
        setCommentId(commendId);
        showModal();
    }

    const handlerMoveReply = (id) => {
        console.log(id);
        navigate("/mypage/comment/"+id);
    };

    useEffect(()=>{
        dataSubmit();
    },[]);

    console.log(data);

    return (
        <>
            <Header useMobile={false} pcTop={"-64px"} />
            <SubNav 
                name={"댓글내역"+(paging?.total_cnt ? "("+paging?.total_cnt+")" : "")+""}
                usePc={true} 
                useMobile={true} 
                usePcRightBtns={true}
                useBtnPrev={true}
                useBtnPcPrev={true}
                useBtnHome={true}

                setTop={'0px'} 
                setPcTop={'0px'} 
                />
            {/* <!-- container --> */}
            <div id="container" ref={contentRef}>
                <section className={"section wrapper " + style['section']} >
                    {data != null && data?.length === 0 
                    ? <p className={"no_data color-txt-04 body-02-long " + (!isMobile ? "large":"")}>아직 등록된 댓글이 없습니다.<br />작품을 감상 후 댓글을 작성해보세요.</p>       
                    : <ul className={"list comment_list " + style['list'] + " " + style['comment_list']} >

                        {data && Array.isArray(data) ? data.map((item,index)=>{
                            if(item?.delete === true){
                                return (
                                    <li key={index}>
                                        <p className="delete_list color-txt-muted body-03-long">작성자가 글을 삭제하였습니다.</p>
                                    </li>
                                )
                            }
                            
                            return (
                                <li key={index}>
                                    <div className="comment_top">
                                        <div className="profile_box left">
                                            <span className="profile radius-full">
                                                <img src={item?.profile} />
                                            </span>
                                        </div>
                                        <div className={"right comment_box " +style['comment_box']}>
                                            <div className={"top_con " + style['top_con']} >
                                                <div className="badge_list">
                                                    {item?.badge?.best === true ? <p className="badge best_badge color-btn-01 bg border border-soild border-line radius-full">
                                                        <span className="white-always txt">Best</span>
                                                    </p> : null}
                                                    {item?.badge?.writer_choice === true ? <p className="badge love_badge color-state-point-01 bg border border-soild border-line radius-full">
                                                        <span className="white-always txt">작가감동♥︎</span>
                                                    </p> : null}
                                                    <p className="badge my_badge border-line border-soild border color-btn-01 radius-full">
                                                        <span className="color-btn-01 txt">MY</span>
                                                    </p>
                                                </div>
                                                <div className="name text_overflow title-01-b-long color-txt-normal">{item?.nickname}</div>
                                                <div className="date title-sub-02-m color-txt-muted">{item?.display_time}</div>
                                            </div>
                                            <div className="desc color-txt-02 body-03-long">
                                                {item?.content}
                                            </div>
                                            <div className="info">
                                                <span className="title body-01-long color-txt-05 text_overflow">{item?.book_title} {item?.chapter_title}</span>
                                                <span className="body-01-long color-txt-05">- {item?.chapter_no}화</span>
                                            </div>
                                            <div className="btns bottom_con">
                                                <div className="left">
                                                    {/* <!-- 좋아요버튼 클릭시 className="click" 추가--> */}
                                                    <button 
                                                        type="button" 
                                                        className={"button btn_03 medium outlined label_on round radius-full " + (item?.like_status === true?"click":"")} 
                                                        id="likeBtn"
                                                        onClick={()=>{
                                                            // alert("댓글내역","자기 댓글에는 좋아요를 하실 수 없습니다.");
                                                            setState.setToast({
                                                                title:"자신의 글에는 좋아요를 누를 수 없습니다."
                                                            });
                                                        }}
                                                        >
                                                        <span className="btn-03 color-txt-04 text">{item?.likes}</span>
                                                    </button>
                                                    {/* <!-- 답글 버튼 --> */}
                                                    <button 
                                                        type="button" 
                                                        onClick={()=>{handlerMoveReply(item?.comment_id)}} 
                                                        className={
                                                            item?.reply_status === true?
                                                            "button btn_01 medium outlined label_on round radius-full after"
                                                            : "button btn_03 medium outlined label_on round radius-full before"
                                                        }
                                                        id="replyBtn">
                                                        <span className="btn-03 color-txt-04 text">{item?.replies === 0 ? "답글달기" : item?.replies}</span>
                                                    </button>
                                                </div>
                                                <div className="right">
                                                    {item?.delete_flag === true ?
                                                        <button type="button" id="deleteBtn" data-commend-id={item?.comment_id} onClick={showModalWithSelectCommendId} >
                                                            <span className="body-01-long color-txt-05">삭제</span>
                                                        </button>
                                                    : 
                                                        <>
                                                            {/* <!-- 신고/차단버튼 --> */}
                                                            <button type="button" id="reportBtn"></button>
                                                        </>
                                                    }
                                                    
                                                    {/* <button type="button" id="reportBtn"></button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            )
                        }):null}
                    </ul>}
                    {/* <!-- 디자인 참고용 --> */}
                    {false ? 
                    <ul className="list comment_list">
                        <li>
                            <div className="comment_top">
                                <div className="profile_box left">
                                    <span className="profile radius-full">
                                        <img src="/images/thumbnail.jpg" />
                                    </span>
                                </div>
                                <div className={"right comment_box " + style['comment_box']}>
                                    <div className="top_con" >
                                        <div className="badge_list">
                                            <p className="badge best_badge color-btn-01 bg border border-soild border-line radius-full">
                                                <span className="white-always txt">Best</span>
                                            </p>
                                            <p className="badge my_badge border-line border-soild border color-btn-01 radius-full">
                                                <span className="color-btn-01 txt">MY</span>
                                            </p>
                                        </div>
                                        <div className="name text_overflow title-01-b-long color-txt-normal">눈송이송송송송송송송송송송</div>
                                        <div className="date title-sub-02-m color-txt-muted">24.03.25</div>
                                    </div>
                                    <div className="desc color-txt-02 body-03-long">
                                        베스트댓글, 작가감동 댓글 뱃지 이렇게 노출됨.
                                        댓글 내용 글자 제한은 300자. 300자이상 쓰지 못하도록 제한을 둠
                                    </div>
                                    <div className="info">
                                        <span className="title body-01-long color-txt-05 text_overflow">작품제목</span>
                                        <span className="body-01-long color-txt-05">- N화</span>
                                    </div>
                                    <div className="btns bottom_con">
                                        <div className="left">
                                            <button type="button" className="button btn_03 medium outlined label_on round radius-full" id="likeBtn">
                                                <span className="btn-03 color-txt-04 text">0</span>
                                            </button>
                                            <button type="button" onClick={()=>{handlerMoveReply(123)}} className="button btn_03 medium outlined label_on round radius-full before" id="replyBtn">
                                                <span className="btn-03 color-txt-04 text">4</span>
                                            </button>
                                        </div>
                                        <div className="right">
                                            <button type="button" id="deleteBtn" onclick="Modal()" >
                                                <span className="body-01-long color-txt-05">삭제</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="comment_top">
                                <div className="profile_box left">
                                    <span className="profile radius-full">
                                        <img src="/images/thumbnail.jpg" />
                                    </span>
                                </div>
                                <div className="right comment_box">
                                    <div className="top_con">
                                        <div className="badge_list">
                                            <p className="badge love_badge color-state-point-01 bg border border-soild border-line radius-full">
                                                <span className="white-always txt">작가감동♥︎</span>
                                            </p>
                                            <p className="badge my_badge border-line border-soild border color-btn-01 radius-full">
                                                <span className="color-btn-01 txt">MY</span>
                                            </p>
                                        </div>
                                        <div className="name text_overflow title-01-b-long color-txt-normal">눈송이송송송송송송송송송송</div>
                                        <div className="date title-sub-02-m color-txt-muted">24.03.25</div>
                                    </div>
                                    <div className="desc color-txt-02 body-03-long">
                                        베스트댓글, 작가감동 댓글 뱃지 이렇게 노출됨.
                                        댓글 내용 글자 제한은 300자. 300자이상 쓰지 못하도록 제한을 둠
                                    </div>
                                    <div className="info">
                                        <span className="title body-01-long color-txt-05 text_overflow">작품제목</span>
                                        <span className="body-01-long color-txt-05">- N화</span>
                                    </div>
                                    <div className="btns bottom_con">
                                        <div className="left">
                                            <button type="button" className="button btn_03 medium outlined label_on round radius-full" id="likeBtn">
                                                <span className="btn-03 color-txt-04 text">0</span>
                                            </button>
                                            <button type="button" className="button btn_03 medium outlined label_on round radius-full before" id="replyBtn">
                                                <span className="btn-03 color-txt-04 text">답글달기</span>
                                            </button>
                                        </div>
                                        <div className="right">
                                            <button type="button" id="deleteBtn">
                                                <span className="body-01-long color-txt-05">삭제</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="comment_top">
                                <div className="profile_box left">
                                    <span className="profile radius-full">
                                        <img src="/images/thumbnail.jpg" />
                                    </span>
                                </div>
                                <div className="right comment_box">
                                    <div className="top_con">
                                        <div className="badge_list">
                                            <p className="badge my_badge border-line border-soild border color-btn-01 radius-full">
                                                <span className="color-btn-01 txt">MY</span>
                                            </p>
                                        </div>
                                        <div className="name text_overflow title-01-b-long color-txt-normal">눈송이송송송송송송송송송송</div>
                                        <div className="date title-sub-02-m color-txt-muted">24.03.25</div>
                                    </div>
                                    <div className="desc color-txt-02 body-03-long">
                                        베스트댓글, 작가감동 댓글 뱃지 이렇게 노출됨.
                                        댓글 내용 글자 제한은 300자. 300자이상 쓰지 못하도록 제한을 둠
                                    </div>
                                    <div className="info">
                                        <span className="title body-01-long color-txt-05 text_overflow">작품제목</span>
                                        <span className="body-01-long color-txt-05">- N화</span>
                                    </div>
                                    <div className="btns bottom_con">
                                        <div className="left">
                                            {/* <!-- 좋아요버튼 클릭시 className="click" 추가--> */}
                                            <button type="button" className="button btn_03 medium outlined label_on round radius-full" id="likeBtn">
                                                <span className="btn-03 color-txt-04 text">0</span>
                                            </button>
                                            {/* <!-- 답글 버튼 --> */}
                                            {/* <!-- <button type="button" className="button btn_03 medium outlined label_on round radius-full before" id="replyBtn">
                                                <span className="btn-03 color-txt-04 text">답글달기</span>
                                            </button> --> */}
                                            {/* <!-- 내가 쓴 답글이 있을때 버튼 --> */}
                                            <button type="button" className="button btn_01 medium outlined label_on round radius-full after" id="replyBtn">
                                                <span className="btn-03 color-btn-01 text">1</span>
                                            </button>
                                        </div>
                                        <div className="right">
                                            <button type="button" id="deleteBtn">
                                                <span className="body-01-long color-txt-05">삭제</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        {/* <!-- 좋아요 눌렀을때 --> */}
                        <li>
                            <div className="comment_top">
                                <div className="profile_box left">
                                    <span className="profile radius-full">
                                        <img src="/images/thumbnail.jpg" />
                                    </span>
                                </div>
                                <div className="right comment_box">
                                    <div className="top_con">
                                        <div className="badge_list">
                                            <p className="badge my_badge border-line border-soild border color-btn-01 radius-full">
                                                <span className="color-btn-01 txt">MY</span>
                                            </p>
                                        </div>
                                        <div className="name text_overflow title-01-b-long color-txt-normal">눈송이송송송송송송송송송송</div>
                                        <div className="date title-sub-02-m color-txt-muted">24.03.25</div>
                                    </div>
                                    <div className="desc color-txt-02 body-03-long">
                                        베스트댓글, 작가감동 댓글 뱃지 이렇게 노출됨.
                                        댓글 내용 글자 제한은 300자. 300자이상 쓰지 못하도록 제한을 둠
                                    </div>
                                    <div className="info">
                                        <span className="title body-01-long color-txt-05 text_overflow">작품제목</span>
                                        <span className="body-01-long color-txt-05">- N화</span>
                                    </div>
                                    <div className="btns bottom_con">
                                        <div className="left">
                                            {/* <!-- 좋아요버튼 클릭시 className="click" 추가--> */}
                                            <button type="button" className="button btn_03 medium outlined label_on round radius-full click" id="likeBtn">
                                                <span className="btn-03 color-txt-04 text">0</span>
                                            </button>
                                            {/* <!-- 답글 버튼 --> */}
                                            <button type="button" className="button btn_03 medium outlined label_on round radius-full before" id="replyBtn">
                                                <span className="btn-03 color-txt-04 text">1</span>
                                            </button>
                                        </div>
                                        <div className="right">
                                            <button type="button" id="deleteBtn">
                                                <span className="body-01-long color-txt-05">삭제</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    : null}
                    
                </section>
            </div>
            {/* <!-- container --> */}

            <Footer />
            <Modal />
            <AlertModal />
            <ConfirmModal />
        </>
    );
}




export default MypageComment;