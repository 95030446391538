import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import BookpalLink from 'component/common/custom/BookpalLink';
import { useMemberInfo } from 'utils/State';
import useAlertModal from 'component/common/modal/useAlertModal';
import { useEffect, useRef } from 'react';

function SubMenuNav(){


    const location = useLocation();
    const memberInfo = useMemberInfo();
    const [AlertModal, alert] = useAlertModal();
    

    const swiperRef = useRef(null);

    // const page = props.page || "";
    const page = location.pathname;

    const pageList = {
        "/novel":0,
        "/novel/romance":1,
        "/novel/rofan":2,
        "/novel/bl":3,
        "/novel/fantasy":4,
        "/novel/highlevel":5,
        "/novel/ranking":6,
        "/novel/freedom":7,
        "/novel/episode":8,
    };

    useEffect(()=>{
        if (swiperRef.current) {
            swiperRef.current.swiper.slideTo(pageList[page], 300);
        }
    },[page]);


    return (
        <>
        <div id="navMenu" className="color-bg-01 type_header ">
            <Swiper className="swiper-container snbSwiper wrapper btn-04 txt color-txt-muted"
                ref={swiperRef}
                slidesPerView={'auto'}
                preventClicks={true}
                preventClicksPropagation={false}
                observer={true}
                observeParents={true}
                // slideToClickedSlide={true}
                freeMode={true}
                // onInit={(swiper) => {
                //     swiper.slides.forEach((slide, index) => {
                //     if(slide.swiperSlideIndex == pageList[page]){
                //         if(swiper && swiper.slideTo){
                //             swiper.slideTo(index, 0);
                //         }
                //     }
                //     });
                // }}
                // centeredSlides={true}
                // onSlideChange={handleSlideChange}

            >
            {/* <div className="swiper-container snbSwiper wrapper"> */}
                {/* <div className="swiper-wrapper "> */}
                    <SwiperSlide className={"swiper-slide " + (page=="/novel"?"on":"")}>
                        <BookpalLink to={"/novel"}>
                            <span className="color-btn-01 border border-soild">추천</span>
                        </BookpalLink>
                    </SwiperSlide>
                    <SwiperSlide className={"swiper-slide " + (page=="/novel/romance"?"on":"")}>
                        <BookpalLink to={"/novel/romance"}>
                            <span className="color-btn-01 border border-soild">로맨스</span>
                        </BookpalLink>
                    </SwiperSlide>
                    <SwiperSlide className={"swiper-slide " + (page=="/novel/rofan"?"on":"")}>
                        <BookpalLink to={"/novel/rofan"}>
                            <span className="color-btn-01 border border-soild">로판</span>
                        </BookpalLink>
                    </SwiperSlide>
                    <SwiperSlide className={"swiper-slide " + (page=="/novel/bl"?"on":"")}>
                        <BookpalLink to={"/novel/bl"}>
                            <span className="color-btn-01 border border-soild">BL</span>
                        </BookpalLink>
                    </SwiperSlide>
                    <SwiperSlide className={"swiper-slide " + (page=="/novel/fantasy"?"on":"")}>
                        <BookpalLink to={"/novel/fantasy"}>
                            <span className="color-btn-01 border border-soild">판타지</span>
                        </BookpalLink>
                    </SwiperSlide>
                    <SwiperSlide className={"swiper-slide " + (page=="/novel/highlevel"?"on":"")}>
                        <BookpalLink 
                            to={"/novel/highlevel"} 
                            check={{
                                view_type:memberInfo?.view_type,
                                work_adult:true,
                            }}
                            alert={alert}>
                            <span className="color-btn-01 border border-soild">29+</span>
                        </BookpalLink>
                    </SwiperSlide>
                    <SwiperSlide className={"swiper-slide " + (page=="/novel/ranking"?"on":"")}>
                        <BookpalLink to={"/novel/ranking"}>
                            <span className="color-btn-01 border border-soild">랭킹</span>
                        </BookpalLink>
                    </SwiperSlide>
                    <SwiperSlide className={"swiper-slide " + (page=="/novel/freedom"?"on":"")}>
                        <BookpalLink to={"/novel/freedom"}>
                            <span className="color-btn-01 border border-soild">자유연재</span>
                        </BookpalLink>
                    </SwiperSlide>
                    <SwiperSlide className={"swiper-slide " + (page=="/novel/episode"?"on":"")}>
                        <BookpalLink to={"/novel/episode"}>
                            <span className="color-btn-01 border border-soild">단행본</span>
                        </BookpalLink>
                    </SwiperSlide>
                {/* </div> */}
            {/* </div> */}
            </Swiper>
        </div>
        <AlertModal />
        </>
    );
}



export default SubMenuNav;