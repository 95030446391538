import { useEffect, useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionSetShowGiftToast } from "action/index";
import { Toast } from "react-bootstrap";
import NewlineToBr from "../content/NewlineToBr";

let timeout = false;

function GiftToast(){

    const toastRef = useRef(false);
    const dispatch = useDispatch();
    
    const toastData = useSelector((state) => state.data.show_gift_toast);

    const title = toastData?.title || "";
    const body = toastData?.body || "";
    const date = toastData?.date || "";
    const [opacity, setOpacity] = useState(false);

    // console.log("GiftToast =>>>>>>>>>>>>>>>> ", title);
    useEffect(()=>{
        if(title){
            
            setTimeout(()=>{
                setOpacity(true);
            },100);

            setTimeout(()=>{
                setOpacity(false);
            },2100);
            clearTimeout(timeout);
            timeout = false;
            timeout = setTimeout(()=>{
                // console.log(toastData);
                dispatch(actionSetShowGiftToast({}));
                setOpacity(false);
            },2300);
        }
    },[toastData])

    if(!title){
        return (
            <></>
        );
    }

    
    return (

        <Toast 
            ref={toastRef}
            show={title ? true : false} 
            className="toast gift black_opacity-80 bg radius-xs fade hide"
            style={{
                transition: "opacity 0.3s ease-in-out",
                opacity: opacity ? 1 : 0,
                zIndex:999999,
            }}>
            <Toast.Body>
                {title ? <p className="text color-btn-02-txt btn-03-active"><NewlineToBr text={title} /></p>:null}
                {body ? <p className="text color-btn-02-txt body-02-long">{body}</p>:null}
                {date ? <p className="text color-state-point-01 txt caption-02-long">{date}</p>:null}
            </Toast.Body>
        </Toast>
    );
}




export default GiftToast;