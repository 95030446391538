import { useNavigate } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import style from '../../style/Contents.module.scss';


function Review(props){

    const navigate = useBookpalNavigate();

    const data = props?.data || null;
    const bid = props?.bid || null;
    const onAction = props?.onAction || (()=>{});




    if(data === null){
        return <></>
    }

    return (
        <>
            <div className={"left " + style['left']}>
                <p className={"icon " + style['icon'] + " " + style['icon_megaphone']} id="icon_megaphone"></p>
                <p className={"title info " + style['title'] + " " + style['info']}>
                    <span className="color-txt-01 body-03">
                        {/* {data?.title} */}
                        댓글 입력시 선물소장권 추첨!
                    </span> 
                </p>
            </div>
        </>
    )
}



export default Review;