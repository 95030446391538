import { Link, useNavigate } from 'react-router-dom';
import BookpalLink from 'component/common/custom/BookpalLink';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import { ImgBadge, ImgCover, ImgDefault } from 'utils/Images';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BookpalLazyLoadImage from 'component/common/custom/BookpalLazyLoadImage';
import { useMemberInfo } from 'utils/State';
import { formatNumber, getViewCntConvert, useWidth } from 'utils/Common';

import style from '../style/Contents.module.scss';
// import style from './ViewerContents.module.scss';
import VerticalSection from 'component/common/section/VerticalSection';
import FavBtn from './viewer_content/FavBtn';
import { useEffect, useState } from 'react';
import NextNovelBtn from './viewer_content/NextNovelBtn';
import NextToonBtn from './viewer_content/NextToonBtn';
import NextEpubBtn from './viewer_content/NextEpubBtn';
import { GetState } from 'utils/State';


function ViewerContents({ data, type, cid, next, className, elemStyle, alert, buyConfirm }){
    // console.log("ViewerContents", type)
    const width = useWidth();

    const memberInfo = useMemberInfo();
    const getState = GetState();

    const navigate = useBookpalNavigate();

    const [paddingStyle,setPaddingStyle] = useState("120px 64px 200px");
    const [isViewIntroduce, setIsViewIntroduce] = useState(false);


    const viewerRun = getState.useGetViewerInfoRun();

    // console.log("viewerContent => ", data);

    const getTypeName = (type) =>{
        switch(type){
            case "novel": return "웹소설";
            case "epub": return "단행본";
            case "toon": return "웹툰";
            case "comic": return "웹툰";
        }
    }

    useEffect(()=>{
        if(width > 540){
            setPaddingStyle("120px 64px 200px");
        } else {
            setPaddingStyle("120px 0px 200px");
        }
    },[width]);

// console.log("viewerRun",viewerRun)
    if(viewerRun === true){
        return <>
            <div style={{height:"100vh"}}></div>
        </>;
    }


    return (
        <div 
            className={"section_box template txt " + className + " " + (style['section_box'] ?? null) + " " + style['template']}
            style={{
                backgroundColor:"white",
                padding: paddingStyle,
                ...elemStyle}}
            >
            {/* <!-- 작가의 말 --> */}
            <div 
                className={"section " + style['section'] + " " + style['idIntroduction']} 
                id="Introduction"
                style={{paddingLeft:"12px", paddingRight:"12px"}}
                >
                <div className={"contents_txt " + style['contents_txt']}>
                    <p className={"title " + style['title']}>
                        <BookpalLink to={"/content/"+type+"/"+data?.bid}>
                            <span className={"episodes_tit color-txt-normal title-book-04-b text_overflow " + style['episodes_tit']}>
                                {data?.title}
                            </span>
                            <span className={"icon " + style['icon']}></span>
                        </BookpalLink>
                    </p>
                    <p className={"episodes title-book-03-m color-txt-04 " + style['episodes']}>
                        {(data?.chapter_extra_no > 0 )?"외전 "+data?.chapter_extra_no+(type==="epub"?"권":"화"):(data?.chapter_no > 0 )?data?.chapter_no+(type==="epub"?"권":"화"):""}
                    </p>
                </div>
                <div className={"introduction_box radius-sm color-border-01 gold_opacity-10 border-line border-soild " + style['introduction_box']}>
                    <div className={"text_box " + style['text_box'] + " " + (isViewIntroduce ? style['show']:"")}>
                        <div className={"write_tit " + style['write_tit']}>
                            <span className={"tit title-01-b color-txt-03 " + style['tit']}>작가의 말</span>
                            <span 
                                className={"write_name title-01-m color-txt-05 text_overflow " + style['write_name'] + " " + style['text_overflow']}
                                style={{lineHeight:"16px"}}
                                >{data?.writer}</span>
                        </div>
                        <div id="inputbox" className={style['inputbox']}>
                            <span className={"text body-01-long color-txt-03 " + style['text'] }>
                                {data?.writer_note}
                            </span>
                        </div>
                        {/* {data?.writer_note && data?.writer_note != "" && data?.writer_note?.includes('\n') ? */}
                        <button 
                            type="button" 
                            id="moreBtn" 
                            className={style['moreBtn']} style={{display:"block"}}
                            onClick={()=>{
                                setIsViewIntroduce(!isViewIntroduce);
                            }}
                            ></button>
                        {/* :null} */}
                        {/* <button 
                            type="button" 
                            id="moreBtn" 
                            style={{display: "block"}}></button> */}
                    </div>
                </div>
            </div>
            {/* <!-- 작가 응원하기 --> */}
            <div 
                className={"section support_box " + style['section'] + " " + style['support_box']}
                style={{paddingLeft:"12px", paddingRight:"12px"}}
                >
                {/* <div className={"click_box " + style['click_box']}>
                    <p className={"tit title-01-m-long color-txt-normal " + style['tit']}>
                        관심 등록으로 작가 응원하기
                    </p>
                    <p className={"icon_box " + style['icon_box']}>
                        
                        <button type="button" className={"button favorite_btn click " + style['button'] + " " + style['favorite_btn'] + " " + style['click']}></button>
                        <button type="button" className={"button alarm_btn " + style['button'] + " " + style['alarm_btn']}></button>
                    </p>
                </div> */}
                <FavBtn 
                    type={type}
                    data={data}
                />
                <div className={"button_box " + style['button_box']}>
                {/* <!-- 댓글 달기 전 --> */}
                {data?.total_comment === 0 ?
                <button 
                    type="button" 
                    className={"button btn_03 medium label_on outlined radius-full round " + style['button']}
                    onClick={()=>{
                        // navigate("/view/"+type+"/"+data?.bid+"/"+cid+"/comment")
                        navigate("/view/"+type+"/"+cid+"/comment")
                        
                    }}
                    >
                    <span className="btn-04 color-txt-04">댓글달기</span>
                </button>
                :null}
                {/* <!-- 댓글 달고나서 --> */}
                {data?.total_comment > 0 ?
                <button 
                    type="button" 
                    className={"button btn_03 medium label_on outlined radius-full round " + style['button']}
                    onClick={()=>{
                        // navigate("/view/"+type+"/"+data?.bid+"/"+cid+"/comment")
                        navigate("/view/"+type+"/"+cid+"/comment")
                    }}
                    >
                    <span className="btn-04 color-txt-04">댓글 <span className="num">{formatNumber( data?.total_comment )}</span></span>
                </button>
                :null}

                </div>
                {/* <!-- 다음회차 안내 영역 --> */}
                {/* <div className={"next_episodes " + style['next_episodes']}>
                    <button type="button" className={"button large label_on radius-sm color-border-01 " + style['button']}>
                        <div className={"left " + style['left']}>
                            <p className="color-txt-02 title-01-m-long">
                                다음화 보기 {next?.free_status ? "(무료)":""}
                            </p>
                            <p className={"title body-01-long color-txt-05 text_overflow " + style['title']}>
                                {next?.no}. {next?.title}
                            </p>
                        </div>
                    </button>
                </div> */}
                {type === "novel" ? 
                <NextNovelBtn 
                    data={data} 
                    type={type}
                    next={next} 
                    bid={data?.bid}
                    alert={alert}
                    buyConfirm={buyConfirm}
                    />
                :null}
                {type === "toon" ? 
                <NextToonBtn 
                    data={data} 
                    type={type}
                    next={next} 
                    bid={data?.bid}
                    alert={alert}
                    buyConfirm={buyConfirm}
                    />
                :null}
                {type === "epub" ? 
                <NextEpubBtn 
                    data={data} 
                    type={type}
                    next={next} 
                    bid={data?.bid}
                    alert={alert}
                    buyConfirm={buyConfirm}
                    />
                :null}
                
            </div>
            {/* <!-- 연관작품 보러가기 --> */}
            {data?.related_info && Array.isArray(data?.related_info) && data?.related_info?.length > 0 ?
            <div className={"section template_column temp_list " + style['section'] + " " + style['template_column'] + " " + style['temp_list']}>
                <div className={"tit_con " + style['tit_con']}>
                    <div className={"left text_overflow " + style['left'] + " " + style['text_overflow']}>
                        <span className="color-txt-normal title-01-b">연관작품 보러가기</span>
                    </div>
                </div>
                
                <ul className={"list " + style['list']}>
                    {data?.related_info.map((item, index)=>{

                        return (
                            <li key={index}>
                                <BookpalLink to={""}>
                                    <div className="cover_img border-soild border-right-line color-border-01 radius-xs">
                                        {item?.adult ? <p className="ic_adult badge_adult_middle"><img src="/images/ic_adult.svg" /></p> : null}
                                        
                                        {/* <img src={item?.cover} /> */}
                                        {/* <BookpalLazyLoadImage 
                                            placeholderSrc={ImgDefault("cover_logo")}
                                            // effect="blur"
                                            src={ImgCover(memberInfo?.view_type, item?.adult, ImgDefault("cover_lock"), item?.cover)}
                                            // style={defaultImageStyle}
                                            onError={(e)=>{
                                                e.currentTarget.src = ImgDefault("cover_logo");
                                            }}
                                        /> */}
                                        <BookpalLazyLoadImage 
                                                adult={item?.adult}
                                                defaulttype={"cover_logo"}
                                                locktype={"cover_lock"}
                                                src={item?.cover}
                                                // style={defaultImageStyle}
                                            />
                                        <p className="ic_bottom badges_box">
                                            {item?.badge_promotion !== "" ? <span className="ic_midnight radius-xs icon_badge"><img src={ImgBadge(item?.badge_promotion)} /></span> : null}
                                            {item?.badge_new_up !== "" ? <span className="ic_up radius-xs icon_badge"><img src={ImgBadge(item?.badge_new_up)} /></span> : null}
                                            {item?.badge_discount > 0 ? <span className="ic_discount radius-xs discount_10 icon_badge"><img src={ImgBadge(item?.badge_discount)} /></span> : null }
                                        </p>
                                    </div>
                                    <div className="info_txt spacing_08">
                                        <div className="info">
                                            {item?.ct_type !== "" ? <span className="body-01 color-txt-05">{getTypeName(item?.ct_type)}</span> : null}
                                            {item?.genre_kor !== "" ? <span className="body-01 color-txt-05">{item?.genre_kor}</span> : null}
                                        </div> 
                                        {/* <div className="title color-txt-normal title-book-03-m text_overflow">{item?.title}</div> */}
                                        {/* {item?.wr_nick !== "" ? <div className="writer color-txt-03 body-01 text_overflow">{item?.wr_nick}</div> : ""} */}
                                        <div className={"list_tit_con " + style['list_tit_con']}>
                                            <span className="title color-txt-normal title-book-03-m text_overflow">{item?.title}</span>
                                        </div>
                                        {item?.wr_nick !== "" ? <div className="writer body-02 color-txt-03 text_overflow">{item?.wr_nick}</div>: ""}
                                        <div className="info">
                                            {item?.free_chapter ? <span className="body-01 color-txt-05">{item?.free_chapter}화무료</span> : null}
                                            <span className="body-01 color-txt-05">{getViewCntConvert(item?.view_cnt)}</span>
                                        </div>
                                    </div>
                                </BookpalLink>
                            </li>
                        )
                    })}
                    {/* <li>
                        <a href="#">
                        <div className="cover_img border-soild border-right-line color-border-01 radius-xs">
                            <p className="ic_adult badge_adult_middle">
                            <img src="../images/ic_adult.svg" />
                            </p>
                            <img src="../images/thumbnail.jpg" />
                        </div>
                        <div className="info_txt spacing_08">
                            <div className="info">
                            <span className="body-01 color-txt-05">웹툰</span>
                            <span className="body-01 color-txt-05">로맨스</span>
                            </div>
                            <div className="list_tit_con">
                            <span className="title color-txt-normal title-book-03-m text_overflow">책제목입니다.</span>
                            </div>
                            <div className="writer body-02 color-txt-03 text_overflow">
                            작가명
                            </div>
                            <div className="info">
                            <span className="body-01 color-txt-05">3화무료</span>
                            <span className="body-01 color-txt-05">61.4만</span>
                            </div>
                        </div>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                        <div className="cover_img border-soild border-right-line color-border-01 radius-xs">
                            <p className="ic_adult badge_adult_middle">
                            <img src="../images/ic_adult.svg" />
                            </p>
                            <img src="../images/thumbnail.jpg" />
                        </div>
                        <div className="info_txt spacing_08">
                            <div className="info">
                            <span className="body-01 color-txt-05">단행본</span>
                            <span className="body-01 color-txt-05">로맨스</span>
                            </div>
                            <div className="list_tit_con">
                            <span className="title color-txt-normal title-book-03-m text_overflow">책제목입니다.</span>
                            </div>
                            <div className="writer body-02 color-txt-03 text_overflow">
                            작가명
                            </div>
                            <div className="info">
                            <span className="body-01 color-txt-05">3화무료</span>
                            <span className="body-01 color-txt-05">61.4만</span>
                            </div>
                        </div>
                        </a>
                    </li> */}
                </ul>
                
            </div>
            :null}

            {data?.other_work ? 
            <VerticalSection 
                sectionType={"small"}
                title={"작가의 다른 작품"}
                useNavigateBtn={false}
                // moreTxt={"작가소개"}
                classNameSection={style['section']}
                classNameTotCon={style['tit_con']}
                classNameList={style['list']}
                useInfo={true}
                data={data?.other_work}
            />
            :null}


            {data?.recommend_work ? 
            <VerticalSection 
                sectionType={"small"}
                title={"독자들이 선택한 다음 작품!"}
                useNavigateBtn={false}
                // moreTxt={"작가소개"}
                classNameSection={style['section']}
                classNameTotCon={style['tit_con']}
                classNameList={style['list']}
                useInfo={true}
                data={data?.recommend_work}
            />
            :null}

        </div>
    )
}


export default ViewerContents;