import { useApiMutation } from './common/ApiCommon';


export const ApiViewerNovel = (options) => {

    const subUrl = "/viewer/novel";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiViewerEpub = (options) => {

    const subUrl = "/viewer/epub";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiViewerToon = (options) => {

    const subUrl = "/viewer/toon";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};