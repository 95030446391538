import { useEffect, useRef, useState } from 'react';
import { SetState, GetState } from 'utils/State';
import { ApiBookChapterList } from 'api/Book';
import he from 'he';


import style from '../style/Contents.module.scss';

import img_icon_minus from 'assets/images/contents/icon_minus.svg';
import img_icon_plus from 'assets/images/contents/icon_plus.svg';
import img_icon_refresh from 'assets/images/icon_refresh.svg';

import style_fade from './FooterHorizontal.module.scss'
import { useNavigate } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import HeaderNovelEpisodeList from './header/HeaderNovelEpisodeList';
import HeaderToonEpisodeList from './header/HeaderToonEpisodeList';
import HeaderEpubEpisodeList from './header/HeaderEpubEpisodeList';

function Header({type, bid, cid, data, visible, alert, buyConfirm, allowStyle, viewerRealStyle}){
    
    const ref = useRef(null);

    const bookListRef = useRef(null);

    const navigate = useBookpalNavigate();

    const setState = SetState();
    const getState = GetState();

    const viewerFont = getState.useGetViewerInfoFont();
    const viewerFontSize = getState.useGetViewerInfoFontSize();
    const viewerLineHeight = getState.useGetViewerInfoLineHeight();
    const viewerSideMargin = getState.useGetViewerInfoSideMargin();
    // const viewerStyle = getState.useGetViewerInfoStyle();
    const viewerTheme = getState.useGetViewerInfoTheme();
    const viewerImageNone = getState.useGetViewerInfoImageNone();
    const viewerRun = getState.useGetViewerInfoRun();

    const [setting, setSetting] = useState(false);
    const [list, setList] = useState(false);
    const [bookmark, setBookmark] = useState(false);

    const [bookData, dataSubmit, initData, paging, setData] = ApiBookChapterList({
        data:{
            bid:bid,
            // viewonly:viewonly,
            // epub:_info?.epub ?? null,
            epub:(type === "epub"),
            // order:order,
        },
        onSuccess:(data)=>{
            // if(data?.status === true){
            //     if(data?.result?.read_info){
            //         if(data?.result?.paging?.current_page === 1){
            //             setReadInfo(data?.result?.read_info);
            //         }
            //     }
            // }
        },
        // bottomRef:contentRef,
    });

    const handlerShowSetting = () => {
        setSetting(!setting);
        if(!setting){
            setList(false);
            setBookmark(false);
        }
    }

    const handlerShowListBookmark = () => {
        // setList(!(list || bookmark));
        console.log("list || bookmark", list, bookmark, (list && bookmark))
        if((list || bookmark)){
            setList(false);
            setSetting(false);
            setBookmark(false)
        } else {
            setList(true);
            setSetting(false);
            setBookmark(false)
        }
    }

    const handlerShowList = (is_list) => {
        setList(is_list || !list);
        if(is_list || !list){
            setSetting(false);
            setBookmark(false)
        }
    }

    const handlerShowBookmark = (book) => {
        setBookmark(book);
        if(book){
            setSetting(false);
            setList(false)
        }
    }

    const handlerSettingInit = ()=>{
        setState.setViewerInfoFont("init");
        setState.setViewerInfoFontSize("init");
        setState.setViewerInfoLineHeight("init");
        setState.setViewerInfoSideMargin("init");
        setState.setViewerInfoStyle("init");
        setState.setViewerInfoTheme("init");
    }


    useEffect(()=>{
        if(list){
            if(bookData === null){
                dataSubmit();
            }
        }
    },[list]);

    useEffect(()=>{

        const handlerScroll = ()=>{
            const div = bookListRef.current;
            if(div){
                if (div.scrollTop + div.clientHeight >= div.scrollHeight) {
                    console.log('Scrolled to bottom');
                    // 원하는 이벤트를 여기서 실행
                    dataSubmit();
                }
            }

            
        }
        const div = bookListRef.current;
        if (div) {
            div.addEventListener('scroll', handlerScroll);
        }

        return () => {
            if (div) {
                div.removeEventListener('scroll', handlerScroll);
            }
        };
    },[bookListRef]);

    useEffect(()=>{

        const handler = () => {
            if(ref.current){
                setTimeout(()=>{
                    ref.current.style.display = "none";
                },500);
            }
        }

        if(visible){
            if(ref.current){
                ref.current.style.display = "block";
            }
        } else {
            //console.log("asdfasdfasdf");
            handler();
            setList(false);
            setSetting(false);
            setBookmark(false)
        }
    },[visible]);

    return (
        <div className={"view_header " + style['view_header'] + " " + (!visible ? style_fade['fade-out']:"")}>
            <div className={"area_box " + style['area_box']}>
                <div className={"m_wrap clearfix " + style['m_wrap']}>
                    <div className={"left " + style['left']}>
                        <button 
                            type="button" 
                            className={"home_btn button " + style['home_btn'] + " " + style['button']}
                            onClick={()=>{
                                navigate("/content/"+type+"/"+bid);
                            }}
                            ></button>
                        <p className={"title text_overflow " + style['title'] + " " + style['text_overflow']}>
                            {/* {data?.chapter_no}.  */}
                            {he.decode( data?.chapter_title ?? "" )}
                        </p>
                    </div>
                    <div className={"right " + style['right']}>
                        <button
                            type="button" 
                            className={"menu_btn button " + style['menu_btn'] + " " + style['button'] + " " + ((list || bookmark) ? style['active'] : "")}
                            onClick={()=>{
                                handlerShowListBookmark();
                            }}
                            ></button>
                        <button 
                            type="button" 
                            className={"setting_btn button " + style['setting_btn'] + " " + style['button'] + " " + (setting ? style['active'] : "")}
                            onClick={()=>{
                                handlerShowSetting();
                            }}
                            ></button>
                    </div>
                </div>
            </div>
            <div id="menu" className={"modal_area " + style['modal_area'] + " " + style['menu']}>
                <div className={"modal_bg " + style['modal_bg']}></div>
                <div 
                    className={"modal_box DarkMode temp_list temp_list_type " + style['modal_box'] + " " + style['DarkMode']}
                    style={{display:( (list || bookmark) ? "block":"none")}}
                    >
                    <div className={"menu_box m_wrap " + style['menu_box'] + " " + style['m_wrap']}>
                        <ul className={"tab_list menu_tab medium " + style['tab_list'] + " " + style['menu_tab']}>
                            <li className="color-txt-muted btn-04"><a 
                                style={{cursor:"pointer"}}
                                className={"border " + style['border'] + " " + (list ? "active":"")}
                                onClick={()=>{
                                    handlerShowList(true);
                                }}
                                >회차(<span>{paging?.total_cnt}{type==="epub"?"권":"화"}</span>)</a></li>
                            <li className="color-txt-muted btn-04"><a 
                                style={{cursor:"pointer"}}
                                className={"border " + style['border'] + " " + (bookmark ? "active":"")}
                                onClick={()=>{
                                    handlerShowBookmark(true);
                                }}
                                >책갈피</a></li>
                        </ul>
                        <div className={"list " + style['list']}
                            style={{display:((list || bookmark)?"block":"none")}}
                            >
                            <div 
                                className={"list_1 " + style['list_1']}
                                style={{display:(list?"block":"none")}}
                                ref={bookListRef}
                                >
                                <ul>
                                    {bookData && Array.isArray(bookData) && bookData?.length > 0 ?
                                        bookData.map((item,index)=>{
                                            switch(type){
                                                case "novel":
                                                    return (
                                                        <HeaderNovelEpisodeList 
                                                            data={item}
                                                            bid={bid}
                                                            cid={cid}
                                                            type={type}
                                                            alert={alert} 
                                                            buyConfirm={buyConfirm}
                                                        />
                                                    );
                                                case "toon":
                                                    return (
                                                        <HeaderToonEpisodeList
                                                            data={item}
                                                            bid={bid}
                                                            cid={cid}
                                                            type={type}
                                                            alert={alert} 
                                                            buyConfirm={buyConfirm}
                                                        />
                                                    );
                                                case "epub":
                                                    return (
                                                        <HeaderEpubEpisodeList
                                                            data={item}
                                                            bid={bid}
                                                            cid={cid}
                                                            type={type}
                                                            alert={alert} 
                                                            buyConfirm={buyConfirm}
                                                        />
                                                    );
                                            }
                                        })
                                    :null}
                                </ul>
                            </div>
                            <div 
                                className={"list_2 " + style['list_2']}
                                style={{display:(bookmark?"flex":"none")}}
                                >
                                <p className={"desc color-txt-04 " + style['desc']}>
                                    {/* 책갈피는 앱에서 지원하는 기능입니다.<br />앱을 이용해 주세요. */}
                                    책갈피 기능은 앱에서 지원될 예정입니다.
                                </p>
                            </div>
                        </div>
                        <p className={"home_indicator radius-full " + style['home_indicator']}></p>
                    </div>
                </div>
            </div>
            <div id="setting" className={"modal_area " + style['modal_area'] + " " + style['setting']}>
                <div className={"modal_bg " + style['modal_bg']}></div>
                <div 
                    className={"modal_box setting_box DarkMode " + style['modal_box'] + " " + style['setting_box'] + " " + style['DarkMode']}
                    style={{display:(setting ? "block":"none")}}
                    > 
                    <div className={"menu_box m_wrap " + style['menu_box'] + " " + style['m_wrap']}>
                        <ul className={"setting_menu " + style['setting_menu']}>
                            <li>
                                <div className={"title color-txt-02 " + style['title']}>글꼴</div>
                                <div className={"container type_btn " + style['container'] + " " + style['type_btn']}>
                                    {/* <!-- 해당버튼 클릭시 active 추가 --> */}
                                    
                                    <button 
                                        type="button" 
                                        id="fontBtn1" 
                                        className={"button border-line border-soild color-border-01 radius-full NanumBarunGothic color-txt-04 " + style['button'] + " " + style['NanumBarunGothic'] + " " + (viewerFont==="NanumBarunGothic"?style['active']:"")}
                                        onClick={()=>{
                                            setState.setViewerInfoFont("NanumBarunGothic");
                                        }}
                                        >나눔바른고딕</button>
                                    <button 
                                        type="button" 
                                        id="fontBtn2" 
                                        className={"button border-line border-soild color-border-01 radius-full NotoSansKR color-txt-04 " + style['button'] + " " + style['NotoSansKR'] + " " + (viewerFont==="NotoSansKR"?style['active']:"")}
                                        onClick={()=>{
                                            setState.setViewerInfoFont("NotoSansKR");
                                        }}
                                        >본명조</button>
                                    <button 
                                        type="button" 
                                        id="fontBtn3" 
                                        className={"button border-line border-soild color-border-01 radius-full YESMyoungjo color-txt-04 " + style['button'] + " " + style['YESMyoungjo'] + " " + (viewerFont==="YESMyoungjo"?style['active']:"")}
                                        onClick={()=>{
                                            setState.setViewerInfoFont("YESMyoungjo");
                                        }}
                                        >예스명조</button>
                                </div>
                            </li>
                            <li>
                                <div className={"title color-txt-02 " + style['title']}>글자크기</div>
                                <div className={"container " + style['container']}>
                                    <button 
                                        type="button" 
                                        className={"btn_03 outlined round medium button label_off radius-full " + style['button']}
                                        onClick={()=>{
                                            setState.setViewerInfoFontSize(viewerFontSize-1);
                                        }}
                                        ><img src={img_icon_minus} /></button>
                                    <div className={"text_cell " + style['text_cell']}>
                                        <span className="num color-btn-01 txt">{viewerFontSize}</span>
                                        <span className="max_num color-btn-03-txt">/10</span>
                                    </div>
                                    <button 
                                        type="button" 
                                        className={"btn_03 outlined round medium button label_off radius-full " + style['button']}
                                        onClick={()=>{
                                            setState.setViewerInfoFontSize(viewerFontSize+1);
                                        }}
                                        ><img src={img_icon_plus} /></button>
                                </div>
                            </li>
                            <li>
                                <div className={"title color-txt-02 " + style['title']}>줄간격</div>
                                <div className={"container " + style['container']}>
                                    <button 
                                        type="button" 
                                        className={"btn_03 outlined round medium button label_off radius-full " + style['button']}
                                        onClick={()=>{
                                            setState.setViewerInfoLineHeight(viewerLineHeight-1);
                                        }}
                                        ><img src={img_icon_minus} /></button>
                                    <div className={"text_cell " + style['text_cell']}>
                                        <span className="num color-btn-01 txt">{viewerLineHeight}</span>
                                        <span className="max_num color-btn-03-txt">/8</span>
                                    </div>
                                    <button 
                                        type="button" 
                                        className={"btn_03 outlined round medium button label_off radius-full " + style['button']}
                                        onClick={()=>{
                                            setState.setViewerInfoLineHeight(viewerLineHeight+1);
                                        }}
                                        ><img src={img_icon_plus} /></button>
                                </div>
                            </li>
                            <li>
                                <div className={"title color-txt-02 " + style['title']}>좌우여백</div>
                                <div className={"container " + style['container']}>
                                    <button 
                                        type="button" 
                                        className={"btn_03 outlined round medium button label_off radius-full " + style['button']}
                                        onClick={()=>{
                                            setState.setViewerInfoSideMargin(viewerSideMargin-1);
                                        }}
                                        ><img src={img_icon_minus} /></button>
                                    <div className={"text_cell " + style['text_cell']}>
                                        <span className="num color-btn-01 txt">{viewerSideMargin}</span>
                                        <span className="max_num color-btn-03-txt">/5</span>
                                    </div>
                                    <button 
                                        type="button" 
                                        className={"btn_03 outlined round medium button label_off radius-full " + style['button']}
                                        onClick={()=>{
                                            setState.setViewerInfoSideMargin(viewerSideMargin+1);
                                        }}
                                        ><img src={img_icon_plus} /></button>
                                </div>
                            </li>
                            <li>
                                <div className={"title color-txt-02 " + style['title']}>넘김방식</div>
                                <div className={"container type_btn " + style['container'] + " " + style['type_btn']}>
                                    {/* <!-- 해당버튼 클릭시 active 추가 --> */}
                                    {allowStyle?.includes("scroll") ? 
                                    <button 
                                        type="button" 
                                        id="scrollBtn" 
                                        className={"button border-line border-soild color-border-01 radius-full color-txt-04 " + style['button'] + " " + (viewerRealStyle==="scroll"?style['active']:"")}
                                        onClick={()=>{
                                            setState.setViewerInfoStyle("scroll");
                                        }}
                                        >스크롤</button>
                                    :null}
                                    {allowStyle?.includes("page") ? 
                                    <button 
                                        type="button" 
                                        id="pageBtn" 
                                        className={"button border-line border-soild color-border-01 radius-full color-txt-04 " + style['button'] + " " + (viewerRealStyle==="page"?style['active']:"")}
                                        onClick={()=>{
                                            setState.setViewerInfoStyle("page");
                                        }}
                                        >좌우페이지</button>
                                    :null}
                                    {allowStyle?.includes("reverse") ? 
                                    <button 
                                        type="button" 
                                        id="reverseBtn" 
                                        className={"button border-line border-soild color-border-01 radius-full color-txt-04 " + style['button'] + " " + (viewerRealStyle==="reverse"?style['active']:"")}
                                        onClick={()=>{
                                            setState.setViewerInfoStyle("reverse");
                                        }}
                                        >역방향</button>
                                    :null}
                                </div>
                            </li>
                            <li>
                                <div className={"title color-txt-02 " + style['title']}>테마</div>
                                <div className={"container type_btn " + style['container'] + " " + style['type_btn']}>
                                    {/* <!-- 해당버튼 클릭시 active 추가 --> */}
                                    <button 
                                        type="button" 
                                        id="themeBtn1" 
                                        className={"view_style radius-full border border-soild border-line viewer-white bg " + style['view_style'] + " " + style['viewer-white'] + " " + (viewerTheme==="white"?style['active']:"")}
                                        onClick={()=>{
                                            setState.setViewerInfoTheme("white");
                                        }}
                                        ></button>
                                    <button 
                                        type="button" 
                                        id="themeBtn2" 
                                        className={"view_style radius-full border border-soild border-line viewer-gray bg " + style['view_style'] + " " + style['viewer-gray'] + " " + (viewerTheme==="gray"?style['active']:"")}
                                        onClick={()=>{
                                            setState.setViewerInfoTheme("gray");
                                        }}
                                        ></button>
                                    <button 
                                        type="button" 
                                        id="themeBtn3" 
                                        className={"view_style radius-full border border-soild border-line viewer-black-02 bg " + style['view_style'] + " " + style['viewer-black-02'] + " " + (viewerTheme==="black"?style['active']:"")}
                                        onClick={()=>{
                                            setState.setViewerInfoTheme("black");
                                        }}
                                        ></button>
                                    <button 
                                        type="button" 
                                        id="themeBtn4" 
                                        className={"view_style radius-full border border-soild border-line viewer-yellow bg " + style['view_style'] + " " + style['viewer-yellow'] + " " + (viewerTheme==="yellow"?style['active']:"")}
                                        onClick={()=>{
                                            setState.setViewerInfoTheme("yellow");
                                        }}
                                        ></button>
                                    <button 
                                        type="button" 
                                        id="themeBtn5" 
                                        className={"view_style radius-full border border-soild border-line viewer-green bg " + style['view_style'] + " " + style['viewer-green'] + " " + (viewerTheme==="green"?style['active']:"")}
                                        onClick={()=>{
                                            setState.setViewerInfoTheme("green");
                                        }}
                                        ></button>
                                </div>
                            </li>
                        </ul>
                        <p className={"reset_btn " + style['reset_btn']}>
                            <button 
                                type="button" 
                                id="resetBtn" 
                                className={"button btn_03 medium normal filled label_on radius-full color-txt-04 " + style['button'] + " " + style['resetBtn']}
                                onClick={()=>{
                                    handlerSettingInit();
                                }}
                                >
                                <span>설정 초기화</span>
                                <img src={img_icon_refresh} />
                            </button>
                        </p>
                        <p className={"home_indicator radius-full " + style['home_indicator']}></p>
                    </div>
                </div> 
            </div>
        </div>
    )
}


export default Header;