import { getAppsDataWithUserAgent } from './Common';

export const isApp = () => {
    const data = getAppsDataWithUserAgent();
    if(["ios","android"].includes(data?.os_type)){
        return true;
    }
    return false;
}

export const isIosApp = () => {
    const data = getAppsDataWithUserAgent();
    if(["ios"].includes(data?.os_type)){
        return true;
    }
    return false;
}

export const isAndroidApp = () => {
    const data = getAppsDataWithUserAgent();
    if(["android"].includes(data?.os_type)){
        return true;
    }
    return false;
}

export const appSet = (type, data) => {
    
    if(window?.webkit?.messageHandlers?.BOOKPAL){
        //ios
        window.webkit.messageHandlers.BOOKPAL.postMessage( JSON.stringify({
            type:type,
            value:data
        }) );
    } else if(window.BOOKPAL && window.BOOKPAL?.set){
        //android
        window.BOOKPAL.set(JSON.stringify({
            type:type,
            value:data
        }));
    } else {
        console.log("not action object", window.BOOKPAL);
    }
}

export const appSetClose = () => {
    console.log("appSetClose")
    appSet("close",{});
}

export const appSetDeclare = (data) => {
    console.log("appSetDeclare")
    appSet("declare",{...data});
}

export const appSetMove = (msg, link) => {
    console.log("appSetMove",msg, link)
    appSet("move",{
        msg:msg,
        link:link
    });
}

export const appSetPath = (path) => {
    console.log("appSetPath",path)
    appSet("path",{
        path:path,
    });
}

export const appSetLogout = () => {
    console.log("appSetLogout")
    appSet("logout",{});
}

export const appSetPay = (product_code, product_iap_code) => {
    appSet("pay",{
        product_code:product_code,
        product_iap_code:product_iap_code,
    });
}