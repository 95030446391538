import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import { ApiMypageInfoEdit, ApiMypageUpdateMyInfo, ApiMypageUpdateMyInfoNew } from 'api/Mypage';
import { ApiCertLoading } from 'api/Cert';
import { ApiAuthEditValidator } from 'api/Auth';
import { ApiPayAutobillLeave } from 'api/Pay';
import useConfirmModal from 'component/common/modal/useConfirmModal';

import Header from 'component/common/header/Header';
import Footer from 'component/common/footer/Footer';

import SubNav from "component/common/nav/SubNav";

import style from './style/Mypage.module.scss';

import img_login_naver_icon from 'assets/images/user/login_naver_icon.svg';
import img_login_kakao_icon from 'assets/images/user/login_kakao_icon.svg';
import img_login_google_icon from 'assets/images/user/login_google_icon.svg';
import img_login_facebook_icon from 'assets/images/user/login_facebook_icon.svg';
import img_login_apple_icon from 'assets/images/user/login_apple_icon.svg';
import useAlertModal from 'component/common/modal/useAlertModal';
import { SetState } from 'utils/State';
import useConfirmCustomModal from 'component/common/modal/useConfirmCustomModal';

function getSocialIcon(social){
    switch(social){
        case "naver": return img_login_naver_icon;
        case "kakao": return img_login_kakao_icon;
        case "google": return img_login_google_icon;
        case "facebook": return img_login_facebook_icon;
        case "apple": return img_login_apple_icon;
        default: return null;
    }
}


function MypageEdit(){


    const navigate = useBookpalNavigate();
    const location = useLocation();
    const setState = SetState();
    const [myInfo, setMyInfo] = useState(null);
    const [id, setId] = useState("");
    const [nickname, setNickname] = useState("");
    const [email, setEmail] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    // const [phone, setPhone] = useState("");
    const [isSubmitDisable, setIsSubmitDisable] = useState(true);

    const confirmEdit = location.state?.confirmEdit ?? null;
    const expire = location.state?.expire ?? null;
    const timestampInSeconds = Math.floor(Date.now() / 1000);

    const [AlertModal, alert] = useAlertModal();

    const [check, setCheck] = useState({
        nickname:"",
        email:"",
        currentPassword:null,
        password:null,
        passwordConfirm:null,
        isSubmitDisable:true,
        valid:null,
    });

    const [ConfirmCustomModal, confirm] = useConfirmCustomModal();

    // const title = data?.title || false;
    // const content = data?.content || false;
    // const btnTitle = data?.btnTitle || "확인";
    // const action = data?.action || (()=>{});
    const [ConfirmModal, showConfirmModal] = useConfirmModal({
        title:"본인인증확인",
        content:"전화번호 수정은\n본인명의로 된 전화번호만 가능합니다.\n본인인증을 진행하시겠습니까?",
        btnTitle:"진행",
        action:()=>{
            // alert("진행");
            handlerCert();
        }
    });

    const autobillLeaveSubmit = ApiPayAutobillLeave({
        onSuccess:(data)=>{
            if(data?.status === true){
                alert("결제",data?.result?.msg,()=>{
                    mypageInfoSubmit();
                });
            }
        },
        onError:(e)=>{

        }
    });

    const handlerAutobillLeave = () => {
        confirm({
            title:myInfo?.auto_charge ? "정기충전 해지":"스마트충전 해지",
            content:"정기충전을 해지하시겠습니까?\n해지내역은 쿠폰내역>충전내역에서\n확인하실 수 있습니다.",
            successBtnTitle:"해지하기",
            successAction:()=>{
                autobillLeaveSubmit({});
            }
        })
    }

    const mypageInfoUpdate = ApiMypageUpdateMyInfoNew({
        onSuccess:(data)=>{
            if(data?.status === true){
                alert("내정보 수정","수정되었습니다.",()=>{
                    navigate("/mypage",{replace:true});
                    setState.delMemberInfo();
                });
                
                return;
            }
            switch(data?.code){
                case "error_email_duplicate":
                    alert("내정보 수정","수정을 실패하였습니다.\n이메일이 중복되었습니다.");
                    break;
                case "error_email_format":
                    alert("내정보 수정","수정을 실패하였습니다.\n이메일 형식이 잘못되었습니다.");
                    break;
                case "error_nickname_duplicate":
                    alert("내정보 수정","수정을 실패하였습니다.\n닉네임이 중복되었습니다.");
                    break;
                case "error_nickname_format":
                    alert("내정보 수정","수정을 실패하였습니다.\n닉네임 형식이 잘못되었습니다.");
                    break;
                case "error_nickname_length":
                    alert("내정보 수정","수정을 실패하였습니다.\n닉네임은 2글자 이상, 8자리 미만의 한글/영문/숫자로 입력해 주세요");
                    break;
                case "error_current_password":
                    alert(data?.message);
                    setCheckValue("currentPassword",data?.message);
                case "error_password":
                    alert(data?.message);
                    break;
                default:
                    alert("내정보 수정","수정에 실패하였습니다.");
                    break;
            }
            
        },
        onError:(e)=>{
            alert("내정보 수정","수정에 실패하였습니다.");
        }
    });

    const mypageInfoUpdateSubmit = ()=>{
        mypageInfoUpdate({
            // mb_id: "example@bookp.al",
            nickname: nickname,
            email: email,
            current_password: currentPassword,
            password: password,
            password_check: passwordConfirm,
        });
    }
    
    const mypageInfoSubmit = ApiMypageInfoEdit({
        onSuccess:(data)=>{
            if(data?.status === true){
                setMyInfo(data?.result);
                setId(data?.result?.mb_id);
                setNickname(data?.result?.nickname);
                setEmail(data?.result?.email);

                return;
            }
        },
        onError:(e)=>{

        }
    });

    const certLoadingSubmit = ApiCertLoading({
        returnPath:"/mypage/edit"
    });

    const handlerCert = ()=>{
        certLoadingSubmit();
    }

    const handlerMoveEditSns = () => {
        navigate("/mypage/editsns");
    };

    const handlerMoveUnRegist = () => {
        navigate("/mypage/unregist");
    };

    const setCheckValue = (key, value)=>{
        setCheck({
            ...check,
            [key]:value,
        })
    }

    const checkSubmit = ApiAuthEditValidator({
        onSuccess:(data)=>{
            if(data.status == false && data.code == "201"){
                setCheckValue(check?.valid, data?.message);
                return;
            }
            setCheckValue(check?.valid, "");
        },
        onError:()=>{}
    });

    const setInputData = (e, type)=>{
        const value = e.target.value;
        switch(type){
            case "nickname":
                setNickname(value);
                if(value === ""){
                    setCheckValue(type,"닉네임을 입력해주세요.");
                } else if(!/^[a-zA-Z가-힣0-9_-]+$/.test(value)){
                    setCheckValue(type,"2글자 이상, 8자리 미만의 한글/영문/숫자로 입력해주세요.");
                } else if(value.length < 2 || value.length >= 8){
                    setCheckValue(type,"2글자 이상, 8자리 미만의 한글/영문/숫자로 입력해주세요.");
                } else {
                    setCheckValue(type,"");
                    setCheckValue("valid",type);
                    checkSubmit({
                        operation:type,
                        value:value,
                    });
                }
                break;
            case "email":
                setEmail(value);
                if(value === ""){
                    setCheckValue(type,"이메일을 입력해주세요.");
                } else if(!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)){
                    setCheckValue(type,"이메일 형식이 잘못되었어요.");
                } else {
                    setCheckValue(type,"");
                    setCheckValue("valid",type);
                    checkSubmit({
                        operation:type,
                        value:value,
                    });
                }
                break;
            case "currentPassword":
                setCurrentPassword(value);
                // if(value === ""){
                //     setCheckValue(type,"비밀번호를 입력해주세요.");
                //     return;
                // }
                if(value === "" && password !== "" && password !== null){
                    setCheckValue(type,"비밀번호를 입력해주세요.");
                    return;
                }
                setCheckValue(type,"");
                break;
            case "password":
                setPassword(value);
                if(value === ""){
                    setCheckValue(type,"비밀번호를 입력해주세요.");
                } else if(value.length < 8 || value.length > 20){
                    setCheckValue(type,"비밀번호는 8~20자로 입력해주세요.");
                } else {
                    // 패턴 체크: 영문 대문자, 소문자, 숫자, 특수문자 중 3종류 이상
                    const regexUpperCase = /[A-Z]/;
                    const regexLowerCase = /[a-z]/;
                    const regexNumber = /[0-9]/;
                    const regexSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

                    let typesCount = 0;
                    if (regexUpperCase.test(value)) typesCount++;
                    if (regexLowerCase.test(value)) typesCount++;
                    if (regexNumber.test(value)) typesCount++;
                    if (regexSpecialChar.test(value)) typesCount++;

                    if (typesCount < 3) {
                        setCheckValue(type,"비밀번호는 8~20자의 영문 대/소문자, 숫자, 특수문자 등 3종류 이상으로 조합해 주세요.");
                        return;
                    }
                    setCheckValue(type,"");
                }
                break;
            case "passwordConfirm":
                setPasswordConfirm(value);
                if(password != value){
                    //낭낭님 요청 -https://flow.team/l/b2J1t 비밀번호가 맞지 않습니다 적용 ( 기존 : 비밀번호가 일치하지 않습니다. )
                    setCheckValue(type,"비밀번호가 맞지 않습니다");
                } else {
                    setCheckValue(type,"");
                }
                break;
        };

        
    }

    useEffect(()=>{
        
        if(confirmEdit !== true){
            navigate("/confirm_pw",{state:"/mypage/edit"});
            return;
        }
        if(expire < timestampInSeconds){
            navigate("/confirm_pw",{state:"/mypage/edit"});
            return;
        }
        mypageInfoSubmit();
    },[]);

    useEffect(()=>{

        // console.log(myInfo)

        if(
            nickname == myInfo?.nickname
            && email == myInfo?.email
            && currentPassword === ""
            && password === ""
            && passwordConfirm === ""
        ){
            setIsSubmitDisable(true);
            return;
        }

        if(password !== "" || currentPassword !== ""){
            if(
                check?.nickname === "" 
                && check?.email === "" 
                && check?.currentPassword === "" 
                && check?.password === "" 
                && check?.passwordConfirm === "" 
            ){
                setIsSubmitDisable(false);
            } else {
                setIsSubmitDisable(true);
            }
        } else {
            if(
                check?.nickname === "" 
                && check?.email === ""
            ){
                setIsSubmitDisable(false);
            } else {
                setIsSubmitDisable(true);
            }
        }
        
    },[check, nickname, email, password, currentPassword, passwordConfirm]);

    if(confirmEdit !== true){
        return <></>
    }

    if(expire < timestampInSeconds){
        return <></>
    }


    if(myInfo === null){
        return (
            <></>
        )
    }

    return (
        <>
            <Header useMobile={false} pcTop={"-64px"} />
            <SubNav 
                name={"내정보 수정"}
                usePc={true} 
                useMobile={true} 
                usePcRightBtns={true}
                useBtnPrev={true}
                useBtnPcPrev={true}
                useBtnHome={true}

                onPrev={()=>{
                    navigate("/mypage", {replace: true});
                }}

                setTop={'0px'} 
                setPcTop={'0px'} 
                />
            {/* <!-- container --> */}
            <div id="container" >
                <section className={"edit_box wrapper " + style['edit_box'] + " " + style['wrapper']}>
                    <div className={"section basis_info " + style['section'] + " " + style['basis_info']}>
                        <div className={"tit_con title-02-b color-txt-normal " + style['tit_con']}>기본정보</div>
                        <div className={"list " + style['list']}>
                            <ul className={"input_box " + style['input_box']}>
                                <li className={"readonly_txt " + style['readonly_txt']}>
                                    <div className={"text_input border-line border-soild radius-sm color-bg-02 color-border-01 " + style['text_input']}>
                                        <p className={"text_tit body-03 color-txt-03 " + style['text_tit']}><span>아이디</span></p>
                                        <span className={"sns_logo " + style['sns_logo']}>
                                            {
                                                (myInfo?.provider && myInfo?.provider != "default") ? <img src={getSocialIcon(myInfo?.provider)} /> : null
                                            }
                                            
                                            {/* <!-- <img src="/user/images/login_kakao_icon.svg">
                                            <img src="/user/images/login_google_icon.svg">
                                            <img src="/user/images/login_facebook_icon.svg">
                                            <img src="/user/images/login_apple_icon.svg"> --> */}
                                        </span>
                                        <input 
                                            type="text" 
                                            className="color-txt-muted body-03" 
                                            value={id}
                                            onChange={(e)=>setId(e.target.value)}
                                            readOnly={true} disabled />
                                    </div>
                                </li>
                                {/* <!-- 잘못되었을때 error_text 추가 --> */}
                                <li className={check?.nickname !== null && check?.nickname != "" ? "error_text":""}>
                                    <div className={"text_input border-line border-soild radius-sm color-bg-01 color-border-02 " + style['text_input']}>
                                        <p className={"text_tit body-03 color-txt-03 " + style['text_tit']}><span className={"essential_tit " + style['essential_tit']}>닉네임</span></p>
                                        <input 
                                            type="text" 
                                            placeholder="닉네임을 입력해주세요." 
                                            value={nickname}
                                            onChange={(e)=>{setInputData(e, "nickname")}}
                                            className="color-txt-normal body-03" />
                                        {nickname !== "" ? 
                                        <p className={"right_btns " + style['right_btns']}>
                                            <span 
                                                className={"btnClear " + style['btnClear']} 
                                                style={{display:"block"}}
                                                onClick={()=>{setNickname("");setCheckValue("nickname",null);}}
                                                ></span> 
                                            <span className={"Error " + style['Error']}></span>
                                        </p>
                                        :null}
                                    </div>
                                    <p className={"bottom_txt caption-02 color-txt-05 " + style['botton_txt']}>{check?.nickname}</p>
                                </li>
                                <li className={check?.email !== null && check?.email != "" ? "error_text":""}>
                                    <div className={"text_input border-line border-soild radius-sm color-bg-01 color-border-02 " + style['text_input']}>
                                        <p className={"text_tit body-03 color-txt-03 " + style['text_tit']}><span className={"essential_tit " + style['essential_tit']}>이메일</span></p>
                                        <input 
                                            type="email" 
                                            placeholder="이메일을 입력해주세요." 
                                            value={email}
                                            onChange={(e)=>setInputData(e, "email")}
                                            className="color-txt-normal body-03" />
                                        {email !== "" ?
                                        <p className={"right_btns " + style['right_btns']}>
                                            <span 
                                                className={"btnClear " + style['btnClear']} 
                                                style={{display:(email != "" ? "block":"none")}}
                                                onClick={()=>{setEmail("");setCheckValue("email",null);}}
                                                ></span>
                                            <span className="Error"></span>
                                        </p>  
                                        :null}
                                    </div>
                                    <p className={"bottom_txt caption-02 color-txt-05 " + style['bottom_txt']}>{check?.email}</p>
                                </li>
                                <li className={check?.currentPassword !== null && check?.currentPassword !== "" ? "error_text":""}>
                                    <div className={"text_input border-line border-soild radius-sm color-bg-01 color-border-02 " + style['text_input']}>
                                        <p className={"text_tit body-03 color-txt-03 " + style['text_tit']}><span>현재 비밀번호</span></p>
                                        <input 
                                            type="password" 
                                            placeholder="수정시에만 입력해주세요." 
                                            value={currentPassword}
                                            onChange={(e)=>setInputData(e, "currentPassword")}
                                            className="color-txt-normal body-03" />
                                        <p className={"right_btns " + style['right_btns']}>
                                            <span className={"btnClear " + style['btnClear']}></span>
                                            <span className="Error"></span>
                                        </p>
                                    </div>
                                    <p className={"bottom_txt caption-02 color-txt-05 " + style['bottom_txt']}>{check?.currentPassword}</p>
                                </li>
                                <li className={check?.password !== null && check?.password != "" ? "error_text":""}>
                                    <div className={"text_input border-line border-soild radius-sm color-bg-01 color-border-02 " + style['text_input']}>
                                        <p className={"text_tit body-03 color-txt-03 " + style['text_tit']}><span>새 비밀번호</span></p>
                                        <input 
                                            type="password" 
                                            placeholder="수정시에만 입력해주세요." 
                                            value={password}
                                            onChange={(e)=>setInputData(e, "password")}
                                            className="color-txt-normal body-03" />
                                        <p className={"right_btns " + style['right_btns']}>
                                            <span className={"btnClear " + style['btnClear']}></span>
                                            <span className="Error"></span>
                                        </p>
                                    </div>
                                    <p className={"bottom_txt caption-02 color-txt-05 " + style['bottom_txt']}>{check?.password}</p>
                                </li>
                                <li className={check?.passwordConfirm !== null && check?.passwordConfirm != "" ? "error_text":""}>
                                    <div className={"text_input border-line border-soild radius-sm color-bg-01 color-border-02 " + style['text_input']}>
                                        <p className={"text_tit body-03 color-txt-03 " + style['text_tit']}><span>비밀번호 확인</span></p>
                                        <input 
                                            type="password" 
                                            placeholder="수정시에만 입력해주세요." 
                                            value={passwordConfirm}
                                            onChange={(e)=>setInputData(e, "passwordConfirm")}
                                            className="color-txt-normal body-03" />
                                        <p className={"right_btns " + style['right_btns']}>
                                            <span className={"btnClear " + style['btnClear']}></span>
                                            <span className="Error"></span>
                                        </p>
                                    </div>
                                    <p className={"bottom_txt caption-02 color-txt-05 " + style['bottom_txt']}>{check?.passwordConfirm}</p>
                                </li>
                                {myInfo?.auth === true ?
                                    <>
                                        {/* <!-- 본인인증 완료 --> */}
                                        <li style={{display: ""}}>
                                            <div className={"flex " + style['flex']}>
                                                <div className={"text_input border-line border-soild radius-sm color-bg-01 color-border-02 " + style['text_input']}>
                                                    <p className={"text_tit body-03 color-txt-03 " + style['text_tit']}><span>전화번호</span></p>
                                                    <p className={"input_text color-txt-normal body-03 " + style['input_text']}>{myInfo?.phone}</p>
                                                </div>
                                                <button 
                                                    type="button" 
                                                    className={"button btn_01 medium outlined label_on radius-full " + style['button']}
                                                    onClick={()=>{showConfirmModal();}}
                                                    ><span className={"color-btn-01 txt btn-04 " + style['txt']}>변경</span></button>
                                            </div>
                                        </li>
                                    </>
                                    : 
                                    <>
                                        {/* <!-- 본인인증 전 --> */}
                                        <li>
                                            <div 
                                                className={"text_input border-line border-soild radius-sm color-bg-01 color-border-02 " + style['text_input']}
                                                onClick={()=>{showConfirmModal();}}
                                                >
                                                <p className={"text_tit body-03 color-txt-03 " + style['text_tit']}><span>전화번호</span></p>
                                                <p className={"input_text color-txt-muted body-03 " + style['input_text']}>인증이 필요합니다.</p>
                                            </div>
                                        </li>
                                    </>
                                }
                                
                                
                                {/* <!-- 본인인증 완료후 변경버튼 눌렀을때 --> */}
                                {/* <!-- 잘못되었을때 error_text 추가 --> */}
                                <li className="" style={{display: "none"}}>
                                    <div className={"flex " + style['flex']}>
                                        <div className={"text_input border-line border-soild radius-sm color-bg-01 color-border-02 " + style['text_input']}>
                                            <input 
                                                type="text" 
                                                placeholder="전화번호 입력" 
                                                className="color-txt-normal body-03" 

                                                />
                                            <p className={"right_btns " + style['right_btns']}>
                                                <span className={"btnClear " + style['btnClear']}></span> 
                                                <span className="Error"></span>
                                            </p>
                                            <span className={"time caption-02 color-txt-05 " + style['time']}>03:00</span>
                                        </div>
                                        {/* <!-- 인증번호 발송 --> */}
                                        <button type="button" className={"button btn_01 medium filled label_on radius-full btn-04 " + style['button']} disabled="disabled">인증번호 발송</button>
                                        {/* <!-- 인증하기 --> */}
                                        {/* <!-- <button type="button" className="button btn_01 medium filled label_on radius-full btn-04">인증하기</button> --> */}
                                        {/* <!-- 재발송 --> */}
                                        {/* <!-- <button type="button" className="button btn_01 medium filled label_on radius-full btn-04">재발송</button> --> */}
                                    </div>
                                    <p className={"bottom_txt caption-02 color-txt-05 " + style['bottom_txt']}>인증번호를 잘못 입력하셨습니다.</p>
                                </li>
                            </ul>
                            {/* <!-- 비활성화 시 disabled="disabled"--> */}
                            <button 
                                type="button" 
                                id="completedBtn" 
                                className={"button btn_01 filled large label_on radius-sm btn-04 " + style['button'] + " " + style['completedBtn']} 
                                disabled={isSubmitDisable ?? false}
                                onClick={mypageInfoUpdateSubmit}
                                >수정완료</button>
                        </div>
                    </div>
                    <div className={"section side_info " + style['section'] + " " + style['side_info']} style={{paddingTop:"0",margin:"0"}}>
                        <div className={"tit_con title-02-b color-txt-normal " + style['tit_con']}>부가정보</div>
                        <div className={"list " + style['list']}>
                            <ul className={"input_box " + style['input_box']}>
                                <li>
                                    <div className={"text_input " + style['text_input']}>
                                        <p className={"text_tit body-03 color-txt-03 " + style['text_tit']}><span>본인인증</span></p>
                                        <p className={"input_text " + style['input_text']}>
                                            {
                                                myInfo?.auth === true ? <span className="color-btn-01 txt body-03">인증완료</span>
                                                : <button 
                                                type="button" 
                                                id="certifyBtn" 
                                                className={"button btn_01 medium outlined label_on radius-full " + style['certifyBtn'] + " " + style['button'] + " " + style['label_on']}
                                                onClick={()=>{handlerCert();}}
                                                ><span className="color-btn-01 txt btn-03">인증하기</span></button>
                                            }
                                            
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className={"text_input " + style['text_input']}>
                                        <p className={"text_tit body-03 color-txt-03 " + style['text_tit']}><span>자동충전상품</span></p>
                                        <p className={"input_text " + style['input_text']}>
                                            {
                                                myInfo?.auto_charge === false && myInfo?.smart_charge === false ? <span className="color-btn-03-txt body-03">이용안함</span>
                                                : 
                                                <>
                                                    <span className="color-btn-01 txt body-03">{myInfo?.charge_text}</span>
                                                    {myInfo?.leave_status || myInfo?.smart_charge ? 
                                                        <button 
                                                        type="button" 
                                                        className="button btn_01 medium outlined label_on radius-full"
                                                        onClick={()=>{
                                                            handlerAutobillLeave();
                                                        }}
                                                        ><span className="color-btn-01 txt btn-03">해지하기</span></button>
                                                    :null}
                                                    
                                                </>
                                            }
                                        </p>
                                    </div>
                                </li>
                            </ul>
                            {myInfo?.provider !== 'kakao' && myInfo?.provider !== 'apple' ? 
                            <div className={"account_button " + style['account_button']}>
                                <button 
                                    type="button" 
                                    className={"button btn_03 outlined large label_on round radius-sm " + style['button']}
                                    onClick={handlerMoveEditSns}
                                    ><span className="btn-04 color-txt-04">SNS 계정 연동</span></button>
                            </div>
                            :null}
                        </div>
                    </div>
                    
                    <div className={"cancel_button bottom_button section " + style['cancel_button'] + " " + style['bottom_button'] + " " + style['section']}>
                        <button 
                            tyep="button" 
                            className="button"
                            onClick={handlerMoveUnRegist}
                            ><span className="btn-04 color-btn-03-txt">회원탈퇴</span></button>
                    </div>
                    
                </section>
            </div>
            {/* <!-- container --> */}

            <Footer />
            <ConfirmModal />
            <ConfirmCustomModal />
            <AlertModal />
        </>
    );
}




export default MypageEdit;