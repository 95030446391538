import { useLocation, Link } from 'react-router-dom';
import { useIsMobile } from 'utils/Common';
import BookpalLink from 'component/common/custom/BookpalLink';

import style from '../style/Contents.module.scss';

function SubTabNav(props){

    const type = props?.type || "";
    const bid = props?.bid || "";
    const isFirst = props?.isFirst || false;
    const dotIcon = props?.dotIcon || false;
    

    const location = useLocation();
    const isMobile = useIsMobile();

    const navStyle = isMobile ? {position:"sticky",top:"48px",zIndex:"2"} : {};

    // console.log(location.pathname === "/content/"+type+"/"+bid+"first");

    return (
        <>
            <div style={{position:"relative"}}>
                <div id="tab_list" style={{position:"absolute",top:"-48px"}}></div>
            </div>
            <ul className={"tab_list menu_tab large color-bg-01 " + style['tab_list'] + " " + style['menu_tab']} style={navStyle}>
                {isFirst ? 
                    <li 
                        className="color-txt-muted btn-04"
                        ><BookpalLink 
                            to={"/content/"+type+"/"+bid+"/first"} 
                            replace={true}
                            className={"border border-soild border-bottom-line " + (location.pathname === "/content/"+type+"/"+bid+"/first"?"active":"")}
                            >첫 화 보기</BookpalLink></li>
                :null}
                <li className="color-txt-muted btn-04">
                    <BookpalLink 
                    to={"/content/"+type+"/"+bid+"/episode"} 
                    replace={true}
                    className={"border border-soild border-bottom-line " + (location.pathname === "/content/"+type+"/"+bid+"/episode"?"active":"")}
                    >
                        {dotIcon?.is_chapter ?
                            <span className={"dot color-btn-01 bg radius-full " + style['dot']}></span>
                        :null}
                        회차
                    </BookpalLink>
                </li>
                <li className="color-txt-muted btn-04">
                    <BookpalLink 
                        to={"/content/"+type+"/"+bid+"/intro"} 
                        replace={true}
                        className={"border border-soild border-bottom-line " + (location.pathname === "/content/"+type+"/"+bid+"/intro"?"active":"")}
                        >
                            {dotIcon?.is_intro ?
                                <span className={"dot color-btn-01 bg radius-full " + style['dot']}></span>
                            :null}
                            소개
                    </BookpalLink>
                </li>
                <li className="color-txt-muted btn-04">
                    <BookpalLink 
                        to={"/content/"+type+"/"+bid+"/news"} 
                        replace={true}
                        className={"border border-soild border-bottom-line " + (location.pathname === "/content/"+type+"/"+bid+"/news"?"active":"")}
                        >
                            {dotIcon?.is_notice ?
                                <span className={"dot color-btn-01 bg radius-full " + style['dot']}></span>
                            :null}
                            소식
                    </BookpalLink>
                </li>
                <li className="color-txt-muted btn-04">
                    <BookpalLink 
                        to={"/content/"+type+"/"+bid+"/comment"} 
                        replace={true}
                        className={"border border-soild border-bottom-line " + (location.pathname === "/content/"+type+"/"+bid+"/comment"?"active":"")}
                        >
                            {dotIcon?.is_comment ?
                                <span className={"dot color-btn-01 bg radius-full " + style['dot']}></span>
                            :null}
                            댓글
                    </BookpalLink>
                </li>
            </ul>
            
        </>
    );
}



export default SubTabNav;