import { useApiMutation, useApiMutationCommentReplyPaging, useApiMutationPaging } from './common/ApiCommon';

export const ApiCommentCommentDelete = (options) => {

    const subUrl = "/comment/comment_delete";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiCommentReplyWrite = (options) => {

    const subUrl = "/comment/reply_write";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};


export const ApiCommentCommentWrite = (options) => {

    const subUrl = "/comment/comment_write";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiCommentCommentLike = (options) => {

    const subUrl = "/comment/comment_like";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiCommentReplyLike = (options) => {

    const subUrl = "/comment/reply_like";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};


export const ApiCommentReplyDelete = (options) => {

    const subUrl = "/comment/reply_delete";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};


export const ApiCommentCommentList = (options) => {
    const subUrl = "/comment/comment_list";
    const data = options?.data || {};
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});
    const bottomFn = options?.bottomFn || (()=>{});
    const bottomRef = options?.bottomRef || (()=>{});
    const perPage = options?.perPage || 30;
    const pageName = options?.pageName || "page";
    const perPageName = options?.perPageName || "per_page";

    return useApiMutationPaging(subUrl, data, successFn, errorFn, bottomFn, perPage, pageName, perPageName, bottomRef);
};

export const ApiCommentReplyList = (options) => {
    const subUrl = "/comment/reply_list";
    const data = options?.data || {};
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});
    const bottomFn = options?.bottomFn || (()=>{});
    const bottomRef = options?.bottomRef || (()=>{});
    const perPage = options?.perPage || 30;
    const pageName = options?.pageName || "page";
    const perPageName = options?.perPageName || "per_page";

    return useApiMutationCommentReplyPaging(subUrl, data, successFn, errorFn, bottomFn, perPage, pageName, perPageName, bottomRef);
};
