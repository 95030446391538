import { useEffect } from 'react';
import style from '../style/Order.module.scss';



function ComponentCoupon(props){

    const data = props.data;
    const prodCode = props.prodCode;
    const setProdCode = props.setProdCode || (()=>{});
    const setSelectProdIapCode = props.setSelectProdIapCode || (()=>{})
    const setPrice = props.setPrice;

    const handlerSelect = () => {
        setProdCode(data?.product_code);
        setPrice(data?.price);
        if(data?.product_iap_code){
            setSelectProdIapCode(data?.product_iap_code);
        }
    }

    return (
        <li>
            {/* <!-- Default --> */}
            <button 
                type="button" 
                className={"click button color-border-01 radius-sm " + style['button'] + " " + (prodCode === data.product_code ? style['click'] : "") }
                onClick={handlerSelect}>
                <div className={"left " + style['left']}>
                    <div className={"coupon_num title-book-04-m color-txt-normal " + style['coupon_num']}>
                        쿠폰 <span className={"num line " + style['num'] + " " + (data?.b_coupon > 0 ? style['line']:"")}>{data?.o_coupon}개</span>
                        {data?.b_coupon > 0 ? <span className={"red_num color-btn-01 txt title-book-04-b " + style['red_num']}>{data?.coupon}개</span> : null}
                        {data?.best ? 
                        <div className={"badge_list " + style['badge_list']}>
                            <p className="badge best_badge color-btn-01 bg border border-soild border-line radius-full">
                                <span className="white-always txt">Best</span>
                            </p>
                        </div> : null}
                    </div>
                    {data?.b_per > 0 ? <div className="coupon_add body-01 color-txt-05">{data?.b_per}% 추가 쿠폰</div> : null}
                    
                </div>
                <div className={"right " + style['right']}>
                    <p className="money title-book-04-b color-txt-normal">{data?.price}원</p>
                </div>
            </button>
        </li>
    )
}



export default ComponentCoupon;

