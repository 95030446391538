import { Link } from 'react-router-dom';
import BookpalLink from 'component/common/custom/BookpalLink';
import { SetState, GetState } from 'utils/State';
import style from '../style/Contents.module.scss';
import PrevNextToonBtn from './footer_menu/PrevNextToonBtn';
import PrevNextNovelBtn from './footer_menu/PrevNextNovelBtn';
import PrevNextEpubBtn from './footer_menu/PrevNextEpubBtn';
import { useState } from 'react';
import useConfirmCustomModal from 'component/common/modal/useConfirmCustomModal';


function FooterMenu({data, type, bid, cid, alert, buyConfirm, runState}){


    const setState = SetState();
    const getState = GetState();
    
    const viewerImageNone = getState.useGetViewerInfoImageNone();
    const viewerRun = getState.useGetViewerInfoRun();

    const [initRunCheck, setInitRunCheck] = useState(false);

    const [ConfirmModal, confirm] = useConfirmCustomModal();

    // console.log("data", data);

    const toggleViewerImage = ()=>{
        setState.setViewerInfoImageNone(!viewerImageNone);
    }

    const toggleViewerRun = ()=>{
        if(viewerRun === false && initRunCheck === false){

            confirm({
                title:"정주행 안내",
                content:"자동으로 다음 회차를 감상합니다.\n선물 이용권과 선물소장권이 우선 사용되며,\n소진 이후 보유하신 쿠폰이 사용됩니다.",
                info:"웹툰은 선물권 소진 후 쿠폰으로 자동 소장",
                successAction:()=>{
                    setState.setViewerInfoRun(true);
                    setInitRunCheck(true);

                    setState.setToast({
                        title:"정주행 모드로 전환되었습니다.",
                    });
                }
            })
            return;
        }

        setState.setViewerInfoRun(!viewerRun);

        if(!viewerRun){
            setState.setToast({
                title:"정주행 모드로 전환되었습니다.",
            });
        } else {
            setState.setToast({
                title:"정주행 모드가 종료되었습니다.",
            });
        }

        
    }


    

    return (
        <>
        <ul>
            {type === "toon"? <></>
            :
            <li>
                <BookpalLink onClick={()=>{toggleViewerImage();}}>
                    {/* <!-- 비활성화시 disabled 추가 --> */}
                    <p className={"icon img " + style['icon'] + " " + style['img'] + " " + (viewerImageNone ? style['disabled']:"")}>이미지</p>
                    {/* <!-- 활성화시 on / 비활성화시 off--> */}
                    <p className={"label state on " + style['label'] + " " + style['state'] + " " + (viewerImageNone ? style['off'] : style['on'])}></p>
                </BookpalLink>
            </li>
            }
            <li>
                <BookpalLink onClick={()=>{toggleViewerRun();}}>
                    {/* <!-- 비활성화시 disabled 추가 --> */}
                    <p className={"icon run disabled " + style['icon'] +" " + style['run'] + " " + (viewerRun ? "":style['disabled'])}>정주행</p>
                    {/* <!-- 활성화시 on / 비활성화시 off--> */}
                    <p className={"label state off " + style['label'] + " " + style['state'] + " " + (viewerRun ? style['on'] : style['off'])}></p>
                </BookpalLink>
            </li>
            <li>
                <BookpalLink to={"/view/"+type+"/"+cid+"/comment"}>
                    <p className={"icon chat " + style['icon'] + " " + style['chat']}>댓글</p>
                    <p className={"label num radius-full " + style['label'] + " " + style['num']}>{data?.total_comment}</p>
                </BookpalLink>
            </li>
            <li>
                {type === "toon" ? 
                <PrevNextToonBtn 
                    data={data}
                    type={type}
                    bid={bid}
                    cid={cid}
                    prevOrNext={"prev"}
                    alert={alert}
                    buyConfirm={buyConfirm}
                    runState={runState}
                />
                :null}
                {type === "novel" ? 
                <PrevNextNovelBtn 
                    data={data}
                    type={type}
                    bid={bid}
                    cid={cid}
                    prevOrNext={"prev"}
                    alert={alert}
                    buyConfirm={buyConfirm}
                    runState={runState}
                />
                :null}
                {type === "epub" ? 
                <PrevNextEpubBtn 
                    data={data}
                    type={type}
                    bid={bid}
                    cid={cid}
                    prevOrNext={"prev"}
                    alert={alert}
                    buyConfirm={buyConfirm}
                    runState={runState}
                />
                :null}
            </li>
            <li>
                {type === "toon" ? 
                <PrevNextToonBtn 
                    data={data}
                    type={type}
                    bid={bid}
                    cid={cid}
                    prevOrNext={"next"}
                    alert={alert}
                    buyConfirm={buyConfirm}
                    runState={runState}
                />
                :null}
                {type === "novel" ? 
                <PrevNextNovelBtn 
                    data={data}
                    type={type}
                    bid={bid}
                    cid={cid}
                    prevOrNext={"next"}
                    alert={alert}
                    buyConfirm={buyConfirm}
                    runState={runState}
                />
                :null}
                {type === "epub" ? 
                <PrevNextEpubBtn 
                    data={data}
                    type={type}
                    bid={bid}
                    cid={cid}
                    prevOrNext={"next"}
                    alert={alert}
                    buyConfirm={buyConfirm}
                    runState={runState}
                />
                :null}
            </li>
        </ul>
        <ConfirmModal />
        </>
    )
}



export default FooterMenu;