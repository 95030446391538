import { useEffect, useRef, useState } from 'react';
import { useIsMobile } from 'utils/Common';
import { ApiBookUseDetail } from 'api/Book';
import { useParams, useNavigate } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import { Link } from 'react-router-dom';
import BookpalLink from 'component/common/custom/BookpalLink';
import { runPopupOrder } from 'utils/Common';
import style from './style/Contents.module.scss';

import Header from 'component/common/header/Header';
import SubNav from 'component/common/nav/SubNav';
import Midnight from './component/promotion/Midnight';
import PlusFree from './component/promotion/PlusFree';
import PlusOne from './component/promotion/PlusOne';
import Bind from './component/promotion/Bind';
import Gift from './component/promotion/Gift';
import Hotdeal from './component/promotion/Hotdeal';
import Review from './component/promotion/Review';
import EpubHotdeal from './component/promotion/EpubHotdeal';
import useAlertModal from 'component/common/modal/useAlertModal';


function ContentsDetailGift(){

    const isMobile = useIsMobile();
    const navigate = useBookpalNavigate();

    const { type, bid } = useParams();

    const contentRef = useRef(null);

    const [promotion, setPromotion] = useState(null);
    const [voucherInfo, setVoucherInfo] = useState(null);

    const [AlertModal, alert] = useAlertModal();

    const [data, dataSubmit, initData, paging, setData] = ApiBookUseDetail({
        data:{
            bid:bid,
            ct_type:type,
        },
        bottomRef:contentRef,
        onSuccess:(_data)=>{
            if(_data?.status === true){
                if(_data?.result?.promotion){
                    setPromotion(_data?.result?.promotion);
                }
                if(_data?.result?.voucher_info){
                    setVoucherInfo(_data?.result?.voucher_info);
                }
            }
        },
    });

    const handlerReload = ()=>{
        initData();
        dataSubmit();
    }

    useEffect(()=>{
        dataSubmit();
    },[])

    return (
        <>
            
            
            <div id="container" className={" " + style['container']} >
                <section 
                    className={"section detail_box wrapper clearfix template " + style['detail_box'] + " " + style['section'] + " " + style['template'] + " " + style['wrapper']}
                    style={{display:"block"}}
                    >
                    <div className={"my_coupon " + style['my_coupon']}>
                        <div className={"area top_con radius-sm color-bg-select-01 " + style['area'] + " " + style['top_con']}>
                            <div className={"left " + style['left']}>
                                <img src="/images/coupon_color.svg" />
                                <span className="title-01-b color-txt-normal">내 쿠폰</span>
                            </div>
                            <div className={"right " + style['right']}>
                                <p className={"num title-03-m color-txt-normal " + style['num']}>
                                    <span className="title-03">{voucherInfo?.coupon_cnt}</span>개
                                </p>
                                {/* <button 
                                    type="button" 
                                    className={"button btn_01 label_on filled medium radius-full " + style['button']}
                                    onClick={(e)=>{
                                        runPopupOrder(e);
                                    }}
                                    >
                                    <span className="btn-03 white-always txt">충전하기</span>
                                </button> */}
                            </div>
                        </div>
                    </div>
                    <div className={"benefits_area " + style['benefits_area']}>
                        <ul className={"info_txt area radius-sm gold_opacity-10 bg " + style['info_txt'] + " " + style['area'] + " " + style['bg']}>
                            <li>
                                <p className={"left " + style['left']} >
                                    <span className="color-txt-02 title-sub-02-m">선물소장권</span>
                                </p>
                                <p className={"right " + style['right']}>
                                    <span className="color-txt-normal title-sub-02-m">{voucherInfo?.own_cnt ?? 0}장</span>
                                </p>
                            </li>
                            <li>
                                <p className={"left " + style['left']} >
                                    <span className="color-txt-02 title-sub-02-m">선물이용권</span>
                                </p>
                                <p className={"right " + style['right']}>
                                    <span className="color-txt-normal title-sub-02-m">{voucherInfo?.rent_cnt ?? 0}장</span>
                                </p>
                            </li>
                            {/* <!-- 벌써 12시 프로모션 --> */}
                            {promotion?.midnight ? 
                                <li>
                                    <Midnight 
                                        data={promotion?.midnight} 
                                        bid={bid}
                                        onAction={(message)=>{
                                            alert("벌써열두시?!",message, ()=>{
                                                handlerReload();
                                            });
                                        }}
                                            />
                                </li>
                            :null}
                            {/* <!-- 연재의 참맛(정주행의맛) 프로모션 --> */}
                            {promotion?.plusone ? 
                                <li>
                                    <PlusOne data={promotion?.plusone} />
                                </li>
                            :null}

                            {/* <!-- 선물함 이벤트 --> */}
                            {promotion?.gift ? 
                                    <Gift 
                                        data={promotion?.gift} 
                                        onAction={(count, voucher_type, msg )=>{

                                            alert("선물함",msg, ()=>{
                                                handlerReload();
                                            });

                                            return true;
                                        }}
                                        />
                            :null}

                            {/* <!-- 묶음이벤트 --> */}
                            {promotion?.bind ? 
                                <li>
                                    <Bind data={promotion?.bind} />
                                </li>
                            :null}

                            {/* <!-- 핫딜 --> */}
                            {promotion?.hotdeal ? 
                                
                                    <Hotdeal data={promotion?.hotdeal} />
                                
                            :null}

                            {/* <!-- 플러스무료 프로모션, 남은시간 데이터 필요 --> */}
                            {promotion?.plusfree ? 
                                <li>
                                    <PlusFree data={promotion?.plusfree} />
                                </li>
                            :null}

                            {/* <!-- 리뷰이벤트 --> */}
                            {promotion?.review ? 
                                <li>
                                    <Review data={promotion?.review} />
                                </li>
                            :null}
                            
                            {/* <!-- 단행본 할인 --> */}
                            {promotion?.epubdeal ? 
                                <li>
                                    <EpubHotdeal data={promotion?.epubdeal} />
                                </li>
                            :null}
                        </ul>
                    </div>
                    
                    <div className={"area_box history_list " + style['area_box'] + " " + style['history_list']}>
                        {/* <!-- 리스트 --> */}
                        {data && Array.isArray(data) && data?.length > 0 ? 
                        <>
                            <ul className="list">
                                {data.map((item,index)=>{

                                    return (
                                        <li className="border-soild border-bottom-line color-border-01">
                                            <BookpalLink 
                                                data-all-type={(item?.all_type === "all")}
                                                data-bid={item?.bid}
                                                data-type={item?.type}
                                                style={{cursor:"auto"}}
                                                onClick={(e)=>{
                                                    e.preventDefault();
                                                    const allType = e.currentTarget.getAttribute("data-all-type");
                                                    const bid = e.currentTarget.getAttribute("data-bid");
                                                    const type = e.currentTarget.getAttribute("data-type");
                                                    // const disabled = e.currentTarget.getAttribute("data-disabled");
                
                                                    // if(disabled === "true"){
                                                    //     return;
                                                    // }
                                                    // console.log(allType, bid, type);
                                                    if(bid != "" && type != ""){
                                                        //링크 사용 안함
                                                        // navigate("/content/"+type+"/"+bid);
                                                    }
                                                }}
                                                >
                                                <div className="left info_txt spacing_08">
                                                    <div className="list_tit_con">
                                                        <span className="title-01-b color-txt-normal text_overflow">{item?.all_type === "all" ? "전체" : item?.title}</span>
                                                    </div>
                                                    <div className="category body-02 color-txt-03 text_overflow">
                                                        {/* <!-- 작가명 들어갈때만 이 span 태그 존재 --> */}
                                                        {item?.comment} 
                                                        {" - "}
                                                        {item?.coupon_type === "own" ? "선물소장권":null} 
                                                        {item?.coupon_type === "rent" ? "선물이용권":null}
                                                    </div>
                                                    <div className="date body-01 color-txt-05">{item?.date_insert_half}</div>
                                                </div>
                                                <div className={"right " + style['right']}>
                                                <div className="title title-01-m color-txt-normal">
                                                    <span>{item?.nonuse_cnt === "0" ? item?.use_cnt :item?.is_expire ? 0 : item?.nonuse_cnt}</span>장
                                                </div>
                                                <div className="data color-btn-01 txt body-01">
                                                    {item?.nonuse_cnt === "0" ?
                                                        <div className="data color-txt-muted body-01">사용 <span>완료</span></div>
                                                        :
                                                            item?.is_expire ? <div className="data color-txt-muted body-01">유효기간 <span>만료</span></div> 
                                                            : <div className="data color-btn-01 txt body-01">유효기간 <span>{item?.date_expire}</span></div>
                                                    }
                                                </div>
                                                </div>
                                            </BookpalLink>
                                        </li>

                                        // <Link 
                                        // data-all-type={(item?.all_type === "all")}
                                        // data-bid={item?.bid}
                                        // data-type={item?.type}
                                        // data-disabled={isDisabled ? "true":"false"}
                                        // onClick={handlerClick}>
                                        // <div className="left info_txt spacing_08">
                                        //     <div className="list_tit_con">
                                        //         <span className={"title-01-b text_overflow " + (isDisabled ? "color-txt-muted" : "color-txt-normal" )}>{item?.all_type === "all" ? "전체" : item?.title}</span>
                                        //         <span className="icon"></span>
                                        //     </div>
                                        //     <div className={"category body-02 " + (isDisabled ? "color-txt-muted" : "color-txt-03") }>
                                        //         {item?.comment} 
                                        //         {" - "}
                                        //         {item?.coupon_type === "own" ? "선물소장권":null} 
                                        //         {item?.coupon_type === "rent" ? "선물이용권":null}</div>
                                        //     <div className={"date body-01 " + (isDisabled ? "color-txt-muted" : "color-txt-05")}>{item?.date_insert}</div>
                                        // </div>
                                        // <div className="right">
                                        //     <div className={"title title-01-m  " + (isDisabled ? "color-txt-muted" : "color-txt-normal")}><span>{item?.nonuse_cnt}</span>장</div>
                                        //     {item?.nonuse_cnt === "0" ?
                                        //         <div className="data color-txt-muted body-01">사용 <span>완료</span></div>
                                        //         :
                                        //             item?.is_expire ? <div className="data color-txt-muted body-01">유효기간 <span>만료</span></div> 
                                        //             : <div className="data color-btn-01 txt body-01">유효기간 <span>{item?.date_expire}</span></div>
                                        //     }
                                            
                                        // </div>
                                        // </Link>
                                    )
                                })}
                                
                                
                            </ul>
                            
                            {/* <!-- 맨 마지막지점에서 --> */}
                            <div className={"body-02 color-txt-muted data_info " + style['data_info']}>
                                최근 90일 까지의 데이터가 노출됩니다.
                            </div>
                        </>
                        :<div className="no_data medium color-txt-04 body-02-long">최근 90일 동안 받은혜택이 없습니다.</div>}
                        {/* <!-- 내역 없을때 --> */}
                        {/* <!-- <div className="no_data medium color-txt-04 body-02-long">최근 90일 동안 받은혜택이 없습니다.</div> --> */}
                    </div>
                    
                </section>
                

                
            </div>
            <AlertModal />
        </>
    )
}



export default ContentsDetailGift;