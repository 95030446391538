import { useState, useEffect } from 'react';
import { ImgBadge } from 'utils/Images';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import BookpalLink from 'component/common/custom/BookpalLink';
import { ApiBuyBuyCheck, ApiBuyBook } from 'api/Buy';
import { runPopupOrder } from 'utils/Common';
import { SetState, GetState } from 'utils/State';
import style from '../../style/Contents.module.scss';



function PrevNextEpubBtn({data, type, bid, cid, prevOrNext, alert, buyConfirm, runState}){


    const navigate = useBookpalNavigate();
    const location = useLocation();

    const setState = SetState();
    const getState = GetState();

    const viewerRun = getState.useGetViewerInfoRun();

    const epubData = (prevOrNext === "prev" ? data?.prev_chapter : data?.next_chapter);

    const [buyCheck, setBuyCheck] = useState(null);
    const [buyType, setBuyType] = useState(null);

    const buySubmit = ApiBuyBook({
        onSuccess:(_data)=>{
            if(_data?.status === true){
                getViewPageLink();
                // setState.setToast({
                //     title:buyCheck?.chapter_no+"권을 구매하였습니다.",
                // })
                setState.setToast({
                    title:_data?.result?.msg,
                })
                return;
            }
            switch(_data?.code){
                case "520":
                    buyConfirm({
                        title:"구매 안내",
                        bookTitle:buyCheck?.buy_info?.title,
                        content:buyCheck?.buy_info?.no + "권을 열람하시겠습니까?",
                        isPay:false,
                        currentConpon:_data?.result?.my_coupon_cnt,
                        useConpon:buyCheck?.buy_info?.price,
                        useVoucher:false,
                        successBtnTitle:"쿠폰 충전하러 가기",
                        cancleBtnTitle:"다음에 할게요",
                        successAction:()=>{
                            runPopupOrder();
                        },
                    });
                    break;
                case "513":
                    alert("구매 안내","무료 회차입니다.");
                    break;
                case "512":
                    alert("구매 안내","구매 가능한 회차가 없습니다.");
                    break;
                case "511":
                    alert("구매 안내","이미 구매한 회차입니다.");
                    break;
                default:
                    alert("구매 안내",_data?.message);
                    break;
            }
        },
        onError:(e)=>{

        }
    })

    const checkSubmit = ApiBuyBuyCheck({
        onSuccess:(_data)=>{
            if(_data?.status === true){
                const result = _data?.result;
                const buy_info = result?.buy_info;
                setBuyCheck(result);

                if(buy_info?.is_buy === true){
                    getViewPageLink();
                    return;
                }

                if(buy_info?.is_event_chapter_free === true){
                    getViewPageLink();
                    return;
                }

                if(buy_info?.is_free === true){
                    getViewPageLink();
                    return;
                }

                // if(viewerRun && runState && prevOrNext === "next"){
                if(viewerRun && prevOrNext === "next"){
                    
                    // setBuyType(buy_type);
                    buySubmit({
                        bid:data?.extra?.bid,
                        cid:epubData?.cid,
                        epub:true,
                        buy_type:"buy",
                    });
                    return;
                }
                // const my_voucher = result?.my_voucher;
                // const isPay = result?.my_coupon_cnt > 0;
                const useVoucher = buyConfirm({
                    title:"구매 안내",
                    bookTitle:buy_info?.title,
                    content:buy_info?.no + "권을 열람하시겠습니까?",
                    isPay:true,
                    currentConpon:buy_info?.user_coupon,
                    useConpon:buy_info?.price,
                    useVoucher:false,
                    successBtnTitle:(true?"소장하기":"확인"),
                    success2BtnTitle:"",
                    successAction:()=>{
                        console.log("useVoucher",useVoucher);
                        buySubmit({
                            bid:data?.extra?.bid,
                            cid:epubData?.cid,
                            epub:true,
                            buy_type:"buy",
                        });
                    },
                    success2Action:()=>{
                        // buySubmit({
                        //     bid:data?.bid,
                        //     cid:data?.cid,
                        //     epub:data?.epub,
                        //     buy_type:"v_rent",
                        // });
                    },
                });
                return;
            }
            switch(_data?.code){
                case "400":
                    alert("알림","책정보가 없습니다.");
                    break;
                case "406":
                    alert("알림","블라인드 작품입니다.");
                    break;
                case "403":
                    alert("알림","작품 감상을 위해 로그인이 필요합니다.",()=>{
                        navigate("/login",{state:{return_path:location.pathname}});
                    });
                    break;
                default:
                    alert("알림","시스템에 문제가 발생하였습니다. 문제가 지속되면 관리자에게 문의주세요.");
                    break;
            }
        },
        onError:(e)=>{

        },
    });

    const handlerCheckPay = (is_paid)=>{
        // if(is_paid === "true"){
        //     getViewPageLink();
        //     return;
        // }

        if(epubData?.cid === 0){
            alert("뷰어","다음 회차가 없습니다.");
            return;
        }

        checkSubmit({
            bid:bid,
            cid:epubData?.cid,
            ct_type:"epub",
            epub:true,
        });
    }


    const getViewPageLink = ()=>{
        navigate("/view/"+type+"/"+epubData?.cid+"/viewer");
    }

    useEffect(()=>{
        if(viewerRun && runState && prevOrNext === "next"){
            handlerCheckPay();
        }
    },[runState]);

    return (
        <>
            <BookpalLink
            // to={"/view/"+data?.ct_type+"/"+data?.cid}
            // data-is_paid={data?.is_paid ? "true":"false"}
            // data-is_payable={data?.is_payable ? "true":"false"}
            onClick={(e)=>{
                e.preventDefault()
                // const is_paid = e.currentTarget.getAttribute("data-is_paid");
                // const is_payable = e.currentTarget.getAttribute("data-is_payable");
                handlerCheckPay();
            }}
            >
                {prevOrNext === "prev"?
                <>
                    <p className={"icon prev " + style['icon'] + " " + style['prev'] + " " + (data?.prev_chapter?.cid === 0 ? style['disabled']:"")}>이전화</p>
                    <p className={"label " + style['label']}></p>
                </>
                :null}
                {prevOrNext === "next"?
                <>
                    <p className={"icon next " + style['icon'] + " " + style['next'] + " " + (data?.next_chapter?.cid === 0 ? style['disabled']:"")}>다음화</p>
                    <p className={"label " + style['label']}></p>
                </>
                :null}
            </BookpalLink>
        </>
    )
}



export default PrevNextEpubBtn;