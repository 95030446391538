import React, { useState, forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { flip, shift } from "@floating-ui/react-dom";
import { ko } from 'date-fns/locale/ko'; // 한국어 로케일

import './selectDateTimePicker.css';
import style from './selectDateTimePicker.module.scss';

const SelectDateTimePicker = ({setDate, setTime, date, setDateValue, dateFormat}) => {
    const setDataFn = setDate || (()=>{});
    const setTimeFn = setTime || (()=>{});
    const _setDateValue = setDateValue || (()=>{});
    // const [startDate, setStartDate] = useState(date);
    const selectDateTime = (currentDate) => {
        // console.log(new Date(currentDate));
        _setDateValue(new Date(currentDate) );
        // 날짜 가져오기 (00-00-00)
        const year = currentDate.getFullYear().toString().slice(-2);
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        // console.log(new Date(formattedDate));
        setDataFn(formattedDate);

        // 시간 가져오기 (00:00)
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const formattedTime = `${hours}:${minutes}`;

        setTimeFn(formattedTime);
    }
    const ExampleCustomInput = forwardRef(({ className, spanClassName, value, onClick }, ref) => {
        return (
            <button className={"text_input select_box button color-bg-01 radius-sm color-border-02 " + style['button']} onClick={onClick} ref={ref}>
                <span className={"body-03 " + style['span'] + " " + ( value=="" ? "color-txt-muted":"color-txt-normal")}>{value=="" ? "00.00.00" : value}</span>
            </button>
        )
    });
    return (
        <DatePicker
            selected={date}
            onChange={selectDateTime}
            dateFormat={dateFormat ?? "yy.MM.dd"}
            locale={ko}
            timeZone="Asia/Seoul"
            maxDate={new Date()}
            timeCaption="시간"
            popperModifiers={[
                flip(),                                  // 화면 가장자리에 맞춰 자동으로 위치 변경
                shift({ padding: 5 }),                   // 화면 밖으로 나가지 않도록 위치 조정
            ]}
            
            customInput={<ExampleCustomInput />}
        />
    );

    // return [datePicker, toggleDatePicker, startDate];
};

export default SelectDateTimePicker;
