import { runPopupBookpalBankOrderInfo, runPopupVBankOrderInfo } from 'utils/Common';
import style from '../style/Order.module.scss';

import img_kakao_logo from 'assets/images/order/kakao_logo.png';
import img_naver_logo from 'assets/images/order/naver_logo.png';
import img_payco_logo from 'assets/images/order/payco_logo.png';
import useAlertModal from 'component/common/modal/useAlertModal';

function getPayImage(pay){
    switch(pay){
        case "KAKP": return img_kakao_logo;
        case "NAEP": return img_naver_logo;
        case "PAYP": return img_payco_logo;
    }
    return "";
}

function ComponentPayEasy(props){

    const data = props.data;
    const id = "a" + props.uniqKey;
    const hidden = props.hidden || false;
    const payCode = props.payCode;
    const setPayCode = props.setPayCode || (()=>{});
    const vbank = props.vbank || false;

    const img_icon = getPayImage(data?.pay);

    const [AlertModal, alert] = useAlertModal();
    
    const handlerSelect = (e) => {
        // console.log(e.target)
        
        if(e.currentTarget.value === "PAYP"){
            alert("결제" ,"페이코 간편결제 준비중입니다!\n'Coming soon'");
            return;
        }

        setPayCode(e.target.value);
    }

    return (
        <>
        <li className={"radius-sm " + style['radius-sm'] + (payCode === data?.pay ? " color-bg-select-01" : "" ) + " "+ (hidden ? style['hidden'] : "")}  >
            {/* <!-- label 클릭시 className="color-bg-select-01" 추가 --> */}
            <label htmlFor={id} className="radio_button check-type-01 small label_on ">
                <input type="radio" id={id} name="kind" value={data?.pay} checked={payCode === data?.pay} onChange={(e)=>handlerSelect(e)}/>
                <span className="box"></span>
                <span className="txt color-txt-normal">{data?.name}</span>
            </label>
            <div className="logo_img"><img src={img_icon} /></div>
            {/* <!-- 최근 결제 --> */}
            {/* <div className={"badge_list " + style['badge_list']}>
                <p className="badge color-state-point-01 bg radius-full border border-soild border-line">
                    <span className="white-always txt">최근 결제</span>
                </p>
            </div> */}
            {/* <!-- 북팔 계좌이체 정보 --> */}
            {data?.pay == "BPVB" ?
            <div className={"info_btn " + style['info_btn']}>
                <button 
                    type="button" 
                    className="button btn_01 tiny label_on outlined radius-full"
                    onClick={()=>{
                        runPopupBookpalBankOrderInfo();
                    }}
                    ><span className="btn-01 color-btn-01 txt">정보</span></button>
            </div> : null}

            { (data?.pay === "INMV" || data?.pay === "INPV") && vbank ?
            <div className={"info_btn " + style['info_btn']}>
                <button 
                    type="button" 
                    className="button btn_01 tiny label_on outlined radius-full"
                    onClick={()=>{
                        runPopupVBankOrderInfo();
                    }}
                    ><span className="btn-01 color-btn-01 txt">정보</span></button>
            </div> : null}
        </li>
        <AlertModal />
        </>
    )
}



export default ComponentPayEasy;

