import { Swiper, SwiperSlide } from 'swiper/react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BookpalLazyLoadImage from '../custom/BookpalLazyLoadImage';
import { ImgDefault } from 'utils/Images';
import { Link } from 'react-router-dom';
import BookpalLink from '../custom/BookpalLink';
import midnightBanner from 'assets/images/promotion/midnight_banner.png';
import { ApiCommonBannerClick } from 'api/Common';


function BigBanner(props){

    const sectionClassName = props.sectionClassName || "section station wrapper banner_big";
    const divClassName = props.divClassName || "area_box radius-sm";

    const data = props.data || null;

    const bannerClickSubmit = ApiCommonBannerClick({
        onSuccess:(data)=>{
            console.log(data);
        },
        onError:(e)=>{

        }
    });

    const handlerBannerClick = (id)=>{
        if(id === "0" || id === null){
            return;
        }
        bannerClickSubmit({
            type:"small",
            id:id,
        });
    }

    if(data === null){
        return null;
    }

    return (
        <>
            <section className={sectionClassName}>
            <Swiper className="swiper mySwiper" 
                slidesPerView={'auto'}
                spaceBetween={4}
                watchOverflow={true}
                observer={true}
                observeParents={true}
                breakpoints={{
                    // 769: {
                    //     freeMode : true, 
                    //     slidesPerView : 'auto',
                    // },
                    // 991: {
                    //     freeMode : true, 
                    //     slidesPerView : '2',
                    // },
                }}
            >

                {
                    data.map((item, index)=>{

                        return (
                            <SwiperSlide key={index}>

                                
                                <BookpalLink to={item?.link}>
                                    <div className={divClassName} style={{background: item?.bg_color}}>    
                                        {/* <img src={midnightBanner} /> */}
                                        <BookpalLazyLoadImage 
                                            placeholderSrc={ImgDefault("small_banner")}
                                            // effect="blur"
                                            src={item?.image}
                                            onError={(e)=>{
                                                e.currentTarget.src = ImgDefault("small_banner");
                                            }}
                                            background={"#ffffff00"}
                                            data-id={item?.id}
                                            onClick={(e)=>{
                                                // console.log(123)
                                                const id = e.currentTarget.getAttribute("data-id");
                                                handlerBannerClick(id)
                                            }}
                                            // style={{width:"100px",height:"150px"}}
                                            // style={defaultImageStyle}
                                            />
                                    </div>
                                </BookpalLink>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
            </section>
        
        </>
    );
}



export default BigBanner;