import { isApp } from 'utils/AppUtil';
import { ApiPayProdAutoList, ApiPayReady } from 'api/Pay';
import { runPopupBookpalBankOrder } from 'utils/Common';

import style from '../style/Order.module.scss';

import img_rating_label_normal from 'assets/images/rating_label_normal.svg';
import img_rating_label_vip from 'assets/images/rating_label_vip.svg';
import img_rating_label_vip_silver from 'assets/images/rating_label_vipSilver.svg';
import img_rating_label_vip_gold from 'assets/images/rating_label_vipGold.svg';
import img_rating_label_rvip from 'assets/images/rating_label_rVip.svg';

import img_kakao_logo from 'assets/images/order/kakao_logo.png';
import img_naver_logo from 'assets/images/order/naver_logo.png';
import img_payco_logo from 'assets/images/order/payco_logo.png';

import img_charge_banner3 from 'assets/images/order/charge_banner3.png';
import img_charge_banner2 from 'assets/images/order/charge_banner2.png';
import { useEffect, useState } from 'react';
import { getCurrentDay, getCurrentMonth, getNextPaymentDate } from 'utils/Common';
import useAlertModal from 'component/common/modal/useAlertModal';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';


function RegularOrder(){

    const navigate = useBookpalNavigate();

    //const [payData, setPayData] = useState(null);
    const [payList, setPayList] = useState(null);
    const [prodList, setProdList] = useState(null);
    const [selectPay, setSelectPay] = useState(null);
    const [selectProd, setSelectProd] = useState(null);
    const [selectPayPrice, setSelectPayPrice] = useState(null);
    const [agree, setAgree] = useState(false);

    const [isPaySubmit, setIsPaySubmit] = useState(false);

    const [tooltip, setTooltip] = useState(null);

    const [AlertModal, alert] = useAlertModal();
    
    const payProdList = ApiPayProdAutoList({
        onSuccess:(data)=>{
            if(data?.status === true){
                //setPayData(data?.result);
                const prod_result = [
                    ...data?.result?.prod
                ];

                setProdList(prod_result);

                Object.keys(prod_result).forEach(key => {
                    const data2 = prod_result[key];
                    if(data2?.default == true){
                        setSelectProd(data2?.product_code);
                        setSelectPayPrice(data2?.price);
                    }
                });

                const pay_result = [
                    ...data?.result?.pay
                ];
//console.log(pay_result)
                setPayList(pay_result);
                return;
            }
            switch(data?.code){
                case "use_auto_bill":
                    alert("중복 이용 불가", "이미 자동충전 이용중입니다.",()=>{
                        navigate(-1);
                    })
                    break;
            }
        },
        onError:(e)=>{

        }
    });

    const handlerPayReady = ApiPayReady({
        onSuccess:(data)=>{
            console.log(data);
            if(data?.status === true){
                submitFormWithPay(data?.result?.action, data?.result?.data);
            }
        },
        onError:()=>{

        }
    });

    const submitPay = ()=>{
        handlerPayReady({
            pay:selectPay,
            prod_code:selectProd,
            front_return_url:process.env.REACT_APP_BASE_URL + "/order/regular",
            front_close_url:process.env.REACT_APP_BASE_URL + "/order/regular",
        })
    }

    const submitFormWithPay = (action, data)=>{
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        const form = document.createElement('form');
        form.setAttribute('method', 'post');
        form.setAttribute('action', action?.ready_url);

        Object.keys(data).forEach(key=>{
            const input = document.createElement('input');
            const p_data = data[key];
            input.setAttribute('type', 'hidden');
            input.setAttribute('name', key);
            input.setAttribute('value', p_data);

            form.appendChild(input);
        });

        switch(action?.pay){
            case "AUPI":
            case "INPC":
            case "INPV":
            case "INPB":
                window.resizeTo(900,700);
                break;
            case "KAKP":
                const input_kakao = document.createElement('input');
                const p_data_kakao = isApp() ? "app": (isMobile ? "mobile":"pc");
                input_kakao.setAttribute('type', 'hidden');
                input_kakao.setAttribute('name', "next_move");
                input_kakao.setAttribute('value', p_data_kakao);
                form.appendChild(input_kakao);
                break;
        }

        document.body.appendChild(form);
        form.submit();

        document.body.removeChild(form);
    };

    useEffect(()=>{
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        payProdList({
            type:isMobile ? "mobile":"pc"
        });
    },[]);

    useEffect(()=>{
        if(selectProd && selectPay && agree){
            setIsPaySubmit(true);
            return;
        }
        setIsPaySubmit(false);
    },[selectProd, selectPay, agree]);

    if(payList === null){
        return <><AlertModal /></>
    }

    //console.log(payList);

    return (
        <>
            <div className={"list_2 " + style['list_2']} >
                {/* <!-- 배너 --> */}
                <div className={"article template temp_list " + style['article'] + " " + style['template'] + " " + style['temp_list']}>
                    <div className="banner_big list">
                        <a>
                            <div className="area_box radius-sm" style={{background:"#EBF4FF"}}>
                                <img src={img_charge_banner3} />
                            </div>
                        </a>
                    </div>
                </div>
                <div className={"article " + style['article']}>
                    <div className={"tit_con " + style['tit_con']}>
                        <h2 className="title left text_overflow title-01-b color-txt-normal">정기충전금액</h2>
                        <div className={"right " + style['right']}>
                            <button 
                                type="button" 
                                className={"info_btn button " + style['info_btn'] + " " + style['button']}
                                onClick={()=>{
                                    setTooltip(true);
                                }}
                                ><span className="btn-03 color-btn-03-txt">정기충전 안내</span></button>
                            <div 
                                id="toolTip right" 
                                className={"toolTip right " + style['toolTip'] + " " + style['right']} 
                                style={{display:tooltip?"block":"none"}}
                                onClick={()=>{
                                    setTooltip(false);
                                }}
                                >
                                <button type="button" className="radius-xs color-btn-01 bg top_left">
                                    <span className="white-always caption-02-long txt">매달 정기충전을 시작한 날짜에 <br/>맞춰 결제하신 상품이 정기적으로<br/>충전됩니다.</span>
                                    <img src="/images/x_circle.svg" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <ul className={"list_box product_list " + style['list_box'] + " " +style['product_list']}>

                        {!!prodList && Array.isArray(prodList) && prodList?.length > 0 ?
                            prodList.map((item, index)=>{
                                return (
                                    <li key={index}>
                                        
                                        <button 
                                            type="button" 
                                            className={"button color-border-01 radius-sm " + style['button'] + " " + (selectProd == item?.product_code ? style['click']:"")}
                                            data-prod={item?.product_code}
                                            data-price={item?.price}
                                            onClick={(e)=>{
                                                const prod = e.currentTarget.getAttribute("data-prod");
                                                const price = e.currentTarget.getAttribute("data-price");
                                                setSelectProd(prod);
                                                setSelectPayPrice(price)
                                            }}
                                            >
                                            <div className={"left " + style['left']}>
                                                <div className={"coupon_num title-book-04-m color-txt-normal " + style['coupon_num']}>
                                                    쿠폰 <span className={"num line " + style['num'] + " " + style['line']}>{item?.o_coupon}개</span>
                                                    <span className={"red_num color-btn-01 txt title-book-04-b " + style['red_num']}>{item?.o_coupon + item?.b_coupon}개</span>
                                                </div>
                                                <div className="coupon_add body-01 color-txt-05">{item?.b_per}% 추가 쿠폰</div>
                                            </div>
                                            <div className={"right " + style['right']}>
                                                <p className="money title-book-04-b color-txt-normal">{item?.price}원</p>
                                            </div>
                                        </button>
                                    </li>
                                )
                            })
                        :null}

                    </ul> 
                </div>
                <div className={"article " + style['article']}>
                    <div className={"tit_con " + style['tit_con']}>
                        <h2 className="title left text_overflow title-01-b color-txt-normal">결제수단 선택</h2>
                    </div>
                    <ul className={"list_box check_list pay_list " + style['list_box'] + " " + style['check_list'] + " " + style['pay_list']}>
                        {!!payList && Array.isArray(payList) && payList?.length > 0 ?
                            payList.map((item, index)=>{
                                return (
                                    <li key={index} className={"radius-sm " + style['radius-sm'] + " " + (selectPay==item?.pay ? "color-bg-select-01":"")}>
                                        <label htmlFor={"c"+index} className={"radio_button check-type-01 small label_on "}>
                                            <input 
                                                type="radio" 
                                                id={"c"+index}
                                                name="kind2" 
                                                data-pay={item?.pay}
                                                onChange={(e)=>{
                                                    const pay = e.currentTarget.getAttribute("data-pay");
                                                    setSelectPay(pay);
                                                }}
                                                />
                                            <span className="box"></span>
                                            <span className="txt color-txt-normal">{item?.name}</span>
                                        </label>
                                    </li>
                                )
                            })
                        :null}
                    </ul>
                </div>
                <div className={"section " + style['section']} style={{padding:"0"}}>
                    <div className={"regular_box color-bg-select-01 radius-sm " + style['regular_box']}>
                        <p className="title title-01-m-long color-txt-normal">
                            매월 <span className="color-btn-01 txt">{getCurrentDay()}일</span> 쿠폰교환을 위해 <br/>
                            <span className="color-btn-01 txt">{selectPayPrice}</span>원이 정기충전됩니다.   
                        </p>
                        <p className={"desc body-01-long color-txt-muted " + style['desc']}>다음달 결제 예정일 : <span>{getNextPaymentDate(getCurrentDay())}</span></p>
                    </div>
                    <div className={"article check_event " + style['article'] + " " + style['check_event']}>
                        <p className={"title color-txt-04 btn-03 " + style['title']}>상품가격, 할인정보, 유의사항 등을 확인하였습니다.</p>
                        <label htmlFor="regularAgree" className={"checkbox_round check-type-01 small label_on " + style['checkbox_round']}>
                            <input type="checkbox" id="regularAgree" checked={agree} onChange={(e)=>{
                                setAgree(e.currentTarget.checked);
                            }} />
                            <span className="box"></span>
                            <span className="txt color-txt-04">구매에 동의합니다.</span>
                        </label>
                    </div>
                </div>
                <div className="notice_box_desc section">
                    <p className="title title-02-b color-txt-04">유의사항</p>
                    <ul className="desc_list body-02-long color-txt-05">
                        <li>구매일로부터 1개월마다 이용권 금액이 설정된 결제수단(휴대폰/신용카드)으로 자동결제됩니다.</li>
                        <li>결제하신 북팔 쿠폰의 일부를 사용하시면 결제를 취소할 수 없습니다.</li>
                        <li>정기충전 해지가 완료되어도 결제된 내역 자동으로 환불 되지 않습니다. 환불 요청 시, 결제 관련 공지를 확인해 주시기 바랍니다. </li>
                        <li>정기충전 신청 후 14일 이후에 자동 결제 해지가 가능합니다.</li>
                        <li>회사 사정에 따라 추가 쿠폰은 변경, 중단될 수 있습니다. </li>
                    </ul>
                </div>
            </div>
            <div className="fixed_button">
                <button 
                    type="button" 
                    className="button btn_01 filled btn-05 white-always txt" 
                    disabled={!isPaySubmit}
                    onClick={submitPay}
                    ><span>{selectPayPrice}</span>원 결제하기</button>
            </div>
            <AlertModal />
        </>
    );
}



export default RegularOrder;