import { ProgressBar } from 'react-bootstrap';
import style from '../style/Contents.module.scss';
import ProgressBarTest from './ProgressBarTest';
import { Slider } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import FooterMenu from './FooterMenu';

import style_fade from './FooterHorizontal.module.scss'
import { useIsMobile } from 'utils/Common';

function FooterHorizontal({data, page, totalPage, setPage, visible, type, bid, cid, alert, buyConfirm}){

    const ref = useRef(null);

    // const [per, setPer] = useState(0);
    const [runState, setRunState] = useState(false);
    const isMobile = useIsMobile();
    const [barPage, setBarPage] = useState(page);

    let timeout = null;

    const handlerSlide = (e, newValue)=>{

        // if(type === "epub"){
        //     console.log(timeout);
        //     // setPage(newValue)
    
        //     if(timeout){
        //         clearTimeout(timeout);
        //         timeout = null;
        //     }
        //     console.log(timeout);
        //     timeout = setTimeout(()=>{
        //         setPage(newValue)
        //     },100);
        // } else {
            // setPage(newValue)
            setBarPage(newValue)
        // }
        
    }

    useEffect(()=>{
        // setPer((page/totalPage) * 100);
        // console.log("setPer",(page/totalPage) * 100)
        if(((page/totalPage) * 100) > 100){
            setRunState(true);
        } else {
            setRunState(false);
        }
    },[page, totalPage]);

    useEffect(()=>{
        // setBarPage(page)
        const handler = setTimeout(() => {
            setBarPage(page);
        }, 300);
    
        // 타이머가 초기화될 때마다 이전 타이머 취소
        return () => {
            clearTimeout(handler);
        };
    },[page])

    useEffect(()=>{

        const handler = () => {
            if(ref.current){
                setTimeout(()=>{
                    if(ref.current && ref?.current?.style){
                        ref.current.style.display = "none";
                    }
                    
                },500);
            }
        }

        if(visible){
            if(ref?.current){
                if(ref?.current && ref?.current?.style){
                    ref.current.style.display = "block";
                }
            }
        } else {
            handler();
        }
    },[visible]);

    return (
        <div ref={ref} className={"view_footer " + style['view_footer'] + " " + (!visible ? style_fade['fade-out']:"")} >
            <div className={"area_box m_wrap " + style['area_box'] + " " + style['m_wrap']}>
                {/* <!-- //스크롤, 좌우방향일때 --> */}
                {/* <div className={"progress_area progress_type1 " + style['progress_area'] + " " + style['progress_type1']} style={{display:"flex"}}>
                    <div className={"return_btn DarkMode " + style['return_btn'] + " " + style['DarkMode']}><button type="button" className={"btn_03 outlined round small button label_on radius-full color-txt-04 btn-02 txt " + style['button'] + " " + style['txt']}>처음</button></div>
                    <div className={"progress_bar " + style['progress_bar']}>
                        <div className={"progress radius-xxs " + style['progress']}></div>
                        <div className={"drag_bar " + style['drag_bar']}></div>
                    </div>
                    <div className={"progress_percent " + style['progress_percent']}><span>75</span>%</div>
                </div> */}
                <div className={"progress_area progress_type1 " + style['progress_area'] + " " + style['progress_type1']} style={{display:"flex"}}>
                    <div 
                        className={"return_btn DarkMode " + style['return_btn'] + " " + style['DarkMode']}
                        ><button 
                            type="button" 
                            className={"btn_03 outlined round small button label_on radius-full color-txt-04 btn-02 txt " + style['button'] + " " + style['txt']}
                            onClick={()=>{
                                setPage(0);
                                setBarPage(0);
                            }}
                            >처음</button></div>

                    <div 
                        className={"progress_bar " + style['progress_bar']}
                        style={{margin:"0 14px"}}>
                        <Slider
                            // aria-label="Small steps"
                            value={barPage}
                            onChange={handlerSlide}
                            onChangeCommitted={(event, newValue)=>{
                                console.log("setPage",7);
                                setPage(newValue);
                                setBarPage(newValue)
                            }}
                            defaultValue={0}
                            step={1}
                            min={0}
                            max={totalPage}
                            valueLabelDisplay="off"
                            style={{width:"100%", padding:isMobile?"3px 0px":"4px 0px"}}
                            sx={{
                                '& .MuiSlider-thumb': {
                                  width: 14,
                                  height: 14,
                                  
                                  backgroundColor: '#FF3746',
                                  '&:hover, &.Mui-focusVisible': {
                                    boxShadow: '#FF3746', // 커서 호버 시 효과
                                  },
                                },

                                '& .MuiSlider-track': {
                                    color:'#FF3746',
                                    backgroundColor: '#FF3746', // 프로그레스 바 색상
                                },
                                '& .MuiSlider-rail': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.7)', // 레일(프로그레스 바의 빈 부분) 색상
                                  },
                              }}
                            />
                    </div>
                    
                    <div className={"progress_percent " + style['progress_percent']}><span>{(parseInt((page/totalPage) * 100, 10) > 100 ? 100:totalPage === 0 ? 0 :parseInt((page/totalPage) * 100, 10))}</span>%</div>
                </div>
                {/* <!-- //역방향일때 --> */}
                <div className={"progress_area progress_type2 " + style['progress_area'] + " " + style['progress_type2']}>
                    <div className={"progress_percent " + style['progress_percent']}><span>75</span>%</div>
                    <div className={"progress_bar " + style['progress_bar']}>
                    <div className={"progress radius-xxs " + style['progress']}></div>
                    <div className={"drag_bar " + style['drag_bar']}></div>
                    </div>
                    <div className={"return_btn DarkMode " + style['return_btn']}><button type="button" className="btn_03 outlined round small button label_on radius-full color-txt-04 btn-02 txt">처음</button></div>
                </div>
                <div className={"btns_box " + style['btns_box']}>
                    <FooterMenu 
                        data={data}
                        type={type}
                        bid={bid}
                        cid={cid}
                        alert={alert}
                        buyConfirm={buyConfirm}
                        runState={runState}
                        />
                </div>
            </div>
        </div>
    )
}


export default FooterHorizontal;