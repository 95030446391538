import { useEffect, useState } from 'react';
import { ApiOrderOrderUseList } from 'api/Order';
import style from './style/Mypage.module.scss';
import useSelectArrDateModal from 'component/common/modal/useSelectArrDateModal';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import BookpalLink from 'component/common/custom/BookpalLink';
import useAlertModal from 'component/common/modal/useAlertModal';
import { appSetMove, isApp } from 'utils/AppUtil';

function getNameWithFlag(flag){
    switch(flag){
        case "26": return "작가쿠폰";
        case "41": return "일괄구매";
        default: return null;
    }
}

function MypageCouponUseList(){

    const [error, setError] = useState(null);

    const navigate = useNavigate();

    const [AlertModal, alert] = useAlertModal();

    const searchData = [
        {
            key:"3",
            value:"3개월",
        },
        {
            key:"6",
            value:"6개월",
        },
        {
            key:"12",
            value:"1년",
        },
        {
            key:"etc",
            value:"직접입력",
        },
    ];

    const [loading, setLoading] = useState(false);
    // const [month, setMonth] = useState(false);

    const [SelectModal, showSelectModal, selectKey, setSelectKey, getValue, startDate, endDate] = useSelectArrDateModal(searchData, searchData[0]?.key, (key)=>{
        setLoading(true);
    },(error)=>{
        alert(error)
    });

    const [data, dataSubmit, initData, paging] = ApiOrderOrderUseList({
        data:{
            expire_mode:(selectKey === "etc" ? "etc" :"month"),
            expire_month:selectKey,
            expire_start:startDate,
            expire_end:endDate,
        },
        onSuccess:(data)=>{
            if(data?.code === "fail_expire_range"){
                setError("검색 범위는 1년이내 데이터만 조회 가능합니다.");
            } else {
                setError(null);
            }
        }
        // bottomRef:contentRef
    });

    useEffect(()=>{
        dataSubmit();
    },[]);

    useEffect(()=>{
        if(loading === true){
            initData();
            dataSubmit();
            setLoading(false);
        }
    },[loading]);

    return (
        <>
            <div className={"list_2 " + style['list_2']} style={{display:"block"}}>
                <div className={"tit_con clearfix " + style['tit_con']}>
                    <div className={"left " + style['left']}></div>
                    <div className={"right " + style['right']}>
                        <BookpalLink 
                            onClick={()=>{
                                showSelectModal();
                            }} 
                            className={"select_btn select_01 " +style['select_btn'] + " " + style['info']} 
                            >
                            <span className="color-btn-03-txt btn-03">{ selectKey === "etc" ? startDate +"~" + endDate : getValue()}</span>
                            <span className="color-btn-03-txt btn-03">최신순</span>
                        </BookpalLink>
                    </div>
                </div>
                {error ? <p className="no_data small color-txt-04 body-02-long">
                    {error}
                </p> : null}
                {paging?.total_cnt === 0 ? <p className="no_data small color-txt-04 body-02-long">
                    쿠폰 사용내역이 없습니다. 
                    {/* <br />랭킹 작품을 감상해 보세요! */}
                    {/* <button 
                        type="button" 
                        className="button btn_01 filled label_on large radius-full"
                        onClick={()=>{
                            if(isApp()){
                                appSetMove("","/novel/ranking");
                                return;
                            }
                            navigate("/novel/ranking");
                        }}
                        ><span className="btn-04">바로가기</span></button> */}
                </p> : 
                <ul className={"list_box list " + style['list_box'] + " " + style['list']}>
                    {data && Array.isArray(data) ?
                        data.map((item, index)=>{

                            return (
                                <li key={index} className="border-soild border-bottom-line color-border-01">
                                    <BookpalLink 
                                    // data-type={item?.type}
                                    // data-content_type={item?.content_type}
                                    // data-bid={item?.bid}
                                    to={"/content/"+item?.type+"/"+item?.bid}
                                    // onClick={(e)=>{
                                    //     const type = e.currentTarget.getAttribute("data-type");
                                    //     const content_type = e.currentTarget.getAttribute("data-content_type");
                                    //     const bid = e.currentTarget.getAttribute("data-bid");
                                    //     console.log(type);
                                    //     if(content_type === "cid"){
                                    //         console.log("/content/"+type+"/"+bid);
                                    //         navigate("/content/"+type+"/"+bid);
                                    //     }
                                    // }}
                                    >
                                        <div className="left info_txt spacing_08">
                                            <div className="list_tit_con">
                                                <span className="title-01-b color-txt-normal text_overflow">{item?.b_title}</span>
                                                {item?.bid != null ? <span className={"icon " + style['icon']}></span>:""}
                                                
                                            </div>
                                            <div className="category body-02 color-txt-03">
                                            {item?.no ?
                                                <>
                                                    {item?.extra === 1 ? "외전":""} {item?.no}{item?.type === "epub"?"권 ":"화 "}
                                                </>
                                            :null}
                                            {getNameWithFlag(item?.flag) ?? (item?.buy_type === "rent" ? "대여" : "소장")}
                                            {/* {item?.buy_type === "rent" ? "대여" : "소장"} */}
                                            </div>
                                            <div className="date body-01 color-txt-05">{item?.date}</div>
                                        </div>
                                        <div className={"right " + style['right']}>
                                            <div className="title title-01-m color-txt-normal">쿠폰 <span>{item?.coupon}</span>개</div>
                                            <div className="data color-txt-05 body-01">
                                                {item?.type === "novel" ? "웹소설":""}
                                                {item?.type === "toon" ? "웹툰":""}
                                                {item?.type === "epub" ? "단행본":""}
                                            </div>
                                        </div>
                                    </BookpalLink>
                                </li>
                            );
                        })
                    :null}
                    
                </ul>
                }
                
                {/* <!-- 더보기 버튼 클릭시 하단으로 리스트 30개 추가 노출 30개미만일때는 더보기버튼 없음 --> */}
                {paging?.is_next_page ? 
                    <div className="more_btn color-bg-01">
                        <button 
                            type="button" 
                            className="button btn_03 medium outlined label_on round radius-full color-txt-04 btn-03"
                            onClick={()=>{
                                dataSubmit();
                            }}
                            >
                            <span>더보기 ({paging?.current_page}/{paging?.total_page})</span>
                        </button>
                    </div>
                :null}
                {/* <!-- 리스트 없을때 --> */}
                {/* <!-- <p className="no_data small color-txt-04 body-02-long">
                    쿠폰 사용내역이 없습니다. <br />랭킹 작품을 감상해 보세요!
                    <button type="button" className="button btn_01 filled label_on large radius-full"><span className="btn-04">바로가기</span></button>
                </p> --> */}
            </div>
            
            <SelectModal />
            <AlertModal />
        </>
    )
}




export default MypageCouponUseList;