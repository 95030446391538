import { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import { Pagination, Navigation, Thumbs, EffectFade, Autoplay } from 'swiper/modules';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BookpalLazyLoadImage from '../custom/BookpalLazyLoadImage';
import { ImgBadge, ImgDefault, ImgCover } from 'utils/Images';
import { useMemberInfo } from 'utils/State';
import { useWidth } from 'utils/Common';
import BookpalLink from '../custom/BookpalLink';

import style from './GenreHomeHalfBanner.module.scss'
import useAlertModal from '../modal/useAlertModal';
import 'swiper/swiper-bundle.css';


function getDefaultImgStyle(gb){
    const style = {};
    switch(gb){
        case "pc": 
            style['maxWidth'] = "150px";
            style['maxHeight'] = "225px";
            break;
        case "mobile": 
            style['maxWidth'] = "120px";
            style['maxHeight'] = "180px";
            break;
    }
    return style;
}


function GenreHomeHalfBanner({ item }){

    const memberInfo = useMemberInfo();
    const width = useWidth();

    const [key, setKey] = useState(0);
    const [prevWidth, setPrevWidth] = useState(window.innerWidth);

    const swiperRef = useRef(null);

    const [defaultImageStyle, setDefaultImageStyle] = useState({});

    const [AlertModal, alert] = useAlertModal();

    const refreshSwiper = () => {
        setKey((prevKey) => prevKey + 1);
    };

    const handleResize = () => {
        const currentWidth = window.innerWidth;
  
        if (Math.abs(currentWidth - prevWidth) > 1) {
          // 좌우로 10px 이상 변경되었을 때 동작하도록 설정
          console.log('Resized!');
          setPrevWidth(currentWidth);
          refreshSwiper();
        }
    };
    useEffect(()=>{
        if(width > 540){
            setDefaultImageStyle(getDefaultImgStyle("pc"));
        } else {
            setDefaultImageStyle(getDefaultImgStyle("mobile"));
        }
    },[width])

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        // console.log("test -> use effect");

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(()=>{
        if(swiperRef.current){
            console.log(swiperRef.current)
            swiperRef.current.addEventListener('touchstart', e => {
                e.stopPropagation();
                e.preventDefault();
            }, { passive: true });
            swiperRef.current.addEventListener('mousedown', e => {
                e.stopPropagation()
                e.preventDefault()
            });
        }
    },[swiperRef])

    if(!item || !item?.title){
        return <></>
    }
    // console.log(item);

    return (
        <>
        <section className="section template wrapper temp_list_half_bg template_row">
            <div className="temp_bg radius-sm" style={{backgroundImage: `url(${item?.bg_image})`}}></div>
            <div className="area_box">
                <div className="tit_con">
                    <h2 className="title left title-02-b text_overflow" style={{color: item?.txt_color}}>{item?.title}</h2>
                </div>
                <div className="list">
                <Swiper 
                    key={key}
                    ref={swiperRef}
                    className={"swiper mySwiper "}
                    direction={'horizontal'}
                    modules={[Navigation]}
                    slidesPerView={'auto'}
                    spaceBetween={4}
                    // watchOverflow={true}
                    // observer={true}
                    // observeParents={true}
                    nested={true}
                    navigation={{
                        nextEl : '.template .swiper-button-next',
                        prevEl : '.template .swiper-button-prev',
                    }}
                    breakpoints={{
                        769: {
                            freeMode : true
                        }
                    }}
                    onInit={(swiper) => {
                        swiper.slides.forEach((slide, index) => {
                        if(slide.swiperSlideIndex == 0){
                            if(swiper && swiper.slideTo){
                            swiper.slideTo(index, 0);
                            }
                        }
                        });
                    }}
                    >
                    {/* <div className="swiper mySwiper"> */}
                        {/* <div className="swiper-wrapper"> */}
                            {/* <!-- 리스트--> */}
                            {Array.isArray(item?.items) && item?.items.length > 0 ?
                                item?.items.map((v, i)=>{
                                 
                                    return (
                                        <SwiperSlide key={i} className={style['swiper-slide']}>
                                            {/* <a href="#"> */}
                                            <BookpalLink 
                                                to={"/content/"+v?.ct_type+"/"+v?.bid}
                                                check={{
                                                    view_type:memberInfo?.view_type,
                                                    work_adult:v?.adult,
                                                }}
                                                alert={alert}
                                                >
                                                <div className={"cover_img radius-xs " + style['cover_img']} >
                                                    {v?.adult ? <p className="ic_adult badge_adult_normal"><img src="../images/ic_adult.svg" /></p> : null}
                                                    {/* <img src={v?.cover} /> */}
                                                    {/* <BookpalLazyLoadImage 
                                                        placeholderSrc={ImgDefault("cover_logo")}
                                                        // effect="blur"
                                                        src={ImgCover(memberInfo?.view_type, v?.adult, ImgDefault("cover_lock"), v?.cover)}
                                                        // style={{width:"100px",height:"150px"}}
                                                        className={style['cover_img']}
                                                        onError={(e)=>{
                                                            e.currentTarget.src = ImgDefault("cover_logo");
                                                        }}
                                                        /> */}
                                                    <BookpalLazyLoadImage 
                                                        adult={v?.adult}
                                                        adultswitch={memberInfo?.audit_switch}
                                                        defaulttype={"cover_logo"}
                                                        locktype={"cover_lock"}
                                                        src={v?.cover}
                                                        style={defaultImageStyle}
                                                    />
                                                    <p className="ic_bottom badges_box">
                                                        {/* <span className="ic_midnight radius-xs icon_badge"><img src="../images/midnight_badge.svg" /></span>
                                                        <span className="ic_up radius-xs icon_badge"><img src="../images/up_badge.svg" /></span>
                                                        <span className="ic_discount radius-xs discount_10 icon_badge"><img src="../images/discount_10.svg" /></span> */}
                                                        {item?.badge_promotion !== "" ? <span className="ic_midnight radius-xs icon_badge"><img src={ImgBadge(v?.badge_promotion)} /></span> : null}
                                                        {item?.badge_new_up !== "" ? <span className="ic_up radius-xs icon_badge"><img src={ImgBadge(v?.badge_new_up)} /></span> : null}
                                                        {item?.badge_discount > 0 ? <span className="ic_discount radius-xs discount_10 icon_badge"><img src={ImgBadge(v?.badge_discount)} /></span> : null }
                                                    </p>
                                                </div>
                                                <div className="info_txt spacing_04">
                                                    <div className="title color-txt-normal title-book-02-m text_overflow2">{v?.title}</div>
                                                    <div className="writer color-txt-03 body-01 text_overflow">{v?.wr_nick}</div>
                                                </div>
                                            </BookpalLink>
                                        </SwiperSlide>
                                    )
                                })
                            :null}
                            {/* <SwiperSlide>
                                <a href="#">
                                    <div className="cover_img radius-xs">
                                        <p className="ic_adult badge_adult_normal"><img src="../images/ic_adult.svg" /></p>
                                        <img src="../images/thumbnail.jpg" />
                                        <p className="ic_bottom badges_box">
                                            <span className="ic_midnight radius-xs icon_badge"><img src="../images/midnight_badge.svg" /></span>
                                            <span className="ic_up radius-xs icon_badge"><img src="../images/up_badge.svg" /></span>
                                            <span className="ic_discount radius-xs discount_10 icon_badge"><img src="../images/discount_10.svg" /></span>
                                        </p>
                                    </div>
                                    <div className="info_txt spacing_04">
                                        <div className="title color-txt-normal title-book-02-m text_overflow2">책제목입니다.</div>
                                        <div className="writer color-txt-03 body-01 text_overflow">작가명</div>
                                    </div>
                                </a>
                            </SwiperSlide>
                            <SwiperSlide>
                                <a href="#">
                                    <div className="cover_img radius-xs">
                                        <p className="ic_adult badge_adult_normal"><img src="../images/ic_adult.svg" /></p>
                                        <img src="../images/thumbnail.jpg" />
                                        <p className="ic_bottom badges_box">
                                            <span className="ic_midnight radius-xs icon_badge"><img src="../images/midnight_badge.svg" /></span>
                                            <span className="ic_up radius-xs icon_badge"><img src="../images/up_badge.svg" /></span>
                                            <span className="ic_discount radius-xs discount_10 icon_badge"><img src="../images/discount_10.svg" /></span>
                                        </p>
                                    </div>
                                    <div className="info_txt spacing_04">
                                        <div className="title color-txt-normal title-book-02-m text_overflow2">책제목입니다.</div>
                                        <div className="writer color-txt-03 body-01 text_overflow">작가명</div>
                                    </div>
                                </a>
                            </SwiperSlide>
                            <SwiperSlide>
                                <a href="#">
                                    <div className="cover_img radius-xs">
                                        <p className="ic_adult badge_adult_normal"><img src="../images/ic_adult.svg" /></p>
                                        <img src="../images/thumbnail.jpg" />
                                        <p className="ic_bottom badges_box">
                                            <span className="ic_midnight radius-xs icon_badge"><img src="../images/midnight_badge.svg" /></span>
                                            <span className="ic_up radius-xs icon_badge"><img src="../images/up_badge.svg" /></span>
                                            <span className="ic_discount radius-xs discount_10 icon_badge"><img src="../images/discount_10.svg" /></span>
                                        </p>
                                    </div>
                                    <div className="info_txt spacing_04">
                                        <div className="title color-txt-normal title-book-02-m text_overflow2">책제목입니다.</div>
                                        <div className="writer color-txt-03 body-01 text_overflow">작가명</div>
                                    </div>
                                </a>
                            </SwiperSlide>
                            <SwiperSlide>
                                <a href="#">
                                    <div className="cover_img radius-xs">
                                        <p className="ic_adult badge_adult_normal"><img src="../images/ic_adult.svg" /></p>
                                        <img src="../images/thumbnail.jpg" />
                                        <p className="ic_bottom badges_box">
                                            <span className="ic_midnight radius-xs icon_badge"><img src="../images/midnight_badge.svg" /></span>
                                            <span className="ic_up radius-xs icon_badge"><img src="../images/up_badge.svg" /></span>
                                            <span className="ic_discount radius-xs discount_10 icon_badge"><img src="../images/discount_10.svg" /></span>
                                        </p>
                                    </div>
                                    <div className="info_txt spacing_04">
                                        <div className="title color-txt-normal title-book-02-m text_overflow2">책제목입니다.</div>
                                        <div className="writer color-txt-03 body-01 text_overflow">작가명</div>
                                    </div>
                                </a>
                            </SwiperSlide>
                            <SwiperSlide>
                                <a href="#">
                                    <div className="cover_img radius-xs">
                                        <p className="ic_adult badge_adult_normal"><img src="../images/ic_adult.svg" /></p>
                                        <img src="../images/thumbnail.jpg" />
                                        <p className="ic_bottom badges_box">
                                            <span className="ic_midnight radius-xs icon_badge"><img src="../images/midnight_badge.svg" /></span>
                                            <span className="ic_up radius-xs icon_badge"><img src="../images/up_badge.svg" /></span>
                                            <span className="ic_discount radius-xs discount_10 icon_badge"><img src="../images/discount_10.svg" /></span>
                                        </p>
                                    </div>
                                    <div className="info_txt spacing_04">
                                        <div className="title color-txt-normal title-book-02-m text_overflow2">책제목입니다.</div>
                                        <div className="writer color-txt-03 body-01 text_overflow">작가명</div>
                                    </div>
                                </a>
                            </SwiperSlide>
                            <SwiperSlide>
                                <a href="#">
                                    <div className="cover_img radius-xs">
                                        <p className="ic_adult badge_adult_normal"><img src="../images/ic_adult.svg" /></p>
                                        <img src="../images/thumbnail.jpg" />
                                        <p className="ic_bottom badges_box">
                                            <span className="ic_midnight radius-xs icon_badge"><img src="../images/midnight_badge.svg" /></span>
                                            <span className="ic_up radius-xs icon_badge"><img src="../images/up_badge.svg" /></span>
                                            <span className="ic_discount radius-xs discount_10 icon_badge"><img src="../images/discount_10.svg" /></span>
                                        </p>
                                    </div>
                                    <div className="info_txt spacing_04">
                                        <div className="title color-txt-normal title-book-02-m text_overflow2">책제목입니다.</div>
                                        <div className="writer color-txt-03 body-01 text_overflow">작가명</div>
                                    </div>
                                </a>
                            </SwiperSlide> */}
                        {/* </div> */}
                    </Swiper>
                    {/* </div> */}
                    <div className="arrow_btns">
                        <div className="swiper-button-next color-bg-01 box-shadow-xs color-border-01 btn_03 medium label_off radius-full round button"><img src="/images/chevron_right.svg" /></div>
                        <div className="swiper-button-prev color-bg-01 box-shadow-xs color-border-01 btn_03 medium label_off radius-full round button"><img src="/images/chevron_left.svg" /></div>
                    </div>
                </div>
                
            </div>
        </section>
        <AlertModal />
        </>
    );
}



export default GenreHomeHalfBanner;