import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import BookpalLink from 'component/common/custom/BookpalLink';
import { ApiCommonHelpTerms, ApiCommonHelpTermsList } from 'api/Common';
import { Swiper, SwiperSlide } from 'swiper/react';

import Header from 'component/common/header/Header';
import Footer from 'component/common/footer/Footer';

import SubNav from "component/common/nav/SubNav";

import style from './Help.module.scss';

import HelpTermsSubMenuNav from './HelpTermsSubMenuNav';

import img_faq_search_img from 'assets/images/help/faq_search_img.png';
import { useEffect, useState } from 'react';
import useSelectArrModal from 'component/common/modal/useSelectArrModal';
import { useIsMobile } from 'utils/Common';
import { isApp } from 'utils/AppUtil';


// default 값 = terms
// terms : 이용약관
// terms_paid : 유료이용약관
// free : 자유연재서비스 이용원칙
// privacy : 개인정보처리방침
// youth : 청소년 보호정책

function getTypeName(type){
    switch(type){
        case "terms": return "서비스 이용약관";
        case "free": return "자유연재서비스 이용원칙";
        case "privacy": return "개인정보처리방침";
        case "youth": return "청소년 보호정책";
        case "terms_paid": return "유료이용약관";
    }
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function HelpTerms(){


    const navigate = useBookpalNavigate();

    const isMobile = useIsMobile();

    const location = useLocation();

    const query = useQuery();

    const queryTermType = query.get("term");

    const paramTermType = location.state?.type;

    // console.log(location);
    
    const [terms, setTerms] = useState(null);
    const [termsList, setTermsList] = useState(null);
    const [termType, setTermType] = useState(queryTermType ?? paramTermType ?? "terms");
    const [id, setId] = useState(null);

    const [loading, setLoading] = useState(false);

    const [SelectModal, showModal, selectKey, setSelectKey] = useSelectArrModal(termsList, null, {
        selectFn:(key)=>{
            setId(key);
            setLoading(true);
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // 부드럽게 스크롤
            });
        },
    });

    const termsSubmit = ApiCommonHelpTerms({
        onSuccess:(data)=>{
            if(data?.status === true){
                setTerms(data?.result);
                setSelectKey(data?.result?.id);
            }
        },
        onError:(e)=>{

        }
    });

    const termsListSubmit = ApiCommonHelpTermsList({
        onSuccess:(data)=>{
            if(data?.status === true){
                
                const n_data = [];
                
                data?.result?.data.map(item=>{
                    n_data.push({
                        key: item?.id,
                        value: item?.title
                    });
                })
                setTermsList(n_data);
                console.log(n_data)
            }
        },
        onError:(e)=>{

        }
    })

    // const handlerMoveQna = () => {
    //     navigate("/mypage_menu/help/qna");
    // };

    // const handlerMoveQnaHistory = () => {
    //     navigate("/mypage_menu/help/qna_history");
    // };

    ///mypage_menu/help/notice

    const changeType = ((type)=>{
        setTermType(type);
        setId(null);
        setLoading(true);
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // 부드럽게 스크롤
        });
    });

    useEffect(()=>{
        termsSubmit({
            type:termType,
            id:id,
        });
        termsListSubmit({
            type:termType
        });
    },[]);

    useEffect(()=>{
        if(loading === true){
            termsSubmit({
                type:termType,
                id:id,
            });
            termsListSubmit({
                type:termType
            });
            setLoading(false);
        }
        
    },[loading]);


    if(terms === null){
        return <></>
    }

    return (
        <>
            <Header useMobile={false} pcTop={"-64px"}/>
            <SubNav 
                name={"이용약관 및 정책"}
                usePc={true} 
                useMobile={true} 
                usePcRightBtns={true}
                useBtnPrev={true}
                useBtnPcPrev={true}
                useBtnHome={false}

                setTop={'0px'} 
                setPcTop={'0px'} 
                />

            {/* <!-- subDepth --> */}
            <div id="subDepth" className={"color-bg-01 type_header " + style['subDepth'] + " " + style['type_header']} style={{position:"sticky",top:(isApp()?"0px":isMobile?"48px":"64px")}}>
                <Swiper className="swiper-container txt color-txt-muted"
                        slidesPerView={'auto'}
                        preventClicks={true}
                        preventClicksPropagation={false}
                        observer={true}
                        observeParents={true}
                    >
                    {/* <div className="swiper-wrapper txt color-txt-muted"> */}
                        {/* <!-- 클릭시 className="on" 추가--> */}
                        {/* <div className={"swiper-slide " + style['swiper-slide'] + " " + (termType === "terms" ? "on":"")}> */}
                        <SwiperSlide className={"swiper-slide " + style['swiper-slide'] + " " + (termType === "terms" ? "on":"")}>
                            <BookpalLink onClick={()=>{changeType("terms")} }>
                                <span className="color-btn-01 border border-soild btn-04">이용약관</span>
                            </BookpalLink>
                        </SwiperSlide>
                        {/* </div> */}
                        <SwiperSlide className={"swiper-slide " + style['swiper-slide'] + " " + (termType === "privacy" ? "on":"")}>
                        {/* <div className={"swiper-slide " + style['swiper-slide'] + " " + (termType === "privacy" ? "on":"")}> */}
                            <BookpalLink onClick={()=>{changeType("privacy")} }>
                                <span className="color-btn-01 border border-soild btn-04-active">개인정보처리방침</span>
                            </BookpalLink>
                        {/* </div> */}
                        </SwiperSlide>
                        <SwiperSlide className={"swiper-slide " + style['swiper-slide'] + " " + (termType === "free" ? "on":"")}>
                        {/* <div className={"swiper-slide " + style['swiper-slide'] + " " + (termType === "free" ? "on":"")}> */}
                            <BookpalLink onClick={()=>{changeType("free")} }>
                                <span className="color-btn-01 border border-soild btn-04">자유연재서비스 이용원칙</span>
                            </BookpalLink>
                        {/* </div> */}
                        </SwiperSlide>
                        <SwiperSlide className={"swiper-slide " + style['swiper-slide'] + " " + (termType === "youth" ? "on":"")}>
                        {/* <div className={"swiper-slide " + style['swiper-slide'] + " " + (termType === "youth" ? "on":"")}> */}
                            <BookpalLink onClick={()=>{changeType("youth")} }>
                                <span className="color-btn-01 border border-soild btn-04">청소년 보호정책</span>
                            </BookpalLink>
                        {/* </div> */}
                        </SwiperSlide>
                    {/* </div> */}
                {/* </div> */}
                </Swiper>
            </div>
            {/* <!-- //subDepth --> */}
            
            <div id="container" className={"wrapper " + style['wrapper']} style={{paddingBottom:"4rem"}}> 
                <section className={"terms_box section " + style['terms_box'] + " " + style['section']}>
                    <div className={"area_box " + style['area_box']}>
                        <div className={"desc_box " + style['desc_box']}>
                            <p className="title title-03 color-btn-01 txt">{terms?.version}</p>
                            <div className={"desc " + style['desc']}>
                                <div dangerouslySetInnerHTML={{ __html: terms?.content }} />
                            </div>
                        </div>
                        {termType !== "youth" && termType !== "free" ? 
                        <div className="fixed_button">
                            <button 
                                type="button" 
                                className="button btn_01 filled btn-05 white-always txt" 
                                onClick={()=>{showModal();}}
                                ><span>{getTypeName(termType)} {terms?.version}</span></button>
                        </div>
                        :null}
                    </div>
                </section>
            </div>
            {/* <!-- container --> */}

            {/* <Footer /> */}
            <SelectModal />
        </>
    );
}




export default HelpTerms;