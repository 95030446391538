import { Link } from "react-router-dom";
import BookpalLink from "../custom/BookpalLink";

function FreeChargeSection({ data }){


    return (
        <section className="section station wrapper banner_big">
            <BookpalLink to={""}>
                <div className="area_box radius-sm" style={{background: data?.bg_color}}>
                    <img src={data?.image} />
                </div>
            </BookpalLink>
        </section>
    )
}



export default FreeChargeSection;