import { useNavigate, useParams } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import { ApiViewerNovel, ApiViewerEpub, ApiViewerToon } from 'api/Viewer';
import useConfirmCustomBuyModal from 'component/common/modal/useConfirmCustomBuyModal';
import Header from './viewer/Header';
import Footer from './viewer/Footer';
import FooterHorizontal from './viewer/FooterHorizontal';
import ViewerScroll from './viewer/ViewerScroll';
import ViewerHorizontal from './viewer/ViewerHorizontal';

// import view

import style from './style/Contents.module.scss';

import img_icon_minus from 'assets/images/contents/icon_minus.svg';
import img_icon_plus from 'assets/images/contents/icon_plus.svg';
import img_icon_refresh from 'assets/images/icon_refresh.svg';
import { useEffect, useState } from 'react';
import { SetState, GetState, useMemberInfo } from 'utils/State';
import ViewerHorizontalReverse from './viewer/ViewerHorizontalReverse';
import FooterHorizontalReverse from './viewer/FooterHorizontalReverse';
import ViewerScrollEpub from './viewer/ViewerScrollEpub';
import FooterHorizontalScroll from './viewer/FooterHorizontalScroll';
import ViewerScrollToon from './viewer/ViewerScrollToon';
import useAlertModal from 'component/common/modal/useAlertModal';
import ViewerToast from './viewer/ViewerToast';
import ViewerComment from './ViewerComment';
import ViewerCommentReply from './ViewerCommentReply';
import BookpalKeepAlive from 'component/common/custom/BookpalKeepAlive';

function Viewer(){


    const { type, cid, mode, reply_id } = useParams();
    const setState = SetState();
    const getState = GetState();
    const navigate = useBookpalNavigate();

    const memberInfo = useMemberInfo();

    const [data, setData] = useState(null);
    const [page, setPage] = useState(null);
    const [totalPage, setTotalPage] = useState(null);

    const [toolbarVisible, setToolbarVisible] = useState(false);

    const [AlertModel, alert] = useAlertModal();
    const [BuyConfirmModal, buyConfirm] = useConfirmCustomBuyModal();

    const viewerTheme = getState.useGetViewerInfoTheme();
    const viewerStyle = getState.useGetViewerInfoStyle();

    const [viewerRealStyle, setViewerRealStyle] = useState(viewerStyle);
    const [allowStyle, setAllowStyle] = useState([]);

    const [scrollHeight, setScrollHeight] = useState(0);

    {/* <!-- // 테마 viewer-white, viewer-gray, viewer-black-02, viewer-yellow, viewer-green 클래스 추가 -->*/}
    const getTheme = (theme)=>{
        switch(theme){
            default:
            case "white": return "viewer-white";
            case "gray": return "viewer-gray";
            case "black": return "viewer-black-02";
            case "yellow": return "viewer-yellow";
            case "green": return "viewer-green";
        }
    }

    const viewerNovelSubmit = ApiViewerNovel({
        onSuccess:(data)=>{
            if(data?.status === true){
                setData(data?.result);
                return;
            }
            switch(data?.code){
                case "510":
                    alert("구매","구매가 안된 회차입니다.",()=>{
                        navigate(-1);
                    });
                    break;
                case "400":
                    alert("작품","책 정보가 존재하지 않습니다.",()=>{
                        navigate("/");
                    });
                    break;
            }
        },
        onError:(e)=>{

        }
    });
    
    const viewerEpubSubmit = ApiViewerEpub({
        onSuccess:(data)=>{
            if(data?.status === true){
                setData(data?.result);
                return;
            }
            switch(data?.code){
                case "510":
                    alert("구매","구매가 안된 회차입니다.",()=>{
                        navigate(-1);
                    });
                    break;
                case "400":
                    alert("작품","책 정보가 존재하지 않습니다.",()=>{
                        navigate("/");
                    });
                    break;
            }
        },
        onError:(e)=>{

        }
    });

    const viewerToonSubmit = ApiViewerToon({
        onSuccess:(data)=>{
            if(data?.status === true){
                setData(data?.result);
                return;
            }
            switch(data?.code){
                case "510":
                    alert("구매","구매가 안된 회차입니다.",()=>{
                        navigate(-1);
                    });
                    break;
                case "400":
                    alert("작품","책 정보가 존재하지 않습니다.",()=>{
                        navigate("/");
                    });
                    break;
            }
        },
        onError:(e)=>{

        }
    });

    useEffect(()=>{
        const _allowStyle = [];

        if(type === "toon"){
            
            
            switch(data?.zone){
                case "toon":
                    if(data?.direction === "normal"){
                        _allowStyle.push("scroll");
                    }
                    if(data?.direction === "reverse"){
                        _allowStyle.push("reverse");
                    }
                    if(data?.option_status){
                        _allowStyle.push("page");
                    }
                    break;
                case "comic":
                    if(data?.direction === "normal"){
                        _allowStyle.push("page");
                    }
                    if(data?.direction === "reverse"){
                        _allowStyle.push("reverse");
                    }
                    if(data?.option_status){
                        _allowStyle.push("scroll");
                    }
                    break;
            }
            if(_allowStyle.includes(viewerStyle)){
                setViewerRealStyle(viewerStyle);
            } else {
                setViewerRealStyle(_allowStyle[0]);
            }

            setAllowStyle(_allowStyle);
            
            return;
        }

        if(type === "novel"){
            _allowStyle.push("scroll");
            _allowStyle.push("page");
            if(_allowStyle.includes(viewerStyle)){
                setViewerRealStyle(viewerStyle);
            } else {
                setViewerRealStyle("scroll");
            }
            setAllowStyle(_allowStyle);
            return;
        }

        if(type === "epub"){
            _allowStyle.push("scroll");
            setViewerRealStyle("scroll");
            setAllowStyle(_allowStyle);
            return;
        }

        setViewerRealStyle(viewerStyle);
    },[data,viewerStyle]);

    useEffect(()=>{
        switch(type){
            case "novel":
                viewerNovelSubmit({
                    cid:cid,
                });
                break;
            case "epub":
                viewerEpubSubmit({
                    cid:cid,
                });
                break;
            case "toon":
                viewerToonSubmit({
                    cid:cid,
                });
                break;
        }
    },[cid]);

    useEffect(()=>{
        setState.setViewerInfoRun(false);

        if(!memberInfo?.is_login){
            alert("알림","로그인 해주세요.",()=>{
                navigate(-1);
            }, true);
            return;
        }

        return ()=>{
            setState.setViewerInfoRun(false);

            document.body.style.position = '';
            document.body.style.width = '';
            document.body.style.overflow = '';
            document.body.style.transform = '';
            document.body.style.webkitTransform = '';
            document.body.style.webkitOverflowScrolling = '';
            document.body.style.backgroundColor='';
        }
    },[])



    if(data === null || !memberInfo?.is_login){
        return (
            <>
                <AlertModel />
            </>
        )
    }

    // console.log("reply_id",reply_id)
    if(typeof reply_id != "undefined"){
        
        return <ViewerCommentReply 
        type={type}
        bid={data?.extra?.bid}
        cid={cid}
        reply_id={reply_id}
        />
    }


    if(mode === "comment"){
        
        return (
            <BookpalKeepAlive>
                <ViewerComment 
                type={type}
                bid={data?.extra?.bid}
                cid={cid}
                />
            </BookpalKeepAlive>
        )
    }

    return (
        <>
            {/* <!-- // 테마 viewer-white, viewer-gray, viewer-black-02, viewer-yellow, viewer-green 클래스 추가 -->*/}
            <div id="container" className={"viewer bg " + style['container'] + " " + style['viewer'] + " " + getTheme(viewerTheme)} style={{padding:"0"}}>
                {/* <!-- // 좌우모드, 역방향 일때 pagemode 추가 --> */}
                
                <div 
                    className={"viewer-content " + style['viewer-content']}
                    style={{ zIndex:100}}
                    >
                    {/* <!--viewer header--> */}
                    <Header 
                        // key={"header_"+type+"_"+cid}
                        data={data}
                        type={type}
                        bid={data?.extra?.bid}
                        cid={cid}
                        visible={toolbarVisible}
                        viewerRealStyle={viewerRealStyle}
                        allowStyle={allowStyle}
                        alert={alert}
                        buyConfirm={buyConfirm}
                        />
                    
                    {/* <!-- contents --> */}
                    <BookpalKeepAlive>
                        <>
                        {viewerRealStyle === "scroll" ? 
                            <>
                            {type==="novel"?
                            <ViewerScroll 
                                key={"viewer_scroll_"+type+"_"+cid}
                                data={data} 
                                page={page} 
                                type={type}
                                bid={data?.extra?.bid}
                                cid={cid}
                                visible={toolbarVisible}
                                viewerRealStyle={viewerRealStyle}
                                setScrollHeight={setScrollHeight}
                                setPage={setPage} 
                                setTotalPage={setTotalPage} 
                                setVisible={setToolbarVisible} 
                                alert={alert}
                                buyConfirm={buyConfirm}
                            />
                            :null}
                            {type==="toon"?
                            <ViewerScrollToon 
                                key={"viewer_scroll_"+type+"_"+cid}
                                data={data} 
                                page={page} 
                                type={type}
                                bid={data?.extra?.bid}
                                cid={cid}
                                visible={toolbarVisible}
                                viewerRealStyle={viewerRealStyle}
                                setScrollHeight={setScrollHeight}
                                setPage={setPage} 
                                setTotalPage={setTotalPage} 
                                setVisible={setToolbarVisible} 
                                alert={alert}
                                buyConfirm={buyConfirm}
                            />
                            :null}
                            {type==="epub"?
                            <ViewerScrollEpub 
                                key={"viewer_scroll_"+type+"_"+cid}
                                data={data} 
                                page={page} 
                                type={type}
                                bid={data?.extra?.bid}
                                cid={cid}
                                visible={toolbarVisible}
                                viewerRealStyle={viewerRealStyle}
                                setScrollHeight={setScrollHeight}
                                setPage={setPage} 
                                setTotalPage={setTotalPage} 
                                setVisible={setToolbarVisible} 
                                alert={alert}
                                buyConfirm={buyConfirm}
                            />
                            :null}
                            </>
                        :null}
                        {viewerRealStyle === "page" ? 
                        (
                            <>
                            {type==="epub"?
                            <ViewerScrollEpub 
                                key={"viewer_scroll_"+type+"_"+cid}
                                data={data} 
                                page={page} 
                                type={type}
                                bid={data?.extra?.bid}
                                cid={cid}
                                visible={toolbarVisible}
                                viewerRealStyle={viewerRealStyle}
                                setPage={setPage} 
                                setTotalPage={setTotalPage} 
                                setVisible={setToolbarVisible} 
                                alert={alert}
                                buyConfirm={buyConfirm}
                                />
                            :null}
                            {/* <ViewerScroll /> */}
                            {type==="novel" || type==="toon"?
                            <ViewerHorizontal 
                                key={"viewer_horizontal_"+type+"_"+cid}
                                data={data} 
                                page={page} 
                                type={type}
                                bid={data?.extra?.bid}
                                cid={cid}
                                visible={toolbarVisible}
                                viewerRealStyle={viewerRealStyle}
                                setPage={setPage} 
                                setTotalPage={setTotalPage} 
                                setVisible={setToolbarVisible}
                                alert={alert}
                                buyConfirm={buyConfirm}
                                />
                            :null}
                            </>
                        )
                        :null}

                        {viewerRealStyle === "reverse" ? 
                        (
                            <>
                            {type==="epub"?
                            <ViewerScrollEpub
                                key={"viewer_scroll_"+type+"_"+cid}
                                data={data} 
                                page={page} 
                                type={type}
                                bid={data?.extra?.bid}
                                cid={cid}
                                visible={toolbarVisible}
                                viewerRealStyle={viewerRealStyle}
                                setPage={setPage} 
                                setTotalPage={setTotalPage} 
                                setVisible={setToolbarVisible} 
                                alert={alert}
                                buyConfirm={buyConfirm}
                                />
                            :null}
                            {/* <ViewerScroll /> */}
                            {type==="novel" || type==="toon"?
                            <ViewerHorizontalReverse
                                key={"viewer_horizontalreverse_"+type+"_"+cid}
                                data={data} 
                                page={page} 
                                type={type}
                                bid={data?.extra?.bid}
                                cid={cid}
                                visible={toolbarVisible}
                                viewerRealStyle={viewerRealStyle}
                                setPage={setPage} 
                                setTotalPage={setTotalPage} 
                                setVisible={setToolbarVisible}
                                alert={alert}
                                buyConfirm={buyConfirm}
                                />
                            :null}
                            </>
                        )
                        :null}
                        </>
                    </BookpalKeepAlive>
                    
                    {viewerRealStyle === "reverse" ? 
                    <FooterHorizontalReverse 
                        // key={"footer_horizontalreverse_"+type+"_"+cid}
                        data={data} 
                        page={page}
                        totalPage={totalPage}
                        setPage={setPage}
                        visible={toolbarVisible}
                        type={type}
                        bid={data?.extra?.bid}
                        cid={cid}
                        alert={alert}
                        buyConfirm={buyConfirm}
                        />
                    :null}
                    {viewerRealStyle === "page" ? 
                    <FooterHorizontal 
                        // key={"footer_horizontal_"+type+"_"+cid}
                        data={data} 
                        page={page}
                        totalPage={totalPage}
                        setPage={setPage}
                        visible={toolbarVisible}
                        type={type}
                        bid={data?.extra?.bid}
                        cid={cid}
                        alert={alert}
                        buyConfirm={buyConfirm}
                        />
                    :null}
                    {viewerRealStyle === "scroll" ? 
                    <FooterHorizontalScroll
                        // key={"footer_horizontalscroll_"+type+"_"+cid}
                        data={data} 
                        page={page}
                        totalPage={totalPage}
                        setPage={setPage}
                        scrollHeight={scrollHeight}
                        visible={toolbarVisible}
                        type={type}
                        bid={data?.extra?.bid}
                        cid={cid}
                        alert={alert}
                        buyConfirm={buyConfirm}
                        />
                    :null}
                </div>
                


                <ViewerToast mode={viewerRealStyle} />


                {/* <script>
                    $(document).ready(function () {
                        $('.direction').toast('show');
                    });
                </script> */}

                {/* <!-- 모달 --> */}
                <div id="Modal" className="modal black_opacity-60" > 
                    <div className="modal-dialog modal-dialog-centered"> 
                        <div className="modal-content radius-xxl color-bg-modal"> 
                            <div className="modal-body"> 
                                <div className="title title-02-b color-txt-normal">타이틀 제목들어가는자리</div>
                                <div className="desc color-txt-02">
                                    <p className="title-book-03-m">
                                        <span className="text_overflow">책제목영역입니다. 책제목영역입니다. 책제목영역입니다.</span>
                                    </p>
                                    <p className="body-03-long">내용들어가는자리<br />내용들어가는자리입니다.</p>
                                </div>
                                <div className="txt_box title-01-m radius-sm color-bg-select-01">
                                    <p className="color-txt-01"><span className="color-txt-04">잔여쿠폰</span> 1개  · <span className="color-txt-04">사용쿠폰</span> 4개</p>
                                    <p className="color-btn-01 txt">쿠폰이 부족합니다.</p>
                                </div>
                                <div>
                                    <label htmlFor="agree" className="checkbox_round check-type-01 small label_on">
                                        <input type="checkbox" id="agree" />
                                        <span className="box"></span>
                                        <span className="txt color-txt-04">선물소장권 사용</span>
                                    </label>
                                </div>
                            </div> 
                            <div className="modal-footer">
                                <button type="button" className="radius-sm btn-04 txt btn_01 large label_on filled button">빨간배경 버튼</button> 
                                <button type="button" className="radius-sm btn-04 txt btn_01 large label_on outlined button">빨간줄 버튼</button> 
                                <button type="button" className="radius-xs btn-03 txt btn_03 medium label_on filled button" data-bs-dismiss="modal">취소</button> 
                            </div> 
                        </div> 
                    </div> 
                </div> 
                {/* <script> 
                let modal =  new bootstrap.Modal(document.getElementById('Modal')); 
                function Modal () { 
                    modal.show(); 
                } 
                </script> */}
                <AlertModel />
                <BuyConfirmModal />
            </div>
        </>
    );
}



export default Viewer;