import { useState, useEffect } from 'react';
import { ImgBadge } from 'utils/Images';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import BookpalLink from 'component/common/custom/BookpalLink';
import { ApiBuyChapterPay, ApiBuyBook } from 'api/Buy';
import { runPopupOrder } from 'utils/Common';
import { SetState, GetState } from 'utils/State';
import style from '../../style/Contents.module.scss';



function PrevNextNovelBtn({data, type, bid, cid, prevOrNext, alert, buyConfirm, runState}){


    const navigate = useBookpalNavigate();
    const location = useLocation();

    const setState = SetState();
    const getState = GetState();

    const viewerRun = getState.useGetViewerInfoRun();

    const novelData = (prevOrNext === "prev" ? data?.prev_chapter : data?.next_chapter);

    const [buyCheck, setBuyCheck] = useState(null);
    const [buyType, setBuyType] = useState(null);

    const buySubmit = ApiBuyBook({
        onSuccess:(_data)=>{
            if(_data?.status === true){
                getViewPageLink();
                //buyType => toast필요
                setState.setToast({
                    title:_data?.result?.msg,
                })
                // console.log("asdfasdfasdf=>_data?.msg", _data?.result?.msg)
                // alert()
                // switch(buyType){
                //     case "buy":
                //     case "v_buy":
                //         setState.setToast({
                //             title:buyCheck?.chapter_no+"화를 구매하였습니다.",
                //         })
                //         break;
                //     case "v_rent":
                //         setState.setToast({
                //             title:buyCheck?.chapter_no+"화를 대여하였습니다.",
                //         })
                //         break;
                // }
                
                return;
            }
            switch(_data?.code){
                case "514":
                    getViewPageLink();
                    setState.setToast({
                        title:_data?.result?.msg,
                    });
                    break;
                case "401":
                case "400":
                    buyConfirm({
                        title:"구매 안내",
                        bookTitle:buyCheck?.book_title,
                        content:buyCheck?.chapter_no + "화를 열람하시겠습니까?",
                        isPay:false,
                        currentConpon:_data?.result?.my_coupon_cnt,
                        useConpon:1,
                        useVoucher:false,
                        successBtnTitle:"쿠폰 충전하러 가기",
                        cancleBtnTitle:"다음에 할게요",
                        successAction:()=>{
                            runPopupOrder();
                        },
                    });
                    break;
                case "513":
                    alert("구매 안내","무료 회차입니다.");
                    break;
                case "512":
                    alert("구매 안내","구매 가능한 회차가 없습니다.");
                    break;
                case "511":
                    alert("구매 안내","이미 구매한 회차입니다.");
                    break;
                default:
                    alert("구매 안내",_data?.message);
                    break;
            }
        },
        onError:(e)=>{

        }
    })

    const checkSubmit = ApiBuyChapterPay({
        onSuccess:(_data)=>{
            if(_data?.status === true){
                const result = _data?.result;
                setBuyCheck(result);
                const my_voucher = result?.my_voucher;
                const isPay = result?.my_coupon_cnt > 0;

                if(result?.is_payable === false){
                    getViewPageLink();
                    return;
                }

                if(result?.is_paid === true){
                    getViewPageLink();
                    return;
                }

                if(result?.is_event_chapter_free === true){
                    // getViewPageLink();
                    buySubmit({
                        bid:bid,
                        cid:novelData?.cid,
                        epub:null,
                        buy_type:null,
                    });
                    return;
                }

                if(result?.is_rent === true){
                    getViewPageLink();
                    return;
                }

                if(result?.is_writer_book === true){
                    getViewPageLink();
                    return;
                }

                // if(viewerRun && runState && prevOrNext === "next"){
                if(viewerRun && prevOrNext === "next"){
                    let buy_type = "buy";
                    if(my_voucher?.voucher_rent_ok && my_voucher?.rent_cnt > 0){
                        buy_type = "v_rent";
                    } else if(my_voucher?.voucher_own_ok && my_voucher?.own_cnt > 0){
                        buy_type = "v_buy";
                    } else {
                        buy_type = "buy";
                    }
                    setBuyType(buy_type);
                    buySubmit({
                        bid:bid,
                        cid:novelData?.cid,
                        epub:false,
                        buy_type:buy_type,
                    });
                    return;
                }

                const useVoucher = buyConfirm({
                    title:"구매 안내",
                    bookTitle:result?.book_title,
                    content:result?.chapter_no + "화를 열람하시겠습니까?",
                    isPay:true,
                    currentConpon:result?.my_coupon_cnt,
                    useConpon:1,
                    isVoucher:(result?.voucher_ok && my_voucher?.voucher_own_ok),
                    successBtnTitle:(true?"소장하기":"확인"),
                    success2BtnTitle:(my_voucher?.voucher_rent_ok && my_voucher?.rent_cnt > 0 ? "대여하기 (선물이용권 1장)":""),
                    successAction:(useVoucher)=>{
                        // alert(useVoucher)
                        console.log("useVoucher",useVoucher);
                        buySubmit({
                            bid:bid,
                            cid:novelData?.cid,
                            epub:false,
                            buy_type:useVoucher ? "v_buy" : "buy",
                        });
                    },
                    success2Action:()=>{
                        buySubmit({
                            bid:bid,
                            cid:novelData?.cid,
                            epub:false,
                            buy_type:"v_rent",
                        });
                    },
                });
                return;
            }
            switch(_data?.code){
                case "400":
                    alert("알림","책정보가 없습니다.");
                    break;
                case "406":
                    alert("알림","블라인드 작품입니다.");
                    break;
                case "403":
                    alert("알림","작품 감상을 위해 로그인이 필요합니다.",()=>{
                        navigate("/login",{state:{return_path:location.pathname}});
                    });
                    break;
                default:
                    alert("알림","시스템에 문제가 발생하였습니다. 문제가 지속되면 관리자에게 문의주세요.");
                    break;
            }
        },
        onError:(e)=>{

        },
    });

    const handlerCheckPay = ()=>{

        if(novelData?.cid === 0){
            alert("뷰어","다음 회차가 없습니다.");
            return;
        }

        checkSubmit({
            bid:bid,
            cid:novelData?.cid,
        });
    }


    const getViewPageLink = ()=>{
        navigate("/view/"+type+"/"+novelData?.cid+"/viewer");
    }

    useEffect(()=>{
        if(viewerRun && runState && prevOrNext === "next"){
            handlerCheckPay();
        }
    },[runState]);

    return (
        <>
            <BookpalLink
            // to={"/view/"+data?.ct_type+"/"+data?.cid}
            // data-is_paid={data?.is_paid ? "true":"false"}
            // data-is_payable={data?.is_payable ? "true":"false"}
            onClick={(e)=>{
                e.preventDefault()
                // const is_paid = e.currentTarget.getAttribute("data-is_paid");
                // const is_payable = e.currentTarget.getAttribute("data-is_payable");
                handlerCheckPay();
            }}
            >
                {prevOrNext === "prev"?
                <>
                    <p className={"icon prev " + style['icon'] + " " + style['prev'] + " " + (data?.prev_chapter?.cid === 0 ? style['disabled']:"")}>이전화</p>
                    <p className={"label " + style['label']}></p>
                </>
                :null}
                {prevOrNext === "next"?
                <>
                    <p className={"icon next " + style['icon'] + " " + style['next'] + " " + (data?.next_chapter?.cid === 0 ? style['disabled']:"")}>다음화</p>
                    <p className={"label " + style['label']}></p>
                </>
                :null}
            </BookpalLink>
        </>
    )
}



export default PrevNextNovelBtn;