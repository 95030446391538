import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import BookpalLink from 'component/common/custom/BookpalLink';
import { ApiCommonFaqKeyword, ApiCommonFaqSearch } from 'api/Common';
import EmphasizedText from 'component/common/content/EmphasizedText';
import NewlineToBr from 'component/common/content/NewlineToBr';

import Header from 'component/common/header/Header';
import Footer from 'component/common/footer/Footer';

import SubNav from "component/common/nav/SubNav";

import style from './Help.module.scss';

import img_faq_search_img from 'assets/images/help/faq_search_img.png';
import { useEffect, useState } from 'react';

function HelpFaqSearch(){


    const navigate = useBookpalNavigate();
    const location = useLocation();
    const state = location.state;

    const [keyword, setKeyword] = useState(null);
    const [data, setData] = useState(null);
    const [searchKeyword, setSearchKeyword] = useState(state?.keyword ?? "");
    const [searchSubmitKeyword, setSearchSubmitKeyword] = useState("");
    const [selectData, setSelectData] = useState(null);
    const [clearBtn, setClearBtn] = useState(false);
    
    const handlerFaqKeyword = ApiCommonFaqKeyword({
        onSuccess:(data)=>{
            console.log(data);
            if(data?.status === true){
                setKeyword(data?.result);
                return;
            }
            setKeyword([]);
        },
        onError:(e)=>{
            alert("통신에 실패하였습니다. 다시 시도해 주세요.");
        }
    });

    const handlerFaqSearch = ApiCommonFaqSearch({
        onSuccess:(data)=>{
            if(data?.status === true){
                setData(data?.result);
                setSelectData(null);
                setSearchSubmitKeyword(searchKeyword);
                return;
            }
            setData([]);
        },
        onError:(e)=>{
            alert("통신에 실패하였습니다. 다시 시도해 주세요.");
        }
    });

    const searchSubmit = (e)=>{
        if (e.key === 'Enter') {
            handlerFaqSearch({
                search:searchKeyword
            });
        }
    }
    const searchKeywordClick = (keyword, tab)=>{
        // setSearchKeyword(keyword);
        navigate("/mypage_menu/help/faq", {state: {tab:tab,subTab:keyword}});
        // handlerFaqSearch({
        //     search:keyword
        // });
    }
    const searchOnChange = (e) => {
        const value = e.target.value;
        setSearchKeyword(value);
        setClearBtn(value?.length === 0 ? false : true);
    }
    const clearSubmit = () => {
        setSearchKeyword("");
        setClearBtn(false);
    }

    useEffect(()=>{
        handlerFaqKeyword();
        if(searchKeyword !== ""){
            searchKeywordClick(searchKeyword);
        }
    },[]);


    if(keyword === null){
        return (
            <></>
        )
    }

    return (
        <>
            <Header useMobile={false} pcTop={"-64px"} />
            <SubNav 
                name={"FAQ 검색"}
                usePc={true} 
                useMobile={true} 
                usePcRightBtns={true}
                useBtnPrev={true}
                useBtnPcPrev={true}
                useBtnHome={false}

                setTop={'0px'} 
                setPcTop={'0px'} 
                />

            {/* <!-- container --> */}
            <div id="container" className={style['containerr']}>
                <section className={"search_box wrapper " + style['search_box'] + " " + style['wrapper']}>
                    <div className={"area_box " + style['area_box']}>
                        <div className={"section s_wrap " + style['s_wrap'] + " " + style['section']}>
                            <div className={"search_text color-bg-02 radius-full input_box " + style['search_text'] + " " + style['input_box']}>
                                <div className={"text_input " + style['text_input']}>
                                    <input 
                                        type="text" 
                                        className={"text_box body-03 color-txt-normal " + style['text_box']} 
                                        placeholder="검색어를 입력하세요." 
                                        value={searchKeyword}
                                        onChange={searchOnChange}
                                        onKeyPress={searchSubmit}
                                        />
                                    <p className={"right_btns " + style['right_btns']}>
                                        <span 
                                            className={"btnClear " + style['btnClear']}
                                            onClick={clearSubmit}
                                            style={{display:(clearBtn?"block":"none")}}
                                            />
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* <!-- 검색 전 --> */}
                        {data === null ?
                            <div className={"before s_wrap " + style['before'] +" " + style['s_wrap']}>
                                <div className="section solo_section">
                                    <div className="top_con clearfix" style={{padding:"0"}}>
                                        <div className="title_con left">
                                            <h4 className="title color-txt-normal title-04 text_overflow">어떤 문제가 발생했나요?</h4>
                                            <p className="desc text_overflow title-02-m color-txt-04">자주 묻는 키워드를 살펴보세요.</p>
                                        </div>
                                        <div className="cover_img right">
                                            <img src={img_faq_search_img} />
                                        </div>
                                    </div>
                                </div>
                                <div className={"type_list section " + style['type_list'] + " " + style['section']}>
                                    <ul>
                                        {keyword && Array.isArray(keyword) ? keyword.map((item, index)=>(
                                            <li key={index}>
                                                <BookpalLink 
                                                    className="button btn_03 label_on outlined medium radius-full round"
                                                    data-tab={item?.tab}
                                                    data-keyword={item?.keyword}
                                                    onClick={(e)=>{
                                                        console.log(e.target)
                                                        const tab = e.currentTarget.getAttribute("data-tab");
                                                        const keyword = e.currentTarget.getAttribute("data-keyword");
                                                        searchKeywordClick(keyword, tab);
                                                    }}
                                                    >
                                                    <span 
                                                        className="btn-03 color-txt-04"
                                                        data-keyword={item?.keyword}
                                                        >{item?.keyword}</span>
                                                </BookpalLink>
                                            </li>
                                        )) : null}
                                    </ul>
                                </div>
                            </div>
                        : (
                            <>
                                {/* <!-- 검색 후 --> */}
                                <div className={"after section " + style['after'] + " " + style['section']}>
                                    <div className={"top_con " + style['top_con']}>
                                        <div className="left"></div>
                                        <div className="right">
                                            <span className="btn-03 color-btn-03-txt">검색결과 ({Array.isArray(data) ? data?.length : 0})</span>
                                        </div>
                                    </div>
                                    <div className="list_box">
                                        {data && !Array.isArray(data) ?
                                            <p className="no_data large color-txt-04 body-02-long">검색 결과가 없습니다.</p>
                                        :
                                            <ul className="toggle_list">
                                                {data && Array.isArray(data) ? data.map((item, index)=>{

                                                    const handler = (e) => {
                                                        
                                                        const select = e.target.getAttribute('data-index');
                                                        console.log(select);
                                                        setSelectData(selectData===select?null:select);
                                                    }
                                                    return (
                                                        <li 
                                                            key={index} 
                                                            onClick={handler} 
                                                            className="border-line border-soild radius-sm color-border-01"
                                                            style={{marginBottom:"0"}}
                                                            >
                                                            <button 
                                                                type="button" 
                                                                className={"button large color-bg-01 label_on " + (selectData==index?"click":"")}
                                                                data-index={index}
                                                                >
                                                                {/* <span className="color-txt-02 title-01-m-long">결제했는데 쿠폰이 <span className="color-btn-01 txt">충전</span>되지 않았어요!</span> */}
                                                                <span 
                                                                    className="color-txt-02 title-01-m-long"
                                                                    data-index={index}
                                                                    ><EmphasizedText 
                                                                        text={item?.q} 
                                                                        emphasizedText={searchSubmitKeyword} 
                                                                        emphasizedClassName={"color-btn-01 txt"} 
                                                                    /></span>
                                                            </button>
                                                            <div 
                                                                className="txt_box color-txt-03 body-02-long border-soild border-top-line color-bg-02 color-border-01" 
                                                                style={{display:(selectData==index?"block":"none")}}>
                                                                <div className="desc body-02-long color-txt-03">
                                                                    <NewlineToBr 
                                                                        text={item?.a}
                                                                        emphasizedText={searchSubmitKeyword} 
                                                                        emphasizedClassName={"color-btn-01 txt"} 
                                                                        />
                                                                </div>
                                                            </div>
                                                        </li>
                                                    );
                                                }) : null}
                                            </ul>
                                        }
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </section>
            </div>
            {/* <!-- container --> */}

            <Footer />
        </>
    );
}




export default HelpFaqSearch;