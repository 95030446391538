import { useApiMutation } from './common/ApiCommon';


export const ApiEventEventBind = (options) => {

    const subUrl = "/event/event_bind";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiEventEventDetail = (options) => {

    const subUrl = "/event/event_detail";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};
