import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import { ApiAuthInfo } from 'api/Auth';
import { GetState } from 'utils/State';

import useAlertModal from 'component/common/modal/useAlertModal';
import useConfirmModal from 'component/common/modal/useConfirmModal';

import Header from 'component/common/header/Header';
import Footer from 'component/common/footer/Footer';

import SubNav from "component/common/nav/SubNav";

import style from './Help.module.scss';


import img_novel from 'assets/images/novel/thumbnail_bg2.png';
import img_thumbnail_illust2 from 'assets/images/novel/thumbnail_illust2.png';

function Help(){


    const navigate = useBookpalNavigate();
    
    const [info, setInfo] = useState(null);

    const getState = GetState();
    const dot = getState.useGetDot();

    const [AlertModal, alert] = useAlertModal();
    const [ConfirmModal, showConfirmModal] = useConfirmModal({
        content:"서비스 이용을 위하여\n로그인이 필요합니다.",
        btnTitle:"로그인",
        btnCancleTitle:"다음에 할게요.",
        action:()=>{
            navigate("/login",{state:{return_path:"/mypage_menu/help/declare"}});
        }
    })

    const handlerInfo = ApiAuthInfo({
        onSuccess:(data)=>{
            if(data?.status === true){
                setInfo(data?.result);
                return;
            }
            alert("시스템 오류입니다. 다시 시도해 주세요.");
        },
        onError:(e)=>{
            alert("통신에 실패하였습니다. 다시 시도해 주세요.");
        }
    });
    // const handlerMoveEditSns = () => {
    //     navigate("/mypage/editsns");
    // };

    const handlerMoveFaq = () => {
        navigate("/mypage_menu/help/faq");
    };

    const handlerMoveNotice = () => {
        navigate("/mypage_menu/help/notice");
    };

    const handlerMoveOneQna = () => {
        navigate("/mypage_menu/help/one_qna");
    };

    const handlerMoveDeclare = () => {
        if(info?.is_login === true){
            navigate("/mypage_menu/help/declare");
        } else {
            showConfirmModal();
        }
        
    };

    const handlerMoveTerms = () => {
        navigate("/mypage_menu/help/terms");
    };

    useEffect(()=>{
        handlerInfo();
    },[]);

    // useEffect(()=>{
    //     if(info?.is_login === false){
            
    //     }
    // },[info]);


    if(info === null){
        return (
            <>
                <AlertModal />
            </>
        )
    }

    return (
        <>
            <Header useMobile={false} pcTop={"-64px"} />
            <SubNav 
                name={"고객센터"}
                usePc={true} 
                useMobile={true} 
                usePcRightBtns={true}
                useBtnPrev={true}
                useBtnPcPrev={true}
                useBtnHome={false}

                setTop={'0px'} 
                setPcTop={'0px'} 
                />


            
            {/* <!-- container --> */}
            <div id="container" className={style['containerr']}>
                <section className={"menu_list section wrapper templete " + style['menu_list'] + " " + style['section']}>
                    <ul className={"toggle_list " + style['toggle_list']}>
                        <li className="border-line border-soild radius-sm color-border-01">
                            <button 
                                type="button" 
                                className={"button large color-bg-01 label_on " + style['button']}
                                onClick={handlerMoveFaq}>
                                <p className="color-txt-02 title-01-m-long">FAQ</p>
                            </button>
                        </li> 
                        <li className="border-line border-soild radius-sm color-border-01">
                            <button 
                                type="button" 
                                className={"button large color-bg-01 label_on " + style['button']}
                                onClick={handlerMoveNotice}>
                                <p className="color-txt-02 title-01-m-long">공지사항</p>
                            </button>
                        </li>
                        <li className="border-line border-soild radius-sm color-border-01">
                            <button 
                                type="button" 
                                className={"button large color-bg-01 label_on " + style['button']}
                                onClick={handlerMoveOneQna}>
                                <p className="color-txt-02 title-01-m-long">1:1 문의{dot?.help_one_qna?<span className="dot color-btn-01 bg radius-full"></span>:null}</p>
                            </button>
                        </li>
                        <li className="border-line border-soild radius-sm color-border-01">
                            <button 
                                type="button" 
                                onClick={handlerMoveDeclare}
                                className={"button large color-bg-01 label_on " + style['button']}>
                                <p className="color-txt-02 title-01-m-long">신고하기{dot?.help_declare?<span className="dot color-btn-01 bg radius-full"></span>:null}</p>
                            </button>
                        </li>
                        <li className="border-line border-soild radius-sm color-border-01">
                            <button 
                                type="button" 
                                className={"button large color-bg-01 label_on " + style['button']}
                                onClick={handlerMoveTerms}>
                                <p className="color-txt-02 title-01-m-long">이용약관 및 정책</p>
                            </button>
                        </li>
                    </ul>
                </section>
            </div>
            {/* <!-- container --> */}

            <Footer />
            <ConfirmModal />
        </>
    );
}




export default Help;