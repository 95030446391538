import { ApiPayPayResult } from 'api/Pay';

import style from '../style/Order.module.scss'

import img_vip_notice from 'assets/images/order/img_vip_notice.png'
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';

function OrderSuccess({oid, msg}){

    const [data, setData] = useState(null);
    const navigate = useBookpalNavigate();

    const payResultSubmit = ApiPayPayResult({
        onSuccess:(data)=>{
            if(data?.status === true){
                setData(data?.result);
            }
        },
        onError:(e)=>{

        }
    });

    useEffect(()=>{
        payResultSubmit({
            oid:oid,
        })
    },[])

    if(data === null){
        return <></>
    }

    return (
        <>
            {/* <!-- 충전 헤더 --> */}
            <div id="TopHeader" className={"color-bg-01 title_header " + style['TopHeader'] + " " + style['title_header']}>
                <div className={"title_menu wrapper " + style['title_menu']}>
                    <div className={"btns left_btns " + style['btns'] + " " + style['left_btns']}></div>
                    <div className={"title-04 color-txt-normal text_overflow " + style['text_overflow'] + " " + style['title-04']}>결제 완료</div>
                    <div className={"btns right_btns " + style['btns'] + " " + style['right_btns']}></div>
                </div>
            </div>

            <div id="container" className={"s_wrap wrapper " + style['container'] + " " + style['s_wrap'] + " " + style['wrapper']} style={{margin:"0", padding:0}}>
                <section className={"area_box color-bg-01 radius-lg color-border-01 border-soild border-line " + style['area_box']}>
                    <div className={"complete_box " + style['complete_box']}>
                        <p className={"title title-03 color-txt-normal " + style['title']}>
                            {/* <!-- 일반충전 완료일때 --> */}
                            <span>주문하신 결제가</span> 완료되었습니다.
                            {/* <!-- 정기충전 완료일때 --> */}
                            {/* <!-- <span>정기충전 신청이</span> 완료되었습니다. --> */}
                            {/* <!-- 스마트충전 완료일때 --> */}
                            {/* <!-- <span>스마트충전 신청이</span> 완료되었습니다. --> */}
                        </p>
                        <ul className={"desc_box section " + style['desc_box'] + " " }>
                            <li>
                                <p className="s_title body-02 color-txt-muted">결제 내용</p>
                                <p className="s_desc body-04-long color-txt-normal">일반충전</p>
                            </li>
                            <li>
                                <p className="s_title body-02 color-txt-muted">주문 번호</p>
                                <p className="s_desc body-04-long color-txt-normal">{data?.order_id}</p>
                            </li>
                            <li>
                                <p className="s_title body-02 color-txt-muted">상품 가격</p>
                                <p className="s_desc body-04-long color-txt-normal">{parseInt(data?.pay_price_checkout,10)}원</p>
                            </li>
                            <li>
                                <p className="s_title body-02 color-txt-muted">충전 쿠폰</p>
                                <p className="s_desc body-04-long color-txt-normal">{data?.charging_coupon}개</p>
                            </li>
                            <li>
                                <p className="s_title body-02 color-txt-muted">추가 쿠폰</p>
                                <p className="s_desc body-04-long color-txt-normal">{data?.bonus_coupon}개</p>
                            </li>
                            <li>
                                <p className="s_title body-02 color-txt-muted">결제 수단</p>
                                <p className="s_desc body-04-long color-txt-normal">{data?.n_pay_method}</p>
                            </li>
                            <li>
                                <p className="s_title body-02 color-txt-muted">승인 번호</p>
                                <p className="s_desc body-04-long color-txt-normal">{data?.pay_pg_tid}</p>
                            </li>
                        </ul>
                        <p className={"benefits_img section " + style['benefits_img']}><img src={img_vip_notice} /></p>
                        <div className={"button_box section " + style['button_box']}>
                            <button 
                                type="button" 
                                className={"button btn_01 outlined label_on large btn-04 color-btn-01 txt radius-sm " + style['button'] + " " + style['txt'] + " " + style['label_on']}
                                onClick={()=>{
                                    navigate("/order/general");
                                }}
                                >돌아가기</button>
                        </div>
                        {/* <!-- 정기충전, 스마트충전 완료일때만 뜨기 --> */}
                        <p className={"section desc_tit body-03-long color-txt-05 " + style['section'] + " " + style['desc_tit']}><span>정기결제</span> 해지를 원하실 경우<br />쿠폰내역&gt;충전내역을 클릭해주세요. </p>
                    </div>
                </section>
            </div>
        </>
    )
}



export default OrderSuccess;