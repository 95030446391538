import { useEffect, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { GetState, useMemberInfo } from 'utils/State';
import style from './NavBtn.module.scss';
import BookpalLink from 'component/common/custom/BookpalLink';


function NavBtn(props){

    const topRef = useRef(null);
    const mode = props.mode || "mobile";
    const page = props.page || "";
    const active = props.active || false;
    const className = props.className || "";
    let to = props.to || "";
    const children = props.children || [];

    let state = {};

    const [isMain, setIsMain] = useState(page == "main");

    const location = useLocation();

    const memberInfo = useMemberInfo();

    const getState = GetState();
    const giftboxDot = getState.useGetDot()?.giftbox;
    const storageDot = getState.useGetDot()?.storage;

    if(to === "/storage/recent" || to === "/giftbox"){
        if(!memberInfo?.is_login){
            
            // state = {return_path:location?.pathname};
            state = {return_path:to};
            to = "/login";
        }
    }

    useEffect(() => {
        const handleScroll = () => {
            
            const scrollTop = window.scrollY;

            // console.log(scrollTop);
            const elementHeight = (topRef.current?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.previousElementSibling) 
                                    ? topRef.current?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.previousElementSibling?.offsetHeight : 0;
            // if(page == "main" ) {
            //     console.log("elementHeight",elementHeight);
            //     console.log(topRef.current.parentElement.parentElement.parentElement.parentElement.parentElement.previousElementSibling.offsetHeight);
            // }
            if(page == "main" && (scrollTop - elementHeight) > 0){
                setIsMain(false);
            }
            if(page == "main" && ( (scrollTop - 1) - elementHeight) <= 0){
                setIsMain(true);
            }
        };

        handleScroll();
        
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <li 
            ref={topRef}
            className={style[className] + " " + (mode == "pc" ? style["pc"]:style["mobile"])}>
            <BookpalLink to={to} state={state} className={(active ? style['active']:"") + " " + (isMain ? style["main"]:"")}>
                <p className={style["icon"]}>
                    {
                        (to === "/giftbox" && mode === "mobile" && giftboxDot)
                        || (to === "/storage/recent" && mode === "mobile" && storageDot)
                        ?
                        <span className="dot color-btn-01 bg radius-full"></span>
                    :null}
                </p>
                <p className={style[mode == "pc" ? "nav-btn-pc" : "nav-btn-mobile"]}>{children}</p>
            </BookpalLink>
        </li>
    )
}


export default NavBtn;