import style from '../style/Promotion.module.scss';



function Tmpl25({data}){

    const contents = data?.item?.TITLE.split("\n");

    return (
        <>
            {/* <!-- 공지사항 --> */}
            <div className={"notice_box color-bg-02 " + style['notice_box']}>
                <h3 className={"title color-txt-04 title-04 " + style['title']}>확인해주세요!</h3>
                <ul className={"desc " + style['desc']}>
                    {contents && Array.isArray(contents) && contents?.length > 0 ?
                        contents.map((item, index)=>{
                            return (
                                <li key={index} className="color-txt-05 body-04-long">{item}</li>
                            )
                        })
                    :null}
                </ul>
            </div>
        </>
    )
}



export default Tmpl25;