import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BookpalLink from "../custom/BookpalLink";
import RankingThreeLineSubSection from "./RankingThreeLineSubSection";


function RankingThreeLineSection({ tabs, title }){

    const [tabIndex, setTabIndex] = useState(null);

    useEffect(()=>{
        if(tabs !== null){
            if(tabs && Array.isArray(tabs) && tabs?.length > 0){
                setTabIndex(0);
            }
        }
    },[tabs]);

    if(tabIndex === null){
        return <></>
    }

    return (
        <section className="section temp_05 ranking grid_row3 wrapper temp_list_type template_column templete">
            <div className="tit_con clearfix">
                <h2 className="title left color-txt-normal title-02-b text_overflow">{title}</h2>
            </div>
            <div className="type_list">
                <div className="top_con clearfix">
                    <ul className="tab_list left">
                        {tabs?.length > 0 ? 
                            tabs?.map((item, index)=>{
                                return (
                                    <li key={index} className="color-txt-04 btn-03">
                                        <BookpalLink 
                                            className={"button btn_05 medium radius-full label_on " + (index == tabIndex ? "active":"")}
                                            data-tab-index={index}
                                            onClick={(e)=>{
                                                const tabIndex = e.currentTarget.getAttribute("data-tab-index");
                                                setTabIndex(tabIndex);
                                            }}
                                            >{item?.tab_title}</BookpalLink>
                                    </li>
                                )
                            })
                        :null}
                        {/* <li className="color-txt-04 btn-03">
                            <a href="#none" className="button btn_05 medium radius-full label_on active">로맨스</a>
                        </li>
                        <li className="color-txt-04 btn-03">
                            <a href="#none" className="button btn_05 medium radius-full label_on">로판</a>
                        </li>
                        <li className="color-txt-04 btn-03">
                            <a href="#none" className="button btn_05 medium radius-full label_on">BL</a>
                        </li>
                        <li className="color-txt-04 btn-03">
                            <a href="#none" className="button btn_05 medium radius-full label_on">판타지</a>
                        </li> */}
                    </ul>
                    <div className="right">
                        <BookpalLink to={tabs[tabIndex]?.tab_more_link} className="more_btn">
                            <span className="color-btn-03-txt btn-03">더보기</span>
                        </BookpalLink>
                    </div>
                </div>
                <div className="list">
                    <div className="list_1">
                        <RankingThreeLineSubSection tabs={tabIndex} data={tabs[tabIndex]?.data ?? null} />
                    </div>
                </div>
            </div>
        </section>
    );
}



export default RankingThreeLineSection;