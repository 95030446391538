import { useApiMutation, useApiMutationPaging } from './common/ApiCommon';

// export const ApiGenreGenreList = (options) => {

//     const subUrl = "/genre/genre_list";
//     const successFn = options?.onSuccess || (()=>{});
//     const errorFn = options?.onError || (()=>{});

//     const mutation = useApiMutation(subUrl, successFn, errorFn);
//     return mutation;
// };

export const ApiGenreGenreList = (options) => {
    const subUrl = "/genre/genre_list";
    const data = options?.data || {};
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});
    const bottomFn = options?.bottomFn || (()=>{});
    const bottomRef = options?.bottomRef || (()=>{});
    const perPage = options?.perPage || 30;
    const pageName = options?.pageName || "page";
    const perPageName = options?.perPageName || "per_page";

    return useApiMutationPaging(subUrl, data, successFn, errorFn, bottomFn, perPage, pageName, perPageName, bottomRef);
};

export const ApiGenreNovelHome = (options) => {

    const subUrl = "/genre/novel_home";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiGenreToonHome = (options) => {

    const subUrl = "/genre/toon_home";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};