import { useEffect, useRef, useState } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation, Link  } from 'react-router-dom';
import BookpalLink from 'component/common/custom/BookpalLink';
import { ApiOrderOrderList } from 'api/Order';
import { ApiAuthInfo } from 'api/Auth';

import useCouponInfoModal from 'component/common/modal/useCouponInfoModal';
import Header from 'component/common/header/Header';
import Footer from 'component/common/footer/Footer';

import SubNav from "component/common/nav/SubNav";

import style from './style/Mypage.module.scss';

import img_rating_label_normal from 'assets/images/rating_label_normal.svg';
import img_rating_label_vip from 'assets/images/rating_label_vip.svg';
import img_rating_label_vip_silver from 'assets/images/rating_label_vipSilver.svg';
import img_rating_label_vip_gold from 'assets/images/rating_label_vipGold.svg';
import img_rating_label_rvip from 'assets/images/rating_label_rVip.svg';
import MypageCouponChargeList from './MypageCouponChargeList';
import MypageCouponUseList from './MypageCouponUseList';
import BookpalKeepAlive from 'component/common/custom/BookpalKeepAlive';



function getImgLevel(level){
    switch(level){
        default:
        case "normal": return img_rating_label_normal;
        case "vip": return img_rating_label_vip;
        case "silver": return img_rating_label_vip_silver;
        case "gold": return img_rating_label_vip_gold;
        case "rvip": return img_rating_label_rvip;
    }
}


function MypageCoupon(){

    const location = useLocation();

    const [CouponModal, couponShow] = useCouponInfoModal();

    const [info, setInfo] = useState(null);
    const infoSubmit = ApiAuthInfo({
        onSuccess:(data)=>{
            if(data?.status === true){
                setInfo(data?.result);
            }
        },
        onError:(e)=>{

        }
    });

    useEffect(()=>{
        infoSubmit();
    },[]);

    if(info === null){
        return <></>
    }
    

    return (
        <>
            <Header useMobile={false} />
            <SubNav 
                name={"쿠폰내역"}
                usePc={true} 
                useMobile={true} 
                usePcRightBtns={true}
                useBtnPrev={true}
                useBtnPcPrev={true}
                useBtnHome={true}

                setTop={'0px'} 
                setPcTop={'64px'} 
                />
            {/* <!-- container --> */}
            <div id="container">
                <section className={"coupon_box wrapper section " + style['coupon_box'] + " " + style['wrapper'] + " " + style['section']}>
                    <div className={"my_box " + style['my_box']}>
                        <div className={"my_grade radius-sm gold_opacity-10 bg clearfix " + style['my_grade'] + " " + style['bg']}>
                            <div className={"left " + style['left']}>
                                <p className="title-01-b color-txt-normal">내 등급</p>
                            </div>
                            <div className={"right " + style['right']}>
                                <p className={"rating_label medium " + style['rating_label']}>
                                    <span className="normal">
                                        <img src={getImgLevel(info?.order_level)} className="Light_label" />
                                        {/* <img src="/images/rating_label_normal-DarkMode.svg" className="Dark_label" /> */}
                                    </span>
                                    {/* <!-- <span className="vip">
                                        <img src="/images/rating_label_vip.svg" className="Light_label">
                                        <img src="/images/rating_label_vip-DarkMode.svg" className="Dark_label">
                                    </span>
                                    <span className="vipSilver">
                                        <img src="/images/rating_label_vipSilver.svg">
                                    </span>
                                    <span className="vipGold">
                                        <img src="/images/rating_label_vipGold.svg">
                                    </span>
                                    <span className="rVip">
                                        <img src="/images/rating_label_rVip.svg" className="Light_label">
                                        <img src="/images/rating_label_rVip-DarkMode.svg" className="Dark_label">
                                    </span> --> */}
                                </p>
                            </div>
                        </div>
                        <div className={"my_coupon radius-sm gold_opacity-10 bg clearfix " + style['my_coupon']}>
                            <div className={"left " + style['left']}>
                                <p className="title-01-b color-txt-normal">내 쿠폰</p>
                            </div>
                            <div className={"right " + style['right']}>
                                <p className="number title-03-m color-txt-normal"><span className="title-03">{info?.coupon_cnt}</span>개</p>
                                <p className={"coupon_button " + style['coupon_button']}>
                                    <button 
                                        type="button" 
                                        className={"button " + style['button']}  
                                        onClick={(e)=>{
                                            couponShow();
                                        }}
                                        ><span className="body-02 color-txt-05">이번달 사라질 예정인 쿠폰 <b>0개</b></span></button>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={"temp_list_type history_list " + style['temp_list_type'] + " " + style['history_list']}>
                        <ul className={"tab_list menu_tab large " + style['tab_list'] + " " + style['menu_tab']}>
                            <li className="color-txt-muted btn-04">
                                <BookpalLink to={"charge"} className={"border border-soild border-bottom-line " + (location?.pathname === "/mypage/coupon/charge" ? "active":"")}>충전내역</BookpalLink>
                            </li>
                            <li className="color-txt-muted btn-04">
                                <BookpalLink to={"use"} className={"border border-soild border-bottom-line " + (location?.pathname === "/mypage/coupon/use" ? "active":"")}>사용내역</BookpalLink>
                            </li>
                        </ul>

                        <Routes>
                            <Route path='/charge' exact element={(
                                <BookpalKeepAlive>
                                    <MypageCouponChargeList  />
                                </BookpalKeepAlive>
                            )} />
                            <Route path='/use' exact element={(
                                <BookpalKeepAlive>
                                    <MypageCouponUseList />
                                </BookpalKeepAlive>
                            )} />
                            <Route path="*" element={<Navigate to="charge" replace={true} />} />
                        </Routes>
                        
                    </div>
                </section>
            </div>
            {/* <!-- container --> */}

            <CouponModal />
            <Footer />
            
        </>
    );
}




export default MypageCoupon;