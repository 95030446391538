//import logo from './logo.svg';
//import './App.css';
import { useEffect, useState } from 'react';
import { useLocation, useMatch, Navigate } from 'react-router-dom';
import { useSelector  } from 'react-redux';
import { GetState, SetState } from 'utils/State';
import { useCommonValues } from "context/CommonContext";
import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ApiAuthInfo } from 'api/Auth';
import { ApiCommonDot } from 'api/Common';
import { setHistoryData, isHistoryData, getStorageData, setStorageData } from 'utils/Storage';

import 'style/common/swiper-bundle.min.scss';
import 'style/common/bootstrap.min.scss';
import 'style/common/re.bootstrap.scss';

import 'style/common/common.scss';
import 'style/common/guid.scss';
import 'style/common/content.scss';
import 'style/common/mediaquery.scss';

import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';

import Checkup from 'component/pages/maintenance/Checkup';

import Main from './component/pages/main/Main';

import NovelSlide from 'component/pages/novel/NovelSlide';

import Novel from './component/pages/novel/Novel';
import NovelSub from 'component/pages/novel/NovelSub';
import NovelRomance from './component/pages/novel/sub/Romance';
import NovelRoFan from './component/pages/novel/sub/RoFan';
import NovelBL from './component/pages/novel/sub/BL';
import NovelFantasy from './component/pages/novel/sub/Fantasy';
import NovelHighLevel from './component/pages/novel/sub/HighLevel';
import NovelRanking from './component/pages/novel/sub/Ranking';
import NovelFreedom from './component/pages/novel/sub/Freedom';
import NovelEpisode from './component/pages/novel/sub/Episode';



import Toon from './component/pages/toon/Toon';
import ToonSub from 'component/pages/toon/ToonSub';
import ToonRomance from './component/pages/toon/sub/Romance';
import ToonRoFan from './component/pages/toon/sub/RoFan';
import ToonBL from './component/pages/toon/sub/BL';
import ToonFantasy from './component/pages/toon/sub/Fantasy';
import ToonHighLevel from './component/pages/toon/sub/HighLevel';
import ToonRanking from './component/pages/toon/sub/Ranking';

import PromotionMidnight from './component/pages/promotion/Midnight';
import PromotionPlusOne from 'component/pages/promotion/PlusOne';
import PromotionHotDeal from 'component/pages/promotion/HotDeal';
import PromotionCollectBook from 'component/pages/promotion/CollectBook';
import PromotionTodayWebtoon from 'component/pages/promotion/TodayWebtoon';
import PromotionBundleEvent from 'component/pages/promotion/BundleEvent';


import GiftBox from 'component/pages/giftbox/sub/GiftBox';
import GiftBoxNovel from 'component/pages/giftbox/sub/GiftBoxNovel';
import GiftBoxToon from 'component/pages/giftbox/sub/GiftBoxToon';

import StorageRecent from 'component/pages/storage/sub/Recent';
import StoragePurchase from 'component/pages/storage/sub/Purchase';
import StroageInterest from 'component/pages/storage/sub/Interest';


import DetailGiftReceive from 'component/pages/detail/gift/Receive';
import DetailGiftUse from 'component/pages/detail/gift/Use';


import ContentsHome from 'component/pages/content/ContentsHome';
import ContentsHomeReply from 'component/pages/content/ContentsHomeReply';
import ContentsDetail from 'component/pages/content/ContentsDetail';
import ContentsOptionalBuy from 'component/pages/content/ContentsOptionalBuy';
import ContentsWriter from 'component/pages/content/ContentsWriter';

import Viewer from 'component/pages/content/Viewer';
import ViewerComment from 'component/pages/content/ViewerComment';
import ViewerCommentReply from 'component/pages/content/ViewerCommentReply';

import Search from './component/pages/search/Search';

import MypageSnsLogin from 'component/pages/mypage/MypageSnsLogin';

import Mypage from './component/pages/mypage/Mypage';
import MypageEdit from 'component/pages/mypage/MypageEdit';
import MypageEditSns from 'component/pages/mypage/MypageEditSns';
import MypageUnRegister from 'component/pages/mypage/MypageUnRegist';
import MypageCoupon from 'component/pages/mypage/MypageCoupon';

import MypageBenefit from 'component/pages/mypage/sub/Benefit';
import MypageEmblem from 'component/pages/mypage/sub/Emblem';
import MypageComment from 'component/pages/mypage/MypageComment';
import MypageCommentReply from 'component/pages/mypage/MypageCommentReply';


import MypageMenuAlarm from 'component/pages/mypage_menu/alarm/Alarm';
import MypageMenuEvent from 'component/pages/mypage_menu/event/Event';
import MypageMenuEventNovel from 'component/pages/mypage_menu/event/EventNovel';
import MypageMenuEventToon from 'component/pages/mypage_menu/event/EventToon';
import MypageMenuEventBookterview from 'component/pages/mypage_menu/event/EventBookterview';

import MypageMenuHelp from 'component/pages/mypage_menu/help/Help';
import MypageMenuHelpFaq from 'component/pages/mypage_menu/help/HelpFaq';
import MypageMenuHelpFaqSearch from 'component/pages/mypage_menu/help/HelpFaqSearch';
import MypageMenuHelpNotice from 'component/pages/mypage_menu/help/HelpNotice';
import MypageMenuHelpOneToOneQna from 'component/pages/mypage_menu/help/HelpOneToOneQna';
import MypageMenuHelpQna from 'component/pages/mypage_menu/help/HelpQna';
import MypageMenuHelpQnaHistory from 'component/pages/mypage_menu/help/HelpQnaHistory';
import MypageMenuHelpDeclare from 'component/pages/mypage_menu/help/HelpDeclare';
import MypageMenuHelpDeclareHistory from 'component/pages/mypage_menu/help/HelpDeclareHistory';
import MypageMenuHelpTerms from 'component/pages/mypage_menu/help/HelpTerms';

import MypageMenuShortcut from 'component/pages/mypage_menu/shortcut/Shortcut';
import MypageMenuSetting from 'component/pages/mypage_menu/setting/Setting';
import MypageMenuSettingDevice from 'component/pages/mypage_menu/setting/SettingDevice';


import Order from 'component/pages/order/Order';
import OrderIos from 'component/pages/order/OrderIos';
import OrderBookpalBank from 'component/pages/order/OrderBookpalBank';
import OrderBookpalBankInfo from 'component/pages/order/OrderBookpalBankInfo';
import OrderVBankInfo from 'component/pages/order/OrderVBankInfo';
import OrderAndroid from 'component/pages/order/OrderAndroid';
import OrderOverseas from 'component/pages/order/OrderOverseas';
import OrderPayHistory from 'component/pages/order/pay/History';

import OrderPointPark from 'component/pages/order/OrderPointPark';
import OrderPointParkResult from 'component/pages/order/OrderPointParkResult';

import MypageGiftGiveList from 'component/pages/mypage/MypageGiftGiveList';
import MypageGiftUseList from 'component/pages/mypage/MypageGiftUseList';


import Login from './component/pages/user/login/Login';
import Logout from 'component/pages/user/logout/Logout';

import Signup from './component/pages/user/join/Signup';
import Join from './component/pages/user/join/Join';
import JoinComplete from './component/pages/user/join/JoinComplete';
import JoinCompleteSns from './component/pages/user/join/JoinCompleteSns';

import FindId from './component/pages/user/find/FindId';
import FindPw from './component/pages/user/find/FindPw';
import FindIdComplate from 'component/pages/user/find/FindIdComplate';
import FindPwComplate from 'component/pages/user/find/FindPwComplate';
import FindPwReset from 'component/pages/user/find/FindPwReset';

import ConfirmPw from 'component/pages/user/confirm/ConfirmPw';

import Cert from 'component/pages/user/cert/Cert';
import CertReturn from 'component/pages/user/cert/CertReturn';



import GiftToast from 'component/common/toast/GiftToast';
import EventBookterview from 'component/pages/mypage_menu/event/EventBookterview';
import { useIsMobile } from 'utils/Common';
import Keyword from 'component/pages/content/Keyword';
import KeepAlive, { AliveScope } from 'react-activation';
import BookpalKeepAlive from 'component/common/custom/BookpalKeepAlive';
import { appSetPath, isApp } from 'utils/AppUtil';
import useConfirmCustomModal from 'component/common/modal/useConfirmCustomModal';
import zIndex from '@mui/material/styles/zIndex';
import PopupDeclare from 'component/pages/etc/PopupDeclare';
import MainFreeview from 'component/pages/freeview/MainFreeview';
import NovelFreeview from 'component/pages/freeview/NovelFreeview';
import ToonFreeview from 'component/pages/freeview/ToonFreeview';







function ScrollToTop() {
    const { pathname, key } = useLocation();
    const isMobile = useIsMobile();
    // const checkup = useMatch("/checkup");
    const match = useMatch("/content/:type/:bid/*");
    // const viewMatch = useMatch("/view/:type/:cid");
    const isContentPath = !!match;
    const getState = GetState();
    const setState = SetState();


    const isDevPopup = process.env.REACT_APP_DEV_POPUP;

    const [ConfirmModal, confirm] = useConfirmCustomModal();
    

    // const tokens = getState.useGetTokens();
    // const memberInfo = getState.useGetMemberInfo();

    // const [loadChk, setLoadChk] = useState(false);

    const refreshDot = ApiCommonDot({
      onSuccess:(data)=>{
        if(data?.status === true){
          setState.setDot(data?.result);
        }
      },
      onError:(e)=>{

      },
    });

    useEffect(()=>{
      if(isDevPopup === "true"){
        confirm({
          title:"경고!!!!!!!!!!",
          content:"접속하신 현재 사이트는 개발중인 사이트 입니다.\n개발중인 데이터는 언제든 초기화 될 수 있습니다.",
          successAction:()=>{
            // window.location.href = "https://m.bookpal.co.kr";
          },
          noneClose:true,
        })
      }
    },[isDevPopup]);

    useEffect(() => {

      // setHistoryData(key);
      // console.log("isHistoryData(key)",isHistoryData(key));
        if(!isContentPath && !isHistoryData(key)){
          
          setTimeout(()=>{
            window.scrollTo(0,1);
          },100);
        }

        // if(!viewMatch){
        //   document.body.style.position = '';
        //   document.body.style.width = '';
        //   document.body.style.overflow = '';
        //   document.body.style.transform = '';
        //   document.body.style.webkitTransform = '';
        //   document.body.style.webkitOverflowScrolling = '';
        //   document.body.style.backgroundColor='';
        // }

        // setTimeout(()=>{
        //   window.scrollTo(0,1);
        // },100);
        // console.log("location key => ",key, " pathname => ", pathname);
        refreshDot({
          types:[
            "alarm_event",
            "alarm_mynews",
            "alarm_total",
            "alarm_update",
            "event_bookterview",
            "event_novel",
            "event_toon",
            "event_total",
            "giftbox",
            "giftbox_list",
            "help",
            "help_declare",
            "help_one_qna",
            "mypage",
            "reply_list",
            "storage",
            "storage_recent"
          ],
        });
        // setLoadChk(true);

        if(isApp()){
          appSetPath(pathname);
        }

        const update_check = getStorageData("update_check");

        if(update_check === "true"){
          setStorageData("update_check","false");
          window.location.reload(true); 
        }

    }, [pathname]);

    const { darkTheme, toggleTheme } = useCommonValues();
    
    // useEffect(()=>{
    //   if(loadChk){
    //     setState.delMemberInfo();
    //     setLoadChk(false);
    //   }
    // },[loadChk]);


    useEffect(() => {
      document.documentElement.setAttribute(
        "data-theme",
        darkTheme ? "dark" : "light"
      );
      const elem = window.document.body.querySelector("#root").firstChild || false;

      if(elem){
        elem.className = darkTheme ? "DarkMode" : "LightMode";
        if(isContentPath){
          if(!isMobile){
            elem.className = elem.className + " color-bg-02";
          } else {
            elem.className = elem.className + " color-bg-01";
          }
        }
      }
    }, [darkTheme, isContentPath, isMobile]);

    useEffect(()=>{

      var htmlElement = document.documentElement;
      htmlElement.style.fontSize = "1rem";
      // if(isMobile){
      //   htmlElement.style.fontSize = "16px";
      // } else {
      //   htmlElement.style.fontSize = "18px";
      // }

      return ()=>{
        htmlElement.style.fontSize = "";
      }
    },[isMobile]);

    return <>
      <ConfirmModal style={{zIndex:9999999}} />
    </>;
}

function initializeReactGA() {
  if(window.location.hostname === "www.bookpal.co.kr"){
    window.gtag('js', new Date());
    window.gtag('config', 'G-6BZCS0YP1V');
  }
}

function App() {

  

  useEffect(() => {
    initializeReactGA();

    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener('contextmenu', handleContextMenu);
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  const divStyle = {
    display: "flex",
    flexDirection: "column",
    minHeight: "-webkit-fill-available",
    minHeight: "80vh"
  }

  const getState = GetState();
  const setState = SetState();
  const tokens = getState.useGetTokens();
  const memberInfo = getState.useGetMemberInfo();

  const [check, setCheck] = useState(false);

  const infoSubmit = ApiAuthInfo({
    onSuccess:(data)=>{
      // console.log("infoSubmit=>",data);
      if(data?.status === true){
        setState.setMemberInfo(data?.result);
      }
      setCheck(true);
    },
    onError:(e)=>{
      setCheck(true);
    }
  })


  useEffect(()=>{
    if(!!tokens){
      infoSubmit();
    } else {
      setCheck(true);
    }
  },[tokens]);

  useEffect(()=>{
    if(!memberInfo && !!tokens){
      infoSubmit();
    }
  },[memberInfo]);


  if(!check && !memberInfo){
    return <></>
  }

  return (
    
      
      <Router>
        <ScrollToTop />
        <div className={"LightMode"} style={divStyle}>
          {/* user Data  : {userData?.token} */}
          <AliveScope>
          <Routes>

          
            <Route path='/checkup' exact element={<Checkup />} />

            <Route path='/freeview/main' exact element={<MainFreeview />} />
            <Route path='/freeview/novel' exact element={<NovelFreeview />} />
            <Route path='/freeview/toon' exact element={<ToonFreeview />} />



            
            <Route path='/' exact element={<Main />} />

            {/* <Route path='/novels/:type' exact element={<NovelSlide />} /> */}
            <Route path='/novel' exact element={
              <BookpalKeepAlive>
                <Novel />
              </BookpalKeepAlive>
            } />
            <Route path='/novel/*' exact element={<NovelSub />} />
            
            {/* <Route path='/novel/romance' exact element={<NovelRomance />} />
            <Route path='/novel/rofan' exact element={<NovelRoFan />} />
            <Route path='/novel/bl' exact element={<NovelBL />} />
            <Route path='/novel/fantasy' exact element={<NovelFantasy />} />
            <Route path='/novel/highlevel' exact element={<NovelHighLevel />} />
            <Route path='/novel/ranking' exact element={<NovelRanking />} />
            <Route path='/novel/freedom' exact element={<NovelFreedom />} />
            <Route path='/novel/episode' exact element={<NovelEpisode />} /> */}

            
            <Route path='/toon' exact element={
              <BookpalKeepAlive>
                <Toon />
              </BookpalKeepAlive>
            } />
            <Route path='/toon/*' exact element={<ToonSub />} />

            {/* <Route path='/toon/romance' exact element={<ToonRomance />} />
            <Route path='/toon/rofan' exact element={<ToonRoFan />} />
            <Route path='/toon/bl' exact element={<ToonBL />} />
            <Route path='/toon/fantasy' exact element={<ToonFantasy />} />
            <Route path='/toon/highlevel' exact element={<ToonHighLevel />} />
            <Route path='/toon/ranking' exact element={<ToonRanking />} /> */}
            
            
            <Route path='/giftbox' exact element={<GiftBox />} />
            <Route path='/giftbox/:type' exact element={<GiftBox />} />
            {/* <Route path='/giftbox/novel' exact element={<GiftBoxNovel />} />
            <Route path='/giftbox/toon' exact element={<GiftBoxToon />} /> */}

            


            <Route path='/storage/recent' exact element={(
              <BookpalKeepAlive>
                <StorageRecent />
              </BookpalKeepAlive>
            )} />
            <Route path='/storage/interest' exact element={(
              <BookpalKeepAlive>
                <StroageInterest />
              </BookpalKeepAlive>
            )} />
            <Route path='/storage/purchase' exact element={(
                <BookpalKeepAlive>
                  <StoragePurchase />
                </BookpalKeepAlive>
            )} />

            
            <Route path='/detail/gift/receive' exact element={<DetailGiftReceive />} />
            <Route path='/detail/gift/use' exact element={<DetailGiftUse />} />

            

            <Route path='/keyword' exact element={<Keyword />} />

            <Route path='/content/:type/:bid/*' exact element={(
              <ContentsHome />
            )} />
            <Route path='/content_reply/:reply_id' exact element={<ContentsHomeReply />} />
            <Route path='/content_detail/:type/:bid' exact element={<ContentsDetail />} />
            <Route path='/content_buy/:type/:bid' exact element={<ContentsOptionalBuy />} />
            <Route path='/content_writer/:name' exact element={<ContentsWriter />} />
            
            <Route path='/view/:type/:cid' exact element={(
              // <BookpalKeepAlive>
                <Viewer />
              // </BookpalKeepAlive>
            )} />
            <Route path='/view/:type/:cid/:mode' exact element={(
              // <BookpalKeepAlive>
                <Viewer />
              // </BookpalKeepAlive>
            )} />
            <Route path='/view/:type/:cid/comment/:reply_id' exact element={(
              // <BookpalKeepAlive>
              // <ViewerCommentReply />
                <Viewer />
              // </BookpalKeepAlive>
            )} />
            <Route path='/view/:type/:bid/:cid/comment' exact element={<ViewerComment />} />
            <Route path='/view/:type/:bid/:cid/comment/:reply_id' exact element={<ViewerCommentReply />} />
            


            <Route path='/promotion/midnight' exact element={<PromotionMidnight />} />
            <Route path='/promotion/plusone' exact element={<PromotionPlusOne />} />
            <Route path='/promotion/hotdeal' exact element={<PromotionHotDeal />} />
            <Route path='/promotion/collectbook' exact element={<PromotionCollectBook />} />
            <Route path='/promotion/todaywebtoon' exact element={<PromotionTodayWebtoon />} />
            <Route path='/promotion/bundleevent' exact element={<PromotionBundleEvent />} />
            
            
            <Route path='/search' element={(
                <BookpalKeepAlive>
                  <Search />
                </BookpalKeepAlive>
            )} />


            <Route path='/mypage' element={<Mypage />} />
            <Route path='/mypage_edit_sns_login' element={<MypageSnsLogin />} />
            <Route path='/mypage/edit' element={<MypageEdit />} />
            <Route path='/mypage/editsns' element={<MypageEditSns />} />
            <Route path='/mypage/unregist' element={<MypageUnRegister />} />
            <Route path='/mypage/coupon/*' element={<MypageCoupon />} />
            <Route path='/mypage/gift/givelist' element={<MypageGiftGiveList />} />
            <Route path='/mypage/gift/uselist' element={<MypageGiftUseList />} />

            
            
            <Route path='/mypage/benefit' element={<MypageBenefit />} />
            <Route path='/mypage/emblem' element={<MypageEmblem />} />

            <Route path='/mypage/comment' element={<MypageComment />} />
            <Route path='/mypage/comment/:reply_id' element={<MypageCommentReply />} />




            <Route path='/mypage_menu/alarm' element={<MypageMenuAlarm />} />
            <Route path='/mypage_menu/event' element={<MypageMenuEvent />} />
            <Route path='/mypage_menu/event/novel' element={<MypageMenuEventNovel />} />
            <Route path='/mypage_menu/event/toon' element={<MypageMenuEventToon />} />
            <Route path='/mypage_menu/event/bookterview' element={<MypageMenuEventBookterview />} />
            

            
            

            <Route path='/mypage_menu/help' element={<MypageMenuHelp />} />

            <Route path='/mypage_menu/help/faq' element={<MypageMenuHelpFaq />} />
            <Route path='/mypage_menu/help/faqsearch' element={<MypageMenuHelpFaqSearch />} />

            <Route path='/mypage_menu/help/notice' element={<MypageMenuHelpNotice />} />
            <Route path='/mypage_menu/help/one_qna' element={<MypageMenuHelpOneToOneQna />} />
            <Route path='/mypage_menu/help/qna' element={<MypageMenuHelpQna />} />
            <Route path='/mypage_menu/help/qna_history' element={<MypageMenuHelpQnaHistory />} />
            <Route path='/mypage_menu/help/declare' element={<MypageMenuHelpDeclare />} />
            <Route path='/mypage_menu/help/declare_history' element={<MypageMenuHelpDeclareHistory />} />
            <Route path='/mypage_menu/help/terms' element={<MypageMenuHelpTerms />} />
            
            <Route path='/mypage_menu/shortcut' element={<MypageMenuShortcut />} />
            <Route path='/mypage_menu/setting' element={<MypageMenuSetting />} />
            <Route path='/mypage_menu/setting/device' element={<MypageMenuSettingDevice />} />
            
            
            


            <Route path='/order/pay/history/:order_no' element={<OrderPayHistory />} />
            <Route path='/order_bookpal' element={<OrderBookpalBank />} />
            <Route path='/order_bookpal_info' element={<OrderBookpalBankInfo />} />
            <Route path='/order_vbank_info' element={<OrderVBankInfo />} />
            <Route path='/order_android/*' element={<OrderAndroid />} />
            <Route path='/order_ios/*' element={<OrderIos />} />
            
            <Route path='/order/*' element={<Order />} />
            <Route path='/order/overseas' element={<OrderOverseas />} />
            
            <Route path='/pointpark' element={(
              // <BookpalKeepAlive>
                <OrderPointPark />
              // </BookpalKeepAlive>
            )} />
            <Route path='/pointpark/result' element={<OrderPointParkResult />} />



            <Route path='/login' element={<Login />} />
            <Route path='/logout' element={<Logout />} />
            

            <Route path='/signup' element={<Signup />} />
            <Route path='/join' element={<Join />} />
            <Route path='/join_complete' element={<JoinComplete />} />
            <Route path='/join_complete_sns' element={<JoinCompleteSns />} />

            <Route path='/find_id' element={<FindId />} />
            <Route path='/find_pw' element={<FindPw />} />
            <Route path='/find_id_complate' element={<FindIdComplate />} />
            <Route path='/find_pw_complate' element={<FindPwComplate />} />
            <Route path='/find_pw_reset' element={<FindPwReset />} />

            <Route path='/confirm_pw' element={<ConfirmPw />} />

            

            <Route path='/cert' element={<Cert />} />
            <Route path='/cert_return' element={<CertReturn />} />


            <Route path='/popup/declare' element={<PopupDeclare />} />
            

            
            <Route path="*" element={<Navigate to={"/"} replace={true} />} />
          </Routes>
          </AliveScope>
          <GiftToast />
        </div>
      </Router>
      
    
  );
}

export default App;
