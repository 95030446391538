import { useNavigate } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import { ApiCommonQna } from 'api/Common';
import { useDispatch } from 'react-redux';
import { actionSetShowGiftToast } from 'action/index';
import { ApiAuthInfo } from 'api/Auth';

import useSelectModal from 'component/common/modal/useSelectModal';
import DateTimePicker from 'component/common/date/DateTimePicker';

import Header from 'component/common/header/Header';
import Footer from 'component/common/footer/Footer';

import SubNav from "component/common/nav/SubNav";
import HelpQnaSubMenuNav from './HelpQnaSubMenuNav';

import style from './Help.module.scss';


import img_faq_search_img from 'assets/images/help/faq_search_img.png';
import { useEffect, useState } from 'react';
import useAlertModal from 'component/common/modal/useAlertModal';
import { getAppsDataWithUserAgent } from 'utils/Common';


function HelpQna(){

    const navigate = useBookpalNavigate();
    const dispatch = useDispatch();

    const user_agent = getAppsDataWithUserAgent();
    const agent_device_id = user_agent?.device_id;

    const [info, setInfo] = useState(null);
    // const qnaType = "w";
    const [qnaGb, setQnaGb] = useState("dok");
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [date, setDate] = useState(null);
    const [time, setTime] = useState(null);
    const [agree, setAgree] = useState(false);
    const [isSubmit, setIsSubmit] = useState(true);

    const [AlertModal, alert] = useAlertModal();

    const handlerInfo = ApiAuthInfo({
        onSuccess:(data)=>{
            if(data?.status === true){
                setInfo(data?.result);
                return;
            }
            alert("시스템 오류입니다. 다시 시도해 주세요.");
        },
        onError:(e)=>{
            alert("통신에 실패하였습니다. 다시 시도해 주세요.");
        }
    })

    // console.log(date);
    // console.log(time);
    const dokCategoryData = {
        "0":"1. 카테고리를 선택해주세요.",
        "11":"회원가입",
        "12":"쿠폰충전",
        "13":"이용방법",
        "14":"이용오류",
        "15":"기기변경",
        "16":"건의",
        "17":"기타",
        "18":"탈퇴",
        "19":"이벤트",
        //"20":"공모전",
    };
    const [DokCategoryModal, dokCategoryModalShow, dokCategorySelectKey, initDokCategory] = useSelectModal(dokCategoryData, "0");
    const jakCategoryData = {
        "0":"1. 카테고리를 선택해주세요.",
        "1":"작품",
        "2":"전자책 출간",
        "3":"정산",
        "4":"정보수정",
        "5":"시스템 오류",
        "6":"기타",
    };
    const [JakCategoryModal, jakCategoryModalShow, jakCategorySelectKey, initJakCategory] = useSelectModal(jakCategoryData, "0");
    const osTypeData = {
        "0":"2. 카테고리를 선택해주세요.",
        "pc":"PC",
        "mobile":"모바일 웹브라우저",
        "android":"Android 앱",
        "ios":"iOS 앱",
    };
    const [OsTypeModal, osTypeModalShow, osTypeSelectKey, initOsType] = useSelectModal(osTypeData, "0");
    

    const handlerQnaGb = (e) => {
        console.log(e.target.value)
        if(qnaGb != e.target.value){
            setQnaGb(e.target.value);
            initDokCategory();
            initJakCategory();
            initOsType();
        }
    }

    const handlerQnaSubmit = ApiCommonQna({
        onSuccess:(data)=>{
            if(data?.status === true){
                alert("1:1 문의","접수 되었습니다.", ()=>{
                    navigate("/mypage_menu/help/qna_history", {replace: true});
                });
                return;
            }

            alert("1:1 문의","등록에 실패하였습니다. 문제가 지속될 경우 관리자에게 문의해 주시기 바랍니다.");
            return;
        },
        onError:(e)=>{
            alert("1:1 문의","통신에 실패하였습니다. 문제가 지속될 경우 관리자에게 문의해 주시기 바랍니다.");
            return;
        }
    });

    const handlerShowToast = (data)=>{
        const title = data?.title ?? null;
        const body = data?.body ?? null;
        const date = data?.date ?? "";
        dispatch(actionSetShowGiftToast({
            title:title,
            body:body,
            date:date,
        }));
    }

    const submit = ()=>{
        const _type = (qnaGb==="dok"?"r":(qnaGb==="jak")?"w":null);
        const _category = (qnaGb==="dok"?dokCategorySelectKey:(qnaGb==="jak")?jakCategorySelectKey:"0");
        const _title = title;
        const _content = content;
        const _os_type = (qnaGb==="dok"?osTypeSelectKey:"0");
        const _os_version = null;
        const _app_version = null;
        const _issue_date = date;
        const _issue_time = time;
        const _agree = agree;

        if(_type === null){
            handlerShowToast({title:"구분을 선택해 주세요."});
            return;
        }
        if(_category === "0"){
            handlerShowToast({title:"1. 카테고리를 선택해 주세요."});
            return;
        }
        if(_type === "r"){
            if(_os_type === "0"){
                handlerShowToast({title:"2. 카테고리를 선택해 주세요."});
                return;
            }
        }
        if(_title === ""){
            handlerShowToast({title:"제목을 입력해 주세요."});
            return;
        }
        if(_content === ""){
            handlerShowToast({title:"내용을 입력해 주세요."});
            return;
        }
        if(_agree === false){
            handlerShowToast({title:"개인정보 수집 및 이용에 동의해 주세요."});
            return;
        }
        // handlerShowToast({title:"성공."});
        // return;
        handlerQnaSubmit({
            type:_type,
            category:_category,
            title:_title,
            content:_content,
            os_type:_os_type,
            os_version:_os_version,
            app_version:_app_version,
            issue_date:_issue_date,
            issue_time:_issue_time,
            device_id:agent_device_id ?? null,
        })
    }

    useEffect(()=>{
        const _type = (qnaGb==="dok"?"r":(qnaGb==="jak")?"w":null);
        const _category = (qnaGb==="dok"?dokCategorySelectKey:(qnaGb==="jak")?jakCategorySelectKey:"0");
        const _title = title;
        const _content = content;
        const _os_type = (qnaGb==="dok"?osTypeSelectKey:"0");
        const _agree = agree;

        if(_type === null){
            setIsSubmit(true);
            return;
        }
        if(_category === "0"){
            setIsSubmit(true);
            return;
        }
        if(_type === "r"){
            if(_os_type === "0"){
                setIsSubmit(true);
                return;
            }
        }
        if(_title === ""){
            setIsSubmit(true);
            return;
        }
        if(_content === ""){
            setIsSubmit(true);
            return;
        }
        if(_agree === false){
            setIsSubmit(true);
            return;
        }
        setIsSubmit(false);
    },[qnaGb, title, content, agree, dokCategorySelectKey, jakCategorySelectKey, osTypeSelectKey]);

    useEffect(()=>{
        handlerInfo();
    },[]);

    useEffect(()=>{
        if(info?.is_login === false){
            alert(null,"잘못된 접근 입니다.",()=>{
                navigate(-1);
            });
        }
    },[info])

    if(info?.is_login !== true){
        return (
            <>
                <AlertModal />
            </>
        )
    }

    return (
        <>
            <Header useMobile={false} pcTop={"-64px"}/>
            <SubNav 
                name={"1:1 문의"}
                usePc={true} 
                useMobile={true} 
                usePcRightBtns={true}
                useBtnPrev={true}
                useBtnPcPrev={true}
                useBtnHome={false}

                setTop={'0px'} 
                setPcTop={'0px'} 
                />


            
                {/* <!-- subDepth --> */}
                <HelpQnaSubMenuNav 
                    isLogin={true}
                    to={"/mypage_menu/help/one_qna"}
                />
                {/* <!-- //subDepth --> */}
                
                {/* <!-- container --> */}
                <div id="container" className={"wrapper " + style['containerr']} style={{paddingBottom: "4rem",marginBottom:"40px"}}>
                    <section className={"input_area section " + style['input_area'] + " " + style['section']}>
                        <div className={"area_box " + style['area_box']}>
                            <ul className={"input_box " + style['input_box']}>
                                <li>
                                    <label for="a1" className={"radio_button check-type-01 small label_on " + style['radio_button']}>
                                        <input type="radio" id="a1" name="type" value={"dok"} onClick={handlerQnaGb} checked={qnaGb == "dok"}/>
                                        <span className="box"></span>
                                        <span className="txt color-txt-04">독자문의</span>
                                    </label>
                                    <label for="a2" className="radio_button check-type-01 small label_on">
                                        <input type="radio" id="a2" name="type" value={"jak"} onClick={handlerQnaGb} checked={qnaGb == "jak"}/>
                                        <span className="box"></span>
                                        <span className="txt color-txt-04">작가문의</span>
                                    </label>
                                </li>
                                <li>
                                    <p className={"input_txt body-01 color-txt-01 " + style['input_txt']}>구분</p>
                                    {/* <!-- 선택전 --> */}
                                    {qnaGb == "dok" ?
                                        <div className={"select_box text_input border-line border-soild radius-sm color-bg-01 color-border-02 " + style['select_box'] + " " + style['text_input']}>
                                            <button type="button" onClick={dokCategoryModalShow}>
                                                <span className={"body-03 " + (dokCategorySelectKey != 0 ? "color-txt-normal" : "color-txt-muted")}>{dokCategoryData[dokCategorySelectKey]}</span>
                                            </button>
                                        </div>
                                    :null}
                                    
                                    {qnaGb == "jak" ?
                                        <div className={"select_box text_input border-line border-soild radius-sm color-bg-01 color-border-02 " + style['select_box'] + " " + style['text_input']}>
                                            <button type="button" onClick={jakCategoryModalShow}>
                                                <span className={"body-03 " + (jakCategorySelectKey != 0 ? "color-txt-normal" : "color-txt-muted")}>{jakCategoryData[jakCategorySelectKey]}</span>
                                            </button>
                                        </div>
                                    :null}
                                    {/* <!-- 독자문의 일때만 --> */}
                                    {/* <!-- 선택전 --> */}
                                    {qnaGb == "dok" ?
                                        <div className={"select_box text_input border-line border-soild radius-sm color-bg-01 color-border-02 " + style['select_box'] + " " + style['text_input']}>
                                            <button type="button" onClick={osTypeModalShow}>
                                                <span className={"body-03 " + (osTypeSelectKey != 0 ? "color-txt-normal" : "color-txt-muted")}>{osTypeData[osTypeSelectKey]}</span>
                                            </button>
                                        </div>
                                    :null}
                                    {/* <!-- 선택후 --> */}
                                    {/* <div className="select_box text_input border-line border-soild radius-sm color-bg-01 color-border-02">
                                        <button type="button">
                                            <span className="body-03 color-txt-normal">부적절한 홍보</span>
                                        </button>
                                    </div> */}
                                    {/* <!-- //독자문의 일때만 --> */}
                                </li>
                                <li>
                                    <p className="input_txt body-01 color-txt-01">제목</p>
                                    <div className="text_input border-line border-soild radius-sm color-bg-01 color-border-02">
                                        <input 
                                            type="text" 
                                            placeholder="제목을 입력해주세요." 
                                            className="color-txt-normal body-03" 
                                            value={title}
                                            onChange={(e)=>{setTitle(e.target.value)}}
                                            />
                                        <p className="right_btns">
                                            <span 
                                                className="btnClear" 
                                                style={{display:(title != ""?"block":"none")}}
                                                onClick={()=>{setTitle("")}}
                                                />
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <p className="input_txt body-01 color-txt-01">내용</p>
                                    <div className="text_input border-line border-soild radius-sm color-bg-01 color-border-02">
                                        <textarea 
                                            placeholder="정확한 답변을 위해 자세한 내용을 입력해주시기 바랍니다." 
                                            className="color-txt-normal body-03-long"
                                            value={content}
                                            onChange={(e)=>{setContent(e.target.value)}}
                                            ></textarea>
                                    </div>
                                </li>
                                <li>
                                    {/* <!-- 필수항목 아님 --> */}
                                    <p className={"input_txt body-01 color-txt-01 select_txt " + style['input_txt']}>문제 발생 일시</p>
                                    <div className={"time_box select_box text_input border-line border-soild radius-sm color-bg-01 color-border-02 " + style['time_box'] + " " + style['select_box']}>
                                        <DateTimePicker 
                                            setDate={setDate}
                                            setTime={setTime}
                                        />
                                        {/* <button type="button" > */}
                                            {/* <!-- 입력 전 --> */}
                                            {/* <span className="body-03 color-txt-muted">0000년 00월 00일</span> */}
                                            {/* <!-- 입력 후 --> */}
                                            {/* <!-- <span className="body-03 color-txt-normal">0000년 00월 00일</span> --> */}
                                        {/* </button> */}
                                    </div>
                                    {/* <div className={"time_box select_box text_input border-line border-soild radius-sm color-bg-01 color-border-02 " + style['time_box'] + " " + style['select_box'] + " " + style['text_input']}> */}
                                        {/* <button type="button" return false> */}
                                            {/* <!-- 입력 전 --> */}
                                            {/* <span className="body-03 color-txt-muted">00:00</span> */}
                                            {/* <!-- 입력 후 --> */}
                                            {/* <!-- <span className="body-03 color-txt-normal">00:00</span> --> */}
                                        {/* </button> */}
                                    {/* </div> */}
                                </li>
                            </ul>
                            <ul className={"info_txt " + style['info_txt']}>
                                <li>
                                    <p className={"text body-02-long color-txt-01 " + style['text']}>문의 작성시 무분별한 욕설과 비방의 내용을 기재하실 경우, 해당 문의는 무통보 삭제 될 수 있음을 안내 해드립니다. 문의의 답변자는 누군가의 소중한 가족임을 잊지 말아 주시기 바랍니다.</p>
                                    <p className={"text body-02-long color-txt-01 " + style['text']}>문의 답변은 평일 10시 ~18시 (공휴일, 주말 제외)에 처리됩니다.</p>
                                </li>
                                <li>
                                    <p className={"title btn-03-active color-txt-01 " + style['title']}>[개인정보 수집·이용에 대한 안내]</p>
                                    <p className={"text body-02-long color-txt-01 " + style['text']}>(주)북팔은 이용자 문의를 처리하기 위해 다음과 같이 개인정보를 수집 및 이용하며, 이용자의 개인정보를 안전하게 취급하는데 최선을 다하고 있습니다.</p>
                                    <div className={"table_box color-border-01 border-top-line border-soild " + style['table_box']}>
                                        <p className={"thead color-bg-02 " + style['thead']}>
                                            <span className="color-txt-01 title-sub-02-b">수집항목</span>
                                            <span className="color-txt-01 title-sub-02-b">수집목적</span>
                                            <span className="color-txt-01 title-sub-02-b">보유기간</span>
                                        </p>
                                        <p className="tbody color-border-01 border-top-line border-bottom-line border-soild">
                                            <span className="body-01-long color-txt-03">아이디, E-mail, 모바일 단말기 정보, 문의/답변 내용에 포함된 개인정보</span>
                                            <span className="body-01-long color-txt-03">신고사항 확인 및 해결</span>
                                            <span className="body-01-long color-txt-03">1년간 보관 후 파기</span>
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <p className={"text body-02-long color-txt-01 " + style['text']}>더 자세한 내용에 대해서는 <b>개인정보처리방침</b>을 참고하시기 바랍니다.</p>
                                    <p className={"text body-02-long color-txt-01 " + style['text']}>이용자는 본 동의를 거부할 수 있으나, 미동의 시 문의 접수가 불가능합니다.</p>
                                </li>
                            </ul>
                            <div className={"check_event " + style['check_event']}>
                                <label for="agree" className="checkbox_round check-type-01 medium label_on">
                                    <input type="checkbox" id="agree" onClick={(e)=>{setAgree(e.target.checked);}} checked={agree}/>
                                    <span className="box"></span>
                                    <span className="txt color-txt-04">개인정보 수집 및 이용에 동의합니다.</span>
                                </label>
                            </div>
                            <div className="fixed_button">
                                {/* <!-- 정상적 입력값 모두 입력상태 disabled="disabled" 제거 --> */}
                                <button 
                                    type="button" 
                                    className="button btn_01 filled btn-05 white-always txt"
                                    onClick={submit}
                                    disabled={isSubmit}
                                    >접수하기</button>
                            </div>
                        </div>
                    </section>
                </div>
                {/* <!-- container --> */}

            {/* <Footer /> */}
            <DokCategoryModal />
            <JakCategoryModal />
            <OsTypeModal />
            <AlertModal />
            {/* <DatePicker /> */}
        </>
    );
}




export default HelpQna;