import { useApiMutation } from './common/ApiCommon';



export const ApiPayPayBookpalBank = (options) => {

    const subUrl = "/pay/pay_bookpal_bank";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
}; 

export const ApiPayPayResult = (options) => {

    const subUrl = "/pay/pay_result";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
}; 

export const ApiPayPayVbank = (options) => {

    const subUrl = "/pay/pay_vbank";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
}; 

export const ApiPayPayVbanks = (options) => {

    const subUrl = "/pay/pay_vbanks";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
}; 

export const ApiPayProdAndroidList = (options) => {

    const subUrl = "/pay/prod_android_list";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiPayProdIosList = (options) => {

    const subUrl = "/pay/prod_ios_list";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiPayProdGeneralList = (options) => {

    const subUrl = "/pay/prod_general_list";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiPayProdAutoList = (options) => {

    const subUrl = "/pay/prod_auto_list";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiPayProdSmileList = (options) => {

    const subUrl = "/pay/prod_smile_list";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiPayPayList = (options) => {

    const subUrl = "/pay/pay_list";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiPayReady = (options) => {

    const subUrl = "/pay/ready";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiPayAutobillLeave = (options) => {

    const subUrl = "/pay/pay_autobill_leave";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};
