import { useEffect, useState } from 'react';
import {  useNavigate } from 'react-router-dom';
import { useLocationName, useNoneMobileHeaderList, useIsMobile } from 'utils/Common';
import { isApp } from 'utils/AppUtil';
import { useBookpalNavigate } from '../custom/BookpalLink';


import style from './SubNav.module.scss';
import { GetState, SetState } from 'utils/State';

function SubNav(props){

    // const page = useLocationName();
    const isMobile = useIsMobile();
    const navigate = useBookpalNavigate();
    const setState = SetState();
    const getState = GetState();
    const setMode = props.setMode || '';
    const setPcTop = props.setPcTop || '64px';
    const setTop = props.setTop || '48px';
    const setPosition = props.setPosition || "sticky";

    const usePc = props.usePc || false;
    const useMobile = props.useMobile || false;

    const navDisplay = (usePc && !isMobile) || (useMobile && isMobile);

    const top = isMobile ? setTop : setPcTop;
  
    const name = props.name || "";

    const useAdult = props.useAdult || false;
    const statAdult = getState.useGetAdultSwitch();

    const isFavorite = props.isFavorite || false;
    const isAlarm = props.isAlarm || false;

    const usePcRightBtns = !isMobile && props.usePcRightBtns || false;

    const useBtnHome = (props.useBtnHome && isMobile) || false;
    const useBtnClose = props.useBtnClose || false;
    const useBtnFinish = props.useBtnFinish || false;
    const useBtnNotFinish = props.useBtnNotFinish || false;
    const useBtnPrev = props.useBtnPrev || false;
    const useBtnPcPrev = props.useBtnPcPrev || false;
    const useBtnFavorite = props.useBtnFavorite || false;
    const useBtnAlarm = props.useBtnAlarm || false;
    const useBtnDetail = props.useBtnDetail || false;
    const useBtnAdult = props.useBtnAdult || false;
    const useBtnFaqSearch = props.useBtnFaqSearch || false;
    const useBtnContents = props.useBtnContents || false;
    

    const onPrev = props.onPrev || (()=>{navigate(-1);});
    const onClose = props.onClose || (()=>{});
    const onFinish = props.onFinish || (()=>{});
    const onNotFinish = props.onNotFinish || (()=>{});
    const onFavorite = props.onFavorite || (()=>{});
    const onAlarm = props.onAlarm || (()=>{});
    const onDetail = props.onDetail || (()=>{});
    const onFaqSearch = props.onFaqSearch || (()=>{});
    const onContents = props.onContents || (()=>{});
    const onAdult = props.onAdult || ((e)=>{
        console.log(useAdult)
        if(useAdult){
            setIsAdult(useAdult && e.target.checked);
            setState.setAdultSwitch(useAdult && e.target.checked);
        } else {
            
        }
    });

    const resultStyleDisplay = navDisplay?"block":"none";
    const resultStyle = (top)
                        ? {"top":top, display:resultStyleDisplay, position:setPosition}
                        : {display:resultStyleDisplay, position:setPosition}

    const defaultModeClassName = "color-bg-01";
    const contentModeClassName = "contents_header";

    const [isAdult, setIsAdult] = useState(statAdult && useAdult);

    const [isCheckTop, setIsCheckTop] = useState(setMode == "content" ? true:false);

    useEffect(() => {
        

        const handleScroll = () => {
            
            if(setMode == "content"){
                const scrollTop = window.scrollY;

                // const elementHeight = (topRef.current.parentElement.previousElementSibling) ? topRef.current.parentElement.previousElementSibling.offsetHeight : 0;

                // console.log(isTop, elementHeight, scrollTop)
                if(1 < scrollTop ){
                    setIsCheckTop(  false );
                } else {
                    setIsCheckTop(  true );
                }
            } else {
                setIsCheckTop(false);
            }
        };

        handleScroll();
        
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const resultClassName = (setMode == "content" && isCheckTop) ?  contentModeClassName + " " + style['contents_header'] : defaultModeClassName;


    if(isApp()){
        return <></>
    }

    return (
        <div className={resultClassName + " " + style['navMenu']} style={resultStyle}>
            <div className={"title_menu wrapper " + style['title_menu'] + " " + style['wrapper']}>
                <div className={"btns left_btns " + style['btns']} >
                    {useBtnPcPrev && (usePc && !isMobile) ? <button type="button" className={"prev_btn " + style['prev_btn']} style={{width:"24px",marginRight:"4px"}} onClick={onPrev}></button> : null}
                    {useBtnPrev && isMobile ? <button type="button" className={"prev_btn " + style['prev_btn']} onClick={onPrev}></button> : null}
                    {useBtnHome && isMobile ? <button type="button" className={"home_btn " + style['home_btn']} onClick={()=>{navigate("/");}}></button> : null}
                </div>
                <div className={"title-04 color-txt-normal text_overflow " + style['title-04']}>{name}</div>
                <div className={"title-04 color-txt-normal text_overflow " + style['title-04']} style={{position:"relative"}}></div>
                <div className={"btns right_btns " + style['btns'] + " " + style['right_btns']} >
                    {useBtnClose ? <button type="button" className={"close_btn " + style['close_btn']} onClick={onClose} ></button> : null}
                    {useBtnFinish ? <button type="button" className={"finished_btn " + style['finished_btn']} onClick={onFinish} ></button> : null}
                    {useBtnNotFinish ? <button type="button" className={"finished_not_btn " + style['finished_not_btn']} onClick={onNotFinish} ></button> : null}
                    {useBtnFavorite ? <button type="button" className={"favorite_btn " + style['favorite_btn'] + " " + (isFavorite?style['click']:"")} onClick={onFavorite}></button> : null}
                    {useBtnAlarm ? <button type="button" className={"alarm_btn " + style['alarm_btn'] + " " + (isAlarm?style['click']:"")} onClick={onAlarm}></button> : null}
                    {useBtnDetail ? <button type="button" className={"detail_btn " + style['detail_btn']} onClick={onDetail}></button> : null}
                    {useBtnAdult ? <button type="button" className={"adult_btn " + style['adult_btn']}>
                        <label>
                            <input role="switch" type="checkbox" checked={isAdult} onChange={onAdult}/>
                        </label>
                    </button> : null}
                    {useBtnFaqSearch ? <button type="button" onClick={onFaqSearch} className={"faq_search_btn " + style['faq_search_btn']}></button> : null}
                    {useBtnContents ? <button type="button" onClick={onContents} className={"content_home_btn " + style['content_home_btn']}></button> : null}
                    
                
                </div>
            </div>
        </div>
    );
}


export default SubNav;