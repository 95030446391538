import { ApiFreePointparkReady } from "api/Free";
import { useEffect } from "react";



function OrderPointPark(){


    const readySubmit = ApiFreePointparkReady({
        onSuccess:(data)=>{
            if(data?.status === true){
                const result = data?.result;
                const form = document.createElement('form');
                form.setAttribute('method', 'post');
                form.setAttribute('action', result?.action);

                Object.keys(result).forEach(key=>{
                    const input = document.createElement('input');
                    const p_data = result[key];
                    input.setAttribute('type', 'hidden');
                    input.setAttribute('name', key);
                    input.setAttribute('value', p_data);
        
                    form.appendChild(input);
                });

                document.body.appendChild(form);
                form.submit();

                document.body.removeChild(form);

                return;
            }
        },
        onError:(e)=>{

        }
    });


    useEffect(()=>{
        readySubmit({
            return_url:process.env.REACT_APP_BASE_URL + "/pointpark/result",
        })
    },[]);



    return (<></>);
}


export default OrderPointPark;