import { useState } from 'react';
import { ImgBadge } from 'utils/Images';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import BookpalLink from 'component/common/custom/BookpalLink';
import { ApiBuyChapterPay, ApiBuyBook } from 'api/Buy';
import { runPopupOrder, getFormatBytes, formatDateString } from 'utils/Common';

import style from '../../style/Contents.module.scss';

import img_badge_free from 'assets/images/contents/badge_free-LightMode.svg';
import { SetState } from 'utils/State';
// import useAlertModal from 'component/common/modal/useAlertModal';

function Novel({data, toggleSetCids, isGetCheck}){

    return (
        // <li className={"" + (data?.is_my_view ? style['read']:"") + " " + (data?.is_my_last_view ? style['bookmark']:"")}>
        <li className={""}>
            <BookpalLink 
                data-cid={data?.cid}
                onClick={(e)=>{
                    const cid = e.currentTarget.getAttribute("data-cid");
                    toggleSetCids(cid);
                }}
                >
                <button type="button" className={"edit_select " + style['edit_select']}>
                    <label className="checkbox_round check-type-01 medium label_on" >
                        <input 
                            type="checkbox" 
                            checked={isGetCheck(data?.cid)}
                            onChange={()=>{}}
                            />
                        <span className="box"></span>
                    </label>
                </button>
                <div className={"left " + style['left']} style={{margin:"0px"}}>
                    {data?.is_extra ? <p className="color-txt-05 title-tiny-01">외전</p> : null}
                    <p className="color-txt-normal btn-03-active">{data?.is_extra ? data?.no_extra : data?.no}화</p>
                    <p className="badges">
                        {data?.is_new ? <span className="ic_up sm radius-xs icon_badge"><img src={ImgBadge("up")} /></span> : null}
                    </p>
                </div>
                <div className={"right info_txt " + style['right'] + " " + style['info_txt']}>
                    <div className={"list_tit_con " + style['list_tit_con']}>
                        {data?.is_adult ? <span className="ic_adult sm icon_badge"><img src="/images/ic_adult.svg" /></span> : null}
                        <span className="title color-txt-01 title-book-01-m text_overflow">{data?.title}</span>
                    </div>
                    <p className={"info_vertical info " + style['info_vertical'] + " " + style['info']}>
                        <span className="color-txt-04 caption-02">{formatDateString(data?.marketup_date)}</span>
                        <span className="color-txt-04 caption-02">{getFormatBytes(data?.size)}</span>
                    </p>
                    <p className={"other_info " + style['other_info']}>
                        {!data?.is_paid && !data?.is_rent && 
                            (data?.is_payable === false
                            || data?.is_event_chapter_free === true) ? 
                        <span className="free_badge free icon_badge sm">
                            {!data?.is_event_chapter_free && data?.is_payable === false ? <img src={img_badge_free} /> : null}
                            {/* <!-- 라이트모드 --> */}
                            
                            {/* <!-- 다크모드 -->
                            <!-- <img src="/contents/images/badge_free-DarkMode.svg"> --> */}
                            {data?.is_event_chapter_free === true ? <img src={ImgBadge("chapterfree")} /> : null}
                            {/* {data?.is_eventChapterFree === true ? <img src={ImgBadge("chapterfree")} /> : null} */}
                            
                        </span>
                        :null}
                        {!data?.is_paid && data?.voucher_rent_expire_ok === true ?
                            <span className="color-state-danger txt caption-02">{data?.voucher_rent_expire_txt}</span>
                        :null}
                        {!data?.is_paid && data?.voucher_use_limit_no !== false ?
                            <span className="color-txt-05 caption-02">선물이용권 이용 제한</span>
                        :null}
                        {data?.is_rent || data?.is_paid ? 
                        <>
                            {data?.is_rent ? 
                            <span className="color-state-danger txt body-02">대여 - {data?.rent_end_rest_time}</span>
                            :<span className="color-state-success txt body-02">소장</span>}
                        </>
                        :null}
                        
                    </p>
                </div>
            </BookpalLink>
        </li>
    )
}




export default Novel;