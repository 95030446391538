import { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import style from './VerticalSection.module.scss';
import { Link } from 'react-router-dom';
import BookpalLink from '../custom/BookpalLink';

import { useWidth } from 'utils/Common';
import { ImgBadge, ImgDefault, ImgCover } from 'utils/Images';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BookpalLazyLoadImage from '../custom/BookpalLazyLoadImage';
import { useMemberInfo } from 'utils/State';
import useAlertModal from '../modal/useAlertModal';



function getDefaultImgStyle(gb){
    const style = {};
    switch(gb){
        case "pc": 
            style['minWidth'] = "150px";
            style['minHeight'] = "225px";
            break;
        case "mobile": 
            style['minWidth'] = "120px";
            style['minHeight'] = "180px";
            break;
        // case ""
    }
    return style;
}

function VerticalSection(props){

    const memberInfo = useMemberInfo();

    const title = props?.title || "제목입니다";
    const moreTxt = props?.moreTxt || "더보기";
    const moreLink = props?.moreLink || null;

    

    const data = props?.data || null;

    const classNameSection = props?.classNameSection || "";
    const classNameList = props?.classNameList || "";
    const classNameTotCon = props?.classNameTotCon || "";

    const sectionType = props?.sectionType || "big";

    const useNavigateBtn = ((props?.useNavigateBtn === false ? false : true));
    const [prevBtn, setPrevBtn] = useState(false);
    const [nextBtn, setNextBtn] = useState(false);
    const useInfo = props?.useInfo || false;
    const useWriter = props?.useWriter || false;

    const swiperRef = useRef(null);

    const width = useWidth();

    const [defaultImageStyle, setDefaultImageStyle] = useState({});
    const [AlertModal, alert] = useAlertModal();

    useEffect(()=>{
        if(width > 540){
            setDefaultImageStyle(getDefaultImgStyle("pc"));
        } else {
            setDefaultImageStyle(getDefaultImgStyle("mobile"));
        }
    },[width]);

    useEffect(()=>{

        const swiper = swiperRef.current.swiper;

        const updateNavButtons = () => {
            if (swiper) {
                const isBeginning = swiper.isBeginning;
                const isEnd = swiper.isEnd;
                // setShowNavButtons(!(isBeginning && isEnd));
                // setUseNavigateBtn(!(isBeginning && isEnd))

                setPrevBtn(!isBeginning);
                setNextBtn(!isEnd);
                // console.log("isEnd",isEnd);
            }
        };

        if(swiper){
            swiper.on('slideChange', updateNavButtons);
            updateNavButtons();
        }

        return () => {
            if(swiper){
                swiper.off('slideChange', updateNavButtons);
            }
        };

    },[])


    return (
        <>
        <section className={"section temp_01 template wrapper template_row " + classNameSection}>
            <div className={"tit_con clearfix " + classNameTotCon}>
                {sectionType == "big" ?
                    <h2 className="title left color-txt-normal title-02-b text_overflow">{title}</h2>
                    : <div className="left text_overflow"><span className="color-txt-normal title-01-b">{title}</span></div>}
                {/* <h2 className="title left color-txt-normal title-02-b text_overflow">제목 들어가는 자리(세로형)</h2> */}
                
                {moreLink ? 
                <div className="right">
                    {/* <a href="#" className="more_btn"> */}
                    <BookpalLink to={moreLink} className="more_btn">
                        <span className="color-btn-03-txt btn-03">{moreTxt}</span>
                    </BookpalLink>
                    {/* </a> */}
                </div>:null}
                
            </div>
            <div className={"list vertical_list " + classNameList}>
                {/* <div className="swiper mySwiper"> */}
                <Swiper className="swiper mySwiper" 
                    ref={swiperRef}
                    slidesPerView={'auto'}
                    spaceBetween={4}
                    watchOverflow={true}
                    observer={true}
                    observeParents={true}
                    nested={true}
                    noSwiping={false}
                    // pagination={{ clickable: false }}
                    
                    breakpoints={{
                        769: {
                            freeMode : true,
                        },
                    }}
                >
                    {data === null ? null :
                        data?.map((item, index)=>{
                            return (
                                <SwiperSlide key={index}>
                                    <BookpalLink 
                                        to={"/content/"+item?.ct_type+"/"+item?.bid} 
                                        check={{
                                            view_type:memberInfo?.view_type,
                                            work_adult:item?.adult,
                                        }}
                                        alert={alert}
                                        >
                                        <div className="cover_img radius-xs">
                                            {item?.adult ? <p className="ic_adult badge_adult_normal"><img src="/images/ic_adult.svg" /></p> : null}
                                            
                                            {/* <img src={item?.cover} /> */}
                                            <BookpalLazyLoadImage 
                                                adult={item?.adult}
                                                adultswitch={memberInfo?.audit_switch}
                                                defaulttype={"cover_logo"}
                                                locktype={"cover_lock"}
                                                src={item?.cover}
                                                style={defaultImageStyle}
                                            />


                                            <p className="ic_bottom badges_box">
                                                {item?.badge_promotion !== "" ? <span className="ic_midnight radius-xs icon_badge"><img src={ImgBadge(item?.badge_promotion)} /></span> : null}
                                                {item?.badge_new_up !== "" ? <span className="ic_up radius-xs icon_badge"><img src={ImgBadge(item?.badge_new_up)} /></span> : null}
                                                {item?.badge_discount > 0 ? <span className="ic_discount radius-xs discount_10 icon_badge"><img src={ImgBadge(item?.badge_discount)} /></span> : null }
                                            </p>
                                        </div>
                                        <div className="info_txt spacing_04">
                                            {useInfo && item?.genre_kor !== "" ? <div className="info">
                                                <span className="color-txt-05 caption-02">{item?.genre_kor}</span>
                                            </div> : ""}
                                            <div className="title color-txt-normal title-book-02-m text_overflow2">{item?.title}</div>
                                            {useWriter && item?.wr_nick !== "" ? <div className="writer color-txt-03 body-01 text_overflow">{item?.wr_nick}</div> : ""}
                                        </div>
                                    </BookpalLink>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
                {/* </div> */}
                {useNavigateBtn ? 
                <div className={"arrow_btns " + style['arrow_btns']}>
                    {nextBtn ? 
                    <div 
                        className="swiper-button-next button btn_03 color-bg-01 box-shadow-xs color-border-01"
                        onClick={() => swiperRef.current.swiper.slideNext()}
                    ><img src="/images/chevron_right.svg" /></div>
                    :null}
                    {prevBtn ? 
                    <div 
                        className="swiper-button-prev button btn_03 color-bg-01 box-shadow-xs color-border-01"
                        onClick={() => swiperRef.current.swiper.slidePrev()}
                    ><img src="/images/chevron_left.svg" /></div>
                    :null}
                </div>
                :null}
            </div>
        </section>
        <AlertModal />
        </>
    )

}


export default VerticalSection;