import img_direction_scroll from 'assets/images/contents/direction_scroll.svg';
import img_direction_pagemode from 'assets/images/contents/direction_pagemode.svg';
import img_direction_pagemode_reverse from 'assets/images/contents/direction_pagemode_reverse.svg';
import { useEffect, useRef, useState } from 'react';
import { getViewerToastData, setViewerToastData } from 'utils/Storage';


function ViewerToast({mode}){

    const toastRef = useRef(null);
    const img = (mode === "scroll") ? img_direction_scroll : (mode === "page") ? img_direction_pagemode : img_direction_pagemode_reverse;

    const showStyle={
        opacity:1,
        zIndex:9999,
    };

    const hideStyle={
        opacity:0,
        zIndex:9999,
    };

    const finishStyle={
        opacity:0,
        zIndex:-1,
    }

    const [style, setStyle] = useState(finishStyle);
    // console.log("1111111111111111112211mode=>",mode);
    useEffect(()=>{
        const check = getViewerToastData(mode);
        // console.log("3333333333333mode=>",mode, check);
        if(!check){
            setTimeout(()=>{
                setStyle(showStyle);
            },500);

            setTimeout(()=>{
                setStyle(hideStyle);
            },2500);

            setTimeout(()=>{
                setStyle(finishStyle);
            },4500);
            setViewerToastData(mode);
        }
    },[mode]);

    if((mode === "scroll" || mode === "page" || mode === "reverse") ){

        //{/* <!-- // 방향이미지 스크롤: direction_scroll.svg , 좌우넘김: direction_pagemode.svg --> */}
        return (
            <div ref={toastRef} 
                style={style}
                className="toast direction viewer_toast black_opacity-80 bg radius-xs" 
                role="alert" 
                aria-live="assertive" 
                aria-atomic="true"
                mode={mode}
                >
                <div className="toast-body">
                    <div className="area">
                        
                        <img src={img} className="icon_direction" />
                        <p className="text body-03">
                            {mode === "scroll" ? "스크롤":""}
                            {mode === "page" ? "좌우 넘김":""}
                            {mode === "reverse" ? "역방향":""}
                        </p>
                    </div>
                </div>
            </div>
        )
    }
    
    return null;
}



export default ViewerToast;