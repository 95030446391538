import style from '../../style/Contents.module.scss';


function EpubHotdeal({data}){

    const discount_info = data?.discount_info || null;



    // return null;

    if(discount_info === null){
        return <></>
    }

    return (
        <>
            <div className={"left " + style['left']}>
                <p className={"icon " + style['icon'] + " " + style['icon_deal']} id="icon_deal"></p>
                <p className={"title info " + style['title'] + " " + style['info']}>
                    <span className="color-txt-01 body-03">단행본 {discount_info?.discount}% 할인</span> 
                    <span className="color-txt-05 txt body-03">{discount_info?.date_left}</span>
                </p>
            </div>
            {/* <p className={"right " + style['right']}><button type="button" className={"button btn_03 round radius-full small label_on outlined " + style['button']}><span className="btn-02 color-txt-04">구매하기</span></button></p> */}
        </>
    )
}



export default EpubHotdeal;