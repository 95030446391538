import { useLocation, useNavigate } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import CertComplete from './CertComplete';
import CertNotCouponComplete from './CertNotCouponComplete';
import { SetState } from 'utils/State';
import { isApp, appSetMove } from 'utils/AppUtil';


function CertReturn(){
    const location = useLocation();
    const navigate = useBookpalNavigate();
    const setState = SetState();

    const queryParams = new URLSearchParams(location.search);
    const dataCertReturnPath = useSelector((state) => state.data.cert_return_path);

    const error = queryParams.get("error");
    //success_coupon
    const is_success_coupon = queryParams.get("success_coupon");
    const success_msg = queryParams.get("success_msg");

    if(is_success_coupon){
        // setState.delMemberInfo();
    }
    
    useEffect(()=>{
        console.log(dataCertReturnPath);
        if(dataCertReturnPath === "/mypage/edit"){
            let state = {};
            const timestampInSeconds = Math.floor(Date.now() / 1000);
            state = {confirmEdit:true,expire:timestampInSeconds + (60*30)};
            
            if(isApp()){
                appSetMove(success_msg,dataCertReturnPath);
            } else {
                // alert(success_msg);
                navigate(dataCertReturnPath, {replace: true, state:state});
            }
        }
    },[dataCertReturnPath]);

    useEffect(()=>{
        setState.delMemberInfo();
    },[]);

    if(dataCertReturnPath === "/mypage/edit"){
        return (
            <></>
        );
    }

    if(is_success_coupon === "true"){
        
        return (
            <CertComplete />
        )
    }

    return (
        <CertNotCouponComplete />
    );
}




export default CertReturn;