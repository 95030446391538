import { getStorageData } from "utils/Storage";

import style from './Checkup.module.scss';

import img_checkup from 'assets/images/pages/checkup.png'

function Checkup(){

    const time_txt = getStorageData("checkup_time");

    return (
        <>
            {/* <!-- container --> */}
            <div id="container" className={"wrapper " + style['container'] + " " + style['wrapper']}>
            <section>
                <div className={"area_box " + style['area_box']}>
                <p className={"images " + style['images']}><img src={img_checkup} /></p>
                <div className={"text_box " + style['text_box']}>
                    <p className={"title color-txt-01 title-02-b-long " + style['title']}>
                    현재 서비스 점검중입니다.
                    </p>
                    <div className={"radius-xl color-bg-02 time_box " + style['time_box']}>
                    <p className={"s_title color-txt-05 body-02 " + style['s_title']}>점검 시간</p>
                    <p className={"time color-txt-normal title-03 " + style['time']}>
                        {time_txt}
                    </p>
                    <p className={"s_desc color-btn-01 txt body-02-long " + style['s_desc']}>
                        ※ 점검 시간은 변경 혹은 연장될 수 있습니다.
                    </p>
                    </div>
                    <p className={"desc color-txt-normal body-02-long " + style['desc']}>
                    서버 안정화를 위해 점검 진행 중입니다.<br />
                    점검 동안 서비스 이용이 불가하니 양해 바랍니다.<br />감사합니다.
                    </p>
                </div>
                <p className={"logo " +style['logo']}></p>
                </div>
            </section>
            </div>
        </>
    )
}



export default Checkup;