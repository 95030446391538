import { useState, useEffect } from "react";
import { useIsMobile } from "utils/Common";


function TopBtn({ style, isNoneShadow, className }){

    const [display, setDisplay] = useState("none");

    const isMobile = useIsMobile();

    const onScrollTop = () => {
        // window.scrollTo(0, 0);
        window.scrollTo({
          top:0,
          left:0,
          behavior:"smooth"
        });
        // document.documentElement.scrollTo({
        //   top: 0,
        //   left: 0,
        //   behavior: "instant", // Optional if you want to skip the scrolling animation
        // });
        // document.documentElement.scrollTo(0, 0);
    };

    useEffect(() => {
        const handleScroll = () => {
            
            const scrollTop = window.scrollY;

                // console.log(isTop, elementHeight, scrollTop)
            if(scrollTop > 200){
                setDisplay("block");
            } else {
                setDisplay("none");
            }
        };

        handleScroll();
        
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <aside id="top_btn" style={style ? style : (isMobile ? {display: display,paddingBottom:"5.25rem",bottom:"0px"}:{display: display,bottom:"20px"})} onClick={()=>{onScrollTop();}}>
            <button type="button" className={className + " radius-full color-border-01 border-line color-bg-01 border-soild " + (isNoneShadow ? "":"box-shadow-lg")}>
                <span>Top</span>
            </button>
        </aside>
    )
}


export default TopBtn;