import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import NewlineToBr from '../content/NewlineToBr';

import style from './useCouponInfoModal.module.scss'

function useCouponInfoModal(data){

    const title = data?.title || false;
    const content = data?.content || false;
    const btnTitle = data?.btnTitle || "확인";
    const btnCancleTitle = data?.btnCancleTitle || "취소";
    const action = data?.action || (()=>{});

    const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const handleSelect = () => {
        // if(!noneActive) setSelectKey(key);
        // if(selectFn) selectFn(key);
        action();
        handleClose();
    }

    function getCurrentYear() {
        const today = new Date();
        const currentYear = today.getFullYear(); // 현재 년도 가져오기
        return currentYear;
    }

    function getCurrentMonth() {
        const today = new Date();
        const currentMonth = today.getMonth() + 1; // JavaScript months are 0-11
        return currentMonth;
    }

    function getNextMonthYearLastTwoDigits() {
        const today = new Date();
        let nextMonth = today.getMonth() + 1; // 현재 월에서 다음 달로 이동
        let nextYear = today.getFullYear(); // 현재 년도 가져오기
    
        if (nextMonth === 12) {
            nextYear++; // 다음 달이 12월인 경우 다음 해의 1월로 넘어감
        }
    
        // 다음 해의 마지막 2자리 숫자로 변환
        let lastTwoDigits = nextYear % 100;
    
        return lastTwoDigits;
    }

    function getNextMonth() {
        const today = new Date();
        let nextMonth = today.getMonth() + 2; // JavaScript months are 0-11, 그래서 +2
        if (nextMonth > 12) {
            nextMonth = 1; // 다음 해의 1월로 넘어가는 경우
        }
        return nextMonth;
    }

    const ModalTmpl =()=>{ 
        return (
        <>
            <Modal 
                // className={"black_opacity-60 " } 
                backdropClassName="black_opacity-60 opacity-10"
                dialogClassName={"modal-dialog-centered large " + style['modal-dialog']}
                contentClassName="radius-xxl color-bg-modal"
                show={showModal}
                animation={false} 
                onHide={handleClose}
                style={{fontSize:"16px"}}
                >
                <Modal.Body className={style['modal-body']}>
                    {/* <div className={style['modal-body']}> */}
                    <div className={"title_box " + style['title_box']}>
                    <p className="title-03 color-txt-normal title">
                        이번 달 소멸 예정 쿠폰
                    </p>
                    <p className={"data title-01-m color-txt-05 " + style['data']}>
                        <span className="title-01-b color-btn-01 txt">{getCurrentYear()}</span>{"년 "}
                        <span className="title-01-b color-btn-01 txt">{getCurrentMonth()}</span>월
                    </p>
                    </div>
                    <div className={"coupon_box " + style['coupon_box']}>
                    <div className={"table_box " + style['table_box']}>
                        <p className={"title color-btn-01 bg " + style['title']}>
                        <span className="white-always txt title-sub-02-b">소멸예정쿠폰</span>
                        </p>
                        <p className={"number gold_opacity-10 bg color-txt-normal title-03-m color-border-01 " + style['number']}>
                        <span className="title-03">0</span>개
                        </p>
                    </div>
                    <p className={"info_txt title-sub-01 color-btn-01 txt " + style['info_txt']}>
                        * {getNextMonthYearLastTwoDigits()}년 {getNextMonth()}월 1일 00시 소멸 예정
                    </p>
                    </div>
                    <div className="guid_txt body-01-long color-txt-03 desc">
                    유료로 구매한 쿠폰의 유효기간은 아래와 같습니다.<br /><br />
                    <span className="color-btn-01 txt">- 2024년 3월 31일까지 유료로 구매한 쿠폰의 유효기간은 마지막
                        서비스를 이용한 시점으로부터 5년<br />
                        - 2024년 4월 1일 부터 유료로 구매한 쿠폰의 유효기간은 쿠폰 결제
                        시점을 기준으로 5년</span><br /><br />
                    쿠폰은 이용 시 유효기간이 짧은 순서 대로 우선 사용되며, 기간 내
                    사용하지 않은 쿠폰은 이월되지 않습니다.<br /><br />
                    소멸 안내 예정 쿠폰은 익월 1일 00시 일괄 소멸됩니다.<br />
                    소멸된 쿠폰은 환급되지 않기 때문에 기간 내 빠른 사용을
                    요청드립니다.
                    </div>
                    <div className={"botton_box " + style['botton_box']}>
                    <button 
                        type="button" 
                        onClick={handleClose} 
                        className={"radius-sm btn-04 txt btn_01 large label_on outlined button " + style['button']}
                        data-bs-dismiss="modal">
                        돌아가기
                    </button>
                    </div>
                    {/* </div> */}
                </Modal.Body>
                {/* <Modal.Footer>
                    <button type="button" className="radius-sm btn-04 txt btn_01 large label_on filled button" onClick={handleSelect}>{btnTitle}</button>
                    <button type="button" className="radius-xs btn-03 txt btn_03 medium label_on filled button" onClick={handleClose}>{btnCancleTitle}</button> 
                </Modal.Footer> */}
            </Modal>
        </>
    )};
    
    return [ModalTmpl, handleShow];
}


export default useCouponInfoModal;