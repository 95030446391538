import { useEffect, useState } from 'react';
import { ApiPayPayBookpalBank } from 'api/Pay';
import { useLocation } from 'react-router-dom';
import style from './style/Order.module.scss'
import useSelectModal from 'component/common/modal/useSelectModal';
import useAlertModal from 'component/common/modal/useAlertModal';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function OrderBookpalBank(){

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [bank, setBank] = useState("");

    const [isSubmit, setIsSubmit] = useState(false);

    const [AlertModel, alert] = useAlertModal();

    const query = useQuery();
    const prod_code = query.get('prod_code');
    const price = query.get('price');

    const modalData = {
        1:"국민은행",
        2:"카카오뱅크",
        3:"토스뱅크",
        4:"케이뱅크",
        5:"기업은행",
        6:"신한은행",
        7:"우리은행",
        8:"하나은행",
        9:"농협은행",
        10:"우체국",
        11:"한국씨티",
        12:"SC은행",
        13:"새마을금고",
        14:"산업은행",
        15:"신협은행",
        16:"전북은행",
        17:"제주은행",
        18:"경남은행",
        19:"광주은행",
        20:"대구은행",
        21:"부산은행"
    }
    const [Modal, modalShow, selectKey] = useSelectModal(modalData, "");

    const BookpalBankSubmit = ApiPayPayBookpalBank({
        onSuccess:(data)=>{
            if(data?.status===true){
                alert("결제","신청되었습니다.",()=>{
                    window.self.close();
                })
                return;
            }
        },
        onError:(e)=>{

        },
    })

    const handlerBankSubmit = ()=>{
        BookpalBankSubmit({
            name:name,
            phone:phone,
            bank:bank,
            prod_code:prod_code,
        });
    }


    useEffect(()=>{
        if(name===""){
            console.log("name");
            setIsSubmit(false);
            return;
        }
        if(phone===""){
            console.log("phone");
            setIsSubmit(false);
            return;
        }
        if(bank===""){
            console.log("bank");
            setIsSubmit(false);
            return;
        }
        console.log("setIsSubmit");
        setIsSubmit(true);
    },[name, phone, bank])

    useEffect(()=>{
        setBank(modalData[selectKey] ?? "");
    },[selectKey])

    return (
        <>
            {/* <!-- 충전 헤더 --> */}
            <div id="TopHeader" className={"color-bg-01 title_header " + style['TopHeader'] + " " + style['title_header']}>
                <div className={"title_menu wrapper " + style['title_menu']}>
                    <div className={"btns left_btns " + style['btns'] + " " + style['left_btns']}></div>
                    <div className={"title-04 color-txt-normal text_overflow " + style['text_overflow'] + " " + style['title-04']}>북팔 계좌이체</div>
                    <div className={"btns right_btns " + style['btns'] + " " + style['right_btns']}></div>
                </div>
            </div>

            <div id="container" className={"s_wrap wrapper " + style['container'] + " " + style['s_wrap'] + " " + style['wrapper']} style={{margin:"0 auto", padding:0}}>
                <section className={"account_box area_box " + style['account_box'] + " " + style['area_box']}>
                    <div className={"title_box radius-sm color-bg-02 " + style['title_box']}>
                        <div className={"title_txt " + style['title_txt']}>
                            <p className={"title title-01-b-long color-txt-01 " + style['title']}>계좌이체는 자동으로 처리가 불가하여<br />쿠폰지급이 늦을 수 있습니다. </p>
                            <p className={"s_title body-03-long color-txt-01 " + style['s_title']}>빠른 처리를 원하시면<br />신용카드 등 <span className={"color-btn-01 txt " + style['txt']}>다른 결제 방법</span>을 이용해주세요. </p>
                        </div>
                    </div>
                    <div className={"section " + style['section']} style={{padding:0}}>
                        <div className={"tit_con " + style['tit_con']}>
                            <h2 className={"title left text_overflow title-01-b color-txt-normal " + style['title'] + " " + style['left'] + " " + style['text_overflow']}>계좌이체 정보 입력</h2>
                        </div>
                        <ul className={"input_box " + style['input_box']}>
                            <li>
                                <p className="input_txt body-01 color-txt-01">이름</p>
                                <div className="text_input border-line border-soild radius-sm color-bg-01 color-border-02">
                                    <input 
                                        type="text" 
                                        placeholder="입금자명 입력" 
                                        className="color-txt-normal body-03" 
                                        value={name}
                                        onChange={(e)=>{
                                            setName(e.target.value);
                                        }}
                                        />
                                    <p className="right_btns">
                                        <span className="btnClear"></span>
                                    </p>
                                </div>
                            </li>
                            <li>
                                <p className="input_txt body-01 color-txt-01">연락처</p>
                                <div className="text_input border-line border-soild radius-sm color-bg-01 color-border-02">
                                    <input 
                                        type="number" 
                                        placeholder="-자리 제외 연락처 입력" 
                                        className="color-txt-normal body-03" 
                                        value={phone}
                                        onChange={(e)=>{
                                            setPhone(e.target.value);
                                        }}
                                        />
                                    <p className="right_btns">
                                        <span className="btnClear"></span>
                                    </p>
                                </div>
                            </li>
                            <li>
                                <p className="input_txt body-01 color-txt-01">입금은행</p>
                                <div className="select_box text_input border-line border-soild radius-sm color-bg-01 color-border-02">
                                    <button 
                                        type="button" 
                                        onClick={()=>{
                                            modalShow();
                                        }}>
                                        <span className={"body-03 " + (bank==""?"color-txt-muted":"")}>{bank == "" ? "입금은행 선택":bank}</span>
                                    </button>
                                </div>
                            </li>
                        </ul>
                        
                    </div>
                    <div className={"section " + style['section']} style={{padding:0}}>
                        <div className={"tit_con " + style['tit_con']}>
                            <h2 className={"title left text_overflow title-01-b color-txt-normal " + style['title'] + " " + style['left']}>북팔 입금 계좌</h2>
                        </div>
                        {/* <!-- 클릭시 클립보드 복사 --> */}
                        <div className={"deposit_num color-bg-select-01 radius-sm " + style['deposit_num']}>
                            <p className="color-txt-01 title-sub-02-b">국민은행 : 423701-01-132478 (주)북팔</p>
                        </div>
                    </div>
                    <div className={"notice_box_desc section " + style['notice_box_desc'] + " " + style['section']} style={{paddingTop:0, paddingBottom:80}}>
                        <p className="title title-02-b color-txt-04">안내</p>
                        <ul className="desc_list body-02-long color-txt-05">
                            <li>입금 처리시간 : 10:00, 14:00, 17:00 (주말 및 공휴일 : 12:00 ~ 13:00)</li>
                            <li>등록 후 3일 이내에 계좌이체를 하시지 않으시면 신청 목록에서 제외됩니다.</li>
                            <li>입금자명과 신청자명이 같지 않을 경우, 본인확인 전까지 쿠폰이 지급되지 않습니다. 꼭 동일하게 작성해 주세요.</li>
                        </ul>
                    </div>
                    <div className={"fixed_button " + style['fixed_button']}>
                        {/* <!-- 비활성화시 disabled 추가--> */}
                        <button 
                            type="button" 
                            className="button btn_01 filled btn-05 white-always txt" 
                            disabled={!isSubmit}
                            onClick={()=>{
                                handlerBankSubmit();
                            }}
                            >{price}원 신청하기</button>
                    </div>
                </section>
            </div>
            <Modal />
            <AlertModel />
        </>
    )
}




export default OrderBookpalBank;