import { useLocation } from 'react-router-dom';
import { ApiPromotionWeekly } from 'api/Promotion';
import BookpalLink from 'component/common/custom/BookpalLink';

import Header from 'component/common/header/Header';
import MobileNav from 'component/common/nav/MobileNav';

import Footer from 'component/common/footer/Footer';
import TopBtn from 'component/common/button/top/TopBtn';

import useSelectModal from 'component/common/modal/useSelectModal';
import SubNav from 'component/common/nav/SubNav';


import style from './style/Promotion.module.scss';
import { useEffect, useRef, useState } from 'react';

import { useWidth } from 'utils/Common';
import { ImgBadge, ImgDefault, ImgCover } from 'utils/Images';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BookpalLazyLoadImage from 'component/common/custom/BookpalLazyLoadImage';
import { useMemberInfo } from 'utils/State';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function TodayWebtoon(){

    const contentRef = useRef(null);

    const query = useQuery();
    const memberInfo = useMemberInfo();



    const modalData = {
        all:"전체",
        romance:"로맨스",
        romancefantasy:"로판",
        bl:"BL",
        fantasy:"판타지",
    }
    const [Modal, modalShow, selectKey, initSelect] = useSelectModal(modalData, "all");

    const sortModalData = {
        ranking:"인기순",
        update:"최신순",
    }
    const [SortModal, sortModalShow, sortSelectKey, sortInitSelect] = useSelectModal(sortModalData, "ranking");

    const [isEnd, setIsEnd] = useState((query.get('end') === "true" ? true : false));
    const [todayNum, setTodayNum] = useState(null);

    const [data, dataSubmit, initData, paging, setData] = ApiPromotionWeekly({
        data:{
            week:(isEnd ? null : todayNum),
            filter:(selectKey==="all"?null:selectKey),
            sort:sortSelectKey,
            end:(isEnd === true ? 1 : 0),
        },
        bottomRef:contentRef,
    });

    const daysOfWeek = {
        0: 7, // 일요일
        1: 1, // 월요일
        2: 2, // 화요일
        3: 3, // 수요일
        4: 4, // 목요일
        5: 5, // 금요일
        6: 6, // 토요일
        8: 8, // 비정기
    };

    useEffect(()=>{
        const today = new Date();
        const dayOfWeek = today.getDay(); // 0 (일요일)부터 6 (토요일)까지 반환
        const dayValue = daysOfWeek[dayOfWeek];
        setTodayNum(dayValue);
    },[])

    useEffect(()=>{
        initData();
        dataSubmit();
    },[todayNum, selectKey, sortSelectKey, isEnd]);

    if(data === null){
        return null;
    }


    return (
        <>
            <Header 
                useMobile={false}
            />
            {/* <MobileNav /> */}
            {/* <!-- 서브메뉴 --> */}
            <SubNav 
                name="오늘의 웹툰" 
                useHome={true} 
                usePc={true}
                useMobile={true}
                useBtnPrev={true}
                useBtnFinish={isEnd===false ? true : false}
                useBtnNotFinish={isEnd===true ? true : false}
                onFinish={()=>{
                    initSelect();
                    sortInitSelect();
                    setIsEnd(true);
                }}
                onNotFinish={()=>{
                    initSelect();
                    sortInitSelect();
                    setIsEnd(false);
                }}
                setPcTop={"64px"}
                setTop={"0px"}
                 />
            {/* <!-- //서브메뉴 --> */}

            {/* <!-- container --> */}
            <div id="container" className={"sub_wrap " + style['container']}>

                {/* <!-- 연재 --> */}
                
                <section className={"section template wrapper temp_list temp_list_type template_row " + style['section']}>
                    {isEnd === false ? 
                    <ul className="tab_list menu_tab large">
                        <li className="color-txt-muted btn-04"><BookpalLink 
                            className={"border border-soild border-bottom-line " + (todayNum===1?"active":"")}
                            onClick={()=>{
                                setTodayNum(1);
                            }}
                            >월</BookpalLink></li>
                        <li className="color-txt-muted btn-04"><BookpalLink 
                            className={"border border-soild border-bottom-line " + (todayNum===2?"active":"")}
                            onClick={()=>{
                                setTodayNum(2);
                            }}
                            >화</BookpalLink></li>
                        <li className="color-txt-muted btn-04"><BookpalLink 
                            className={"border border-soild border-bottom-line " + (todayNum===3?"active":"")}
                            onClick={()=>{
                                setTodayNum(3);
                            }}
                            >수</BookpalLink></li>
                        <li className="color-txt-muted btn-04"><BookpalLink 
                            className={"border border-soild border-bottom-line " + (todayNum===4?"active":"")}
                            onClick={()=>{
                                setTodayNum(4);
                            }}
                            >목</BookpalLink></li>
                        <li className="color-txt-muted btn-04"><BookpalLink 
                            className={"border border-soild border-bottom-line " + (todayNum===5?"active":"")}
                            onClick={()=>{
                                setTodayNum(5);
                            }}
                            >금</BookpalLink></li>
                        <li className="color-txt-muted btn-04"><BookpalLink 
                            className={"border border-soild border-bottom-line " + (todayNum===6?"active":"")}
                            onClick={()=>{
                                setTodayNum(6);
                            }}
                            >토</BookpalLink></li>
                        <li className="color-txt-muted btn-04"><BookpalLink 
                            className={"border border-soild border-bottom-line " + (todayNum===7?"active":"")}
                            onClick={()=>{
                                setTodayNum(7);
                            }}
                            >일</BookpalLink></li>
                        <li className="color-txt-muted btn-04"><BookpalLink 
                            className={"border border-soild border-bottom-line " + (todayNum===8?"active":"")}
                            onClick={()=>{
                                setTodayNum(8);
                            }}
                            >비정기</BookpalLink></li>
                    </ul>
                    :null}
                    <div className="tit_con clearfix">
                        <div className="left">
                        </div>
                        <div className="right">
                            <BookpalLink className="select_btn select_01" onClick={modalShow} ><span className="color-btn-03-txt btn-03">{modalData[selectKey]}</span></BookpalLink>
                            <BookpalLink className="select_btn select_01" onClick={sortModalShow} ><span className="color-btn-03-txt btn-03">{sortModalData[sortSelectKey]}</span></BookpalLink>
                            {/* <a href="#" className="select_btn select_02"><span className="color-btn-03-txt btn-03">인기순</span></a> */}
                        </div>
                    </div>
                    
                    <div className="list">
                        {data && Array.isArray(data) && data?.length > 0 ?
                        <div className="list_1">
                            <ul>
                                {/* <!-- 리스트 --> */}
                                {data.map((item,index)=>{

                                    return (
                                        <li>
                                            <BookpalLink to={"/content/"+item?.ct_type+"/"+item?.bid}>
                                                <div className="cover_img radius-xs">
                                                    {/* <p className="ic_adult badge_adult_normal"><img src="/images/ic_adult.svg" /></p> */}
                                                    {item?.adult ? <p className="ic_adult badge_adult_normal"><img src="/images/ic_adult.svg" /></p> : null}
                                                    {/* <img src="/images/thumbnail.jpg" /> */}
                                                    {/* <BookpalLazyLoadImage 
                                                        placeholderSrc={ImgDefault("cover_logo")}
                                                        // effect="blur"
                                                        src={ImgCover(memberInfo?.view_type, item?.adult, ImgDefault("cover_lock"), item?.cover == "" ? ImgDefault("cover_logo") : item?.cover)}
                                                        onError={(e)=>{
                                                            e.currentTarget.src = ImgDefault("cover_logo");
                                                        }}
                                                        // style={defaultImageStyle}
                                                    /> */}
                                                    <BookpalLazyLoadImage 
                                                        adult={item?.adult}
                                                        adultswitch={memberInfo?.audit_switch}
                                                        defaulttype={"cover_logo"}
                                                        locktype={"cover_lock"}
                                                        src={item?.cover}
                                                        // style={defaultImageStyle}
                                                        // className={style['cover_img']}
                                                    />
                                                    <p className="ic_bottom badges_box">
                                                        {/* <span className="ic_midnight radius-xs icon_badge"><img src="/images/midnight_badge.svg" /></span>
                                                        <span className="ic_up radius-xs icon_badge"><img src="/images/up_badge.svg" /></span>
                                                        <span className="ic_discount radius-xs discount_10 icon_badge"><img src="/images/discount_10.svg" /></span> */}
                                                        {item?.badge_promotion !== "" ? <span className="ic_midnight radius-xs icon_badge"><img src={ImgBadge(item?.badge_promotion)} /></span> : null}
                                                        {item?.badge_new_up !== "" ? <span className="ic_up radius-xs icon_badge"><img src={ImgBadge(item?.badge_new_up)} /></span> : null}
                                                        {item?.badge_discount > 0 ? <span className="ic_discount radius-xs discount_10 icon_badge"><img src={ImgBadge(item?.badge_discount)} /></span> : null }
                                                    </p>
                                                </div>
                                                <div className="info_txt spacing_04">
                                                    <div className="info">
                                                        <span className="color-txt-05 caption-02">{item?.genre_kor}</span>
                                                        <span className="color-txt-05 caption-02">{item?.is_finished ? "완결":item?.is_rest ? "휴재":"연재"}</span>
                                                    </div>
                                                    <div className="title color-txt-normal title-book-02-m text_overflow2">{item?.title}</div>
                                                    <div className="writer color-txt-03 body-01 text_overflow">{item?.wr_nick}</div>
                                                </div>
                                            </BookpalLink>
                                        </li>
                                    )
                                })}
                                
                            </ul>
                            {/* <!-- 리스트 없을때 -->
                            <!-- <p className="no_data large color-txt-04 body-02-long">더 많은 작품을 준비 중입니다.</p> -->
                            <!-- //리스트 없을때 --> */}
                        </div>
                        :<p className="no_data large color-txt-04 body-02-long">더 많은 작품을 준비 중입니다.</p>}
                        
                    </div>
                    
                </section>

            </div>
            <Modal />
            <SortModal />
            {/* <!-- container --> */}
            <Footer />
            <TopBtn />
        </>
    );
}


export default TodayWebtoon;