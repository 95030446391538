import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BookpalLink from '../custom/BookpalLink';
import RankingSubSection from './RankingSubSection';



function RankingSection({ tabs, title, isRand }){

    const [tabIndex, setTabIndex] = useState(null);

    const rand = Math.floor(Math.random() * 4);

    useEffect(()=>{
        if(tabs !== null){
            if(tabs && Array.isArray(tabs) && tabs?.length > 0){
                if(isRand){
                    setTabIndex(rand);
                    return;
                }
                setTabIndex(0);
            }
        }
    },[tabs]);

    if(tabIndex === null){
        return <></>
    }

    return (
        <section className="section temp_04 template wrapper temp_list_type ranking template_row">
            <div className="tit_con clearfix">
                <h2 className="title left color-txt-normal title-02-b text_overflow">{title}</h2>
            </div>
            <div className="type_list">
                <div className="top_con clearfix">
                    <ul className="tab_list left">
                        {tabs?.length > 0 ? 
                            tabs?.map((item, index)=>{
                                return (
                                    <li key={index} className="color-txt-04 btn-03">
                                        <BookpalLink 
                                            className={"button btn_05 medium radius-full label_on " + (index == tabIndex ? "active":"")}
                                            data-tab-index={index}
                                            onClick={(e)=>{
                                                const tabIndex = e.currentTarget.getAttribute("data-tab-index");
                                                setTabIndex(tabIndex);
                                            }}
                                            >{item?.tab_title}</BookpalLink>
                                    </li>
                                )
                            })
                        :null}
                    </ul>
                    <div className="right">
                        <BookpalLink to={tabs[tabIndex]?.tab_more_link} className="more_btn">
                            <span className="color-btn-03-txt btn-03">더보기</span>
                        </BookpalLink>
                    </div>
                </div>
                <div className="list">
                    <div className="list_1">
                        <RankingSubSection tabs={tabIndex} data={tabs[tabIndex]?.data ?? null} />
                    </div>
                </div>
            </div>
        </section>
    )
}


export default RankingSection;