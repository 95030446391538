import { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import BookpalLink from 'component/common/custom/BookpalLink';
// import { useShowGiftToast } from 'utils/ToastUtil';
import { useDispatch } from 'react-redux';
import { actionSetShowGiftToast } from 'action/index';
import { useNavigate } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import { ImgBadge, ImgDefault, ImgCover } from 'utils/Images';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BookpalLazyLoadImage from 'component/common/custom/BookpalLazyLoadImage';
import { useMemberInfo } from 'utils/State';
import { SetState } from 'utils/State';

import Header from 'component/common/header/Header';
import MobileNav from 'component/common/nav/MobileNav';

import Footer from 'component/common/footer/Footer';
import TopBtn from 'component/common/button/top/TopBtn';

import SubMenuNav from '../nav/SubMenuNav';
import useSelectModal from 'component/common/modal/useSelectModal';

import img_gift from 'assets/images/gift/gift_img.png';

import style from '../style/GiftBox.module.scss';
import { ApiMyGiftBoxList, ApiMyGiftBoxGet } from 'api/My';
import { appSet, appSetMove, isApp } from 'utils/AppUtil';
import useAlertModal from 'component/common/modal/useAlertModal';
import useConfirmCustomModal from 'component/common/modal/useConfirmCustomModal';

function GiftBox(){

    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useBookpalNavigate();
    const setState = SetState();

    const memberInfo = useMemberInfo();

    const [AlertModal, alert] = useAlertModal();
    const [ConfirmModal, confirm] = useConfirmCustomModal();

    const [giftBoxId, setGiftBoxId] = useState(null);
    const [giftInfo, setGiftInfo] = useState(null);
    const [totalGiftCnt, setTotalGiftCnt] = useState(null);
    const { type } = useParams();

    const contentRef = useRef(null);

    // console.log(type);

    // const modalData = {
    //     all:"전체",
    //     select1:"select option 01",
    //     select2:"select option 02",
    // }
    // const [Modal, modalShow, selectKey] = useSelectModal(modalData, "all");

    const [data, dataSubmit, initData, paging, setData] = ApiMyGiftBoxList({
        data:{
            ct_type:type,
        },
        bottomRef:contentRef,
        onSuccess:(data)=>{
            if(data?.status === true){
                setTotalGiftCnt(data?.result?.total_gift_cnt);
            }
        },
    });

    const giftSubmit = ApiMyGiftBoxGet({
        onSuccess:(_data)=>{
            if(_data?.status === true){
                const tmp = data;
                let gift_info = null;
                tmp.map((item, index)=>{
                    if(item.gift_info?.gift_box_id == giftBoxId){
                        item.gift_info.is_get = true;
                    }

                    tmp[index] = item;
                    console.log(item)
                    gift_info = item.gift_info;
                })

                setData(tmp);

                gift_info['title'] = _data?.result?.title;
                gift_info['date_expire'] = _data?.result?.date_expire;
                console.log(gift_info)
                setGiftInfo({...gift_info});
                setState.setToast({
                    title:_data?.result?.title, 
                    body:(_data?.result?.voucher_type === 2 ? "선물소장권":"선물이용권") + " " + _data?.result?.item_count +"장을 받았습니다.", 
                    date:_data?.result?.date_expire
                })
            }
        },
        onError:(e)=>{

        },
    });

    const selectGiftSubmit = (gift_box_id, adult)=>{
        // if(adult)


        switch(memberInfo?.view_type){
            case "N":
                alert("알림","선물을 받으시려면 로그인이 필요합니다.",()=>{
                    if(isApp()){
                        appSetMove("", "nbp://login?app_return_link="+location.pathname);
                        return;
                    }
                    navigate("/login",{state:{return_path:location.pathname}});
                });


                return false;
                break;
            case "C":
                
                // alert("알림","성인 인증이 필요합니다.",()=>{
                //     if(isApp()){
                //         appSetMove("", "nbp://cert?app_return_link="+location.pathname);
                //         return;
                //     }
                //     navigate("/cert?app_return_link="+location.pathname, {state:{return_path:location.pathname}});
                // });
                // return false;

                confirm({
                    title:"선물함 이용을 위해선\n본인인증이 필요합니다.",
                    successBtnTitle:"본인인증 하러가기",
                    cancleBtnTitle:"다음에 할게요.",
                    successAction:()=>{
                        if(isApp()){
                            appSetMove("", "nbp://cert?app_return_link="+location.pathname);
                            return;
                        }
                        navigate("/cert?app_return_link="+location.pathname, {state:{return_path:location.pathname}});
                    }
                });
                return false;
                
                break;
            case "D":
                if(adult){
                    //해당 작품은 성인 작품으로 19세 미만 청소년이 이용할 수 없습니다. 
                    alert("알림","해당 작품은 성인 작품으로 19세 미만 청소년이 이용할 수 없습니다.")
                    return false;
                }
            default:
            case "Y":
                break;
        }

        setGiftBoxId(gift_box_id);
    }


    // const useHandlerShowToast = (title, body, date)=>{
    //     dispatch(actionSetShowGiftToast({
    //         title:title,
    //         body:body,
    //         date:date,
    //     }));
    // }

    const handlerMoveDetailGiftReceive = () => {
        // navigate("/detail/gift/receive");
        ///mypage/gift/givelist
        navigate("/mypage/gift/givelist");
    }

    const getCtTypeName = (ct_type)=>{
        switch(ct_type){
            case "novel": return "웹소설";
            case "epub": return "단행본";
            case "toon": return "웹툰";
            case "comic": return "웹툰";
            default: return null;
        }
    }

    useEffect(()=>{
        initData();
        dataSubmit();
    },[type]);

    useEffect(()=>{
        // setState.setToast({
        //     title:giftInfo?.title, 
        //     body:(giftInfo?.voucher_type === 2 ? "선물소장권":"선물이용권") + " " + giftInfo?.voucher_cnt +"장을 받았습니다.", 
        //     date:giftInfo?.date_expire
        // })
    }, [giftInfo])

    useEffect(()=>{
        if(giftBoxId){
            giftSubmit({
                gift_box_id:giftBoxId,
            })
        }
    },[giftBoxId])

    useEffect(()=>{
        dataSubmit();
    },[])

    if(data === null){
        return (
            <>
            {isApp() ? null:<Header title={"선물함"} />}
            {isApp() ? null:<MobileNav />}

            {/* <!-- 서브메뉴 --> */}
            <SubMenuNav page="/giftbox"/>
            </>
        );
    }

    return (
        <>
            
            {isApp() ? null:<Header title={"선물함"} />}
            {isApp() ? null:<MobileNav />}

            {/* <!-- 서브메뉴 --> */}
            <SubMenuNav page="/giftbox"/>
            {/* <!-- //서브메뉴 --> */}

            {/* <!-- container --> */}
            <div id="container">
                

                {/* <!-- 선물함배너 --> */}
                {!type ? 
                <section className={"section wrapper gift_banner " + style['gift_banner']}>
                    <div className={"area_box clearfix top_con"}>
                        <div className="title_con left">
                            <h4 className="title color-txt-normal title-04 text_overflow2">지금 바로<br />받을 수 있는 선물</h4>
                            <button 
                                type="button" 
                                className={"desc button color-btn-01 txt " + style['button']} 
                                onClick={handlerMoveDetailGiftReceive}
                                >
                                <span className="btn-04">받은 선물 보러가기</span>
                                <span className={"link_icon " + style['link_icon']}></span>
                            </button>
                        </div>
                        <div className={"cover_img right " + style['right']}>
                            <img src={img_gift} />
                        </div>
                        <div className={"num_gift color-btn-02 bg radius-full " + style['num_gift']}>
                            <p className="color-btn-02-txt title-book-02-m"><span className="title-book-04-b">{!totalGiftCnt ? 0 : totalGiftCnt > 999 ? "999+":totalGiftCnt} </span>개</p>
                        </div>
                    </div>
                </section>
                :null}
                {/* <!-- 섹션 --> */}
                {/* <!-- 버튼있는 섹션 --> */}
                <section className={"section template wrapper temp_list template_column list_btn " + style['temp_list'] + " " + style['template'] + " " + style['template_column']}>
                    <div className={"list " + style['list']} ref={contentRef}>
                        {/* <!-- 리스트 --> */}
                        {data && Array.isArray(data) && data?.length > 0 ? 
                        <ul>
                            {/* <!-- 받지않은 선물 리스트 상단 노출 --> */}
                            
                                {data.map((item, index)=>{

                                    return (
                                        <li key={index} className={"clearfix gift_list " + style['gift_list']}>
                                            <BookpalLink >
                                                <div className="cover_img radius-xs">
                                                    {item?.adult ? <p className="ic_adult badge_adult_middle"><img src="/images/ic_adult.svg" /></p> : null}
                                                    {/* <img src="../images/thumbnail.jpg" /> */}
                                                    {/* <BookpalLazyLoadImage 
                                                        placeholderSrc={ImgDefault("cover_logo")}
                                                        // effect="blur"
                                                        src={ImgCover(memberInfo?.view_type, item?.adult, ImgDefault("cover_lock"), item?.cover)}
                                                        // style={defaultImageStyle}
                                                        onError={(e)=>{
                                                            e.currentTarget.src = ImgDefault("cover_logo");
                                                        }}
                                                    /> */}
                                                    <BookpalLazyLoadImage 
                                                        adult={item?.adult}
                                                        adultswitch={memberInfo?.audit_switch}
                                                        defaulttype={item?.bid === 0 ? (item?.gift_info?.voucher_ct_type === "novel"?"gift_novel":"gift_toon") : "cover_logo"}
                                                        locktype={"cover_lock"}
                                                        src={item?.cover}
                                                        // style={defaultImageStyle}
                                                    />
                                                    <p className="ic_bottom badges_box">
                                                        {item?.badge_promotion !== "" ? <span className="ic_midnight radius-xs icon_badge"><img src={ImgBadge(item?.badge_promotion)} /></span> : null}
                                                        {/* {item?.badge_new_up !== "" ? <span className="ic_up radius-xs icon_badge"><img src={ImgBadge(item?.badge_new_up)} /></span> : null} */}
                                                        {item?.badge_discount > 0 ? <span className="ic_discount radius-xs discount_10 icon_badge"><img src={ImgBadge(item?.badge_discount)} /></span> : null }
                                                        
                                                    </p>
                                                </div>
                                                <div className={"info_txt spacing_08 " + style['info_txt']}>
                                                    {item?.bid !== 0 ?
                                                    <div className="info">
                                                        <span className="color-txt-05 body-01">{getCtTypeName(item?.ct_type)}</span>
                                                        <span className="color-txt-05 body-01">{item?.genre_kor}</span>
                                                        <span className="color-txt-05 body-01">{item?.is_finished ? "완결":item?.is_rest ? "휴재":"연재"}</span>
                                                    </div>
                                                    :null}
                                                    <div className="list_tit_con">
                                                        {item?.badge_new_up !== "" ? <span className="ic_up radius-xs icon_badge"><img src={ImgBadge(item?.badge_new_up)} /></span> : null}
                                                        {/* <span className="ic_up radius-xs icon_badge"><img src="../images/up_badge.svg" /></span> */}
                                                        <span className="title color-txt-normal title-book-03-m text_overflow">{item?.bid === 0 ? (item?.gift_info?.voucher_ct_type === "novel"?"웹소설":"웹툰")+" 전체" : item?.title}</span>
                                                    </div>
                                                    <div className="writer color-txt-03 body-02 text_overflow">{item?.wr_nick}</div>
                                                    <div className={"info date " + style['date']}>
                                                        <span className="color-txt-05 body-01">{item?.gift_info?.voucher_type === 2 ? "선물소장권" : "선물이용권"}</span>
                                                        <span className="color-btn-01 txt body-01">{item?.gift_info?.is_get ? "받기완료":item?.gift_info?.voucher_cnt+"장"}</span>
                                                    </div>
                                                </div>
                                                <div className={"button_box " + style['button_box']}>
                                                    {item?.gift_info?.is_get ?
                                                    <button 
                                                        type="button" 
                                                        className={"button btn_03 outlined label_on medium btn-02 radius-full round"}
                                                        data-voucher_type={item?.gift_info?.voucher_type}
                                                        data-voucher_ct_type={item?.gift_info?.voucher_ct_type}
                                                        data-bid={item?.bid}
                                                        onClick={(e)=>{
                                                            e.preventDefault();
                                                            
                                                            const voucher_type = e.currentTarget.getAttribute("data-voucher_type");
                                                            const voucher_ct_type = e.currentTarget.getAttribute("data-voucher_ct_type");
                                                            const bid = e.currentTarget.getAttribute("data-bid");
                                                            
                                                            if(bid === "0"){
                                                                if(voucher_ct_type === "novel"){
                                                                    if(isApp()){
                                                                        appSetMove("","/novel/ranking");
                                                                        return;
                                                                    }
                                                                    navigate("/novel/ranking");
                                                                } else {
                                                                    if(isApp()){
                                                                        appSetMove("","/toon/ranking");
                                                                        return;
                                                                    }
                                                                    navigate("/toon/ranking");
                                                                }
                                                                return;
                                                            }

                                                            if(isApp()){
                                                                appSetMove("","/content/"+voucher_ct_type+"/"+bid);
                                                                return;
                                                            }

                                                            navigate("/content/"+voucher_ct_type+"/"+bid);
                                                        }}
                                                        ><span 
                                                            className="btn-03 color-txt-04">작품보기</span></button>
                                                    :<button 
                                                        type="button" 
                                                        className={"gift_btn button btn_01 filled label_on medium radius-full " + style['gift_btn'] + " " + style['button']}
                                                        data-gift_box_id={item?.gift_info?.gift_box_id}
                                                        data-adult={item?.adult ? "true":"false"}
                                                        onClick={(e)=>{
                                                            const gift_box_id = e.currentTarget.getAttribute("data-gift_box_id");
                                                            const adult = e.currentTarget.getAttribute("data-adult");

                                                            selectGiftSubmit(gift_box_id, adult === "true");
                                                        }}
                                                        ><span 
                                                            className="white_always txt btn-03"
                                                            >선물받기</span></button>}
                                                </div>
                                            </BookpalLink>
                                        </li>
                                    )
                                })}
                            
                        </ul>
                        :<div className="no_data small color-txt-04 body-02-long">더 많은 작품을 준비 중입니다.</div>}
                        {/* <!-- 내역 없을때 -->
                        <!-- <div className="no_data small color-txt-04 body-02-long">더 많은 작품을 준비 중입니다.</div> --> */}
                    </div>
                </section>

            </div>
            {/* <Modal /> */}
            {/* <!-- container --> */}
            <ConfirmModal />
            <AlertModal />
            <Footer />
            <TopBtn />
        </>
    );
}


export default GiftBox;