import { useEffect, useRef, useState } from 'react';
import { ApiSearchWork, ApiSearchFavKeyword } from 'api/Search';
import { setKeywordData, getKeywordData, delKeywordData, initKeywordData } from 'utils/Storage';
import { SetState, GetState, useMemberInfo } from 'utils/State';

import Header from 'component/common/header/Header';
import MobileNav from 'component/common/nav/MobileNav';

import Footer from 'component/common/footer/Footer';
import TopBtn from 'component/common/button/top/TopBtn';

import SubNav from 'component/common/nav/SubNav';

// import './search.scss';
import style from '../search/Search.module.scss';

import SearchKeyword from 'component/common/section/SearchKeyword';

import WorkTmpl from 'component/common/template/work/WorkTmpl';
import NoneTmpl from 'component/common/template/none/NoneTmpl';
import NoneSearchKeyword from 'component/common/template/none/NoneSearchKeyword';
import PopularKeywordTmpl from 'component/common/template/search/PopularKeywordTmpl';

import SearchKeywordModal from '../search/component/SearchKeywordModal';
import { Link, useLocation, useParams } from 'react-router-dom';
import BookpalLink from 'component/common/custom/BookpalLink';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

function Keyword(){

    const contentRef = useRef(null);

    const query = useQuery();

    const params = useLocation().state;



    const setState = SetState();
    const getState = GetState();
    const memberInfo = useMemberInfo();

    const no_adult = query.get("no_adult");

    const [searchText, setSearchText] = useState(query.get("txt") ?? "");
    const [tab, setTab] = useState("all");
    const [arrTab, setArrTab] = useState(null);
    const [end, setEnd] = useState(false);
    const [keyword, setKeyword] = useState(params?.keyword ?? query.get("keyword") ?? null);


    // console.log(params);

    const [keywordList, setKeywordList] = useState([]);
    const [favKeyword, setFavKeyword] = useState([]);

    const saveMode = getState.useGetKeywordSaveMode() === false ? false : true;

    // const [keywordTab, setKeywordTab] = useState(null);

    const favKeywordSubmit = ApiSearchFavKeyword({
        onSuccess:(data)=>{
            if(data?.status === true){
                setFavKeyword(data?.result);
            }
        }
    })

    const [data, dataSubmit, initData, paging, setData, isLoading] = ApiSearchWork({
        bottomRef:contentRef,
        data:{
            title_writer: !!keyword ? null : searchText,
            category:arrTab,
            end:(end ? true : null),
            keyword:keyword,
            adult:(no_adult === "1" ? ['15']: null),
        },
        onError:()=>{
            dataSubmit();
        }
    });

    const saveModeToggle = ()=>{
        // console.log(saveMode)
        setState.setKeywordSaveMode(!saveMode);
    }

    const getKeywordSubmit = ()=>{
        setKeywordList(getKeywordData());
    }

    const searchTextSubmit = (searchText)=>{
        if(searchText?.trim() !== ""){
            setTab("all");
            setArrTab(null);
            setEnd(false);
            setKeyword(null);
            initData();
            dataSubmit();
            if(saveMode){
                setKeywordData(searchText?.trim());
            }
            getKeywordSubmit();
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === 'NumpadEnter') {
            searchTextSubmit(searchText);
            e.target.blur();
        } else  {
            
        }
    };

    const initKeyword = () => {
        if(keyword !== null){
            setSearchText("");
            setTab("all");
            setArrTab(null);
            setEnd(false);
            setKeyword(null);
            initData();
        }
    }

    

    const [showSearchKeywordModal, setShowSearchKeywordModal] = useState(false);

    const handlerToggleKeywordModal = () =>{
        setShowSearchKeywordModal(!showSearchKeywordModal);
    }

    

    useEffect(()=>{
        // console.log("end, arrtab")
        // console.log(searchText, keyword);
        // if(data && Array.isArray(data) && data?.length === 0){
        //     return;
        // }
        // console.log("searchText",searchText);
        // console.log("keyword",keyword);
        if(searchText !== "" || keyword !== null){
            // console.log("init");
            initData();
            dataSubmit();
        }
    },[end,arrTab])

    useEffect(()=>{
        if(keyword !== null){
            setTab("all");
            setArrTab(null);
            setEnd(false);
            initData();
            dataSubmit();
        }
    },[keyword])
    
    useEffect(()=>{
        // console.log(tab);
        if(tab !== null){
            switch(tab){
                default:
                case "all":
                    setArrTab(null);
                    break;
                case "novel":
                    setArrTab(["novel"]);
                    break;
                case "toon":
                    setArrTab(["toon","comic"]);
                    break;
                case "epub":
                    setArrTab(["epub"]);
                    break;
            }
            // setEnd(false);
        }
    },[tab]);

    useEffect(()=>{
        getKeywordSubmit();
        favKeywordSubmit();
    },[])

// console.log(data, isLoading);
    return (
        <>
            <Header useMobile={false} />
            {/* <MobileNav /> */}
            {/* <!-- 서브메뉴 --> */}
            <SubNav 
                name={"#"+keyword}
                usePc={true} 
                // useHome={true} 
                useMobile={true} 
                useBtnPrev={true}
                // useBtnHome={true}
                setTop={'0px'} 
                setPcTop={'64px'}  
                />

            {/* <!-- //서브메뉴 --> */}
            <div id="container" className={"sub_wrap wrap " + style['container']} >


                {/* <!-- 검색 시 나오는 화면 --> */}
                {data !== null || isLoading === true ?
                    <section className="section template wrapper temp_list temp_list_type template_column" ref={contentRef}>


                        <div className="tit_con clearfix">
                            <div className="left">
                                <label className="medium toggle_btn">
                                    <input role="switch" type="checkbox" checked={end} onChange={(e)=>{setEnd(e.target.checked);}}/>
                                    <span className="color-txt-05 btn-03">완결</span>
                                </label>
                            </div>
                            <div className="right">
                                <span className="color-btn-03-txt btn-03">검색결과 ({(paging?.total_cnt >= 10000 ? "10,000+" : paging?.total_cnt ?? 0).toLocaleString()})</span>
                            </div>
                        </div>
                        {(data && Array.isArray(data) && data?.length === 0) ? <p className="no_data large color-txt-04 body-02-long">검색결과가 없습니다.</p> :null }
                        
                        {isLoading || (data && Array.isArray(data) && data?.length > 0) ?
                        <>
                            <div className="list" >
                                <div className="list_1">
                                    <ul>
                                        {/* <!-- 리스트 --> */}
                                        {data && Array.isArray(data) ? 
                                            data.map((item,index)=>{
                                                return (
                                                    <li key={index} className="clearfix">
                                                        <WorkTmpl
                                                            memberInfo={memberInfo}
                                                            data={item}
                                                            />
                                                    </li>
                                                )
                                            })
                                        :null}
                                    </ul>
                                </div>
                            </div>
                            <div style={{height:"100px"}}></div>
                        </>
                        :null}
                    </section>
                :null}
            </div>
            {/* <!-- container --> */}

            <SearchKeywordModal 
                isShow={showSearchKeywordModal} 
                selectKeyword={keyword}
                onClose={()=>{setShowSearchKeywordModal(false);}} 
                action={(keyword)=>{
                    // console.log(keyword)
                    setSearchText("#"+keyword);
                    setKeyword(keyword);
                }}
                />
            {/* <Footer /> */}
            <TopBtn />
        </>
    )
}

export default Keyword;