import { useEffect, useState, useRef } from 'react';
import { ApiCommentCommentList, ApiCommentCommentLike, ApiCommentCommentDelete } from 'api/Comment';
import { SetState, useMemberInfo } from 'utils/State';
import { ApiCommonWriterReject } from 'api/Common';
import { runPopupDeclare } from 'utils/Common';

import style from '../style/Contents.module.scss';
import NewlineToBr from 'component/common/content/NewlineToBr';
import { useLocation, useNavigate } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import useConfirmCustomModal from 'component/common/modal/useConfirmCustomModal';
import useAlertModal from 'component/common/modal/useAlertModal';


function Comment({ info }){

    const navigate = useBookpalNavigate();
    const setState = SetState();

    const memberInfo = useMemberInfo();
    const location = useLocation();

    const _info = info || false;

    const [commentId, setCommentId] = useState(null);
    const [likeCommentId, setLikeCommentId] = useState(null);
    const [filter, setFilter] = useState("like");
    // const [data, setData] = useState(null);

    const [rejectReplyId, setRejectReplyId] = useState(null);

    const [ConfirmModal, confirm] = useConfirmCustomModal();
    const [ConfirmModal2, confirm2] = useConfirmCustomModal();
    const [AlertModal, alert] = useAlertModal();

    const contentRef = useRef(null);

    const [data, dataSubmit, initData, paging, setData] = ApiCommentCommentList({
        data:{
            filter:filter,
            bid:_info?.bid,
        },
        bottomRef:contentRef,
    });



    const handlerRejectOrDeclare = (chapter_id, comment_id, reject, nickname)=>{

        if(!memberInfo?.is_login){
            alert("알림","신고/차단을 위해서는 로그인이 필요합니다.",()=>{
                navigate("/login",{state:{return_path:location.pathname}});
            });
            return;
        }

        confirm({
            title:"신고/차단하기",
            successBtnTitle:"신고하기",
            successBtnTitle2:reject ? "차단 해제하기":"차단하기",
            cancleBtnTitle:"취소",
            successAction:()=>{
                // console.log(comment);
                runPopupDeclare({
                    type:5,
                    chapter_id:chapter_id,
                    reply_id:comment_id,
                    // comment_reply_id:reply_id,
                });
            },

            successAction2:()=>{
                setRejectReplyId(comment_id);
                // rejectSubmit({
                //     reject_id:comment_id,
                //     reject_type:"comment",
                // });
                confirm2({
                    title:"차단하기",
                    content:"["+nickname+"]님 댓글/답글을 "+(reject ? "차단 해제":"차단")+"하시겠습니까?",
                    successBtnTitle:reject ? "차단 해제하기":"차단하기",
                    cancleBtnTitle:"취소",
                    successAction:()=>{
                        rejectSubmit({
                            reject_id:comment_id,
                            reject_type:"comment",
                        });
                    },
                })
            },
        });
    }

    const rejectSubmit = ApiCommonWriterReject({
        onSuccess:(_data)=>{
            if(_data?.status === true){
                const reject = _data?.result?.reject;

                const tmp = [];
                data.map((item,index)=>{
                    if(item?.comment_id == rejectReplyId){
                        item.reject = reject;
                    }
                    tmp.push(item);
                })
                // rejectReplyId
                setData(tmp);

                if(reject){
                    setState.setToast({
                        title:"차단하였습니다.",
                    });
                } else {
                    setState.setToast({
                        title:"차단 해제하였습니다.",
                    });
                }
            }
        },
        onError:(e)=>{

        },
    });


    const commentLikeSubmit = ApiCommentCommentLike({
        onSuccess:(_data)=>{
            if(_data?.status === true){
                const tmp = [];

                data.map((item)=>{
                    if(item?.comment_id === parseInt(likeCommentId,10)){
                        
                        item.likes = _data?.result?.likes;
                        item.like_status = _data?.result?.like_status;
                    }
                    tmp.push(item);
                })

                setData(tmp);
                setLikeCommentId(null);
            }
        },
        onError:(e)=>{

        },
    });

    const handlerCommentLike = (comment_id)=>{
        if(!memberInfo?.is_login){
            alert("알림","로그인이 필요합니다.",()=>{
                navigate("/login",{state:{return_path:location.pathname}});
            });
            return;
        }
        commentLikeSubmit({
            comment_id:comment_id,
        });
    }

    const deleteSubmit = ApiCommentCommentDelete({
        onSuccess:(_data)=>{
            console.log(_data);
            if(_data?.status === true){
                // handlerShowToast({
                //     title:"삭제되었습니다."
                // });

                setState.setToast({
                    title:"삭제되었습니다."
                });

                const n_data = [];
                data.map((item)=>{
                    if(item?.comment_id == commentId){
                        n_data.push({
                            ...item,
                            delete:true,
                        });
                    } else {
                        n_data.push(item);
                    }
                });
                setData(n_data);
            }
        },
        onError:(e)=>{
            console.log(e);
        }
    });

    const delComment = (ccommendId)=>{
        confirm({
            title:"삭제 안내",
            content:"삭제한 글은 복구할 수 없습니다.",
            successBtnTitle:"삭제하기",
            successAction:()=>{
                deleteSubmit({
                    comment_id:ccommendId
                })
            }
        })
    }

    const showModalWithSelectCommendId = (e)=>{
        const commendId = e.currentTarget.getAttribute('data-commend-id');
        console.log((commendId))
        // alert(commendId)
        setCommentId(commendId);
        // showModal();

        delComment(commendId);
    }

    // const [Modal, showModal] = useConfirmModal({
    //     title:"삭제 안내",
    //     content:"삭제한 글은 복구할 수 없습니다.",
    //     btnTitle:"삭제하기",
    //     action:()=>{
    //         deleteSubmit({
    //             comment_id:commentId
    //         })
    //     }
    // });

    
    useEffect(()=>{
        dataSubmit();
    },[])

    useEffect(()=>{
        if(likeCommentId){
            handlerCommentLike(likeCommentId);
        }
    },[likeCommentId]);

    useEffect(()=>{
        initData();
        dataSubmit();
    },[filter]);


    // console.log(data);
    if(data === null){
        return <>
            <div style={{height:"300px"}}></div>
        </>
    }

    return (
        <>
            {data && Array.isArray(data) && data?.length > 0 ?
            <div className={"tit_con clearfix " + style['tit_con']}>
                <div className={"left " + style['left']}>
                    <span className="btn-03 color-btn-03-txt">댓글 {paging?.total_cnt}</span>
                </div>
                <div className={"right button_dot " + style['right'] + " " + style['button_dot']}>
                    {/* <!-- 클릭시 button class에 click 추가--> */}
                    <button 
                        type="button" 
                        className={"button btn-03 " + style['button'] + " " + (filter === "like" ? style['click'] : "")}
                        onClick={()=>{
                            setFilter("like");
                        }}
                        ><span className="color-btn-03-txt">좋아요순</span></button>
                    <button 
                        type="button" 
                        className={"button btn-03 " + style['button'] + " " + (filter === "new" ? style['click'] : "")}
                        onClick={()=>{
                            setFilter("new");
                        }}
                        ><span className="color-btn-03-txt">최신순</span></button>
                </div>
            </div>
            :null}
            <div className={"temp_list template_column " + style['temp_list']}>
                {data && Array.isArray(data) && data?.length === 0 ?
                    <p className="no_data sm color-txt-04 body-02-long">아직 등록된 댓글이 없습니다.<br />작품 감상 후 첫 댓글을 작성해 보세요.</p>
                :null}
                {data && Array.isArray(data) && data?.length > 0 ?
                    <ul className={"list comment_list " + style['list'] + " " + style['comment_list']} ref={contentRef}>
                        {data.map((item, index)=>{

                            if(item?.delete === true){
                                return (
                                    <li key={index}>
                                        <p className="delete_list color-txt-muted body-03-long">작성자가 글을 삭제하였습니다.</p>
                                    </li>
                                )
                            }

                            return (
                                <li key={index}>
                                    <div className={"profile_box left " + style['profile_box'] + " " + style['left']}>
                                        <span className={"profile radius-full " + style['profile']}>
                                            <img src={item?.profile} />
                                        </span>
                                    </div>
                                    <div className={"right comment_box " + style['right'] + " " + style['comment_box']}>
                                        <div className={"top_con " + style['top_con']}>
                                            <div className={"badge_list " + style['badge_list']}>
                                                {item?.badge?.my === true ? <p className={"badge my_badge color-btn-01 border border-soild border-line radius-full " + style['badge'] + " " + style['my_badge']}>
                                                    <span className={"color-btn-01 txt " + style['txt']}>MY</span>
                                                </p> : null}
                                                {item?.badge?.best === true ? <p className={"badge best_badge color-btn-01 bg border border-soild border-line radius-full " + style['badge'] + " " + style['best_badge']}>
                                                    <span className={"white-always txt " + style['txt']}>Best</span>
                                                </p> : null}
                                                {item?.badge?.writer_choice === true ? <p className={"badge love_badge color-state-point-01 bg border border-soild border-line radius-full " + style['badge'] + " " + style['love_badge']}>
                                                    <span className={"white-always txt " + style['txt']}>작가감동♥︎</span>
                                                </p> : null}
                                            </div>
                                            <div className={"name text_overflow title-01-b-long color-txt-normal " + style['name'] + " " + style['text_overflow']}>{item?.nickname}</div>
                                            <div className={"date title-sub-02-m color-txt-muted " + style['date']}>{item?.display_time}</div>
                                        </div>
                                        <div className={"desc color-txt-02 body-03-long " + style['desc']}>
                                            <NewlineToBr text={item?.reject ? "차단한 사용자 입니다.":(item?.content ?? "")} />
                                        </div>
                                        <div className={"info body-01-long color-txt-05 " + style['info']}>{item?.book_title} - {item?.chapter_no}화</div>
                                        <div className={"btns bottom_con " + style['btns'] + " " + style['bottom_con']}>
                                            <div className={"left " + style['left']}>
                                                {/* <!-- 좋아요버튼 클릭시 className="click" 추가--> */}
                                                <button 
                                                    type="button" 
                                                    className={"button btn_03 medium outlined label_on round radius-full " + style['likeBtn'] + " " + (item?.like_status === true?style["click"]:"")} 
                                                    data-my={item?.badge?.my ? "true" : "false"}
                                                    data-comment_id={item?.comment_id}
                                                    onClick={(e)=>{
                                                        const my = e.currentTarget.getAttribute("data-my");
                                                        const comment_id = e.currentTarget.getAttribute("data-comment_id");

                                                        if(my === "true"){
                                                            setState.setToast({
                                                                title:"자신의 글에는 좋아요를 누를 수 없습니다."
                                                            });
                                                        } else {
                                                            setLikeCommentId(comment_id)
                                                        }
                                                    }}
                                                    >
                                                    <span className="btn-03 color-txt-04 text">{item?.likes}</span>
                                                </button>
                                                {/* <!-- 답글 버튼 --> */}
                                                <button type="button" 
                                                        className={
                                                            item?.reply_status === true?
                                                            "button btn_01 medium outlined label_on round radius-full after " + style['button'] + " " + style['replyBtn'] + " " + style['after']
                                                            :"button btn_03 medium outlined label_on round radius-full after " + style['button'] + " " + style['replyBtn']} 
                                                        data-comment_id={item?.comment_id}
                                                        onClick={(e)=>{
                                                            const comment_id = e.currentTarget.getAttribute("data-comment_id");
                                                            navigate("/content_reply/" + comment_id);
                                                        }}
                                                    >
                                                    <span className={"btn-03 text " + style['text']}>{item?.replies === 0 ? "답글달기" : item?.replies}</span>
                                                </button>
                                            </div>
                                            <div className={"right " + style['right']}>
                                                {item?.delete_flag === true ?
                                                    <button type="button" id="deleteBtn" data-commend-id={item?.comment_id} onClick={showModalWithSelectCommendId} >
                                                        <span className="body-01-long color-txt-05">삭제</span>
                                                    </button>
                                                : 
                                                    <>
                                                        {/* <!-- 신고/차단버튼 --> */}
                                                        <button 
                                                            type="button" 
                                                            id="reportBtn"
                                                            data-chapter_id={item?.chapter_id}
                                                            data-comment_id={item?.comment_id}
                                                            data-reject={item?.reject ? "true" : "false"}
                                                            data-nickname={item?.nickname}
                                                            onClick={(e)=>{
                                                                const chapter_id = e.currentTarget.getAttribute("data-chapter_id");
                                                                const comment_id = e.currentTarget.getAttribute("data-comment_id");
                                                                const reject = e.currentTarget.getAttribute("data-reject");
                                                                const nickname = e.currentTarget.getAttribute("data-nickname");
                                                                handlerRejectOrDeclare(chapter_id, comment_id, reject === "true", nickname);
                                                            }}
                                                            ></button>
                                                    </>
                                                }
                                                {/* <!-- 신고/차단버튼 --> */}
                                                {/* <button 
                                                    type="button"
                                                    className={style['reportBtn']}
                                                    ></button> */}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            )
                        })}
                        
                        {/* <!-- 삭제한 댓글이 있을때 --> */}
                        {/* <li className="delete_list">
                            <p className="color-txt-muted body-03-long">작성자가 글을 삭제하였습니다. </p>
                        </li> */}
                    </ul>
                :null}
                {/* <!-- 댓글이 없을때 -->
                <!-- <p className="no_data sm color-txt-04 body-02-long">아직 등록된 댓글이 없습니다.<br />작품 감상 후 첫 댓글을 작성해 보세요.</p> --> */}
            </div>
            <ConfirmModal />
            <ConfirmModal2 />
            <AlertModal />
        </>
    )
}



export default Comment;