import { ApiPromotionCollectbook } from 'api/Promotion';


import Header from 'component/common/header/Header';
import MobileNav from 'component/common/nav/MobileNav';

import Footer from 'component/common/footer/Footer';
import TopBtn from 'component/common/button/top/TopBtn';

// import GenreHomeHalfBanner from 'component/common/banner/GenreHomeHalfBanner';

import useSelectModal from 'component/common/modal/useSelectModal';
import BigBanner from 'component/common/banner/BigBanner';
import SubNav from 'component/common/nav/SubNav';


import style from './style/Promotion.module.scss';


import img_collectbook from 'assets/images/promotion/collectbook.png';
import img_icon_collectbook_tag_01 from 'assets/images/promotion/icon_collectbook_tag_01.svg';
import img_icon_collectbook_tag_02 from 'assets/images/promotion/icon_collectbook_tag_02.svg';
import img_icon_collectbook_tag_03 from 'assets/images/promotion/icon_collectbook_tag_03.svg';
import img_icon_collectbook_tag_04 from 'assets/images/promotion/icon_collectbook_tag_04.svg';
import img_icon_collectbook_tag_05 from 'assets/images/promotion/icon_collectbook_tag_05.svg';

import img_default from 'assets/images/promotion/default.svg';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function CollectBook(){

    const navigate = useBookpalNavigate();
    const query = useQuery();

    const dateYm = query.get("date_ym");

    const modalData = {
        all:"전체",
        select1:"select option 01",
        select2:"select option 02",
    }
    const [Modal, modalShow, selectKey] = useSelectModal(modalData, "all");
    const [data, setData] = useState(null);

    const [useData, setUseData] = useState(null);

    const collectSubmit = ApiPromotionCollectbook({
        onSuccess:(data)=>{
            if(data?.status === true){
                setData(data?.result)
            }
        },
        onError:(e)=>{

        },
    });

    const getDay = (dateString)=>{
        const date = new Date(dateString);
        return date.getDate();
    }

    const getWeekTxt = (dateString)=>{
        // 요일 텍스트 배열
        const weekDays = ['일', '월', '화', '수', '목', '금', '토'];
        // Date 객체를 생성하여 요일 부분을 반환
        const date = new Date(dateString);
        const dayIndex = date.getDay();
        return weekDays[dayIndex];
    }

    useEffect(()=>{
        collectSubmit({
            date_ym:dateYm ?? null,
        });
    },[]);

    useEffect(()=>{
        if(data){
            const tmp = {};
            data?.data.map((item)=>{
                if(!tmp[item?.oepn_date]){
                    tmp[item?.oepn_date] = [];
                }
                tmp[item?.oepn_date].push(item);
            })

            setUseData(tmp);
            // console.log(tmp);
        }
    },[data]);

    if(useData === null){
        return null;
    }


    return (
        <>
            <Header useMobile={false} />
            {/* <MobileNav /> */}
            {/* <!-- 서브메뉴 --> */}
            <SubNav 
                name="이달의 콜렉트북" 
                useHome={true} 
                usePc={true}
                useMobile={true}
                useBtnPrev={true}
                useBtnHome={true}
                setPcTop={"64px"} 
                setTop={"0px"}
                />
            {/* <!-- //서브메뉴 --> */}

            {/* <!-- container --> */}
            <div id="container" className={style['container']} style={{paddingBottom:"0px"}}>
                
                {/* <!-- 섹션 --> */}
                <section className={"section wrapper event_temp " + style['event_temp']} style={{backgroundColor: data?.bg_color}}>
                    {/* <p className="images"><img src={img_collectbook} /></p> */}
                    <p className="images"><img src={data?.header} /></p>

                    

                    <div className={"calendar " + style['calendar']}>
                        <div className={"calendarContent " + style['calendarContent']}>
                            <ul className={"table_box " + style['table_box']}>
                                {Object.entries(useData).map(([key,items]) =>{

                                    return (
                                        <li className="clearfix">
                                            <div className={"date_box left " + style['date_box'] + " " + style['left']}>
                                                <p className={"month display-03 black-real-always txt " + style['txt']}>{getDay(key)}</p>
                                                <p className={"week body-03 " + style['week']}>{getWeekTxt(key)}</p>
                                            </div>
                                            {items.map((item, index)=>{

                                                return (
                                                    <div className={"text_box right clearfix " + style['text_box']}>
                                                        <div className={"info_box left " + style['info_box'] + " " + style['left']}>
                                                            <p className={"tag " + style['tag']}>
                                                                {item?.genre_code === "romance"?
                                                                    <img src={img_icon_collectbook_tag_01} />
                                                                :null}
                                                                
                                                                {item?.genre_code === "romancefantasy"?
                                                                    <img src={img_icon_collectbook_tag_02} />
                                                                :null}

                                                                {item?.genre_code === "bl"?
                                                                    <img src={img_icon_collectbook_tag_03} />
                                                                :null}

                                                                {item?.genre_code === "fantasy"?
                                                                    <img src={img_icon_collectbook_tag_04} />
                                                                :null}

                                                                {item?.bookpal_only === true?
                                                                    <img src={img_icon_collectbook_tag_05} />
                                                                :null}
                                                            </p>
                                                            <p className={"title title-book-04-m text_overflow"}>{item?.title}</p>
                                                            <p className="writer body-03 text_overflow">{item?.writer_name}</p>
                                                        </div>
                                                        <div className={"icon_box right " + style['icon_box'] + " " + style['right']}>
                                                            <p className="icon">
                                                                <img src={item?.promotion1} style={{maxWidth:"37px"}} />
                                                            </p>
                                                            <p className={"button " + style['button']}>
                                                                {/* <!-- 작품보기 --> */}
                                                                {item?.is_open === true ?
                                                                <button 
                                                                    type="button" 
                                                                    className={"btn-03 white-always txt default radius-full " + style['txt'] + " " + style['default']}
                                                                    data-ct_type={item?.ct_type}
                                                                    data-bid={item?.bid}
                                                                    onClick={(e)=>{
                                                                        const ct_type = e.currentTarget.getAttribute("data-ct_type");
                                                                        const bid = e.currentTarget.getAttribute("data-bid");
                                                                        navigate("/content/"+ct_type+"/"+bid);
                                                                    }}
                                                                    >작품보기</button>
                                                                :null}
                                                                {/* <!-- 출간예정 --> */}
                                                                {item?.is_open === false ?
                                                                <button 
                                                                    type="button" 
                                                                    className={"btn-03 disabled radius-full " + style['disabled']}
                                                                    style={{cursor:"pointer"}}
                                                                    >출간예정</button>
                                                                :null}
                                                            </p>
                                                        </div>
                                                    </div>   
                                                )
                                            })}
                                            
                                        </li>
                                    )
                                })}

                            </ul>
                        </div>
                        <p className={"desc body-03-long " + style['desc']}>※ 런칭 일정은 내부 운영에 따라 변동될 수 있습니다.</p>
                    </div>
                </section>
            </div>
            <Modal />
            {/* <!-- container --> */}
            <Footer />
            <TopBtn />
        </>
    );
}


export default CollectBook;