import React, { createContext, useContext, useState, useEffect } from "react";
import { getAppsDataWithUserAgent } from 'utils/Common';
import * as datas from 'constant/DataType';
import { getStorageData } from "utils/Storage";

const CommonContext = createContext({
    darkTheme: true,
    toggleTheme: () => {},
});

export const CommonProvider = ({ children }) => {
    const bookpalUserAgentData = getAppsDataWithUserAgent();

    

    const [darkTheme, setDarkTheme] = useState(false);
    const toggleThemeHandler = () => {
        // setDarkTheme((prevState) => !prevState);
        setThemeMode();
    };

    const setThemeMode = () => {
        const systemMode = getStorageData(datas.SYSTEM_MODE);

        if(bookpalUserAgentData?.dark_mode === "dark" || bookpalUserAgentData?.dark_mode === "light" ){
            if(darkTheme === false && bookpalUserAgentData?.dark_mode === "dark"){
                //다크모드 사용시 원복
                // setDarkTheme(true);
                setDarkTheme(false);
            } else {
                setDarkTheme(false);
            }
        } else {
            switch(systemMode){
                case "light":
                    if(darkTheme === true) {
                        setDarkTheme(false);
                    }
                    break;
                case "dark":
                    if(darkTheme === false) {
                        setDarkTheme(false);
                        //다크모드 사용시 원복
                        // setDarkTheme(true);
                    }
                    break;
                default:
                case "system":
                    if(window?.matchMedia('(prefers-color-scheme: dark)')?.matches){
                        if(darkTheme === false) {
                            //다크모드 사용시 원복
                            // setDarkTheme(true);
                            setDarkTheme(false);
                        }
                    } else {
                        if(darkTheme === true) {
                            setDarkTheme(false);
                        }
                    }
                    break;
            }
        }
    }

    useEffect(()=>{ 
        setThemeMode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    
    return (
        <CommonContext.Provider
            value={{
              darkTheme: darkTheme,
              toggleTheme: toggleThemeHandler,
              bookpalAgentData:bookpalUserAgentData,
            }}
        >
            {children}
        </CommonContext.Provider>
    );
};

export const useCommonValues = () => {
    return useContext(CommonContext);
};
