import { useState, useEffect } from 'react';
import { Button, Modal, ModalDialog } from 'react-bootstrap';
import { ApiCommonKeywordList } from 'api/Common';

import style from './SearchKeywordModal.module.scss';
import { Link } from 'react-router-dom';
import BookpalLink from 'component/common/custom/BookpalLink';

function SearchKeywordModal(props){

    const isShow = props.isShow || false;
    const onClose = props.onClose || (()=>{});
    const selectKeyword = props.selectKeyword || null;
    const action = props.action || (()=>{});


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [data, setData] = useState(null);
    const [tab, setTab] = useState("romance");
    const [keyword, setKeyword] = useState(selectKeyword);

    const keywordListSubmit = ApiCommonKeywordList({
        onSuccess:(data)=>{
            if(data?.status === true){
                setData(data?.result);
            }
        },
        onError:(e)=>{
            
        }
    });

    const selectAction = (e)=>{
        const keyword = e.currentTarget.getAttribute('data-keyword');
        action(keyword);
        setKeyword(keyword);
        onClose();
    }

    // setShow(isShow);

    useEffect(()=>{
        keywordListSubmit({
            tab:tab,
        });
    },[]);

    useEffect(()=>{
        keywordListSubmit({
            tab:tab,
        });
    },[tab]);




    return (
        <div id="Modal" className={isShow?"modal black_opacity-60 show":"modal black_opacity-60"} > 
            <div className="modal-dialog modal-dialog-centered large"> 
                <div className="modal-content radius-xxl color-bg-modal"> 
                    <div className="modal-header">
                        <div className="title_menu">
                            <div className="title title-03 color-txt-normal text_overflow">#키워드 검색</div>
                            <button type="button" className="close_btn" onClick={onClose} data-bs-dismiss="modal"></button>
                        </div>
                    </div> 
                    <div className={"modal-body " + style['modal-body']}> 
                        <ul className="tab_list menu_tab large">
                            <li className="color-txt-muted btn-04">
                                {/* <!-- 클릭될때 active 추가--> */}
                                <BookpalLink onClick={()=>{setTab("romance")}} className={"border border-soild border-bottom-line "+(tab==="romance"?"active":"")}>로맨스</BookpalLink>
                            </li>
                            <li className="color-txt-muted btn-04">
                                <BookpalLink onClick={()=>setTab("romancefantasy")} className={"border border-soild border-bottom-line "+(tab==="romancefantasy"?"active":"")}>로판</BookpalLink>
                            </li>
                            <li className="color-txt-muted btn-04">
                                <BookpalLink onClick={()=>setTab("bl")} className={"border border-soild border-bottom-line "+(tab==="bl"?"active":"")}>BL</BookpalLink>
                            </li>
                            <li className="color-txt-muted btn-04">
                                <BookpalLink onClick={()=>setTab("fantasy")} className={"border border-soild border-bottom-line "+(tab==="fantasy"?"active":"")}>판타지</BookpalLink>
                            </li>
                        </ul>
                        <div className={"list " + style['list']}>
                            <ul className="scroll_box">
                                {data && Array.isArray(data) ? 
                                    data?.map((item, index)=>{
                                        const keywords = item?.keywords?.map((v, k)=>{
                                            return <button 
                                                        key={"keywords_"+index+"_"+k} 
                                                        type="button" 
                                                        data-keyword={v?.name}
                                                        className={"btn_05 medium button label_on radius-full color-txt-02 btn-04 " + (selectKeyword == v?.name ? "active":"")}
                                                        onClick={selectAction}
                                                        >{v?.name}</button>;
                                        })
                                        return (
                                            <li key={"keywords_" + index}>
                                                <div className={"title title-02-b color-txt-normal " + style['title']}>{item?.group_name}</div>
                                                <div className={"keyword_word " + style['keyword_word']}>
                                                    {/* <!-- 클릭시 active 추가 --> */}
                                                    {keywords}
                                                </div>
                                            </li>
                                        )
                                    })
                                :null}
                            </ul>
                        </div>
                    </div> 
                </div> 
            </div> 
        </div> 
        // <div id="Modal" className="modal black_opacity-60" tabindex="-1" style={{"display":(isShow ? "block":"none")}}> 
        //     <div className="modal-dialog modal-dialog-centered large"> 
        //         <div className="modal-content radius-xxl color-bg-modal"> 
        //             <div className="modal-header">
        //                 <div className="title_menu">
        //                     <div className="title title-03 color-txt-normal text_overflow">#키워드 검색</div>
        //                     <button type="button" className="close_btn" data-bs-dismiss="modal" onClick={closeHandler}></button>
        //                 </div>
        //             </div> 
        //             <div className="modal-body"> 
                        
        //             </div> 
        //         </div> 
        //     </div> 
        // </div> 
    );
}


export default SearchKeywordModal;