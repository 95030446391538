import NewlineToBr from 'component/common/content/NewlineToBr';
import style from '../style/Contents.module.scss';
import { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { decryptOld } from 'utils/Crypt';
import { SetState, GetState } from 'utils/State';
import { setViewerData, getViewerData } from 'utils/Storage';

import styleVer from './ViewerHorizontal.module.scss';
import ViewerContents from './ViewerContents';

function ViewerHorizontalReverse({data, type, bid, cid, page, visible, setPage, setTotalPage, setVisible, alert, buyConfirm}){

    const [pages, setPages] = useState([]);
    // const [currPages, setCurrPages] = useState([]);

    const containerRef = useRef(null);
    const swiperRef = useRef(null);
    
    const setState = SetState();
    const getState = GetState();

    const [fadeState, setFadeState] = useState(false);
    const [init, setInit] = useState(false);
    

    const viewerFont = getState.useGetViewerInfoFont();
    
    // console.log("viewerFont",viewerFont)
    const viewerFontSize = getState.useGetViewerInfoFontSizePx();
    const viewerLineHeight = getState.useGetViewerInfoLineHeightPer();
    const viewerSideMargin = getState.useGetViewerInfoSideMarginPx();
    const viewerStyle = getState.useGetViewerInfoStyle();
    const viewerTheme = getState.useGetViewerInfoTheme();
    const viewerImageNone = getState.useGetViewerInfoImageNone();
    const viewerRun = getState.useGetViewerInfoRun();

    const padding = 0;


    const [realViewerFont, setRealViewerFont] = useState(viewerFont);
    const [realViewerFontSize, setRealViewerFontSize] = useState(viewerFontSize);
    const [realViewerLineHeight, setRealViewerLineHeight] = useState(viewerLineHeight);
    const [realViewerSideMargin, setRealViewerSideMargin] = useState(viewerSideMargin);

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    const isDeviceMobile = /android/i.test(userAgent) || (/iPad|iPhone|iPod/.test(userAgent)) || false;
    
    const getThemeColor = (theme)=>{
        switch(theme){
            default:
            case "white": return "viewer-black-01";
            case "gray": return "viewer-black-01";
            // case "black": return "viewer-gray";
            case "black": return "viewer-white";
            case "yellow": return "viewer-black-01";
            case "green": return "viewer-gray";
        }
    }

    const getThemeBodyColor = (theme)=>{
        switch(theme){
            default:
            case "white": return "#f9f9f9";
            case "gray": return "#d4d4d4";
            case "black": return "#000";
            case "yellow": return "#f3eedf";
            case "green": return "#2f4740";
        }
    }

    const handleSlideChange = () => {
        const swiper = swiperRef.current?.swiper;
        if (swiper) {
            // if(init){
                
                
                // setTimeout(()=>{
                //     setPage(swiper.activeIndex);
                // },1000);
                // console.log("setPage",12221,swiper.activeIndex )
                // console.log(swiper);
                setPage(swiper.activeIndex);
                if(!swiper?.destroyed){
                    setViewerData(type, "horizontal", data?.extra?.bid, cid, (swiper.activeIndex === swiper.slides.length - 1)?swiper.activeIndex -1:swiper.activeIndex);
                }
                // if(window.timeout){
                //     clearTimeout(window.timeout);
                //     window.timeout = null;
                // }
                
                // window.timeout = setTimeout(()=>{
                //     console.log("setPage",11,swiper.activeIndex)
                    
                //     if(!swiper?.destroyed){
                //         setViewerData(type, "horizontal", data?.extra?.bid, cid, (swiper.activeIndex === swiper.slides.length - 1)?swiper.activeIndex -1:swiper.activeIndex);
                //     }
                // },300);
            // }

            // if (swiper.activeIndex === swiper.slides.length - 1) {
            //     // console.log("swiper.allowSlideNext = false");
            //     swiper.allowSlideNext = false;
            //     swiper.allowSlidePrev = false;
            // } else {
            //     // swiper.allowSlideNext = true;
            //     // swiper.allowSlidePrev = true;
            // }
        }
    };

    const refreshAfter = ()=>{
        setFadeState(false);
        // setTimeout(()=>{
            
        // },30)
    }

    const refresh = ()=>{
        setTimeout(()=>{
            const container = containerRef.current;
            container.style.fontSize = realViewerFontSize;
            container.style.height = '99%';
            setTimeout(()=>{
                container.style.height = '100%';
            },50)
        },10)
    }

    useEffect(()=>{
        refreshAfter();
        setTimeout(()=>{
            setRealViewerFont(viewerFont);
        },50)
    },[viewerFont])

    useEffect(()=>{
        refreshAfter();
        setTimeout(()=>{
            setRealViewerFontSize(viewerFontSize);
        },50)
    },[viewerFontSize])

    useEffect(()=>{
        refreshAfter();
        setTimeout(()=>{
            setRealViewerLineHeight(viewerLineHeight);
        },50)
    },[viewerLineHeight])

    useEffect(()=>{
        refreshAfter();
        setTimeout(()=>{
            setRealViewerSideMargin(viewerSideMargin);
        },50)
    },[viewerSideMargin])

    useEffect(()=>{
        // console.log("refresh");
        refresh();
    },[realViewerFont, realViewerFontSize, realViewerLineHeight, realViewerSideMargin, viewerImageNone])

    useEffect(()=>{
        // console.log("refresh");
        refreshAfter();
        // swiperRef.current.swiper.slideTo(0);
        
        // setInit(false);
        setTimeout(()=>{
            
            setPage(0);
            refresh();
            // setInit(false);
        },50)

        // setTimeout(()=>{
            
        // },300)
    },[data])

    useEffect(()=>{
        if(fadeState === true){
            const page = getViewerData(type, "horizontal",cid);
            if(page ){
                // console.log("asdf")
                swiperRef.current.swiper.slideTo(page);
                return;
            }
            swiperRef.current.swiper.slideTo(0);
        }
    },[fadeState])

    useEffect(() => {
        const container = containerRef.current;
        let timeover = null;
        if (container) {
            const observer = new ResizeObserver(entries => {
                if(timeover){
                    clearTimeout(timeover);
                    timeover = null;
                }
                for (let entry of entries) {
                    const { width, height } = entry.contentRect;
                    if(width <= 0){
                        document.body.style.position = '';
                        document.body.style.width = '';
                        document.body.style.overflow = '';
                        document.body.style.transform = '';
                        document.body.style.webkitTransform = '';
                        document.body.style.webkitOverflowScrolling = '';
                        document.body.style.backgroundColor='';
                        return;
                    }
                    document.body.style.position = 'fixed';
                    document.body.style.width = '100%';
                    document.body.style.overflow = 'hidden';
                    document.body.style.transform = 'translate3d(0,0,0)';
                    document.body.style.webkitTransform = 'translateZ(0)';
                    document.body.style.webkitOverflowScrolling = 'touch';
                    document.body.style.backgroundColor=getThemeBodyColor(viewerTheme);
                }
                timeover = setTimeout(()=>{
                    // setPages([]);
                    // setFadeState(false)
                    console.log("refresh======================", fadeState)

                    // if(swiperRef && swiperRef.current?.swiper && !swiperRef.current?.swiper?.destroyed && swiperRef.current?.swiper?.slides?.length > 1){
                    //     setFadeState(true);
                    //     return false;
                    // }

                    const pageChange = [];
                    for (let entry of entries) {
                        const { width, height } = entry.contentRect;
                        // console.log("aaa >> ", width, height);
                        // console.log("aaa >> ", width, height);
                        if(width <= 0){
                            return;
                        }

                        
// console.log(data)
                        for(let item of data?.contents){
                            switch(item?.type){
                                case "image":
                                    if(!viewerImageNone || type==="toon"){
                                        console.log(item)
                                        pageChange.push(...[item]);
                                    }
                                    break;
                                case "text":
                                    const text = splitTextIntoPages(decryptOld(item?.data), width, height, style[realViewerFont], realViewerFontSize, realViewerLineHeight);
                                    // console.log(text)
                                    pageChange.push(...text);
                                    // return;
                                    break;
                            }
                        }
                        
                    }
                    setPages(pageChange)

                    // setTimeout(()=>{
                    //     swiperRef.current.swiper.slideTo(0);
                    // },150)
                    
                    setTimeout(()=>{
                        setFadeState(true);
                    },300);
                    
                    // console.log("pageChange?.length", pageChange?.length - 1)
                    setTotalPage(pageChange?.length - 1);
                },150);
                
            });
            observer.observe(container);

            // CSS 적용
            document.body.style.position = 'fixed';
            document.body.style.width = '100%';
            document.body.style.overflow = 'hidden';
            document.body.style.transform = 'translate3d(0,0,0)';
            document.body.style.webkitTransform = 'translateZ(0)';
            document.body.style.webkitOverflowScrolling = 'touch';
            document.body.style.backgroundColor=getThemeBodyColor(viewerTheme);
            
            return () => {
                if (container) observer.unobserve(container);

                document.body.style.position = '';
                document.body.style.width = '';
                document.body.style.overflow = '';
                document.body.style.transform = '';
                document.body.style.webkitTransform = '';
                document.body.style.webkitOverflowScrolling = '';
                document.body.style.backgroundColor='';
            };
        }

    }, [realViewerFont, realViewerFontSize, realViewerLineHeight, realViewerSideMargin, viewerImageNone, viewerTheme, data]);


    useEffect(()=>{
        // console.log(swiperRef.current.swiper)
        if(swiperRef.current.swiper.activeIndex != page){
            swiperRef.current.swiper.slideTo(page);
        }
        // const timeout = setTimeout(()=>{
        //     if(swiperRef.current.swiper.activeIndex != page){
        //         swiperRef.current.swiper.slideTo(page);
        //     }
        // },200);

        // return ()=>{
        //     clearTimeout(timeout)
        // }
    },[page]);

    const splitTextIntoPages = (text, boxWidth, boxHeight, font, fontSize, lineHeight) => {
        // text = text.replace(/\n/g, " \n");
        const words = text.split(' ');
        // console.log(text)
        // const words = text.split(/\n/);
        // let currentPage = [];
        let tempText = '';
        let prevText = '';

        const pageAdd = [];
        // setPages([...[]])

        let dummyDiv = document.createElement('div');
        dummyDiv.style.width = `${boxWidth}px`;
        dummyDiv.style.height = `${boxHeight-5}px`;
        dummyDiv.style.position = 'absolute';
        dummyDiv.style.fontSize = fontSize;
        dummyDiv.style.lineHeight = lineHeight;
        dummyDiv.style.visibility = 'hidden';
        dummyDiv.className = font;
        // dummyDiv.style.display = 'none';
        dummyDiv.style.whiteSpace = 'pre-wrap';
        document.body.appendChild(dummyDiv);

        while (words.length > 0) {
            let word = words.shift();
            const check = word.split(/\n/);
            // console.log(check)

            if(check?.length >= 2){

                for(let i=0,m=check.length;i<m;i++){
                    word = check[i];
                    
                    prevText = tempText;
                    if(i < (m-1)){
                        tempText += word + "";

                        // tempText += word + " ";
                        // let tmp = tempText + "\n\n";
                        let tmp = tempText + "";
                        
                        dummyDiv.innerText = tmp;
        
                        if (dummyDiv.scrollHeight > boxHeight) {
                            // console.log(dummyDiv.scrollHeight , boxHeight)
                            // console.log(tempText)
                            // console.log(prevText)
                            // console.log(word)
                            pageAdd.push(...[{
                                type:"text",
                                data:prevText,
                            }]);
                            // if(i < (m-1)){
                                tempText = word + "\n";
                            // } else {
                            //     tempText = word + " ";
                            // }
                        } else {
                            tempText += "\n";
                        }
                    } else {
                        tempText += word + " ";
                        let tmp = tempText + " ";
                        
                        dummyDiv.innerText = tmp;
        
                        if (dummyDiv.scrollHeight > boxHeight) {
                            // console.log(dummyDiv.scrollHeight , boxHeight)
                            // console.log(tempText)
                            // console.log(prevText)
                            // console.log(word)
                            pageAdd.push(...[{
                                type:"text",
                                data:prevText,
                            }]);
                            // if(i < (m-1)){
                            //     tempText = word + "\n";
                            // } else {
                                tempText = word + " ";
                            // }
                        }
                    }
                }
            } else {
                prevText = tempText;
                // tempText += word + "\n";
                tempText += word + " ";
            }

            // console.log()
            

            
            let tmp = tempText + " ";
            //let tmp = tempText + "\n \n";
            
            dummyDiv.innerText = tmp;

            if (dummyDiv.scrollHeight > boxHeight) {
                // console.log(word)
                // console.log(dummyDiv.scrollHeight , boxHeight)
                // console.log(dummyDiv.scrollWidth , boxWidth)
                // console.log(tempText)
                // console.log(prevText)
                // console.log(word)
                pageAdd.push(...[{
                    type:"text",
                    data:prevText,
                }]);
                tempText = word + " ";
                continue;
                // tempText = word + " ";
                // break;
            } else {
                //currentPage.push(word);
                
            }
            
            
        }

        pageAdd.push(...[{
            type:"text",
            data:tempText,
        }]);

        document.body.removeChild(dummyDiv);
        // setPages([...pageAdd]);
        return pageAdd;
    };


    // const handleTouchStart = (event) => {
    //     console.log('Swiper touched');
    //     // 이벤트 전파 중단
    //     event.stopPropagation();
    // };

    // useEffect(() => {
    //     const swiperElement = swiperRef.current;
    
    //     if (swiperElement) {
    //       swiperElement.addEventListener('touchstart', handleTouchStart);
    //       swiperElement.addEventListener('touchmove', handleTouchStart);
    //     }
    
    //     return () => {
    //       if (swiperElement) {
    //         swiperElement.removeEventListener('touchstart', handleTouchStart);
    //         swiperElement.removeEventListener('touchmove', handleTouchStart);
    //       }
    //     };
    //   }, [swiperRef]);

    // console.log("pages=>", pages?.length)
    
    return (
        <div 
            className={"view_body " + style['view_body'] + " " + styleVer['fade_element'] + " " + (fadeState?styleVer['fadein']:styleVer['fadeout'])}
            onClick={()=>{
                setVisible(!visible);
            }}
            onTouchMove={()=>{
                setVisible(false);
            }}
            >
            <div 
                className={"area_box " + style['area_box']}
                style={{
                    display: "flex",
                    alignItems: "center",
                    //height: "100vh",
                    overflow: "hidden",
                }}
                >
                {/* <!-- 좌우모드, 역방향일때 작품소개 영역일때만 scrollmode 추가 --> */}
                <div className={"contents_box m_wrap " + style['contents_box'] +" " + style['m_wrap']}>
                    {/* <!-- // 테마 viewer-white, viewer-gray, viewer-black-02, viewer-yellow, viewer-green 클래스 추가 -->
                    <!-- // 글꼴 NanumBarunGothic, NotoSansKR, YESMyoungjo -->
                    <!-- // 좌우여백 style 변경 --> */}
                    <div 
                        className={"area bg " + " " + style['area'] + " " + style['bg'] + " " + style['viewer-black-02']} 
                        style={{paddingLeft: "0", paddingRight: "0", paddingBottom:"0",paddingTop:"0", height:"100%"}}
                        >
                        <Swiper className="swiper mySwiper" 
                            ref={swiperRef}
                            onSlideChange={handleSlideChange}
                            nested={true}
                            dir={'rtl'}
                            // pagination={{ clickable: true }}
                            noSwiping={true}
                            onSwiper={(swiper)=>{
                                // swiperRef.current = swiper;
                                // console.log("init activeindex => ", swiper.activeIndex)
                                // if(!init){
                                //     const page = getViewerData(type, "horizontal",cid);
                                //     // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!init activeindex => ", swiper.activeIndex, pages?.length)
                                //     if(page){
                                //         setTimeout(()=>{
                                //             // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!init activeindex => ", swiper.activeIndex, pages?.length)
                                //             if(page){
                                //                 console.log("setPage",221,swiper.activeIndex)
                                //                 setPage(page);
                                //                 // console.log("11111111111---------------------"+page, pages?.length);
                                                
                                //                 swiperRef.current.swiper.slideTo(page);
                                //             }
                                //         },500);
                                //     } else {
                                //         console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!init activeindex => ", swiper.activeIndex, pages?.length)
                                //         swiperRef.current.swiper.slideTo(0);
                                //     }
                                    
                                //     return;
                                // }
                                // console.log("setPage",33,swiper.activeIndex)
                                // setPage(swiper.activeIndex);
                            }}
                            slidesPerView={'auto'}
                            spaceBetween={padding}
                            watchOverflow={true}
                            cssMode={isDeviceMobile ? false : false}
                            // speed={100}
                            // observer={true}
                            // observeParents={true}
                            rtl={true}
                            touchRatio={1} // 감도를 높이기 위해 값을 낮춤
                            touchAngle={45} // 좌우 스와이프 감도를 높이기 위해 각도를 낮춤
                            // threshold={0}
                            // resistance={false}
                            // resistanceRatio={1}
                            grabCursor={true}
                            // speed={200}
                            
                            breakpoints={{
                                769: {
                                    freeMode : true,
                                },
                            }}
                            style={{
                                position: "fixed",
                                width: "100%",
                                maxWidth: "768px",
                                overflowY: "hidden",
                                WebkitOverflowScrolling: "touch",
                                paddingLeft:padding,
                                paddingRight:padding,
                            }}
                        >
                            {pages === null ? null :
                                pages?.map((item, index)=>{
                                    if(item?.type === "image"){
                                        return (
                                            <SwiperSlide key={index} >
                                                <div 
                                                    className={"images " + style['images']}
                                                    style={{
                                                        height: "100%",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        width:"100%",
                                                    }}
                                                ><img src={item?.data} 
                                                    style={{
                                                        width: "auto",
                                                        maxHeight: "100%",
                                                        maxWidth:"100%",
                                                        pointerEvents:"none",
                                                    }}
                                                    /></div>
                                            </SwiperSlide>
                                        )
                                    }
                                    if(item?.type === "text"){
                                        return (
                                            <SwiperSlide 
                                                key={index}
                                                style={{
                                                    paddingTop:"calc(6vh)",
                                                    paddingBottom:"calc(6vh)",
                                                    direction: "ltr",
                                                }}
                                                >
                                                <div 
                                                    // ref={containerRef} 
                                                    className={"contents txt " + getThemeColor(viewerTheme) + " " + viewerFont + " " + style['contents'] + " " + style['txt'] + " " + style[viewerFont]} 
                                                    style={{
                                                        fontSize: realViewerFontSize, 
                                                        lineHeight: realViewerLineHeight, 
                                                        height:"100%",
                                                        display:"flex",
                                                        paddingTop:0,
                                                        paddingBottom:0,
                                                        paddingLeft:(realViewerSideMargin),
                                                        paddingRight:(realViewerSideMargin),
                                                        // alignItems:"center",
                                                    }}
                                                    >
                                                    <NewlineToBr text={item?.data} />
                                                </div>
                                            </SwiperSlide>
                                        )
                                    }
                                })
                            }

                            <SwiperSlide 
                                // key={index}
                                className={isDeviceMobile ? "" : "swiper-no-swiping"}
                                style={{
                                    // paddingTop:"calc(6vh)",
                                    // paddingBottom:"calc(6vh)",
                                    overflow:"auto",
                                    backgroundColor:"white",
                                    direction:"ltr",
                                }}
                                >
                                    {/* {swiperRef && swiperRef?.current && swiperRef?.current?.swiper?.activeIndex === swiperRef?.current?.swiper?.slides.length - 1 ?  */}
                                    <ViewerContents 
                                        className={getThemeColor(viewerTheme)} 
                                        data={data?.extra}
                                        next={data?.next_chapter}
                                        type={type}
                                        cid={cid}
                                        alert={alert}
                                        buyConfirm={buyConfirm}
                                        />
                                    {/* :<div></div>} */}
                            </SwiperSlide>
                        </Swiper>
                        

                        {/* <!-- 데이터 템플릿 사이즈 계산 사용용도 --> */}
                        <div className={style['m_wrap']} style={{height:"100%",position:"fixed",top:"0px",padding:"0 0px",zIndex:-1000}}>
                            {/* <div className={"images " + style['images']}><img src="https://image.bookpal.co.kr/img_view_big?m=0&cd=8UfLQP%2FdDPWoRvUPegueQA%3D%3D" /></div> */}
                            {/* <!-- // 글자크기, 줄간격 style 변경 --> */}
                            <div 
                                ref={containerRef} 
                                className={"contents viewer-black-01 txt " + viewerFont + " " + style['contents'] + " " + style['txt'] + " " + style[viewerFont]} 
                                style={{
                                    fontSize: realViewerFontSize, 
                                    lineHeight: realViewerLineHeight, 
                                    height:"100%", 
                                    visibility:"hidden",
                                    paddingLeft:(realViewerSideMargin),
                                    paddingRight:(realViewerSideMargin),
                                    paddingTop:"calc(6vh)",
                                    paddingBottom:"calc(6vh)",
                                    // borderColor:"red",
                                    // border:"2px",
                                }}>
                                {/* <NewlineToBr text={pages[0]} /> */}
                            </div>
                        </div>
                    </div>
                    {/* <!-- 작품 소개 영역 --> */}
                    <div className={"section_box m_wrap " + style['section_box'] + " " + style['m_wrap']}></div>
                </div>
                <div 
                    className={"arrow_btns m_wrap " + styleVer['arrow_btns'] + " " + styleVer['m_wrap'] + " " + style['arrow_btns'] + " " + style['m_wrap']}
                    style={{top:"0px", display:"block"}}
                    >
                    <div 
                        className="box-shadow-xs button btn_03 outlined label_off giant next radius-full label_off"
                        style={{right:"-65px"}}
                        onClick={(e) => {
                            e.stopPropagation();
                            
                            swiperRef.current.swiper.slidePrev()
                        }}
                        ><img src="/images/chevron_right.svg" /></div>
                    <div 
                        className="box-shadow-xs button btn_03 outlined label_off giant prev radius-full label_off"
                        style={{left:"-65px"}}
                        onClick={(e) => {
                            e.stopPropagation();
                            swiperRef.current.swiper.slideNext()
                            
                        }}
                        ><img src="/images/chevron_left.svg" /></div>
                </div> 
            </div>
        </div>
    )
}


export default ViewerHorizontalReverse;