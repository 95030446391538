import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import { ApiCommonFaqKeyword } from 'api/Common';
import { ApiAuthInfo } from 'api/Auth';
import useAlertModal from 'component/common/modal/useAlertModal';
import useConfirmModal from 'component/common/modal/useConfirmModal';

import Header from 'component/common/header/Header';
import Footer from 'component/common/footer/Footer';

import SubNav from "component/common/nav/SubNav";
import HelpQnaSubMenuNav from './HelpQnaSubMenuNav';

import style from './Help.module.scss';


import img_faq_search_img from 'assets/images/help/faq_search_img.png';


function HelpOneToOneQna(){


    const navigate = useBookpalNavigate();

    const [info, setInfo] = useState(null);
    const [keyword, setKeyword] = useState(null);

    const [AlertModal, alert] = useAlertModal();
    const [ConfirmModal, showConfirmModal] = useConfirmModal({
        content:"서비스 이용을 위하여\n로그인이 필요합니다.",
        btnTitle:"로그인",
        btnCancleTitle:"다음에 할게요.",
        action:()=>{
            navigate("/login",{state:{return_path:"/mypage_menu/help/one_qna"}});
        }
    })
    
    const handlerKeyword = ApiCommonFaqKeyword({
        onSuccess:(data)=>{
            console.log(data);
            if(data?.status === true){
                setKeyword(data?.result);
                return;
            }
            setKeyword([]);
        },
        onError:(e)=>{
            alert("통신에 실패하였습니다. 다시 시도해 주세요.");
        }
    });

    const handlerInfo = ApiAuthInfo({
        onSuccess:(data)=>{
            if(data?.status === true){
                setInfo(data?.result);
                return;
            }
            alert("시스템 오류입니다. 다시 시도해 주세요.");
        },
        onError:(e)=>{
            alert("통신에 실패하였습니다. 다시 시도해 주세요.");
        }
    })

    const handlerMoveQna = () => {
        if(info?.is_login !== true){
            showConfirmModal();
        } else {
            navigate("/mypage_menu/help/qna");
        }
    };

    const handlerMoveQnaHistory = () => {
        navigate("/mypage_menu/help/qna_history");
    };

    const handlerMoveFaq = (keyword, tab) => {
        console.log(keyword, tab)
        // navigate("/mypage_menu/help/faqsearch", {state: {keyword:keyword}});
        navigate("/mypage_menu/help/faq", {state: {tab:tab,subTab:keyword}});
    };

    // const handlerMoveNotice = () => {
    //     navigate("/mypage_menu/help/notice");
    // };

    ///mypage_menu/help/notice

    useEffect(()=>{
        handlerInfo();
        handlerKeyword();
    },[]);

    if(info === null || keyword === null){
        return (
            <></>
        )
    }

    return (
        <>
            <Header useMobile={false} pcTop={"-64px"} />
            <SubNav 
                name={"1:1 문의"}
                usePc={true} 
                useMobile={true} 
                usePcRightBtns={true}
                useBtnPrev={true}
                useBtnPcPrev={true}
                useBtnHome={false}

                setTop={'0px'} 
                setPcTop={'0px'} 
                />


            
                {/* <!-- subDepth --> */}
                <HelpQnaSubMenuNav 
                    isLogin={info?.is_login}
                    loginSubmit={()=>{
                        showConfirmModal();
                    }}
                    to={"/mypage_menu/help/one_qna"}
                />
                {/* <!-- //subDepth --> */}
                
                {/* <!-- container --> */}
                <div id="container" className={style['containerr']} style={{marginBottom:"76px"}}>
                    <section className={"qna_box search_box wrapper " + style['qna_box'] + " " + style['search_box']}>
                        <div className={"area_box " + style['area_box']}>
                            <div className={"before s_wrap " + style['before'] + " " + style['s_wrap']}>
                                <div className={"section solo_section " + style['solo_section'] + " " + style['section']} style={{paddingRight:"0"}}>
                                    <div className={"top_con clearfix " + style['top_con']}>
                                        <div className={"title_con left " + style['title_con']}>
                                            <h4 className="title color-txt-normal title-04 text_overflow">어떤 문제가 발생했나요?</h4>
                                            <p className="desc text_overflow title-02-m color-txt-04">자주 묻는 키워드를 살펴보세요.</p>
                                        </div>
                                        <div className={"cover_img right " + style['cover_img'] + " " + style['right']}>
                                            <img src={img_faq_search_img} />
                                        </div>
                                    </div>
                                </div>
                                <div className={"type_list section " + style['type_list']}>
                                    <ul>
                                        {keyword && Array.isArray(keyword) ? keyword.map((item, index)=>(
                                            <li key={index}>
                                                <a 
                                                    className="button btn_03 label_on outlined medium radius-full round"
                                                    data-keyword={item?.keyword}
                                                    data-tab={item?.tab}
                                                    onClick={(e)=>{
                                                        // console.log(e.target)
                                                        const keyword = e.currentTarget.getAttribute("data-keyword");
                                                        const tab = e.currentTarget.getAttribute("data-tab");
                                                        // console.log(keyword)
                                                        // searchKeywordClick(keyword);
                                                        handlerMoveFaq(keyword, tab);
                                                        // navigate("/mypage_menu/help/faqsearch");
                                                    }}
                                                    >
                                                    <span 
                                                        className="btn-03 color-txt-04"
                                                        data-keyword={item?.keyword}
                                                        >{item.keyword}</span>
                                                </a>
                                            </li>
                                        )) : null}
                                    </ul>
                                </div>
                                <div className={"section button_box " + style['section'] + " " + style['button_box']}>
                                    <p className="body-03-long color-txt-04">문제 해결이 어려우신가요?</p>
                                    <button 
                                        type="button" 
                                        className={"button btn-04 btn_01 filled large filled label_on white-always txt radius-full " + style['button']}
                                        onClick={handlerMoveQna}
                                        style={{width:"auto"}}
                                        ><span>1:1 문의하기</span></button>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {/* <!-- container --> */}

            <Footer />
            <AlertModal />
            <ConfirmModal />
        </>
    );
}




export default HelpOneToOneQna;