import { useApiMutation } from './common/ApiCommon';


export const ApiAuthLogin = (options) => {

    const subUrl = "/auth/login";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiAuthLogout = (options) => {

    const subUrl = "/auth/logout";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiAuthJoin = (options) => {

    const subUrl = "/auth/join";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiAuthJoinAgree = (options) => {

    const subUrl = "/auth/join_agree";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiAuthJoinValidator = (options) => {

    const subUrl = "/auth/join_validator";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiAuthEditValidator = (options) => {

    const subUrl = "/auth/edit_validator";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiAuthSocialLoginUrl = (options) => {
    const subUrl = "/social/login_url";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiAuthSocialLogin = (options) => {
    const subUrl = "/social/login";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiAuthSocialJoin = (options) => {
    const subUrl = "/social/join";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiAuthSocialConnect = (options) => {
    const subUrl = "/social/connect";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiAuthSocialUnConnect = (options) => {
    const subUrl = "/social/unconnect";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiAuthFindId = (options) => {

    const subUrl = "/auth/find_id";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiAuthFindPw = (options) => {

    const subUrl = "/auth/find_pw";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiAuthFindPwReset = (options) => {

    const subUrl = "/auth/find_pw_reset";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiAuthFindPwResetId = (options) => {

    const subUrl = "/auth/find_pw_reset_id";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiAuthCert = (options) => {

    const subUrl = "/auth/cert";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

// export const ApiAuthCertReturn = (options) => {

//     const subUrl = "/auth/cert_return";
//     const successFn = options?.onSuccess || (()=>{});
//     const errorFn = options?.onError || (()=>{});

//     const mutation = useApiMutation(subUrl, successFn, errorFn);
//     return mutation;
// };


export const ApiAuthConfirmPw = (options) => {

    const subUrl = "/auth/confirm_pw";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiAuthConfirmPwNew = (options) => {

    const subUrl = "/auth/confirm_pw_new";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiAuthInfo = (options) => {

    const subUrl = "/auth/info";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};