import { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Thumbs, EffectFade, Autoplay } from 'swiper/modules';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BookpalLazyLoadImage from '../custom/BookpalLazyLoadImage';
import { ImgDefault } from 'utils/Images';
import { ApiCommonBannerClick } from 'api/Common';

import thumbnailBg from 'assets/images/novel/thumbnail_bg.png';
import thumbnailBg2 from 'assets/images/novel/thumbnail_bg2.png';

import thumbnailIllust from 'assets/images/novel/thumbnail_illust.png';
import thumbnailIllust2 from 'assets/images/novel/thumbnail_illust2.png';
import { Link } from 'react-router-dom';
import BookpalLink from '../custom/BookpalLink';
import { useMemberInfo } from 'utils/State';

// function getTypeName(type){
//     switch(type){
//         case 'genre_novel': return "novel";
//         case 'genre_toon': return "toon";
//         default: return null;
//     }
// }

function GenreHomeBanner({ gb, data }){

    const [key, setKey] = useState(0);
    const [prevWidth, setPrevWidth] = useState(window.innerWidth);
    
    const memberInfo = useMemberInfo();
    // console.log(data)

    const isAudlt = memberInfo?.is_adult && memberInfo?.audit_switch;

    const refreshSwiper = () => {
        setKey((prevKey) => prevKey + 1);
    };

    const handleResize = () => {
        const currentWidth = window.innerWidth;
  
        if (Math.abs(currentWidth - prevWidth) > 1) {
          // 좌우로 10px 이상 변경되었을 때 동작하도록 설정
          console.log('Resized!');
          setPrevWidth(currentWidth);
          refreshSwiper();
        }
    };

    const bannerClickSubmit = ApiCommonBannerClick({
        onSuccess:(data)=>{
            console.log(data);
        },
        onError:(e)=>{

        }
    });

    const handlerBannerClick = (id)=>{
        if(id === "0" || id === null){
            return;
        }
        bannerClickSubmit({
            type:"genre_"+gb,
            id:id,
        });
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        // console.log("test -> use effect");

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if(!data || !Array.isArray(data)){
        return <></>
    }

    return (
        <section className="section genre_banner wrapper">
            <div className="list">
                <Swiper 
                    key={key}
                    className={"swiper mySwiper "}
                    modules={[Thumbs, EffectFade, Navigation, Pagination, Autoplay]}
                    fadeEffect={{
                        crossFade: true
                    }}
                    watchOverflow={true}
                    slidesPerView={1}
                    loop={true}
                    observer={true}
                    observeParents={true}
                    effect="fade"
                    pagination={{
                        el: '.swiper-pagination',
                        clickable: false
                    }}
                    navigation={{
                        nextEl: '.swiper-button-next',
                        prevEl: ".swiper-button-prev",
                    }}
                    autoplay={{
                        delay: 4000,
                        disableOnInteraction: false,
                    }}
                    speed={1200}
                    onInit={(swiper) => {
                        swiper.slides.forEach((slide, index) => {
                        if(slide.swiperSlideIndex == 0){
                            if(swiper && swiper.slideTo){
                            swiper.slideTo(index, 0);
                            }
                        }
                        });
                    }}
                >
                        {
                            data.map((item, index)=>{

                                return (
                                    <SwiperSlide key={index}>
                                        {/* <a href="#"> */}
                                        {/* <Link to={item?.link}> */}
                                        <BookpalLink to={item?.link}>
                                            <div className="cover_img radius-sm" style={{backgroundImage: item?.adult_type === "all" ? (`url(${item?.bg_image ?? ImgDefault("genre_banner_bg")})`) : (`url(${isAudlt ? item?.bg_image : ImgDefault("genre_banner_bg")})`)}}>
                                                {/* <img src={item?.image} /> */}
                                                {/* <BookpalLazyLoadImage 
                                                    placeholderSrc={<img src={ImgDefault("genre_banner")} />}
                                                    // effect="opacity"
                                                    src={item?.image}
                                                    onError={(e)=>{
                                                        e.currentTarget.src = ImgDefault("genre_banner");
                                                    }}
                                                /> */}
                                                <BookpalLazyLoadImage 
                                                        adult={item?.adult_type !== "all"}
                                                        adultswitch={memberInfo?.audit_switch}
                                                        defaulttype={"genre_banner"}
                                                        locktype={"genre_banner"}
                                                        src={item?.image}
                                                        background={"#ffffff00"}
                                                        data-id={item?.id}
                                                        onClick={(e)=>{
                                                            // console.log(123)
                                                            const id = e.currentTarget.getAttribute("data-id");
                                                            handlerBannerClick(id)
                                                        }}
                                                        // style={defaultImageStyle}
                                                    />
                                                {item?.is_badge ? <label className="label white-always txt btn-02" style={{background: item?.bg_color}}>{item?.badge_txt}</label> : null}
                                            </div>
                                            <div className="info_txt spacing_04 content_box radius-sm" style={{background: item?.bg_color}}>
                                                <div className="title white-always title-01-b-long txt text_overflow">{item?.title}</div>
                                                <div className="desc white-always txt body-01 text_overflow">{item?.description}</div>
                                            </div>
                                        </BookpalLink>
                                        {/* </a> */}
                                    </SwiperSlide>
                                )
                            })
                        }
                        
                </Swiper>
                <div className="swiper-pagination"></div>
                <div className="arrow_btns">
                    <div className="swiper-button-next color-bg-01 box-shadow-xs color-border-01 btn_03 medium label_off radius-full round button"><img src="/images/chevron_right.svg" /></div>
                    <div className="swiper-button-prev color-bg-01 box-shadow-xs color-border-01 btn_03 medium label_off radius-full round button"><img src="/images/chevron_left.svg" /></div>
                </div>

                
            </div>
        </section>
    );
}



export default GenreHomeBanner;