import { ApiAuthInfo } from 'api/Auth';
import { ApiCommonMemberLeave } from 'api/Common';
import { SetState } from 'utils/State';

import Header from 'component/common/header/Header';
import Footer from 'component/common/footer/Footer';

import SubNav from "component/common/nav/SubNav";

import style from './style/Mypage.module.scss';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import useAlertModal from 'component/common/modal/useAlertModal';
import useConfirmCustomModal from 'component/common/modal/useConfirmCustomModal';
import { appSetLogout, appSetMove, isApp } from 'utils/AppUtil';
import { getBookpalLink } from 'utils/BookpalLinkUtil';

function MypageUnRegist(){

    const navigate = useBookpalNavigate();

    const setState = SetState();

    const [info, setInfo] = useState(null);
    const [agree, setAgree] = useState(false);
    const [reason, setReason] = useState(null);
    const [etcReason, setEtcReason] = useState("");
    const [password, setPassword] = useState("");

    const [AlertModal, alert] = useAlertModal();
    const [ConfirmModal, confirm] = useConfirmCustomModal()

    const infoSubmit = ApiAuthInfo({
        onSuccess:(data)=>{
            if(data?.status === true){
                setInfo(data?.result);
            }
        },
        onError:()=>{
            
        }
    });

    const back = ()=>{
        navigate("/mypage/edit");
    }

    const leaveSubmit = ApiCommonMemberLeave({
        onSuccess:(data)=>{
            if(data?.status === true){
                alert("회원 탈퇴","탈퇴가 완료되었습니다.",()=>{
                    if(isApp()){
                        appSetLogout();
                        appSetMove("",getBookpalLink("main"));
                        return;
                    }
                    setState.delToken();
                    navigate("/");
                });
                return;
            }
            switch(data?.code){
                case "error_password": alert("회원 탈퇴", "비밀번호가 다릅니다."); break;
                default: alert("회원 탈퇴", "회원 탈퇴에 실패하였습니다. 다시 시도해 주세요."); break;
            }
        },
        onError:(e)=>{
            alert("회원 탈퇴", "통신에 실패하였습니다. 다시 시도해 주세요.");
        }
    })

    const unregistSubmit = ()=>{
        if(agree === false){
            alert("회원 탈퇴","유의사항에 동의하셔야 탈퇴가 가능합니다.");
            return;
        }
        if(reason === null || reason === ""){
            alert("회원 탈퇴","탈퇴사유를 선택해주세요.");
            return;
        }
        if(reason === "기타"){
            if(etcReason === null || etcReason === ""){
                alert("회원 탈퇴","기타 사유를 입력해주세요.");
                return;
            }
        }
        if(info?.sns === "" || info?.sns === "etc"){
            if(password === ""){
                alert("회원 탈퇴","비밀번호를 입력해주세요.");
                return;
            }
        }

        confirm({
            title:"회원 탈퇴",
            content:"회원 탈퇴 시 쿠폰, 재화는 모두 소멸되며 , 계정 정보 복구가 불가능합니다. 또한 모든 콘텐츠 열람이 불가하며 재가입시에도 동일합니다.\n정말로 탈퇴하시겠어요? ",
            successBtnTitle:"계속 사용하기",
            cancleBtnTitle:"네, 탈퇴할게요.",
            successAction:()=>{},
            cancleAction:()=>{
                leaveSubmit({
                    password:password,
                    reason:(reason === "기타"?etcReason : reason),
                })
            }
        })
    }

    useEffect(()=>{
        infoSubmit();
    },[]);
    
    if(info === null){
        return <></>
    }

    return (
        <>
            <Header useMobile={false} />
            <SubNav 
                name={"회원 탈퇴"}
                usePc={true} 
                useMobile={true} 
                usePcRightBtns={true}
                useBtnPrev={true}
                useBtnPcPrev={true}
                useBtnHome={true}

                setTop={'0px'} 
                setPcTop={'64px'} 
                />
            {/* <!-- container --> */}
            <div id="container" style={style['container']}>
                <section className={"connection_box wrapper " + style['connection_box'] + " " + style['wrapper']}>
                    <div className={"section " + style['section']}>
                        <div className={"tit_con title-02-b color-txt-normal " + style['tit_con']}>탈퇴 안내</div>
                        <div className={"check_box radius-sm border-line border-soild color-border-01 " + style['check_box']}>
                            <div className={"text_box body-03-long color-txt-03 " + style['text_box']}>
                                탈퇴 진행시<br /><br />
                                1&#41; 회원님께서 보유하고 계시던 쿠폰 등 재화는 모두 소멸되며 더 이상 사용이 불가합니다.<br />
                                2&#41; 회원님께서 구매하셨던 모든 유료 콘텐츠의 열람이 불가합니다.<br />
                                3&#41; 재가입 시 잔여 쿠폰, 구입하신 콘텐츠 내역 등은 복구가 불가합니다.<br /><br />
                                또한 탈퇴 이후에는 동일한 인증정보로 재가입이 불가하오니 신중하게 탈퇴를 진행해주시길 부탁드립니다.<br />
                                탈퇴를 진행하시겠습니까?
                            </div>
                            <p className={"check_event " + style['check_event']}>
                                <label htmlFor="check" className={"checkbox_round check-type-01 medium label_on " + style['checkbox_round']}>
                                    <input type="checkbox" id="check" checked={agree} onChange={(e)=>{setAgree(e.target.checked)}}/>
                                    <span className="box"></span>
                                    <span className="txt color-txt-04">유의사항 확인 후 동의합니다 <span className="color-btn-01 txt">(필수)</span></span>
                                </label>
                            </p>
                        </div>
                    </div>
                    <div className={"section " + style['section']} style={{paddingTop:"0", paddingBottom:"0",margin:"0"}}>
                        <div className={"tit_con title-02-b color-txt-normal " + style['tit_con']}>탈퇴 사유 <span className="btn-04 color-btn-01 txt">(필수)</span></div>
                        <ul className={"check_list " + style['check_list']}>
                            <li>
                                <label htmlFor="a1" className="radio_button check-type-01 medium label_on">
                                    <input type="radio" id="a1" name="radio" value={"잦은 시스템 오류"} checked={reason === "잦은 시스템 오류"} onChange={(e)=>{setReason(e.target.value)}}/>
                                    <span className="box"></span>
                                    <span className="txt color-txt-04">잦은 시스템 오류</span>
                                </label>
                            </li>
                            <li>
                                <label htmlFor="a2" className="radio_button check-type-01 medium label_on">
                                    <input type="radio" id="a2" name="radio" value={"콘텐츠로 인한 불만"} checked={reason === "콘텐츠로 인한 불만"} onChange={(e)=>{setReason(e.target.value)}}/>
                                    <span className="box"></span>
                                    <span className="txt color-txt-04">콘텐츠로 인한 불만</span>
                                </label>
                            </li>
                            <li>
                                <label htmlFor="a3" className="radio_button check-type-01 medium label_on">
                                    <input type="radio" id="a3" name="radio" value={"서비스 운영이 마음에 들지 않음"} checked={reason === "서비스 운영이 마음에 들지 않음"} onChange={(e)=>{setReason(e.target.value)}}/>
                                    <span className="box"></span>
                                    <span className="txt color-txt-04">서비스 운영이 마음에 들지 않음</span>
                                </label>
                            </li>
                            <li>
                                <label htmlFor="a4" className="radio_button check-type-01 medium label_on">
                                    <input type="radio" id="a4" name="radio" value={"사용하지 않는 계정"} checked={reason === "사용하지 않는 계정"} onChange={(e)=>{setReason(e.target.value)}}/>
                                    <span className="box"></span>
                                    <span className="txt color-txt-04">사용하지 않는 계정</span>
                                </label>
                            </li>
                            <li>
                                <label htmlFor="a5" className="radio_button check-type-01 medium label_on">
                                    <input type="radio" id="a5" name="radio" value={"기타"} checked={reason === "기타"} onChange={(e)=>{setReason(e.target.value)}}/>
                                    <span className="box"></span>
                                    <span className="txt color-txt-04">기타</span>
                                </label>
                                {/* <!-- 기타 눌렀을때만 보이게 --> */}
                                <ul className="input_box" style={{display: (reason === "기타" ? "block":"none")}}>
                                    <li>
                                        <div className="text_input border-line border-soild radius-sm color-bg-01 color-border-02">
                                            <textarea 
                                                placeholder="더 나은 북팔이 될 수 있도록 의견을 들려주세요." 
                                                className="color-txt-normal body-03-long"
                                                value={etcReason}
                                                onChange={(e)=>{setEtcReason(e.target.value)}}
                                                ></textarea>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    {/* <!-- 비밀번호 입력 불가 계정인 경우 아예 안보이게 --> */}
                    {info?.sns == "" || info?.sns == "etc" ?
                    <div className={"section " + style['section']} style={{paddingTop:"0",paddingBottom:"0"}}>
                        <div className={"tit_con title-02-b color-txt-normal " + style['tit_con']}>비밀번호 확인</div>
                        <ul className={"input_box " + style['input_box']}>
                            <li>
                                <div className="text_input border-line border-soild radius-sm color-bg-01 color-border-02">
                                    <input 
                                        type="password" 
                                        value={password} 
                                        onChange={(e)=>{setPassword(e.target.value)}}
                                        placeholder="비밀번호를 입력하세요." 
                                        className="color-txt-normal body-03" />
                                    <p className="right_btns">
                                        <span className="btnClear"></span>
                                        <span className="Error"></span>
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    :null}
                    
                    <div className={"section button_box " + style['section'] + " " + style['button_box']} style={{margin:"0"}}>
                        <button 
                            type="button" 
                            className="button btn_01 filled large label_on radius-sm btn-04"
                            onClick={back}
                            >취소</button>
                        <button 
                            type="button" 
                            className="button btn_01 outlined large label_on radius-sm btn-04 color-btn-01 txt" 
                            onClick={unregistSubmit} 
                            >탈퇴하기</button>
                    </div>
                </section>
            </div>
            {/* <!-- container --> */}

            <Footer />
            <AlertModal />
            <ConfirmModal />
        </>
    );
}




export default MypageUnRegist;