import { Link } from 'react-router-dom';
import { ApiEventEventDetail } from 'api/Event';

import Header from 'component/common/header/Header';
import MobileNav from 'component/common/nav/MobileNav';

import Footer from 'component/common/footer/Footer';
import TopBtn from 'component/common/button/top/TopBtn';

import useSelectModal from 'component/common/modal/useSelectModal';
import SubNav from 'component/common/nav/SubNav';


import style from './style/Promotion.module.scss';


import img_promotion_event from 'assets/images/promotion/event.png';
import img_promotion_download from 'assets/images/promotion/download.svg';
import { useEffect, useState } from 'react';
import Tmpl7 from './event_tmpl/Tmpl7';
import Tmpl13 from './event_tmpl/Tmpl13';
import Tmpl17 from './event_tmpl/Tmpl17';
import Tmpl18 from './event_tmpl/Tmpl18';
import Tmpl21 from './event_tmpl/Tmpl21';
import Tmpl25 from './event_tmpl/Tmpl25';



function BundleEventDetail({id}){

    const modalData = {
        all:"전체",
        select1:"select option 01",
        select2:"select option 02",
    }
    const [Modal, modalShow, selectKey] = useSelectModal(modalData, "all");
    const [data, setData] = useState(null);

    const [title, setTitle] = useState("");

    const eventSubmit = ApiEventEventDetail({
        onSuccess:(data)=>{
            if(data?.status === true){
                setData(data?.result);
                setTitle(data?.result[0]?.event_title ?? "");
                return;
            }
        },
        onError:(e)=>{

        },
    });

    useEffect(()=>{
        eventSubmit({
            event_id:id,
        });
    },[])


    if(data === null){
        return null;
    }


    return (
        <>
            <Header 
                useMobile={false}
            />
            {/* <MobileNav /> */}
            {/* <!-- 서브메뉴 --> */}
            <SubNav 
                name={title} 
                useHome={true} 
                usePc={true}
                useMobile={true}
                useBtnPrev={true}
                useBtnHome={true}
                setPcTop={"64px"}
                setTop={"0px"}
                 />
            {/* <!-- //서브메뉴 --> */}

            {/* <!-- container --> */}
            <div id="container" className={style['container']}>
 
                {/* <!-- 섹션 --> */}
                <section className={"section wrapper event_temp " + style['section']}>
                    <div className={"typeList " + style['typeList']}>

                        {data && Array.isArray(data) && data?.length > 0 ?
                            data.map((item, index)=>{

                                switch(item?.templ_id){
                                    case 7: return <Tmpl7 data={item} />;
                                    case 13: return <Tmpl13 data={item} />;
                                    case 17: return <Tmpl17 data={item} />;
                                    case 18: return <Tmpl18 data={item} />;
                                    case 21: return <Tmpl21 data={item} />;
                                    case 25: return <Tmpl25 data={item} />;
                                    default: return null;
                                }
                            })
                        :null}
                    </div>
                </section>

            </div>
            <Modal />
            {/* <!-- container --> */}
            <Footer />
            <TopBtn />
        </>
    );
}


export default BundleEventDetail;