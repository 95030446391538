import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import BookpalLink from '../custom/BookpalLink';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ImgBadge, ImgDefault, ImgCover } from 'utils/Images';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BookpalLazyLoadImage from '../custom/BookpalLazyLoadImage';
import { useMemberInfo } from 'utils/State';

import style from './RankingSubSection.module.scss';
// import LazyLoad from 'react-lazyload';
import { useWidth } from 'utils/Common';
import useAlertModal from '../modal/useAlertModal';

function getDefaultImgStyle(gb){
    const style = {};
    switch(gb){
        case "pc": 
            style['minWidth'] = "100px";
            style['minHeight'] = "150px";
            break;
        case "mobile": 
            style['minWidth'] = "90px";
            style['minHeight'] = "135px";
            break;
        // case ""
    }
    return style;
}

function RankingSubSection({ tabs, data }){
    const swiperRef = useRef(null);

    const width = useWidth();

    const memberInfo = useMemberInfo();

    const [defaultImageStyle, setDefaultImageStyle] = useState({});
    const [AlertModal, alert] = useAlertModal();
    const [prevBtn, setPrevBtn] = useState(false);
    const [nextBtn, setNextBtn] = useState(false);

    useEffect(()=>{
        if(width > 540){
            setDefaultImageStyle(getDefaultImgStyle("pc"));
        } else {
            setDefaultImageStyle(getDefaultImgStyle("mobile"));
        }
    },[width]);

    useEffect(()=>{

        const swiper = swiperRef.current.swiper;

        const updateNavButtons = () => {
            if (swiper) {
                const isBeginning = swiper.isBeginning;
                const isEnd = swiper.isEnd;
                // setShowNavButtons(!(isBeginning && isEnd));
                // setUseNavigateBtn(!(isBeginning && isEnd))

                setPrevBtn(!isBeginning);
                setNextBtn(!isEnd);
                // console.log("isEnd",isEnd);
            }
        };

        if(swiper){
            swiper.on('slideChange', updateNavButtons);
            updateNavButtons();
        }

        return () => {
            if(swiper){
                swiper.off('slideChange', updateNavButtons);
            }
        };

    },[])

    if(data === null){
        return <></>
    }

    return (
        <>
            <Swiper className="swiper mySwiper" 
                ref={swiperRef}
                slidesPerView={'auto'}
                spaceBetween={4}
                watchOverflow={true}
                observer={true}
                observeParents={true}
                style={{height:"215px"}}
                breakpoints={{
                    769: {
                        freeMode : true,
                    },
                }}
            >
                {data?.map((item, index)=>{
                    
                    return (
                        <SwiperSlide key={tabs+"_"+index}>
                            {/* <a href="#"> */}
                            <BookpalLink 
                                to={"/content/"+item?.ct_type+"/"+item?.bid}
                                check={{
                                    view_type:memberInfo?.view_type,
                                    work_adult:item?.adult,
                                }}
                                alert={alert}
                                >
                                <div className="ranking_number color-txt-01">{item?.ranking}</div>
                                <div className="thum_box">
                                    <div className="cover_img radius-xs">
                                        {item?.adult ? <p className="ic_adult badge_adult_middle"><img src="./images/ic_adult.svg" /></p> : null}
                                        {/* <img src={item?.cover} /> */}
                                        {/* <BookpalLazyLoadImage 
                                                placeholderSrc={ImgDefault("cover_logo")}
                                                // effect="blur"
                                                src={ImgCover(memberInfo?.view_type, item?.adult, ImgDefault("cover_lock"), item?.cover)}
                                                style={defaultImageStyle}
                                                onError={(e)=>{
                                                    e.currentTarget.src = ImgDefault("cover_logo");
                                                }}
                                            /> */}
                                        <BookpalLazyLoadImage 
                                                adult={item?.adult}
                                                adultswitch={memberInfo?.audit_switch}
                                                defaulttype={"cover_logo"}
                                                locktype={"cover_lock"}
                                                src={item?.cover}
                                                style={defaultImageStyle}
                                            />
                                    </div>
                                    <div className="info_txt spacing_04">
                                        <div className="title color-txt-normal title-book-02-m text_overflow2">{item?.title}</div>
                                        <div className="writer color-txt-03 body-01 text_overflow">{item?.wr_nick}</div>
                                    </div>
                                </div>
                            {/* </a> */}
                            </BookpalLink>
                        </SwiperSlide>
                    )
                })}
                
            </Swiper>
            <div className={"arrow_btns " + style['arrow_btns']}>
                {nextBtn ? 
                <div 
                    className="swiper-button-next button btn_03 color-bg-01 box-shadow-xs color-border-01"
                    onClick={() => swiperRef.current.swiper.slideNext()}
                ><img src="./images/chevron_right.svg" /></div>
                :null}
                {prevBtn ? 
                <div 
                    className="swiper-button-prev button btn_03 color-bg-01 box-shadow-xs color-border-01"
                    onClick={() => swiperRef.current.swiper.slidePrev()}
                ><img src="./images/chevron_left.svg" /></div>
                :null}
            </div>
            <AlertModal />
        </>
    )
}



export default RankingSubSection;