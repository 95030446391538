import appBannerStyle from './AppBanner.module.scss';
import { useLocationName, useNoneMobileHeaderList, useIsMobile } from 'utils/Common';
import { Link } from 'react-router-dom';
import { GetState, SetState } from 'utils/State';

function AppBanner(){

    const page = useLocationName();
    const getState = GetState();
    const setState = SetState();

    const display = getState.useGetAppBanner();
    const isMobile = useIsMobile();

    const style = {backgroundColor: "#FEEAEC",display:(display) ? "none": isMobile ? "flex" : "none"};

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let link = '';

    if (/android/i.test(userAgent)) {
        link = 'https://play.google.com/store/apps/details?id=kr.co.bookpal.webnovel';
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        link = 'https://apps.apple.com/kr/app/%EB%B6%81%ED%8C%94-%EC%9B%B9%EC%86%8C%EC%84%A4/id898129838';
    } else {
        link = 'https://play.google.com/store/apps/details?id=kr.co.bookpal.webnovel'; // 디폴트 링크
    }

    const disableAppBanner = ()=>{
        setState.setAppBanner(true);
    }

    return (
        <div className={"app_banner " + appBannerStyle["app_banner"]} style={style}>
            <div className="left">
                <button 
                    type="button" 
                    className="close_btn"
                    onClick={disableAppBanner}
                    >
                    <img src="/images/app_banner_close_btn.svg" />
                </button>
                <p className="banner_img">
                    <img src="/images/app_banner_img.png" />
                </p>
            </div>
            <div className="right">
                <a href={link} target="_blank">
                    <img src="/images/app_banner_download_btn.svg" />
                </a>
            </div>
        </div>
    );
}


export default AppBanner;