import { Link } from 'react-router-dom';
import quickNavStyle from './QuickNav.module.scss';
import { ImgDefault } from 'utils/Images';
import LazyLoad from 'react-lazyload';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BookpalLazyLoadImage from '../custom/BookpalLazyLoadImage';
import BookpalLink from '../custom/BookpalLink';


function QuickNav({ data }){

    if(!data){
        return <></>
    }

    return (
        <section className={"wrapper " + quickNavStyle["quickMenu"]}>
            <ul className={"menu_list " + quickNavStyle["menu_list"]}>
                {data && Array.isArray(data) ? 
                    data.map((item, index)=>{

                        return (
                            <li key={"quick_" + index}>
                                <BookpalLink to={item?.link}>
                                
                                    <p className="images">
                                        <BookpalLazyLoadImage 
                                            placeholderSrc={ImgDefault("quick_menu")}
                                            effect="opacity-only"
                                            src={item?.image_light ?? ImgDefault("quick_menu")}
                                            onError={(e)=>{
                                                e.currentTarget.src = ImgDefault("quick_menu");
                                            }}
                                        />
                                        {/* <LazyLoad 
                                            debounce={5000} 
                                            placeholder={<img src={ImgDefault("main_light")} 
                                            />} >
                                            <img src={item?.image_light} />
                                        </LazyLoad> */}
                                    </p>
                                    <p className="s_tit color-txt-04 title-sub-01 text_overflow">{item?.title}</p>
                                
                                </BookpalLink>
                            </li>
                        )
                    })
                :null}
                {/* <li>
                    <Link to={"/promotion/midnight"}>
                    
                        <p className="images">
                            <img src="./images/quick_midnight.svg" />
                        </p>
                        <p className="s_tit color-txt-04 title-sub-01 text_overflow">벌써열두시</p>
                    
                    </Link>
                </li>
                <li>
                    <Link to={"/promotion/collectbook"}>
                        <p className="images">
                            <img src="./images/quick_midnight.svg" />
                        </p>
                        <p className="s_tit color-txt-04 title-sub-01 text_overflow">콜렉트북</p>
                    </Link>
                </li>
                <li>
                    <Link to={"/promotion/todaywebtoon"}>
                        <p className="images">
                            <img src="./images/quick_midnight.svg" />
                        </p>
                        <p className="s_tit color-txt-04 title-sub-01 text_overflow">오늘의 웹툰</p>
                    </Link>
                </li>
                <li>
                    <Link to={"/promotion/bundleevent"}>
                        <p className="images">
                            <img src="./images/quick_midnight.svg" />
                        </p>
                        <p className="s_tit color-txt-04 title-sub-01 text_overflow">묶음이벤트</p>
                    </Link>
                </li>
                <li>
                    <a href="#">
                        <p className="images">
                            <img src="./images/quick_midnight.svg" />
                        </p>
                        <p className="s_tit color-txt-04 title-sub-01 text_overflow">핫딜</p>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <img src="./images/quick_midnight.svg" />
                        <p className="s_tit color-txt-04 title-sub-01 text_overflow">신작</p>
                    </a>
                </li> */}
            </ul>    
        </section>
    )
}



export default QuickNav;