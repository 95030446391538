import { useEffect, useState } from 'react';
import { ApiMypageSnsConnectionInfo } from 'api/Mypage';
import { useDispatch } from 'react-redux';
import { ApiAuthSocialLoginUrl, ApiAuthSocialUnConnect } from 'api/Auth';
import { actionSetConnectSocialLoginType } from 'action/index';

import Header from 'component/common/header/Header';
import Footer from 'component/common/footer/Footer';

import SubNav from "component/common/nav/SubNav";

import style from './style/Mypage.module.scss';

import img_login_naver_icon from 'assets/images/user/login_naver_icon.svg';
import img_login_kakao_icon from 'assets/images/user/login_kakao_icon.svg';
import img_login_google_icon from 'assets/images/user/login_google_icon.svg';
import img_login_facebook_icon from 'assets/images/user/login_facebook_icon.svg';
import img_login_apple_icon from 'assets/images/user/login_apple_icon.svg';
import { useNavigate } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import useConfirmModal from 'component/common/modal/useConfirmModal';


function getSocialName(social){
    switch(social){
        case "naver": return "네이버";
        case "google": return "구글";
        case "facebook": return "페이스북";
        default: return null;
    }
}

function MypageEditSns(){

    const navigate = useBookpalNavigate();
    const dispatch = useDispatch();

    // const accessSns = ["naver","google","facebook"];
    const accessSns = ["naver","google"];
    const [snsData, setSnsData] = useState(null);
    const [selectSnsTypeData, setSelectSnsTypeData] = useState(null);
    const [selectSnsIdData, setSelectSnsIdData] = useState(null);
    
    const [ConfirmModal, showConfirmModal] = useConfirmModal({
        title:"해제하시겠습니까?",
        btnTitle:"연동해제",
        action:()=>{
            handlerSocialUnConnect({
                type:selectSnsTypeData,
                sns_id:selectSnsIdData
            });

        }
    })

    const snsConnectionInfoSubmit = ApiMypageSnsConnectionInfo({
        onSuccess:(data)=>{
            if(data?.status === true){
                setSnsData(data?.result);
            }
        },
        onError:()=>{

        }
    });


    const socialLoginGetUrl = ApiAuthSocialLoginUrl({
        onSuccess: (data) => {
            console.log(data);
            if(data?.status === true){
                
                
                window.location.href = data?.result?.login_url;
            } else {
                alert("시스템 문제가 발생하였습니다. 문제가 지속되면 관리자에게 문의주세요.");
            }
        },
        onError: (e) => {
            console.log(e);
            alert("통신이 실패하였습니다. 문제가 지속되면 관리자에게 문의주세요.");
        },
    });

    const handlerSocialLoginUrl = async (social_type) => {
        dispatch(actionSetConnectSocialLoginType(social_type));
        
        await socialLoginGetUrl({
            type: social_type,
            return_url: process.env.REACT_APP_BASE_URL + "/login",
        });
    };

    const snsOnOffSubmit = (e)=>{
        const item = e.target.getAttribute('data-item');
        const snsid = e.target.getAttribute('data-snsid');
        
        // setSnsData({...snsData, [item]:{...snsData[item], status:e.target.checked}}); 
        if(e.target.checked){
            handlerSocialLoginUrl(item);
        } else {
            setSelectSnsTypeData(item);
            setSelectSnsIdData(snsid);
            showConfirmModal();
        }
    }

    const handlerSocialUnConnect = ApiAuthSocialUnConnect({
        onSuccess:(data)=>{
            if(data?.status === true){
                // navigate("/mypage/editsns", {replace:true});
                window.location.reload();
            }
        },
        onError:(e)=>{

        }
    });



    useEffect(()=>{
        snsConnectionInfoSubmit();
    },[]);
    

    if(snsData === null){
        return (
            <></>
        )
    }

    return (
        <>
            <Header useMobile={false} />
            <SubNav 
                name={"SNS 연결정보"}
                usePc={true} 
                useMobile={true} 
                usePcRightBtns={true}
                useBtnPrev={true}
                useBtnPcPrev={true}
                useBtnHome={false}
                onPrev={()=>{
                    navigate("/mypage/edit");
                }}

                setTop={'0px'} 
                setPcTop={'64px'} 
                />
            {/* <!-- container --> */}
            <div id="container">
                <section className={"connection_box wrapper " + style['connection_box'] + " " + style['wrapper']}>
                    <div className={"section temp_list " + style['section'] + " " + style['temp_list']}>
                        <ul className={"signup_list " + style['signup_list']}>

                            { accessSns.map((item, index)=>{
                                const data = snsData[item] || null;
                                if(data === null){
                                    return (
                                        <></>
                                    )
                                }

                                return (
                                    <li className="border-bottom-line border-soild color-border-01">
                                        <div className={"left " + style['left']}>
                                            <p className={"sns_login " + style['sns_login']}>
                                                <span className={"sns_logo " + style['sns_logo']} id={item}></span>
                                                <span className="title color-txt-02 title-01-m-long">{getSocialName(item)} 아이디로 로그인</span>
                                            </p>
                                            {/* <!-- 사용중일때만 보이기 --> */}
                                            {data?.status === true ? <p className={"existing_email body-01-long color-txt-05 " + style['existing_email']}><span>{data?.email}</span> 사용중</p> : null}
                                        </div>
                                        <div className="right">
                                            <label className="large toggle_btn">
                                                <input 
                                                    role="switch" 
                                                    type="checkbox" 
                                                    data-item={item}
                                                    data-snsid={data?.id}
                                                    checked={data?.status}
                                                    onChange={snsOnOffSubmit}
                                                     />
                                            </label>
                                        </div>
                                    </li>
                                )
                            })}

                        </ul>
                    </div>
                </section>
            </div>
            {/* <!-- container --> */}

            <Footer />
            <ConfirmModal />
        </>
    );
}




export default MypageEditSns;