import React from 'react';
import he from 'he';

function EmphasizedText({ text, emphasizedText, emphasizedClassName, tag }) {
    // 문자열을 강조하는 함수
    const emphasizeText = (text, emphasizedTexts, emphasizedClassName, tag) => {
        // 강조할 텍스트가 문자열인 경우 배열로 변환
        if (typeof emphasizedTexts === 'string') {
            emphasizedTexts = [emphasizedTexts];
        }

        if (!Array.isArray(emphasizedTexts)) {
            return he.decode( text );
        }

        const Tag = tag;

        // 문자열을 배열로 처리
        let parts = [text];

        emphasizedTexts.forEach(emphasized => {
            parts = parts.flatMap(part => 
                typeof part === 'string'
                    ? part.split(emphasized).flatMap((subPart, index, array) => (
                        index < array.length - 1
                            ? (Tag 
                                ? [subPart, <Tag className={emphasizedClassName} key={`${emphasized}-${index}`}>{emphasized}</Tag>] 
                                : [subPart, <span className={emphasizedClassName} key={`${emphasized}-${index}`}>{emphasized}</span>])
                            : [subPart]
                    ))
                    : [part]
            );
        });

        return parts;
    };

    return (
        <>
            {emphasizeText(text, emphasizedText, emphasizedClassName, tag)}
        </>
    );
}

export default EmphasizedText;
