


function AppDownloadSection({ data }){

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let link = '';

    if (/android/i.test(userAgent)) {
        link = 'https://play.google.com/store/apps/details?id=kr.co.bookpal.webnovel';
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        link = 'https://apps.apple.com/kr/app/%EB%B6%81%ED%8C%94-%EC%9B%B9%EC%86%8C%EC%84%A4/id898129838';
    } else {
        link = 'https://play.google.com/store/apps/details?id=kr.co.bookpal.webnovel'; // 디폴트 링크
    }

    return (
        <section className="section app_down wrapper banner_big">
            <a href={link} target="_blank">
                <div className="area_box radius-sm" style={{background: data?.bg_color}}>
                    <img src={data?.image} />
                </div>
            </a>
        </section> 
    )
}


export default AppDownloadSection;