import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import App from './App';
import { CommonProvider } from "./context/CommonContext";
import configureStore from './store/configureStore';
//import reportWebVitals from './reportWebVitals';
import CheckForUpdates from './CheckForUpdates';
import TagManager from 'react-gtm-module';


// const GoogleTagManager = ({gtmId}) => {

//   useEffect(()=>{
//     console.log("GoogleTagManager load => ");
//     if(process.env.REACT_APP_GTM){
//       console.log("GoogleTagManager load => tagManagerArgs");
//       const tagManagerArgs = {
//         gtmId: process.env.REACT_APP_GTM
//       };
    
//       console.log("GoogleTagManager load => TagManager.initialize");
//       TagManager.initialize(tagManagerArgs);
//     }
//   },[gtmId])

//   return null;
// };

// const tagManagerArgs = {
//   gtmId: process.env.REACT_APP_GTM
// };

// TagManager.initialize(tagManagerArgs);


const root = ReactDOM.createRoot(document.getElementById('root'));
// const store = configureStore();
const queryClient = new QueryClient()


root.render(
  // <React.StrictMode>
    <Provider store={configureStore}>
      <CommonProvider>
        <QueryClientProvider client={queryClient}>
          <App />
          <CheckForUpdates />
          {/* <GoogleTagManager /> */}
        </QueryClientProvider>
      </CommonProvider>
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
