import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import NewlineToBr from '../content/NewlineToBr';

function useConfirmCustomBuyModal(){


    const [title, setTitle] = useState(false);
    const [bookTitle, setBookTitle] = useState(false);
    const [content, setContent] = useState(false);
    
    const [isPay, setIsPay] = useState(false);
    const [isPayView, setIsPayView] = useState(false);

    const [currentConpon, setCurrentCoupon] = useState(false);
    const [useConpon, setUseCoupon] = useState(false);
    const [isHotdeal, setIsHotdeal] = useState(false);
    const [hotdealAllChapterCnt, setHotdealAllChapterCnt] = useState(false);
    const [hotdealAllCouponCnt, setHotdealAllCouponCnt] = useState(false);
    const [hotdealDiscount, setHotdealDiscount] = useState(false);
    const [isVoucher, setIsVoucher] = useState(false);
    const [useVoucher, setUseVoucher] = useState(false);
    const [voucherOwnCnt, setVoucherOwnCnt] = useState(false);
    const [successBtnTitle, setSuccessBtnTitle] = useState("확인");
    const [success2BtnTitle, setSuccess2BtnTitle] = useState("확인");
    const [cancleBtnTitle, setCancleBtnTitle] = useState("취소");
    const [action, setAction] = useState({});
    const [showModal, setShowModal] = useState(false);

    let hotdealContent = "- 기존 구매 회차는 쿠폰이 소모되지 않습니다.";
    hotdealContent += "\n- 할인은 일괄 구매 시에만 적용되며 자동 소장됩니다.";
    hotdealContent += "\n- 쿠폰으로만 구매가 가능합니다. (선물권 사용 불가)";
    hotdealContent += "\n- 핫딜 구매 후에는 환불이 불가합니다.";


    const handleClose = () => {
        setShowModal(false);
    }
    const handleShow = (data) => {

        setTitle(data?.title || false);
        setBookTitle(data?.bookTitle || false);
        setContent(data?.content || false);
        setIsPay(data?.isPay || false);
        setIsPayView((data?.isPayView === false) ? false : true);
        setCurrentCoupon(data?.currentConpon || false);
        setUseCoupon(data?.useConpon || false);

        setIsHotdeal(data?.isHotdeal || false);
        setHotdealAllChapterCnt(data?.hotdealAllChapterCnt || false);
        setHotdealAllCouponCnt(data?.hotdealAllCouponCnt || false);
        setHotdealDiscount(data?.hotdealDiscount || false);


        setIsVoucher(data?.isVoucher || false);
        setUseVoucher(false);
        setVoucherOwnCnt(data?.voucherOwnCnt ?? 1);
        setSuccessBtnTitle(data?.successBtnTitle || "");
        setSuccess2BtnTitle(data?.success2BtnTitle || "");
        setCancleBtnTitle(data?.cancleBtnTitle || "");
        setAction({
            ...action,
            cancleAction:data?.cancleAction,
            successAction:data?.successAction,
            success2Action:data?.success2Action,
        })
        
        setShowModal(true);

        return useVoucher;
    };

    const handleCancle = () => {
        if(action?.cancleAction){
            action?.cancleAction();
        }
        setShowModal(false);
        setAction({
            ...action,
            cancleAction:null,
            successAction:null,
        });
    }

    const handleSelect = () => {
        
        handleClose();
        setAction({
            ...action,
            cancleAction:null,
            successAction:null,
        });
        if(action?.successAction){
            // alert(useVoucher)
            action?.successAction(useVoucher);
        }
    }

    const handle2Select = () => {
        handleClose();
        setAction({
            ...action,
            cancleAction:null,
            successAction:null,
        });
        if(action?.success2Action){
            action?.success2Action();
        }
    }


    const ModalTmpl =()=>{ 
        return (
        <>
            <Modal 
                // className="black_opacity-60" 
                backdropClassName="black_opacity-60 opacity-10"
                dialogClassName="modal-dialog-centered"
                contentClassName="radius-xxl color-bg-modal"
                show={showModal}
                animation={false} 
                onHide={handleClose}
                >
                <Modal.Body>
                    {title ? <div className="title title-02-b color-txt-normal">{title}</div> : null}
                    <div className="desc color-txt-02">
                        {bookTitle ? 
                        <p className="title-book-03-m">
                            <span className="text_overflow">{bookTitle}</span>
                        </p>
                        :null}
                        {content ? <div className="body-03-long"><NewlineToBr text={content} /></div> : null}
                    </div>
                    {!isPay ? 
                    <div className="txt_box title-01-m radius-sm color-bg-select-01">
                        <p className="color-txt-01">
                            <span className="color-txt-04">잔여쿠폰</span> {currentConpon ? currentConpon : 0}개 ·
                            <span className="color-txt-04">사용쿠폰</span> {useConpon ? useConpon : 0}개
                        </p>
                        <p className="color-btn-01 txt">쿠폰이 부족합니다.</p>
                    </div>
                    :null}
                    {isHotdeal ? 
                    <>
                    <div className="txt_box title-01-m radius-sm color-bg-select-01">
                        <p className="color-txt-01">
                            <span className="color-txt-04">구매회차</span> {hotdealAllChapterCnt}개
                            {/* <span className="color-txt-04">사용쿠폰</span> {hotdealAllCouponCnt}개 */}
                        </p>
                        {/* <p className="color-btn-01 txt">쿠폰이 부족합니다.</p> */}
                        <p className="color-btn-01 txt">쿠폰 {hotdealAllCouponCnt}개 ({hotdealDiscount}%할인)</p>
                    </div>
                    <div className="txt color-txt-04 caption-02-long"><NewlineToBr text={hotdealContent} /></div>
                    </>
                    :null}
                    
                    {isVoucher ? 
                    <div>
                        <label
                            htmlFor="agree_iiii"
                            className="checkbox_round check-type-01 small label_on"
                            onClick={(e)=>{
                                console.log(e.currentTarget.checked)
                            }}
                        >
                            <input 
                                type="checkbox" 
                                id="agree_iiii" 
                                checked={useVoucher}
                                onChange={(e)=>{
                                    setUseVoucher(e.currentTarget.checked);
                                }}
                                
                                />
                            <span className="box"></span>
                            <span className="txt color-txt-04">선물소장권 사용</span>
                        </label>
                    </div>
                    :null}
                    
                    {/* <div className="select_box">
                    {optionElements}
                    </div> */}
                </Modal.Body>
                <Modal.Footer>
                    {successBtnTitle != "" ?
                    <button 
                        type="button" 
                        className="radius-sm btn-04 txt btn_01 large label_on filled button" 
                        onClick={handleSelect}
                        >{successBtnTitle}{isPayView && isPay ? (useVoucher?" (선물소장권 "+(voucherOwnCnt ?? 1)+"개 사용)":" (쿠폰 "+useConpon+"개 사용)"):""}</button>
                    :null}
                    {success2BtnTitle != "" ?
                    <button
                        type="button"
                        className="radius-sm btn-04 txt btn_01 large label_on outlined button"
                        onClick={handle2Select}
                        >
                        {success2BtnTitle}
                    </button>
                    :null}
                    {cancleBtnTitle != "" ?
                    <button type="button" className="radius-xs btn-03 txt btn_03 medium label_on filled button" onClick={handleCancle}>{cancleBtnTitle}</button> 
                    :null}
                </Modal.Footer>
            </Modal>
        </>
    )};
    
    return [ModalTmpl, handleShow];
}


export default useConfirmCustomBuyModal;