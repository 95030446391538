import { useLocationName } from 'utils/Common';

import NavBtn from 'component/common/button/nav/NavBtn';

function PcNav(){

    const page = useLocationName();


    return (
        <ul className="gnb">
            <NavBtn mode={"pc"} active={page=="main"?true:false} className="home" to="/" page={page}>추천</NavBtn>
            <NavBtn mode={"pc"} active={page=="novel"?true:false} className="novel" to="/novel" page={page}>웹소설</NavBtn>
            <NavBtn mode={"pc"} active={page=="toon"?true:false} className="toon" to="/toon" page={page}>웹툰</NavBtn>
            <NavBtn mode={"pc"} active={page=="giftbox"?true:false} className="gift" to="/giftbox" page={page}>선물함</NavBtn>
            <NavBtn mode={"pc"} active={page=="storage"?true:false} className="storage" to="/storage/recent" page={page}>보관함</NavBtn>
        </ul>
    )
}



export default PcNav;
