import { useApiMutation, useApiMutationCommentReplyPaging, useApiMutationPaging } from './common/ApiCommon';

export const ApiBuyChapterPay = (options) => {

    const subUrl = "/buy/chapter_pay";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};


export const ApiBuyBook = (options) => {

    const subUrl = "/buy/buy_book";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiBuyBuyCheck = (options) => {

    const subUrl = "/buy/buy_check";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiBuyChapterPayMulti = (options) => {

    const subUrl = "/buy/chapter_pay_multi";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiBuyBuyBookMulti = (options) => {

    const subUrl = "/buy/buy_book_multi";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiBuyPayChapterList = (options) => {
    const subUrl = "/buy/pay_chapter_list";
    const data = options?.data || {};
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});
    const bottomFn = options?.bottomFn || (()=>{});
    const bottomRef = options?.bottomRef || (()=>{});
    const perPage = options?.perPage || 50;
    const pageName = options?.pageName || "page";
    const perPageName = options?.perPageName || "per_page";

    return useApiMutationPaging(subUrl, data, successFn, errorFn, bottomFn, perPage, pageName, perPageName, bottomRef);
};