import { useEffect, useState } from 'react';
import HeaderInner from './header_inner/HeaderInner'
import { useIsMobile } from 'utils/Common';

function Header(props){

    const isMobile = useIsMobile();

    const useMobile = (props?.useMobile === false) ? props?.useMobile : true;
    const usePc = (props?.usePc === false) ? props?.usePc : true;

    const title = props?.title ?? null;

    const display = isMobile ? useMobile : usePc;

    const pcTop = props?.pcTop || "0px";
    const mobileTop = props?.mobileTop || "0px";

    const [top, setTop] = useState("0px");
    

    useEffect(()=>{
        if(isMobile){
            setTop(mobileTop)
        } else {
            setTop(pcTop)
        }
        
    },[isMobile]);

    return (
        <header id="header" style={{display: (display ? "block" : "none"), top:top}}>
            <HeaderInner title={title} />
        </header>
    )
}


export default Header;
