import { useNavigate } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import { ApiMypocketDeviceList, ApiMypocketDeviceDel } from 'api/Mypocket';
import useAlertModal from 'component/common/modal/useAlertModal';
import useConfirmCustomModal from 'component/common/modal/useConfirmCustomModal';
import { SetState } from 'utils/State';


import Header from 'component/common/header/Header';
import Footer from 'component/common/footer/Footer';

import SubNav from "component/common/nav/SubNav";

import style from './Setting.module.scss';


import img_novel from 'assets/images/novel/thumbnail_bg2.png';
import img_thumbnail_illust2 from 'assets/images/novel/thumbnail_illust2.png';
import { useEffect, useState } from 'react';

function SettingDevice(){


    const navigate = useBookpalNavigate();
    const setState = SetState();

    const [data, setData] = useState(null);
    const [deleteCnt, setDeleteCnt] = useState(null);
    
    const [AlertModal, alert] = useAlertModal();
    const [ConfirmModal, confirm] = useConfirmCustomModal();

    const handlerDeviceList = ApiMypocketDeviceList({
        onSuccess:(data)=>{
            if(data?.status === true){
                setData(data?.result?.device);
                setDeleteCnt(data?.result?.delete_cnt);
                return;
            }
        },
        onError:(e)=>{

        }
    });

    const handlerDeviceDelSubmit = ApiMypocketDeviceDel({
        onSuccess:(data)=>{
            switch(data?.code){
                case "207":
                    setState.setToast({
                        title:"기기 삭제가능 횟수 초과 [월2회]",
                    })
                    return;
                case "409":
                    setState.setToast({
                        title:"기기 삭제에 실패하였습니다.\n다시 시도해 주세요.",
                    })
                    return;
                case "204":
                    setState.setToast({
                        title:"해제할 기기가 없습니다.",
                    })
                    return;
            }
            if(data?.status === true){
                // handlerDeviceList();
                setData(data?.result?.device);
                setDeleteCnt(data?.result?.delete_cnt);
                setState.setToast({
                    title:"삭제 되었습니다."
                })
            }
        }
    })

    const handlerDeviceDel = (e)=>{
        const device_id = e.currentTarget.getAttribute("data-device-id");

        confirm({
            title:"기기 해제",
            content:"선택한 기기를 해제하시겠습니까?",
            successAction:()=>{
                // alert(device_id);
                handlerDeviceDelSubmit({
                    device:device_id
                })
            }
        })
    }


    useEffect(()=>{
        handlerDeviceList();
    },[]);
    
    if(data === null){
        return <></>
    }

    console.log(data);

    return (
        <>
            <Header useMobile={false} />
            <SubNav 
                name={"기기 등록 관리"}
                usePc={true} 
                useMobile={true} 
                usePcRightBtns={true}
                useBtnPrev={true}
                useBtnPcPrev={true}
                useBtnHome={true}

                setTop={'0px'} 
                setPcTop={'64px'} 
                />


            
            {/* <!-- container --> */}
            <div id="container" className={style['containerr']}>
                <section className={"device_box wrapper " + style['device_box']}>
                    <div className={"section " + style['section']}>
                        {data && Array.isArray(data) && data?.length > 0 ?
                            <div className={"top_con " + style['top_con']}>
                                <p className={"left btn-03 color-btn-03-txt " + style['left']}>이번 달 해제 가능 횟수 : {deleteCnt ?? 0}회</p>
                            </div>
                        :null}
                        <ul className={"my_device " + style['my_device']}>

                            {data && Array.isArray(data) && data?.length === 0 ? 
                                <p className="no_data large color-txt-04 body-02-long">등록하신 내역이 없습니다.</p>
                            :null}
                            {data && Array.isArray(data) && data?.length > 0 ? 
                                data.map((item, index)=>{

                                    return (
                                        <li key={index} className="color-bg-02 radius-sm">
                                            <div className={"left " + style['left']}>
                                                <p className="btn-05 color-txt-01">{item?.model_name}</p>
                                                <p className="date caption-02 color-txt-muted">{item?.reg_date}</p>
                                            </div>
                                            <div className={"right " + style['right']}>
                                                <button 
                                                    type="button" 
                                                    className="button btn_02 filled radius-full label_on small"
                                                    data-device-id={item?.device_id}
                                                    onClick={handlerDeviceDel}
                                                    ><span className="btn-02 color-btn-02-txt">해제</span></button>
                                            </div>
                                        </li>
                                    )
                                })
                            :null}

                            {/* <li className="color-bg-02 radius-sm">
                                <div className={"left " + style['left']}>
                                    <p className="btn-05 color-txt-01">Android 1</p>
                                    <p className="date caption-02 color-txt-muted">2024.02.22</p>
                                </div>
                                <div className={"right " + style['right']}>
                                    <button type="button" className="button btn_02 filled radius-full label_on small"><span className="btn-02 color-btn-02-txt">해제</span></button>
                                </div>
                            </li>
                            <li className="color-bg-02 radius-sm">
                                <div className={"left " + style['left']}>
                                    <p className="btn-05 color-txt-01">iPad 1</p>
                                    <p className="date caption-02 color-txt-muted">2024.02.22</p>
                                </div>
                                <div className="right">
                                    <button type="button" className="button btn_02 filled radius-full label_on small"><span className="btn-02 color-btn-02-txt">해제</span></button>
                                </div>
                            </li>
                            <li className="color-bg-02 radius-sm">
                                <div className={"left " + style['left']}>
                                    <p className="btn-05 color-txt-01">iPhone 1</p>
                                    <p className="date caption-02 color-txt-muted">2024.02.22</p>
                                </div>
                                <div className="right">
                                    <button type="button" className="button btn_02 filled radius-full label_on small"><span className="btn-02 color-btn-02-txt">해제</span></button>
                                </div>
                            </li>
                            <li className="color-bg-02 radius-sm">
                                <div className={"left " + style['left']}>
                                    <p className="btn-05 color-txt-01">iPhone 2</p>
                                    <p className="date caption-02 color-txt-muted">2024.02.22</p>
                                </div>
                                <div className="right">
                                    <button type="button" className="button btn_02 filled radius-full label_on small"><span className="btn-02 color-btn-02-txt">해제</span></button>
                                </div>
                            </li> */}
                        </ul>
                        <div className={"add_btn " + style['add_btn']}>
                            <button 
                                type="button" 
                                className="button btn_03 outlined medium label_on radius-full round"
                                onClick={()=>{
                                    alert("안내","기기 등록은 APP에서만 가능합니다.");
                                }}
                                ><span className="btn-04 color-txt-04">현재 기기 추가하기 </span></button>
                        </div>
                    </div>
                    <div className="info_txt_box section">
                        <p className="title title-02-b color-txt-04">이용안내</p>
                        <ul className="desc_list body-02-long color-txt-05">
                            <li>회차 다운로드를 위해 기기 등록이 필요합니다.</li>
                            <li>ID 당 최대 5개의 기기만 등록이 가능합니다.</li>
                            <li>기기 해제는 월 2회만 가능합니다. (매월 1일 기준)</li>
                        </ul>
                    </div>
                </section>
            </div>
            {/* <!-- container --> */}

            <Footer />
            <AlertModal />
            <ConfirmModal />
        </>
    );
}




export default SettingDevice;