import CryptoJS from 'crypto-js';



export function encrypt(data){
    const secretKey = process.env.REACT_APP_CRYPTO_KEY;
    const ciphertext = CryptoJS.AES.encrypt(data, secretKey).toString();
    return ciphertext;
}


export function decrypt(encrypt_data){
    const secretKey = process.env.REACT_APP_CRYPTO_KEY;
    const bytes = CryptoJS.AES.decrypt(encrypt_data, secretKey);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
}


export function contentEncrypt(data){
    const secretKey = process.env.REACT_APP_CRYPTO_CONTENT_KEY;
    const ciphertext = CryptoJS.AES.encrypt(data, secretKey).toString();
    return ciphertext;
}


export function contentDecrypt(encrypt_data){
    const secretKey = process.env.REACT_APP_CRYPTO_CONTENT_KEY;
    const bytes = CryptoJS.AES.decrypt(encrypt_data, secretKey);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
}

export const decryptOld = (data) => {
    const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTO_CONTENT_KEY); // PHP의 __CRYPT_KEY__ 값과 동일해야 합니다.
    const iv = CryptoJS.enc.Utf8.parse('\x00'.repeat(16)); // PHP의 str_repeat(chr(0), 16)와 동일합니다.
  
    const decrypted = CryptoJS.AES.decrypt(
      {
        ciphertext: CryptoJS.enc.Base64.parse(data)
      },
      key,
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }
    );
  
    return decrypted.toString(CryptoJS.enc.Utf8);
  };