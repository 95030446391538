import { useApiMutation, useApiMutationPaging } from './common/ApiCommon';


export const ApiSearchFavKeyword = (options) => {

    const subUrl = "/search/fav_keyword";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiSearchWork = (options) => {
    const subUrl = "/search/work";
    const data = options?.data || {};
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});
    const bottomFn = options?.bottomFn || (()=>{});
    const bottomRef = options?.bottomRef || (()=>{});
    const perPage = options?.perPage || 30;
    const pageName = options?.pageName || "page";
    const perPageName = options?.perPageName || "per_page";

    return useApiMutationPaging(subUrl, data, successFn, errorFn, bottomFn, perPage, pageName, perPageName, bottomRef);
};