import { ApiMyBookmarkSet, ApiMyBookmarkBell } from 'api/My';

import style from '../../style/Contents.module.scss';
import { useState } from 'react';
import { SetState } from 'utils/State';



function FavBtn({type, data}){


    const [fav, setFav] = useState(data?.like_status);
    const [bell, setBell] = useState(data?.push_status);

    const setState = SetState();

    const bookmarkSetSubmit = ApiMyBookmarkSet({
        onSuccess:(data)=>{
            if(data?.status === true){
                if(data?.result?.bookmark?.is_my_bookmark ===true){
                    setFav(true);
                    setBell(true);
                    setState.setToast({
                        title:"관심 작품 등록되었습니다.\n작품 업데이트 알림을 보내드릴게요.",
                    });
                }
                if(data?.result?.bookmark?.is_my_bookmark ===false){
                    setFav(false);
                    setBell(false);
                    setState.setToast({
                        title:"관심 작품 해제하였습니다.\n작품 알림을 받으실 수 없습니다.",
                    });
                }
            }
        },
        onError:(e)=>{

        }
    });

    const handlerBookmarkSetSubmit = (bid)=>{
        bookmarkSetSubmit({
            bid:data?.bid,
            epub:(type === "epub"),
        });
    }

    const bookmarkBellSubmit = ApiMyBookmarkBell({
        onSuccess:(data)=>{
            if(data?.status === true){
                if(data?.result?.is_bell ===true){
                    setBell(true);
                    setState.setToast({
                        title:"해당 작품 업데이트 알림이\n설정되었습니다. ",
                    });
                }
                if(data?.result?.is_bell ===false){
                    setBell(false);
                    setState.setToast({
                        title:"해당 작품 업데이트 알림이\n해제되었습니다.",
                    });
                }
            }
        },
        onError:(e)=>{

        }
    });

    const handlerBookmakrBellSubmit = ()=>{
        bookmarkBellSubmit({
            bid:data?.bid,
            bell:!bell,
            epub:(type === "epub"),
        })
    }

    return (
        <div className={"click_box " + style['click_box']}>
            <p className={"tit title-01-m-long color-txt-normal " + style['tit']}>
                관심 등록으로 작가 응원하기
            </p>
            <p className={"icon_box " + style['icon_box']}>
                {/* <!-- 클릭시 click 추가 --> */}
                <button 
                    type="button" 
                    className={"button favorite_btn click " + style['button'] + " " + style['favorite_btn'] + " " + (fav ? style['click'] : "")}
                    onClick={()=>{
                        handlerBookmarkSetSubmit();
                    }}
                    ></button>
                {fav ? 
                <button 
                    type="button" 
                    className={"button alarm_btn " + style['button'] + " " + style['alarm_btn'] + " " + (bell ? style['click'] : "")}
                    onClick={()=>{
                        handlerBookmakrBellSubmit();
                    }}
                    ></button>
                :null}
            </p>
        </div>
    )
}



export default FavBtn;