import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import SelectDateTimePicker from '../date/SelectDateTimePicker';

function useSelectArrDateModal(data, selectData, selectFn, _errorFn, noneSelect, title, info){

    const options = data || [];
    const noneActive = noneSelect || false;
    const errorFn = _errorFn || (()=>{});

    const [selectKey, setSelectKey] = useState(selectData);

    const [selectTmpKey, setSelectTmpKey] = useState(selectData);

    const [showModal, setShowModal] = useState(false);

    const [returnStartDate, setReturnStartDate] = useState(null);
    const [returnEndDate, setReturnEndDate] = useState(null);

    const [startDate, setStartDate] = useState( new Date( (new Date).setFullYear(new Date().getFullYear() - 1 ) + 60*60*24*1000 ) );
    const [endDate, setEndDate] = useState((new Date));

    const [startTmpDate, setStartTmpDate] = useState( new Date( (new Date).setFullYear(new Date().getFullYear() - 1 ) + 60*60*24*1000 ) );
    const [endTmpDate, setEndTmpDate] = useState((new Date));

    const handleClose = () => {
        setShowModal(false);
        setSelectTmpKey(selectKey);

        setStartTmpDate(startDate);
        setEndTmpDate(endDate);
    }
    const handleShow = () => setShowModal(true);

    const keyData = selectKey;

    const handleSelect = (key) => {
        setSelectTmpKey(key);
    }

    const handleConfirm = ()=>{
        console.log(startTmpDate)
        console.log(endTmpDate)
        if(!noneActive) setSelectKey(selectTmpKey);
        if(selectFn) selectFn(selectTmpKey);
        setStartDate(startTmpDate);
        setEndDate(endTmpDate);
        setShowModal(false);
    }

    // const initSelect = ()=>{
    //     setSelectKey(selectData);
    // }

    const getValue = ()=>{
        return options.map((item,index)=>{
            if(item?.key === selectKey){
                return item?.value;
            }
        });
    }

    const optionElements = [];
    options.map((item,index)=>{
        optionElements.push(
            <p 
                key={index} 
                data-key={item?.key}
                className={"radius-sm color-bg-modal color-txt-normal body-02 " + (item?.key == selectTmpKey ? "click":"")}
                style={{padding:"0 16px"}}
                onClick={(obj)=>{
                    // e.preventDefault()
                    console.log(obj);
                    const targetkey = obj.target.attributes['data-key'].value;
                    console.log(targetkey);
                    handleSelect(targetkey);
                }}
            >
                {item?.value}
            </p>
        );
    });

    const returnDateSetting = () => {
        if(startDate && startDate?.getFullYear){
            const s_year = startDate.getFullYear().toString().slice(-2);
            const s_month = String(startDate.getMonth() + 1).padStart(2, '0');
            const s_day = String(startDate.getDate()).padStart(2, '0');
            const s_formattedDate = `${s_year}.${s_month}.${s_day}`;
            setReturnStartDate(s_formattedDate);
        }

        if(endDate && endDate?.getFullYear){
            const e_year = endDate.getFullYear().toString().slice(-2);
            const e_month = String(endDate.getMonth() + 1).padStart(2, '0');
            const e_day = String(endDate.getDate()).padStart(2, '0');
            const e_formattedDate = `${e_year}.${e_month}.${e_day}`;
            setReturnEndDate(e_formattedDate);
        }
    }

    const handlerSetStartTmpDate = (val)=>{
        if(endTmpDate < val){
            errorFn("시작 날짜는 마지막날짜 이전 이여야 합니다.");
            return;
        }
        setStartTmpDate(val);
    }

    const handlerSetEndTmpDate = (val)=>{
        if(startTmpDate > val){
            errorFn("마지막 날짜는 시작날짜 이후 여야 합니다.");
            return;
        }
        setEndTmpDate(val);
    }

    useEffect(()=>{
        returnDateSetting();
    },[]);

    useEffect(()=>{
        returnDateSetting();
    },[startDate, endDate]);

    const ModalTmpl =()=>{ 
        return (
        <>
            <Modal 
                // className="select_modal modal black_opacity-60" 
                className="select_modal modal" 
                backdropClassName="black_opacity-60 opacity-10"
                dialogClassName="modal-dialog-centered"
                contentClassName="radius-xxl color-bg-modal"
                show={showModal}
                animation={false} 
                onHide={handleClose}
                >
                <Modal.Body>
                    {title ? <div className="title title-02-b color-txt-normal">{title}</div>:null}
                    <div className="scroll_box select_box">
                    {optionElements}
                    </div>
                    
                    <div className="input_box" style={{display:(selectTmpKey === "etc" ? "block":"none")}}>
                        {/* <!-- 잘못되었을때 error_text 추가 --> */}
                        <div className="data_box">
                            <div className="button_box">
                                <SelectDateTimePicker 
                                    className="text_input select_box button color-bg-01 radius-sm color-border-02"
                                    customType="select"
                                    date={startTmpDate}
                                    setDateValue={handlerSetStartTmpDate}
                                    // setDate={setReturnStartDate}
                                    />
                                {/* <button type="button" className="text_input select_box button color-bg-01 radius-sm color-border-02">
                                    <span className="body-02 color-txt-normal">24.03.23</span>
                                </button> */}
                                <span className="tilde color-txt-05 body-03">~</span>
                                <SelectDateTimePicker 
                                    className="text_input select_box button color-bg-01 radius-sm color-border-02"
                                    customType="select"
                                    date={endTmpDate}
                                    setDateValue={handlerSetEndTmpDate}
                                    // setDate={setReturnEndDate}
                                    />
                                {/* <button type="button" className="text_input select_box button color-bg-01 radius-sm color-border-02">
                                    <span className="body-02 color-txt-normal">24.04.23</span>
                                </button> */}
                            </div>
                            <p className="bottom_txt caption-02 color-state-danger txt">시작일을 종료일보다 이전으로 선택하세요.</p>
                        </div>
                    </div>
                    
                    {info ? <div className="desc color-txt-muted body-02-long">*{info}</div>:null}
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="radius-sm btn-04 txt btn_01 large label_on filled button" onClick={handleConfirm}>확인</button>
                    <button type="button" className="radius-xs btn-03 txt btn_03 medium label_on filled button" onClick={handleClose}>취소</button> 
                </Modal.Footer>
            </Modal>
        </>
    )};
    
    return [ModalTmpl, handleShow, selectKey, setSelectKey, getValue, returnStartDate, returnEndDate];
}


export default useSelectArrDateModal;