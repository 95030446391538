import { useState } from 'react';
import { ImgBadge } from 'utils/Images';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import BookpalLink from 'component/common/custom/BookpalLink';
import { ApiBuyBuyCheck, ApiBuyBook } from 'api/Buy';
import { runPopupOrder, getFormatBytes, formatDateString } from 'utils/Common';
import style from '../../style/Contents.module.scss';
import { SetState } from 'utils/State';

import img_badge_free from 'assets/images/contents/badge_free-LightMode.svg';

function Epub({bid, data, alert, buyConfirm}){

    const navigate = useBookpalNavigate();
    const location = useLocation();
    const setState = SetState();

    const [buyCheck, setBuyCheck] = useState(null);

    const buySubmit = ApiBuyBook({
        onSuccess:(_data)=>{
            if(_data?.status === true){
                getViewPageLink();
                setState.setToast({
                    title:_data?.result?.msg,
                })
                return;
            }
            switch(_data?.code){
                case "520":
                    buyConfirm({
                        title:"구매 안내",
                        bookTitle:buyCheck?.buy_info?.title,
                        content:buyCheck?.buy_info?.no + "권을 열람하시겠습니까?",
                        isPay:false,
                        currentConpon:_data?.result?.my_coupon_cnt+"",
                        useConpon:buyCheck?.buy_info?.price,
                        useVoucher:false,
                        successBtnTitle:"쿠폰 충전하러 가기",
                        cancleBtnTitle:"다음에 할게요",
                        successAction:()=>{
                            runPopupOrder();
                        },
                    });
                    break;
                case "513":
                    alert("구매 안내","무료 회차입니다.");
                    break;
                case "512":
                    alert("구매 안내","구매 가능한 회차가 없습니다.");
                    break;
                case "511":
                    alert("구매 안내","이미 구매한 회차입니다.");
                    break;
                default:
                    alert("구매 안내",_data?.message);
                    break;
            }
        },
        onError:(e)=>{

        }
    })

    const checkSubmit = ApiBuyBuyCheck({
        onSuccess:(_data)=>{
            if(_data?.status === true){
                const result = _data?.result;
                const buy_info = result?.buy_info;
                setBuyCheck(result);

                if(buy_info?.is_buy === true){
                    getViewPageLink();
                    return;
                }

                if(buy_info?.is_event_chapter_free === true){
                    getViewPageLink();
                    return;
                }

                if(buy_info?.is_free === true){
                    getViewPageLink();
                    return;
                }

                // const my_voucher = result?.my_voucher;
                // const isPay = result?.my_coupon_cnt > 0;
                const useVoucher = buyConfirm({
                    title:"구매 안내",
                    bookTitle:buy_info?.title,
                    content:buy_info?.no + "권을 열람하시겠습니까?",
                    isPay:true,
                    currentConpon:buy_info?.user_coupon,
                    useConpon:buy_info?.price,
                    useVoucher:false,
                    successBtnTitle:(true?"소장하기":"확인"),
                    success2BtnTitle:"",
                    successAction:(useVoucher)=>{
                        console.log("useVoucher",useVoucher);
                        buySubmit({
                            bid:bid,
                            cid:data?.epid,
                            epub:true,
                            buy_type:"buy",
                        });
                    },
                    success2Action:()=>{
                        // buySubmit({
                        //     bid:data?.bid,
                        //     cid:data?.cid,
                        //     epub:data?.epub,
                        //     buy_type:"v_rent",
                        // });
                    },
                });
                return;
            }
            switch(_data?.code){
                case "400":
                    alert("알림","책정보가 없습니다.");
                    break;
                case "406":
                    alert("알림","블라인드 작품입니다.");
                    break;
                case "403":
                    alert("알림","작품 감상을 위해 로그인이 필요합니다.",()=>{
                        navigate("/login",{state:{return_path:location.pathname}});
                    });
                    break;
                default:
                    alert("알림","시스템에 문제가 발생하였습니다. 문제가 지속되면 관리자에게 문의주세요.");
                    break;
            }
        },
        onError:(e)=>{

        },
    });

    const handlerCheckPay = (is_paid)=>{
        if(is_paid === "true"){
            getViewPageLink();
            return;
        }

        checkSubmit({
            bid:bid,
            cid:data?.epid,
            epub:true,
        });
    }


    const getViewPageLink = ()=>{
        navigate("/view/"+data?.ct_type+"/"+data?.epid+"/viewer");
    }

    return (
        <li className={"" + (data?.is_my_view ? style['read']:"") + " " + (data?.is_my_last_view ? style['bookmark']:"")}>
            <BookpalLink 
                // to={"/view/"+data?.ct_type+"/"+data?.epid}
                data-is_paid={data?.is_paid ? "true":"false"}
                // data-is_payable={data?.is_payable ? "true":"false"}
                onClick={(e)=>{
                    e.preventDefault()
                    const is_paid = e.currentTarget.getAttribute("data-is_paid");
                    // const is_payable = e.currentTarget.getAttribute("data-is_payable");
                    // handlerCheckPay(is_paid, is_payable);
                    handlerCheckPay(is_paid);
                }}
                >
                <div className={"left " + style['left']}>
                    {data?.is_extra ? <p className="color-txt-05 title-tiny-01">외전</p> : null}
                    <p className="color-txt-normal btn-03-active">{data?.is_extra ? data?.no_extra : data?.no}권</p>
                    <p className="badges">
                        {data?.is_new ? <span className="ic_up sm radius-xs icon_badge"><img src={ImgBadge("up")} /></span> : null}
                    </p>
                </div>
                <div className={"right info_txt " + style['right'] + " " + style['info_txt']}>
                    <div className={"list_tit_con " + style['list_tit_con']}>
                        {data?.is_adult ? <span className="ic_adult sm icon_badge"><img src="/images/ic_adult.svg" /></span> : null}
                        <span className="title color-txt-01 title-book-01-m text_overflow">{data?.title}</span>
                    </div>
                    <p className={"info_vertical info " + style['info_vertical'] + " " + style['info']}>
                        <span className="color-txt-04 caption-02">{formatDateString(data?.marketup_date)}</span>
                        <span className="color-txt-04 caption-02">{getFormatBytes(data?.size)}</span>
                    </p>
                    <p className={"other_info " + style['other_info']}>
                        {!data?.is_paid && !data?.is_rent && 
                        (data?.is_payable === false
                        || data?.is_event_chapter_free === true) ? 
                        <span className="free_badge free icon_badge sm">
                            {!data?.is_event_chapter_free && data?.is_payable === false ? <img src={img_badge_free} /> : null}
                            {/* <!-- 라이트모드 --> */}
                            
                            {/* <!-- 다크모드 -->
                            <!-- <img src="/contents/images/badge_free-DarkMode.svg"> --> */}
                            {data?.is_event_chapter_free === true ? <img src={ImgBadge("chapterfree")} /> : null}
                            {/* {data?.is_eventChapterFree === true ? <img src={ImgBadge("chapterfree")} /> : null} */}
                            
                        </span>
                        :null}
                        {!data?.is_paid && data?.voucher_rent_expire_ok === true ?
                            <span className="color-state-danger txt caption-02">{data?.voucher_rent_expire_txt}</span>
                        :null}
                        {!data?.is_paid && data?.voucher_use_limit_no !== false ?
                            <span className="color-txt-05 caption-02">선물이용권 이용 제한</span>
                        :null}
                        {data?.is_paid || data?.is_rent ? 
                        <>
                            {data?.is_rent ? 
                            <span className="color-state-danger txt body-02">대여 - {data?.rent_end_rest_time}</span>
                            :<span className="color-state-success txt body-02">소장</span>}
                        </>
                        :null}
                    </p>
                </div>
            </BookpalLink>
        </li>
    )
}




export default Epub;