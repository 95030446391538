import { useState } from 'react';
import { ImgBadge } from 'utils/Images';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import BookpalLink from 'component/common/custom/BookpalLink';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import { ApiBuyChapterPay, ApiBuyBook } from 'api/Buy';
import { runPopupOrder } from 'utils/Common';
import { SetState, GetState } from 'utils/State';


import style from '../../style/Contents.module.scss';


function HeaderToonEpisodeList({data, bid, cid, type, alert, buyConfirm}){

    const navigate = useBookpalNavigate();
    const location = useLocation();

    const setState = SetState();
    const getState = GetState();

    // const viewerRun = getState.useGetViewerInfoRun();

    // const toonData = (prevOrNext === "prev" ? data?.prev_chapter : data?.next_chapter);

    const [buyCheck, setBuyCheck] = useState(null);
    // const [buyType, setBuyType] = useState(null);

    const buySubmit = ApiBuyBook({
        onSuccess:(_data)=>{
            if(_data?.status === true){
                getViewPageLink();
                //buyType => toast필요
                setState.setToast({
                    title:_data?.result?.msg,
                })
                return;
            }

            switch(_data?.code){
                case "514":
                    getViewPageLink();
                    setState.setToast({
                        title:_data?.result?.msg,
                    });
                    break;
                case "400":
                    buyConfirm({
                        title:"구매 안내",
                        bookTitle:buyCheck?.book_title,
                        content:buyCheck?.chapter_no + "화를 열람하시겠습니까?",
                        isPay:false,
                        currentConpon:_data?.result?.my_coupon_cnt,
                        useConpon:buyCheck?.toon_price_buy,
                        useVoucher:false,
                        successBtnTitle:"쿠폰 충전하러 가기",
                        cancleBtnTitle:"다음에 할게요",
                        successAction:()=>{
                            runPopupOrder();
                        },
                    });
                    break;
                case "513":
                    alert("구매 안내","무료 회차입니다.");
                    break;
                case "512":
                    alert("구매 안내","구매 가능한 회차가 없습니다.");
                    break;
                case "511":
                    alert("구매 안내","이미 구매한 회차입니다.");
                    break;
                default:
                    alert("구매 안내",_data?.message);
                    break;
            }
        },
        onError:(e)=>{

        }
    })

    const checkSubmit = ApiBuyChapterPay({
        onSuccess:(_data)=>{
            if(_data?.status === true){
                const result = _data?.result;
                setBuyCheck(result);
                const my_voucher = result?.my_voucher;

                if(result?.is_payable === false){
                    getViewPageLink();
                    return;
                }

                if(result?.is_paid === true){
                    getViewPageLink();
                    return;
                }

                if(result?.is_event_chapter_free === true){
                    // getViewPageLink();
                    buySubmit({
                        bid:bid,
                        cid:data?.cid,
                        epub:null,
                        buy_type:null,
                    });
                    return;
                }

                if(result?.is_rent === true){
                    getViewPageLink();
                    return;
                }

                if(result?.is_writer_book === true){
                    getViewPageLink();
                    return;
                }

                
                const isBuyPay = result?.my_coupon_cnt >= result?.toon_price_buy;
                let isRentPay = (result?.my_coupon_cnt >= result?.toon_price_rent || my_voucher?.rent_cnt > 0);
                if(result?.toon_price_buy == result?.toon_price_rent){
                    isRentPay = false;
                }
                const useVoucher = buyConfirm({
                    title:"구매 안내",
                    bookTitle:result?.book_title,
                    content:result?.chapter_no + "화를 열람하시겠습니까?",
                    isPay:true,
                    currentConpon:result?.my_coupon_cnt,
                    useConpon:result?.toon_price_buy,
                    isVoucher:(result?.voucher_ok && my_voucher?.voucher_own_ok),
                    successBtnTitle:(isBuyPay?"소장하기":"확인"),
                    success2BtnTitle:(isRentPay ? (my_voucher?.rent_cnt > 0 && my_voucher?.voucher_rent_ok ? "대여하기 (선물이용권 1장)":"대여하기 (쿠폰 "+result?.toon_price_rent+"장 사용)"):""),
                    successAction:(useVoucher)=>{
                        console.log("useVoucher",useVoucher);
                        buySubmit({
                            bid:bid,
                            cid:data?.cid,
                            epub:false,
                            buy_type:useVoucher ? "v_buy" : "buy",
                        });

                    },
                    success2Action:()=>{
                        if(isRentPay){
                            buySubmit({
                                bid:bid,
                                cid:data?.cid,
                                epub:false,
                                buy_type:my_voucher?.rent_cnt > 0 && my_voucher?.voucher_rent_ok ? "v_rent" : "rent",
                            });
                        }
                    },
                });
                return;
            }
            switch(_data?.code){
                case "400":
                    alert("알림","책정보가 없습니다.");
                    break;
                case "406":
                    alert("알림","블라인드 작품입니다.");
                    break;
                case "403":
                    alert("알림","작품 감상을 위해 로그인이 필요합니다.",()=>{
                        navigate("/login",{state:{return_path:location.pathname}});
                    });
                    break;
                default:
                    alert("알림","시스템에 문제가 발생하였습니다. 문제가 지속되면 관리자에게 문의주세요.");
                    break;
            }
        },
        onError:(e)=>{

        },
    });

    const handlerCheckPay = ()=>{


        if(data?.cid === 0){
            alert("뷰어","다음 회차가 없습니다.");
            return;
        }

        // if(is_payable === "false"){
        //     getViewPageLink();
        //     return;
        // }

        // if(is_paid === "true"){
        //     getViewPageLink();
        //     return;
        // }

        checkSubmit({
            bid:bid,
            cid:data?.cid,
        });
    }


    const getViewPageLink = ()=>{
        navigate("/view/"+type+"/"+data?.cid+"/viewer");
    }

    return (
        <li className={"color-txt-01 color-border-thumbnail border-bottom-line border-soild " + (parseInt(cid,10)===parseInt(data?.cid,10) ? style['active']:"")}>
            <BookpalLink
                onClick={(e)=>{
                    e.preventDefault()
                    handlerCheckPay();
                }}
                ><span className="text_overflow">
                    {data?.no}. {data?.title}
                </span>
            </BookpalLink>
        </li>
    )
}



export default HeaderToonEpisodeList;