export const SET_TOKEN = 'SET_TOKEN';
export const DEL_TOKEN = 'DEL_TOKEN';

export const SET_MEMBER_INFO = 'SET_MEMBER_INFO';
export const DEL_MEMBER_INFO = 'DEL_MEMBER_INFO';

export const SET_DOT = 'SET_DOT';

export const SET_KEYWORD_SAVE_MODE = 'SET_KEYWORD_SAVE_MODE';


export const SET_VIEWER_INFO_FONT = 'SET_VIEWER_INFO_FONT';
export const SET_VIEWER_INFO_FONT_SIZE = 'SET_VIEWER_INFO_FONT_SIZE';
export const SET_VIEWER_INFO_LINE_HEIGHT = 'SET_VIEWER_INFO_LINE_HEIGHT';
export const SET_VIEWER_INFO_SIDE_MARGIN = 'SET_VIEWER_INFO_SIDE_MARGIN';
export const SET_VIEWER_INFO_STYLE = 'SET_VIEWER_INFO_STYLE';
export const SET_VIEWER_INFO_THEME = 'SET_VIEWER_INFO_THEME';
export const SET_VIEWER_INFO_IMAGE_NONE = 'SET_VIEWER_INFO_IMAGE_NONE';
export const SET_VIEWER_INFO_RUN = 'SET_VIEWER_INFO_RUN';


export const SET_SYSTEM_MODE = 'SET_SYSTEM_MODE';

export const SET_ADULT_SWITCH = 'SET_ADULT_SWITCH';

export const SET_AUTO_LOGIN = 'SET_AUTO_LOGIN';

export const SET_APP_BANNER = 'SET_APP_BANNER';

export const SET_SAVE_ID = 'SET_SAVE_ID';
export const SET_SAVE_ID_CHECK = 'SET_SAVE_ID_CHECK';
export const SET_LAST_SOCIAL_LOGIN_TRY_PATH = 'SET_LAST_SOCIAL_LOGIN_TRY_PATH';
export const SET_LAST_SOCIAL_LOGIN_TRY_TYPE = 'SET_LAST_SOCIAL_LOGIN_TRY_TYPE';
export const SET_LAST_SOCIAL_LOGIN_TYPE = 'SET_LAST_SOCIAL_LOGIN_TYPE';
export const SET_LAST_SOCIAL_LOGIN_DATE = 'SET_LAST_SOCIAL_LOGIN_DATE';

export const SET_CONNECT_SOCIAL_LOGIN_TYPE = 'SET_CONNECT_SOCIAL_LOGIN_TYPE';

export const SET_CERT_RETURN_PATH = 'SET_CERT_RETURN_PATH';

export const SET_TOOLTIP_LOGIN = 'SET_TOOLTIP_LOGIN';
export const SET_TOOLTIP_SEARCH = 'SET_TOOLTIP_SEARCH';




export const SET_SHOW_GIFT_TOAST = 'SET_SHOW_GIFT_TOAST';
