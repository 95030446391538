import { useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import BookpalLink from 'component/common/custom/BookpalLink';
import { useSelector, useDispatch  } from 'react-redux';
import { ApiAuthInfo, ApiAuthSocialLoginUrl } from 'api/Auth';
import { ApiMypageProfileUpload, ApiMypageProfileDefault } from 'api/Mypage';
import { ApiCommonDot } from 'api/Common';
import { useEffect, useState } from 'react';
import { ApiCertLoading } from "api/Cert";
import { isApp } from 'utils/AppUtil';
import { runPopupOrder } from 'utils/Common';
import { actionSetLastSocialLoginTryType, actionSetLastSocialLoginTryPath } from 'action/index';
import { GetState, SetState } from 'utils/State';


import useSelectModal from 'component/common/modal/useSelectModal';
import useConfirmSnsModel from 'component/common/modal/useConfirmSnsModel';

import Header from 'component/common/header/Header';
import Footer from 'component/common/footer/Footer';

import SubNav from "component/common/nav/SubNav";

import style from './style/Mypage.module.scss';


import img_rating_label_normal from 'assets/images/rating_label_normal.svg';
import img_rating_label_vip from 'assets/images/rating_label_vip.svg';
import img_rating_label_vip_silver from 'assets/images/rating_label_vipSilver.svg';
import img_rating_label_vip_gold from 'assets/images/rating_label_vipGold.svg';
import img_rating_label_rvip from 'assets/images/rating_label_rVip.svg';
import useConfirmCustomModal from 'component/common/modal/useConfirmCustomModal';

function Mypage(){

    // const style = {
    //     fontSize:'15px',
    //     padding: '10px'
    // };

    const navigate = useBookpalNavigate();
    const dispatch = useDispatch();

    const getState = GetState();
    const setState = SetState();
    const dot = getState.useGetDot();

    // const userData = useSelector((state) => state.data.tokens);
    // console.log(userData);
    // const userData = JSON.parse(userDataStr);
    const [loading, setLoading] = useState(true);
    const [isLogin, setIsLogin] = useState(false);

    const [mbInfo, setMbInfo] = useState({});
    const [myInfoSns, setMyInfoSns] = useState(null);

    const [image, setImage] = useState(null);
    const fileInputRef = useRef(null);

    const [ConfirmModal, confirm] = useConfirmCustomModal();


    const infoSubmit = ApiAuthInfo({
        onSuccess:(data)=>{
            // console.log(data);
            if(data?.status === true){
                setIsLogin(data?.result?.is_login);
                // setMbId(data?.result?.mb_id);
                
                setMbInfo({...data?.result});
                setMyInfoSns(data?.result?.sns);
                // console.log(data?.result);
            }

            setLoading(false);
        },
        onError:()=>{
            alert("통신이 실패하였습니다. 다시 시도해 주세요.");
        },
    });

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        // console.log("file",file)
        if (file && file.type.startsWith('image/')) {
            // setImage(URL.createObjectURL(file));
            setImage(file);
        } else {
            alert("이미지만 업로드 가능합니다.");
        }
    };

    const handlerProfileDefault = ApiMypageProfileDefault({
        onSuccess:(data)=>{
            if(data?.status === true){
                navigate("/mypage",{replace:true});
                // window.location.reload();
                setState.setToast({
                    title:"프로필이 변경되었습니다.",
                });
                infoSubmit({});
                return;
            }
            alert("시스템에 문제가 있습니다. 다시 시도해 주세요.");
        },
        onError:(e)=>{
            alert("이미지 업로드가 실패하였습니다. 다시 시도해 주세요.");
        }
    });

    const handlerProfileUpload = ApiMypageProfileUpload({
        onSuccess:(data)=>{
            console.log(data);
            if(data?.status === true){
                navigate("/mypage",{replace:true});
                // alert("등록 되었습니다.");
                // window.location.reload();
                setState.setToast({
                    title:"프로필이 변경되었습니다.",
                });
                infoSubmit({});
                setImage(null);
                fileInputRef.current.value = null;
                return;
            }
            alert("시스템에 문제가 있습니다. 다시 시도해 주세요.");
            setImage(null);
            fileInputRef.current.value = null;
        },
        onError:(e)=>{
            alert("이미지 업로드가 실패하였습니다. 다시 시도해 주세요.");
            setImage(null);
            fileInputRef.current.value = null;
        }
    })

    useEffect(()=>{
        if(image){
            const formData = new FormData();
            console.log("image", image)
            formData.append('picture', image);
            // document.picForm;
            // handlerProfileUpload(document.picForm);
            handlerProfileUpload(formData);
        }
    },[image]);

    const modalData = {
        album:"앨범에서 사진 선택",
        emblem:"엠블럼에서 선택",
        default:"기본이미지 사용",
    }
    const [Modal, modalShow, selectKey] = useSelectModal(modalData, "all", (key) => {
        switch(key){
            case "album": 
                fileInputRef.current.click();
                // const formData = new FormData();
                // formData.append('image', image);
                // const formData = new FormData();
                // images.forEach((image, index) => {
                //     formData.append("picture", image);
                // });
                // const inputFile = document.createElement("input");
                // inputFile.
            break;
            case "emblem": 
            navigate("/mypage/emblem");
            break;
            case "default": 
            handlerProfileDefault();
            break;
        }
    }, true);

    const socialLoginGetUrl = ApiAuthSocialLoginUrl({
        onSuccess: (data) => {
            console.log(data);
            // console.log(appReturnLink);
            // return;
            if(data?.status === true){
                // if(isApp()){
                //     if(appReturnLink){
                //         dispatch(actionSetLastSocialLoginTryPath(appReturnLink));
                //     }
                // }
                setState.setLastSocialLoginTryPath("/mypage/edit");
                // dispatch(actionSetLastSocialLoginTryPath("/mypage/edit"));
                setTimeout(()=>{
                    window.location.href = data?.result?.login_url;
                },100);
                
            } else {
                alert("시스템 문제가 발생하였습니다. 문제가 지속되면 관리자에게 문의주세요.");
            }
        },
        onError: (e) => {
            console.log(e);
            alert("통신이 실패하였습니다. 문제가 지속되면 관리자에게 문의주세요.");
        },
    });

    const handlerSocialLoginUrl = async (social_type) => {
        dispatch(actionSetLastSocialLoginTryType(social_type));
        
        await socialLoginGetUrl({
            type: social_type,
            return_url: process.env.REACT_APP_BASE_URL + "/login",
        });
        
    };

    const [SnsModal, snsModalShow] = useConfirmSnsModel({
        sns: myInfoSns,
        action: ()=>{
            // navigate("/login?test=1");
            handlerSocialLoginUrl(myInfoSns);
        }
    });

    

    const handlerMoveLogout = () => {
        // setTitle(data?.title || false);
        // setContent(data?.content || false);
        // setSuccessBtnTitle(data?.successBtnTitle || "확인");
        // setCancleBtnTitle(data?.cancleBtnTitle || "취소");
        // setAction({
        //     ...action,
        //     cancleAction:data?.cancleAction,
        //     successAction:data?.successAction,
        //     closeAction:data?.closeAction,
        // })
        confirm({
            title:"로그아웃 하시겠습니까?",
            successAction:()=>{
                navigate("/logout",{replace: true});
            },
        })
        
    }

    const handlerMoveLogin = () => {
        navigate("/login",{replace: true});
    }

    const handlerMoveBenefit = () => {
        navigate("/mypage/benefit");
    }

    const handlerMyinfoEdit = () => {
        if(isLogin){
            if(mbInfo?.sns === "etc" || mbInfo?.sns === ""){
                navigate("/confirm_pw",{state: "/mypage/edit"});
            }
            if(mbInfo?.sns === "kakao"){
                // alert("kakao");
                snsModalShow();
            }
            if(mbInfo?.sns === "apple"){
                // alert("apple");
                snsModalShow();
            }
        }
    }

    const certLoadingSubmit = ApiCertLoading();

    const handlerCert = ()=>{        
        certLoadingSubmit(process.env.REACT_APP_BASE_URL + "/mypage");
    }

    

    useEffect(()=>{
        infoSubmit({});
    },[]);

    if(loading){
        return (
            <>
                Loading...
            </>
        )
    }

    if(!isLogin){
        return (
            <>
                <Header useMobile={false} />
                <SubNav 
                    name={"마이페이지"}
                    usePc={true} 
                    useMobile={true} 
                    usePcRightBtns={true}
                    useBtnPrev={true}
                    useBtnAdult={mbInfo?.is_login && mbInfo?.is_cert && mbInfo?.is_adult}
                    // onPrev={()=>{
                    //     navigate("/");
                    // }}
                    statAdult={true}
                    setTop={'0px'} 
                    setPcTop={'64px'} 
                    />

                {/* <!-- container --> */}
                <div id="container">
                    <section className={"my_page wrapper " + style['my_page'] + " " + style['wrapper']}>
                        <div className={"my_profile section " + style['my_profile'] + " " + style['section']}>
                            {/* <!-- 로그인 전 --> */}
                            <div className={"login_btn before " + style['login_btn'] + " " + style['before']}>
                                <p className={"s_tit body-03 color-txt-02 " + style['s_tit']}>로그인 후 이용 가능합니다.</p>
                                <button type="button" className={"button radius-full btn_01 medium filled label_on " + style['button'] + " " + style['label_on']} onClick={handlerMoveLogin}><span className={"white-always txt " + style['white-always'] + " " + style['txt']}>로그인 하기</span></button>
                            </div>
                            {/* <!-- 로그인 후 --> */}
                        </div>
                        
                        <div className={"menu_list section " + style['menu_list'] + " " + style['section']}>
                            <nav>
                                <ul>
                                    <li>
                                        <BookpalLink to={"/mypage_menu/event"}>
                                            <span className={"menu_icon event_icon " + style['menu_icon'] + " " + style['event_icon']}></span>
                                            <p className={"menu_tit body-04 color-txt-normal " + style['menu_tit']}>
                                                이벤트
                                            </p>
                                        </BookpalLink>
                                    </li>
                                    <li>
                                        <BookpalLink to={"/mypage_menu/help"}>
                                            <span className={"menu_icon exclamation_icon " + style['menu_icon'] + " " + style['exclamation_icon']}></span>
                                            <p className={"menu_tit body-04 color-txt-normal " + style['menu_tit']}>
                                                고객센터
                                            </p>
                                        </BookpalLink>
                                    </li>
                                    <li>
                                        <a href="https://factory.bookpal.co.kr/" target="_blank">
                                            <span className={"menu_icon edit_icon " + style['menu_icon'] + " " + style['edit_icon']}></span>
                                            <p className={"menu_tit body-04 color-txt-normal " + style['menu_tit']}>
                                                작가홈가기
                                            </p>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </section>
                </div>
                {/* <!-- container --> */}
                <Footer />
            </>
        );
    }

    return (
        <>
        {mbInfo?.test}
            <Header useMobile={false} />
            <SubNav 
                name={"마이페이지"}
                usePc={true} 
                useMobile={true} 
                usePcRightBtns={true}
                useBtnPrev={true}
                useBtnAdult={mbInfo?.is_login && mbInfo?.is_cert && mbInfo?.is_adult}
                useAdult={mbInfo?.is_adult}
                // onPrev={()=>{
                //     navigate("/");
                // }}
                setTop={'0px'} 
                setPcTop={'64px'} 
                />

            {/* <!-- container --> */}
            <div id="container" style={{paddingBottom:"36px"}}>
                <section className={"my_page wrapper " + style['my_page'] + " " + style['wrapper']} >
                    <div className={"my_profile section " + style['my_profile'] + " " + style['section']} >
                        {/* <!-- 로그인 후 --> */}
                        <div className={"my_info after " + style['after'] + " " + style['my_info']}>
                            <div className={"left profile_box " + style['left'] + " " + style['profile_box']}>
                                <p className={"profile radius-full " + style['profile']}>
                                    {/* <img src="/images/thumbnail.jpg" /> */}
                                    <img src={mbInfo?.emblem_use === true ? mbInfo?.emblem : mbInfo?.profile_image} />
                                </p>
                                <button 
                                    type="button" 
                                    className={"button btn_02 medium outlined round label_off radius-full " + style['button'] + " " + style['label_off']} 
                                    onClick={modalShow}
                                    ><span className={"icon " + style['icon']}></span></button>
                            </div>
                            <div className={"left info_txt " + style['info'] + " " + style['info_txt']}>
                                <div className={"nick_box " + style['nick_box']}>
                                    <p className={"nick_name color-txt-normal title-02-m " + style['nick_name']}><span className={"name title-02-b " + style['name']} id="nickName" style={{maxWidth:"149px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{mbInfo?.mb_nick}</span>님</p>
                                    <button type="button" className={"button setting_btn " + style['button'] + " " + style['setting_btn']} onClick={handlerMyinfoEdit}></button> 
                                </div> 
                                <div className={"rating_box clearfix " + style['rating_box']}>
                                    <p className={"left rating_label medium " + style['left'] + " " + style['rating_label'] + " " + style['medium']}>

                                        {mbInfo?.order_level == "normal" ? 
                                            <span className={"normal " + style['normal']}>
                                                <img src={img_rating_label_normal} className={"Light_label " + style['Light_label']} />
                                                {/* <img src="/images/rating_label_normal-DarkMode.svg" className={"Dark_label " + style['Dark_label']} /> */}
                                            </span>
                                        : null}
                                        
                                        {mbInfo?.order_level == "vip" ? 
                                            <span className={"vip " + style['vip']}>
                                                <img src={img_rating_label_vip} className="Light_label" />
                                                {/* <img src="/images/rating_label_vip-DarkMode.svg" className="Dark_label" /> */}
                                            </span>
                                        : null}

                                        {mbInfo?.order_level == "silver" ? 
                                            <span className={"vipSilver " + style['vipSilver']}>
                                                <img src={img_rating_label_vip_silver} />
                                            </span>
                                        : null}
                                        
                                        {mbInfo?.order_level == "gold" ? 
                                            <span className="vipGold">
                                                <img src={img_rating_label_vip_gold} />
                                            </span>
                                        : null}

                                        {mbInfo?.order_level == "rvip" ? 
                                            <span className="rVip">
                                                <img src={img_rating_label_rvip} className="Light_label" />
                                                {/* <img src="/images/rating_label_rVip-DarkMode.svg" className="Dark_label" /> */}
                                            </span>
                                        : null}
                                    </p>
                                    <p className={"right benefits_btn " + style['right'] + " " + style['benefits_btn']}>
                                        <button type="button" className={"button " + style['button']} onClick={handlerMoveBenefit} ><span className="btn-03 color-btn-03-txt">등급별 혜택</span></button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- 로그인 후 --> */}
                    <div className={"my_pay section " + style['my_pay'] + " " + style['section']} style={{paddingTop:"0", marginTop:"0"}}>
                        <div className={"area_box " + style['area_box']}>
                            <div className={"coupon_box " + style['coupon_box']}>
                                <div className={"left gold_opacity-10 bg radius-lg " + style['left'] + " " + style['bg']} >
                                    <p className={"icon_coupon_box " + style['icon_coupon_box']}>
                                        <span></span>
                                    </p>
                                    <div className={"text_box " + style['text_box']}>
                                        <p className={"s_title btn-03 color-txt-04 " + style['s_title']}>내가 가진 쿠폰</p>
                                        <p className={"num_btn " + style['num_btn']}>
                                            <BookpalLink to={"/mypage/coupon"} className="title-04-m color-txt-normal">
                                            {/* <a href="#" className="title-04-m color-txt-normal"> */}
                                                <span className="title-04">{mbInfo?.coupon_cnt}</span>개
                                            {/* </a> */}
                                            </BookpalLink>
                                        </p>
                                    </div>
                                </div>
                                <div className={"right gold_opacity-10 bg radius-lg " + style['right'] + " " + style['bg']}>
                                    {/* <!-- 본인인증 전 --> */}
                                    {
                                        mbInfo?.is_cert ? 
                                            <div className={"after " + style['after']}>
                                            <div className={"text_box " + style['text_box']}>
                                                <p className={"s_title btn-03 color-txt-04 " + style['s_title']}>선물이용권</p>
                                                <p className={"num_btn " + style['num_btn']}>
                                                    <BookpalLink to={"/mypage/gift/givelist"} className="title-03-m color-txt-normal">
                                                        <span className="title-03">{mbInfo?.coupon_use_cnt}</span>장
                                                    </BookpalLink>
                                                </p>
                                            </div>
                                            <div className={"text_box " + style['text_box']}>
                                                <p className={"s_title btn-03 color-txt-04 " + style['s_title']}>선물소장권</p>
                                                <p className={"num_btn " + style['num_btn']}>
                                                    <BookpalLink to={"/mypage/gift/givelist"} className="title-03-m color-txt-normal">
                                                        <span className="title-03">{mbInfo?.coupon_own_cnt}</span>장
                                                    </BookpalLink>
                                                </p>
                                            </div>
                                        </div>
                                        : <div className={"self_identify before " + style['self_identify'] + " " + style['before']}>
                                            <p className={"s_tit title-sub-02-m color-txt-03 " + style['s_tit']}>본인인증 후 선물을 받을 수 있어요!</p>
                                            <button type="button" className="button btn_01 outlined label_on medium radius-full"><span className="btn-03 color-btn-01 txt" onClick={handlerCert}>본인인증</span></button>
                                        </div>
                                    }
                                    {/* <div className={"self_identify before " + style['self_identify'] + " " + style['before']}>
                                        <p className={"s_tit title-sub-02-m color-txt-03 " + style['s_tit']}>본인인증 후 선물을 받을 수 있어요!</p>
                                        <button type="button" className="button btn_01 outlined label_on medium radius-full"><span className="btn-03 color-btn-01 txt">본인인증</span></button>
                                    </div> */}
                                    {/* <!-- 본인인증 후 --> */}
                                    {/* <div className={"after " + style['after']}>
                                        <div className={"text_box " + style['text_box']}>
                                            <p className={"s_title btn-03 color-txt-04 " + style['s_title']}>선물이용권</p>
                                            <p className={"num_btn " + style['num_btn']}>
                                                <a href="#" className="title-03-m color-txt-normal">
                                                    <span className="title-03">9,000</span>개
                                                </a>
                                            </p>
                                        </div>
                                        <div className={"text_box " + style['text_box']}>
                                            <p className={"s_title btn-03 color-txt-04 " + style['s_title']}>선물소장권</p>
                                            <p className={"num_btn " + style['num_btn']}>
                                                <a href="#" className="title-03-m color-txt-normal">
                                                    <span className="title-03">0</span>개
                                                </a>
                                            </p>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className={"button_box " + style['button_box']}>
                                <button 
                                    type="button" 
                                    className="button btn_01 large filled label_on radius-sm"
                                    onClick={runPopupOrder}
                                    ><span className="white-always txt btn-04">충전하기</span></button>
                            </div>
                        </div>
                    </div>
                    {/* <!-- // 로그인 후 --> */}
                    <div className={"menu_list section " + style['menu_list'] + " " + style['section']} style={{paddingTop:"0", marginTop:"0"}}>
                        <nav>
                            <ul>
                                <li>
                                    <BookpalLink to={"/mypage_menu/alarm"}>
                                        <span className={"menu_icon alarm_icon " + style['menu_icon'] + " " + style['alarm_icon']}></span>
                                        <p className={"menu_tit body-04 color-txt-normal " + style['menu_tit']}>
                                            <span className={"color-btn-01 bg radius-full " + (dot?.alarm_total?'dot':'') + " " + style['bg']}></span>알림
                                        </p>
                                    </BookpalLink>
                                </li>
                                <li>
                                    <BookpalLink to={"/mypage_menu/event"}>
                                        <span className={"menu_icon event_icon " + style['menu_icon'] + " " + style['event_icon']}></span>
                                        <p className={"menu_tit body-04 color-txt-normal " + style['menu_tit']}>
                                            <span className={"color-btn-01 bg radius-full " + (dot?.event_total?'dot':'')}></span>이벤트
                                        </p>
                                    </BookpalLink>
                                </li>
                                <li>
                                    <BookpalLink to={"/mypage/comment"}>
                                        <span className={"menu_icon chat_icon " + style['menu_icon'] + " " + style['chat_icon']}></span>
                                        <p className={"menu_tit body-04 color-txt-normal " + style['menu_tit']}>
                                            <span className={"color-btn-01 bg radius-full " + (dot?.reply_list?'dot':'') + " "}></span>댓글내역
                                        </p>
                                    </BookpalLink>
                                </li>
                                <li>
                                    <BookpalLink to={"/mypage_menu/help"}>
                                        <span className={"menu_icon exclamation_icon " + style['menu_icon'] + " " + style['exclamation_icon']}></span>
                                        <p className={"menu_tit body-04 color-txt-normal " + style['menu_tit']}>
                                            <span className={"color-btn-01 bg radius-full " + (dot?.help?'dot':'') + " "}></span>고객센터
                                        </p>
                                    </BookpalLink>
                                </li>
                                <li>
                                    <BookpalLink to={"/mypage_menu/shortcut"}>
                                        <span className={"menu_icon allMenu_icon " + style['menu_icon'] + " " + style['allMenu_icon']}></span>
                                        <p className={"menu_tit body-04 color-txt-normal " + style['menu_tit']}>
                                        바로가기
                                        </p>
                                    </BookpalLink>
                                </li>
                                <li>
                                    <BookpalLink to={"/mypage_menu/setting"}>
                                        <span className={"menu_icon setting_icon " + style['menu_icon'] + " " + style['setting_icon']}></span>
                                        <p className={"menu_tit body-04 color-txt-normal " + style['menu_tit']}>
                                            설정
                                        </p>
                                    </BookpalLink>
                                </li>
                                <li>
                                    <a href="https://factory.bookpal.co.kr/" target="_blank">
                                        <span className={"menu_icon edit_icon " + style['menu_icon'] + " " + style['edit_icon']}></span>
                                        <p className={"menu_tit body-04 color-txt-normal " + style['menu_tit']}>
                                            작가홈가기
                                        </p>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className={"login_out bottom_button section " + style['login_out'] + " " + style['bottom_button'] + " " + style['section']} style={{marginTop:"0"}}>
                        <button tyep="button" className={"button " + style['button']} onClick={handlerMoveLogout} ><span className="btn-04 color-btn-03-txt">로그아웃</span></button>
                    </div>
                </section>
            </div>
            {/* <!-- container --> */}
            <Modal />
            <SnsModal />
            <ConfirmModal />
            <Footer />
            <form name="picForm" >
                <input 
                    type="file" 
                    onChange={handleImageChange} 
                    multiple 
                    ref={fileInputRef} 
                    accept=".jpg, .jpeg, .png, .gif"
                    style={{display: 'none'}}
                />
            </form>
        </>
    );

    // if(loading){
    //     return (
    //         <>
    //             Loading...
    //         </>
    //     )
    // }

    // return (
    //     <div className='header_inner'>
    //         user Data  : {userData?.token} , {userData?.login_type}
    //         <br /><br /><br />
    //         Mypage { isLogin ? "로그인 상태" : "비로그인 상태"} {mbId}
    //         <br /><br /><br />
    //         <ul>
    //             <li>
    //                 <u>#비로그인</u>
    //             </li>
    //             <li>
    //                 <p style={style}>
    //                     <Link to="/login">
    //                         로그인 - 로그인 후 이용 가능합니다.
    //                     </Link>
    //                 </p>
    //                 <p style={style}>이벤트</p>
    //                 <p style={style}>고객센터</p>
    //                 <p style={style}>작가홈 가기</p>
    //             </li>
    //             <li>
    //                 <u>#로그인</u>
    //             </li>
    //             <li>
    //                 <p style={style}>프로필사진 :</p>
    //                 <p style={style}>닉네임 :</p>
    //                 <p style={style}>등급 :</p>
    //                 <p style={style}>등급별 혜택 info</p>
    //                 <p style={style}>19금(온/오프) : </p>
    //                 <p style={style}>내가가진쿠폰 : , 선물이용권 : , 선물소장권 : </p>
    //                 <p style={style}>충전하기</p>
    //                 <p style={style}>알림</p>
    //                 <p style={style}>이벤트</p>
    //                 <p style={style}>댓글내역</p>
    //                 <p style={style}>고객센터</p>
    //                 <p style={style}>바로가기</p>
    //                 <p style={style}>설정</p>
    //                 <p style={style}>작가홈가기</p>
    //                 <p style={style}>로그아웃</p>
    //             </li>
    //         </ul>
            
    //     </div>
    // )
}


export default Mypage;
