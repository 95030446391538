import Header from 'component/common/header/Header';
import MobileNav from 'component/common/nav/MobileNav';

import Footer from 'component/common/footer/Footer';
import TopBtn from 'component/common/button/top/TopBtn';

import SubNav from 'component/common/nav/SubNav';
// import SubMenuNav from '../nav/SubMenuNav';
import useSelectModal from 'component/common/modal/useSelectModal';

import GiftDetailTab from '../tab/GiftDetailTab';

import img_gift from 'assets/images/gift/gift_img.png';

import style from '../style/DetailGift.module.scss';
import { useState } from 'react';

function Receive(){

    const modalData = {
        all:"전체",
        select1:"select option 01",
        select2:"select option 02",
    }
    const [Modal, modalShow, selectKey] = useSelectModal(modalData, "all");

    const [toolTip, setToolTip] = useState(false);

    return (
        <>
            <Header />
            <MobileNav />
            {/* <!-- 서브메뉴 --> */}
            {/* <SubMenuNav page="/detail/gift/receive"/> */}
            <SubNav 
                name="선물내역" 
                useHome={true} 
                usePc={true}
                useMobile={true}
                useBtnPrev={true}
                setTop={"0px"}
                setPcTop={"64px"}
                 />
            {/* <!-- //서브메뉴 --> */}

            {/* <!-- container --> */}
            <div id="container" className={style['container']}>
                

                <section className={"section wrapper history_list " + style['section']}>
                    <GiftDetailTab to={"receive"}/>
                    <div className="area_box">
                        <div className={"tit_con clearfix " + style['tit_con']}>
                            <div className="left">
                                <label for="check" className="checkbox_round check-type-01 small label_on">
                                    <input type="checkbox" id="check"/>
                                    <span className="box"></span>
                                    <span className="txt color-txt-04"><span className="color-btn-01 txt">사용가능한</span> 선물만 보기</span>
                                </label>
                            </div>
                            <div className={"right " + style['right']}>
                                <p className={"button btn-03 color-btn-02-disabled txt " + style['button']} id="infoBtn"><span className={"icon " + style['icon']} onClick={()=>{setToolTip(true);}}></span>이용안내</p>
                                <div id="toolTip" className={style['toolTip']} style={{display:toolTip?"block":"none"}}>
                                    <button type="button" className="radius-xs color-btn-01 bg top_left">
                                        <span className="white-always caption-02-long txt">
                                            유효기간이 지나지 않은 선물 내역을<br />
                                            보시려면 <b>사용가능한 선물만 보기</b>를<br />
                                            이용하세요.
                                        </span>
                                        <img src="/images/x_circle.svg" onClick={()=>{setToolTip(false);}} />
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* <!-- 리스트 --> */}
                        <ul className="list">
                            <li className="border-soild border-bottom-line color-border-01">
                                <a href="#">
                                    <div className="left info_txt spacing_08">
                                        <div className="list_tit_con">
                                            <span className="title-01-b color-txt-normal text_overflow">결혼지옥</span>
                                            <span className="icon"></span>
                                        </div>
                                        <div className="category body-02 color-txt-03">[거래구분] 선물이용권</div>
                                        <div className="date body-01 color-txt-05">YY.MM.DD 00:00:00</div>
                                    </div>
                                    <div className="right">
                                        <div className="title title-01-m color-txt-normal"><span>1</span>장</div>
                                        <div className="data color-btn-01 txt body-01">유효기간 <span>23.11.10</span></div>
                                    </div>
                                </a>
                            </li>
                            {/* <!-- 전체 탭 리스트 (모달뜨고 웹소설>로맨스 랭킹페이지로 이동)  --> */}
                            <li className="border-soild border-bottom-line color-border-01">
                                <a href="#none" >
                                    <div className="left info_txt spacing_08">
                                        <div className="list_tit_con">
                                            <span className="title-01-b color-txt-normal text_overflow">전체</span>
                                            <span className="icon"></span>
                                        </div>
                                        <div className="category body-02 color-txt-03">[거래구분] 선물이용권</div>
                                        <div className="date body-01 color-txt-05">YY.MM.DD 00:00:00</div>
                                    </div>
                                    <div className="right">
                                        <div className="title title-01-m color-txt-normal"><span>1</span>장</div>
                                        <div className="data color-btn-01 txt body-01">유효기간 <span>23.11.10</span></div>
                                    </div>
                                </a>
                            </li>
                            {/* <!-- 유효기간 만료 리스트 --> */}
                            <li className="border-soild border-bottom-line color-border-01 disabled">
                                <a>
                                    <div className="left info_txt spacing_08">
                                        <div className="list_tit_con">
                                            <span className="title-01-b color-txt-muted text_overflow">결혼지옥</span>
                                            <span className="icon"></span>
                                        </div>
                                        <div className="category body-02 color-txt-muted">[거래구분] 선물이용권</div>
                                        <div className="date body-01 color-txt-muted">YY.MM.DD 00:00:00</div>
                                    </div>
                                    <div className="right">
                                        <div className="title title-01-m color-txt-muted"><span>1</span>장</div>
                                        <div className="data color-txt-muted body-01">유효기간 <span>만료</span></div>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        {/* <!-- 더보기 버튼 클릭시 하단으로 리스트 30개 추가 노출 30개미만일때는 더보기버튼 없음 --> */}
                        <div className="more_btn color-bg-01">
                            <button type="button" className="button btn_03 medium outlined label_on round radius-full color-txt-04 btn-03">
                                <span>더보기 (1/20)</span>
                            </button>
                        </div>
                        {/* <!-- 내역 없을때 -->
                        <!-- <div className="no_data large color-txt-04 body-02-long">최근 90일 동안 사용한 내역이 없습니다.</div> --> */}
                    </div>
                </section>

            </div>
            <Modal />
            {/* <!-- container --> */}
            <Footer />
            <TopBtn />
        </>
    );
}


export default Receive;