import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useLocationName, useNoneMobileHeaderList, useIsMobile, runPopupOrder } from 'utils/Common';
import { GetState, useMemberInfo } from 'utils/State';
import BookpalLink from 'component/common/custom/BookpalLink';


import PcNav from 'component/common/nav/PcNav';

import style from './HeaderInner.module.scss';
import useAlertModal from 'component/common/modal/useAlertModal';


function HeaderInner({title}){
    const page = useLocationName();
    const memberInfo = useMemberInfo();
    const [AlertModal, alert] = useAlertModal();
    const [isTop, setIsTop] = useState(page == "main" ? true:false);
    const topRef = useRef(null);
    const isMobile = useIsMobile();
    const display = (useNoneMobileHeaderList(page) && isMobile ? "none":"flex");

    const getState = GetState();
    const mypageDot = getState.useGetDot()?.mypage;

    const handlerRunPopupOrder = (e)=>{
        if(memberInfo.is_login === false){
            alert("충전","로그인 해주세요.");
            return;
        }
        runPopupOrder(e);
    }

    // const handlerPopupOrder = (e)=>{
    //     e.preventDefault(); 
    //     var popupWidth = 380;
    //     var popupHeight = 640;
    //     var popupX = (window.innerWidth - popupWidth) / 2;
    //     var popupY = (window.innerHeight - popupHeight) / 2;
    //     var url = "/order";
    //     var popupX = (window.screenX + (window.innerWidth - popupWidth) / 2);
    //     var popupY = (window.screenY + (window.innerHeight - popupHeight) / 2);
    //     window.open(url, '', 'status=no, location=no, height=' + popupHeight + ', width=' + popupWidth + ', left=' + popupX + ', top=' + popupY);
    // }

    useEffect(() => {
        const handleScroll = () => {
            
            if(page == "main"){
                const scrollTop = window.scrollY;

                const elementHeight = (topRef.current.parentElement.previousElementSibling) ? topRef.current.parentElement.previousElementSibling.offsetHeight : 0;

                // console.log(isTop, elementHeight, scrollTop)
                if(elementHeight < scrollTop - 1 ){
                    setIsTop(  false );
                } else {
                    setIsTop(  true );
                }
            } else {
                setIsTop(false);
            }
        };

        handleScroll();
        
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const classHeaderInner = isTop ? "header_inner color-border-01 " + (page == "main" ? style['header_inner']:"") : "header_inner color-border-01 scroll border-soild border-bottom-line color-bg-01 " + (page == "main" ? style['header_inner']:"");

    return (
        <>
        <div 
            style={{"display":display}}
            ref={topRef}
            className={classHeaderInner} 
            // style={{top: "0px",position:(isTop ? "absolute" : "fixed")}} 
            >
            <div className="clearfix wrapper">
                <div className="left">
                    <div className="logo text_overflow">
                        {/* <a href="/index.html"></a> */}
                        {title ? 
                        <>
                        {!isMobile ? 
                        <BookpalLink to={"/"}></BookpalLink>
                        :null}
                        <span 
                            className="color-txt-normal title-02-b title"
                            style={{display:isMobile?"block":"none"}}
                            >{title}</span>
                        </>
                        :<BookpalLink to={"/"}></BookpalLink>}
                        
                    </div>
                    <PcNav />
                </div>
                <div className="btns right">
                    <a href="https://factory.bookpal.co.kr/" target="_blank">
                        <p className="factory">작가홈</p>
                    </a>
                    <BookpalLink onClick={handlerRunPopupOrder}>
                        <p className="charge">충전</p>
                    </BookpalLink>
                    <BookpalLink to="/search">
                        <p className="search">검색</p>
                    </BookpalLink>
                    <BookpalLink to="/mypage">
                        <p className="mypage">
                            {mypageDot ? <span className="dot color-btn-01 bg radius-full"></span>:null}
                            마이페이지
                        </p>
                    </BookpalLink>
                </div> 
            </div>
        </div>
        <AlertModal />
        </>
    )
}

export default HeaderInner;