import { useNavigate } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import { useCommonValues } from "context/CommonContext";
import { ApiAuthInfo } from 'api/Auth';

import useSelectArrModal from 'component/common/modal/useSelectArrModal';
import useAlertModal from 'component/common/modal/useAlertModal';
import useConfirmModal from 'component/common/modal/useConfirmModal';

import Header from 'component/common/header/Header';
import Footer from 'component/common/footer/Footer';

import SubNav from "component/common/nav/SubNav";

import style from './Setting.module.scss';


import img_novel from 'assets/images/novel/thumbnail_bg2.png';
import img_thumbnail_illust2 from 'assets/images/novel/thumbnail_illust2.png';
import { useEffect, useState } from 'react';
import { GetState, SetState } from 'utils/State';

function Setting(){

    const { darkTheme, toggleTheme } = useCommonValues();
    const navigate = useBookpalNavigate();

    const [info, setInfo] = useState(null);

    const [AlertModal, alert] = useAlertModal();
    const [ConfirmModal, showConfirmModal] = useConfirmModal({
        content:"서비스 이용을 위하여\n로그인이 필요합니다.",
        btnTitle:"로그인",
        btnCancleTitle:"다음에 할게요.",
        action:()=>{
            navigate("/login",{state:{return_path:"/mypage_menu/setting"}});
        }
    })

    const handlerInfo = ApiAuthInfo({
        onSuccess:(data)=>{
            if(data?.status === true){
                setInfo(data?.result);
                return;
            }
            alert("시스템 오류입니다. 다시 시도해 주세요.");
        },
        onError:(e)=>{
            alert("통신에 실패하였습니다. 다시 시도해 주세요.");
        }
    })

    const setState = SetState();
    const getState = GetState();
    const systemMode = getState.useGetSystemMode();
    const themeData = ([
        {
            key:"light",
            value:"라이트모드",
        },
        {
            key:"dark",
            value:"다크모드",
        },
        {
            key:"system",
            value:"시스템모드",
        }
    ]);
    console.log(systemMode)
    const [SelectModal, showSelectModal, selectKey,setSelectKey, initSelect, getValue] = useSelectArrModal(themeData, systemMode || "system", {
        selectFn:(key)=>{
            if(systemMode != key){
                setState.setSystemMode(key);
                toggleTheme();
            }
        },
        title: "화면스타일", 
        info:"해당 스타일은 현재 접속 환경에서만 적용됩니다."
    });

    const handlerMoveSettingDevice = () => {
        if(info?.is_login === true){
            navigate("/mypage_menu/setting/device");
        } else {
            showConfirmModal();
        }
    };

    useEffect(()=>{
        handlerInfo();
    },[]);

    useEffect(()=>{
        if(info?.is_login === false){
            alert(null, "잘못된 접근 입니다.",()=>{
                navigate(-1);
            })
        }
    },[info])
    // const handlerMoveEditSns = () => {
    //     navigate("/mypage/editsns");
    // };

    ///mypage_menu/setting/device

    if(info?.is_login !== true){
        return (
            <>
                <AlertModal />
            </>
        )
    }


    return (
        <>
            <Header useMobile={false} />
            <SubNav 
                name={"설정"}
                usePc={true} 
                useMobile={true} 
                usePcRightBtns={true}
                useBtnPrev={true}
                useBtnPcPrev={true}
                useBtnHome={true}

                setTop={'0px'} 
                setPcTop={'64px'} 
                />


            
            {/* <!-- container --> */}
            <div id="container" className={style['containerr']}>
                <section className={"wrapper " + style['wrapper']}>
                    {/* <div className={"section " + style['section']}>
                        <div className={"tit_con " + style['tit_con']}>
                            <h2 className={"title left color-txt-normal title-02-b " + style['title']}>서비스 이용 환경 설정</h2>
                        </div>
                        <div className={"button_box " + style['button_box']}>
                            <button 
                                type="button" 
                                className={"button color-border-01 radius-sm " + style['button']} 
                                onClick={showSelectModal}
                                >
                                <p className={"left text_overflow title-sub-02-m color-txt-02 " + style['left'] + " " + style['text_overflow']}>화면스타일</p>
                                <p className={"right " + style['right']}>
                                    <span className="mode_txt color-btn-01 txt body-02-long">{getValue()}</span>
                                    <span className={"icon " + style['icon']}></span>
                                </p>
                            </button>
                        </div>
                    </div> */}
                    <div className={"section " + style['section']}> 
                        <div className={"tit_con " + style['tit_con']}>
                            <h2 className={"title left color-txt-normal title-02-b " + style['title']}>기기 등록 및 기타 정보</h2>
                        </div>
                        <div className={"button_box " + style['button_box']}>
                            <button 
                                type="button" 
                                className={"button color-border-01 radius-sm " + style['button']}
                                onClick={handlerMoveSettingDevice}
                                >
                                <p className={"left text_overflow title-sub-02-m color-txt-02 " + style['left'] + " " + style['text_overflow']}>기기 등록 관리</p>
                                <p className={"right " + style['right']}>
                                    <span className={"icon " + style['icon']}></span>
                                </p>
                            </button>
                        </div>
                    </div>
                </section>
            </div>
            {/* <!-- container --> */}

            <Footer />
            <SelectModal />
            <AlertModal />
            <ConfirmModal />
        </>
    );
}




export default Setting;