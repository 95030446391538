import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import NewlineToBr from '../content/NewlineToBr';

import style from './useCoverModal.module.scss';

import closeBtn from 'assets/images/contents/coverModal_closeBtn.svg';

function useCoverModal(data){

    const btnTitle = data?.btnTitle || "확인";

    // const [title, setTitle] = useState(false);
    // const [content, setContent] = useState(false);
    // const [action, setAction] = useState({});
    const [img, setImg] = useState(null);

    const [showModal, setShowModal] = useState(false);

    const handleClose = () => {
        setShowModal(false);
        // if(action?.action){
        //     action?.action();
        //     setAction({
        //         ...action,
        //         action:null,
        //     });
        // }
    }

    const handlerOnlyClose = ()=>{
        setShowModal(false);
    }

    const handleShow = (img) => {

        setImg(img);
        setShowModal(true);
    }

    const handleSelect = () => {
        handleClose();
    }


    const ModalTmpl =()=>{ 
        return (
        <>
            <Modal 
                id="coverModal"
                className={"black_opacity-60 " + style['coverModal']}
                dialogClassName={"modal-dialog-centered " + style['modal-dialog']}
                contentClassName={"area_box " + style['area_box']}
                show={showModal}
                animation={false} 
                onHide={handlerOnlyClose}
                >
                {/* <Modal.Body> */}
                    {/* {title ? <div className="title title-02-b color-txt-normal">{title}</div> : null}
                    {content ? <div className="desc body-03-long"><NewlineToBr text={content} /></div> : null} */}
                    {/* <div className="select_box">
                    {optionElements}
                    </div> */}
                    {/* <div className="modal-content area_box"> */}
                        <p 
                            className={"closeBtn " + style['closeBtn']}
                            ><button 
                                type="button" 
                                className="button" 
                                data-bs-dismiss="modal"
                                onClick={handlerOnlyClose}
                                ><img src={closeBtn} /></button></p> 
                        <img src={img} />
                    {/* </div> */}
                {/* </Modal.Body> */}
                {/* <Modal.Footer> */}
                    {/* <button type="button" className="radius-sm btn-04 txt btn_01 large label_on filled button" onClick={handleSelect}>{btnTitle}</button> */}
                    {/* <button type="button" className="radius-xs btn-03 txt btn_03 medium label_on filled button" onClick={handleClose}>취소</button>  */}
                {/* </Modal.Footer> */}
            </Modal>
        </>
    )};
    
    return [ModalTmpl, handleShow];
}


export default useCoverModal;