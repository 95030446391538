import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import BookpalLazyLoadImage from "../custom/BookpalLazyLoadImage";
import { ImgDefault } from "utils/Images";
import NewlineToBr from "../content/NewlineToBr";
import BookpalLink from "../custom/BookpalLink";

import style from './ExclusiveTypeSection.module.scss';
import { useMemberInfo } from "utils/State";
import useAlertModal from "../modal/useAlertModal";

function ExclusiveTypeSection({ item }){

    const type = item?.is_underline ? "type2" : "type1";
    const memberInfo = useMemberInfo();
    const [AlertModal, alert] = useAlertModal();

    return (
        <section className={"section solo_section wrapper " + ("solo_" + type)}>
            <div className="area_box">
                <BookpalLink 
                    to={item?.link_more}
                    check={{
                        view_type:memberInfo?.view_type,
                        work_adult:item?.adult,
                    }}
                    alert={alert}
                    >
                {/* <a href="#"> */}
                    <div className="top_con clearfix">
                        <div className="title_con">
                            <h4 className="title color-txt-normal title-04 text_overflow2"><NewlineToBr text={item?.title} /></h4>
                            <p className="desc color-txt-04 title-02-m text_overflow">{item?.content}</p>
                        </div>
                        {(()=>{
                            switch(type){
                                case "type1":
                                    return (
                                        <div className="cover_img">
                                            {/* <img src={item?.image} /> */}
                                            {/* <BookpalLazyLoadImage 
                                                placeholderSrc={ImgDefault("cover_logo")}
                                                // effect="blur"
                                                src={item?.image}
                                                // style={defaultImageStyle}
                                                onError={(e)=>{
                                                    e.currentTarget.src = ImgDefault("cover_logo");
                                                }}
                                            /> */}
                                            <BookpalLazyLoadImage 
                                                adult={item?.adult}
                                                adultswitch={memberInfo?.audit_switch}
                                                defaulttype={"cover_logo"}
                                                locktype={"cover_lock"}
                                                src={item?.image}
                                                // style={defaultImageStyle}
                                            />
                                        </div>
                                    )
                                case "type2":
                                    return (
                                        <div className={"cover_img border-soild border-bottom-line color-border-01 radius-xs " + style['cover_img']}>
                                            {item?.adult ? <p className="ic_adult badge_adult_middle"><img src="../images/ic_adult.svg" /></p> : null}
                                            {/* <img src={item?.image} className="radius-xs" /> */}
                                            {/* <BookpalLazyLoadImage 
                                                placeholderSrc={ImgDefault("cover_logo")}
                                                // effect="blur"
                                                src={item?.image}
                                                // style={defaultImageStyle}
                                                className="radius-xs"
                                                onError={(e)=>{
                                                    e.currentTarget.src = ImgDefault("cover_logo");
                                                }}
                                            /> */}
                                            <BookpalLazyLoadImage 
                                                adult={item?.adult}
                                                adultswitch={memberInfo?.audit_switch}
                                                defaulttype={"cover_logo"}
                                                locktype={"cover_lock"}
                                                src={item?.image}
                                                className="radius-xs"
                                                // style={defaultImageStyle}
                                            />
                                        </div>
                                    )
                            }
                        })()}
                    </div>
                {/* </a> */}
                </BookpalLink>
            </div>
            <AlertModal />
        </section>
    )
}


export default ExclusiveTypeSection;