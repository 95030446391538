import { useLocation } from "react-router-dom";
import KeepAlive, { useAliveController } from "react-activation";

import { useEffect, useState } from "react";
import { isHistoryData, setHistoryData } from "utils/Storage";




function BookpalKeepAlive(props){
    const { pathname, key } = useLocation();
    const { drop, refresh } = useAliveController();

    // const [check, setCheck] = useState( isHistoryData(key) );

    // useEffect(()=>{
    //     if(!isHistoryData(key)){
    //         console.log("drop----------------------");
    //         // refresh(pathname);
    //         setHistoryData(key);
    //         setCheck(true);
    //     }
    // },[]);

    // if(!check){
    //     return null;
    // }

    // console.log("isHistoryData(key)",isHistoryData(key));
    setHistoryData(key)
    return (
        <KeepAlive 
            // name={pathname}
            // id={key} 
            when={() => true}
            cacheKey={key}
            // saveScrollPosition="screen"
            >
            {props.children}
            {/* <Search /> */}
        </KeepAlive>
    )
}



export default BookpalKeepAlive;