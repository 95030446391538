import { useLocation, useNavigate } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import { ApiMyGiftBoxGet } from 'api/My';
import style from '../../style/Contents.module.scss';
import { useState } from 'react';
import { SetState, useMemberInfo } from 'utils/State';
import useAlertModal from 'component/common/modal/useAlertModal';


function Gift(props){

    const memberInfo = useMemberInfo();
    const location = useLocation();
    const navigate = useBookpalNavigate();

    const data = props?.data || null;
    const bid = props?.bid || null;
    const onAction = props?.onAction || (()=>{});
    const setState = SetState();

    const [getYn, setGetYn] = useState(data?.get_yn);

    const [AlertModal, alert] = useAlertModal();

    const giftSubmit = ApiMyGiftBoxGet({
        onSuccess:(data)=>{
            if(data?.status === true){
                if(data?.result?.success === true){
                    // onAction(
                    //     data?.result?.msg, 
                    //     data?.result?.content, 
                    //     data?.result?.date_expire, 
                    //     data?.result?.item_count
                    // );

                    const result = onAction(data?.result?.item_count, data?.result?.voucher_type, data?.result?.content );

                    setGetYn(true);

                    if(!result){
                        setState.setToast({
                            title:data?.result?.content, 
                        });
                    }
                }
                return;
            }
        },
        onError:(e)=>{

        },
    });

    const handlerSubmit = ()=>{

        if(!memberInfo?.is_login){
            alert("알림","로그인 해주세요.",()=>{
                navigate("/login",{state:{return_path:location.pathname}});
            });
            return;
        }


        giftSubmit({
            gift_box_id:data?.gift_box_id
        })
    }


    if(data === null){
        return <></>
    }


    //받으면 노출 안함
    if(getYn === true){
        return null;
    }

    return (
        <>
        <li>
            <div className={"left " + style['left']}>
                <p className={"icon " + style['icon'] + " " + style['icon_gift']} id="icon_gift"></p>
                <p className={"title info " + style['title'] + " " + style['info']}>
                    <span className="color-txt-01 body-03">
                        {/* {data?.title} */}
                        깜짝선물이 왔어요!
                    </span> 
                </p>
            </div>
            <p className={"right " + style['right']}>
                {!data?.get_yn ? 
                    <button 
                    type="button" 
                    className={"button btn_03 round radius-full small label_on outlined " + style['button']}
                    onClick={()=>{
                        handlerSubmit();
                    }}
                    ><span className="btn-02 color-txt-04">선물받기</span></button>
                :<p className="title-sub-02-m color-btn-01 txt">받기완료</p>}
            </p>
        </li>
        <AlertModal />
        </>
    )
}



export default Gift;