import { useLocation, useNavigate } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import SubNav from "component/common/nav/SubNav";

import style from '../style/User.module.scss';


function FindPwComplate(){

    const navigate = useBookpalNavigate();
    const location = useLocation();
    const searchData = new URLSearchParams(location.search);
    const email = searchData.get("mail");

    return (
        <>
            <SubNav 
                name="아이디 찾기" 
                usePc={false} 
                useMobile={true} 
                setTop={'0px'} 
                useBtnPrev={true} 
            />
            
            <div id="container" className={"wrapper " + style['container']}>
                <section className={"find_box find_id " + style['find_box'] + " " + style['find_id']}>
                    <p className={"title color-txt-normal title-04 " + style['title']}>아이디 찾기</p>
                    <div className={"area_box color-bg-01 radius-lg color-border-01 border-soild border-line " + style['area_box']}>
                        <div className={"logo_title " + style['logo_title']}>
                            <p className={"logo " + style['logo']}></p>
                            <p className={"body-01 color-txt-muted label_txt " + style['label_txt']}>당신의 상상이 이야기가 되는 곳</p>
                        </div>
                        <div className={"text_box body-03-long " + style['text_box']}>
                            {/* <!-- 아이디 찾기 결과 --> */}
                            <span className="email color-btn-01 txt">{email}</span> (으)로<br />
                            비밀번호 재설정 메일을 전송하였습니다.<br />
                            해당 메일함을 확인해주시기 바랍니다.
                            {/* <!-- 비번 찾기 결과 --> */}
                            {/* <!-- <span className="email color-btn-01 txt">bookpal@bookpal.co.kr</span> (으)로<br>
                            비밀번호 재설정 메일을 전송하였습니다.<br>
                            해당 메일함을 확인해주시기 바랍니다.  --> */}
                        </div>
                        <p className={"button_box " + style['button_box']}>
                            <button 
                                type="button" 
                                className={"radius-sm btn-04 txt btn_01 large label_on filled button " + style['button']} 
                                onClick={()=>{navigate("/login");}}
                            >확인</button> 
                        </p>
                        <p className={"body-03-long spam_txt " + style['spam_txt']}>
                            메일이 도착하지 않았을 경우 <br />
                            <span className="color-btn-01 txt">’스팸함’</span>을 확인해 주세요. 
                        </p>
                        <p className={"info_txt color-txt-muted body-01-long " + style['info_txt']}>
                            문의사항이 있으시면 아래 주소로 문의 바랍니다. <br />
                            bookpal@bookpal.co.kr
                        </p>
                    </div>        
                </section>
            </div>
        </>
    )
}



export default FindPwComplate;