import { useState } from 'react';
import { ImgBadge } from 'utils/Images';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import BookpalLink from 'component/common/custom/BookpalLink';
import { ApiBuyBuyCheck, ApiBuyBook } from 'api/Buy';
import { runPopupOrder, getFormatBytes, formatDateString } from 'utils/Common';
import style from '../../style/Contents.module.scss';
import { SetState } from 'utils/State';

import img_badge_free from 'assets/images/contents/badge_free-LightMode.svg';

function Epub({bid, info, data, alert, buyConfirm, isMobile}){

    const navigate = useBookpalNavigate();
    const location = useLocation();
    const setState = SetState();

    const [buyCheck, setBuyCheck] = useState(null);

    const buySubmit = ApiBuyBook({
        onSuccess:(_data)=>{
            if(_data?.status === true){
                getViewPageLink();
                setState.setToast({
                    title:_data?.result?.msg,
                })
                return;
            }
            switch(_data?.code){
                case "520":
                    buyConfirm({
                        title:"구매 안내",
                        bookTitle:buyCheck?.buy_info?.title,
                        content:buyCheck?.buy_info?.no + "권을 열람하시겠습니까?",
                        isPay:false,
                        currentConpon:_data?.result?.my_coupon_cnt+"",
                        useConpon:buyCheck?.buy_info?.price,
                        useVoucher:false,
                        successBtnTitle:"쿠폰 충전하러 가기",
                        cancleBtnTitle:"다음에 할게요",
                        successAction:()=>{
                            runPopupOrder();
                        },
                    });
                    break;
                case "513":
                    alert("구매 안내","무료 회차입니다.");
                    break;
                case "512":
                    alert("구매 안내","구매 가능한 회차가 없습니다.");
                    break;
                case "511":
                    alert("구매 안내","이미 구매한 회차입니다.");
                    break;
                default:
                    alert("구매 안내",_data?.message);
                    break;
            }
        },
        onError:(e)=>{

        }
    })

    const checkSubmit = ApiBuyBuyCheck({
        onSuccess:(_data)=>{
            if(_data?.status === true){
                const result = _data?.result;
                const buy_info = result?.buy_info;
                setBuyCheck(result);

                if(buy_info?.is_buy === true){
                    getViewPageLink();
                    return;
                }

                if(buy_info?.is_event_chapter_free === true){
                    getViewPageLink();
                    return;
                }

                if(buy_info?.is_free === true){
                    getViewPageLink();
                    return;
                }
                // const my_voucher = result?.my_voucher;
                // const isPay = result?.my_coupon_cnt > 0;
                const useVoucher = buyConfirm({
                    title:"구매 안내",
                    bookTitle:buy_info?.title,
                    content:buy_info?.no + "권을 열람하시겠습니까?",
                    isPay:true,
                    currentConpon:buy_info?.user_coupon,
                    useConpon:buy_info?.price,
                    useVoucher:false,
                    successBtnTitle:(true?"소장하기":"확인"),
                    success2BtnTitle:"",
                    successAction:(useVoucher)=>{
                        console.log("useVoucher",useVoucher);
                        buySubmit({
                            bid:bid,
                            cid:data?.cid,
                            epub:true,
                            buy_type:"buy",
                        });
                    },
                    success2Action:()=>{
                        // buySubmit({
                        //     bid:data?.bid,
                        //     cid:data?.cid,
                        //     epub:data?.epub,
                        //     buy_type:"v_rent",
                        // });
                    },
                });
                return;
            }
            switch(_data?.code){
                case "400":
                    alert("알림","책정보가 없습니다.");
                    break;
                case "406":
                    alert("알림","블라인드 작품입니다.");
                    break;
                case "403":
                    alert("알림","작품 감상을 위해 로그인이 필요합니다.",()=>{
                        navigate("/login",{state:{return_path:location.pathname}});
                    });
                    break;
                default:
                    alert("알림","시스템에 문제가 발생하였습니다. 문제가 지속되면 관리자에게 문의주세요.");
                    break;
            }
        },
        onError:(e)=>{

        },
    });

    const handlerCheckPay = (is_paid)=>{
        if(is_paid === "true"){
            getViewPageLink();
            return;
        }

        checkSubmit({
            bid:bid,
            cid:data?.cid,
            epub:true,
        });
    }


    const getViewPageLink = ()=>{
        navigate("/view/"+info?.ct_type+"/"+data?.cid+"/viewer");
    }

    return (
        <>
            {isMobile ? 
            <div 
                className={"view_button color-btn-01 bg radius-sm " + style['view_button']}
                style={{
                    position: "fixed",
                    left: 0,
                    bottom: 0,
                    borderRadius: 0,
                    zIndex: 99,
                    marginTop: "24px",
                    width: "100%",
                    height: "3.5rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "hidden",
                }}
                >
                <button 
                    type="button" 
                    className={"button " + style['button']} 
                    // onClick={()=>{handlerMoveViewer(type, info?.last_view?.last?.cid)}}>
                    data-is_paid={data?.is_paid ? "true":"false"}
                    onClick={(e)=>{
                        e.preventDefault()
                        const is_paid = e.currentTarget.getAttribute("data-is_paid");
                        handlerCheckPay(is_paid);
                    }}>
                    {info?.last_view?.is_first_read === true ?
                        <p className="white-always txt btn-04 title">
                            뷰어로 첫 권 보기
                        </p>
                    :
                    <>
                        <p className={"white-always txt btn-04 title " + style['title']}>이어보기</p>
                        <p className={"caption-02 txt_white_opacity-80 s_title " + style['s_title']}>{info?.last_view?.last?.title}</p>
                    </>
                    }
                </button>
            </div>
            :
            <div className={"view_button color-btn-01 bg radius-sm " + style['view_button']}>
                <button 
                    type="button" 
                    className={"button " + style['button']} 
                    // onClick={()=>{handlerMoveViewer(type, info?.last_view?.last?.cid)}}>
                    data-is_paid={data?.is_paid ? "true":"false"}
                    onClick={(e)=>{
                        e.preventDefault()
                        const is_paid = e.currentTarget.getAttribute("data-is_paid");
                        handlerCheckPay(is_paid);
                    }}
                    >
                    {info?.last_view?.is_first_read === true ?
                        <p className="white-always txt btn-04 title">
                            뷰어로 첫 권 보기
                        </p>
                    :
                    <>
                        <p className={"white-always txt btn-04 title " + style['title']}>이어보기</p>
                        <p className={"caption-02 txt_white_opacity-80 s_title " + style['s_title']}>{info?.last_view?.last?.title}</p>
                    </>
                    }
                </button>
            </div>
            }
        </>
    )
}




export default Epub;