import { Link, useLocation, useParams } from 'react-router-dom';

import Header from 'component/common/header/Header';
import MobileNav from 'component/common/nav/MobileNav';

import Footer from 'component/common/footer/Footer';
import TopBtn from 'component/common/button/top/TopBtn';

import useSelectModal from 'component/common/modal/useSelectModal';
import SubNav from 'component/common/nav/SubNav';


import style from './style/Promotion.module.scss';


import img_promotion_event from 'assets/images/promotion/event.png';
import img_promotion_download from 'assets/images/promotion/download.svg';
import BundleEventDetail from './BundleEventDetail';
import BundleEventGift from './BundleEventGift';
import { useEffect } from 'react';



function BundleEvent(){

    const location = useLocation();

    // const { detail_id } = useParams();

    const queryParams = new URLSearchParams(location.search);

    const eventDetail = queryParams.get("event_detail");

    const giftId = queryParams.get("gift_id");
    
    // useEffect(() => {
    //     // alert("123");
    //     console.log(queryParams.get("event_detail"), hrefQueryParams.get("event_detail"), queryParams.has("event_detail"), hrefQueryParams.has("event_detail"))
    //     // const queryParams = new URLSearchParams(location.search);
    //     // if(location.search.startsWith("?event_detail") && url != location.search){
    //     //     window.location.reload(true);
    //     // }
    // },[location.search]);

    // useEffect(()=>{

    // },[]);

    if(eventDetail){
        return (
            <BundleEventDetail key={"eventDetail_"+eventDetail} id={eventDetail} />
        )
    }

    if(giftId){
        return (
            <BundleEventGift id={giftId} />
        )
    }


    return null;
}


export default BundleEvent;