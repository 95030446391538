import { useEffect, useRef, useState } from 'react';
import {Routes, Route, Navigate, useLocation} from 'react-router-dom';

import { ApiGenreGenreList } from 'api/Genre';
import useConfirmCustomModal from 'component/common/modal/useConfirmCustomModal';

import Header from 'component/common/header/Header';
import MobileNav from 'component/common/nav/MobileNav';

import Footer from 'component/common/footer/Footer';
import TopBtn from 'component/common/button/top/TopBtn';

import SubMenuNav from './nav/SubMenuNav';

import ToonCommonSub from './sub/ToonCommonSub';
import Romance from "./sub/Romance";
import RoFan from "./sub/RoFan";
import BL from "./sub/BL";
import Fantasy from "./sub/Fantasy";
import HighLevel from "./sub/HighLevel";
import Comic from "./sub/Comic";
import Ranking from "./sub/Ranking";
import BookpalKeepAlive from 'component/common/custom/BookpalKeepAlive';
// import Freedom from "./sub/Freedom";
// import Episode from "./sub/Episode";




function ToonSub(){

    // const location = useLocation();

    // const genrePathList = {
    //     "/toon/romance":"romance",
    //     "/toon/rofan":"romancefantasy",
    //     "/toon/bl":"bl",
    //     "/toon/fantasy":"c",
    //     "/toon/highlevel":"29plus",
    //     "/toon/comic":"comic",
    // };

    // const contentRef = useRef(null);

    // const [genre, setGenre] = useState(null);
    // const [options, setOptions] = useState({});
    // const [mdSection, setMdSection] = useState(null);
    // const [filterList, setFilterList] = useState(null);
    // const [sortList, setSortList] = useState(null);

    // const [ConfirmModal, confirm] = useConfirmCustomModal();

    // const [data, dataSubmit, initData, paging, setData] = ApiGenreGenreList({
    //     bottomRef:contentRef,
    //     data:{
    //         ...options,
    //         ct_type:"toon",
    //         tab:genre,
    //     },
    //     onSuccess:(data)=>{
    //         if(data?.status === true){
    //             if(!!data?.result?.md_section && data?.result?.paging?.current_page === 1){
    //                 setMdSection(data?.result?.md_section);
    //             }
    //             if(!!data?.result?.selbox?.filter && data?.result?.paging?.current_page === 1){
    //                 setFilterList(data?.result?.selbox?.filter);
    //             }
    //             if(!!data?.result?.selbox?.sort && data?.result?.paging?.current_page === 1){
    //                 setSortList(data?.result?.selbox?.sort);
    //             }
    //             return;
    //         }
    //         if(!data){
    //             confirm({
    //                 title:"에러",
    //                 content:"통신에 실패하였습니다.새로고침 하시겠습니까?",
    //                 successBtnTitle:"새로고침",
    //                 cancleBtnTitle:"아니요.",
    //                 successAction:()=>{
    //                     dataSubmit();
    //                 }
    //             })
    //         }
    //     },
    //     onError:(e)=>{
    //         if(!data){
    //             confirm({
    //                 title:"에러",
    //                 content:"통신에 실패하였습니다.새로고침 하시겠습니까?",
    //                 successBtnTitle:"새로고침",
    //                 cancleBtnTitle:"아니요.",
    //                 successAction:()=>{
    //                     dataSubmit();
    //                 }
    //             })
    //         }
    //     }
    // });

    // useEffect(()=>{
    //     initData();
    //     setMdSection(null);
    //     // setFilterList(null);
    //     // setSortList(null);
    //     setOptions({});
    //     if(!!genre){
    //         dataSubmit();
    //     }
    // },[genre]);

    // useEffect(()=>{
    //     initData();
    //     if(!!genre){
    //         dataSubmit();
    //     }
    // },[options])

    // useEffect(()=>{
    //     initData();
    //     setGenre(genrePathList[location?.pathname]);
    // },[location?.pathname]);


    // const Sub = ()=>{
    //     return (
    //         <ToonCommonSub 
    //             ref={contentRef} 
    //             md={mdSection} 
    //             data={data} 
    //             filterList={filterList} 
    //             sortList={sortList} 
    //             options={options} 
    //             setOptions={setOptions}
    //             />
    //     )
    // }

    return (
        <>
            <Header title={"웹툰"}/>
            <MobileNav />
            {/* <!-- 서브메뉴 --> */}
            <SubMenuNav />
            {/* <!-- //서브메뉴 --> */}
            <Routes>
                <Route path='/romance' exact element={
                    <BookpalKeepAlive>
                        <Romance />
                    </BookpalKeepAlive>
                } />
                <Route path='/rofan' exact element={
                    <BookpalKeepAlive>
                        <RoFan />
                    </BookpalKeepAlive>
                } />
                <Route path='/bl' exact element={
                    <BookpalKeepAlive>
                        <BL />
                    </BookpalKeepAlive>
                } />
                <Route path='/fantasy' exact element={
                    <BookpalKeepAlive>
                        <Fantasy />
                    </BookpalKeepAlive>
                } />
                <Route path='/highlevel' exact element={
                    <BookpalKeepAlive>
                        <HighLevel />
                    </BookpalKeepAlive>
                } />
                <Route path='/comic' exact element={
                    <BookpalKeepAlive>
                        <Comic />
                    </BookpalKeepAlive>
                } />

                {/* <!-- 자유연재 md섹션위치 개발 요망, 디자인 다름 --> */}
                {/* <Route path='/freedom' exact element={<Sub />} />  */}
                {/* <Route path='/episode' exact element={<Sub />} /> */}
                <Route path='/ranking' exact element={(
                    <BookpalKeepAlive>
                        <Ranking />
                    </BookpalKeepAlive>
                )} />
                <Route path="*" element={<Navigate to="romance" replace={true} />} />
            </Routes>

            {/* <div style={{height:"100px"}}></div> */}
            {/* <Footer /> */}
            <TopBtn />
            {/* <ConfirmModal /> */}
        </>
    )
}



export default ToonSub;