import { useState, useEffect } from "react";
import { useBookpalNavigate } from "component/common/custom/BookpalLink";
import { getAppsDataWithUserAgent, useIsMobile } from "utils/Common";
import { ApiCommonReport } from "api/Common";
import useAlertModal from "component/common/modal/useAlertModal";
import useSelectModal from "component/common/modal/useSelectModal";

import SubNav from "component/common/nav/SubNav";

import style from "./PopupDeclare.module.scss";
import { useLocation } from "react-router-dom";
import { appSetClose, isApp } from "utils/AppUtil";


function PopupDeclare(){

    const navigate = useBookpalNavigate();
    const location = useLocation();
    const isMobile = useIsMobile();

    const user_agent = getAppsDataWithUserAgent();
    const agent_device_id = user_agent?.device_id;

    const [info, setInfo] = useState(null);

    const queryParams = new URLSearchParams(location.search);

    const type = queryParams.get("type");
    const chapter_id = queryParams.get("chapter_id");
    const reply_id = queryParams.get("reply_id");
    const comment_reply_id = queryParams.get("comment_reply_id");


    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [agree, setAgree] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    const [AlertModal, alert] = useAlertModal();

    const categoryData = {
        "0":"카테고리를 선택해주세요.",
        "1":"부적절한 홍보",
        "2":"무의미한 도배글",
        "3":"성희롱",
        "4":"욕설",
        "5":"비방",
        "6":"반사회적 게시글",
        "7":"저작권법 위반 게시글",
        "8":"작품 신고",
    };
    const [CategoryModal, categoryModalShow, categorySelectKey, initCategory] = useSelectModal(categoryData, "0");

    const submitReport = ApiCommonReport({
        onSuccess:(data)=>{
            if(data?.status === true){
                alert("신고하기","접수가 완료되었습니다.\n신고하신 내용은 운영 정책에 따라 처리됩니다. ", ()=>{
                    // navigate("/mypage_menu/help/declare_history", {replace:true});
                    window.self.close();
                    if(isApp()){
                        appSetClose();
                    }
                }, false);
                return;
            }
            alert("신고하기","신고가 실패하였습니다. 다시 시도해 주시기 바랍니다.");
        },
        onError:(e)=>{
            alert("신고하기","통신에 실패하였습니다. 문제가 지속될 경우 관리자에게 문의해 주시기 바랍니다.");
        }
    });

    
    const handlerSubmitReport = () => {
        submitReport({
            "type" : type,
            "reply_cid" : chapter_id,
            "reply_id" : reply_id,
            "comment_reply_id" : comment_reply_id,
            "step" : categorySelectKey,
            "title" : title,
            "content" : content,
            // "device_id" : agent_device_id ?? null,
        });
    }

    useEffect(()=>{
        if(
            categorySelectKey !== "0"
            && title !== ""
            && content !== ""
            && agree === true
        ){
            setIsSubmit(true);
            return;
        }

        setIsSubmit(false);
    },[categorySelectKey, title, content, agree]);

    

    return (
        <>
            {/* // <!-- 서브메뉴 --> */}
            {/* <div id="navMenu" className="color-bg-01 title_header">
                <div className="title_menu wrapper">
                    <div className="btns left_btns"></div>
                    <div className="title-04 color-txt-normal text_overflow">신고하기</div>
                    <div className="btns right_btns">
                        <button type="button" className="close_btn"></button>
                    </div>
                </div>
            </div> */}
            <SubNav 
                name={"신고하기"}
                usePc={false} 
                useMobile={true} 
                useBtnClose={true}
                // usePcRightBtns={true}
                // useBtnPrev={true}
                // useBtnAdult={mbInfo?.is_login && mbInfo?.is_cert && mbInfo?.is_adult}
                // onPrev={()=>{
                //     navigate("/");
                // }}
                // statAdult={true}
                setTop={'0px'} 
                // setPcTop={'64px'} 
            />
            {/* <!-- //서브메뉴 -->  */}
            
            <div id="container" className={"s_wrap wrapper " + style['container']}>
                <section className="declaration_box">
                    <p className="title color-txt-normal title-04">신고하기</p>
                    <div className={"area_box color-bg-01 radius-lg color-border-01 border-soild border-line " + style['area_box']}>
                        <ul className={"input_box " + style['input_box']}>
                            <li>
                                <p className={"input_txt body-01 color-txt-01 " + style['input_txt']}>구분</p>
                                {/* <!-- 선택전 --> */}
                                <div className="select_box text_input border-line border-soild radius-sm color-bg-01 color-border-02">
                                    <button type="button" onClick={()=>{
                                        categoryModalShow();
                                    }}>
                                        <span className={"body-03 " + (categorySelectKey === "0"?"color-txt-muted":"color-txt-normal")}>{categoryData[categorySelectKey]}</span>
                                    </button>
                                </div>
                                {/* <!-- 선택후 --> */}
                                {/* <!-- <div className="select_box text_input border-line border-soild radius-sm color-bg-01 color-border-02">
                                    <button type="button">
                                        <span className="body-03 color-txt-normal">부적절한 홍보</span>
                                    </button>
                                </div> --> */}
                            </li>
                            <li>
                                <p className="input_txt body-01 color-txt-01">제목</p>
                                <div className="text_input border-line border-soild radius-sm color-bg-01 color-border-02">
                                    <input 
                                        type="text" 
                                        placeholder="제목을 입력해주세요." 
                                        className="color-txt-normal body-03" 
                                        value={title}
                                        onChange={(e)=>{setTitle(e.currentTarget.value)}}
                                        />
                                    <p className="right_btns">
                                        <span className="btnClear"></span> 
                                    </p>
                                </div>
                            </li>
                            <li>
                                <p className="input_txt body-01 color-txt-01">내용</p>
                                <div className="text_input border-line border-soild radius-sm color-bg-01 color-border-02">
                                    <textarea 
                                        placeholder="불법적인 내용이거나,서비스 이용목적에 부합하지 않는 경우 신고해주세요." 
                                        className="color-txt-normal body-03-long"
                                        value={content}
                                        onChange={(e)=>{setContent(e.currentTarget.value)}}
                                        ></textarea>
                                </div>
                            </li>
                        </ul>
                        <ul className={"info_txt " + style['info_txt']}>
                            <li>
                                <p className={"text body-02-long color-txt-01 " + style['text']}>신고사항은 운영정책에 따라 처리되며,허위신고시 이용에 제한을 받을수 있습니다.</p>
                                <p className={"text body-02-long color-txt-01 " + style['text']}>신고 답변은 평일 10시 ~18시 (공휴일, 주말 제외)에 처리됩니다.</p>
                            </li>
                            <li>
                                <p className="title btn-03-active color-txt-01">[개인정보 수집·이용에 대한 안내]</p>
                                <p className={"text body-02-long color-txt-01 " + style['text']}>(주)북팔은 이용자 문의를 처리하기 위해 다음과 같이 개인정보를 수집 및 이용하며, 이용자의 개인정보를 안전하게 취급하는데 최선을 다하고 있습니다.</p>
                                <div className={"table_box color-border-01 border-top-line border-soild " + style['table_box']}>
                                    <p className="thead color-bg-02">
                                        <span className="color-txt-01 title-sub-02-b">수집항목</span>
                                        <span className="color-txt-01 title-sub-02-b">수집목적</span>
                                        <span className="color-txt-01 title-sub-02-b">보유기간</span>
                                    </p>
                                    <p className="tbody color-border-01 border-top-line border-bottom-line border-soild">
                                        <span className="body-01-long color-txt-03">아이디, E-mail, 모바일 단말기 정보, 문의/답변 내용에 포함된 개인정보</span>
                                        <span className="body-01-long color-txt-03">신고사항 확인 및 해결</span>
                                        <span className="body-01-long color-txt-03">1년간 보관 후 파기</span>
                                    </p>
                                </div>
                            </li>
                            <li>
                                <p className={"text body-02-long color-txt-01 " + style['text']}>더 자세한 내용에 대해서는 <b>개인정보처리방침</b>을 참고하시기 바랍니다.</p>
                                <p className={"text body-02-long color-txt-01 " + style['text']}>이용자는 본 동의를 거부할 수 있으나, 미동의 시 문의 접수가 불가능합니다.</p>
                            </li>
                        </ul>
                        <div className={"check_event " + style['check_event']}>
                            <label htmlFor="agree_declare" className="checkbox_round check-type-01 medium label_on">
                                <input 
                                    type="checkbox" 
                                    id="agree_declare"
                                    checked={agree}
                                    onClick={(e)=>{
                                        setAgree(e.currentTarget.checked)
                                    }}
                                    />
                                <span className="box"></span>
                                <span className="txt color-txt-04">개인정보 수집 및 이용에 동의합니다.</span>
                            </label>
                        </div>
                        <div className="fixed_button">
                            {/* <!-- 정상적 입력값 모두 입력상태 disabled="disabled" 제거 --> */}
                            <button 
                                type="button" 
                                className="button btn_01 filled btn-05 white-always txt" 
                                disabled={!isSubmit}
                                onClick={()=>{
                                    handlerSubmitReport();
                                }}
                                >접수하기</button>
                        </div>
                    </div>
                </section>
            </div>
            <CategoryModal />
            <AlertModal />
        </>
    )
}




export default PopupDeclare;