import { Link, useLocation } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import BookpalLink from 'component/common/custom/BookpalLink';
import { useMemberInfo } from 'utils/State';
import useAlertModal from 'component/common/modal/useAlertModal';
import { useRef, useEffect } from 'react';

function SubMenuNav(props){
    const location = useLocation();
    const memberInfo = useMemberInfo();
    const [AlertModal, alert] = useAlertModal();

    const swiperRef = useRef(null);
    // const page = props.page || "";
    const page = location.pathname;

    const pageList = {
        "/toon":0,
        "/toon/romance":1,
        "/toon/rofan":2,
        "/toon/bl":3,
        "/toon/fantasy":4,
        "/toon/highlevel":5,
        "/toon/ranking":6,
        "/toon/comic":7,
    };

    useEffect(()=>{
        if (swiperRef.current) {
            swiperRef.current.swiper.slideTo(pageList[page], 300);
        }
    },[page]);
// alert(page)
    return (
        <>
        <div id="navMenu" className="color-bg-01 type_header">
            <Swiper className="swiper-container snbSwiper wrapper btn-04 txt color-txt-muted"
                ref={swiperRef}
                slidesPerView={'auto'}
                preventClicks={true}
                preventClicksPropagation={false}
                observer={true}
                observeParents={true}
                slideToClickedSlide={true}
            >
                <SwiperSlide className={"swiper-slide " + (page=="/toon"?"on":"")}>
                    <BookpalLink to={"/toon"}>
                        <span className="color-btn-01 border border-soild">추천</span>
                    </BookpalLink>
                </SwiperSlide>
                <SwiperSlide className={"swiper-slide " + (page=="/toon/romance"?"on":"")}>
                    <BookpalLink to={"/toon/romance"}>
                        <span className="color-btn-01 border border-soild">로맨스</span>
                    </BookpalLink>
                </SwiperSlide>
                <SwiperSlide className={"swiper-slide " + (page=="/toon/rofan"?"on":"")}>
                    <BookpalLink to={"/toon/rofan"}>
                        <span className="color-btn-01 border border-soild">로판</span>
                    </BookpalLink>
                </SwiperSlide>
                <SwiperSlide className={"swiper-slide " + (page=="/toon/bl"?"on":"")}>
                    <BookpalLink to={"/toon/bl"}>
                        <span className="color-btn-01 border border-soild">BL</span>
                    </BookpalLink>
                </SwiperSlide>
                <SwiperSlide className={"swiper-slide " + (page=="/toon/fantasy"?"on":"")}>
                    <BookpalLink to={"/toon/fantasy"}>
                        <span className="color-btn-01 border border-soild">판타지</span>
                    </BookpalLink>
                </SwiperSlide>
                <SwiperSlide className={"swiper-slide " + (page=="/toon/highlevel"?"on":"")}>
                    <BookpalLink 
                        to={"/toon/highlevel"}
                        check={{
                            view_type:memberInfo?.view_type,
                            work_adult:true,
                        }}
                        alert={alert}>
                        <span className="color-btn-01 border border-soild">29+</span>
                    </BookpalLink>
                </SwiperSlide>
                <SwiperSlide className={"swiper-slide " + (page=="/toon/ranking"?"on":"")}>
                    <BookpalLink to={"/toon/ranking"}>
                        <span className="color-btn-01 border border-soild">랭킹</span>
                    </BookpalLink>
                </SwiperSlide>
                <SwiperSlide className={"swiper-slide " + (page=="/toon/comic"?"on":"")}>
                    <BookpalLink to={"/toon/comic"}>
                        <span className="color-btn-01 border border-soild">만화</span>
                    </BookpalLink>
                </SwiperSlide>
            </Swiper>
        </div>
        <AlertModal />
        </>
    );
}



export default SubMenuNav;