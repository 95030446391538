import { useEffect, useRef, useState } from 'react';
import { useIsMobile } from 'utils/Common';
import { ApiBookUseDetail } from 'api/Book';
import { useParams, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import BookpalLink from 'component/common/custom/BookpalLink';
import { runPopupOrder } from 'utils/Common';
import style from './style/Contents.module.scss';

import Header from 'component/common/header/Header';
import SubNav from 'component/common/nav/SubNav';
import Midnight from './component/promotion/Midnight';
import PlusFree from './component/promotion/PlusFree';
import PlusOne from './component/promotion/PlusOne';
import Bind from './component/promotion/Bind';
import Gift from './component/promotion/Gift';
import Hotdeal from './component/promotion/Hotdeal';
import Review from './component/promotion/Review';
import EpubHotdeal from './component/promotion/EpubHotdeal';
import useAlertModal from 'component/common/modal/useAlertModal';
import ContentsDetailGift from './ContentsDetailGift';
import ContentsDetailUse from './ContentsDetailUse';
import { isApp } from 'utils/AppUtil';


function ContentsDetail(){

    const [page, setPage] = useState("gift");
    const isMobile = useIsMobile();

    return (
        <>
            <Header useMobile={false} pcTop={"-64px"}/>
            {/* <MobileNav /> */}
            <SubNav 
                name="이 작품 이용내역" 
                useBtnHome={true} 
                usePc={true}
                useMobile={true}
                useBtnPrev={true}
                setTop={"0px"}
                setPcTop={"0px"}
                setPosition={"sticky"}
                 />

            <div 
                id="subDepth" 
                className="color-bg-01 type_header" 
                style={{position:"sticky",top:(isApp()?"0px":isMobile?"48px":"64px")}}
                >
                <div className="swiper-container swiper-initialized swiper-horizontal swiper-backface-hidden">
                    <div className="swiper-wrapper txt color-txt-muted" id="swiper-wrapper-fda9f99ee1b1648a" aria-live="polite">
                    {/* <!-- 클릭시 className="on" 추가--> */}
                    <div className={"swiper-slide " + (page==="gift"?"on":"")} role="group" aria-label="1 / 2">
                        <BookpalLink
                            onClick={(e)=>{
                                e.preventDefault();
                                setPage("gift");
                            }}>
                            <span className="color-btn-01 border border-soild btn-04">받은혜택</span>
                        </BookpalLink>
                    </div>
                    <div className={"swiper-slide " + (page==="use"?"on":"")} role="group" aria-label="2 / 2">
                        <BookpalLink
                            onClick={(e)=>{
                                e.preventDefault();
                                setPage("use");
                            }}>
                            <span className="color-btn-01 border border-soild btn-04">사용내역</span>
                        </BookpalLink>
                    </div>
                    </div>
                <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
            </div>
            
            {page === "gift" ? 
                <ContentsDetailGift />
            :null}

            {page === "use" ? 
                <ContentsDetailUse />
            :null}
        </>
    )
}



export default ContentsDetail;