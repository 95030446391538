import { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BookpalLazyLoadImage from '../custom/BookpalLazyLoadImage';
import { ImgDefault } from 'utils/Images';
import style from './SmallBanner.module.scss';
import { Link } from 'react-router-dom';
import BookpalLink from '../custom/BookpalLink';
import { ApiCommonBannerClick } from 'api/Common';

function SmallBanner({ item, data }){
    const swiperRef = useRef(null);

    const bannerClickSubmit = ApiCommonBannerClick({
        onSuccess:(data)=>{
            console.log(data);
        },
        onError:(e)=>{

        }
    });

    const handlerBannerClick = (id)=>{
        if(id === "0" || id === null){
            return;
        }
        // bannerClickSubmit({
        //     type:"small",
        //     id:id,
        // });
    }

    if(!data || !Array.isArray(data)){
        return <></>
    }

    return (
        <section className="section small_banner wrapper">
            <div className="list">
                {/* <div className="swiper mySwiper"> */}

                <Swiper className="swiper mySwiper" 
                    ref={swiperRef}
                    slidesPerView={'auto'}
                    spaceBetween={4}
                    watchOverflow={true}
                    observer={true}
                    observeParents={true}
                    breakpoints={{
                        769: {
                            freeMode : true, 
                            slidesPerView : 'auto',
                        },
                        991: {
                            freeMode : true, 
                            slidesPerView : '2',
                        },
                    }}
                >
                    {
                        data.map((item, index)=>{

                            return (
                                <SwiperSlide key={index}>
                                    {/* <a href="#"> */}
                                    <BookpalLink to={item?.link}>
                                        <div className="cover_img radius-sm" style={{background: item?.bg_color}}>
                                            {/* <img src={item?.image} /> */}
                                            {/* <BookpalLazyLoadImage 
                                                placeholderSrc={ImgDefault("small_banner")}
                                                // effect="blur"
                                                src={item?.image}
                                                // style={{width:"100px",height:"150px"}}
                                                // style={defaultImageStyle}
                                                onError={(e)=>{
                                                    e.currentTarget.src = ImgDefault("small_banner");
                                                }}
                                                /> */}
                                            <BookpalLazyLoadImage 
                                                // adult={v?.adult}
                                                defaulttype={"small_banner"}
                                                locktype={"small_banner"}
                                                src={item?.image}
                                                background={"#ffffff00"}
                                                data-id={item?.id}
                                                onClick={(e)=>{
                                                    // console.log(123)
                                                    const id = e.currentTarget.getAttribute("data-id");
                                                    handlerBannerClick(id)
                                                }}
                                                // style={defaultImageStyle}
                                            />
                                        </div>
                                        {!!item?.badge_txt && item?.badge_txt != "" ? <label className="label white-always txt btn-02 black_opacity-80" style={{backgroundColor:"#000000cc"}}>{item?.badge_txt}</label> : null}
                                    </BookpalLink>
                                    {/* </a> */}
                                </SwiperSlide>
                            )
                        })
                    }
                    
                </Swiper>
                {/* </div> */}
                <div className={"arrow_btns " + style['arrow_btns']}>
                    <div 
                        className="swiper-button-next color-bg-01 box-shadow-xs color-border-01"
                        onClick={() => swiperRef.current.swiper.slideNext()}
                    ><img src="/images/chevron_right.svg" /></div>
                    <div 
                        className="swiper-button-prev color-bg-01 box-shadow-xs color-border-01"
                        onClick={() => swiperRef.current.swiper.slidePrev()}
                    ><img src="/images/chevron_left.svg" /></div>
                </div>
            </div>
        </section>
    )
}



export default SmallBanner;