import style from '../../style/Contents.module.scss';


function PlusFree(props){

    const data = props?.data || null;
    const bid = props?.bid || null;
    const onAction = props?.onAction || (()=>{});




    if(data === null){
        return <></>
    }

    return (
        <>
            <div className={"left " + style['left']}>
                <p className={"icon " + style['icon'] + " " + style['icon_plus']} id="icon_plus"></p>
                <p className={"title info " + style['title'] + " " + style['info']}>
                    {/* <span className="color-txt-01 body-03">총 {data?.item_count}화 플러스무료</span>  */}
                    <span className="color-txt-01 body-03">{data?.content}</span>
                    <span className="color-txt-05 txt body-03">{data?.event_end_time_txt}</span>
                </p>
            </div>
        </>
    )
}



export default PlusFree;