import { useState } from 'react';
import { ImgBadge } from 'utils/Images';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import { ApiBuyBuyCheck, ApiBuyBook } from 'api/Buy';
import { runPopupOrder } from 'utils/Common';
import { SetState, GetState, useMemberInfo } from 'utils/State';
import style from '../../style/Contents.module.scss';
import useAlertModal from 'component/common/modal/useAlertModal';


function Hotdeal(props){

    const data = props?.data || null;
    const bid = props?.bid || null;
    const onAction = props?.onAction || (()=>{});
    const buyConfirm = props?.buyConfirm || (()=>{});
    const buyAction = props?.buyAction || (()=>{});

    const memberInfo = useMemberInfo();
    const location = useLocation();



    const navigate = useBookpalNavigate();

    const setState = SetState();
    const getState = GetState();

    const viewerRun = getState.useGetViewerInfoRun();

    const [discountInfo, setDiscountInfo] = useState(data?.discount_info || null );

    const [buyCheck, setBuyCheck] = useState(null);

    const [AlertModal, alert] = useAlertModal();

    const buySubmit = ApiBuyBook({
        onSuccess:(_data)=>{
            if(_data?.status === true && _data?.result?.status === true){
                // getViewPageLink();
                setDiscountInfo({...discountInfo, status:false});
                setState.setToast({
                    title:"쿠폰 "+_data?.result?.coupon_discount+"개가 사용되었습니다.",
                });
                buyAction();
                return;
            }
            switch(_data?.code){
                case "520":
                    buyConfirm({
                        title:"구매 안내",
                        bookTitle:buyCheck?.buy_info?.title,
                        content:buyCheck?.buy_info?.no + "화를 열람하시겠습니까?",
                        isPay:false,
                        currentConpon:_data?.result?.my_coupon_cnt,
                        useConpon:buyCheck?.buy_info?.price,
                        useVoucher:false,
                        successBtnTitle:"쿠폰 충전하러 가기",
                        cancleBtnTitle:"다음에 할게요",
                        successAction:()=>{
                            runPopupOrder();
                        },
                    });
                    break;
                case "513":
                    alert("구매 안내","무료 회차입니다.");
                    break;
                case "512":
                    alert("구매 안내","구매 가능한 회차가 없습니다.");
                    break;
                case "511":
                    alert("구매 안내","이미 구매한 회차입니다.");
                    break;
                default:
                    if(_data?.result?.status === false){
                        buyConfirm({
                            title:"구매 안내",
                            bookTitle:buyCheck?.buy_info?.title,
                            content: "핫딜 구매",
                            isPay:false,
                            currentConpon:_data?.result?.my_coupon_cnt+"",
                            useConpon:_data?.result?.hotdeal_coupon_cnt,
                            useVoucher:false,
                            successBtnTitle:"쿠폰 충전하러 가기",
                            cancleBtnTitle:"다음에 할게요",
                            successAction:()=>{
                                runPopupOrder();
                            },
                        });
                    }
                    // if(_data?.result?.alert){
                    //     setState.setToast({
                    //         title:_data?.result?.alert?.content,
                    //     });
                    // }
                    break;
            }
        },
        onError:(e)=>{

        }
    })

    const checkSubmit = ApiBuyBuyCheck({
        onSuccess:(_data)=>{
            if(_data?.status === true){
                const result = _data?.result;
                const buy_info = result?.buy_info;
                setBuyCheck(result);

                // if(buy_info?.is_buy){
                //     getViewPageLink();
                //     return;
                // }

                // if(buy_info?.is_free){
                //     getViewPageLink();
                //     return;
                // }
                // const my_voucher = result?.my_voucher;
                // const isPay = result?.my_coupon_cnt > 0;
                const useVoucher = buyConfirm({
                    title:"구매 안내",
                    bookTitle:buy_info?.title,
                    isPayView:false,
                    // content:buy_info?.no + "화를 열람하시겠습니까?",
                    isPay:true,
                    // currentConpon:buy_info?.user_coupon,
                    useConpon:discountInfo?.coupon_discount,
                    hotdealAllChapterCnt:discountInfo?.coupon_sell,
                    hotdealAllCouponCnt:discountInfo?.coupon_discount,
                    hotdealDiscount:discountInfo?.discount,
                    isHotdeal:true,
                    useVoucher:false,
                    successBtnTitle:"핫딜 구매",
                    success2BtnTitle:"",
                    successAction:(useVoucher)=>{
                        console.log("useVoucher",useVoucher);
                        buySubmit({
                            bid:bid,
                            ct_type:"hotdeal",
                        });
                    },
                    success2Action:()=>{
                        // buySubmit({
                        //     bid:data?.bid,
                        //     cid:data?.cid,
                        //     epub:data?.epub,
                        //     buy_type:"v_rent",
                        // });
                    },
                });
            } else {
                //이미 구매한 핫딜

            }
        },
        onError:(e)=>{

        },
    });

    const handlerCheckPay = ()=>{

        if(!memberInfo?.is_login){
            alert("알림","로그인 해주세요.",()=>{
                navigate("/login",{state:{return_path:location.pathname}});
            });
            return;
        }
        // if(is_paid === "true"){
        //     getViewPageLink();
        //     return;
        // }

        // if(epubData?.cid === 0){
        //     alert("안내","마지막 회차 입니다.");
        //     return;
        // }

        checkSubmit({
            bid:bid,
            // cid:epubData?.cid,
            ct_type:"hotdeal",
        });
    }


    const getViewPageLink = ()=>{
        // navigate("/view/"+type+"/"+epubData?.cid);
    }


    if(data === null){
        return <></>
    }

    //받기 후 노출 생략
    if(!discountInfo?.status){
        return null;
    }
    
    return (
        <>
        <li>
            <div className={"left " + style['left']}>
                <p className={"icon " + style['icon'] + " " + style['icon_deal']} id="icon_deal"></p>
                <p className={"title info " + style['title'] + " " + style['info']}>
                    <span className="color-txt-01 body-03">핫딜 {discountInfo?.discount}% 할인</span> 
                    <span className="color-txt-05 txt body-03">{discountInfo?.event_end_time_txt}</span>
                </p>
            </div>
            {discountInfo?.status ? 
            <p className={"right " + style['right']}
                ><button 
                    type="button" 
                    className={"button btn_03 round radius-full small label_on outlined " + style['button']}
                    onClick={(e)=>{
                        handlerCheckPay();
                    }}
                    ><span className="btn-02 color-txt-04">구매하기</span></button></p>
            :null}
            {!discountInfo?.status ? 
            <p className="title-sub-02-m color-btn-01 txt">구매완료</p>
            :null}
        </li>
        <AlertModal />
        </>
    )
}



export default Hotdeal;