import { useState } from 'react';
import { Modal } from 'react-bootstrap';


import style from './useAgreeModal.module.scss';

function useAgreeModal(){

    const [showModal, setShowModal] = useState(false);
    const [txt, setTxt] = useState([]);
    const [title, setTitle] = useState([]);

    const handleClose = () => setShowModal(false);
    const handleShow = (modifyTitle, modifyTxt) => {

        // const tmp = [];
        // modifyTxt.split('\n').map((line, index) =>{ 
        //     tmp.push(
        //         <>
        //             {line}<br />
        //         </>
        //     );
        // });

        // setTxt(tmp);
        setTxt(modifyTxt);
        setTitle(modifyTitle);
        setShowModal(true);  
    } 

    const ModalTmpl =()=>{ 
        return (
        <>
            <Modal 
                // className="black_opacity-60" 
                backdropClassName="black_opacity-60 opacity-10"
                dialogClassName="modal-dialog-centered large"
                contentClassName="radius-xxl color-bg-modal"
                show={showModal}
                animation={false} >
                <Modal.Header>
                    <div className="title_menu">
                        <div className="title title-03 color-txt-normal text_overflow">{title}</div>
                        <button type="button" className="close_btn" data-bs-dismiss="modal" onClick={handleClose}></button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div 
                        className={"scroll_box area_box " + style['area_box']}
                        dangerouslySetInnerHTML={{ __html: txt }}>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )};
    
    return [ModalTmpl, handleShow];
}


export default useAgreeModal;