import { useNavigate, useLocation } from "react-router-dom";
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import { isApp, appSetMove, appSetClose } from 'utils/AppUtil';
import { getBookpalLink } from 'utils/BookpalLinkUtil';
import { ApiAuthConfirmPw, ApiAuthConfirmPwNew } from "api/Auth";
import { decryptOld } from "utils/Crypt";

import SubNav from "component/common/nav/SubNav";

import style from '../style/User.module.scss';

import success_icon from 'assets/images/user/success_icon.png';
import { useEffect, useState } from "react";
import { useMemberInfo } from "utils/State";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

function ConfirmPw(){

    const navigate = useBookpalNavigate();
    const memberInfo = useMemberInfo();
    const location = useLocation();
    const query = useQuery();
    const successPath = (query.get('path') === "edit" ? "/mypage/edit":null) ?? location.state;

    const [password, setPassword] = useState("");
    const [pwDisable, setPwDisable] = useState(true);
    const [error, setError] = useState("");

    const confirmPw = ApiAuthConfirmPwNew({
        onSuccess: (data)=>{
            // console.log(data);
            if(data?.status === true){
                const timestampInSeconds = Math.floor(Date.now() / 1000);
                // navigate(successPath, {state:{confirmEdit:true,expire:timestampInSeconds + (60*30)}});

                const code = data?.result?.code;
                if(!code){
                    setError("시스템에 문제가 있습니다. 관리자에게 문의하세요.");
                }
                
                const code_json = decryptOld( code );
                
                if(!code_json || code_json == ""){
                    setError("시스템에 문제가 있습니다. 관리자에게 문의하세요.");
                    return;
                }

                const code_data = JSON.parse( code_json );

                if(code_data?.mb_id == memberInfo?.mb_id && code_data?.expire > timestampInSeconds){
                    navigate(successPath, {state:{confirmEdit:true,expire:timestampInSeconds + (60*30)}});
                    return;
                }
                setError("시스템에 문제가 있습니다. 관리자에게 문의하세요.");
            } else {
                if(data?.code === "fail_cnt"){
                    setError(data?.message);
                    return;
                }
                setError("비밀번호가 일치하지 않습니다.");
            }
        },
        onError:(e)=>{
            // console.log(e);
            setError("통신이 실패하였습니다. 다시 시도해 주세요.");
        }
    });

    const submitConfirmPw = ()=>{
        confirmPw({
            password: password
        });
    }

    const pwOnChange = (e) => {
        setPassword(e.target.value);
        setError("");
        if(e.target.value != ""){
            setPwDisable(false);
        } else {
            setPwDisable(true);
        }
    }

    // console.log(location.state);
    // console.log("confirm_pw");
    useEffect(()=>{
        // console.log(successPath);
        if(!successPath){
            alert('잘못된 접근 입니다.');
            navigate(-1);
        }
    },[]);



    return (
        <>
            <SubNav 
                name="비밀번호 확인" 
                usePc={false} 
                useMobile={true} 
                setTop={'0px'} 
                useBtnPrev={true} 
                useBtnClose={false}
                onClose={()=>{}}
            />
            
            {/* <!-- container --> */}
            <div id="container" className={"s_wrap wrapper " + style['s_wrap'] + " " + style['wrapper'] + " " + style['container']}>
                <section className={"comfirm_box " + style['comfirm_box']} style={{margin:"0"}}>
                    <p className={"title color-txt-normal title-04 " + style['title']}>비밀번호 확인</p>
                    <div className={"area_box color-bg-01 radius-lg color-border-01 border-soild border-line " + style['area_box']}>
                        <div className={"logo_title " + style['logo_title']}>
                            <p className={"logo " + style['logo']}></p>
                            <p className={"body-01 color-txt-muted label_txt " + style['label_txt']}>당신의 상상이 이야기가 되는 곳</p>
                        </div>
                        <div className={"text_box body-03-long color-txt-normal " + style['text_box']}>
                            개인정보 보호를 위해 비밀번호 <br />확인 후 이용하실 수 있습니다. 
                        </div>
                        <ul className={"input_box " + style['input_box']}>
                            {/* <!-- 입력한 이메일이 유효한 값이 아닐 경우 error_text 추가 --> */}
                            <li className={error == "" ? "" : "error_text"}>
                                <div className={"text_input border-line border-soild radius-sm color-bg-01 color-border-02 " + style['text_input']}>
                                    <input 
                                        type="password" 
                                        placeholder="비밀번호를 입력해주세요." 
                                        className="color-txt-normal body-03" 
                                        value={password}
                                        onChange={pwOnChange}
                                        />
                                    <p className={"right_btns " + style['right_btns']}>
                                        <span className={"btnClear " + style['btnClear']}></span>
                                        <span className={"Error " + style['Error']}></span>
                                    </p>
                                </div>
                                <p className={"bottom_txt caption-02 color-txt-05 " + style['bottom_txt']}>{error}</p>
                            </li>
                        </ul>
                        <p className={"button_box " + style['button_box']}>
                            {/* <!-- 비활성화일땐  disabled='disabled' --> */}
                            <button type="button" 
                                className={"radius-sm btn-04 txt btn_01 large label_on filled button " + style['label_on'] + " " + style['filled'] + " " + style['button']} 
                                disabled={pwDisable}
                                onClick={submitConfirmPw}
                                >확인</button> 
                            <button 
                                type="button" 
                                className={"radius-sm btn-04 txt btn_01 large label_on outlined button " + style['label_on'] + " " + style['outlined'] + " " + style['button']}
                                onClick={()=>{
                                    appSetClose();
                                    navigate(-1);
                                }}
                                >취소</button> 
                        </p>
                        <div className={"find_pw_box " + style['find_pw_box']}> 
                            <p className="color-txt-05 title-book-02-m">비밀번호가 생각나지 않는다면?</p>
                            <button 
                                type="button" 
                                onClick={(e)=>{navigate("/find_pw")}}
                                className={"button color-txt-05 title-book-02-m " + style['button']}>비밀번호 찾기</button>
                        </div>
                    </div>        
                </section>
            </div>
            {/* <!-- container --> */}
        </>
    );
}



export default ConfirmPw;