import { Link } from "react-router-dom";
import BookpalLink from "component/common/custom/BookpalLink";
import { GetState } from 'utils/State';
import { isApp } from "utils/AppUtil";
import style from './Event.module.scss';
import { useIsMobile } from "utils/Common";

function SubMentNav(props){

    const to = props.to || "";

    const isMobile = useIsMobile();

    const getState = GetState();
    const dot = getState.useGetDot();
    
    return (
        <div id="subDepth" className={"color-bg-01 type_header " + style['subDepth'] + " " + style['type_header']} style={{position:"sticky",top:isApp()?"0px":isMobile?"48px":"64px"}}>
            <div className="swiper-container">
                <div className="swiper-wrapper txt color-txt-muted">
                    {/* <!-- 클릭시 className="on" 추가--> */}
                    <div className={"swiper-slide " + style['swiper-slide'] + " " + (to==="/mypage_menu/event" ? "on":"")}>
                        <BookpalLink to={"/mypage_menu/event"}>
                            <span className="color-btn-01 border border-soild btn-04">전체{dot?.event_total ? <span className="dot color-btn-01 bg radius-full"></span>:null}</span>
                        </BookpalLink>
                    </div>
                    <div className={"swiper-slide " + style['swiper-slide'] + " " + (to==="/mypage_menu/event/novel" ? "on":"")}>
                        <BookpalLink to={"/mypage_menu/event/novel"}>
                            <span className="color-btn-01 border border-soild btn-04">웹소설{dot?.event_novel ? <span className="dot color-btn-01 bg radius-full"></span>:null}</span>
                        </BookpalLink>
                    </div>
                    <div className={"swiper-slide " + style['swiper-slide'] + " " + (to==="/mypage_menu/event/toon" ? "on":"")}>
                        <BookpalLink to={"/mypage_menu/event/toon"}>
                            <span className="color-btn-01 border border-soild btn-04">웹툰{dot?.event_toon ? <span className="dot color-btn-01 bg radius-full"></span>:null}</span>
                        </BookpalLink>
                    </div>
                    <div className={"swiper-slide " + style['swiper-slide'] + " " + (to==="/mypage_menu/event/bookterview" ? "on":"")}>
                        <BookpalLink to={"/mypage_menu/event/bookterview"}>
                            <span className="color-btn-01 border border-soild btn-04">북터뷰{dot?.event_bookterview ? <span className="dot color-btn-01 bg radius-full"></span>:null}</span>
                        </BookpalLink>
                    </div>
                </div>
            </div>
        </div>
    );
}



export default SubMentNav;