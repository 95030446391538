import { Routes, Route, Navigate, useNavigate, useParams, useLocation  } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import { useIsMobile, formatDateString, formatNumber } from 'utils/Common';
import { ApiBookView } from 'api/Book';
import { getViewCntConvert, getBookStateConvert, runPopupOrder } from 'utils/Common';
import { ApiMyBookmarkSet, ApiMyBookmarkBell } from 'api/My';
import he from 'he';

import useAlertModal from 'component/common/modal/useAlertModal';

import Header from 'component/common/header/Header';
import MobileNav from 'component/common/nav/MobileNav';

import Footer from 'component/common/footer/Footer';
import TopBtn from 'component/common/button/top/TopBtn';

import SubNav from 'component/common/nav/SubNav';
import SubTabNav from './nav/SubTabNav';



import FirstEpisode from './component/FirstEpisode';
import EpisodeList from './component/EpisodeList';
import Introduction from './component/Introduction';
import News from './component/News';
import Comment from './component/Comment';


import Midnight from './component/promotion/Midnight';
import PlusFree from './component/promotion/PlusFree';
import PlusOne from './component/promotion/PlusOne';


import style from './style/Contents.module.scss';

import img_icon_heart from 'assets/images/contents/icon_heart.svg';
import img_icon_person from 'assets/images/contents/icon_person.svg';
import { useEffect, useState } from 'react';
import Bind from './component/promotion/Bind';
import Gift from './component/promotion/Gift';
import Hotdeal from './component/promotion/Hotdeal';
import Review from './component/promotion/Review';
import EpubHotdeal from './component/promotion/EpubHotdeal';
import useConfirmCustomBuyModal from 'component/common/modal/useConfirmCustomBuyModal';
import useConfirmCustomModal from 'component/common/modal/useConfirmCustomModal';
import useCoverModal from 'component/common/modal/useCoverModal';
import BookpalKeepAlive from 'component/common/custom/BookpalKeepAlive';
import { SetState, useMemberInfo } from 'utils/State';
import Epub from './component/content_home/Epub';

function ContentsHome(){

    const isMobile = useIsMobile();
    const navigate = useBookpalNavigate();
    const location = useLocation();

    const memberInfo = useMemberInfo();

    const { type, bid } = useParams();
    const [info, setInfo] = useState(null);
    const [stopTooltip, setStopTooltip] = useState(false);

    const setState = SetState();

    const [AlertModal, alert] = useAlertModal();
    const [ConfirmModal, confirm] = useConfirmCustomModal();
    const [CoverModal, cover] = useCoverModal();

    const [BuyConfirmModal, buyConfirm] = useConfirmCustomBuyModal();

    const contentStyle = {
        overflow:(isMobile ? "unset" : "hidden")
    };

    const handlerContentBookViewSubmit = ApiBookView({
        onSuccess:(data)=>{
            if(data?.status===true){
                // console.log(data)
                setInfo(data?.result);
                return;
            }
            switch(data?.code){
                case "403": 
                    // alert("알림","성인 작품입니다. 로그인 후 이용해주세요.",()=>{
                    //     // navigate(-1);
                    //     navigate("/login",{replace:true, state:{return_path:location.pathname}});
                    // }); 

                    confirm({
                        title:"알림",
                        content:"성인 작품입니다. 로그인 후 이용해주세요.",
                        successBtnTitle:"로그인",
                        cancleBtnTitle:"다음에 할게요.",
                        successAction:()=>{
                            navigate("/login",{replace:true, state:{return_path:location.pathname}});
                        },
                        cancleAction:()=>{
                            navigate(-1);
                        },
                        closeAction:()=>{
                            navigate(-1);
                        }
                    });

                    break;
                case "407": 
                    // alert("알림","성인 작품입니다. 성인인증 후 이용해주세요.",()=>{
                    //     // navigate(-1);
                    //     navigate("/cert?app_return_link="+location.pathname, {replace:true, state:{return_path:location.pathname}});
                    // }); 

                    confirm({
                        title:"알림",
                        content:"성인 작품입니다. 성인인증 후 이용해주세요.",
                        successBtnTitle:"로그인",
                        cancleBtnTitle:"다음에 할게요.",
                        successAction:()=>{
                            navigate("/cert?app_return_link="+location.pathname, {replace:true, state:{return_path:location.pathname}});
                        },
                        cancleAction:()=>{
                            navigate(-1);
                        },
                        closeAction:()=>{
                            navigate(-1);
                        }
                    });
                    break;
                default: 
                    alert("알림","잘못된 접근 입니다.",()=>{
                        navigate(-1);
                    });
                    break;
            }
        },
        onError:(e)=>{
            
        }
    })


    const handlerMoveViewer = (type, bid, _work_adult) => {

        const _to = "/view/"+type+"/"+bid+"/viewer";
        const next = false;

        // if(check){
            const view_type = memberInfo.view_type;
            const work_adult = _work_adult;
            // console.log(view_type, work_adult);
            if(work_adult === true){
                switch(view_type){
                    case "N":
                        alert("알림","작품 감상을 위해 로그인이 필요합니다.",()=>{
                            if(next && _to){
                                navigate("/login",{state:{return_path:_to}});  
                                return;
                            }
                            // navigate("/login",{state:{return_path:location.pathname}});
                            navigate("/login",{state:{return_path:location.pathname}});
                        });
                        return false;
                        break;
                    case "C":
                        //작품 감상을 위해 성인 인증이 필요합니다.
                        alert("알림","작품 감상을 위해 성인 인증이 필요합니다.",()=>{
                            if(next && _to){
                                navigate("/cert?app_return_link="+location.pathname,{state:{return_path:_to}});  
                                return;
                            }
                            navigate("/cert?app_return_link="+location.pathname, {state:{return_path:location.pathname}});
                        })
                        return false;
                        break;
                    case "D":
                        //해당 작품은 성인 작품으로 19세 미만 청소년이 이용할 수 없습니다. 
                        alert("알림","해당 작품은 성인 작품으로 19세 미만 청소년이 이용할 수 없습니다.")
                        return false;
                    default:
                    case "Y":
                        break;
                }
            } else {
                if(view_type === "N"){
                    alert("알림","작품 감상을 위해 로그인이 필요합니다.",()=>{
                        if(next && _to){
                            navigate("/login",{state:{return_path:_to}});  
                            return;
                        }
                        // navigate("/login",{state:{return_path:location.pathname}});
                        navigate("/login",{state:{return_path:location.pathname}});
                    });
                    return false;
                }
            }
        // }

        navigate("/view/"+type+"/"+bid+"/viewer");
    }
    
    const handlerReload = ()=>{
        setInfo(null);
        
        handlerContentBookViewSubmit({
            bid:bid,
            epub: (type === "epub"),
        });
    }

    useEffect(()=>{
        // window.scrollTo({ top: 0});
        // console.log("type, bid",type, bid)
        // handlerContentBookViewSubmit({
        //     bid:bid,
        //     epub: (type === "epub"),
        // });
    },[]);

    useEffect(()=>{
        setInfo(null);
        // console.log("type, bid",type, bid)
        handlerContentBookViewSubmit({
            bid:bid,
            epub: (type === "epub"),
        });
    },[type, bid])

    const getTypeName = (type) =>{
        switch(type){
            case "novel": return "웹소설";
            case "epub": return "단행본";
            case "toon": return "웹툰";
            case "comic": return "웹툰";
        }
    }

    const bookmarkSetSubmit = ApiMyBookmarkSet({
        onSuccess:(data)=>{
            if(data?.status === true){
                // console.log(data);
                const tmp = info;

                if(data?.result?.bookmark?.is_my_bookmark ===true){
                    tmp.is_bell = true;
                    tmp.is_bookmark = true;
                    setState.setToast({
                        title:"관심 작품 등록되었습니다.\n작품 업데이트 알림을 보내드릴게요.",
                    });
                }
                if(data?.result?.bookmark?.is_my_bookmark ===false){
                    tmp.is_bell = false;
                    tmp.is_bookmark = false;
                    setState.setToast({
                        title:"관심 작품 해제하였습니다.\n작품 알림을 받으실 수 없습니다.",
                    });
                }
                
                setInfo(tmp);
            }
        },
        onError:(e)=>{

        }
    });

    const handlerBookmakrSetSubmit = (bid)=>{
        bookmarkSetSubmit({
            bid:bid,
            epub:(type === "epub"),
        })
    }

    const bookmarkBellSubmit = ApiMyBookmarkBell({
        onSuccess:(data)=>{
            if(data?.status === true){
                // console.log(data);
                const tmp = info;

                if(data?.result?.is_bell ===true){
                    tmp.is_bell = true;
                    setState.setToast({
                        title:"해당 작품 업데이트 알림이\n설정되었습니다. ",
                    });
                }
                if(data?.result?.is_bell ===false){
                    tmp.is_bell = false;
                    setState.setToast({
                        title:"해당 작품 업데이트 알림이\n해제되었습니다.",
                    });
                }
                
                setInfo(tmp);
            }
        },
        onError:(e)=>{

        }
    });

    const handlerBookmakrBellSubmit = (bid)=>{
        bookmarkBellSubmit({
            bid:bid,
            bell:!info?.is_bell,
            epub:(type === "epub"),
        })
    }

    if(info === null){
        return (
            <>
                <Header />
                <AlertModal />
                <ConfirmModal />
            </>
        )
    }

    return (
        <>
            <Header />
            {/* <MobileNav /> */}
            <SubNav 
                name="" 
                useBtnHome={true} 
                usePc={false}
                useMobile={true}
                useBtnPrev={true}
                useBtnFavorite={true}
                useBtnAlarm={info?.is_bookmark ?? false}
                useBtnDetail={true}
                setMode={"content"}
                setTop={"0px"}
                setPcTop={"64px"}
                setPosition={"fixed"}
                onFavorite={()=>{
                    if(!memberInfo?.is_login){
                        alert("알림","관심작품으로 등록하기 위해서는 로그인이 필요합니다.",()=>{
                            navigate("/login",{state:{return_path:location.pathname}});
                        });
                        return;
                    }
                    handlerBookmakrSetSubmit(info?.bid);
                }}
                onAlarm={()=>{
                    handlerBookmakrBellSubmit(info?.bid);
                }}
                onDetail={()=>{
                    // alert('1');
                    ///content_detail/:type/:bid
                    if(!memberInfo?.is_login){
                        alert("알림","작품 이용내역을 사용하기 위해 로그인이 필요합니다.",()=>{
                            navigate("/login",{state:{return_path:location.pathname}});
                        });
                        return;
                    }
                    navigate("/content_detail/"+type+"/"+bid)
                }}
                isFavorite={info?.is_bookmark ?? false}
                isAlarm={info?.is_bell ?? false}
                 />
        
            {/* <!-- container --> */}
            <div id="container" className={" " + style['container']} >
                <section className={"section detail_box wrapper clearfix template " + style['detail_box'] + " " + style['section'] + " " + style['template'] + " " + style['wrapper']}>
                    <div className={"info_box radius-xxl left color-bg-01 " + style['info_box'] + " " + style['left']}>
                        <div className={"view_top " + style['view_top']} style={{backgroundImage: `url(${info?.cover})`}}>
                            <div className={"view_con " + style['view_con']}>
                                <div 
                                    className={"cover_img radius-xs " + style['cover_img']}
                                    onClick={(e)=>{
                                        // console.log("asdf");
                                        cover(info?.cover_big);
                                    }}
                                    >
                                    {info?.adult ? <p className={"ic_adult badge_adult_normal " + style['ic_adult']}><img src="/images/ic_adult.svg" /></p> : null}
                                    <img 
                                        src={info?.cover} 
                                        className="radius-xs" 
                                        
                                        />
                                </div>
                                <ul className={"info_box radius-xs black_opacity-30 white-always txt " + style['info_box'] + " " + style['txt']}>
                                    <li>
                                        <p className={"icon_label icon_txt radius-full white_opacity-10 title-sub-01 " + style['icon_txt']}>{info?.ct_type === "toon" ? info?.toon_genre :getTypeName(info?.ct_type)}</p>
                                        <p className={"info_txt title-sub-02-b " + style['info_txt']}>{info?.chapter}{info?.ct_type === "epub"?"권":"화"}</p>
                                        {info?.free_chapter > 0 ?
                                            <p className={"info_stxt title-sub-01 txt_white_opacity-60 " + style['info_stxt']}>{info?.free_chapter}{info?.ct_type === "epub"?"권":"화"} 무료</p>
                                        :null}
                                    </li>
                                    <li>
                                        <p className={"icon_label"}><img src={img_icon_heart} /></p>
                                        <p className={"info_txt title-sub-02-b " + style['info_txt']}>{formatNumber(info?.bookmark)}</p>
                                    </li>
                                    <li>
                                        <p className="icon_label"><img src={img_icon_person} /></p>
                                        <p className={"info_txt title-sub-02-b " + style['info_txt']}>{getViewCntConvert(info?.view_cnt)}</p>
                                    </li>
                                </ul>
                            </div>
                            <div className={"view_btns " + style['view_btns']}>
                                {/* <!-- 관심작품, 알림 클릭시 click 추가 --> */}
                                <button 
                                    type="button" 
                                    className={"button body-01 " + style['button'] + " " + style['favoriteBtn'] + " " + (info?.is_bookmark ? style['click']:"")} 
                                    id="favoriteBtn"
                                    data-bid={info?.bid}
                                    onClick={(e)=>{

                                        if(!memberInfo?.is_login){
                                            alert("알림","관심작품으로 등록하기 위해서는 로그인이 필요합니다.",()=>{
                                                navigate("/login",{state:{return_path:location.pathname}});
                                            });
                                            return;
                                        }
                                        const bid = e.currentTarget.getAttribute("data-bid");
                                        handlerBookmakrSetSubmit(bid);
                                    }}
                                    ><span className="txt_white_opacity-60">관심작품</span></button>
                                {info?.is_bookmark ? 
                                <button 
                                    type="button" 
                                    className={"button body-01 " + style['button'] + " " + style['alarmBtn'] + " " + (info?.is_bell ? style['click']:"")} 
                                    id="alarmBtn"
                                    data-bid={info?.bid}
                                    onClick={(e)=>{
                                        const bid = e.currentTarget.getAttribute("data-bid");
                                        handlerBookmakrBellSubmit(bid);
                                    }}
                                    ><span className="txt_white_opacity-60">알림</span></button>
                                :null}
                                <button 
                                    type="button" 
                                    className={"button body-01 " + style['button'] + " " + style['detailBtn']} id="detailBtn" 
                                    onClick={()=>{
                                        if(!memberInfo?.is_login){
                                            alert("알림","작품 이용내역을 사용하기 위해 로그인이 필요합니다.",()=>{
                                                navigate("/login",{state:{return_path:location.pathname}});
                                            });
                                            return;
                                        }
                                        navigate("/content_detail/"+type+"/"+bid);
                                    }}
                                    ><span className="txt_white_opacity-60">작품 이용내역</span></button>
                            </div>
                        </div>
                        
                        <div className={"view_bottom radius-xxl color-bg-01 " + style['view_bottom']}>
                            
                            <div className={"info_wrap " + style['info_wrap']}>
                                
                                
                                <div className={"info_txt " + style['info_txt']}>
                                    <p className={"color-txt-normal title-book-05-b text_overflow2 title " + style['title']}>{info?.title}</p>
                                    <p className={"writer color-txt-03 body-02 text_overflow " + style['writer']}>{info?.wr_nick}</p>
                                </div>
                                <p className={"category_list " + style['category_list']}>


                                    {info?.badge && Array.isArray(info?.badge) && info?.badge?.length > 0 ?
                                        info?.badge.map((item, index)=>{
                                            return (
                                                <span 
                                                    key={"badge_"+index} 
                                                    className="btn-01 color-txt-02 radius-full" 
                                                    style={{backgroundColor: item?.color}}>{item?.title}</span>
                                            )
                                        })
                                    :null}

                                    {/* <!-- 로맨스, 로판, BL, 판타지 (장르구분) --> */}
                                    {/* <span className="btn-01 color-txt-02 radius-full" style={{backgroundColor: "rgba(210, 167, 126, .20)"}}>{info?.genre_kor}</span> */}
                                    {/* <!-- 완결, 연재, 휴재 (연재상태구분) --> */}
                                    {/* <span className="btn-01 color-txt-02 radius-full color-bg-03">{getBookStateConvert(info?.is_rest, info?.is_finished)}</span> */}

                                    {/* <!-- 19, 29 (성인작 수위 구분) --> */}
                                    {/* {info?.adult ? 
                                        <span className="btn-01 color-txt-02 radius-full" style={{backgroundColor: "rgba(236, 42, 56, .10)"}}>{info?.adult_level}</span>
                                    :null} */}


                                    {/* <!-- 월, 화, 수,목, 금, 토, 일, 비정기, 만화 (연재웹툰 요일 구분) --> */}
                                    {/* <!-- 데이터 필요 --> */}
                                    {/* <span className="btn-01 color-txt-02 radius-full" style={{backgroundColor: "rgba(248, 178, 0, .15)"}}>월</span> */}

                                    
                                    {/* <!-- 벌써열두시?!, 연재의참맛, 이벤트, 자유연재 (프로모션 구분 1) --> */}
                                    {/* {info?.promotion_icon?.midnight ?
                                        <span className="btn-01 color-txt-02 radius-full" style={{backgroundColor: "rgba(176, 93, 227, .15)"}}>벌써열두시?!</span>
                                    :null}
                                    {info?.promotion_icon?.plusone ?
                                        <span className="btn-01 color-txt-02 radius-full" style={{backgroundColor: "rgba(176, 93, 227, .15)"}}>연재의참맛</span>
                                    :null}
                                    {info?.promotion_icon?.bind ?
                                        <span className="btn-01 color-txt-02 radius-full" style={{backgroundColor: "rgba(176, 93, 227, .15)"}}>이벤트</span>
                                    :null} */}
                                    {/* <!-- 자유연재 데이터 필요 --> */}
                                    {/* {info?.promotion_icon?. ?
                                        <span className="btn-01 color-txt-02 radius-full" style={{backgroundColor: "rgba(176, 93, 227, .15)"}}>자유연재</span>
                                    :null} */}
                                    
                                    {/* <!-- 선물함, 리뷰무료, 핫딜, 단행본 할인, 플러스무료 (프로모션 구분 2) --> */}
                                    {/* {info?.promotion_icon?.gift ?
                                        <span className="btn-01 color-txt-02 radius-full" style={{backgroundColor: "rgba(0, 183, 140, .15)"}}>선물함</span>
                                    :null}
                                    {info?.promotion_icon?.review ?
                                        <span className="btn-01 color-txt-02 radius-full" style={{backgroundColor: "rgba(0, 183, 140, .15)"}}>리뷰무료</span>
                                    :null}
                                    {info?.promotion_icon?.hotdeal ?
                                        <span className="btn-01 color-txt-02 radius-full" style={{backgroundColor: "rgba(0, 183, 140, .15)"}}>핫딜</span>
                                    :null}
                                    {info?.promotion_icon?.epubdeal ?
                                        <span className="btn-01 color-txt-02 radius-full" style={{backgroundColor: "rgba(0, 183, 140, .15)"}}>단행본 할인</span>
                                    :null}
                                    {info?.promotion_icon?.chapterfree ?
                                        <span className="btn-01 color-txt-02 radius-full" style={{backgroundColor: "rgba(0, 183, 140, .15)"}}>플러스무료</span>
                                    :null} */}
                                    
                                    
                                </p>
                                {/* <!-- 뷰어로 첫화보기 버튼 --> */}
                                {info?.is_first_view && type !== "epub" && !isMobile ? 
                                    <div className={"view_button color-btn-01 bg radius-sm " + style['view_button']}>
                                        <button 
                                            type="button" 
                                            className={"button " + style['button']} 
                                            onClick={()=>{handlerMoveViewer(type, info?.last_view?.last?.cid, info?.last_view?.last?.adult)}}>
                                            <p className="white-always txt btn-04 title">뷰어로 첫 {type === "epub" ? "권":"화"} 보기</p>
                                        </button>
                                    </div>
                                :null}
                                {!info?.is_first_view && type !== "epub" && !isMobile ? 
                                    <div className={"view_button color-btn-01 bg radius-sm " + style['view_button']}>
                                        <button 
                                            type="button" 
                                            className={"button " + style['button']} 
                                            onClick={()=>{handlerMoveViewer(type, info?.last_view?.last?.cid, info?.last_view?.last?.adult)}}>
                                            {/* <p className="white-always txt btn-04 title">뷰어로 첫 화 보기</p> */}
                                            <p className={"white-always txt btn-04 title " + style['title']}>이어보기</p>
                                            <p className={"caption-02 txt_white_opacity-80 s_title " + style['s_title']}>{ he.decode( info?.last_view?.last?.title ?? "" )}</p>
                                        </button>
                                    </div>
                                :null}

                                {type === "epub" && !isMobile? 
                                    // <div className={"view_button color-btn-01 bg radius-sm " + style['view_button']}>
                                    //     <button 
                                    //         type="button" 
                                    //         className={"button " + style['button']} 
                                    //         onClick={()=>{handlerMoveViewer(type, info?.last_view?.last?.cid)}}>
                                    //         <p className="white-always txt btn-04 title">뷰어로 첫 {type === "epub" ? "권":"화"} 보기</p>
                                    //     </button>
                                    // </div>
                                    <Epub 
                                    bid={bid}
                                    info={info} 
                                    isMobile={isMobile}
                                    data={info?.last_view?.last} 
                                    alert={alert}
                                    buyConfirm={buyConfirm}
                                    />
                                :null}
                                 
                                {/* <!-- 이어보기 버튼 --> */}
                                {/* <div className={"view_button color-btn-01 bg radius-sm " + style['view_button']} style={{display: "none"}}>
                                    <button type="button" className={"button " + style['button']}>
                                        <p className={"white-always txt btn-04 title " + style['title']}>이어보기</p>
                                        <p className={"caption-02 txt_white_opacity-80 s_title " + style['s_title']}>회차제목영역입니다.</p>
                                    </button> */}
                                    {/* <!-- 태블릿부터 나오는 버튼 --> */}
                                    {/* <!-- <button type="button" className={"button " + style['button']} id="positionBtn">
                                        <p className="title"><img src="/contents/images/icon_position_btn.svg"></p>
                                        <p className="caption-02 txt_white_opacity-80 s_title">보던 위치로</p>
                                    </button> --> */}
                                {/* </div> */}
                            </div>
                            <div className={"list_wrap border-top-line border-soild border color-border-01 " + style['list_wrap'] + " " + style['border']}>
                                <ul className={"benefits_box info_txt " + style['benefits_box'] + " " + style['info_txt']}>
                                    {/* <!-- 벌써 12시 프로모션 --> */}
                                    {info?.promotion?.midnight ? 
                                        <li>
                                            <Midnight 
                                                data={info?.promotion?.midnight} 
                                                bid={info?.bid}
                                                onAction={(message)=>{
                                                    alert("벌써열두시?!",message, ()=>{
                                                        // handlerReload();
                                                    });
                                                }}
                                                 />
                                        </li>
                                    :null}
                                    {/* <!-- 연재의 참맛(정주행의맛) 프로모션 --> */}
                                    {info?.promotion?.plusone ? 
                                        <li>
                                            <PlusOne data={info?.promotion?.plusone} />
                                        </li>
                                    :null}

                                    {/* <!-- 선물함 이벤트 --> */}
                                    {info?.promotion?.gift ? 
                                        
                                            <Gift 
                                                data={info?.promotion?.gift} 
                                                onAction={(count, voucher_type )=>{
                                                    if( parseInt(voucher_type,10) == 2 ){
                                                        info.voucher_info.own_cnt += parseInt(count,10);
                                                    } else {
                                                        info.voucher_info.rent_cnt += parseInt(count,10);
                                                    }
                                                    
                                                    setInfo({...info});
                                                    // alert("선물함",message, ()=>{
                                                    //     handlerReload();
                                                    // });
                                                }}
                                                />
                                        
                                    :null}

                                    {/* <!-- 묶음이벤트 --> */}
                                    {info?.promotion?.bind ? 
                                        <li>
                                            <Bind data={info?.promotion?.bind} />
                                        </li>
                                    :null}

                                    {/* <!-- 핫딜 --> */}
                                    {info?.promotion?.hotdeal ? 
                                        
                                            <Hotdeal 
                                                data={info?.promotion?.hotdeal} 
                                                bid={bid} 
                                                buyConfirm={buyConfirm} 
                                                buyAction={()=>{
                                                    handlerReload();
                                                }}
                                                />
                                    :null}

                                    {/* <!-- 플러스무료 프로모션, 남은시간 데이터 필요 --> */}
                                    {info?.promotion?.plusfree ? 
                                        <li>
                                            <PlusFree data={info?.promotion?.plusfree} />
                                        </li>
                                    :null}

                                    {/* <!-- 리뷰이벤트 --> */}
                                    {info?.promotion?.review ? 
                                        <li>
                                            <Review data={info?.promotion?.review} />
                                        </li>
                                    :null}
                                    
                                    {/* <!-- 단행본 할인 --> */}
                                    {info?.promotion?.epubdeal ? 
                                        <li>
                                            <EpubHotdeal data={info?.promotion?.epubdeal} />
                                        </li>
                                    :null}
                                    
                                    <li>
                                        <div className={"left " + style['left']}>
                                            <p className={"icon " + style['icon'] + " " + style['icon_coupon']} id="icon_coupon"></p>
                                            <p className={"title info " + style['title'] + " " + style['info']}>
                                                <span className="color-txt-01 body-03">보유중인 쿠폰</span> 
                                                <span className="color-txt-normal title-01-b">{formatNumber(info?.voucher_info?.coupon_cnt)}개</span>
                                            </p>
                                        </div>
                                        <p className={"right " + style['right']}><button 
                                            onClick={()=>{
                                                if(!memberInfo?.is_login){
                                                    alert("알림","쿠폰을 충전하기 위해서는 로그인이 필요합니다.",()=>{
                                                        navigate("/login",{state:{return_path:location.pathname}});
                                                    });
                                                    return;
                                                }
                                                runPopupOrder();
                                            }}
                                            type="button" 
                                            className={"button btn_03 round radius-full small label_on outlined link_btn " + style['button'] + " " + style['link_btn']}
                                            ><span className="btn-02 color-txt-04">충전하기</span></button></p>
                                    </li>
                                </ul>
                                <ul className={"available_box radius-xs color-bg-02 " + style['available_box']}>
                                    <li>
                                        <span className="left title color-txt-04 caption-02">사용 가능 선물이용권</span>
                                        <span className="right num title-sub-01 color-txt-02">{(type === "epub") ? 0 : info?.voucher_info?.rent_cnt}</span>
                                    </li>
                                    <li>
                                        <span className="left title color-txt-04 caption-02">사용 가능 선물소장권</span>
                                        <span className="right num title-sub-01 color-txt-02">{(type === "epub") ? 0 : info?.voucher_info?.own_cnt}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    {type === "epub" && isMobile ? 
                        <Epub 
                        bid={bid}
                        info={info} 
                        isMobile={isMobile}
                        data={info?.last_view?.last} 
                        alert={alert}
                        buyConfirm={buyConfirm}
                        />
                    :null}
                    
                    {info?.is_first_view && type !== "epub" && isMobile ? 
                        <div 
                            className={"view_button color-btn-01 bg radius-sm " + style['view_button']}
                            style={{
                                position: "fixed",
                                left: 0,
                                bottom: 0,
                                borderRadius: 0,
                                zIndex: 99,
                                marginTop: "24px",
                                width: "100%",
                                height: "3.5rem",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                overflow: "hidden",
                            }}
                            >
                            <button 
                                type="button" 
                                className={"button " + style['button']} 
                                onClick={()=>{handlerMoveViewer(type, info?.last_view?.last?.cid, info?.last_view?.last?.adult)}}>
                                <p className="white-always txt btn-04 title">뷰어로 첫 화 보기</p>
                            </button>
                        </div>
                    :null}
                    {!info?.is_first_view && type !== "epub" && isMobile ? 
                        <div 
                            className={"view_button color-btn-01 bg radius-sm " + style['view_button']}
                            style={{
                                position: "fixed",
                                left: 0,
                                bottom: 0,
                                borderRadius: 0,
                                zIndex: 99,
                                marginTop: "24px",
                                width: "100%",
                                height: "3.5rem",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                overflow: "hidden",
                            }}
                            >
                            <button 
                                type="button" 
                                className={"button " + style['button']} 
                                onClick={()=>{handlerMoveViewer(type, info?.last_view?.last?.cid, info?.last_view?.last?.adult)}}>
                                <p className={"white-always txt btn-04 title " + style['title']}>이어보기</p>
                                <p className={"caption-02 txt_white_opacity-80 s_title " + style['s_title']}>{he.decode( info?.last_view?.last?.title ?? "" )}</p>
                            </button>
                        </div>
                    :null}
                    <div className={"content color-bg-01 radius-xxl color-bg-01 right temp_list temp_list_type " + style['content'] + " " + style['right'] + " " + style['temp_list_type']} style={contentStyle}>
                        {/* <!-- 중단예정 작품일때 --> */}
                        {info?.step !== "X" && info?.stop_date !== "0000-00-00 00:00:00" && info?.stop_date !== "" ?
                            <div id="StopEpisode" className={"notice_sec " + style['notice_sec'] + " " + style['stopEpisode']}>
                                <p className={"area color-bg-select-01 radius-sm " + style['area']}>
                                    <span className={"title title-sub-02-m color-btn-01-hover txt " + style['title'] + " " + style['txt']}>
                                        {/* {info?.stop_info?.will_be_stop_title}  */}
                                        다운로드 또는 소장을 해주세요!<br />
                                        ({formatDateString(info?.stop_info?.will_be_stop_date)} 중단 예정)
                                        </span>
                                    <span 
                                        className={"icon " + style['icon']}
                                        onClick={()=>{
                                            setStopTooltip(true);
                                        }}
                                        style={{cursor:"pointer"}}
                                        ></span>
                                </p>
                                <div 
                                    id="toolTip" 
                                    className={"toolTip " + style["toolTip"]} 
                                    style={{display: stopTooltip?"block":"none",zIndex:1000}}
                                    onClick={()=>{
                                        setStopTooltip(false);
                                    }}
                                    >
                                    <button type="button" className={"radius-xs color-btn-02 bg top_left"}>
                                        <span className="white-always caption-02-long txt">
                                            소장된 회차는 상시 다운로드 가능<br />
                                            상시 무료회차는 소장이 불가능<br />
                                            (단, 중단 전 다운로드 가능)
                                        </span>
                                        <p className="clear_btn"></p>
                                    </button>
                                </div>
                            </div>
                        :null}
                        {/* <!-- 중단된 작품일때--> */}
                        {info?.step === "X" ?
                            <div id="BlindEpisode" className={"notice_sec " + style['notice_sec'] + " " + style['blindEpisode']}>
                                <div className={"area color-bg-select-01 radius-sm " + style['area']}>
                                    <p className={"title title-01-m-long color-btn-01-hover txt " + style['title'] + " " + style['txt']}>
                                        요청에 의해 중단된 작품으로<br />
                                        소장된 회차만 보실 수 있습니다. 
                                    </p>
                                </div>
                            </div>
                        :null}
                        
                        {/* <!-- 블라인드 작품일때--> */}
                        {/* <!-- <div id="BlindEpisode" className="notice_sec">
                            <div className="area color-bg-select-01 radius-sm">
                                <p className="title title-01-m-long color-btn-01-hover txt">
                                    블라인드된 작품입니다. 
                                </p>
                            </div>
                        </div> --> */}
                        
                        

                        {/* <!-- 서브 탭 메뉴 --> */}
                        <SubTabNav 
                            type={type}
                            bid={bid}
                            isFirst={info?.is_first_view && type !== "epub"}
                            dotIcon={info?.dot_icon}
                        />

                        <div className={"list " + style['list']}>
                            
                            <Routes>
                                {/* <!-- 첫화보기 --> */}
                                {info?.is_first_view && type !== "epub" ? 
                                    <Route path='/first' exact element={(
                                        <BookpalKeepAlive>
                                            <div className={"list_1 " + style['list_1'] + " " + style['firstEpisode']} id="FirstEpisode">
                                                <FirstEpisode 
                                                    info={info}
                                                    />
                                            </div>
                                        </BookpalKeepAlive>
                                    )} />
                                :null}
                                
                                {/* <!-- 회차 --> */}
                                <Route path='/episode' exact element={(
                                    <BookpalKeepAlive>
                                    <div className={"list_2 " + style['list_2'] + " " + style['episodeList']} id="EpisodeList">
                                        <EpisodeList 
                                            info={info}
                                            type={type}
                                            alert={alert}
                                            buyConfirm={buyConfirm}
                                            />
                                    </div>
                                    </BookpalKeepAlive>
                                )} />
                                {/* <!-- 소개 --> */}
                                <Route path='/intro' exact element={(
                                    <BookpalKeepAlive>
                                    <div className={"list_3 " + style['list_3'] + " " + style['idIntroduction']} id="Introduction">
                                        <Introduction 
                                            ct_type={type}
                                            info={info}
                                            setInfo={setInfo}
                                            />
                                    </div>
                                    </BookpalKeepAlive>
                                )} />
                                {/* <!-- 소식 --> */}
                                <Route path='/news' exact element={(
                                    <BookpalKeepAlive>
                                    <div className={"list_4 template_row " + style['list_4'] + " " + style['News']} id="News">
                                        <News 
                                            info={info}
                                            />
                                    </div>
                                    </BookpalKeepAlive>
                                )} />
                                {/* <!-- 댓글 --> */}
                                <Route path='/comment' exact element={(
                                    <BookpalKeepAlive>
                                    <div className={"list_5 " + style['list_5'] + " " + style['Comment']} id="Comment">
                                        <Comment 
                                            info={info}
                                            />
                                    </div>
                                    </BookpalKeepAlive>
                                )} />
                                <Route path="*" element={<Navigate to={info?.is_first_view && type !== "epub" ? "first" : "episode"} replace={true} />} />
                            </Routes>
                            
                            
                        </div>
                    </div>
                </section>
            </div>
            {/* <!-- container --> */}


            <Footer />
            <TopBtn />
            <AlertModal />
            <BuyConfirmModal />
            <ConfirmModal />
            <CoverModal />
        </>
    );
}






export default ContentsHome;