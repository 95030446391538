import { useRef, useState, useEffect } from 'react';
import { ApiBookRankingList } from 'api/Book';
import { ImgBadge } from 'utils/Images';
import { ImgDefault, ImgCover } from 'utils/Images';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BookpalLazyLoadImage from 'component/common/custom/BookpalLazyLoadImage';
import { getViewCntConvert } from 'utils/Common';
import useSelectArrModal from 'component/common/modal/useSelectArrModal';

import Header from 'component/common/header/Header';
import MobileNav from 'component/common/nav/MobileNav';

import Footer from 'component/common/footer/Footer';
import TopBtn from 'component/common/button/top/TopBtn';

import SubMenuNav from '../nav/SubMenuNav';
import GenreHomeHalfBanner from 'component/common/banner/GenreHomeHalfBanner';

import useSelectModal from 'component/common/modal/useSelectModal';
import { Link } from 'react-router-dom';
import BookpalLink from 'component/common/custom/BookpalLink';
import useAlertModal from 'component/common/modal/useAlertModal';
import { useMemberInfo } from 'utils/State';


function Ranking(){

    const contentRef = useRef();
    const memberInfo = useMemberInfo();

    const [options, setOptions] = useState({});
    const [filterList, setFilterList] = useState(null);
    const [sortList, setSortList] = useState(null);

    const [AlertModal, alert] = useAlertModal();

    const [data, dataSubmit, initData, paging, setData] = ApiBookRankingList({
        bottomRef:contentRef,
        pageLimit:20,
        data:{
            ...options,
            ct_type:"toon",
        },
        onSuccess:(data)=>{
            if(data?.status === true){
                // if(!!data?.result?.md_section && data?.result?.paging?.current_page === 1){
                //     setMdSection(data?.result?.md_section);
                // }
                if(!!data?.result?.selbox?.filter && data?.result?.paging?.current_page === 1){
                    setFilterList(data?.result?.selbox?.filter);
                }
                if(!!data?.result?.selbox?.sort && data?.result?.paging?.current_page === 1){
                    setSortList(data?.result?.selbox?.sort);
                }
            }
        },
        onError:(e)=>{

        }
    });

    const [FilterModal, showFilterModal, selectKey,setSelectKey, initSelect, getValue] = useSelectArrModal(filterList, options?.filter || "", {
        valueName:"title",
        selectFn:(key)=>{
            setOptions({
                ...options,
                filter:key,
            });
        },
    });

    const [SortModal, showSortModal, selectSortKey,setSelectSortKey, initSortSelect, getSortValue] = useSelectArrModal(sortList, options?.sort || "day" , {
        valueName:"title",
        selectFn:(key)=>{
            setOptions({
                ...options,
                sort:key,
            });
        },
    });

    const handlerEndOptionToggle = (e)=>{
        const checked = e.currentTarget.checked;

        setOptions({
            ...options,
            end:(checked ? 1 : null),
        })
    }

    useEffect(()=>{
        dataSubmit();
    },[]);

    useEffect(()=>{
        initData();
        dataSubmit();
    },[options])

    // if(data === null){
    //     return <></>
    // }

    return (
        <>
            {/* <!-- container --> */}
            <div id="container" className="sub_wrap wrap">
                
                {/* <!-- 섹션 --> */}
                <section className="section template wrapper rank_list temp_list template_column ranking">
                    <div className="tit_con clarfix">
                        <div className="left">
                            <label className="medium toggle_btn">
                                <input role="switch" type="checkbox" checked={options?.end ? true : false} onChange={handlerEndOptionToggle}/>
                                <span className="color-txt-05 btn-03">완결</span>
                            </label>
                        </div>
                        <div className="right" style={{cursor:"pointer"}}>
                            <a className="select_btn select_01" onClick={showFilterModal}><span className="color-btn-03-txt btn-03" >{getValue()}</span></a>
                            <a className="select_btn select_02" onClick={showSortModal}><span className="color-btn-03-txt btn-03">{getSortValue()}</span></a>
                        </div>
                    </div>
                    <div className="list" ref={contentRef}>
                        {Array.isArray(data) && data.length === 0 ? <p className="no_data large color-txt-04 body-02-long">더 많은 작품을 준비 중입니다.</p> : null}
                        {data && Array.isArray(data) && data?.length > 0 ?
                        <ul>
                            {/* <!-- 리스트--> */}
                            {Array.isArray(data) && data.length > 0 ? 
                                data.map((item,index)=>{

                                    return (
                                        <li className="swiper-slide">
                                            <BookpalLink 
                                                to={"/content/"+item?.ct_type+"/"+item?.bid}
                                                check={{
                                                    view_type:memberInfo?.view_type,
                                                    work_adult:item?.adult,
                                                }}
                                                alert={alert}
                                                >
                                                <div className="cover_img radius-xs">
                                                    {item?.adult ? <p className="ic_adult badge_adult_middle"><img src="../images/ic_adult.svg" /></p> : null}
                                                    {/* <img src={item?.cover} /> */}
                                                    {/* <BookpalLazyLoadImage 
                                                        placeholderSrc={ImgDefault("cover_logo")}
                                                        // effect="blur"
                                                        src={ImgCover(memberInfo?.view_type, item?.adult, ImgDefault("cover_lock"), item?.cover)}
                                                        // style={{width:"100px",height:"150px"}}
                                                        // style={defaultImageStyle}
                                                        // className={style['cover_img']}
                                                        onError={(e)=>{
                                                            e.currentTarget.src = ImgDefault("cover_logo");
                                                        }}
                                                        /> */}
                                                    <BookpalLazyLoadImage 
                                                        adult={item?.adult}
                                                        adultswitch={memberInfo?.audit_switch}
                                                        defaulttype={"cover_logo"}
                                                        locktype={"cover_lock"}
                                                        src={item?.cover}
                                                        // style={defaultImageStyle}
                                                        // className={style['cover_img']}
                                                    />
                                                    <p className="ic_bottom badges_box">
                                                        {item?.badge_promotion !== "" ? <span className="ic_midnight radius-xs icon_badge"><img src={ImgBadge(item?.badge_promotion)} /></span> : null}
                                                        {/* {item?.badge_new_up !== "" ? <span className="ic_up radius-xs icon_badge"><img src={ImgBadge(item?.badge_new_up)} /></span> : null} */}
                                                        {item?.badge_discount > 0 ? <span className="ic_discount radius-xs discount_10 icon_badge"><img src={ImgBadge(item?.badge_discount)} /></span> : null }
                                                    </p>
                                                </div>
                                                <div className="ranking_number left title-01-b color-txt-normal">{index +1}</div>
                                                <div className="info_txt spacing_08">
                                                    <div className="info">
                                                        <span className="color-txt-05 body-01">{item?.genre_kor}</span>
                                                        <span className="color-txt-05 body-01">{item?.is_finished === true ? "완결":item?.is_rest ? "휴재":"연재"}</span>
                                                    </div>
                                                    <div className="list_tit_con">
                                                        {item?.badge_new_up !== "" ? <span className="ic_up radius-xs icon_badge"><img src={ImgBadge(item?.badge_new_up)} /></span> : null}
                                                        <span className="title color-txt-normal title-book-03-m text_overflow">{item?.title}</span>
                                                    </div>
                                                    <div className="writer color-txt-03 body-02 text_overflow">{item?.wr_nick}</div>
                                                    <div className="view body-01 color-txt-05">{getViewCntConvert(item?.view_cnt)}</div>
                                                </div>
                                            </BookpalLink>
                                        </li>
                                    )
                                })
                            :null}
                            {/* <li className="swiper-slide">
                                <a href="#">
                                    <div className="cover_img radius-xs">
                                        <p className="ic_adult badge_adult_middle"><img src="../images/ic_adult.svg" /></p>
                                        <img src="../images/thumbnail.jpg" />
                                        <p className="ic_bottom badges_box">
                                            <span className="ic_midnight radius-xs icon_badge"><img src="../images/midnight_badge.svg" /></span>
                                            <span className="ic_discount radius-xs discount_10 icon_badge"><img src="../images/discount_10.svg" /></span>
                                        </p>
                                    </div>
                                    <div className="ranking_number left title-01-b color-txt-normal">1</div>
                                    <div className="info_txt spacing_08">
                                        <div className="info">
                                            <span className="color-txt-05 body-01">장르</span>
                                            <span className="color-txt-05 body-01">완결여부</span>
                                        </div>
                                        <div className="list_tit_con">
                                            <span className="ic_up radius-xs icon_badge"><img src="../images/up_badge.svg" /></span>
                                            <span className="title color-txt-normal title-book-03-m text_overflow">책제목입니다.</span>
                                        </div>
                                        <div className="writer color-txt-03 body-02 text_overflow">작가명</div>
                                        <div className="view body-01 color-txt-05">61.4만</div>
                                    </div>
                                </a>
                            </li>
                            <li className="swiper-slide">
                                <a href="#">
                                    <div className="cover_img radius-xs">
                                        <p className="ic_adult badge_adult_middle"><img src="../images/ic_adult.svg" /></p>
                                        <img src="../images/thumbnail.jpg" />
                                        <p className="ic_bottom badges_box">
                                            <span className="ic_midnight radius-xs icon_badge"><img src="../images/midnight_badge.svg" /></span>
                                            <span className="ic_discount radius-xs discount_10 icon_badge"><img src="../images/discount_10.svg" /></span>
                                        </p>
                                    </div>
                                    <div className="ranking_number left title-01-b color-txt-normal">1</div>
                                    <div className="info_txt spacing_08">
                                        <div className="info">
                                            <span className="color-txt-05 body-01">장르</span>
                                            <span className="color-txt-05 body-01">완결여부</span>
                                        </div>
                                        <div className="list_tit_con">
                                            <span className="ic_up radius-xs icon_badge"><img src="../images/up_badge.svg" /></span>
                                            <span className="title color-txt-normal title-book-03-m text_overflow">책제목입니다.</span>
                                        </div>
                                        <div className="writer color-txt-03 body-02 text_overflow">작가명</div>
                                        <div className="view body-01 color-txt-05">61.4만</div>
                                    </div>
                                </a>
                            </li>
                            <li className="swiper-slide">
                                <a href="#">
                                    <div className="cover_img radius-xs">
                                        <p className="ic_adult badge_adult_middle"><img src="../images/ic_adult.svg" /></p>
                                        <img src="../images/thumbnail.jpg" />
                                        <p className="ic_bottom badges_box">
                                            <span className="ic_midnight radius-xs icon_badge"><img src="../images/midnight_badge.svg" /></span>
                                            <span className="ic_discount radius-xs discount_10 icon_badge"><img src="../images/discount_10.svg" /></span>
                                        </p>
                                    </div>
                                    <div className="ranking_number left title-01-b color-txt-normal">1</div>
                                    <div className="info_txt spacing_08">
                                        <div className="info">
                                            <span className="color-txt-05 body-01">장르</span>
                                            <span className="color-txt-05 body-01">완결여부</span>
                                        </div>
                                        <div className="list_tit_con">
                                            <span className="ic_up radius-xs icon_badge"><img src="../images/up_badge.svg" /></span>
                                            <span className="title color-txt-normal title-book-03-m text_overflow">책제목입니다.</span>
                                        </div>
                                        <div className="writer color-txt-03 body-02 text_overflow">작가명</div>
                                        <div className="view body-01 color-txt-05">61.4만</div>
                                    </div>
                                </a>
                            </li>
                            <li className="swiper-slide">
                                <a href="#">
                                    <div className="cover_img radius-xs">
                                        <p className="ic_adult badge_adult_middle"><img src="../images/ic_adult.svg" /></p>
                                        <img src="../images/thumbnail.jpg" />
                                        <p className="ic_bottom badges_box">
                                            <span className="ic_midnight radius-xs icon_badge"><img src="../images/midnight_badge.svg" /></span>
                                            <span className="ic_discount radius-xs discount_10 icon_badge"><img src="../images/discount_10.svg" /></span>
                                        </p>
                                    </div>
                                    <div className="ranking_number left title-01-b color-txt-normal">1</div>
                                    <div className="info_txt spacing_08">
                                        <div className="info">
                                            <span className="color-txt-05 body-01">장르</span>
                                            <span className="color-txt-05 body-01">완결여부</span>
                                        </div>
                                        <div className="list_tit_con">
                                            <span className="ic_up radius-xs icon_badge"><img src="../images/up_badge.svg" /></span>
                                            <span className="title color-txt-normal title-book-03-m text_overflow">책제목입니다.</span>
                                        </div>
                                        <div className="writer color-txt-03 body-02 text_overflow">작가명</div>
                                        <div className="view body-01 color-txt-05">61.4만</div>
                                    </div>
                                </a>
                            </li> */}
                        </ul>
                        :<div style={{height:"100vh"}}></div>}
                    </div>
                </section>
            </div>
            <FilterModal />
            <SortModal />
            <AlertModal />
            {/* <!-- container --> */}
        </>
    );
}


export default Ranking;