import { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Thumbs, EffectFade, Autoplay } from 'swiper/modules';
import { ImgBadge, ImgDefault, ImgCover } from 'utils/Images';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BookpalLazyLoadImage from '../custom/BookpalLazyLoadImage';
import { useMemberInfo } from 'utils/State';
import BookpalLink from '../custom/BookpalLink';


function FreeBannerSub({data, title, key}){

    const memberInfo = useMemberInfo();

    // const [key, setKey] = useState(0);
    // const [prevWidth, setPrevWidth] = useState(window.innerWidth);

    // const refreshSwiper = () => {
    //     setKey((prevKey) => prevKey + 1);
    // };

    // const handleResize = () => {
    //     const currentWidth = window.innerWidth;
  
    //     if (Math.abs(currentWidth - prevWidth) > 1) {
    //       // 좌우로 10px 이상 변경되었을 때 동작하도록 설정
    //       console.log('Resized!');
    //       setPrevWidth(currentWidth);
    //       refreshSwiper();
    //     }
    // };

    // useEffect(() => {
    //     // 컴포넌트가 마운트될 때 이벤트 리스너 추가
    //     // window.addEventListener('resize', refreshSwiper);
    //     window.addEventListener('resize', handleResize);
    //     // console.log("test -> use effect");

    //     // 컴포넌트가 언마운트될 때 이벤트 리스너 정리
    //     return () => {
    //         // window.removeEventListener('resize', refreshSwiper);
    //         window.removeEventListener('resize', handleResize);
    //     };
    // }, []);

    if(!data){
        return <></>;
    }

    return (
        <>
            <Swiper 
                key={key}
                className={"swiper mySwiper "}
                modules={[Navigation]}
                slidesPerView={'auto'}
                spaceBetween={4}
                watchOverflow={true}
                observer={true}
                observeParents={true}
                navigation={{
                    nextEl : '.template .swiper-button-next',
                    prevEl : '.template .swiper-button-prev',
                }}
                breakpoints={{
                    769: {
                        freeMode : true
                    }
                }}
                onInit={(swiper) => {
                    swiper.slides.forEach((slide, index) => {
                    if(slide.swiperSlideIndex == 0){
                        if(swiper && swiper.slideTo){
                        swiper.slideTo(index, 0);
                        }
                    }
                    });
                }}
            >
                {/* <!-- 리스트--> */}
                {data && Array.isArray(data) && data?.length > 0 ?
                    data.map((item,index)=>{
                        return (
                            <SwiperSlide>
                                <BookpalLink 
                                    to={"/content/"+item?.ct_type+"/"+item?.bid} 
                                    check={{
                                        view_type:memberInfo?.view_type,
                                        work_adult:item?.adult,
                                    }}
                                    >
                                    <div className="cover_img radius-xs">
                                        {/* <p className="ic_adult badge_adult_normal"><img src="../images/ic_adult.svg" /></p> */}
                                        {item?.adult ? <p className="ic_adult badge_adult_normal"><img src="/images/ic_adult.svg" /></p> : null}
                                        {/* <img src="../images/thumbnail.jpg" /> */}
                                        {/* <BookpalLazyLoadImage 
                                                placeholderSrc={ImgDefault("cover_logo")}
                                                // effect="blur"
                                                src={ImgCover(memberInfo?.view_type, item?.adult, ImgDefault("cover_lock"), item?.cover)}
                                                // style={defaultImageStyle}
                                                onError={(e)=>{
                                                    e.currentTarget.src = ImgDefault("cover_logo");
                                                }}
                                            /> */}
                                        <BookpalLazyLoadImage 
                                            adult={item?.adult}
                                            adultswitch={memberInfo?.audit_switch}
                                            defaulttype={"cover_logo"}
                                            locktype={"cover_lock"}
                                            src={item?.cover}
                                            // style={defaultImageStyle}
                                        />
                                        <p className="ic_bottom badges_box">
                                            {/* <span className="ic_midnight radius-xs icon_badge"><img src="../images/midnight_badge.svg" /></span>
                                            <span className="ic_up radius-xs icon_badge"><img src="../images/up_badge.svg" /></span>
                                            <span className="ic_discount radius-xs discount_10 icon_badge"><img src="../images/discount_10.svg" /></span> */}
                                            {item?.badge_promotion !== "" ? <span className="ic_midnight radius-xs icon_badge"><img src={ImgBadge(item?.badge_promotion)} /></span> : null}
                                            {item?.badge_new_up !== "" ? <span className="ic_up radius-xs icon_badge"><img src={ImgBadge(item?.badge_new_up)} /></span> : null}
                                            {item?.badge_discount > 0 ? <span className="ic_discount radius-xs discount_10 icon_badge"><img src={ImgBadge(item?.badge_discount)} /></span> : null }
                                        </p>
                                    </div>
                                    <div className="info_txt spacing_04">
                                        <div className="title color-txt-normal title-book-02-m text_overflow2">{item?.title}</div>
                                        <div className="writer color-txt-03 body-01 text_overflow">{item?.wr_nick}</div>
                                    </div>
                                </BookpalLink>
                            </SwiperSlide>
                        )
                    })
                :null}
            </Swiper>
        </>
    );
}



export default FreeBannerSub;