import { useEffect, useState } from 'react';
import { useDispatch, useSelector  } from 'react-redux';
// import { useCommonValues } from "context/CommonContext";
import { getAppsDataWithUserAgent, getTodayDate } from 'utils/Common';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import BookpalLink from 'component/common/custom/BookpalLink';
import { SetState, GetState } from 'utils/State';
import { isApp } from 'utils/AppUtil';
import useAlertModal from 'component/common/modal/useAlertModal';
import { 
            ApiAuthLogin, 
            ApiAuthSocialLoginUrl, 
            ApiAuthSocialLogin, 
            ApiAuthSocialJoin, 
            ApiAuthSocialConnect 
        } from 'api/Auth';
import {    actionSetToken, 
            actionSetSaveId, 
            actionSetToolTipLogin, 
            actionSetLastSocialLoginTryPath, 
            actionSetLastSocialLoginTryType, 
            actionSetLastSocialLoginType, 
            actionSetLastSocialLoginDate,
            actionSetConnectSocialLoginType
        } from 'action';

import { appSet, appSetMove } from 'utils/AppUtil';
import { getBookpalLink } from 'utils/BookpalLinkUtil';

import SubNav from "component/common/nav/SubNav";

import style from '../style/User.module.scss';

import naverBtnImg from 'assets/images/user/login_naver_icon.svg';
import kakaoBtnImg from 'assets/images/user/login_kakao_icon.svg';
import googleBtnImg from 'assets/images/user/login_google_icon.svg';
import facebookBtnImg from 'assets/images/user/login_facebook_icon.svg';
import appleBtnImg from 'assets/images/user/login_apple_icon.svg';

function Login(){

    const dispatch = useDispatch();
    const navigate = useBookpalNavigate();
    const [AlertModal, alert] = useAlertModal();
    const setState = SetState();
    const getState = GetState();

    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);

    const socialCode = queryParams.get("code");
    const socialState = queryParams.get("state");
    const appReturnLink = queryParams.get("app_return_link");
    const webReturnPath = location.state?.return_path;

    const [socialIsLoading, setSocialIsLoading] = useState(socialCode ? true : false);
    const dataLastSocialLoginTryPath = useSelector((state) => state.data.last_social_login_try_path);
    const dataLastSocialLoginTryType = useSelector((state) => state.data.last_social_login_try_type);

    const dataSaveId = useSelector((state) => state.data.saveid);
    const dataTooltipLogin = useSelector((state) => state.data.tooltip_login);
    const dataLastSocialLoginType = useSelector((state) => state.data.last_social_login_type);
    const dataLastSocialLoginDate = useSelector((state) => state.data.last_social_login_date);

    const dataConnectSocialLoginType = useSelector((state) => state.data.connect_social_login_type);
//dispatch(actionSetConnectSocialLoginType(social_type));

    const strDataLastSocialLoginType = dataLastSocialLoginType ? dataLastSocialLoginType.charAt(0).toUpperCase() + dataLastSocialLoginType.slice(1) : '';

    const bookpalAgentData = getAppsDataWithUserAgent();


    const [loginId, setLoginId] = useState(dataSaveId);
    const [loginPassword, setLoginPassword] = useState('');

    const [errorIdTxt, setErrorIdTxt] = useState("");
    const [errorPasswdTxt, setErrorPasswdTxt] = useState("");
// console.log(getState.useGetAutoLogin());
    const [autoLogin, setAutoLogin] = useState(isApp() ? (getState.useGetAutoLogin() === false ? false : true) : false);
    // const [saveId, setSaveId] = useState(true);
    const saveId = getState.useGetSaveIdCheck();
    const setSaveId = (check)=>{
        setState.setSaveIdCheck(check);
        if(check === false){
            setState.setSaveId("");
        }
    }


    


    const socialJoin = ApiAuthSocialJoin({
        onSuccess: (data)=>{
            console.log(data);

            if(data?.status === true){
                if(bookpalAgentData?.os_type === "ios" || bookpalAgentData?.os_type === "android"){
                    // 웹뷰 디바이스 함수 호출
                    // window.BOOKPAL.setLogin(JSON.stringify(data));
                    // console.log(true, "웹뷰 디바이스 함수 호출 구현해야함" );

                    // dispatch(actionSetToken(data.result));
                    setState.setToken(data.result);
                    // dispatch(actionSetLastSocialLoginType(data.result?.login_type));
                    setState.setLastSocialLoginType(data.result?.login_type);
                    // dispatch(actionSetLastSocialLoginDate(getTodayDate()));
                    setState.setLastSocialLoginDate(getTodayDate());

                    const appSetData = {
                        ...data.result,
                        login_date:getTodayDate()
                    }

                    appSet("login", appSetData);

                    if(['naver','google','kakao','facebook','apple'].includes(dataLastSocialLoginType)){
                        navigate("/join_complete_sns", {replace: true});
                    } else {
                        navigate("/join_complete", {replace: true});
                    }
                } else {
                    // dispatch(actionSetToken(data.result));
                    setState.setToken(data.result);
                    // dispatch(actionSetLastSocialLoginType(data.result?.login_type));
                    setState.setLastSocialLoginType(data.result?.login_type);
                    // dispatch(actionSetLastSocialLoginDate(getTodayDate()));
                    setState.setLastSocialLoginDate(getTodayDate());
                    
                    if(['naver','google','kakao','facebook','apple'].includes(dataLastSocialLoginType)){
                        navigate("/join_complete_sns", {replace: true});
                    } else {
                        navigate("/join_complete", {replace: true});
                    }
                }
            } else {
                switch(data?.code){
                    default: alert("시스템 문제가 발생하였습니다. 문제가 지속되면 관리자에게 문의주세요."); navigate("/login", {replace: true}); break;
                }
            }
            setSocialIsLoading(false);
        },
        onError: ()=>{
            setSocialIsLoading(false);
        },
    });

    const handlerSocialJoin = async (data) => {
        await socialJoin({
            encrypt:data.encrypt,
            apps:bookpalAgentData,
        });
    };

    const socialLogin = ApiAuthSocialLogin({
        onSuccess:(data)=>{
            if(!socialIsLoading) return;
            console.log("socialLogin => ", data);
            if(data?.status === true){
                if(data?.result?.status === "no_join"){
                    // navigate("/signup", {replace: true});
                    handlerSocialJoin(data?.result);
                    return;
                }
                // console.log(dataLastSocialLoginTryPath);
                if(bookpalAgentData?.os_type === "ios" || bookpalAgentData?.os_type === "android"){
                    // 웹뷰 디바이스 함수 호출
                    // window.BOOKPAL.setLogin(JSON.stringify(data));
                    // console.log(true, "웹뷰 디바이스 함수 호출 구현해야함" );

                    const appSetData = {
                        ...data.result,
                        login_date:getTodayDate()
                    }

                    

                    dispatch(actionSetToken(data.result));
                    dispatch(actionSetLastSocialLoginType(data.result?.login_type));
                    dispatch(actionSetLastSocialLoginDate(getTodayDate()));

                    if(dataLastSocialLoginTryPath){
                        let state = {};
                        if(dataLastSocialLoginTryPath === "/mypage/edit"){
                            const timestampInSeconds = Math.floor(Date.now() / 1000);
                            state = {confirmEdit:true,expire:timestampInSeconds + (60*30)};
                            navigate(dataLastSocialLoginTryPath, {replace: true, state:state});
                            dispatch(actionSetLastSocialLoginTryPath("/"));
                            return;
                        }
                        

                        appSetMove("",dataLastSocialLoginTryPath);
                        dispatch(actionSetLastSocialLoginTryPath(null));
                        return;
                    }

                    // if(dataLastSocialLoginTryPath){
                    //     console.log(dataLastSocialLoginTryPath);
                    //     appSetMove("",dataLastSocialLoginTryPath);
                    //     dispatch(actionSetLastSocialLoginTryPath(null));
                    //     return;
                    // }
                    appSet("login", appSetData);
                    appSetMove("",getBookpalLink("main"));

                } else {
                    dispatch(actionSetToken(data.result));
                    dispatch(actionSetLastSocialLoginType(data.result?.login_type));
                    dispatch(actionSetLastSocialLoginDate(getTodayDate()));
                    
//                     console.log(dataLastSocialLoginTryPath);
// alert(dataLastSocialLoginTryPath)
                    if(dataLastSocialLoginTryPath){
                        let state = {};
                        if(dataLastSocialLoginTryPath === "/mypage/edit"){
                            const timestampInSeconds = Math.floor(Date.now() / 1000);
                            state = {confirmEdit:true,expire:timestampInSeconds + (60*30)};
                        }
                        navigate(dataLastSocialLoginTryPath, {replace: true, state:state});
                        dispatch(actionSetLastSocialLoginTryPath("/"));
                        return;
                    }

                    if(!!webReturnPath){
                        navigate(webReturnPath, {replace: true});
                    } else {
                        navigate("/", {replace: true});
                    }
                    // navigate("/", {replace: true});
                }
            } else {
                switch(data?.code){
                    case "leave_member": alert("탈퇴된 회원 입니다."); navigate("/login", {replace: true}); break;
                    default: alert("시스템 문제가 발생하였습니다. 문제가 지속되면 관리자에게 문의주세요."); navigate("/login", {replace: true}); break;
                }
            }
            setSocialIsLoading(false);
        },
        onError:(e)=>{
            setSocialIsLoading(false);
            console.log(e);
            alert("통신이 실패하였습니다. 문제가 지속되면 관리자에게 문의주세요.");
        },
    });

    const socialLoginAsync = async (data) => {
        await socialLogin(data);
    };

    const socialConnectSns = ApiAuthSocialConnect({
        onSuccess:(data)=>{
            
            if(data?.status === true){
                // alert("연결되었습니다.");
                navigate("/mypage/editsns");
                dispatch(actionSetConnectSocialLoginType(null));
                return;
            }
            if(data?.code === "alredy_member"){
                alert("SNS연결","이미 다른계정에 연결된 소셜계정입니다.",()=>{
                    navigate("/mypage/editsns");
                });
            } else {
                alert("SNS연결","연결에 실패하였습니다.",()=>{
                    navigate("/mypage/editsns");
                });
            }
            
            
        },
        onError:(e)=>{
            
            alert("SNS연결","연결에 실패하였습니다.",()=>{
                navigate("/mypage/editsns");
            });
            
            dispatch(actionSetConnectSocialLoginType(null));
        }
    });

    useEffect(()=>{
        if(socialIsLoading && !!dataConnectSocialLoginType){
            console.log("dataConnectSocialLoginType", dataConnectSocialLoginType);
            socialConnectSns({
                type: dataConnectSocialLoginType,
                access_token: socialCode,
                state: socialState,
                redirect_url: process.env.REACT_APP_BASE_URL + "/login",
                apps:bookpalAgentData,
            });
        } else if(socialIsLoading){
            console.log("dataLastSocialLoginTryType", dataLastSocialLoginTryType);
            socialLoginAsync({
                type: dataLastSocialLoginTryType,
                access_token: socialCode,
                state: socialState,
                redirect_url: process.env.REACT_APP_BASE_URL + "/login",
                apps:bookpalAgentData,
                // auto_login:autoLogin,
            });
        }
        // console.log(autoLogin)
        setState.setAutoLogin(autoLogin);
        dispatch(actionSetConnectSocialLoginType(null));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


    useEffect(()=>{
        if(bookpalAgentData?.os_type === "ios" || bookpalAgentData?.os_type === "android"){
            setSaveId(false);
            if(autoLogin !== false && autoLogin !== true){
                setAutoLogin(true);
                
            }
        }
    },[bookpalAgentData]);

    const handlerSetSaveIdOrAutoLogin = () => {
        if(bookpalAgentData?.os_type === "web"){
            setSaveId(!saveId);
        } else {
            setState.setAutoLogin(!autoLogin);
            setAutoLogin(!autoLogin);
        }
    };

    const loginSubmit = ApiAuthLogin({
        onSuccess: (data) => {
            // console.log(data);
            if(data?.status === true){
                console.log(true, data );
                if(bookpalAgentData?.os_type === "ios" || bookpalAgentData?.os_type === "android"){
                    // 웹뷰 디바이스 함수 호출
                    // window.BOOKPAL.setLogin(JSON.stringify(data));
                    // console.log(true, "웹뷰 디바이스 함수 호출 구현해야함" );
                    const appSetData = {
                        ...data.result,
                        login_date:getTodayDate()
                    }

                    appSet("login", appSetData);
                    appSetMove("",getBookpalLink("main"));
                } else {
                    dispatch(actionSetToken(data.result));
                    dispatch(actionSetLastSocialLoginType(data.result?.login_type));
                    dispatch(actionSetLastSocialLoginDate(getTodayDate()));
                    if(!!webReturnPath){
                        navigate(webReturnPath, {replace: true});
                    } else {
                        navigate("/", {replace: true});
                    }
                }
            } else {
                let msg = "";
                switch(data?.code){
                    case '401': msg = "아이디/비밀번호가 입력되지 않았습니다.";     break;
                    case '402': msg = "아이디/비밀번호가 일치하지 않습니다.";       break;
                    case '403': msg = "탈퇴 처리된 회원입니다.";                   break;
                    case '405': msg = data?.message;                             break;
                    default:    msg = "시스템 문제가 발생하였습니다. 문제가 지속되면 관리자에게 문의주세요."; break;
                }
                // alert(msg);
                setErrorPasswdTxt(msg);
            }
        },
        onError: (e) => {
            console.log(e);
            // alert("통신이 실패하였습니다. 문제가 지속되면 관리자에게 문의주세요.");
            setErrorPasswdTxt("통신이 실패하였습니다. 문제가 지속되면 관리자에게 문의주세요.");
        }
    });

    const handleLogin = async (e) => {
        e.preventDefault();
        
        // ((loginId ?? "") === "") ? setErrorIdTxt("아이디를 입력해 주세요.") : setErrorIdTxt("");
        if((loginId ?? "") === ""){
            setErrorIdTxt("아이디를 입력해 주세요.")
            return;
        } else {
            setErrorIdTxt("");
        }

        // (loginPassword === "") ? setErrorPasswdTxt("비밀번호를 입력해 주세요.") : setErrorPasswdTxt("");
        // if(loginPassword === "") return;
        if((loginPassword ?? "") === ""){
            setErrorPasswdTxt("비밀번호를 입력해 주세요.")
            return;
        } else {
            setErrorPasswdTxt("");
        }

        if(saveId){
            dispatch(actionSetSaveId(loginId));
        } else {
            dispatch(actionSetSaveId(""));
        }

        await loginSubmit({ 
            login_id: loginId, 
            login_password: loginPassword, 
            auto_login: autoLogin ? "Y" : "N", 
            apps: bookpalAgentData?.company === "bookpal" ? bookpalAgentData:[],
        });
    };

    const handlerTooltip = () => {
        dispatch(actionSetToolTipLogin(true));
    };


    //--------------------------------------
    // social login
    //--------------------------------------

    const socialLoginGetUrl = ApiAuthSocialLoginUrl({
        onSuccess: (data) => {
            console.log(data);
            // console.log(appReturnLink);
            // return;
            if(data?.status === true){
                if(bookpalAgentData?.os_type === "ios" || bookpalAgentData?.os_type === "android"){
                    if(appReturnLink){
                        dispatch(actionSetLastSocialLoginTryPath(appReturnLink));
                    }
                }
                window.location.href = data?.result?.login_url;
            } else {
                alert("시스템 문제가 발생하였습니다. 문제가 지속되면 관리자에게 문의주세요.");
            }
        },
        onError: (e) => {
            console.log(e);
            alert("통신이 실패하였습니다. 문제가 지속되면 관리자에게 문의주세요.");
        },
    });

    const handlerSocialLoginUrl = async (social_type) => {
        dispatch(actionSetLastSocialLoginTryType(social_type));
        
        await socialLoginGetUrl({
            type: social_type,
            return_url: process.env.REACT_APP_BASE_URL + "/login",
        });
        
    };


    const handleKeyDownNext = (e) => {
        if (e.key === 'Enter' || e.key === 'NumpadEnter') {
            const passwordInput = document.getElementById('password');
            passwordInput.focus();
            // e.target.blur();
        } else  {
            
        }
    };

    const handleKeyDownLogin = (e) => {
        if (e.key === 'Enter' || e.key === 'NumpadEnter') {
            handleLogin(e);
            e.target.blur();
        } else  {
            
        }
    };
    

    if(socialIsLoading){
        return (
            <>
                Loading...
                <AlertModal />
            </>
        )
    }

    return (
        <>
            <SubNav 
                name="로그인" 
                usePc={false} 
                useMobile={true} 
                setTop={'0px'} 
                useBtnPrev={true} 
            />

            {/* <!-- container --> */}
            <div id="container" className={"wrapper " + style['container']}>
                <section className={"login_box " + style['login_box']}>
                    <p className={"title color-txt-normal title-04 " + style['title']}>로그인</p>
                    <div className={"area_box color-bg-01 radius-lg color-border-01 border-soild border-line " + style['area_box']}>
                        <div className={"logo_title " + style['logo_title']}>
                            <p className={"logo " + style['logo']}></p>
                            <p className={"body-01 color-txt-muted label_txt " + style['label_txt']}>당신의 상상이 이야기가 되는 곳</p>
                        </div>
                        <ul className={"input_box " + style['input_box']}>
                            <li className={errorIdTxt ? "error_text":""}>
                                <div className="text_input border-line border-soild radius-sm color-bg-01 color-border-02">
                                    <input 
                                        type="text" 
                                        placeholder="아이디" 
                                        name="loginId" 
                                        className="color-txt-normal body-03" 
                                        value={loginId} 
                                        onKeyDown={handleKeyDownNext}
                                        onChange={(e) => {
                                            setLoginId(e.target.value);
                                            setErrorIdTxt("");
                                        }} />
                                    <p className={"right_btns " + style['right_btns']}>
                                        <span className="btnClear"></span>
                                        <span className={"Error"}></span>
                                    </p>
                                </div>
                                <p className="bottom_txt caption-02 color-txt-05">{errorIdTxt}</p>
                            </li>
                            {/* <!-- 로그인 정보가 잘못되었을 경우, ID 또는 비밀번호 입력을 하지 않고 ‘로그인’ 버튼을 클릭했을 경우  error_text 추가 --> */}
                            <li className={errorPasswdTxt ? "error_text":""}>
                                <div className="text_input border-line border-soild radius-sm color-bg-01 color-border-02">
                                    <input 
                                        id="password"
                                        type="password" 
                                        placeholder="비밀번호" 
                                        className="color-txt-normal body-03" 
                                        value={loginPassword} 
                                        onKeyDown={handleKeyDownLogin}
                                        onChange={(e) => {
                                            setLoginPassword(e.target.value);
                                            setErrorPasswdTxt("");
                                        }} />
                                    <p className={"right_btns " + style['right_btns']}>
                                        <span className="btnClear"></span>
                                        <span className="Error"></span>
                                    </p>
                                </div>
                                <p className="bottom_txt caption-02 color-txt-05">{errorPasswdTxt}</p>
                            </li>
                        </ul>
                        <p className={"check_area " + style['check_area']}>
                            <label htmlFor="agree" className={"checkbox_round check-type-01 small label_on"}>
                                {
                                    (bookpalAgentData?.os_type === "web") ? (
                                        <input type="checkbox" id="agree_save" checked={saveId} onChange={(e)=>{setSaveId(e.target.checked)}} />
                                    ) : (
                                        <input type="checkbox" id="agree_auto" checked={autoLogin} onChange={(e)=>{setAutoLogin(e.target.checked)}} />
                                    )
                                }
                                
                                <span className={"box"} onClick={handlerSetSaveIdOrAutoLogin}></span>
                                <span className={"txt color-txt-04 " + style['txt']}  >
                                    {(bookpalAgentData?.os_type === "web") ? ("ID 저장하기") : ("자동로그인")}
                                </span>
                            </label>
                        </p>
                        <p className={"button_box " + style['button_box']}>
                            <button type="button" className={"radius-sm btn-04 txt btn_01 large label_on filled button " + style['button']} onClick={handleLogin}>로그인</button> 
                        </p>
                        <div className={"user_box " + style['user_box']}>
                            <p className={"find_box " + style['find_box']}>
                                <BookpalLink className={"btn-03"} to={"/find_id"}><span className="color-btn-03-txt">아이디찾기</span></BookpalLink>
                                <BookpalLink className={"btn-03"} to={"/find_pw"}><span className="color-btn-03-txt">비밀번호 찾기</span></BookpalLink>
                            </p>
                            <p className={"join_box " + style['join_box']}>
                                {/* <a href="/user/signup.html" className="btn-04"><span className="color-btn-02 txt">회원가입</span></a> */}
                                <BookpalLink className={"btn-04"} to={"/signup"}><span className="color-btn-02 txt">회원가입</span></BookpalLink>
                            </p>
                            {
                                !dataTooltipLogin ?
                                <>
                                {/* <div id="toolTip" className={style['toolTip']} onClick={handlerTooltip}>
                                    <button type="button" className={"radius-xs color-btn-02 bg top_left " + style['color-btn-02'] + " " + style['bg'] + " " + style['top_left']}>
                                        <span className="color-btn-02-txt caption-02-long">본인인증시 선물소장권 지급</span>
                                        <p className={"clear_btn " + style['clear_btn']} ></p>
                                    </button>
                                </div> */}
                                </>
                                : null
                            }
                            
                        </div>
                        <div className={"bottom_con " + style['bottom_con']}>
                            {
                                ["naver","kakao","google","apple"].includes(dataLastSocialLoginType) ? 
                                (
                                    <>
                                        <p className={"title " + style['title']}><span className="btn-02 color-btn-03-txt color-bg-01">또는</span></p>
                                        <p className={"button_box previous_login " + style['button_box'] + " " + style['previous_login']}>
                                            <button 
                                                type="button" 
                                                className="radius-sm btn_01 border-line border-soild color-btn-01 border" 
                                                onClick={(e)=>{e.preventDefault();handlerSocialLoginUrl(dataLastSocialLoginType)}}
                                            >
                                                <p className="btn-04 txt color-btn-01"><span>{strDataLastSocialLoginType}</span>로 로그인</p>
                                                <p className={"caption-01 color-txt-muted record " + style['record']}>마지막 로그인 : <span>{dataLastSocialLoginDate}</span></p>
                                            </button> 
                                        </p>
                                    </>
                                ) : null
                            }
                             
                            <div className={"sns_link " + style['sns_link']}>
                                <a href="#" id="naver" onClick={(e)=>{e.preventDefault();handlerSocialLoginUrl('naver')}}><img src={naverBtnImg} alt="naver login" /></a>
                                <a href="#" id="kakao" onClick={(e)=>{e.preventDefault();handlerSocialLoginUrl('kakao')}}><img src={kakaoBtnImg} alt="kakao login" /></a>
                                <a href="#" id="google" onClick={(e)=>{e.preventDefault();handlerSocialLoginUrl('google')}}><img src={googleBtnImg} alt="google login" /></a>
                                {/* <a href="#" id="facebook" onClick={(e)=>{e.preventDefault();handlerSocialLoginUrl('facebook')}}><img src={facebookBtnImg} alt="facebook login" /></a> */}
                                <a href="#" id="apple" onClick={(e)=>{e.preventDefault();handlerSocialLoginUrl('apple')}}><img src={appleBtnImg} alt="apple login" /></a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <AlertModal />
            {/* <!-- container --> */}
        </>
    )
}


export default Login;
