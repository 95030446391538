export const TOKENS = 'TOKENS';
export const MEMBER_INFO = 'MEMBER_INFO';

export const DOT = 'DOT';

export const KEYWORD_SAVE_MODE = 'KEYWORD_SAVE_MODE';
export const ADULT_SWITCH = 'ADULT_SWITCH';
export const SYSTEM_MODE = 'SYSTEM_MODE';
export const SAVE_ID = 'SAVE_ID';
export const SAVE_ID_CHECK = 'SAVE_ID_CHECK';
export const AUTO_LOGIN = 'AUTO_LOGIN';
export const APP_BANNER = 'APP_BANNER';

export const LAST_SOCIAL_LOGIN_TRY_PATH = 'LAST_SOCIAL_LOGIN_TRY_PATH';
export const LAST_SOCIAL_LOGIN_TRY_TYPE = 'LAST_SOCIAL_LOGIN_TRY_TYPE';
export const LAST_SOCIAL_LOGIN_TYPE = 'LAST_SOCIAL_LOGIN_TYPE';
export const LAST_SOCIAL_LOGIN_DATE = 'LAST_SOCIAL_LOGIN_DATE';
export const CONNECT_SOCIAL_LOGIN_TYPE = 'CONNECT_SOCIAL_LOGIN_TYPE';


export const VIEWER_INFO_FONT = 'VIEWER_INFO_FONT';
export const VIEWER_INFO_FONT_SIZE = 'VIEWER_INFO_FONT_SIZE';
export const VIEWER_INFO_LINE_HEIGHT = 'VIEWER_INFO_LINE_HEIGHT';
export const VIEWER_INFO_SIDE_MARGIN = 'VIEWER_INFO_SIDE_MARGIN';
export const VIEWER_INFO_STYLE = 'VIEWER_INFO_STYLE';
export const VIEWER_INFO_THEME = 'VIEWER_INFO_THEME';
export const VIEWER_INFO_IMAGE_NONE = 'VIEWER_INFO_IMAGE_NONE';
export const VIEWER_INFO_RUN = 'VIEWER_INFO_RUN';


//cert
export const CERT_RETURN_PATH = 'CERT_RETURN_PATH';

//toolTip
export const TOOLTIP_LOGIN = 'TOOLTIP_LOGIN';
export const TOOLTIP_SEARCH = 'TOOLTIP_SEARCH';


//toast
export const SHOW_GIFT_TOAST = 'SHOW_GIFT_TOAST';