import React from 'react';
import { useEffect, useState } from 'react';
import { ApiGenreNovelHome } from 'api/Genre';


import Header from 'component/common/header/Header';
import MobileNav from 'component/common/nav/MobileNav';

import Footer from 'component/common/footer/Footer';
import TopBtn from 'component/common/button/top/TopBtn';

import SubMenuNav from './nav/SubMenuNav';

import GenreHomeBanner from 'component/common/banner/GenreHomeBanner';
import VerticalSection from 'component/common/section/VerticalSection';
import RankingThreeLineSection from 'component/common/section/RankingThreeLineSection';
import VerticalLongSection from 'component/common/section/VerticalLongSection';
import SmallBanner from 'component/common/banner/SmallBanner';

import RecommendWorkSection from 'component/common/section/RecommendWorkSection';
import ExclusiveTypeOneSection from 'component/common/section/ExclusiveTypeOneSection';
import ExclusiveTypeTwoSection from 'component/common/section/ExclusiveTypeTwoSection';
import RankingSection from 'component/common/section/RankingSection';
import RecommendKeywordSection from 'component/common/section/RecommendKeywordSection';
import HorizontalSection from 'component/common/section/HorizontalSection';
import FreeChargeSection from 'component/common/section/FreeChargeSection';
import AppDownloadSection from 'component/common/section/AppDownloadSection';
import ExclusiveTypeSection from 'component/common/section/ExclusiveTypeSection';


function defaultBaseData(){
    const data = {};
    data['sections'] = [];
    const banner = [];
    for(let i=0,m=5;i<m;i++){
        banner.push({
            "type": "main",
            "adult_type": "all",
            "link": "",
            "bg_color": "",
            "description": "",
            "badge_txt": ""
        });
    }
    data['sections'].push({
        "type": "banner",
        "data": banner
    });
    data['sections'].push({
        type:"blank"
    })

    return data;
}

function Novel(){

    const [data, setData] = useState(defaultBaseData());

    const handlerNovelHome = ApiGenreNovelHome({
        onSuccess:(data)=>{
            if(data?.status===true){
                setData(data?.result);
            }
        }
    });

    useEffect(()=>{
        handlerNovelHome();
    },[])

    if(data === null){
        return <></>
    }

    return (
        <>
            <Header title={"웹소설"} />
            <MobileNav />
            {/* <!-- 서브메뉴 --> */}
            <SubMenuNav page="/novel"/>
            {/* <!-- //서브메뉴 --> */}
            
            {/* <!-- container --> */}
            <div id="container" className="sub_wrap wrap">
            
                {!!data?.sections && Array.isArray(data?.sections) ? 
                    data?.sections.map((item, index)=>(
                        <React.Fragment key={index}>
                            {(()=>{
                                switch(item?.type){
                                    case "banner":
                                        return <GenreHomeBanner 
                                                    gb={"novel"}
                                                    data={item?.data}
                                                    />
                                    case "vertical_sec":
                                    case "recommend_work":
                                    case "recent_view":
                                        return <VerticalSection 
                                                    data={item?.data} 
                                                    title={item?.title} 
                                                    moreLink={item?.link_more}
                                                    useInfo={true}
                                                    useWriter={true}
                                                    />
                                    case "recommend_keyword":
                                        return <RecommendKeywordSection 
                                                    data={item?.keyword} 
                                                    title={item?.keyword_genre} 
                                                    image={item?.image} 
                                                    />
                                    case "free_charge":
                                        return <FreeChargeSection data={item} />
                                    case "app_download":
                                        return <AppDownloadSection data={item} />
                                    case "vertical_novel_rank":
                                        return <RankingThreeLineSection 
                                                    tabs={item?.tabs}
                                                    title={item?.title}
                                                    />
                                    case "horizontal_toon_rank":
                                    case "horizontal_novel_rank":
                                        return <RankingSection 
                                                    tabs={item?.tabs}
                                                    title={item?.title}
                                                    />
                                    case "vertical_long_sec":
                                        return <VerticalLongSection 
                                                    data={item?.data}
                                                    moreLink={item?.link_more}
                                                    title={item?.title}
                                                    />
                                    case "horizontal_sec":
                                        return <HorizontalSection 
                                                    data={item?.data}
                                                    moreLink={item?.link_more}
                                                    title={item?.title}
                                                    />
                                    case "exclusive_sec":
                                        return <ExclusiveTypeSection 
                                                    item={item}
                                                    />
                                    case "small_banner":
                                        return <SmallBanner 
                                                    item={item}
                                                    data={item?.data}
                                                    />
                                    case "blank":
                                        return <>
                                            <div style={{height:"300px"}}></div>
                                        </>
                                            
                                }
                            })()}
                        </React.Fragment>
                    ))
                :null}
                {/* <!-- 장르홈 긴배너(추천) --> */}
                {/* <GenreHomeBanner /> */}

                
                {/* <!-- 세로표지 --> */}
                {/* <VerticalSection /> */}

                {/* <!-- 랭킹 --> */}
                {/* <RankingThreeLineSection /> */}

                {/* <!-- 세로 긴 카드섹션 --> */}
                {/* <VerticalLongSection /> */}
            
                {/* <!-- 스몰배너 -->  */}
                {/* <SmallBanner /> */}
                
            </div>
            {/* <!-- container --> */}

            <Footer />
            <TopBtn />
        </>
    );
}



export default Novel;