import { useApiMutation, useApiMutationPaging } from './common/ApiCommon';

export const ApiBookRankingList = (options) => {
    const subUrl = "/book/ranking_list";
    const data = options?.data || {};
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});
    const bottomFn = options?.bottomFn || (()=>{});
    const bottomRef = options?.bottomRef || (()=>{});
    const perPage = options?.perPage || 30;
    const pageName = options?.pageName || "page";
    const perPageName = options?.perPageName || "per_page";
    const pageLimit = options?.pageLimit || null;

    return useApiMutationPaging(subUrl, data, successFn, errorFn, bottomFn, perPage, pageName, perPageName, bottomRef, pageLimit);
};

export const ApiBookView = (options) => {

    const subUrl = "/book/view";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiBookGetMidnight = (options) => {

    const subUrl = "/book/get_midnight";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiBookFirstView = (options) => {

    const subUrl = "/book/first_view";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

// export const ApiBookChapterList = (options) => {

//     const subUrl = "/book/chapter_list";
//     const successFn = options?.onSuccess || (()=>{});
//     const errorFn = options?.onError || (()=>{});

//     const mutation = useApiMutation(subUrl, successFn, errorFn);
//     return mutation;
// };

export const ApiBookChapterList = (options) => {
    const subUrl = "/book/chapter_list";
    const data = options?.data || {};
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});
    const bottomFn = options?.bottomFn || (()=>{});
    const bottomRef = options?.bottomRef || (()=>{});
    const perPage = options?.perPage || 30;
    const pageName = options?.pageName || "page";
    const perPageName = options?.perPageName || "per_page";

    return useApiMutationPaging(subUrl, data, successFn, errorFn, bottomFn, perPage, pageName, perPageName, bottomRef);
};

export const ApiBookBookIntro = (options) => {

    const subUrl = "/book/book_intro";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiBookUseDetail = (options) => {
    const subUrl = "/book/use_detail";
    const data = options?.data || {};
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});
    const bottomFn = options?.bottomFn || (()=>{});
    const bottomRef = options?.bottomRef || (()=>{});
    const perPage = options?.perPage || 30;
    const pageName = options?.pageName || "page";
    const perPageName = options?.perPageName || "per_page";

    return useApiMutationPaging(subUrl, data, successFn, errorFn, bottomFn, perPage, pageName, perPageName, bottomRef);
};