import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

function useSelectArrModal(data, _selectData, _options){

    const options = data || [];
    const noneActive = _options?.noneSelect || false;
    const title = _options?.title || false;
    const info = _options?.info || false;
    const selectFn = _options?.selectFn ? ((key)=>{_options?.selectFn(key)}) : (()=>{});
    const selectData = _selectData || false;

    // const _keyName = _options?.keyName || "key";
    // const _valueName = _options?.valueName || "value";

    const [_keyName, setKeyName] = useState(_options?.keyName || "key");
    const [_valueName, setValueName] = useState(_options?.valueName || "value");

    // console.log(selectData)
    const [selectKey, setSelectKey] = useState(selectData);
    // console.log(selectKey)
    const [showModal, setShowModal] = useState(false);
    
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    // const keyData = selectKey;

    const handleSelect = (key) => {
        console.log(key)
        // alert(key)
        if(!noneActive) setSelectKey(key);
        selectFn(key);
        handleClose();
    }

    const initSelect = ()=>{
        // setSelectKey(selectData);
    }

    const getValue = ()=>{
        return options.map((item)=>{
            // console.log(selectKey)
            if((item[_keyName] ?? null) == selectKey){
                return (item[_valueName] ?? null);
            }
        });
    }

    const optionElements = [];
    options.map((item,index)=>{
        optionElements.push(
            <p 
                key={index} 
                data-key={(item[_keyName] ?? null)}
                className={"radius-sm color-bg-modal color-txt-normal body-02 " + ( (item[_keyName] ?? null) == selectKey ? "click":"")}
                style={{padding:"0 16px"}}
                onClick={(obj)=>{
                    // e.preventDefault()
                    console.log(obj);
                    const targetkey = obj.target.attributes['data-key'].value;
                    console.log(targetkey);
                    handleSelect(targetkey);
                }}
            >
                {(item[_valueName] ?? null)}
            </p>
        );
    })
    // Object.keys(options).forEach(key => {
    //     // console.log(key);
    //     // const opkey = key;
    //     const value = options[key];
    //     optionElements.push(
    //         <p 
    //             key={key} 
    //             data-key={key}
    //             className={"radius-sm color-bg-modal color-txt-normal body-02 " + (key == keyData ? "click":"")}
    //             style={{padding:"0 16px"}}
    //             onClick={(obj)=>{
    //                 // e.preventDefault()
    //                 console.log(obj);
    //                 const targetkey = obj.target.attributes['data-key'].value;
    //                 console.log(targetkey);
    //                 handleSelect(targetkey);
    //             }}
    //         >
    //             {value}
    //         </p>
    //     );
    // });

    useEffect(()=>{
        initSelect();
    },[]);


    const ModalTmpl =()=>{ 
        return (
        <>
            <Modal 
                // className="select_modal modal black_opacity-60" 
                className="select_modal modal" 
                backdropClassName="black_opacity-60 opacity-10"
                dialogClassName="modal-dialog-centered"
                contentClassName="radius-xxl color-bg-modal"
                show={showModal}
                animation={false} 
                onHide={handleClose}
                >
                <Modal.Body>
                    {title ? <div className="title title-02-b color-txt-normal">{title}</div>:null}
                    <div className="scroll_box select_box">
                    {optionElements}
                    </div>
                    {info ? <div className="desc color-txt-muted body-02-long">*{info}</div>:null}
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="radius-xs btn-03 txt btn_03 medium label_on filled button" onClick={handleClose}>취소</button> 
                </Modal.Footer>
            </Modal>
        </>
    )};
    
    return [ModalTmpl, handleShow, selectKey, setSelectKey, initSelect, getValue];
}


export default useSelectArrModal;