import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import NewlineToBr from '../content/NewlineToBr';

function useConfirmCustomModal(){


    const [title, setTitle] = useState(false);
    const [content, setContent] = useState(false);
    const [info, setInfo] = useState(false);
    const [successBtnTitle, setSuccessBtnTitle] = useState("확인");
    const [successBtnTitle2, setSuccessBtnTitle2] = useState("확인");
    const [cancleBtnTitle, setCancleBtnTitle] = useState("취소");
    const [action, setAction] = useState({});
    const [showModal, setShowModal] = useState(false);

    const [noneClose, setNoneClose] = useState(false);

    const handleClose = () => {
        if(noneClose){
            return;
        }
        if(action?.closeAction){
            action?.closeAction();
        }
        setShowModal(false);
    }
    const handleShow = (data) => {

        setTitle(data?.title || false);
        setContent(data?.content || false);
        setInfo(data?.info || false);
        setSuccessBtnTitle(data?.successBtnTitle || "확인");
        setSuccessBtnTitle2(data?.successBtnTitle2 || null);
        setCancleBtnTitle(data?.cancleBtnTitle || "취소");
        setNoneClose(data?.noneClose || false);
        setAction({
            ...action,
            cancleAction:data?.cancleAction,
            successAction:data?.successAction,
            successAction2:data?.successAction2,
            closeAction:data?.closeAction,
        })
        
        setShowModal(true);
    };

    const handleCancle = () => {
        if(action?.cancleAction){
            action?.cancleAction();
        }
        setShowModal(false);
        setAction({
            ...action,
            cancleAction:null,
            closeAction:null,
            successAction:null,
        })
    }

    const handleSelect = () => {
        if(action?.successAction){
            action?.successAction();
        }
        // handleClose();
        setShowModal(false);
        setAction({
            ...action,
            cancleAction:null,
            closeAction:null,
            successAction:null,
        })
    }

    const handleSelect2 = () => {
        if(action?.successAction2){
            action?.successAction2();
        }
        // handleClose();
        setShowModal(false);
        setAction({
            ...action,
            cancleAction:null,
            closeAction:null,
            successAction:null,
            successAction2:null,
        })
    }


    const ModalTmpl =()=>{ 
        return (
        <>
            <Modal 
                // className="black_opacity-60" 
                backdropClassName="black_opacity-60 opacity-10"
                dialogClassName="modal-dialog-centered"
                contentClassName="radius-xxl color-bg-modal"
                show={showModal}
                animation={false} 
                onHide={handleClose}
                >
                <Modal.Body>
                    {title ? <div className="title title-02-b color-txt-normal">{title}</div> : null}
                    {content ? <div className="desc body-03-long"><NewlineToBr text={content} /></div> : null}
                    {info ? <div className="desc body-01-long color-btn-01 txt">
                    ※ {info}
                    {/* 웹툰은 선물권 소진 후 쿠폰으로 자동 소장 */}
                    </div> : null}
                    
                    {/* <div className="select_box">
                    {optionElements}
                    </div> */}
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="radius-sm btn-04 txt btn_01 large label_on filled button" onClick={handleSelect}>{successBtnTitle}</button>
                    {successBtnTitle2 ? <button
                        type="button"
                        className="radius-sm btn-04 txt btn_01 large label_on outlined button"
                        onClick={handleSelect2}
                        >
                        {successBtnTitle2}
                    </button> : null}
                    <button type="button" className="radius-xs btn-03 txt btn_03 medium label_on filled button" onClick={handleCancle}>{cancleBtnTitle}</button> 
                </Modal.Footer>
            </Modal>
        </>
    )};
    
    return [ModalTmpl, handleShow];
}


export default useConfirmCustomModal;