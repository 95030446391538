import { useNavigate } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import { ApiCommonHelpNotice } from 'api/Common';
import { getNbpLink } from 'component/common/custom/BookpalLink';

import Header from 'component/common/header/Header';
import Footer from 'component/common/footer/Footer';

import SubNav from "component/common/nav/SubNav";

import style from '../../mypage/style/Mypage.module.scss';


import img_novel from 'assets/images/novel/thumbnail_bg2.png';
import img_thumbnail_illust2 from 'assets/images/novel/thumbnail_illust2.png';
import { useEffect, useRef, useState } from 'react';
import NewlineToBr from 'component/common/content/NewlineToBr';

function HelpNotice(){


    const navigate = useBookpalNavigate();
    
    const contentRef = useRef(null);

    const [selectData, setSelectData] = useState(null);
    const handlerSelect = (e) => {
        const index = e.target.getAttribute("data-index");
        setSelectData(selectData == index ? null : index);
    }
    const [data, dataSubmit, dataInit, paging] = ApiCommonHelpNotice({
        bottomRef:contentRef,
        onSuccess:(data)=>{
            console.log(data);
        },
        onError:(e)=>{
            console.log(e);
        }
    });

    useEffect(() => {
        
        const handleClick = (event) => {
            if (event.target.tagName === 'A') {
                event.preventDefault();
                const href = event.target.getAttribute('href');
                console.log('Link clicked:', href);
                console.log(getNbpLink(href))

                const link = getNbpLink(href);
                // 원하는 함수를 여기서 실행합니다.
                // yourFunction(href);
                if(typeof link === "string" && (link?.startsWith("http://") || link?.startsWith("https://"))){
                    window.location.href = link;
                    return;
                }
                navigate(getNbpLink(href));
            }
        };

        const contentElement = contentRef.current;
        if (contentElement && data) {
            contentElement.addEventListener('click', handleClick);
        }

        return () => {
            if (contentElement) {
                contentElement.removeEventListener('click', handleClick);
            }
        };
    }, [data]);
    
    useEffect(()=>{
        dataSubmit();
    },[]);

    return (
        <>
            <Header useMobile={false} pcTop={"-64px"} />
            <SubNav 
                name={"공지사항"}
                usePc={true} 
                useMobile={true} 
                usePcRightBtns={true}
                useBtnPrev={true}
                useBtnPcPrev={true}
                useBtnHome={true}

                setTop={'0px'} 
                setPcTop={'0px'} 
                />



            
            {/* <!-- container --> */}

            <div id="container" className={style['containerr']} ref={contentRef}>

                <section className={"section wrapper " + style['section'] + " " + style['wrapper'] + " " + style['notice_list']}>
                    <ul className={"toggle_list " + style['toggle_list']}>
                        {data && Array.isArray(data) ? 
                            data.map((item, index)=>(
                                <li key={index} data-index={index} className="border-line border-soild radius-sm color-border-01">
                                    <button 
                                        type="button" 
                                        className={"button large color-bg-01 label_on " + style['button'] + " " + (selectData == index ? style["click"]:"")}
                                        data-index={index}
                                        onClick={handlerSelect}
                                        >
                                        <div className={"info_txt " + style['info_txt']} data-index={index}>
                                            <p data-index={index} className="title color-txt-02 title-01-m-long">{item?.title}</p>
                                            <p data-index={index} className={"date color-txt-05 body-01 " + style['date']}>{item?.date}</p>
                                        </div>
                                        {/* <span data-index={index} className="color-txt-02 title-01-m-long">{item?.title}</span> */}
                                    </button>
                                    <div 
                                        className={"txt_box color-txt-03 body-02-long border-soild border-top-line color-bg-02 color-border-01 " + style['txt_box']}
                                        style={{display:(selectData==index ? "block":"none")}}
                                        >
                                        <div className={"desc body-02-long color-txt-03 " + style['desc']}>
                                            {/* <NewlineToBr text={item?.content?.split("<br />").join("").split("<br/>").join("").split("<br>").join("") ?? ""} /> */}
                                            {item?.image != "" && item?.image ? 
                                            <img src={item?.image} />
                                            :null}
                                            <div dangerouslySetInnerHTML={{ __html: item?.content }}>
                                            </div>
                                        </div>
                                    </div>
                                </li> 
                            ))
                        : null}
                    </ul>
                </section>

            </div>
            {/* <!-- container --> */}

            <Footer />
        </>
    );
}




export default HelpNotice;