import { useEffect, useState } from 'react';
import { ApiMypageEmblem, ApiMypageSetEmblem } from 'api/Mypage';
import useConfirmModal from 'component/common/modal/useConfirmModal';
import Header from 'component/common/header/Header';
import Footer from 'component/common/footer/Footer';

import SubNav from "component/common/nav/SubNav";

import style from '../style/Mypage.module.scss';

import img_emblem from 'assets/images/mypage/emblem.png';
import { useNavigate } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import { appSetMove, isApp } from 'utils/AppUtil';
import { SetState } from 'utils/State';
import useAlertModal from 'component/common/modal/useAlertModal';


function Emblem(){

    const navigate = useBookpalNavigate();
    const setState = SetState();

    const [emblem, setEmblem] = useState(null);
    const [selectEmblem, setSelectEmblem] = useState(null);
    const [AlertModal, alert] = useAlertModal();

    const handlerSetEmblem = ApiMypageSetEmblem({
        onSuccess:(data)=>{
            if(data?.status === true){
                // alert("엠블럼","엠블럼을 변경하였습니다.",()=>{
                    
                // });

                setState.setToast({
                    title:"엠블럼이 변경되었습니다",
                });

                if(isApp()){
                    appSetMove("","/mypage");
                    return;
                }
                navigate("/mypage",{replace:true});
                return;
            }
        },
        onError:(e)=>{

        }
    })

    const [Modal, showModal] = useConfirmModal({
        title:"선택한 엠블럼으로 설정하기",
        btnTitle:"확인",
        action:()=>{
            handlerSetEmblem({
                emblem_id:selectEmblem,
                mode:"set"
            });
        }
    });

    const handlerSelectEmblem = (e)=>{
        const id = e.target.getAttribute("data-id");
        console.log(id)
        if(id){
            setSelectEmblem(id);
            showModal();
        }
    }

    const handlerEmblemSubmit = ApiMypageEmblem({
        onSuccess:(data)=>{
            if(data?.status === true){
                setEmblem(data?.result?.data);
            }
        },
        onError:(e)=>{

        }
    });

    useEffect(()=>{
        handlerEmblemSubmit();
    },[]);


    if(emblem === null){
        return (
            <></>
        )
    }

    return (
        <>
            <Header useMobile={false} />
            <SubNav 
                name={"엠블럼"}
                usePc={true} 
                useMobile={true} 
                usePcRightBtns={true}
                useBtnPrev={true}
                useBtnPcPrev={true}
                useBtnHome={true}

                setTop={'0px'} 
                setPcTop={'64px'} 
                />
            {/* <!-- container --> */}
            <div id="container">
                <section className={"myEmblem wrapper section " + style['myEmblem'] + " " + style['wrapper'] + " " + style['section']}>
                    <div className={"tit_con clearfix " + style['tit_con']}>
                        <div className="left">
                            <p className="title body-01 color-txt-muted"><span className="title-02-b color-txt-normal">내 엠블럼 </span>엠블럼을 선택해주세요.</p>
                        </div>
                    </div>
                    {emblem && Array.isArray(emblem) && emblem?.length === 0 ? 
                        <p className="no_data small body-02-long color-txt-04">보유한 엠블럼이 없습니다.</p>
                    : null}
                    
                    <div className={"emblem_list list " + style['emblem_list'] + " " + style['list']}>
                        {emblem && Array.isArray(emblem) && emblem?.length > 0 ?
                        <ul>
                             {emblem.map((item, index)=>(
                                <li 
                                    key={index}
                                    data-id={item?.id}
                                    className={(item?.is_use === "Y" ? style['active']:"")}>
                                    <a 
                                        data-id={item?.id}
                                        style={{cursor:"pointer"}}
                                        className="radius-xl" 
                                        onClick={handlerSelectEmblem}>
                                        <img data-id={item?.id} src={(process.env.REACT_APP_IMG_URL ?? "https://img.bookpal.co.kr") + "/_DATA/store_emblems/" +item?.file} />
                                    </a>
                                </li>
                            ))}
                        </ul>
                        : <p className="no_data small body-02-long color-txt-04">보유한 엠블럼이 없습니다.</p>}
                        {/* <!-- 엠블럼 없을때 --> */}
                        {/* <!-- <p className="no_data small body-02-long color-txt-04">보유한 엠블럼이 없습니다.</p> --> */}
                    </div>
                </section>
            </div>
            {/* <!-- container --> */}

            <Footer />
            <Modal />
            <AlertModal />
        </>
    );
}




export default Emblem;