import NavBtn from 'component/common/button/nav/NavBtn';
import { useLocationName, useNoneMobileHeaderList, useIsMobile } from 'utils/Common';

function MobileNav(){

    const page = useLocationName();
    const isMobile = useIsMobile();
    const none_display = (useNoneMobileHeaderList(page) && isMobile ? true:false);

    return (
        <ul id="Mnavigation" className="color-bg-01 gnb" style={(none_display)?{"display":"none"}:{}}>
            <NavBtn mode={"mobile"} active={page=="main"?true:false} to="/" className={"home"}>추천</NavBtn>
            <NavBtn mode={"mobile"} active={page=="novel"?true:false} to="/novel" className={"novel"}>웹소설</NavBtn>
            <NavBtn mode={"mobile"} active={page=="toon"?true:false} to="/toon" className={"toon"}>웹툰</NavBtn>
            <NavBtn mode={"mobile"} active={page=="giftbox"?true:false} to="/giftbox" className={"gift"}>선물함</NavBtn>
            <NavBtn mode={"mobile"} active={page=="storage"?true:false} to="/storage/recent" className={"storage"}>보관함</NavBtn>
        </ul>
    )
}

export default MobileNav;