import { Link, useLocation } from 'react-router-dom';
import BookpalLink from 'component/common/custom/BookpalLink';

import style from '../detail/style/DetailGift.module.scss'
import { useIsMobile } from 'utils/Common';
import { isApp } from 'utils/AppUtil';


function MypageGiftMenuSubNav(){

    const location = useLocation();
    const to = location.pathname;
    const isMobile = useIsMobile();

    return (
        // <ul className={"tab_list menu_tab large " + style['tab_list'] + " " + style['menu_tab']} style={{position:"sticky",top:(isMobile ? "48px":"64px")}}>
        //     <li className="color-txt-muted btn-04">
        //         <Link to={"/mypage/gift/givelist"} className={"border border-soild border-bottom-line " + (location?.pathname === "/mypage/gift/givelist" ? "active":"")}>받은내역</Link>
        //     </li>
        //     <li className="color-txt-muted btn-04">
        //         <Link to={"/mypage/gift/uselist"} className={"border border-soild border-bottom-line " + (location?.pathname === "/mypage/gift/uselist" ? "active":"")}>사용내역</Link>
        //     </li>
        // </ul>

        <div id="subDepth" className={"color-bg-01 type_header " + style['subDepth'] + " " + style['type_header']} style={{position:"sticky",top:isApp()?"0px":(isMobile ? "48px":"64px")}}>
            <div className="swiper-container">
                <div className="swiper-wrapper txt color-txt-muted">
                    {/* <!-- 클릭시 className="on" 추가--> */}
                    <div className={"swiper-slide " + style['swiper-slide'] + " " + (to==="/mypage/gift/givelist" ? "on":"")}>
                        <BookpalLink to={"/mypage/gift/givelist"}>
                            <span className="color-btn-01 border border-soild btn-04">받은내역</span>
                        </BookpalLink>
                    </div>
                    <div className={"swiper-slide " + style['swiper-slide'] + " " + (to==="/mypage/gift/uselist" ? "on":"")}>
                        <BookpalLink to={"/mypage/gift/uselist"}>
                            <span className="color-btn-01 border border-soild btn-04">사용내역</span>
                        </BookpalLink>
                    </div>
                </div>
            </div>
        </div>
    )
}



export default MypageGiftMenuSubNav;