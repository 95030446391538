import { useLocation } from 'react-router-dom';
import { ApiPayProdGeneralList, ApiPayReady } from 'api/Pay';
import { isApp } from 'utils/AppUtil';
import { runPopupBookpalBankOrder, runPopupBookpalBankOrderInfo } from 'utils/Common';

import ComponentCoupon from '../component/ComponentCoupon';
import ComponentPay from '../component/ComponentPay';

import style from '../style/Order.module.scss';


import img_rating_label_normal from 'assets/images/rating_label_normal.svg';
import img_rating_label_vip from 'assets/images/rating_label_vip.svg';
import img_rating_label_vip_silver from 'assets/images/rating_label_vipSilver.svg';
import img_rating_label_vip_gold from 'assets/images/rating_label_vipGold.svg';
import img_rating_label_rvip from 'assets/images/rating_label_rVip.svg';

import img_kakao_logo from 'assets/images/order/kakao_logo.png';
import img_naver_logo from 'assets/images/order/naver_logo.png';
import img_payco_logo from 'assets/images/order/payco_logo.png';

import img_charge_banner1 from 'assets/images/order/charge_banner1.png';
import img_charge_banner2 from 'assets/images/order/charge_banner2.png';
import { useEffect, useState } from 'react';

function GeneralOrder(){

    const location = useLocation();
    const [prodList, setProdList] = useState(null);
    const [selectProd, setSelectProd] = useState(null);

    const [payList, setPayList] = useState(null);
    const [selectPay, setSelectPay] = useState(null);
    const [selectPayPrice, setSelectPayPrice] = useState(null);

    const [vbank, setVbank] = useState(null);

    const [payHidden, setPayHidden] = useState(true);

    const [isPaySubmit, setIsPaySubmit] = useState(false);

    const loadProdListSubmit = ApiPayProdGeneralList({
        onSuccess:(data)=>{
            console.log(data);
            if(data?.status === true){
                const prod_result = [
                    ...data?.result?.prod
                ];

                setProdList(prod_result);

                Object.keys(prod_result).forEach(key => {
                    const data2 = prod_result[key];
                    if(data2?.default == true){
                        setSelectProd(data2?.product_code);
                        setSelectPayPrice(data2?.price);
                    }
                });

                const pay_result = {
                    ...data?.result?.pay
                };

                setPayList(pay_result);

                setVbank(data?.result?.vbank ?? false);
                return;
            }

            alert("시스템 오류입니다. 다시 시도해주세요.");
            //window.close();
        },
        onError:(e)=>{
            alert("통신이 실패하였습니다. 다시 시도해주세요.");
            //window.close();
        }
    });

    const handlerPopupOverseas = (e)=>{
        e.preventDefault(); 
        var popupWidth = 380;
        var popupHeight = 640;
        var popupX = (window.innerWidth - popupWidth) / 2;
        var popupY = (window.innerHeight - popupHeight) / 2;
        var url = process.env.REACT_APP_BASE_URL + "/order/overseas";
        var popupX = (window.screenX + (window.innerWidth - popupWidth) / 2);
        var popupY = (window.screenY + (window.innerHeight - popupHeight) / 2);
        window.open(url, '', 'status=no, location=no, height=' + popupHeight + ', width=' + popupWidth + ', left=' + popupX + ', top=' + popupY);
    }
    const handlerPayHiddenToggle = () => {
        setPayHidden(!payHidden);
    }
    const handlerPayReady = ApiPayReady({
        onSuccess:(data)=>{
            console.log(data);
            if(data?.status === true){
                submitFormWithPay(data?.result?.action, data?.result?.data);
            }
        },
        onError:()=>{

        }
    });
    const submitPay = ()=>{
        // $param['pay']               = $json_data['pay'] ?? "";
        // $param['prod_code']         = $json_data['prod_code'] ?? "";
        // $param['front_return_url']  = $json_data['front_return_url'] ?? "";
        // $param['front_close_url']   = $json_data['front_close_url'] ?? "";
        if(selectPay === "BPVB"){
            runPopupBookpalBankOrder(selectProd, selectPayPrice);
        } else {
            handlerPayReady({
                pay:selectPay,
                prod_code:selectProd,
                front_return_url:process.env.REACT_APP_BASE_URL + "/order/general",
                front_close_url:process.env.REACT_APP_BASE_URL + "/order/general",
            })
        }
        
    }

    const submitFormWithPay = (action, data)=>{
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        const form = document.createElement('form');
        form.setAttribute('method', 'post');
        form.setAttribute('action', action?.ready_url);

        Object.keys(data).forEach(key=>{
            const input = document.createElement('input');
            const p_data = data[key];
            input.setAttribute('type', 'hidden');
            input.setAttribute('name', key);
            input.setAttribute('value', p_data);

            form.appendChild(input);
        });

        switch(action?.pay){
            case "INPC":
            case "INPV":
            case "INPB":
                window.resizeTo(900,700);
                break;
            case "KAKP":
                const input_kakao = document.createElement('input');
                const p_data_kakao = isApp() ? "app": (isMobile ? "mobile":"pc");
                input_kakao.setAttribute('type', 'hidden');
                input_kakao.setAttribute('name', "next_move");
                input_kakao.setAttribute('value', p_data_kakao);
                form.appendChild(input_kakao);
                break;
        }

        document.body.appendChild(form);
        form.submit();

        document.body.removeChild(form);
    };

    useEffect(()=>{
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        loadProdListSubmit({
            type:isMobile ? "mobile":"pc"
        });
    },[]);

    useEffect(()=>{
        if(selectProd && selectPay){
            setIsPaySubmit(true);
            return;
        }
        setIsPaySubmit(false);
    },[selectProd, selectPay]);


    //로딩중..
    if(prodList === null || payList === null){
        return (
            <></>
        );
    }

    const prodListElements = [];

    Object.keys(prodList).forEach(key => {
        const data = prodList[key];
        
        prodListElements.push(
            <ComponentCoupon 
                key={key} 
                data={data} 
                prodCode={selectProd} 
                setProdCode={setSelectProd}
                setPrice={setSelectPayPrice}
                 />
        );
    });

    const payEasyListElements = [];

    Object.keys(payList?.easy).forEach(key => {
        const data = payList?.easy[key];
        
        payEasyListElements.push(
            <ComponentPay key={key} uniqKey={"a"+key} vbank={vbank} data={data} payCode={selectPay} setPayCode={setSelectPay} />
        );
    });

    const payGeneralListElements = [];

    Object.keys(payList?.general).forEach(key => {
        const data = payList?.general[key];
        
        payGeneralListElements.push(
            <ComponentPay key={key} uniqKey={"b"+key} vbank={vbank} data={data} payCode={selectPay} setPayCode={setSelectPay} hidden={payHidden} />
        );
    });


    return (
        <>
            <div className="list_1" style={{display:""}}>
                <div className={"article " + style['article']}>
                    <div className={"tit_con " + style['tit_con']}>
                        <h2 className="title left text_overflow title-01-b color-txt-normal">결제 상품 선택</h2>
                        <div className={"right " + style['right']}>
                            <button 
                                type="button" 
                                className={"info_btn button " + style['info_btn'] + " " + style['button']}  
                                onClick={handlerPopupOverseas}
                                >
                                <span className="btn-03 color-btn-03-txt">해외이용자 결제 안내</span>
                            </button>
                        </div>
                    </div>
                    <ul className={"list_box product_list " + style['list_box'] + " " + style['product_list']}>
                        {prodListElements}
                    </ul>
                </div>
                <div className={"article " + style['article']}>
                    <div className={"tit_con " + style['tit_con']}>
                        <h2 className="title left text_overflow title-01-b color-txt-normal">간편결제</h2>
                    </div>
                    <ul className={"list_box check_list " + style['list_box'] + " " + style['check_list']}>
                        {payEasyListElements}
                        
                        
                    </ul>
                </div>
                <div className={"article " + style['article']}>
                    <div className={"tit_con " + style['tit_con']}>
                        <h2 className="title left text_overflow title-01-b color-txt-normal">일반결제</h2>
                    </div>
                    <ul className={"list_box check_list pay_list " + style['list_box'] + " " + style['check_list'] + " " + style['pay_list']}>
                        {payGeneralListElements}
                        {/* <!-- label 클릭시 className="color-bg-select-01" 추가 --> */}
                    </ul>
                    <div className={"list_btn " + style['list_btn']}>
                        <button 
                            type="button" 
                            className={"button btn_03 label_on small outlined round radius-full " + (payHidden ? style['click'] : "")}
                            onClick={handlerPayHiddenToggle}
                            ><span className="btn-03 color-txt-04">{payHidden ? "더보기" : "접기"}</span></button>
                    </div>
                </div>
            </div>
            <div className="fixed_button">
                <button 
                    type="button" 
                    className="button btn_01 filled btn-05 white-always txt" 
                    disabled={!isPaySubmit}
                    onClick={submitPay}
                    ><span>{selectPayPrice}</span>원 결제하기</button>
            </div>
        </>
    );
}



export default GeneralOrder;