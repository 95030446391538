import { useState } from 'react';
import { ImgBadge } from 'utils/Images';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import BookpalLink from 'component/common/custom/BookpalLink';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import { ApiBuyBuyCheck, ApiBuyBook } from 'api/Buy';
import { runPopupOrder } from 'utils/Common';
import { SetState, GetState } from 'utils/State';


import style from '../../style/Contents.module.scss';


function HeaderEpubEpisodeList({data, bid, cid, type, alert, buyConfirm}){

    const navigate = useBookpalNavigate();
    const location = useLocation();

    const setState = SetState();
    const getState = GetState();

    // const viewerRun = getState.useGetViewerInfoRun();

    // const epubData = (prevOrNext === "prev" ? data?.prev_chapter : data?.next_chapter);

    const [buyCheck, setBuyCheck] = useState(null);

    const buySubmit = ApiBuyBook({
        onSuccess:(_data)=>{
            if(_data?.status === true){
                getViewPageLink();
                setState.setToast({
                    title:_data?.result?.msg,
                })
                return;
            }
            switch(_data?.code){
                case "520":
                    buyConfirm({
                        title:"구매 안내",
                        bookTitle:buyCheck?.buy_info?.title,
                        content:buyCheck?.buy_info?.no + "권을 열람하시겠습니까?",
                        isPay:false,
                        currentConpon:_data?.result?.my_coupon_cnt,
                        useConpon:buyCheck?.buy_info?.price,
                        useVoucher:false,
                        successBtnTitle:"쿠폰 충전하러 가기",
                        cancleBtnTitle:"다음에 할게요",
                        successAction:()=>{
                            runPopupOrder();
                        },
                    });
                    break;
                case "513":
                    alert("구매 안내","무료 회차입니다.");
                    break;
                case "512":
                    alert("구매 안내","구매 가능한 회차가 없습니다.");
                    break;
                case "511":
                    alert("구매 안내","이미 구매한 회차입니다.");
                    break;
                default:
                    alert("구매 안내",_data?.message);
                    break;
            }
        },
        onError:(e)=>{

        }
    })

    const checkSubmit = ApiBuyBuyCheck({
        onSuccess:(_data)=>{
            if(_data?.status === true){
                const result = _data?.result;
                const buy_info = result?.buy_info;
                setBuyCheck(result);

                if(buy_info?.is_buy === true){
                    getViewPageLink();
                    return;
                }

                if(buy_info?.is_event_chapter_free === true){
                    getViewPageLink();
                    return;
                }

                if(buy_info?.is_free === true){
                    getViewPageLink();
                    return;
                }
                // const my_voucher = result?.my_voucher;
                // const isPay = result?.my_coupon_cnt > 0;
                const useVoucher = buyConfirm({
                    title:"구매 안내",
                    bookTitle:buy_info?.title,
                    content:buy_info?.no + "권을 열람하시겠습니까?",
                    isPay:true,
                    currentConpon:buy_info?.user_coupon,
                    useConpon:buy_info?.price,
                    useVoucher:false,
                    successBtnTitle:(true?"소장하기":"확인"),
                    success2BtnTitle:"",
                    successAction:(useVoucher)=>{
                        console.log("useVoucher",useVoucher);
                        buySubmit({
                            bid:bid,
                            cid:data?.epid,
                            epub:true,
                            buy_type:"buy",
                        });
                    },
                    success2Action:()=>{
                        // buySubmit({
                        //     bid:data?.bid,
                        //     cid:data?.cid,
                        //     epub:data?.epub,
                        //     buy_type:"v_rent",
                        // });
                    },
                });
                return;
            }
            switch(_data?.code){
                case "400":
                    alert("알림","책정보가 없습니다.");
                    break;
                case "406":
                    alert("알림","블라인드 작품입니다.");
                    break;
                case "403":
                    alert("알림","작품 감상을 위해 로그인이 필요합니다.",()=>{
                        navigate("/login",{state:{return_path:location.pathname}});
                    });
                    break;
                default:
                    alert("알림","시스템에 문제가 발생하였습니다. 문제가 지속되면 관리자에게 문의주세요.");
                    break;
            }
        },
        onError:(e)=>{

        },
    });

    const handlerCheckPay = (is_paid)=>{
        // if(is_paid === "true"){
        //     getViewPageLink();
        //     return;
        // }

        if(data?.epid === 0){
            return;
        }

        checkSubmit({
            bid:bid,
            cid:data?.epid,
            ct_type:"epub",
            epub:true,
        });
    }


    const getViewPageLink = ()=>{
        navigate("/view/"+type+"/"+data?.epid+"/viewer");
    }

    return (
        <li className={"color-txt-01 color-border-thumbnail border-bottom-line border-soild " + (parseInt(cid,10)===parseInt(data?.epid,10) ? style['active']:"")}>
            <BookpalLink
                onClick={(e)=>{
                    e.preventDefault()
                    handlerCheckPay();
                }}
                ><span className="text_overflow">
                    {data?.no}. {data?.title}
                </span>
            </BookpalLink>
        </li>
    )
}



export default HeaderEpubEpisodeList;