import { useEffect, useRef, useState } from 'react';
import { ApiOrderOrderGiftList } from 'api/Order';
import { Link, useNavigate } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import BookpalLink from 'component/common/custom/BookpalLink';

import Header from 'component/common/header/Header';
import Footer from 'component/common/footer/Footer';

import SubNav from "component/common/nav/SubNav";

// import style from './style/Mypage.module.scss';
import style from '../detail/style/DetailGift.module.scss'

// import MypageCouponChargeList from './MypageCouponChargeList';
// import MypageCouponUseList from './MypageCouponUseList';

import MypageGiftMenuSubNav from './MypageGiftMenuSubNav';

function MypageGiftGiveList(){

    const [tooltip, setTooltip] = useState(false);

    const [available, setAvailable] = useState(false);

    const navigate = useBookpalNavigate();


    const contentRef = useRef(null);

    const [data, dataSubmit, initData, paging] = ApiOrderOrderGiftList({
        bottomRef:contentRef,
        data:{
            use_available:available,
        }
    });

    useEffect(()=>{
        dataSubmit();
    },[]);

    useEffect(()=>{
        initData();
        dataSubmit();
    },[available])


    if(data === null){
        return <></>
    }

    return (
        <>
            <Header useMobile={false} pcTop={"-64px"} />
            <SubNav 
                name={"선물내역"}
                usePc={true} 
                useMobile={true} 
                usePcRightBtns={true}
                useBtnPrev={true}
                useBtnPcPrev={true}
                useBtnHome={true}

                setTop={'0px'} 
                setPcTop={'0px'} 
                />
            <MypageGiftMenuSubNav />
            {/* <!-- container --> */}
            <div id="container">
                <section className="section wrapper history_list">
                    <div className="area_box">
                        <div className="tit_con clearfix">
                            <div className="left">
                                <label htmlFor="check" className="checkbox_round check-type-01 small label_on">
                                    <input type="checkbox" id="check" checked={available} onChange={(e)=>{setAvailable(e.target.checked); }} />
                                    <span className="box"></span>
                                    <span className="txt color-txt-04"><span className="color-btn-01 txt">사용가능한</span> 선물만 보기</span>
                                </label>
                            </div>
                            <div className="right">
                                <p className="button btn-03 color-btn-02-disabled txt" id="infoBtn" onClick={()=>{setTooltip(true);}}><span className="icon" ></span>이용안내</p>
                                <div id="toolTip" className="toolTip right" style={{display: (tooltip ? "block":"none")}}>
                                    <button type="button" className="radius-xs color-btn-01 bg top_left">
                                        <span className="white-always caption-02-long txt">
                                            유효기간이 지나지 않은 선물 내역을<br />
                                            보시려면 <b>사용가능한 선물만 보기</b>를<br />
                                            이용하세요.
                                        </span>
                                        <img src="/images/x_circle.svg" onClick={()=>{setTooltip(false);}}/>
                                    </button>
                                </div>
                            </div>
                        </div>


                        {data && Array.isArray(data) && data?.length === 0 ?
                        <div className="no_data large color-txt-04 body-02-long">선물내역이 없습니다.<br />받은내역은 최근 1년까지 노출됩니다.</div>
                        :
                        <ul className="list" ref={contentRef}>
                            {data.map((item, index)=>{

                                const isDisabled = (item?.nonuse_cnt === "0" || item?.is_expire === 1);
                                const disableClass = isDisabled ? "color-txt-muted" : "color-txt-03";
                                const handlerClick = (e)=>{
                                    e.preventDefault();
                                    const allType = e.currentTarget.getAttribute("data-all-type");
                                    const bid = e.currentTarget.getAttribute("data-bid");
                                    const type = e.currentTarget.getAttribute("data-type");
                                    const disabled = e.currentTarget.getAttribute("data-disabled");

                                    // if(disabled === "true"){
                                    //     return;
                                    // }
                                    console.log(allType, bid, type, disabled);
                                    if(bid === "0" && type === "novel"){
                                        navigate("/novel/ranking");
                                        return;
                                    }
                                    if(bid === "0" && type === "toon"){
                                        navigate("/toon/ranking");
                                        return;
                                    }
                                    if(bid != "" && type != ""){
                                        navigate("/content/"+type+"/"+bid);
                                    }
                                }

                                return ( 
                                    <li key={index} className={"border-soild border-bottom-line color-border-01 " + (isDisabled?"disabled":"")}>
                                        <BookpalLink 
                                            data-all-type={(item?.all_type === "all")}
                                            data-bid={item?.bid}
                                            data-type={item?.type}
                                            data-disabled={isDisabled ? "true":"false"}
                                            onClick={handlerClick}>
                                            <div className="left info_txt spacing_08">
                                                <div className="list_tit_con">
                                                    <span className={"title-01-b text_overflow " + (isDisabled ? "color-txt-muted" : "color-txt-normal" )}>{item?.all_type === "all" ? (item?.type === "novel"?"웹소설":"웹툰") + " 전체" : item?.title}</span>
                                                    <span className="icon"></span>
                                                </div>
                                                <div className={"category body-02 " + (isDisabled ? "color-txt-muted" : "color-txt-03") }>
                                                    {item?.comment} 
                                                    {" - "}
                                                    {item?.coupon_type === "own" ? "선물소장권":null} 
                                                    {item?.coupon_type === "rent" ? "선물이용권":null}</div>
                                                <div className={"date body-01 " + (isDisabled ? "color-txt-muted" : "color-txt-05")}>{item?.date_insert_half}</div>
                                            </div>
                                            <div className="right">
                                                <div className={"title title-01-m  " + (isDisabled ? "color-txt-muted" : "color-txt-normal")}><span>{item?.nonuse_cnt}</span>장</div>
                                                {item?.nonuse_cnt === "0" ?
                                                    <div className="data color-txt-muted body-01">사용 <span>완료</span></div>
                                                    :
                                                        item?.is_expire ? <div className="data color-txt-muted body-01">유효기간 <span>만료</span></div> 
                                                        : <div className="data color-btn-01 txt body-01">유효기간 <span>{item?.date_expire}</span></div>
                                                }
                                                
                                            </div>
                                        </BookpalLink>
                                    </li>
                                )
                            })}
                            {/* <li className="border-soild border-bottom-line color-border-01">
                                <a href="#">
                                    <div className="left info_txt spacing_08">
                                        <div className="list_tit_con">
                                            <span className="title-01-b color-txt-normal text_overflow">결혼지옥</span>
                                            <span className="icon"></span>
                                        </div>
                                        <div className="category body-02 color-txt-03">[거래구분] 선물이용권</div>
                                        <div className="date body-01 color-txt-05">YY.MM.DD 00:00:00</div>
                                    </div>
                                    <div className="right">
                                        <div className="title title-01-m color-txt-normal"><span>1</span>장</div>
                                        <div className="data color-btn-01 txt body-01">유효기간 <span>23.11.10</span></div>
                                    </div>
                                </a>
                            </li> */}
                            {/* <!-- 전체 탭 리스트 (모달뜨고 웹소설>로맨스 랭킹페이지로 이동)  --> */}
                            {/* <li className="border-soild border-bottom-line color-border-01">
                                <a href="#none" onclick="Modal()">
                                    <div className="left info_txt spacing_08">
                                        <div className="list_tit_con">
                                            <span className="title-01-b color-txt-normal text_overflow">전체</span>
                                            <span className="icon"></span>
                                        </div>
                                        <div className="category body-02 color-txt-03">[거래구분] 선물이용권</div>
                                        <div className="date body-01 color-txt-05">YY.MM.DD 00:00:00</div>
                                    </div>
                                    <div className="right">
                                        <div className="title title-01-m color-txt-normal"><span>1</span>장</div>
                                        <div className="data color-btn-01 txt body-01">유효기간 <span>23.11.10</span></div>
                                    </div>
                                </a>
                            </li> */}
                            {/* <!-- 유효기간 만료 리스트 --> */}
                            {/* <li className="border-soild border-bottom-line color-border-01 disabled">
                                <a>
                                    <div className="left info_txt spacing_08">
                                        <div className="list_tit_con">
                                            <span className="title-01-b color-txt-muted text_overflow">결혼지옥</span>
                                            <span className="icon"></span>
                                        </div>
                                        <div className="category body-02 color-txt-muted">[거래구분] 선물이용권</div>
                                        <div className="date body-01 color-txt-muted">YY.MM.DD 00:00:00</div>
                                    </div>
                                    <div className="right">
                                        <div className="title title-01-m color-txt-muted"><span>1</span>장</div>
                                        <div className="data color-txt-muted body-01">유효기간 <span>만료</span></div>
                                    </div>
                                </a>
                            </li> */}
                        </ul>
                        }
                        
                        {/* <!-- 내역 없을때 --> */}
                        {/* <!-- <div className="no_data large color-txt-04 body-02-long">선물내역이 없습니다.<br />받은내역은 최근 1년까지 노출됩니다.</div> --> */}
                    </div>
                </section>
            </div>
            {/* <!-- container --> */}

            <Footer />
        </>
    );
}




export default MypageGiftGiveList;