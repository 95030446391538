import { useState } from "react";
import { Link } from "react-router-dom";
import _style from './Footer.module.scss';
import BookpalLink from "../custom/BookpalLink";

function Footer({ style, className }){

    const [show, setShow] = useState(false);

    const handlerToggle = ()=>{
        setShow(!show);
    }

    return (
        <footer id="footer" className={className + " wrapper"} style={style}>
            <div className="area_box clearfix">
                <div className="left">
                    <div className={"info " + _style['info']}>
                        <button 
                            type="button" 
                            className={"color-txt-03 btn-02 " + (show ? "show":"") }
                            onClick={handlerToggle}
                            >(주) 북팔 사업자정보</button>
                        <div className={"txt_box color-txt-04 caption-01-long " + _style['slide-content'] + " " + (show ? _style['active']:"")} style={{display:(show ? "block":"block")}}>
                            서울특별시 영등포구 국회대로70길 23 , 용산빌딩 7층 (여의도동)<br />
                            대표이사 : 김석환  |  사업자등록번호 : 214-88-74813  |  통신판매업신고 :
                            <a href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=2148874813" target="_blank">제2022-서울영등포-1421호</a><br />
                            개인정보관리책임자 : 최성민 <a href="mailto:bookpal@bookpal.co.kr">bookpal@bookpal.co.kr</a>
                        </div>
                    </div>
                    <p className="copyright btn-01 color-txt-muted">Copyright ⓒ BOOKPAL CO., LTD. All Rights Reserverd.</p>
                    <ul className="menu_links">
                        <li className="color-txt-muted btn-02">
                            <BookpalLink to={"/mypage_menu/help"}>고객센터</BookpalLink>
                            {/* <a href="#">고객센터</a> */}
                        </li>
                        <li className="color-txt-muted btn-02">
                            <BookpalLink to={"/mypage_menu/help/terms"}>이용약관</BookpalLink>
                            {/* <a href="#">이용약관</a> */}
                        </li>
                        <li className="color-txt-muted btn-02-active">
                            <BookpalLink to={"/mypage_menu/help/terms"} state={{type: 'privacy'}}>개인정보처리방침</BookpalLink>
                            {/* <a href="#">개인정보처리방침</a> */}
                        </li>
                        <li className="color-txt-muted btn-02">
                            <BookpalLink to={"/mypage_menu/help/terms"} state={{type: 'youth'}}>청소년 보호정책</BookpalLink>
                            {/* <a href="#">청소년 보호정책</a> */}
                        </li>
                    </ul>
                </div>
                <div className="right">
                    <ul className="sns_link">
                        <li className="color-bg-02 radius-full blog">
                            <a href="https://blog.naver.com/bookpalbooks" target="_blank" className="button btn_03 large label_off round on radius-full"><span>블로그</span></a>
                        </li>
                        <li className="color-bg-02 radius-full twitter">
                            <a href="https://twitter.com/bookpalkorea" target="_blank" className="button btn_03 large label_off round on radius-full"><span>트위터</span></a>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    )
}

export default Footer;