import { useEffect, useRef, useState } from 'react';
import { ApiOrderOrderGiftUseList } from 'api/Order';
import { Link } from 'react-router-dom';
import BookpalLink from 'component/common/custom/BookpalLink';
import { useNavigate } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';

import Header from 'component/common/header/Header';
import Footer from 'component/common/footer/Footer';

import SubNav from "component/common/nav/SubNav";

// import style from './style/Mypage.module.scss';
import style from '../detail/style/DetailGift.module.scss'

// import MypageCouponChargeList from './MypageCouponChargeList';
// import MypageCouponUseList from './MypageCouponUseList';

import MypageGiftMenuSubNav from './MypageGiftMenuSubNav';

function MypageGiftUseList(){

    // const [available, setAvailable] = useState(false);

    const contentRef = useRef(null);

    const navigate = useBookpalNavigate();

    const [data, dataSubmit, initData, paging] = ApiOrderOrderGiftUseList({
        bottomRef:contentRef,
    });

    useEffect(()=>{
        dataSubmit();
    },[]);



    if(data === null){
        return <></>
    }

    return (
        <>
            <Header useMobile={false} pcTop={"-64px"}/>
            <SubNav 
                name={"선물내역"}
                usePc={true} 
                useMobile={true} 
                usePcRightBtns={true}
                useBtnPrev={true}
                useBtnPcPrev={true}
                useBtnHome={true}

                setTop={'0px'} 
                setPcTop={'0px'} 
                />
            <MypageGiftMenuSubNav />
            {/* <!-- container --> */}
            <div id="container" ref={contentRef}>
                <section className="section wrapper history_list">
                    <div className="area_box" >


                        {data && Array.isArray(data) && data?.length === 0 ?
                        <div className="no_data large color-txt-04 body-02-long">선물내역이 없습니다.<br />받은내역은 최근 1년까지 노출됩니다.</div>
                        :
                        <ul className="list" >
                            {data.map((item, index)=>{

                                const handlerClick = (e)=>{
                                    e.preventDefault();
                                    const bid = e.currentTarget.getAttribute("data-bid");
                                    const cid = e.currentTarget.getAttribute("data-cid");
                                    const type = e.currentTarget.getAttribute("data-type");
                                    
                                    console.log(cid, bid, type);
                                    if(bid != "" && type != ""){
                                        navigate("/content/"+type+"/"+bid);
                                    }
                                }

                                return (
                                    <li key={index} className={"border-soild border-bottom-line color-border-01 "}>
                                        <BookpalLink 
                                            data-bid={item?.bid}
                                            data-cid={item?.cid}
                                            data-type={item?.type}
                                            onClick={handlerClick}>
                                            <div className="left info_txt spacing_08">
                                                <div className="list_tit_con">
                                                    <span className={"title-01-b text_overflow color-txt-normal"}>{item?.all_type === "all" ? "전체" : item?.title}</span>
                                                    <span className="icon"></span>
                                                </div>
                                                <div className={"category body-02 color-txt-03"}>
                                                    {item?.is_extra === 1 ? "외전":""} {item?.no}화 {item?.coupon_type === "own" ? "소장":null} {item?.coupon_type === "rent" ? "대여":null}</div>
                                                <div className={"date body-01 color-txt-05"}>{item?.date_insert}</div>
                                            </div>
                                            <div className="right">
                                                <div className={"title title-01-m color-txt-normal "}>
                                                    {item?.coupon_type === "own" ? "선물소장권":null} 
                                                    {item?.coupon_type === "rent" ? "선물이용권":null}
                                                </div>
                                                <div className="data body-01 color-txt-05">[{item?.type === "novel" ? "웹소설":"웹툰"}]</div>
                                                
                                            </div>
                                        </BookpalLink>
                                    </li>
                                )
                            })}
                        </ul>
                        }
                        
                        {/* <!-- 내역 없을때 --> */}
                        {/* <!-- <div className="no_data large color-txt-04 body-02-long">선물내역이 없습니다.<br />받은내역은 최근 1년까지 노출됩니다.</div> --> */}
                    </div>
                </section>
            </div>
            {/* <!-- container --> */}

            <Footer />
        </>
    );
}




export default MypageGiftUseList;