import { useState, useRef } from 'react';
// import { useShowGiftToast } from 'utils/ToastUtil';
import { useDispatch } from 'react-redux';
import { actionSetShowGiftToast } from 'action/index';



import Header from 'component/common/header/Header';
import MobileNav from 'component/common/nav/MobileNav';

import Footer from 'component/common/footer/Footer';
import TopBtn from 'component/common/button/top/TopBtn';

import SubMenuNav from '../nav/SubMenuNav';
import useSelectModal from 'component/common/modal/useSelectModal';

import img_gift from 'assets/images/gift/gift_img.png';

import style from '../style/GiftBox.module.scss';

function GiftBoxToon(){

    const dispatch = useDispatch();

    const modalData = {
        all:"전체",
        select1:"select option 01",
        select2:"select option 02",
    }
    const [Modal, modalShow, selectKey] = useSelectModal(modalData, "all");

    const useHandlerShowToast = ()=>{
        dispatch(actionSetShowGiftToast({
            title:"작품 전체",
            body:"선물소장권 999장을 받았습니다.",
            date:"유효기간 :  2024. 01. 03 23:59:59",
        }));
    }

    return (
        <>
            <Header />
            <MobileNav />
            {/* <!-- 서브메뉴 --> */}
            <SubMenuNav page="/giftbox/toon"/>
            {/* <!-- //서브메뉴 --> */}

            {/* <!-- container --> */}
            <div id="container">
                

                
                {/* <!-- 섹션 --> */}
                {/* <!-- 버튼있는 섹션 --> */}
                <section className={"section template wrapper temp_list template_column list_btn " + style['temp_list'] + " " + style['template'] + " " + style['template_column']}>
                    <div className={"list " + style['list']}>
                        {/* <!-- 리스트 --> */}
                        <ul>
                            {/* <!-- 받지않은 선물 리스트 상단 노출 --> */}
                            <li className={"clearfix gift_list " + style['gift_list']}>
                                <a href="#">
                                    <div className="cover_img radius-xs">
                                        <p className="ic_adult badge_adult_middle"><img src="../images/ic_adult.svg" /></p>
                                        <img src="../images/thumbnail.jpg" />
                                        <p className="ic_bottom badges_box">
                                            <span className="ic_midnight radius-xs icon_badge"><img src="../images/midnight_badge.svg" /></span>
                                        </p>
                                    </div>
                                    <div className={"info_txt spacing_08 " + style['info_txt']}>
                                        <div className="info">
                                            <span className="color-txt-05 body-01">웹소설/웹툰</span>
                                            <span className="color-txt-05 body-01">장르</span>
                                            <span className="color-txt-05 body-01">완결여부 </span>
                                        </div>
                                        <div className="list_tit_con">
                                            <span className="ic_up radius-xs icon_badge"><img src="../images/up_badge.svg" /></span>
                                            <span className="title color-txt-normal title-book-03-m text_overflow">책제목입니다.</span>
                                        </div>
                                        <div className="writer color-txt-03 body-02 text_overflow">작가명</div>
                                        <div className={"info date " + style['date']}>
                                            <span className="color-txt-05 body-01">선물이용권</span>
                                            <span className="color-btn-01 txt body-01">3장</span>
                                        </div>
                                    </div>
                                    <div className={"button_box " + style['button_box']}>
                                        <button type="button" className={"gift_btn button btn_01 filled label_on medium radius-full " + style['gift_btn'] + " " + style['button']}><span className="white_always txt btn-03">선물받기</span></button>
                                    </div>
                                </a>
                            </li>
                            <li className={"clearfix gift_list " + style['gift_list']}>
                                <a href="#">
                                    <div className="cover_img radius-xs">
                                        <p className="ic_adult badge_adult_middle"><img src="../images/ic_adult.svg" /></p>
                                        <img src="../images/thumbnail.jpg" />
                                        <p className="ic_bottom badges_box">
                                            <span className="ic_midnight radius-xs icon_badge"><img src="../images/midnight_badge.svg" /></span>
                                        </p>
                                    </div>
                                    <div className={"info_txt spacing_08 " + style['info_txt']}>
                                        <div className="info">
                                            <span className="color-txt-05 body-01">웹소설/웹툰</span>
                                            <span className="color-txt-05 body-01">장르</span>
                                            <span className="color-txt-05 body-01">완결여부 </span>
                                        </div>
                                        <div className="list_tit_con">
                                            <span className="ic_up radius-xs icon_badge"><img src="../images/up_badge.svg" /></span>
                                            <span className="title color-txt-normal title-book-03-m text_overflow">책제목입니다.</span>
                                        </div>
                                        <div className="writer color-txt-03 body-02 text_overflow">작가명</div>
                                        <div className={"info date " + style['date']}>
                                            <span className="color-txt-05 body-01">선물이용권</span>
                                            <span className="color-btn-01 txt body-01">3장</span>
                                        </div>
                                    </div>
                                    <div className={"button_box " + style['button_box']} onClick={useHandlerShowToast}>
                                        <button type="button" className={"gift_btn button btn_01 filled label_on medium radius-full " + style['gift_btn'] + " " + style['button']}><span className="white_always txt btn-03">선물받기</span></button>
                                    </div>
                                </a>
                            </li>
                            {/* <!-- 받은 선물 리스트 하단으로 --> */}
                            <li className={"clearfix completer_list " + style['completer_list']}>
                                <a href="#">
                                    <div className="cover_img radius-xs">
                                        <p className="ic_adult badge_adult_middle"><img src="../images/ic_adult.svg" /></p>
                                        <img src="../images/thumbnail.jpg" />
                                        <p className="ic_bottom badges_box">
                                            <span className="ic_midnight radius-xs icon_badge"><img src="../images/midnight_badge.svg" /></span>
                                        </p>
                                    </div>
                                    <div className={"info_txt spacing_08 " + style['info_txt']}>
                                        <div className="info">
                                            <span className="color-txt-05 body-01">웹소설/웹툰</span>
                                            <span className="color-txt-05 body-01">장르</span>
                                            <span className="color-txt-05 body-01">완결여부 </span>
                                        </div>
                                        <div className="list_tit_con">
                                            <span className="ic_up radius-xs icon_badge"><img src="../images/up_badge.svg" /></span>
                                            <span className="title color-txt-normal title-book-03-m text_overflow">책제목입니다.</span>
                                        </div>
                                        <div className="writer color-txt-03 body-02 text_overflow">작가명</div>
                                        <div className={"info date " + style['date']}>
                                            <span className="color-txt-05 body-01">선물이용권</span>
                                            <span className="color-btn-01 txt body-01">받기완료</span>
                                        </div>
                                    </div>
                                    <div className={"button_box " + style['button_box']}>
                                        <button type="button" className={"button btn_03 outlined label_on medium btn-02 radius-full round"}><span className="btn-03 color-txt-04">작품보기</span></button>
                                    </div>
                                </a>
                            </li>
                            <li className={"clearfix completer_list " + style['completer_list']}>
                                <a href="#">
                                    <div className="cover_img radius-xs">
                                        <p className="ic_adult badge_adult_middle"><img src="../images/ic_adult.svg" /></p>
                                        <img src="../images/thumbnail.jpg" />
                                        <p className="ic_bottom badges_box">
                                            <span className="ic_midnight radius-xs icon_badge"><img src="../images/midnight_badge.svg" /></span>
                                        </p>
                                    </div>
                                    <div className={"info_txt spacing_08 " + style['info_txt']}>
                                        <div className="info">
                                            <span className="color-txt-05 body-01">웹소설/웹툰</span>
                                            <span className="color-txt-05 body-01">장르</span>
                                            <span className="color-txt-05 body-01">완결여부 </span>
                                        </div>
                                        <div className="list_tit_con">
                                            <span className="ic_up radius-xs icon_badge"><img src="../images/up_badge.svg" /></span>
                                            <span className="title color-txt-normal title-book-03-m text_overflow">책제목입니다.</span>
                                        </div>
                                        <div className="writer color-txt-03 body-02 text_overflow">작가명</div>
                                        <div className={"info date " + style['date']}>
                                            <span className="color-txt-05 body-01">선물이용권</span>
                                            <span className="color-btn-01 txt body-01">받기완료</span>
                                        </div>
                                    </div>
                                    <div className={"button_box " + style['button_box']}>
                                        <button type="button" className={"button btn_03 outlined label_on medium btn-02 radius-full round"}><span className="btn-03 color-txt-04">작품보기</span></button>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        {/* <!-- 내역 없을때 -->
                        <!-- <div className="no_data small color-txt-04 body-02-long">더 많은 작품을 준비 중입니다.</div> --> */}
                    </div>
                </section>

            </div>
            <Modal />
            {/* <!-- container --> */}
            <Footer />
            <TopBtn />
        </>
    );
}


export default GiftBoxToon;