import { useNavigate } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import style from '../../style/Contents.module.scss';


function Bind(props){

    const navigate = useBookpalNavigate();

    const data = props?.data || null;
    const bid = props?.bid || null;
    const onAction = props?.onAction || (()=>{});




    if(data === null){
        return <></>
    }

    return (
        <>
            <div className={"left " + style['left']}>
                <p className={"icon " + style['icon'] + " " + style['icon_event']} id="icon_event"></p>
                <p className={"title info " + style['title'] + " " + style['info']}>
                    <span className="color-txt-01 body-03">{data?.title}</span> 
                </p>
            </div>
            <p 
                className={"right " + style['right']}
                ><button 
                    type="button" 
                    className={"button btn_03 round radius-full small label_on outlined link_btn " + style['button'] + " " + style['link_btn']}
                    onClick={()=>{
                        navigate(data?.gift_url);
                    }}
                    ><span className="btn-02 color-txt-04">진행중</span></button></p>
        
        </>
    )
}



export default Bind;