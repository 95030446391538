import { useState, useRef, useEffect } from 'react';
import { ApiBoxSboxRecent, ApiBoxSetExclude, ApiBoxSetExcludeRecent } from 'api/Box';
import { Link, useNavigate } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import BookpalLink from 'component/common/custom/BookpalLink';

import { useWidth } from 'utils/Common';
import { ImgBadge, ImgDefault, ImgCover } from 'utils/Images';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BookpalLazyLoadImage from 'component/common/custom/BookpalLazyLoadImage';
import { SetState, useMemberInfo } from 'utils/State';
import useConfirmCustomModal from 'component/common/modal/useConfirmCustomModal';
import useAlertModal from 'component/common/modal/useAlertModal';

import Header from 'component/common/header/Header';
import MobileNav from 'component/common/nav/MobileNav';

import Footer from 'component/common/footer/Footer';
import TopBtn from 'component/common/button/top/TopBtn';

import SubMenuNav from '../nav/SubMenuNav';
import VerticalSection from 'component/common/section/VerticalSection';

import useSelectModal from 'component/common/modal/useSelectModal';


import style from '../style/Storage.module.scss';
import { isApp, isIosApp } from 'utils/AppUtil';

function Recent(){

    const contentRef = useRef(null);

    const navigate = useBookpalNavigate();
    const setState = SetState();

    const memberInfo = useMemberInfo();
    const [ConfirmModal, confirm] = useConfirmCustomModal();
    const [AlertModal, alert] = useAlertModal();

    const modalData = {
        all:"전체",
        novel:"웹소설",
        toon:"웹툰",
        epub:"단행본",
    }
    const [Modal, modalShow, selectKey] = useSelectModal(modalData, "all");

    const searchRef = useRef(false);
    const [search, setSearch] = useState("");
    const [searchTxt, setSearchTxt] = useState("");
    const [searchMode, setSearchMode] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [excludeArr, setExcludeArr] = useState([]);
    const [unsetRecentArr, setUnsetRecentArr] = useState([]);

    const [data, dataSubmit, initData, paging, setData] = ApiBoxSboxRecent({
        data:{
            filter:(selectKey === "all" ? null : selectKey),
            search:(searchTxt === "" ? null:searchTxt),
        },
        bottomRef:contentRef,
        onSuccess:(data)=>{
            // console.log(data)
            if(data?.status === false){
                switch(data?.code){
                    case "403":
                        alert("보관함","로그인이 필요한 서비스입니다.",()=>{
                            navigate(-1);
                        });
                        break;
                    default:
                        alert("보관함","서비스에 문제가 있습니다. 다시 시도해 주세요.",()=>{
                            navigate(-1);
                        });
                        break;
                }
                
            }
        },
        onError:(e)=>{
            alert("보관함","서비스에 문제가 있습니다. 다시 시도해 주세요.",()=>{
                navigate(-1);
            });
        },
    });

    const handlerEditOn = () => {
        if(!data || (Array.isArray(data) && data?.length <= 0)){
            setState.setToast({
                title:"편집할 작품이 없습니다.",
            });
            return;
        }
        setEditMode(true);
    }

    const excludeSubmit = ApiBoxSetExclude({
        onSuccess:(_data)=>{
            if(_data?.status === true){
                const result = _data?.result;
                alert("삭제안내","삭제되었습니다.",()=>{
                    const tmp = [];
                    data.map((item)=>{
                        let check = false;
                        result?.bids.map((bid)=>{
                            bid = parseInt(bid);
                            if(item?.bid == bid){
                                check = true;
                            }
                        })
                        if(!check){
                            tmp.push(item);
                        }
                    })

                    setData(tmp);
                    setEditMode(false);
                    initData();
                    dataSubmit();
                })
            }
        },
        onError:(e)=>{

        },
    });

    const unsetRecentSubmit = ApiBoxSetExcludeRecent({
        onSuccess:(_data)=>{
            if(_data?.status === true){
                const result = _data?.result;
                alert("삭제안내","삭제되었습니다.",()=>{
                    // const tmp = [];
                    // data.map((item)=>{
                    //     let check = false;
                    //     result?.bids.map((bid)=>{
                    //         bid = parseInt(bid);
                    //         if(item?.bid == bid){
                    //             check = true;
                    //         }
                    //     })
                    //     if(!check){
                    //         tmp.push(item);
                    //     }
                    // })

                    // setData(tmp);
                    setUnsetRecentArr([]);
                    setEditMode(false);
                    initData();
                    dataSubmit();
                })
            }
        },
        onError:(e)=>{

        },
    });

    const handlerExclude = ()=>{
        confirm({
            title:"삭제 안내",
            content:"삭제하시겠습니까?",
            successBtnTitle:"삭제하기",
            successAction:()=>{
                // excludeSubmit({
                //     menu:"recent",
                //     bids:excludeArr,
                // })
                unsetRecentSubmit({
                    data:unsetRecentArr
                })
            }
        });
    }

    const handlerSearch = (e)=>{
        e.preventDefault();
        setSearchMode(!searchMode);
        setSearchTxt("");
        setSearch("");
        if(!searchMode){
            setTimeout(function(){
                searchRef.current.focus();
            },100);
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === 'NumpadEnter') {
            setSearchTxt(e.currentTarget.value);
            e.target.blur();
        } else  {
            
        }
    };

    const getCtTypeName = (ct_type)=>{
        switch(ct_type){
            case "novel": return "웹소설";
            case "epub": return "단행본";
            case "toon": return "웹툰";
            case "comic": return "웹툰";
            default: return null;
        }
    }
    
    const excludeBidAllCheck = ()=>{
        if(unsetRecentArr?.length == data?.length){
            setExcludeArr([]);
            setUnsetRecentArr([]);
        } else {
            const tmp = [];
            const tmp2 = [];
            data.map((item)=>{
                tmp.push(item?.bid);
                tmp2.push({bid:item?.bid+"", is_epub:item?.ct_type === "epub"});
            })
            setExcludeArr(tmp);
            setUnsetRecentArr(tmp2);
            console.log(tmp2)
        }
    }
    
    const excludeBidEpub = (bid, epub, checked)=>{

        if(!checked){
            const tmp = [];
            unsetRecentArr.map((item)=>{
                if(item?.bid === (bid+"") && item?.is_epub === epub){
                    return;
                }
                tmp.push(item);
            });
            setUnsetRecentArr(tmp);
            return;   
        }
        // console.log(unsetRecentArr);
        unsetRecentArr.push({bid:bid, is_epub:epub});
        console.log(unsetRecentArr);
        setUnsetRecentArr([...unsetRecentArr]);
    }

    const excludeBid = (bid, checked)=>{

        if(!checked){
            const tmp = [];
            excludeArr.map((item)=>{
                if(item != bid){
                    tmp.push(item);
                }
            });
            setExcludeArr(tmp);
            return;   
        }
        
        excludeArr.push(bid);
        setExcludeArr([...excludeArr]);
    }

    const getIsExcludeArr = (bid)=>{
        // console.log(bid)
        if(excludeArr?.length <= 0){
            return false;
        }
        let check = false;
        excludeArr.map((item)=>{
            if(item == bid){
                check = true;
            }
        })

        return check;
    }

    const getIsUnsetRecentArr = (bid, epub)=>{
        // console.log(bid)
        if(unsetRecentArr?.length <= 0){
            return false;
        }
        let check = false;
        unsetRecentArr.map((item)=>{
            // console.log(item)
            // console.log(bid, epub)
            if(item?.bid === (bid+"") && item?.is_epub === epub){
                check = true;
            }
        })

        return check;
    }

    useEffect(()=>{
        initData();
        dataSubmit();
    },[selectKey, searchTxt])

    useEffect(()=>{
        dataSubmit();
    },[]);


    if(data === null){
        return (
            <>
            {isApp() ? null:<Header title={"보관함"}/>}
            {isApp() ? null:<MobileNav />}
            {/* <!-- 서브메뉴 --> */}
            <SubMenuNav page="/storage/recent"/>
            {/* <!-- //서브메뉴 --> */}
            <AlertModal />
            </>
        );
    }


    return (
        <>
            {isApp() ? null:<Header title={"보관함"}/>}
            
            {isApp() ? null:<MobileNav />}
            {/* <!-- 서브메뉴 --> */}
            <SubMenuNav page="/storage/recent"/>
            {/* <!-- //서브메뉴 --> */}

            {/* <!-- container --> */}
            <div id="container">
                

                {/* <!-- 섹션 --> */}
                {/* <!-- 버튼있는 섹션 --> */}
                <section 
                    ref={contentRef}
                    className={"section template wrapper temp_list template_column storage_list " + style['template'] + " " + style['section'] + " " + style['temp_list'] + " " + style['template_column'] + " " + style['storage_list']}>
                    <div className={"area"}>
                        <div className={"top_box " + style['top_box']} style={{display:(editMode)?"none":"block"}}>
                            <div className={"search_box clearfix top_con before" + style['search_box'] + " " + style['top_con']} style={{display:(searchMode)?"none":"flex"}}>
                                <div className={"left"}>
                                    <button type="button" className={"button btn_05 medium label_on filled radius-full search_btn " + style['search_btn'] + " " +style['button']} onClick={handlerSearch}><span className="btn-03 color-btn-03-txt">검색</span></button>
                                </div>
                                <div className={"right"}>
                                    <BookpalLink 
                                        className={"select_btn select_01"} 
                                        onClick={()=>{
                                            modalShow();
                                        }}
                                        ><span className="color-btn-03-txt btn-03">{modalData[selectKey]}</span></BookpalLink>
                                </div>
                            </div>
                            <div className={"btn_box input_box after " + style['input_box'] + " " + style['after']} style={{display:(searchMode)?"flex":"none"}}>
                                <div className={"text_input color-bg-02 radius-full border-line border border-soild " + style['text_input']}>
                                    <input 
                                        type="text" 
                                        ref={searchRef} 
                                        className={"text_box btn-03 color-txt-normal " + style['text_box']} 
                                        placeholder="최근 본 작품 검색" 
                                        value={search}
                                        onChange={(e)=>{
                                            setSearch(e.currentTarget.value);
                                        }}
                                        onKeyDown={handleKeyDown}
                                        />
                                    <p className={"right_btns " + style['right_btns']}>
                                        <span className="btnClear"></span>
                                    </p>
                                </div>
                                <button 
                                    type="button" 
                                    className={"button btn-03 color-btn-03-txt " + style['button']} 
                                    onClick={handlerSearch}
                                    
                                    ><span>취소</span></button>
                            </div>
                        </div>
                        <div className={"bottom_box edit_box"}>
                            <div className={"tit_con clearfix before"} style={{display:(editMode)?"none":"flex"}}>
                                <div className={"left"}>
                                    {paging?.total_cnt ? <span className="color-btn-03-txt btn-03">총 {paging?.total_cnt}종</span> : null}
                                </div>
                                <div className={"right"}>
                                    <a style={{cursor:"pointer"}} className={"button " + style['button']} id="editBtn" onClick={(e)=>{e.preventDefault();handlerEditOn();}} ><span className="color-btn-03-txt btn-03">편집</span></a>
                                </div>
                            </div>
                            <div className={"tit_con clearfix after " + style['after']} style={{display:(editMode)?"flex":"none"}} >
                                <div className="left">
                                    <label htmlFor="a1" className={"checkbox_round check-type-01 small label_on"}>
                                        <input 
                                            type="checkbox" 
                                            id="a1" 
                                            checked={(excludeArr?.length == data?.length)}
                                            onChange={()=>{

                                            }}
                                            onClick={()=>{
                                                excludeBidAllCheck();
                                            }}
                                            />
                                        <span className="box"></span>
                                        <span className="txt color-txt-04">전체 선택 
                                        {/* (총 {data?.length}작품) */}
                                        </span>
                                    </label>
                                </div>
                                <div className="right">
                                    <a style={{cursor:"pointer"}} className="button" onClick={(e)=>{e.preventDefault();setEditMode(false);}}><span className="color-btn-03-txt btn-03">편집 끄기</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {data && Array.isArray(data) && data?.length > 0 ?
                    <div className={"list " + style['list']}>
                        <ul
                            style={{
                                display:"grid",
                                gridTemplateColumns: "none",
                                gridGap: "4px 0",
                            }}
                            >
                            {/* <!-- 리스트 --> */}
                            {data.map((item, index)=>{
                                return (
                                    <li key={index} className="clearfix">
                                        <button 
                                            type="button" 
                                            className={"edit_select " + style['edit_select']} 
                                            style={{display:(editMode)?"flex":"none"}}
                                            
                                            >
                                            <label htmlFor={"check_"+index} className="checkbox_round check-type-01 medium label_on">
                                                <input 
                                                    type="checkbox" 
                                                    id={"check_"+index} 
                                                    // checked={getIsExcludeArr(item?.bid)}
                                                    checked={getIsUnsetRecentArr(item?.bid, item?.ct_type === "epub")}
                                                    data-bid={item?.bid}
                                                    data-ct_type={item?.ct_type}
                                                    onChange={()=>{

                                                    }}
                                                    onClick={(e)=>{
                                                        const bid = e.currentTarget.getAttribute("data-bid");
                                                        const ct_type = e.currentTarget.getAttribute("data-ct_type");
                                                        // excludeBid(bid, e.currentTarget.checked);
                                                        excludeBidEpub(bid, ct_type === "epub", e.currentTarget.checked);
                                                    }}
                                                    />
                                                <span className={"box " + style['box']}></span>
                                            </label>
                                        </button>
                                        
                                        <BookpalLink 
                                            data-ct_type={item?.ct_type}
                                            data-bid={item?.bid}
                                            data-cid={item?.recent_info?.last_chapter_cid}
                                            onClick={(e)=>{
                                                const ct_type = e.currentTarget.getAttribute("data-ct_type");
                                                const bid = e.currentTarget.getAttribute("data-bid");
                                                const cid = e.currentTarget.getAttribute("data-cid");
                                                navigate("/content/"+ct_type+"/"+bid+"/episode")
                                                // navigate("/view/"+ct_type+"/"+cid)
                                            }}>
                                            <div className="cover_img radius-xs">
                                                {item?.adult ? <p className="ic_adult badge_adult_middle"><img src="/images/ic_adult.svg" /></p> : null}
                                                {/* <img src="../images/thumbnail.jpg" /> */}
                                                {/* <BookpalLazyLoadImage 
                                                    placeholderSrc={ImgDefault("cover_logo")}
                                                    // effect="blur"
                                                    src={ImgCover(memberInfo?.view_type, item?.adult, ImgDefault("cover_lock"), item?.cover)}
                                                    // style={defaultImageStyle}
                                                    onError={(e)=>{
                                                        e.currentTarget.src = ImgDefault("cover_logo");
                                                    }}
                                                /> */}
                                                <BookpalLazyLoadImage 
                                                    adult={item?.adult}
                                                    adultswitch={memberInfo?.audit_switch}
                                                    defaulttype={"cover_logo"}
                                                    locktype={"cover_lock"}
                                                    src={item?.cover}
                                                    // style={defaultImageStyle}
                                                />
                                                <p className="ic_bottom badges_box">
                                                    {/* <span className="ic_midnight radius-xs icon_badge"><img src="../images/midnight_badge.svg" /></span>
                                                    <span className="ic_discount radius-xs discount_10 icon_badge"><img src="../images/discount_10.svg" /></span> */}
                                                    {item?.badge_promotion !== "" ? <span className="ic_midnight radius-xs icon_badge"><img src={ImgBadge(item?.badge_promotion)} /></span> : null}
                                                    {/* {item?.badge_new_up !== "" ? <span className="ic_up radius-xs icon_badge"><img src={ImgBadge(item?.badge_new_up)} /></span> : null} */}
                                                    {item?.badge_discount > 0 ? <span className="ic_discount radius-xs discount_10 icon_badge"><img src={ImgBadge(item?.badge_discount)} /></span> : null }
                                                </p>
                                            </div>
                                            <div className={"info_txt spacing_08 " + style['info_txt']}>
                                                <div className="info">
                                                    <span className="color-txt-05 body-01">{getCtTypeName(item?.ct_type)}</span>
                                                    <span className="color-txt-05 body-01">{item?.genre_kor}</span>
                                                    <span className="color-txt-05 body-01">{item?.is_finished ? "완결":item?.is_rest ? "휴재":"연재"}</span>
                                                    {/* <span className="color-txt-05 body-01">웹소설/웹툰</span>
                                                    <span className="color-txt-05 body-01">장르</span>
                                                    <span className="color-txt-05 body-01">완결여부</span> */}
                                                </div>
                                                <div className="list_tit_con">
                                                    {item?.badge_new_up !== "" ? <span className="ic_up radius-xs icon_badge"><img src={ImgBadge(item?.badge_new_up)} /></span> : null}
                                                    <span className="title color-txt-normal title-book-03-m text_overflow">{item?.title}</span>
                                                </div>
                                                <div className="writer color-txt-03 body-02 text_overflow">{item?.wr_nick}</div>
                                                <div className={"info date " + style['date']}>
                                                    <span className="color-txt-05 body-01">{item?.recent_info?.last_chapter_read_update_date} 열람</span>
                                                    {item?.recent_info?.new_story_cnt > 0 ?
                                                    <span className="color-btn-01 txt body-01">UP {item?.recent_info?.new_story_cnt}+</span>
                                                    :null}
                                                </div>
                                            </div>
                                        </BookpalLink>
                                        <div className={"button_box " + style['button_box']} style={{display:(editMode)?"none":"block"}}>
                                            <button 
                                                type="button" 
                                                className={"btn_05 filled label_on medium radius-full button " + style['button']}
                                                data-ct_type={item?.ct_type}
                                                data-bid={item?.bid}
                                                data-cid={item?.recent_info?.last_chapter_cid}
                                                onClick={(e)=>{
                                                    const ct_type = e.currentTarget.getAttribute("data-ct_type");
                                                    const bid = e.currentTarget.getAttribute("data-bid");
                                                    const cid = e.currentTarget.getAttribute("data-cid");


                                                    if(isIosApp()){
                                                        navigate("/view/"+ct_type+"/"+bid+"/"+cid);
                                                        return;
                                                    }

                                                    navigate("/view/"+ct_type+"/"+cid+"/viewer");
                                                    // navigate("/content/"+ct_type+"/"+bid+"/episode")
                                                    // /view/novel/2570396
                                                }}
                                                ><span className="btn-03 color-btn-03-txt">이어보기</span></button>
                                            <p className="caption-02 color-txt-05"><span>{item?.recent_info?.last_chapter_read}</span>{item?.ct_type === "epub"?"권":"화"} 보는중</p>
                                        </div>
                                    </li>
                                )
                            })}
                            
                        </ul>
                    </div>
                    :<p className="no_data small body-02-long color-txt-04">최근 본 작품이 없습니다.<br />북팔에서 인기 있는 작품을 추천해드려요.</p>}
                    <div className={"fixed_button " + style['fixed_button']} style={{display:(editMode)?"flex":"none"}}>
                        <button 
                            type="button" 
                            className="button btn_01 filled btn-05 white-always txt" 
                            disabled={unsetRecentArr?.length > 0 ? false:true}
                            onClick={()=>{
                                handlerExclude();
                            }}
                            ><span>{unsetRecentArr?.length}</span>개 삭제하기</button>
                    </div>
                    {/* <!-- 작품 없을때 --> */}
                    {/* <!-- <p className="no_data small body-02-long color-txt-04">최근 본 작품이 없습니다.<br>북팔에서 인기 있는 작품을 추천해드려요.</p> --> */}
                </section>

                {/* <!-- 세로표지 // 작품없을때 나오는 리스트  --> */}
                {/* <VerticalSection /> */}
            </div>
            <Modal />
            <ConfirmModal />
            <AlertModal />
            {/* <!-- container --> */}
            <Footer />
            <TopBtn />
        </>
    );
}


export default Recent;