import * as types from 'constant/ActionType';
import * as datas from 'constant/DataType';
import { getTokenWithUserAgent, getLastSocialLoginTypeWithUserAgent, getLastSocialLoginDateWithUserAgent, getAppsDataWithUserAgent } from 'utils/Common';
import { getStorageData, setStorageData } from 'utils/Storage';
import { createStore } from 'redux';
import rootReducer from 'reducer';

const configureStore = () => {
  const store = createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
  return store;
};

const store = configureStore();

try {
    const userAgentToken = getTokenWithUserAgent(navigator.userAgent);
    const tokens = userAgentToken ? {token:userAgentToken} : getStorageData(datas.TOKENS);
    if (tokens) {
        store.dispatch({ type: types.SET_TOKEN, token: tokens });
    }
} catch(e) {}


try {
    const userAudltSwitch = getAppsDataWithUserAgent()?.audit_switch;
    // const audltSwitch = userAudltSwitch === "on" ? true : getStorageData(datas.ADULT_SWITCH);
    if(userAudltSwitch === "on" || userAudltSwitch === "off"){
        const audltSwitch = userAudltSwitch === "on" ? true : false;
        console.log("audltSwitch",audltSwitch)
        // if (audltSwitch) {
        store.dispatch({ type: types.SET_ADULT_SWITCH, adult_switch: audltSwitch });
        // }
    }
} catch(e) {}


try {
    const saveId = getStorageData(datas.SAVE_ID);
    if (saveId) {
        store.dispatch({ type: types.SET_SAVE_ID, saveid: saveId });
    }
} catch(e) {}

try {
    const userAgentLastSocialLoginType = getLastSocialLoginTypeWithUserAgent(navigator.userAgent);
    // console.log("userAgentLastSocialLoginType",userAgentLastSocialLoginType);
    const lastSocialLoginType = userAgentLastSocialLoginType ? userAgentLastSocialLoginType : getStorageData(datas.LAST_SOCIAL_LOGIN_TYPE);
    if (lastSocialLoginType) {
        store.dispatch({ type: types.SET_LAST_SOCIAL_LOGIN_TYPE, last_social_login_type: lastSocialLoginType });
    }
} catch(e) {}

try {
    const userAgentLastSocialLoginDate = getLastSocialLoginDateWithUserAgent(navigator.userAgent);
    console.log("userAgentLastSocialLoginDate",userAgentLastSocialLoginDate);
    const lastSocialLoginDate = userAgentLastSocialLoginDate ? userAgentLastSocialLoginDate : getStorageData(datas.LAST_SOCIAL_LOGIN_DATE);
    if (lastSocialLoginDate) {
        store.dispatch({ type: types.SET_LAST_SOCIAL_LOGIN_DATE, last_social_login_date: lastSocialLoginDate });
    }
} catch(e) {}

try {
    const tooltipLogin = getStorageData(datas.TOOLTIP_LOGIN);
    if (tooltipLogin) {
        store.dispatch({ type: types.SET_TOOLTIP_LOGIN, tooltip_login: tooltipLogin });
    }
} catch(e) {}

try {
    const tooltip = getStorageData(datas.TOOLTIP_SEARCH);
    if (tooltip) {
        store.dispatch({ type: types.SET_TOOLTIP_SEARCH, tooltip_search: tooltip });
    }
} catch(e) {}


try {
    const lastSocialLoginTryPath = getStorageData(datas.LAST_SOCIAL_LOGIN_TRY_PATH);
    if (lastSocialLoginTryPath) {
        store.dispatch({ type: types.SET_LAST_SOCIAL_LOGIN_TRY_PATH, last_social_login_try_path: lastSocialLoginTryPath });
    }
} catch(e) {}

try {
    const lastSocialLoginTryType = getStorageData(datas.LAST_SOCIAL_LOGIN_TRY_TYPE);
    if (lastSocialLoginTryType) {
        store.dispatch({ type: types.SET_LAST_SOCIAL_LOGIN_TRY_TYPE, last_social_login_try_type: lastSocialLoginTryType });
    }
} catch(e) {}

try {
    const connectSocialLoginType = getStorageData(datas.CONNECT_SOCIAL_LOGIN_TYPE);
    if (connectSocialLoginType) {
        store.dispatch({ type: types.SET_CONNECT_SOCIAL_LOGIN_TYPE, connect_social_login_type: connectSocialLoginType });
    }
} catch(e) {}

try {
    const certReturnPath = getStorageData(datas.CERT_RETURN_PATH);
    if (certReturnPath) {
        store.dispatch({ type: types.SET_CERT_RETURN_PATH, cert_return_path: certReturnPath });
    }
} catch(e) {}

try {
    const autoLogin = getStorageData(datas.AUTO_LOGIN);
    // console.log(autoLogin)
    if (autoLogin === false || autoLogin === true) {
        store.dispatch({ type: types.SET_AUTO_LOGIN, auto_login: autoLogin });
    }
} catch(e) {}

try {
    const adultSwitch = getStorageData(datas.ADULT_SWITCH);
    // console.log(autoLogin)
    if (adultSwitch === false || adultSwitch === true) {
        store.dispatch({ type: types.SET_ADULT_SWITCH, adult_switch: adultSwitch });
    } else {
        store.dispatch({ type: types.SET_ADULT_SWITCH, adult_switch: true });
        setStorageData(datas.ADULT_SWITCH, true);
    }
} catch(e) {}

try {
    const systemMode = getStorageData(datas.SYSTEM_MODE);
    store.dispatch({ type: types.SET_SYSTEM_MODE, system_mode: systemMode });
} catch(e) {}

try {
    const data = getStorageData(datas.APP_BANNER);
    store.dispatch({ type: types.SET_APP_BANNER, app_banner: data });
} catch(e) {}


try {
    const saveidCheck = getStorageData(datas.SAVE_ID_CHECK);
    if(saveidCheck === false || saveidCheck === true){
        store.dispatch({ type: types.SET_SAVE_ID_CHECK, saveid_check: saveidCheck });
    } else {
        store.dispatch({ type: types.SET_SAVE_ID_CHECK, saveid_check: true });
    }
} catch(e) {}


// --------------------
// Viewer
// --------------------

try {
    const data = getStorageData(datas.VIEWER_INFO_FONT) ?? "NanumBarunGothic";
    store.dispatch({ type: types.SET_VIEWER_INFO_FONT, viewer_font: data });
} catch(e) {}

try {
    const data = getStorageData(datas.VIEWER_INFO_FONT_SIZE) ?? 5;
    store.dispatch({ type: types.SET_VIEWER_INFO_FONT_SIZE, viewer_font_size: data });
} catch(e) {}

try {
    const data = getStorageData(datas.VIEWER_INFO_LINE_HEIGHT) ?? 4;
    store.dispatch({ type: types.SET_VIEWER_INFO_LINE_HEIGHT, viewer_line_height: data });
} catch(e) {}

try {
    const data = getStorageData(datas.VIEWER_INFO_SIDE_MARGIN) ?? 3;
    store.dispatch({ type: types.SET_VIEWER_INFO_SIDE_MARGIN, viewer_side_margin: data });
} catch(e) {}

try {
    const data = getStorageData(datas.VIEWER_INFO_STYLE) ?? "scroll";
    store.dispatch({ type: types.SET_VIEWER_INFO_STYLE, viewer_style: data });
} catch(e) {}

try {
    const data = getStorageData(datas.VIEWER_INFO_THEME) ?? "white";
    store.dispatch({ type: types.SET_VIEWER_INFO_THEME, viewer_thmeme: data });
} catch(e) {}

try {
    const data = getStorageData(datas.VIEWER_INFO_IMAGE_NONE) ?? false;
    store.dispatch({ type: types.SET_VIEWER_INFO_IMAGE_NONE, viewer_image_none: data });
} catch(e) {}

try {
    const data = getStorageData(datas.VIEWER_INFO_RUN) ?? false;
    store.dispatch({ type: types.SET_VIEWER_INFO_RUN, viewer_run: data });
} catch(e) {}

try {
    const data = getStorageData(datas.DOT) ?? false;
    store.dispatch({ type: types.SET_DOT, dot: data });
} catch(e) {}



export default store;
