import { ApiPayPayVbanks } from 'api/Pay';

import style from './style/Order.module.scss'

import img_virtual_account from 'assets/images/order/virtual_account.png'
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';

function OrderVBankInfo({oid, msg}){

    const [data, setData] = useState(null);
    const navigate = useBookpalNavigate();

    const payResultSubmit = ApiPayPayVbanks({
        onSuccess:(data)=>{
            if(data?.status === true){
                setData(data?.result);
            }
        },
        onError:(e)=>{

        }
    });

    useEffect(()=>{
        payResultSubmit({})
    },[])

    if(data === null){
        return <></>
    }

    return (
        <>
            {/* <!-- 충전 헤더 --> */}
            <div id="TopHeader" className={"color-bg-01 title_header " + style['TopHeader'] + " " + style['title_header']}>
                <div className={"title_menu wrapper " + style['title_menu']}>
                    <div className={"btns left_btns " + style['btns'] + " " + style['left_btns']}></div>
                    <div className={"title-04 color-txt-normal text_overflow " + style['text_overflow'] + " " + style['title-04']}>가상계좌이체</div>
                    <div className={"btns right_btns " + style['btns'] + " " + style['right_btns']}>
                        <button 
                            type="button" 
                            className={"close_btn " + style['close_btn']} 
                            onClick={()=>{window.close();}}
                            ></button>
                    </div>
                </div>
            </div>

            <div id="container" className={"s_wrap wrapper " + style['s_wrap'] + " " + style['container']} style={{margin:"0px auto"}}>
                <section className={"area_box color-bg-01 radius-lg color-border-01 border-soild border-line " + style['area_box']}>
                    <div className={"complete_box virtual_account " + style['complete_box'] + " " + style['virtual_account']}>
                        <p className={"benefits_img section " + style['benefits_img']}>
                            <img src={img_virtual_account} />
                        </p>
                        <ul className={"list " + style['list']}>
                            {data && Array.isArray(data) && data?.length > 0 ?
                                data?.map((item,index)=>{

                                    return (
                                        <li className={"section "}>
                                            <div className={"tit_con " + style['tit_con']}>
                                                <h2 className={"title title-01-b color-txt-normal " + style['title']}>입금 정보</h2>
                                            </div>
                                            <ul className={"desc_box radius-sm color-bg-02 " + style['desc_box']}>
                                                <li>
                                                <p className={"s_title body-02 color-txt-muted " + style['s_title']}>입금은행</p>
                                                <p className={"s_desc body-04-long color-txt-normal " + style['s_desc']}>{item?.vbank_nm}</p>
                                                </li>
                                                <li>
                                                <p className={"s_title body-02 color-txt-muted " + style['s_title']}>계좌 번호</p>
                                                <p className={"s_desc body-04-long color-txt-normal " + style['s_desc']}>
                                                {item?.vbank_num}
                                                </p>
                                                </li>
                                                <li>
                                                <p className={"s_title body-02 color-txt-muted " + style['s_title']}>예금주</p>
                                                <p className={"s_desc body-04-long color-txt-normal " + style['s_desc']}>{item?.vbank_company_nm}</p>
                                                </li>
                                                <li>
                                                <p className={"s_title body-02 color-txt-muted " + style['s_title']}>입금 금액</p>
                                                <p className={"s_desc body-04-long color-txt-normal " + style['s_desc']}>{item?.vbank_price}</p>
                                                </li>
                                                <li>
                                                <p className={"s_title body-02 color-txt-muted " + style['s_title']}>입금 기한</p>
                                                <p className={"s_desc body-04-long color-txt-normal " + style['s_desc']}>{item?.vbank_expire}</p>
                                                </li>
                                                <li>
                                                <p className={"s_title body-02 color-txt-muted " + style['s_title']}>진행 상태</p>
                                                <p className={"s_desc body-04-long color-txt-normal " + style['s_desc']}>{item?.vbank_status}</p>
                                                </li>
                                            </ul>
                                        </li>
                                    )
                                })
                            :null}
                        </ul>
                    </div>
                </section>
            </div>
        </>
    )
}



export default OrderVBankInfo;