import { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BookpalLink from 'component/common/custom/BookpalLink';
import { ApiOrderOrderList } from 'api/Order';
import style from './style/Mypage.module.scss';
import useCouponInfoModal from 'component/common/modal/useCouponInfoModal';

function getNameWithFlag(flag){
    switch(flag){
        case "26": return "작가쿠폰";
        case "41": return "일괄구매";
        default: return "";
    }
}

function MypageCouponChargeList(){

    const contentRef = useRef(null);
    
    const [showTooltip, setShowTooltip] = useState(false);

    const [data, dataSubmit, initData, paging] = ApiOrderOrderList({
        bottomRef:contentRef
    });

    useEffect(()=>{
        dataSubmit();
    },[]);

    if(data === null){
        return null;
    }

    return (
        <>
            <div className={"list " + style['list']} ref={contentRef}> 
                <div className={"list_1 " + style['list_1']}>
                    <div className={"tit_con clearfix " + style['tit_con']}>
                        <div className={"left " + style['left']}></div>
                        <div className={"right " + style['right']}>
                            <p className={"button btn-03 color-btn-02-disabled txt " + style['button'] + " " + style['txt'] + " " + style['infoBtn']} 
                                id="infoBtn"
                                onClick={()=>{
                                    setShowTooltip(true);
                                }}
                                ><span className={"icon " + style['icon']}></span>이용안내</p>
                            <div 
                                id="toolTip" 
                                className={"toolTip right " + style['toolTip'] + " " + style['right']} style={{display:(showTooltip ? "block":"none")}}
                                onClick={()=>{
                                    setShowTooltip(false);
                                }}
                                >
                                <button type="button" className={"radius-xs color-btn-01 bg top_left " + style['top_left']}>
                                    <span className={"white-always caption-02-long txt " + style['txt']}>
                                        <span className={"title title-tiny-02 " + style['title']}>쿠폰 이용 안내</span>
                                        - 쿠폰은 북팔에서 사용하는 <br />전자 화폐입니다.<br />
                                        - 쿠폰은 충전해서 사용하실 수 <br />있습니다.<br />
                                        - 쿠폰 1개로 웹소설 유료 회차 <br />1개를 열람할 수 있습니다. 
                                    </span>
                                    <img src="/images/x_circle.svg" />
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* <!-- 최근 90일까지의 데이터리스트 한번에 보이기 --> */}
                    {data && Array.isArray(data) && data?.length > 0 ? 
                    <ul className="list_box">
                        {data.map((item, index)=>{
                            return (
                                <li key={index} className={"border-soild border-bottom-line color-border-01 " + (item?.r_id !== null ? "cancel_list" : "")}>
                                    <BookpalLink to={item?.o_order_coupon_id > 0 ? "/order/pay/history/" + item?.o_order_coupon_id : null}>
                                        <div className="left info_txt spacing_08">
                                            <div className="list_tit_con">
                                                <span className="title-01-b color-txt-normal text_overflow">{item?.title === "" ? getNameWithFlag(item?.c_flag) : item?.title}</span>
                                                <span className={"icon " + style['icon']}></span>
                                            </div>
                                            <div className="category body-02 color-txt-03">{item?.type}</div>
                                            <div className="date body-01 color-txt-05">{item?.c_date_insert}</div>
                                        </div>
                                        <div className={"right " + style['right']}>
                                            <div className="title title-01-m color-txt-normal">쿠폰 <span>{item?.c_coupon}</span>개 충전</div>
                                        </div>
                                    </BookpalLink>
                                </li>
                            )
                        })}
                    </ul>
                    : <p className="no_data small color-txt-04 body-02-long">최근 충전 내역이 없습니다. <br />내역은 최근 90일까지 노출됩니다. </p>}
                    
                    <div className="notice_box_desc section">
                        <p className="title title-02-b color-txt-04">유의사항</p>
                        <ul className="desc_list body-02-long color-txt-05">
                            <li>쿠폰 충전 후 7일 이내 사용하지 않은 쿠폰에 대해선 환불이 가능하며, 이후에는 환불이 불가합니다.</li>
                            <li>쿠폰 충전 후 쿠폰을 ‘전혀 사용하지 않은 경우’에만 전액 환불 가능합니다. 쿠폰을 하나라도 사용하게 되면 환불은 불가합니다. </li>
                            <li>환불된 내역은 취소 표시가 노출됩니다. </li>
                            <li>쿠폰에 관련하여 궁금하신 점은 <span className="color-txt-03">고객센터 &gt; FAQ</span>를 참고해 주세요.</li>
                        </ul>
                    </div>
                </div>
                
            </div>
        </>
    )
}




export default MypageCouponChargeList;