import React from 'react';
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { ApiMainFreeviewMain } from "api/Main";
import { checkPopupData, setPopupData, setStorageData } from 'utils/Storage';
import { useMemberInfo } from 'utils/State';

import style from "./MainFreeview.module.scss";
// import "./main_mediaquery.scss";


import AppBanner from 'component/common/banner/AppBanner';
import Header from 'component/common/header/Header';
import MobileNav from 'component/common/nav/MobileNav';

import Footer from 'component/common/footer/Footer';
import TopBtn from 'component/common/button/top/TopBtn';

import MainBanner from 'component/common/banner/MainBanner';
import QuickNav from 'component/common/nav/QuickNav';
import RecommendWorkSection from 'component/common/section/RecommendWorkSection';
import ExclusiveTypeOneSection from 'component/common/section/ExclusiveTypeOneSection';
import ExclusiveTypeTwoSection from 'component/common/section/ExclusiveTypeTwoSection';
import VerticalLongSection from 'component/common/section/VerticalLongSection';
import SmallBanner from 'component/common/banner/SmallBanner';
import RankingSection from 'component/common/section/RankingSection';
import VerticalSection from 'component/common/section/VerticalSection';
import RecommendKeywordSection from 'component/common/section/RecommendKeywordSection';
import HorizontalSection from 'component/common/section/HorizontalSection';
import FreeChargeSection from 'component/common/section/FreeChargeSection';
import AppDownloadSection from 'component/common/section/AppDownloadSection';
import ExclusiveTypeSection from 'component/common/section/ExclusiveTypeSection';
import usePopupModal from 'component/common/modal/usePopupModal';
import useAlertModal from 'component/common/modal/useAlertModal';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function defaultBaseData(){
    const data = {};
    data['sections'] = [];
    const main_banner = [];
    for(let i=0,m=5;i<m;i++){
        main_banner.push({
            "type": "main",
            "adult_type": "all",
            "link": "",
            "bg_color": "",
            "bg_image": "",
            "description": "",
            "badge_txt": ""
        });
    }
    data['sections'].push({
        "type": "main_banner",
        "data": main_banner
    });
    const quick_menu = [];
    for(let i=0,m=6;i<m;i++){
        quick_menu.push({
            "title": "------",
        });
    }
    data['sections'].push({
        "type": "quick_menu",
        "data": quick_menu
    });
    data['sections'].push({
        type:"blank"
    })

    return data;
}



function popupData(data){
    const popup_data = [];
    if(Array.isArray(data)){
        data.map((item)=>{
            if(Array.isArray(item?.data)){
                if(popup_data?.length === 0){
                    item?.data.map(item2=>{
                        if(!checkPopupData(item2?.id)){
                            // console.log(item2)
                            popup_data.push( item2 );
                        }
                    })
                }
            }
        });
    }
    popup_data.sort(() => Math.random() - 0.5);
    return popup_data[0] ?? null
}


function truncateText(text, maxLength = 10) {
    // const element = document.querySelector(selector);
    // const text = element.textContent;

    if(typeof text !== "string"){
        return "";
    }

    if (text.length > maxLength) {
        return text.slice(0, maxLength) + '...';
    }
    return text;
}

function MainFreeview(){

    // const memberInfo = useMemberInfo();

    const query = useQuery();
    const no = query.get("no");

    const [data, setData] = useState(defaultBaseData());

    const [AlertModal, alert] = useAlertModal();

    // console.log(memberInfo);
    const mainSubmit = ApiMainFreeviewMain({
        onSuccess:(data)=>{
            if(data?.status === true){
                setData(data?.result);
                return;
            }
            switch(data?.code){
                case "system_error":
                    alert("에러","시스템 에러입니다. 관리자에게 문의주세요.");
                    break;
                default:
                    alert("에러","페이지 로딩에 실패하였습니다. 문제가 지속될 경우 관리자에게 문의주세요.");
                    break;
            }
        },
        onError:(e)=>{
            alert("에러","통신에 실패하였습니다. 문제가 지속될 경우 관리자에게 문의주세요.");
        }
    });

    const [PopupModal, showPopupModal] = usePopupModal();

    useEffect(()=>{
        if(no > 0){
            mainSubmit({
                no:no,
            });
        }
        
    },[]);

    useEffect(()=>{
        if(data?.main_popup){
            const pop_data = popupData(data?.main_popup);
            if(pop_data){
                showPopupModal(pop_data?.image, pop_data?.link, pop_data?.id, ()=>{
                    setPopupData(pop_data?.id)
                });
                // console.log(pop_data);
            }
        }
    },[data]);

    if(data === null){
        return <></>
    }


    return (
        <>
            <AppBanner />
            <Header />
            <MobileNav />
            
            <main className={""}>
                {!!data?.sections && Array.isArray(data?.sections) ? 
                    data?.sections.map((item, index)=>(
                        <React.Fragment key={index}>
                            {(()=>{
                                switch(item?.type){
                                    case "main_banner":
                                        return <MainBanner data={item?.data} />;
                                    case "quick_menu":
                                        return <QuickNav data={item?.data} />;
                                    case "recommend_work":
                                        return <VerticalSection 
                                                    data={item?.data} 
                                                    title={truncateText(item?.nickname,10) + item?.title} 
                                                    moreLink={item?.link_more}
                                                    useInfo={true}
                                                    useWriter={true}
                                                    />
                                    case "vertical_sec":
                                    case "recent_view":
                                        return <VerticalSection 
                                                    data={item?.data} 
                                                    title={item?.title} 
                                                    moreLink={item?.link_more}
                                                    useInfo={true}
                                                    useWriter={true}
                                                    />
                                    case "recommend_keyword":
                                        return <RecommendKeywordSection 
                                                    data={item?.keyword} 
                                                    title={item?.keyword_genre} 
                                                    image={item?.image} 
                                                    />
                                    case "free_charge":
                                        return <FreeChargeSection data={item} />
                                    case "app_download":
                                        return <AppDownloadSection data={item} />
                                    case "horizontal_toon_rank":
                                    case "horizontal_novel_rank":
                                        return <RankingSection 
                                                    tabs={item?.tabs}
                                                    title={item?.title}
                                                    isRand={true}
                                                    />
                                    case "vertical_long_sec":
                                        return <VerticalLongSection 
                                                    data={item?.data}
                                                    moreLink={item?.link_more}
                                                    title={item?.title}
                                                    />
                                    case "horizontal_sec":
                                        return <HorizontalSection 
                                                    data={item?.data}
                                                    moreLink={item?.link_more}
                                                    title={item?.title}
                                                    />
                                    case "exclusive_sec":
                                        return <ExclusiveTypeSection 
                                                    item={item}
                                                    />
                                    case "small_banner":
                                        return <SmallBanner 
                                                    item={item}
                                                    data={item?.data}
                                                    />
                                    case "blank":
                                        return <>
                                            <div style={{height:"300px"}}></div>
                                        </>
                                            
                                }
                            })()}
                        </React.Fragment>
                    ))
                :null}
                {/* <MainBanner /> */}
                {/* <QuickNav /> */}
                {/* <RecommendWorkSection /> */}
                {/* <ExclusiveTypeOneSection />
                <ExclusiveTypeTwoSection /> */}
                {/* <VerticalLongSection /> */}
                {/* <SmallBanner /> */}
                {/* <RankingSection /> */}
                {/* <VerticalSection /> */}
                {/* <RecommendKeywordSection /> */}
                {/* <HorizontalSection /> */}
                {/* <FreeChargeSection /> */}
                {/* <AppDownloadSection /> */}
            </main>
            
            <Footer style={{display:"block",padding:0,paddingTop:"36px"}} />
            <TopBtn 
                style={{position:"static",margin:0,textAlign:"center",padding:"16px 0 86px",display:"block"}}
                isNoneShadow={true}
                />
            <PopupModal />
            <AlertModal />
        </>
    );
}


export default MainFreeview;

