import { useEffect, useRef, useState } from 'react';
import { useIsMobile } from 'utils/Common';
import { ApiOrderOrderUseAllList } from 'api/Order';
import { useParams, useNavigate } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import { Link } from 'react-router-dom';
import BookpalLink from 'component/common/custom/BookpalLink';
import { runPopupOrder } from 'utils/Common';
import style from './style/Contents.module.scss';

import Header from 'component/common/header/Header';
import SubNav from 'component/common/nav/SubNav';
import Midnight from './component/promotion/Midnight';
import PlusFree from './component/promotion/PlusFree';
import PlusOne from './component/promotion/PlusOne';
import Bind from './component/promotion/Bind';
import Gift from './component/promotion/Gift';
import Hotdeal from './component/promotion/Hotdeal';
import Review from './component/promotion/Review';
import EpubHotdeal from './component/promotion/EpubHotdeal';
import useAlertModal from 'component/common/modal/useAlertModal';


function ContentsDetailUse(){

    const isMobile = useIsMobile();
    const navigate = useBookpalNavigate();

    const { type, bid } = useParams();

    const contentRef = useRef(null);


    const [AlertModal, alert] = useAlertModal();

    const [data, dataSubmit, initData, paging, setData] = ApiOrderOrderUseAllList({
        data:{
            bid:bid,
            ct_type:type,
        },
        bottomRef:contentRef,
    });

    useEffect(()=>{
        dataSubmit();
    },[])
    
    if(data === null){
        return null;
    }

    return (
        <>
            
            <div id="container" className={" " + style['container']} >
                <section 
                    className={"section detail_box wrapper clearfix template " + style['detail_box'] + " " + style['section'] + " " + style['template'] + " " + style['wrapper']}
                    style={{display:"block"}}
                    >
                        
                    <div className={"area_box history_list " + style['area_box'] + " " + style['history_list']}>
                        {/* <!-- 리스트 --> */}
                        {data && Array.isArray(data) && data?.length > 0 ? 
                        <>
                            <ul className="list" ref={contentRef}>
                                {data.map((item,index)=>{

                                    return (
                                        <li key={index} className={"border-soild border-bottom-line color-border-01 "}>
                                            <BookpalLink 
                                                data-bid={item?.bid}
                                                data-cid={item?.cid}
                                                data-type={item?.type}
                                                style={{cursor:"auto"}}
                                                // onClick={handlerClick}
                                                >
                                                <div className="left info_txt spacing_08">
                                                    <div className="list_tit_con">
                                                        <span className={"title-01-b text_overflow color-txt-normal"}>{item?.all_type === "all" ? "전체" : item?.title}</span>
                                                        {/* <span className="icon"></span> */}
                                                    </div>
                                                    <div className={"category body-02 color-txt-03"}>
                                                        {item?.extra === 1 ? "외전":""} {item?.no}화 {item?.buy_type === "own" ? "소장":null} {item?.buy_type === "rent" ? "대여":null}</div>
                                                    <div className={"date body-01 color-txt-05"}>{item?.date}</div>
                                                </div>
                                                <div className="right">
                                                    <div className={"title title-01-m color-txt-normal "}>
                                                        {item?.list_type === "gift" ? 
                                                        <>
                                                            {item?.buy_type === "own" ? "선물소장권":null} 
                                                            {item?.buy_type === "rent" ? "선물이용권":null}
                                                        </>
                                                        :
                                                        <>
                                                            쿠폰 {item?.buy_cnt}장
                                                        </>
                                                        }
                                                    </div>
                                                    <div className="data body-01 color-txt-05">[{item?.type === "novel" ? "웹소설":"웹툰"}]</div>
                                                    
                                                </div>
                                            </BookpalLink>
                                        </li>
                                    )
                                })}
                                
                                
                            </ul>
                            
                            {/* <!-- 맨 마지막지점에서 --> */}
                            <div className={"body-02 color-txt-muted data_info " + style['data_info']}>
                                최근 90일 까지의 데이터가 노출됩니다.
                            </div>
                        </>
                        :<div className="no_data medium color-txt-04 body-02-long">최근 90일 동안 사용한 내역이 없습니다.</div>}
                        {/* <!-- 내역 없을때 --> */}
                        {/* <!-- <div className="no_data medium color-txt-04 body-02-long">최근 90일 동안 받은혜택이 없습니다.</div> --> */}
                    </div>
                    
                </section>
                

                
            </div>
            <AlertModal />
        </>
    )
}



export default ContentsDetailUse;