import { useNavigate } from "react-router-dom";
import { isApp, appSetMove } from 'utils/AppUtil';
import { getBookpalLink } from 'utils/BookpalLinkUtil';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';

import SubNav from "component/common/nav/SubNav";

import style from '../style/User.module.scss';

import success_icon from 'assets/images/user/success_icon.png';

function CertComplete(){

    const navigate = useBookpalNavigate();
    

    const handlerMoveMain = ()=>{
        if(isApp()){
            appSetMove("",getBookpalLink("main"));
            return;
        }
        navigate("/");
    }


    return (
        <>
            <SubNav 
                name="본인인증 성공" 
                usePc={false} 
                useMobile={true} 
                setTop={'0px'} 
                useBtnPrev={false} 
                useBtnClose={true}
                onClose={()=>{}}
            />
            
            <div id="container" className={"s_wrap wrapper " + style['container']}>
                <section className={"success " + style['success']}>
                    <p className={"title color-txt-normal title-04 " + style['title'] }>본인인증 성공</p>
                    <div className={"area_box color-bg-01 radius-lg color-border-01 border-soild border-line " + style['area_box']}>
                        <div className={"logo_title " + style['logo_title']}>
                            <p className={"logo " + style['logo']}></p>
                            <p className={"body-01 color-txt-muted label_txt " + style['label_txt']}>당신의 상상이 이야기가 되는 곳</p>
                        </div>
                        <div className={"text_box " + style['text_box']}>
                            <p className={"images " + style['images']}><img src={success_icon} /></p>
                            <p className={"body-03-long color-txt-normal"}><span className="color-btn-01 txt">선물소장권</span>을 발급해드렸어요.<br />지금 바로 작품을 감상해보세요!</p>
                            <p className={"button_box " + style['button_box']}>
                                <button 
                                    type="button" 
                                    className={"radius-sm btn-04 txt btn_01 large label_on filled button " + style['button']}
                                    onClick={handlerMoveMain}
                                >메인으로 이동</button> 
                            </p>
                        </div>
                    </div>        
                </section>
            </div>
        </>
    );
}



export default CertComplete;