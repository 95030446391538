import { useRef } from 'react';
import { Link } from 'react-router-dom';
import BookpalLink from '../custom/BookpalLink';
import { Swiper, SwiperSlide } from 'swiper/react';


function SearchKeyword(props){

    const searchBoxClassName = props.searchBoxClassName || "";
    const slideClassName = props.slideClassName || "";
    const closeClassName = props.closeClassName || "";

    const keywordList = props.keywordList || null;

    const onSelect = props.onSelect || (()=>{});
    const onDelete = props.onDelete || (()=>{});
    const isSaveMode = props.isSaveMode === false ? false : true;

    const swiperRef = useRef(null);

    if(!isSaveMode){
        return (
            <p className="no_data small color-txt-04 body-02-long">최근 검색어 저장이 꺼져 있습니다.</p>
        )
    }

    // console.log(keywordList)
    if(keywordList === null || !Array.isArray(keywordList) || keywordList?.length === 0){
        return (
            <p className="no_data small color-txt-04 body-02-long">최근 검색어가 없습니다.</p>
        )
    }

    return (
        <>
            <Swiper className={"searh_box swiper mySwiper list " + searchBoxClassName} 
                ref={swiperRef}
                slidesPerView={'auto'}
                spaceBetween={8}
                watchOverflow={true}
                freeMode={true}
                breakpoints={{
                    768: {
                        freeMode : true,
                    },
                }}
            >
                {keywordList && Array.isArray(keywordList) ? 
                    keywordList?.map((item, index)=>{

                        return (
                            <SwiperSlide key={"keywordList_"+index} className={"swiper-slide radius-full color-border-01 border-line border-soild " + slideClassName}>
                                <BookpalLink 
                                    
                                    data-keyword={item}
                                    onClick={(e)=>{
                                        onSelect(e.currentTarget.getAttribute('data-keyword'));
                                    }}
                                    ><span className="color-txt-02 btn-04 text_overflow">{item}</span></BookpalLink>
                                <button 
                                    type="button" 
                                    className={"close_btn " + closeClassName}
                                    data-keyword={item}
                                    onClick={(e)=>{
                                        onDelete(e.currentTarget.getAttribute('data-keyword'));
                                    }}
                                    ><span></span></button>
                            </SwiperSlide>
                        )
                    })
                :null}
{/*                 
                <SwiperSlide className={"swiper-slide radius-full color-border-01 border-line border-soild " + slideClassName}>
                    <a href="#none"><span className="color-txt-02 btn-04 text_overflow">영웅은 몸으로 구한다1</span></a>
                    <button type="button" className={"close_btn " + closeClassName}><span></span></button>
                </SwiperSlide>
                <SwiperSlide className={"swiper-slide radius-full color-border-01 border-line border-soild " + slideClassName}>
                    <a href="#none"><span className="color-txt-02 btn-04 text_overflow">영웅은 몸으로 구한다1</span></a>
                    <button type="button" className={"close_btn " + closeClassName}><span></span></button>
                </SwiperSlide>
                <SwiperSlide className={"swiper-slide radius-full color-border-01 border-line border-soild " + slideClassName}>
                    <a href="#none"><span className="color-txt-02 btn-04 text_overflow">영웅은 몸으로 구한다1</span></a>
                    <button type="button" className={"close_btn " + closeClassName}><span></span></button>
                </SwiperSlide>
                <SwiperSlide className={"swiper-slide radius-full color-border-01 border-line border-soild " + slideClassName}>
                    <a href="#none"><span className="color-txt-02 btn-04 text_overflow">영웅은 몸으로 구한다1</span></a>
                    <button type="button" className={"close_btn " + closeClassName}><span></span></button>
                </SwiperSlide>
                <SwiperSlide className={"swiper-slide radius-full color-border-01 border-line border-soild " + slideClassName}>
                    <a href="#none"><span className="color-txt-02 btn-04 text_overflow">영웅은 몸으로 구한다1</span></a>
                    <button type="button" className={"close_btn " + closeClassName}><span></span></button>
                </SwiperSlide>
                <SwiperSlide className={"swiper-slide radius-full color-border-01 border-line border-soild " + slideClassName}>
                    <a href="#none"><span className="color-txt-02 btn-04 text_overflow">영웅은 몸으로 구한다1</span></a>
                    <button type="button" className={"close_btn " + closeClassName}><span></span></button>
                </SwiperSlide>
                <SwiperSlide className={"swiper-slide radius-full color-border-01 border-line border-soild " + slideClassName}>
                    <a href="#none"><span className="color-txt-02 btn-04 text_overflow">영웅은 몸으로 구한다1</span></a>
                    <button type="button" className={"close_btn " + closeClassName}><span></span></button>
                </SwiperSlide> */}
            </Swiper>
        </>
    )
}



export default SearchKeyword;