import { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation, Link  } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import { ApiAuthInfo } from 'api/Auth';
import useCouponInfoModal from 'component/common/modal/useCouponInfoModal';

import Header from 'component/common/header/Header';
import Footer from 'component/common/footer/Footer';

import SubNav from "component/common/nav/SubNav";

import IosGeneralOrder from './order_sub/IosGeneralOrder';

import style from './style/Order.module.scss';


import img_rating_label_normal from 'assets/images/rating_label_normal.svg';
import img_rating_label_vip from 'assets/images/rating_label_vip.svg';
import img_rating_label_vip_silver from 'assets/images/rating_label_vipSilver.svg';
import img_rating_label_vip_gold from 'assets/images/rating_label_vipGold.svg';
import img_rating_label_rvip from 'assets/images/rating_label_rVip.svg';

import img_kakao_logo from 'assets/images/order/kakao_logo.png';
import img_naver_logo from 'assets/images/order/naver_logo.png';
import img_payco_logo from 'assets/images/order/payco_logo.png';

import img_charge_banner1 from 'assets/images/order/charge_banner1.png';
import img_charge_banner2 from 'assets/images/order/charge_banner2.png';
import { isApp } from 'utils/AppUtil';


function getImgLevel(level){
    switch(level){
        default:
        case "normal": return img_rating_label_normal;
        case "vip": return img_rating_label_vip;
        case "silver": return img_rating_label_vip_silver;
        case "gold": return img_rating_label_vip_gold;
        case "rvip": return img_rating_label_rvip;
    }
}


function OrderIos(){

    const navigate = useBookpalNavigate();
    const location = useLocation();

    const [info, setInfo] = useState(null);
    const [CouponModal, couponShow] = useCouponInfoModal();

    const [tab, setTab] = useState({
        general:false,
        regular:false,
        smile:false
    });
    

    const handlerMemberInfoSubmit = ApiAuthInfo({
        onSuccess:(data)=>{
            if(data?.status === true){
                if(data?.result?.is_login === false){
                    alert('로그인 해주세요.');
                    window.close();
                    return;
                }

                setInfo(data?.result);
                return;
            }
            alert('시스템 오류입니다. 다시 시도해주세요.');
            //window.close();
            return;
        },
        onError:(e)=>{
            alert("통신이 실패하였습니다. 다시 시도해주세요.");
            //window.close();
        }
    })

    const handlerBack = () => {
        navigate(-1);
    }

    // console.log(location);

    useEffect(()=>{
        handlerMemberInfoSubmit();
    },[]);

    useEffect(()=>{
        setTab({
            general: (location.pathname === "/order/general"),
            regular: (location.pathname === "/order/regular"),
            smile: (location.pathname === "/order/smile"),
        });
    },[location?.pathname]);

    if(info === null){
        return (
            <>
                Loading...
            </>
        );
    }

    return (
        <>
            {/* <!-- 충전 헤더 --> */}
            {/* <div id="TopHeader" className={"color-bg-01 title_header " + style['TopHeader'] + " " + style['title_header']}>
                <div className={"title_menu wrapper " + style['title_menu']}>
                    <div className={"btns left_btns " + style['btns'] + " " + style['left_btns']}></div>
                    <div className={"title-04 color-txt-normal text_overflow " + style['text_overflow'] + " " + style['title-04']}>충전</div>
                    <div className={"btns right_btns " + style['btns'] + " " + style['right_btns']}>
                        <button 
                            type="button" 
                            className={"close_btn " + style['close_btn']} 
                            onClick={()=>{window.close();}}
                            ></button>
                    </div>
                </div>
            </div> */}

            {/* <!-- container --> */}
            <div id="container" className={"s_wrap wrapper " + style['container'] + " " + style['s_wrap'] + " " + style['wrapper']} style={{marginTop:"0"}}>
                <section className={"charge_box " + style['charge_box']}>
                    <div className={"area_box " + style['area_box']}>
                        <div className={"my_box section " + style['my_box'] + " " + style['section']} style={{padding:"0"}}>
                            <div className={"my_grade radius-sm gold_opacity-10 bg clearfix " + style['my_grade']}>
                                <div className={"left " + style['left']}>
                                    <p className="title-01-b color-txt-normal">내 등급</p>
                                </div>
                                <div className={"right " + style['right']}>
                                    <p className={"rating_label small " + style['rating_label']}>
                                        <span className={"normal " + style['normal']}>
                                            <img src={getImgLevel(info?.order_level)} className="Light_label" />
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className={"my_coupon radius-sm gold_opacity-10 bg clearfix " + style['my_coupon']}>
                                <div className={"left " + style['left']}>
                                    <p className="title-01-b color-txt-normal">내 쿠폰</p>
                                </div>
                                <div className={"right " + style['right']}>
                                    <p className="number title-03-m color-txt-normal"><span className="title-03">{info?.coupon_cnt ?? 0}</span>개</p>
                                    <p className={"coupon_button " + style['coupon_button']}>
                                        <button 
                                            type="button" 
                                            className={"button " + style['button']} 
                                            onClick={()=>{
                                                couponShow();
                                            }}
                                            ><span className="body-02 color-txt-05">이번달 사라질 예정인 쿠폰 <b>0개</b></span></button>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={"temp_list_type section " + style['temp_list_type'] + " " + style['section']} style={{padding:"0"}}>
                            {/* <ul className="tab_list menu_tab large">
                                <li className="color-txt-muted btn-04"><Link to={"general"} className={"border border-soild border-bottom-line " + (tab?.general ? "active":"")}>일반충전</Link></li>
                                <li className="color-txt-muted btn-04"><Link to={"regular"} className={"border border-soild border-bottom-line " + (tab?.regular ? "active":"")}>정기충전</Link></li>
                                <li className="color-txt-muted btn-04"><Link to={"smile"} className={"border border-soild border-bottom-line " + (tab?.smile ? "active":"")}>스마일충전</Link></li>
                            </ul> */}
                            <div className={"list " + style['list']} > 
                                <Routes>
                                    {/* <!-- ios --> */}
                                    <Route path='/ios' exact element={(
                                        <IosGeneralOrder />
                                    )} />
                                    <Route path="*" element={<Navigate to="ios" replace={true} />} />
                                </Routes>
                            </div>
                        </div>
                        
                    </div>
                </section>
            </div>
            <CouponModal />
            {/* <!-- container --> */}

            {/* <Footer /> */}
        </>
    );
}


export default OrderIos;