import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import { runPopupDeclare } from 'utils/Common';
import { 
    ApiCommentReplyWrite, 
    ApiCommentReplyDelete, 
    ApiCommentCommentDelete,
    ApiCommentReplyLike,
} from 'api/Comment';
import { ApiMypageReplyHistory } from 'api/Mypage';
import { ApiCommonWriterReject } from 'api/Common';
import { actionSetShowGiftToast } from 'action/index';
import { SetState } from 'utils/State';
import useConfirmCustomModal from 'component/common/modal/useConfirmCustomModal';
import useAlertModal from 'component/common/modal/useAlertModal';

import NewlineToBr from 'component/common/content/NewlineToBr';
import Header from 'component/common/header/Header';
import Footer from 'component/common/footer/Footer';

import SubNav from "component/common/nav/SubNav";

import style from './style/Mypage.module.scss';

import img_login_naver_icon from 'assets/images/user/login_naver_icon.svg';
import img_login_kakao_icon from 'assets/images/user/login_kakao_icon.svg';
import img_login_google_icon from 'assets/images/user/login_google_icon.svg';
import img_login_facebook_icon from 'assets/images/user/login_facebook_icon.svg';
import img_login_apple_icon from 'assets/images/user/login_apple_icon.svg';
import { isApp, appSetDeclare } from 'utils/AppUtil';




function MypageCommentReply(){


    const navigate = useBookpalNavigate();
    const dispatch = useDispatch();
    const contentRef = useRef(null);
    const setState = SetState();

    const { reply_id } = useParams();
    const [addComment, setAddComment] = useState("");
    const [likeReplyId, setLikeReplyId] = useState(null);
    const [deleteReplyId, setDeleteReplyId] = useState(null);

    const [rejectReplyId, setRejectReplyId] = useState(null);
    

    const [ConfirmModal, confirm] = useConfirmCustomModal();
    const [ConfirmModal2, confirm2] = useConfirmCustomModal();
    const [AlertModal, alert] = useAlertModal();

    // const handlerShowToast = (data)=>{
    //     const title = data?.title ?? null;
    //     const body = data?.body ?? null;
    //     const date = data?.date ?? "";
    //     dispatch(actionSetShowGiftToast({
    //         title:title,
    //         body:body,
    //         date:date,
    //     }));
    // }

    const [comment, data, dataSubmit, dataInit, paging, setData, setComment] = ApiMypageReplyHistory({
        data:{
            comment_id:reply_id
        },
        bottomRef:contentRef,
        onSuccess:(data)=>{
            console.log(data);
            switch(data?.code){
                case "not_found":
                    navigate(-1);
            }
        },
        onError:(e)=>{
            console.log(e);
        }
    });

    const handlerRejectOrDeclare = (reply_id, reject, nickname)=>{
        confirm({
            title:"신고/차단하기",
            successBtnTitle:"신고하기",
            successBtnTitle2:reject ? "차단 해제하기":"차단하기",
            cancleBtnTitle:"취소",
            successAction:()=>{
                console.log(comment);
                runPopupDeclare({
                    type:5,
                    chapter_id:comment?.chapter_id,
                    reply_id:comment?.comment_id,
                    comment_reply_id:reply_id,
                });

                if(isApp()){
                    appSetDeclare({
                        type:5,
                        chapter_id:comment?.chapter_id,
                        reply_id:comment?.comment_id,
                        comment_reply_id:reply_id,
                    });
                }
            },
            successAction2:()=>{
                setRejectReplyId(reply_id);
                confirm2({
                    title:"차단하기",
                    content:"["+nickname+"]님 댓글/답글을 "+(reject ? "차단 해제":"차단")+"하시겠습니까?",
                    successBtnTitle:reject ? "차단 해제하기":"차단하기",
                    cancleBtnTitle:"취소",
                    successAction:()=>{
                        rejectSubmit({
                            reject_id:reply_id,
                            reject_type:"reply",
                        });
                    },
                })
                
            },
        });
    }

    const rejectSubmit = ApiCommonWriterReject({
        onSuccess:(_data)=>{
            if(_data?.status === true){
                const reject = _data?.result?.reject;

                const tmp = [];
                data.map((item,index)=>{
                    if(item?.reply_id == rejectReplyId){
                        item.reject = reject;
                    }
                    tmp.push(item);
                })
                // rejectReplyId
                setData(tmp);

                if(reject){
                    setState.setToast({
                        title:"차단하였습니다.",
                    });
                } else {
                    setState.setToast({
                        title:"차단 해제하였습니다.",
                    });
                }
            }
        },
        onError:(e)=>{

        },
    });

    const replyLikeSubmit = ApiCommentReplyLike({
        onSuccess:(_data) => {
            if(_data?.status === true){
                const tmp = [];

                data.map((item) => {
                    if(item?.reply_id === parseInt(likeReplyId,10)){
                        
                        item.likes = _data?.result?.likes;
                        item.like_status = _data?.result?.like_status;
                    }
                    tmp.push(item);
                });

                setData(tmp);
                setLikeReplyId(null);
                // if(_data?.result?.like_status){
                //     setState.setToast({title:"좋아요 ."});
                // } else {
                //     setState.setToast({title:"비방/욕설글은 작성하실 수 없습니다."});
                // }
            }
        },
        onError:(e)=>{

        },
    });

    const handlerReplyLike = (reply_id) => {
        replyLikeSubmit({
            reply_id:reply_id,
        });
    }

    const deleteSubmit = ApiCommentCommentDelete({
        onSuccess:(_data)=>{
            console.log(_data);
            if(_data?.status === true){
                alert("삭제안내","삭제되었습니다.",()=>{
                    navigate(-1);
                });
            }
        },
        onError:(e)=>{
            console.log(e);
        }
    });

    const replySubmit = ApiCommentReplyWrite({
        onSuccess:(data)=>{
            if(data?.status === true){
                alert(null,'등록 되었습니다.',()=>{
                    window.location.reload();
                });
                return;
            }
            switch(data?.code){
                case "403":
                    setState.setToast({title:"로그인이 필요한 서비스입니다."});
                    break;
                case "400":
                    switch(data?.code_msg){
                        case "invalid_parameter_content_length":
                            setState.setToast({title:"300글자 이내로 작성해 주세요."});
                            break;
                        case "found_forbidden_words":
                            setState.setToast({title:"비방/욕설글은 작성하실 수 없습니다."});
                            break;
                        case "breach_of_policy":
                            setState.setToast({title:"댓글 작성할 수 없습니다."});
                            break;
                        case "abusing_user":
                            setState.setToast({title:"한번에 많은 글을 작성할 수 없습니다.\n잠시 후 다시 시도해 주세요."});
                            break;
                    }
                    break;
                default:
                    setState.setToast({title:"댓글 등록에 실패했습니다.\n잠시 후 다시 시도해 주세요."});
                    break;
            }
            // if(data?.code_msg === "found_forbidden_words"){
            //     setState.setToast({title:"비방/욕설글은 작성하실 수 없습니다."});
            // } else if(data?.code_msg === "abusing_user"){
            //     setState.setToast({title:"답글 등록 후 10초 이내는\n추가 등록할 수 없습니다."});
            // } else {
            //     setState.setToast({title:"등록에 실패하였습니다."});
            // }
        },
        onError:(e)=>{
            setState.setToast({title:"통신에 실패하였습니다."});
        }
    });

    const replyDeleteSubmit = ApiCommentReplyDelete({
        onSuccess:(_data)=>{
            console.log(_data);
            if(_data?.status === true){
                // handlerShowToast({
                //     title:"삭제되었습니다."
                // });

                setState.setToast({
                    title:"삭제되었습니다."
                });

                const n_data = [];
                data.map((item)=>{
                    if(item?.reply_id == deleteReplyId){
                        n_data.push({
                            ...item,
                            delete:true,
                        });
                    } else {
                        n_data.push(item);
                    }
                });
                setData(n_data);

                setComment({...comment,replies:comment?.replies -1 })
                
            }
        },
        onError:(e)=>{
            console.log(e);
        }
    });

    const replyWriteSubmit = ()=>{
        replySubmit({
            comment_id:reply_id,
            content:addComment
        });
    }

    const commentDeleteSubmit = (e)=>{
        const comment_id = e.currentTarget.getAttribute("data-comment-id");

        console.log(comment_id)
        confirm({
            title:"삭제 안내",
            content:"삭제한 글은 복구할 수 없습니다.",
            successBtnTitle:"삭제하기",
            successAction:()=>{
                deleteSubmit({
                    comment_id:comment_id
                })
            }
        });
    }

    const replyDelSubmit = (e)=>{
        const reply_id = e.currentTarget.getAttribute("data-reply-id");
        setDeleteReplyId(reply_id);
        confirm({
            title:"삭제 안내",
            content:"삭제한 글은 복구할 수 없습니다.",
            successBtnTitle:"삭제하기",
            successAction:()=>{
                replyDeleteSubmit({
                    reply_id:reply_id
                })
            }
        });
    };

    const replyAddCommentUpdate = (e)=>{
        const value = e.target.value;
        if(value.length > 300){
            // handlerShowToast({
            //     title:"글자수는 300자 이내로 작성해 주세요.",
            // })
            setState.setToast({
                title:"글자수는 300자 이내로 작성해 주세요."
            });
            return;
        }
        setAddComment(value);
    }

    useEffect(()=>{
        dataSubmit();
    },[]);

    useEffect(()=>{
        if(likeReplyId){
            handlerReplyLike(likeReplyId);
        }
    },[likeReplyId]);

    if(!comment){
        return (
            <></>
        )
    }
    console.log("comment => ", comment);
    console.log("data => ", data);

    return (
        <>
            <Header useMobile={false} />
            <SubNav 
                name={"답글" + (comment?.replies ? "("+comment?.replies+")" : "(0)" )}
                usePc={true} 
                useMobile={true} 
                usePcRightBtns={true}
                useBtnPrev={true}
                useBtnPcPrev={true}
                useBtnHome={true}
                useBtnContents={true}

                onContents={()=>{
                    const type = (comment?.type === "book") ? "novel" : "toon";
                    navigate("/content/"+type+"/"+comment?.book_id);
                }}

                setTop={'0px'} 
                setPcTop={'64px'} 
                />
            {/* <!-- container --> */}
            <div id="container" ref={contentRef}>
                <section className={"wrapper " + style['section']}>
                    <div className={"comment_list content_body " + style['comment_list']}>
                        <div className="list section">
                            {/* <!-- 댓글 --> */}
                            <div className={"comment_top " + style['comment_top']}>
                                <div className="left profile_box">
                                    <span className="profile radius-full">
                                        <img src={comment?.profile} />
                                    </span>
                                </div>
                                <div className={"right comment_box " + style['comment_box']}>
                                    <div className={"top_con " + style['top_con']}>
                                        <div className="badge_list">
                                            {comment?.badge?.best === true ? <p className="badge best_badge color-btn-01 bg border border-soild border-line radius-full">
                                                <span className="white-always txt">Best</span>
                                            </p> : null}
                                            {comment?.badge?.writer_choice === true ? <p className="badge love_badge color-state-point-01 bg border border-soild border-line radius-full">
                                                <span className="white-always txt">작가감동♥︎</span>
                                            </p> : null}
                                            {/* <!-- 내가 쓴 댓글일때 MY뱃지 --> */}
                                            {comment?.badge?.my === true ? <p className="badge my_badge border-line border-soild border color-btn-01 radius-full">
                                                <span className="color-btn-01 txt">MY</span>
                                            </p> : null}
                                        </div>
                                        <div className="name text_overflow title-01-b-long color-txt-normal">{comment?.nickname}</div>
                                        <div className="date title-sub-02-m color-txt-muted">{comment?.display_time}</div>
                                    </div>
                                    <div className="desc color-txt-02 body-03-long">
                                        <NewlineToBr text={(comment?.content ?? "")} />
                                    </div>
                                    <div className="info">
                                        <span className="title body-01-long color-txt-05 text_overflow">{comment?.book_title} {comment?.chapter_title}</span>
                                        <span className="body-01-long color-txt-05">- {comment?.chapter_no}화</span>
                                    </div>
                                    <div className="btns bottom_con">
                                        <div className="left">
                                            {/* <!-- 좋아요버튼 클릭시 className="click" 추가--> */}
                                            <button 
                                                type="button" 
                                                className={"button btn_03 medium outlined label_on round radius-full " + (comment?.like_status === true?"click":"")} 
                                                id="likeBtn"
                                                onClick={()=>{
                                                    setState.setToast({
                                                        title:"자신의 글에는 좋아요를 누를 수 없습니다."
                                                    });
                                                }}
                                                >
                                                <span className="btn-03 color-txt-04 text">{comment?.likes}</span>
                                            </button>
                                            {/* <!-- 답글 버튼 --> */}
                                            <button 
                                                type="button" 
                                                className={
                                                    comment?.reply_status === true?
                                                    "button btn_01 medium outlined label_on round radius-full after"
                                                    : "button btn_03 medium outlined label_on round radius-full before"
                                                }
                                                id="replyBtn">
                                                <span className="btn-03 color-txt-04 text">{comment?.replies === 0 ? "답글달기" : comment?.replies }</span>
                                            </button>
                                        </div>
                                        <div className="right">
                                            {/* <!-- 신고/차단버튼 (기본) --> */}
                                            {/* <!-- <button type="button" id="reportBtn"></button> --> */}
                                            {/* <!-- 내가 쓴 댓글일때 삭제버튼 --> */}
                                            {/* <button type="button" id="deleteBtn"  >
                                                <span className="body-01-long color-txt-05">삭제</span>
                                            </button> */}
                                            {comment?.delete_flag === true ?
                                                <button 
                                                    type="button" 
                                                    id="deleteBtn" 
                                                    data-comment-id={comment?.comment_id} 
                                                    onClick={commentDeleteSubmit}
                                                    >
                                                    <span className="body-01-long color-txt-05">삭제</span>
                                                </button>
                                            : 
                                                <>
                                                    {/* <!-- 신고/차단버튼 --> */}
                                                    <button type="button" id="reportBtn"></button>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- 답글 --> */}
                            {data && Array.isArray(data) && data.length === 0 ? <p className="no_data small color-txt-04 body-02-long">아직 등록된 답글이 없습니다. <br />첫 답글을 작성해 보세요.</p>
                            :
                                <ul className="reply_list">
                                    {data && Array.isArray(data) && data.length > 0 ?
                                        data.map((item, index)=>{

                                            if(item?.delete === true){
                                                return (
                                                    <li key={index}>
                                                        <p className="delete_list color-txt-muted body-03-long">작성자가 글을 삭제하였습니다.</p>
                                                    </li>
                                                )
                                            }

                                            return (
                                                <li 
                                                    key={index} 
                                                    className="border-soild border-top-line color-border-01"
                                                    style={{wordBreak: "break-all"}}
                                                    >
                                                    <div className="profile_box left">
                                                        <span className="profile radius-full">
                                                            <img src={item?.profile} />
                                                        </span>
                                                    </div>
                                                    <div className={"right comment_box " + style['comment_box']}>
                                                        <div className={"top_con " + style['top_con']}>
                                                            <div className="badge_list">
                                                                {/* <!-- 내가 쓴 덧글 일때 MY뱃지 --> */}
                                                                {item?.badge_my === true ? <p className="badge my_badge border-line border-soild border color-btn-01 radius-full">
                                                                    <span className="color-btn-01 txt">MY</span>
                                                                </p> : null}
                                                            </div>
                                                            <div className="name text_overflow title-01-b color-txt-normal">{item?.nickname}</div>
                                                            <div className="date title-sub-02-m color-txt-muted">{item?.display_time}</div>
                                                        </div>
                                                        <div className="desc color-txt-02 body-03-long">
                                                            <NewlineToBr text={item?.reject ? "차단한 사용자 입니다.":(item?.content ?? "")} />
                                                        </div>
                                                        <div className="btns bottom_con">
                                                            <div className="left">
                                                                {/* <!-- 좋아요버튼 클릭시 className="click" 추가--> */}
                                                                <button 
                                                                    type="button" 
                                                                    className={"button btn_03 medium outlined label_on round radius-full " + (item?.like_status === true?"click":"")} 
                                                                    id="likeBtn"
                                                                    data-my={item?.badge_my ? "true" : "false"}
                                                                    data-reply_id={item?.reply_id}
                                                                    onClick={(e)=>{
                                                                        const my = e.currentTarget.getAttribute("data-my");
                                                                        const reply_id = e.currentTarget.getAttribute("data-reply_id");

                                                                        if(my === "true"){
                                                                            setState.setToast({
                                                                                title:"자신의 글에는 좋아요를 누를 수 없습니다."
                                                                            });
                                                                        } else {
                                                                            // setState.setToast({
                                                                            //     title:"준비중입니다."
                                                                            // });
                                                                            setLikeReplyId(reply_id)
                                                                        }
                                                                    }}
                                                                    >
                                                                    <span className="btn-03 color-txt-04 text">{item?.likes}</span>
                                                                </button>
                                                            </div>
                                                            <div className="right">
                                                                {item?.delete_flag === true ?
                                                                    <button 
                                                                        type="button" 
                                                                        id="deleteBtn" 
                                                                        data-reply-id={item?.reply_id}
                                                                        onClick={replyDelSubmit}
                                                                        >
                                                                        <span className="body-01-long color-txt-05">삭제</span>
                                                                    </button>
                                                                : 
                                                                    <>
                                                                        {/* <!-- 신고/차단버튼 --> */}
                                                                        <button 
                                                                            type="button" 
                                                                            id="reportBtn"
                                                                            data-reply-id={item?.reply_id}
                                                                            data-reject={item?.reject ? "true":"false"}
                                                                            data-nickname={item?.nickname}
                                                                            onClick={(e)=>{
                                                                                const reply_id = e.currentTarget.getAttribute("data-reply-id");
                                                                                const reject = e.currentTarget.getAttribute("data-reject");
                                                                                const nickname = e.currentTarget.getAttribute("data-nickname");
                                                                                handlerRejectOrDeclare(reply_id, reject === "true", nickname);
                                                                            }}
                                                                            ></button>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        }
                                        )
                                    : null}
                                    
                                    {/* <li className="border-soild border-top-line color-border-01"> */}
                                        {/* <!-- 삭제한 답글 --> */}
                                        {/* <p className="delete_list color-txt-muted body-03-long">작성자가 글을 삭제하였습니다.</p> */}
                                    {/* </li> */}
                                </ul>
                            }
                            {/* <!-- 등록된 답글이 없을때 --> */}
                            
                        </div>
                        {paging?.is_next_page ? 
                            <div className={"list_more_btn " + style['list_more_btn']}>
                                <button 
                                    type="button" 
                                    className={"button btn_03 label_on medium outlined round radius-full " + style['button']}
                                    onClick={()=>{
                                        dataSubmit();
                                    }}
                                    ><span className="btn-03 color-txt-04">더보기</span></button>
                            </div>
                        : null}
                        
                        <div className="write_box">
                            <div className="left">
                                <textarea 
                                    placeholder="아름다운 언어로 소통해주세요. 이모지, 이모티콘은 등록 되지 않습니다." 
                                    className="body-03-long color-txt-02 radius-sm color-bg-02 color-border-01 border-line border-soild"
                                    value={addComment}
                                    onChange={replyAddCommentUpdate}
                                    ></textarea>
                            </div>
                            <div className="right">
                                <p className="num caption-02 color-txt-04">{addComment?.length ?? 0}</p>
                                <button 
                                    type="button" 
                                    className="button btn_01 medium filled label_on radius-full"
                                    onClick={replyWriteSubmit}
                                    ><span className="txt white-always btn-03">등록</span></button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {/* <!-- container --> */}

            <Footer />
            <ConfirmModal />
            <ConfirmModal2 />
            <AlertModal />
        </>
    );
}




export default MypageCommentReply;