import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";


export function getTodayDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1; // 월은 0부터 시작하므로 1을 더해줍니다.
    let day = today.getDate();

    // 한 자리 숫자인 경우 앞에 0을 붙여 두 자리로 만듭니다.
    if (month < 10) {
      month = '0' + month;
    }
    if (day < 10) {
      day = '0' + day;
    }

    const formattedDate = year + '.' + month + '.' + day;
    return formattedDate;
}

export function getAppsDataWithUserAgent(){
    const bookpalUserAgentData = navigator.userAgent.match(/\(bookpal;[^)]*\)/);

    //(company;token_value, gcm_token, os_type, os_version, device_id, device_type, app_store, app_version, audit_switch, dark_mode)
    if(!bookpalUserAgentData){
        const returnArray = {
            company:      null,
            token:        null,
            gcm_token:    null,
            os_type:      "web",
            os_version:   null,
            device_id:    null,
            device_type:  null,
            app_store:    null,
            app_version:  null,
            audit_switch: null,
            dark_mode:    null,
            last_social_login_type:    null,
            last_social_login_date:    null
        }
        return returnArray;
    }

    try {
        const tmpData = bookpalUserAgentData[0]?.replace(/\(|\)/g, '');
        const dataArray = tmpData?.split(';'); // 세미콜론으로 데이터 분리

        const returnArray = {
            company:      dataArray[0],
            token:        dataArray[1],
            gcm_token:    dataArray[2],
            os_type:      dataArray[3],
            os_version:   dataArray[4],
            device_id:    dataArray[5],
            device_type:  dataArray[6],
            app_store:    dataArray[7],
            app_version:  dataArray[8],
            audit_switch: dataArray[9],
            dark_mode:    dataArray[10],
            last_social_login_type:   dataArray[11],
            last_social_login_date:   dataArray[12],
        }

        return returnArray;
    } catch(e){
        return null;
    }
}


export function getTokenWithUserAgent(userAgent){
    try{
        const bookpalUserAgentData = userAgent.match(/\(bookpal;[^)]*\)/);
        const tmpData = bookpalUserAgentData[0]?.replace(/\(|\)/g, '');
        const dataArray = tmpData?.split(';'); // 세미콜론으로 데이터 분리
        
        return dataArray[1];
    } catch(e){
        return false;
    }
}

export function getLastSocialLoginTypeWithUserAgent(userAgent){
    try{
        const bookpalUserAgentData = userAgent.match(/\(bookpal;[^)]*\)/);
        const tmpData = bookpalUserAgentData[0]?.replace(/\(|\)/g, '');
        const dataArray = tmpData?.split(';'); // 세미콜론으로 데이터 분리
        
        return dataArray[11];
    } catch(e){
        return false;
    }
}

export function getLastSocialLoginDateWithUserAgent(userAgent){
    try{
        const bookpalUserAgentData = userAgent.match(/\(bookpal;[^)]*\)/);
        const tmpData = bookpalUserAgentData[0]?.replace(/\(|\)/g, '');
        const dataArray = tmpData?.split(';'); // 세미콜론으로 데이터 분리
        
        return dataArray[12];
    } catch(e){
        return false;
    }
}

export const runPopupDeclare = (data)=>{

    const type = data?.type ?? 0;
    const chapter_id = data?.chapter_id ?? "";
    const reply_id = data?.reply_id ?? "";
    const comment_reply_id = data?.comment_reply_id ?? "";

    // if(e){
    //     e.preventDefault(); 
    // }
    var popupWidth = 420;
    var popupHeight = 640;
    var popupX = (window.innerWidth - popupWidth) / 2;
    var popupY = (window.innerHeight - popupHeight) / 2;
    var url = "/popup/declare?type=" + type + "&chapter_id=" + chapter_id + "&reply_id=" + reply_id + "&comment_reply_id=" + comment_reply_id;
    var popupX = (window.screenX + (window.innerWidth - popupWidth) / 2);
    var popupY = (window.screenY + (window.innerHeight - popupHeight) / 2);
    window.open(url, '', 'status=no, location=no, height=' + popupHeight + ', width=' + popupWidth + ', left=' + popupX + ', top=' + popupY);
}

export const runPopupOrder = (e)=>{
    if(e){
        e.preventDefault(); 
    }
    var popupWidth = 420;
    var popupHeight = 640;
    var popupX = (window.innerWidth - popupWidth) / 2;
    var popupY = (window.innerHeight - popupHeight) / 2;
    var url = "/order";
    var popupX = (window.screenX + (window.innerWidth - popupWidth) / 2);
    var popupY = (window.screenY + (window.innerHeight - popupHeight) / 2);
    window.open(url, '', 'status=no, location=no, height=' + popupHeight + ', width=' + popupWidth + ', left=' + popupX + ', top=' + popupY);
}

export const runPopupBookpalBankOrder = (prod_code, price)=>{
    // e.preventDefault(); 
    var popupWidth = 420;
    var popupHeight = 640;
    var popupX = (window.innerWidth - popupWidth) / 2;
    var popupY = (window.innerHeight - popupHeight) / 2;
    var url = "/order_bookpal?prod_code="+prod_code+"&price="+price;
    var popupX = (window.screenX + (window.innerWidth - popupWidth) / 2);
    var popupY = (window.screenY + (window.innerHeight - popupHeight) / 2);
    window.open(url, '', 'status=no, location=no, height=' + popupHeight + ', width=' + popupWidth + ', left=' + popupX + ', top=' + popupY);
}

export const runPopupBookpalBankOrderInfo = ()=>{
    // e.preventDefault(); 
    var popupWidth = 420;
    var popupHeight = 440;
    var popupX = (window.innerWidth - popupWidth) / 2;
    var popupY = (window.innerHeight - popupHeight) / 2;
    var url = "/order_bookpal_info";
    var popupX = (window.screenX + (window.innerWidth - popupWidth) / 2);
    var popupY = (window.screenY + (window.innerHeight - popupHeight) / 2);
    window.open(url, '', 'status=no, location=no, height=' + popupHeight + ', width=' + popupWidth + ', left=' + popupX + ', top=' + popupY);
}

export const runPopupVBankOrderInfo = ()=>{
    // e.preventDefault(); 
    var popupWidth = 420;
    var popupHeight = 640;
    var popupX = (window.innerWidth - popupWidth) / 2;
    var popupY = (window.innerHeight - popupHeight) / 2;
    var url = "/order_vbank_info";
    var popupX = (window.screenX + (window.innerWidth - popupWidth) / 2);
    var popupY = (window.screenY + (window.innerHeight - popupHeight) / 2);
    window.open(url, '', 'status=no, location=no, height=' + popupHeight + ', width=' + popupWidth + ', left=' + popupX + ', top=' + popupY);
}


export function useLocationName(){
    const location = useLocation();

    const map = {
        "/":"main",
        "/search":"search",
        "/novel":"novel",
        "/toon":"toon",
        "/signup":"signup",
        "/storage":"storage",
        "/giftbox":"giftbox",
        "/detail":"detail",
        "/content":"content",
        "":"none",
    }

    // console.log(location.pathname.split("/"));
    return map["/"+location.pathname.split("/")[1]] ?? "";
}

export function useNoneMobileHeaderList(page){
    const noneUsePageList = [
        'search',
        'detail',
        'content'
    ];
    return noneUsePageList.indexOf(page) !== -1;
}

export function useIsMobile(){
    const [isMobile, setIsMobile] = useState((window.innerWidth <= 999) ? true:false);
    const [isAdd, setIsAdd] = useState(false);
    
    const handleWindowResize = () => {
        const screenWidth = window.innerWidth;
      
        if (screenWidth <= 999) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };

    if(isAdd === false){
        window.addEventListener('resize', handleWindowResize);
        setIsAdd(true);
    }

    return isMobile;
}

export function useWidth(){
    const [width, setWidth] = useState(window.innerWidth);
    // const [isAdd, setIsAdd] = useState(false);
    
    const handleWindowResize = () => {
        const screenWidth = window.innerWidth;
        setWidth(screenWidth);
    };

    // if(isAdd === false){
    //     window.addEventListener('resize', handleWindowResize);
    //     setIsAdd(true);
    // }

    useEffect(()=>{
        window.addEventListener('resize', handleWindowResize);

        return ()=>{
            window.removeEventListener('resize', handleWindowResize);
        }
    },[])

    return width;
}

export function getViewCntConvert(cnt){
    if (cnt >= 10000) {
        return (cnt / 10000).toFixed(1) + '만';
    } else if (cnt >= 1000) {
        return (cnt / 1000).toFixed(1) + '천';
    } else {
        try {
            return cnt.toString();
        }catch(e){
            return "0";
        }
    }
}


export function formatDateString(dateTimeString) {
    const date = new Date(dateTimeString);
  
    if (isNaN(date)) {
      return null;
    }
  
    const year = String(date.getFullYear()).slice(-2);
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더합니다.
    const day = String(date.getDate()).padStart(2, '0');
  
    return `${year}.${month}.${day}`;
}


export function getFormatBytes(input) {
    
    if (typeof input === 'string') {
        input = parseFloat(input);
    }

    if (isNaN(input) || typeof input !== 'number') {
        return null;
    }

    // const units = ['B', 'KB', 'MB', 'GB'];
    // let unitIndex = 0;
    
    // while (bytes >= 1024 && unitIndex < units.length - 1) {
    //     bytes /= 1024;
    //     unitIndex++;
    // }

    const units = ['MB', 'GB'];
    let bytes = input / 1048576; // bytes to MB
    let unitIndex = 0;

    // 0.1 MB보다 작으면 0.1 MB로 고정
    if (bytes < 0.1) {
        bytes = 0.1;
    } else {
        // 1024 MB 이상인 경우 GB로 변환
        while (bytes >= 1024 && unitIndex < units.length - 1) {
            bytes /= 1024;
            unitIndex++;
        }
    }
    
    return `${bytes.toFixed(1)} ${units[unitIndex]}`;
}

export function getBookStateConvert(is_rest, is_finished){
    if(is_rest){
        return "휴재";
    }
    if(is_finished){
        return "완결";
    }
    return "연재";
}

export function getCurrentMonth(){
    // 현재 날짜를 가져옵니다.
    const now = new Date();

    // 현재 월을 가져옵니다. (0-11)
    const currentMonth = now.getMonth();

    // 사용자 친화적인 형식으로 월을 출력하려면 +1을 합니다.
    const displayMonth = currentMonth + 1;

    return displayMonth;
}

export function getCurrentDay(){
    const now = new Date();

    // 현재 연도, 월, 날짜를 각각 가져옵니다.
    // const year = now.getFullYear();
    // const month = now.getMonth() + 1; // getMonth()는 0부터 시작하므로 +1을 해야 실제 월이 됩니다.
    const date = now.getDate();
    return date;
}

export function getNextPaymentDate(paymentDay) {
    // 현재 날짜를 가져옵니다.
    const currentDate = new Date();
    
    // 현재 연도와 월을 가져옵니다.
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    
    // 다음 달의 첫 번째 날짜를 생성합니다.
    const nextMonth = new Date(currentYear, currentMonth + 1, 1);
    
    // 다음 달의 마지막 날짜를 구합니다.
    const lastDayOfNextMonth = new Date(currentYear, currentMonth + 2, 0).getDate();
    
    // 결제 예정일을 다음 달의 해당 일자로 설정합니다.
    let nextPaymentDate;
    if (paymentDay > lastDayOfNextMonth) {
        // 다음 달에 해당 일자가 존재하지 않으면, 다음 달의 마지막 날짜를 사용합니다.
        nextPaymentDate = new Date(currentYear, currentMonth + 1, lastDayOfNextMonth, 1, 30, 0);
    } else {
        // 다음 달의 해당 일자를 설정합니다.
        nextPaymentDate = new Date(currentYear, currentMonth + 1, paymentDay, 1, 30, 0);
    }
    
    // 포맷팅하여 반환합니다. ("YYYY. MM. DD HH:mm:ss" 형식)
    const formattedDate = nextPaymentDate.toLocaleString('ko-KR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        // hour: '2-digit',
        // minute: '2-digit',
        // second: '2-digit'
    }).replace(/\. /g, '. ').replace(/\. /g, '. ');

    return formattedDate;
}

export function formatNumber(number) {
    if (typeof number === 'string') {
      number = Number(number);
    }
    if (isNaN(number)) {
      return '';
    }
    return number.toLocaleString('en-US');
  }