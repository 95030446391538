import { ApiAuthSocialLoginUrl } from "api/Auth";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { actionSetLastSocialLoginTryType } from "action/index";
import { SetState } from "utils/State";
import { useLocation } from "react-router-dom";


function MypageSnsLogin(){

    const dispatch = useDispatch();
    const location = useLocation();
    const setState = SetState();

    const queryParams = new URLSearchParams(location.search);

    const socialType = queryParams.get("sns_type");

    const handlerSocialLoginUrl = async (social_type) => {
        dispatch(actionSetLastSocialLoginTryType(social_type));
        
        await socialLoginGetUrl({
            type: social_type,
            return_url: process.env.REACT_APP_BASE_URL + "/login",
        });
        
    };

    const socialLoginGetUrl = ApiAuthSocialLoginUrl({
        onSuccess: (data) => {
            console.log(data);
            // console.log(appReturnLink);
            // return;
            if(data?.status === true){
                // if(isApp()){
                //     if(appReturnLink){
                //         dispatch(actionSetLastSocialLoginTryPath(appReturnLink));
                //     }
                // }
                setState.setLastSocialLoginTryPath("/mypage/edit");
                // dispatch(actionSetLastSocialLoginTryPath("/mypage/edit"));
                setTimeout(()=>{
                    window.location.href = data?.result?.login_url;
                },100);
                
            } else {
                alert("시스템 문제가 발생하였습니다. 문제가 지속되면 관리자에게 문의주세요.");
            }
        },
        onError: (e) => {
            console.log(e);
            alert("통신이 실패하였습니다. 문제가 지속되면 관리자에게 문의주세요.");
        },
    });

    useEffect(()=>{
        if(socialType){
            if(socialType === "kakao" || socialType === "apple"){
                handlerSocialLoginUrl(socialType);
            }
        }
    },[socialType])

    return (
        <></>
    );
}



export default MypageSnsLogin;