import { useState } from 'react';
import { Modal } from 'react-bootstrap';

function useSelectModal(data, selectData, selectFn, noneSelect){

    const options = data || {};
    const noneActive = noneSelect || false;
    const [selectKey, setSelectKey] = useState(selectData);

    const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const keyData = selectKey;

    const handleSelect = (key) => {
        if(!noneActive) setSelectKey(key);
        if(selectFn) selectFn(key);
        handleClose();
    }

    const initSelect = ()=>{
        setSelectKey(selectData);
    }

    const optionElements = [];
    Object.keys(options).forEach(key => {
        // console.log(key);
        // const opkey = key;
        const value = options[key];
        optionElements.push(
            <p 
                key={key} 
                data-key={key}
                className={"radius-sm color-bg-modal color-txt-normal body-02 " + (key == keyData ? "click":"")}
                style={{padding:"0 16px"}}
                onClick={(obj)=>{
                    // e.preventDefault()
                    console.log(obj);
                    const targetkey = obj.target.attributes['data-key'].value;
                    console.log(targetkey);
                    handleSelect(targetkey);
                }}
            >
                {value}
            </p>
        );
    });


    const ModalTmpl =()=>{ 
        return (
        <>
            <Modal 
                // className="select_modal modal black_opacity-60" 
                className="select_modal modal" 
                backdropClassName="black_opacity-60 opacity-10"
                dialogClassName="modal-dialog-centered"
                contentClassName="radius-xxl color-bg-modal"
                show={showModal}
                animation={false} 
                onHide={handleClose}
                >
                <Modal.Body>
                    <div className="scroll_box select_box">
                    {optionElements}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="radius-xs btn-03 txt btn_03 medium label_on filled button" onClick={handleClose}>취소</button> 
                </Modal.Footer>
            </Modal>
        </>
    )};
    
    return [ModalTmpl, handleShow, selectKey, initSelect];
}


export default useSelectModal;