import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { GetState } from 'utils/State';
import { useSelector  } from 'react-redux';
import { setStorageData } from 'utils/Storage';

const apiBaseUrl = process.env.REACT_APP_API_URL; // API URL 예시

export const useApiMutation = (apiSubUrl, successFn, errorFn) => {

    const apiUrl = apiBaseUrl + apiSubUrl;

    const getState = GetState();
    const tokens = getState.useGetTokens();
    const adultSwitch = getState.useGetAdultSwitch();
    // const tokens = useSelector((state) => state.data.tokens);
    
    const fetchWithTimeout = (url, options, timeout = 30000) => {
        return Promise.race([
            fetch(url, options),
            new Promise((_, reject) =>
                setTimeout(() => reject(new Error('Request timed out')), timeout)
            ),
        ]);
    };

    const mutation = useMutation({
        mutationFn: async (requestData) => {
            let headers_data = {};
            headers_data['Content-Type'] = 'application/json';
            if(tokens && tokens?.token){
                headers_data['Authorization'] = 'Bearer ' + tokens['token'];
                if(adultSwitch === true){
                    headers_data['x-audit-switch'] = "on";
                } else {
                    headers_data['x-audit-switch'] = "off";
                }
            } else {
                headers_data['x-audit-switch'] = "off";
            }

            // console.log(apiUrl);

            try{
                const response = await fetchWithTimeout(apiUrl, {
                    method: 'POST',
                    headers: headers_data,
                    body: JSON.stringify(requestData),
                });
                
                const data = await response.json();

                if(!apiUrl.endsWith("/auth/info") && !apiUrl.endsWith("/common/dot")){
                    if(data?.status === false && data?.code === "310"){
                        setStorageData("checkup_time",data?.time_txt);
                        document.location.href = "/checkup";
                    }
                }

                if(document?.location?.href?.endsWith("/checkup")){
                    if(apiUrl.endsWith("/auth/info") || apiUrl.endsWith("/common/dot")){
                        if(data?.status !== false || data?.code !== "310"){
                            document.location.href = "/";
                        }
                    }
                    if(data?.status === false && data?.code === "310"){
                        setStorageData("checkup_time",data?.time_txt);
                    }
                }
                
                return data;
            }
            catch(e){
                return {
                    code:'local_error',
                    status:false,
                    result:false,
                    message:"",
                };
            }
        },
        onSuccess: successFn,
        onError: errorFn,
        retry: 3, // 실패 시 재시도 횟수
        retryDelay: 1000, // 재시도 딜레이 (ms)
    });

    const mutateAsync = async (data) => {
        await mutation.mutateAsync(data);
    };

    return mutateAsync;
};

export const useApiMutationFormData = (apiSubUrl, successFn, errorFn) => {

    const apiUrl = apiBaseUrl + apiSubUrl;

    const getState = GetState();
    const tokens = getState.useGetTokens();
    // const tokens = useSelector((state) => state.data.tokens);

    const mutation = useMutation({
        mutationFn: async (formData) => {
            let headers_data = {};
            // headers_data['Content-Type'] = 'multipart/form-data';
            if(tokens && tokens?.token){
                headers_data['Authorization'] = 'Bearer ' + tokens['token'];
            }

            try{
                const response = await fetch(apiUrl, {
                    method: 'POST',
                    headers: headers_data,
                    body: formData,
                });
                
                const data = await response.json();
                return data;
            }
            catch(e){
                return {
                    code:'local_error',
                    status:false,
                    result:false,
                    message:"",
                };
            }
        },
        onSuccess: successFn,
        onError: errorFn,
        retry: 0, // 실패 시 재시도 횟수
        retryDelay: 1000, // 재시도 딜레이 (ms)
    });

    const mutateAsync = async (data) => {
        await mutation.mutateAsync(data);
    };

    return mutateAsync;
};

export const useApiMutationPaging = (apiSubUrl, requestSendData, successFn, errorFn, bottomFn, perPage, pageName, perPageName, bottomRef, $pageLimit) => {
    const _pageName = pageName ?? "page";
    const _perPageName = perPageName ?? "per_page";
    const _perPage = perPage ?? 15;
    const _bottomRef = bottomRef;
    const _requestSendData = requestSendData;
    const _pageLimit = $pageLimit;
    // const _isFn = isFn || (()=>{return true})
    // const [useFetch, setUseFetch] = useState(false);
    const [fetch, setFetch] = useState(false);
    const [data, setData] = useState(null);
    const [paging, setPaging] = useState(null);
    const [page, setPage] = useState(0);
    const [nextPage, setNextPage] = useState(true);
    
    const successAfterFn = (responseData) => {
        
        if(responseData?.status === true){
            // console.log(responseData?.result)
            setData([
                ...(data ?? []),
                ...(responseData?.result?.data ?? [])
            ]);
            setPage(responseData?.result?.paging?.current_page);
            setNextPage(responseData?.result?.paging?.is_next_page);
            setPaging(responseData?.result?.paging);
            // window.scrollTo(0, document.body.scrollHeight - 100);  
        }
        
        setFetch(false);
        // console.log("setFatch1 false");
        successFn(responseData);
    }
    const errorAfterFn = (e) => {
        setFetch(false);
        // console.log("setFatch2 false");
        errorFn(e);
    }
    const mutation = useApiMutation(apiSubUrl, successAfterFn, errorAfterFn);
    const pagingMutation = async (requestData) => {
        const pagingData = {
            ...(requestData ?? {}),
        }
        await mutation(pagingData);
    };
    useEffect(()=>{
        // console.log("fetch", fetch)
        // console.log("nextPage", nextPage)
        if(fetch && nextPage){
            // console.log("useEffect => fetch && nextPage");
            const _page = parseInt(page) + 1;
            pagingMutation({
                ...(_requestSendData ?? {}),
                [_pageName]:_page,
                [_perPageName]:_perPage
            });
        }
    },[fetch, nextPage]);

    useEffect(()=>{
        const handleScroll = () => {
            if (_bottomRef.current) {
                const scrollY = window.scrollY;
                const innerHeight = window.innerHeight;
                const { offsetTop, clientHeight } = _bottomRef.current;
    
                const scrollBottom = scrollY + innerHeight;
                const contentBottom = offsetTop + clientHeight;
    
                // console.log(scrollBottom, contentBottom, scrollBottom >= contentBottom, fetch);
                if (scrollBottom >= contentBottom) {
                    // console.log(1);
                    // setFetch(true);
                    // if(useFetch){
                        if(_pageLimit){
                            // console.log(2);
                            if(paging?.current_page < _pageLimit){
                                console.log(3);
                                loadDataSubmit();
                                if(bottomFn) bottomFn();
                            }
                            return;
                        } 
        //                 console.log(4);
        //                 console.log("fetch", fetch)
        // console.log("nextPage", nextPage)
                        loadDataSubmit();
                        if(bottomFn) bottomFn();
                    // }
                }
            }
        }
        // console.log("data", data)
        if(data !== null){
            // console.log("test_scroll")
            window.addEventListener('scroll', handleScroll);
        }
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    },[data]);

    const loadDataSubmit = () => {
        // console.log("_isFn",_isFn())
        // if(fetch === false){
            setFetch(fetch + 1);
        // }
    }

    const initData = () => {
        // console.log("initData")
        setData(null);
        setFetch(false);
        setPage(0);
        setPaging(null);
        setNextPage(true);
    }

    return [
        data,
        loadDataSubmit,
        initData,
        paging,
        setData,
        fetch
        // setUseFetch
    ];
}

export const useApiMutationCommentReplyPaging = (apiSubUrl, requestSendData, successFn, errorFn, bottomFn, perPage, pageName, perPageName, bottomRef) => {
    const _pageName = pageName ?? "page";
    const _perPageName = perPageName ?? "per_page";
    const _perPage = perPage ?? 15;
    const _bottomRef = bottomRef;
    const _requestSendData = requestSendData;
    const [fetch, setFetch] = useState(false);
    const [comment, setComment] = useState(null);
    const [data, setData] = useState(null);
    const [paging, setPaging] = useState(null);
    const [page, setPage] = useState(0);
    const [nextPage, setNextPage] = useState(true);
    
    const successAfterFn = (responseData) => {
        
        if(responseData?.status === true){
            
            setData([
                ...(data ?? []),
                ...(responseData?.result?.reply?.data ?? [])
            ]);
            setComment(responseData?.result?.comment);
            setPage(responseData?.result?.reply?.paging?.current_page);
            setNextPage(responseData?.result?.reply?.paging?.is_next_page);
            setPaging(responseData?.result?.reply?.paging);
        }
        setFetch(false);
        successFn(responseData);
    }
    const errorAfterFn = (e) => {
        setFetch(false);
        errorFn(e);
    }
    const mutation = useApiMutation(apiSubUrl, successAfterFn, errorAfterFn);
    const pagingMutation = async (requestData) => {
        const pagingData = {
            ...(requestData ?? {}),
        }
        await mutation(pagingData);
    };
    useEffect(()=>{
        if(fetch && nextPage){
            // console.log("useEffect => fetch && nextPage");
            const _page = parseInt(page) + 1;
            pagingMutation({
                ...(_requestSendData ?? {}),
                [_pageName]:_page,
                [_perPageName]:_perPage
            })
        }
    },[fetch]);

    useEffect(()=>{
        const handleScroll = () => {
            if (_bottomRef.current) {
                const scrollY = window.scrollY;
                const innerHeight = window.innerHeight;
                const { offsetTop, clientHeight } = _bottomRef.current;
    
                const scrollBottom = scrollY + innerHeight;
                const contentBottom = offsetTop + clientHeight;
    
                // console.log(scrollBottom +" "+ contentBottom);
                // console.log(scrollBottom >= contentBottom);
                if (scrollBottom >= contentBottom) {
                    // setFetch(true);
                    loadDataSubmit();
                    if(bottomFn) bottomFn();
                }
            }
        }
        window.addEventListener('scroll', handleScroll);
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    },[]);

    const loadDataSubmit = () => {
        setFetch(fetch + 1);
    }

    const initData = () => {
        setComment(null);
        setData(null);
        setFetch(false);
        setPage(0);
        setNextPage(true);
    }

    return [
        comment,
        data,
        loadDataSubmit,
        initData,
        paging,
        setData,
        setComment
    ];
}