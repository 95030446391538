import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMemberInfo } from 'utils/State';
import { ImgDefault, ImgCover } from 'utils/Images';
import { GetState } from 'utils/State';
import { useEffect, useState } from 'react';



function BookpalLazyLoadImage(props){

    const memberInfo = useMemberInfo();
    // const getState = GetState();
    const adult = props?.adult ?? false;
    const adultswitch = props?.adultswitch ?? false;
    
    const defaulttype = props?.defaulttype ?? "cover_logo";
    const locktype = props?.locktype ?? "cover_lock";
    const defaultImg = (ImgDefault(defaulttype) ?? ImgDefault("cover_logo"));
    const background = props?.background ?? "#fff";
    const style = {...(props?.style ?? {}), backgroundColor:background};
    const className = props?.className || "";
    // console.log(style);
    // const style = props?.style ?? {};
    let src = (null);


    
    // useEffect(()=>{
        if(adult){
            switch(memberInfo?.view_type){
                case "Y":
                    if(!adultswitch){
                        src = (ImgDefault(locktype) ?? ImgDefault("cover_lock"));
                    } else {
                        src = (props?.src);
                    }
                    break;
                case "D":
                case "C":
                case "N":
                default:
                    src = (ImgDefault(locktype) ?? ImgDefault("cover_lock"));
                    break;
            }
        } else {
            src = (props?.src);
        }
    // },[]);
    // placeholderSrc={ImgDefault("cover_logo")}
    //                                             // effect="blur"
    //                                             src={ImgCover(memberInfo?.view_type, item?.adult, ImgDefault("cover_lock"), item?.cover)}
    //                                             style={defaultImageStyle}
    //                                             onError={(e)=>{
    //                                                 e.currentTarget.src = ImgDefault("cover_logo");
    //                                             }}

    // if(src === null){
    //     return <LazyLoadImage 
    //         {...props} 
    //         placeholderSrc={defaultImg}
    //         src={defaultImg} 
    //         />;
    // }
    
    // return (
    //     <>
    //         <img 
    //             {...props} 
    //             adult={""}
    //             placeholderSrc={defaultImg}
    //             src={src} 
    //             // style={style}
    //             onError={(e)=>{
    //                 e.currentTarget.src = defaultImg;
    //             }}
    //             style={style}
    //             className={className}
    //         />
    //     </>
    // )

    return (
        <LazyLoadImage 
            {...props} 
            adult={""}
            placeholderSrc={defaultImg}
            src={src} 
            // style={style}
            onError={(e)=>{
                e.currentTarget.src = defaultImg;
            }}
            style={style}
            className={className}
            
            wrapperProps={{style:style, className:className}}
            />
    )
}



export default BookpalLazyLoadImage;