import style from '../style/Promotion.module.scss';
import img_promotion_event from 'assets/images/promotion/event.png';
import img_promotion_download from 'assets/images/promotion/download.svg';
import BookpalLink from 'component/common/custom/BookpalLink';


function Tmpl7({data}){


    return (
        
        <div className={"event_header " + style['event_header']}>

            {/* <!-- 이벤트 배너--> */}
            {/* <p className={"images"}><img src={img_promotion_event} /></p> */}
            <BookpalLink to={data?.item?.LINK_URL} style={{cursor:(data?.item?.LINK_URL === ""?"auto":"pointer")}}>
                <p className={"images"}><img src={data?.item?.BANNER} /></p>
            </BookpalLink>
        </div>
    )
}



export default Tmpl7;