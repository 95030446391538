import OrderSuccess from "./order_result/OrderSuccess";
import OrderFail from "./order_result/OrderFail";
import OrderVBank from "./order_result/OrderVBank";


function OrderResult({success, oid, msg}){



    if(success === "true"){

        if(oid.startsWith("INPV") || oid.startsWith("INMV")){
            return (
                <OrderVBank
                    oid={oid}
                    msg={msg}
                />
            )
        }

        return (
            <OrderSuccess 
                oid={oid}
                msg={msg}
            />
        )
    }

    if(success === "false"){
        return (
            <OrderFail 
                msg={msg}
            />
        )
    }


    return null;
}





export default OrderResult;