import { useEffect, useRef, useState } from 'react';
import { ApiSearchWork, ApiSearchFavKeyword } from 'api/Search';
import { setKeywordData, getKeywordData, delKeywordData, initKeywordData } from 'utils/Storage';
import { SetState, GetState, useMemberInfo } from 'utils/State';

import Header from 'component/common/header/Header';
import MobileNav from 'component/common/nav/MobileNav';

import Footer from 'component/common/footer/Footer';
import TopBtn from 'component/common/button/top/TopBtn';

import SubNav from 'component/common/nav/SubNav';

// import './search.scss';
import style from './Search.module.scss';

import SearchKeyword from 'component/common/section/SearchKeyword';

import WorkTmpl from 'component/common/template/work/WorkTmpl';
import NoneTmpl from 'component/common/template/none/NoneTmpl';
import NoneSearchKeyword from 'component/common/template/none/NoneSearchKeyword';
import PopularKeywordTmpl from 'component/common/template/search/PopularKeywordTmpl';

import SearchKeywordModal from './component/SearchKeywordModal';
import { Link, useLocation, useParams } from 'react-router-dom';
import BookpalLink from 'component/common/custom/BookpalLink';


import img_search_white from 'assets/images/search/search_white.svg';
import img_hash_white from 'assets/images/search/hash_white.svg';


const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

function Search(){

    const contentRef = useRef(null);

    const query = useQuery();

    const params = useLocation().state;



    const setState = SetState();
    const getState = GetState();
    const memberInfo = useMemberInfo();

    const isTooltip = getState.useGetTooltipSearch();

    const no_adult = query.get("no_adult");

    const [searchText, setSearchText] = useState(query.get("txt") ?? "");
    const [tab, setTab] = useState("all");
    const [arrTab, setArrTab] = useState(null);
    const [end, setEnd] = useState(false);
    const [keyword, setKeyword] = useState(params?.keyword ?? query.get("keyword") ?? null);

    // console.log(params);

    const [keywordList, setKeywordList] = useState([]);
    const [favKeyword, setFavKeyword] = useState([]);

    const saveMode = getState.useGetKeywordSaveMode() === false ? false : true;

    // const [keywordTab, setKeywordTab] = useState(null);

    const favKeywordSubmit = ApiSearchFavKeyword({
        onSuccess:(data)=>{
            if(data?.status === true){
                setFavKeyword(data?.result);
            }
        }
    })

    const [data, dataSubmit, initData, paging, setData, isLoading] = ApiSearchWork({
        bottomRef:contentRef,
        data:{
            title_writer: !!keyword ? null : searchText,
            category:arrTab,
            end:(end ? true : null),
            keyword:keyword,
            adult:(no_adult === "1" ? ['15']: null),
        },
        onError:()=>{
            dataSubmit();
        }
    });

    const saveModeToggle = ()=>{
        // console.log(saveMode)
        setState.setKeywordSaveMode(!saveMode);
    }

    const getKeywordSubmit = ()=>{
        setKeywordList(getKeywordData());
    }

    const searchTextSubmit = (searchText)=>{
        if(searchText?.trim() !== ""){
            setTab("all");
            setArrTab(null);
            setEnd(false);
            setKeyword(null);
            initData();
            dataSubmit();
            if(saveMode){
                setKeywordData(searchText?.trim());
            }
            getKeywordSubmit();
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === 'NumpadEnter') {

            if(searchText === ""){
                initSearch();
                return;
            }

            searchTextSubmit(searchText);
            e.target.blur();
        } else  {
            
        }
    };

    const initKeyword = () => {
        if(keyword !== null){
            setSearchText("");
            setTab("all");
            setArrTab(null);
            setEnd(false);
            setKeyword(null);
            initData();
        }
    }


    const initSearch = () => {
        setSearchText("");
        setTab("all");
        setArrTab(null);
        setEnd(false);
        setKeyword(null);
        initData();
    }

    

    const [showSearchKeywordModal, setShowSearchKeywordModal] = useState(false);

    const handlerToggleKeywordModal = () =>{
        setState.setTooltipSearch(true);
        setShowSearchKeywordModal(!showSearchKeywordModal);
    }

    

    useEffect(()=>{
        // console.log("end, arrtab")
        // console.log(searchText, keyword);
        // if(data && Array.isArray(data) && data?.length === 0){
        //     return;
        // }
        // console.log("searchText",searchText);
        // console.log("keyword",keyword);
        if(searchText !== "" || keyword !== null){
            console.log("init");
            initData();
            dataSubmit();
        }
    },[end,arrTab])

    useEffect(()=>{
        if(keyword !== null){
            setTab("all");
            setArrTab(null);
            setEnd(false);
            initData();
            dataSubmit();
        }
    },[keyword])
    
    useEffect(()=>{
        // console.log(tab);
        if(tab !== null){
            switch(tab){
                default:
                case "all":
                    setArrTab(null);
                    break;
                case "novel":
                    setArrTab(["novel"]);
                    break;
                case "toon":
                    setArrTab(["toon","comic"]);
                    break;
                case "epub":
                    setArrTab(["epub"]);
                    break;
            }
            // setEnd(false);
        }
    },[tab]);

    useEffect(()=>{
        getKeywordSubmit();
        favKeywordSubmit();
    },[])

// console.log(data, isLoading);
    return (
        <>
            <Header useMobile={false} />
            <MobileNav />
            {/* <!-- 서브메뉴 --> */}
            <SubNav 
                name="검색" 
                usePc={true} 
                useHome={true} 
                useMobile={true} 
                useBtnPrev={true}
                useBtnHome={true}
                setTop={'0px'} 
                setPcTop={'64px'}  
                />

            {/* <!-- //서브메뉴 --> */}
            <div id="container" className={"sub_wrap wrap " + style['container']} >
                <section className={"section search_box wrapper " + style['search_box']}>
                    {/* <!-- 키워드검색시에서 keyword_box 추가 --> */}
                    <div className={"area_box " + (keyword ? style['keyword_box'] : style['area_box'])}>
                        <div className={"search_text color-bg-02 radius-full input_box " + style['search_text'] + " " + style['input_box']}>
                            <div className={"text_input " + style['text_input']}>
                                <input 
                                    type="text" 
                                    className={"text_box body-03 color-txt-normal " + style['text_box']} 
                                    placeholder="검색어를 입력하세요." 
                                    value={searchText}
                                    onChange={(e)=>{setSearchText(e.target.value ?? "")}}
                                    onKeyDown={(e)=>handleKeyDown(e)}
                                    />
                                <p className={"right_btns " + style['right_btns']}>
                                    <span 
                                        className="btnClear"
                                        style={{display:(searchText === "" ? "none":"inline-block")}}
                                        onClick={()=>{
                                            initSearch();
                                        }}
                                        ></span>
                                </p>
                            </div>
                            <button type="button" className="radius-full color-bg-01 color-btn-01 border border-line border-soild">
                                <span className="color-btn-01 txt btn-03">{keyword}</span>
                                <span 
                                    className={"close_btn " + style['close_btn']}
                                    onClick={()=>{
                                        initKeyword();
                                    }}
                                    ></span>
                            </button>
                        </div>
                        <div className={"right_button " + style['right_button']}>
                            <button 
                                type="button" 
                                className={"button color-btn-01 bg radius-full search_button " + style['search_button']}
                                onClick={(e)=>{
                                    searchTextSubmit(searchText);
                                    e.target.blur();
                                }}
                                >
                                <img src={img_search_white} />
                            </button>
                            <button 
                                type="button" 
                                className={"button color-btn-01 bg radius-full keyword_btn " + style['keyword_btn']}
                                onClick={handlerToggleKeywordModal} 
                                >
                                <img src={img_hash_white} />
                            </button>
                        </div>
                        {/* <button 
                            type="button" 
                            onClick={handlerToggleKeywordModal} 
                            className={"button btn_05 large label_off round on radius-full keyword_btn " + style['button'] + " " + style['large'] + " " + style['keyword_btn'] + " " + (keyword ? "active":"")}>
                            <span></span>
                        </button> */}
                        {!isTooltip ? <></>
                        // <div id="toolTip" className={style['toolTip']}>
                        //     <button 
                        //         type="button" 
                        //         className="radius-xs color-btn-01 bg top_left"  
                        //         onClick={()=>{
                        //             setState.setTooltipSearch(true);
                        //         }}
                        //         >
                        //         <span className="white-always caption-02-long txt">키워드 검색은 # 버튼을 눌러주세요</span>
                        //         <img src="/images/x_circle.svg" />
                        //     </button>
                        // </div>
                        :null}
                    </div>
                </section>

                {/* <!-- 검색시 나오기 전 화면 --> */}
                {data === null && isLoading === false ?
                    <>
                        <section className={"section recent_search wrapper s_wrap " + style['s_wrap'] + " " + style['recent_search']}>
                            
                            {saveMode ? 
                                <div className="tit_con clearfix">
                                    <h2 className="title left color-txt-normal title-02-b text_overflow">최근 검색어</h2>
                                    <div className="right">
                                        <BookpalLink 
                                            onClick={(e)=>{
                                                initKeywordData();
                                                getKeywordSubmit();
                                            }}
                                        ><span className="color-btn-03-txt btn-03">전체 삭제</span></BookpalLink>
                                        <BookpalLink onClick={()=>saveModeToggle()}><span className="color-btn-03-txt btn-03">저장 기능 끄기</span></BookpalLink>
                                    </div>
                                </div>
                            :
                                <div className="tit_con clearfix">
                                    <h2 className="title left color-txt-normal title-02-b text_overflow">최근 검색어</h2>
                                    <div className="right">
                                        <BookpalLink onClick={()=>saveModeToggle()}><span className="color-btn-03-txt btn-03">저장 기능 켜기</span></BookpalLink>
                                    </div>
                                </div>
                            }
                            
                            <SearchKeyword 
                                searchBoxClassName={style['searh_box']} 
                                slideClassName={style['swiper-slide']} 
                                closeClassName={style['close_btn']} 
                                keywordList={keywordList}
                                isSaveMode={saveMode}
                                onSelect={(name)=>{
                                    setSearchText(name);
                                    searchTextSubmit(name);
                                }}
                                onDelete={(name)=>{
                                    delKeywordData(name);
                                    getKeywordSubmit();
                                }}
                                onInit={()=>{
                                    delKeywordData();
                                    getKeywordSubmit();
                                }}
                                />
                            {/* <NoneSearchKeyword /> */}
                        </section>
                        {favKeyword && Array.isArray(favKeyword) && favKeyword?.length > 0 ?
                        <section className={"section popular_search wrapper s_wrap " + style['popular_search'] + " " + style['s_wrap']}>
                            <div className="tit_con clearfix">
                                <h2 className="title left color-txt-normal title-02-b text_overflow">인기 검색 키워드</h2>
                            </div>
                            <ol className={"list " + style['list']}>
                                {favKeyword.map((item, index)=>{
                                    return (
                                        <li key={index}>
                                            <PopularKeywordTmpl 
                                                numClassName={style['num']} 
                                                textClassName={style['text']} 
                                                number={index+1}
                                                keyword={item}
                                                onClick={(keyword)=>{
                                                    setKeyword(keyword);
                                                }}
                                                />
                                        </li>
                                    )
                                })}
                            </ol>
                        </section>
                        :null}

                        <section className={"section wrapper s_wrap keyword_area "+ style['keyword_area'] + " " + style['s_wrap']}>
                            <button 
                                type="button" 
                                className={"keyword_search_btn button btn_03 label_on large outlined round radius-full " + style['keyword_search_btn']}
                                onClick={handlerToggleKeywordModal} 
                                >
                                <span className="btn-04 color-txt-04">키워드 검색하기</span>
                            </button>
                        </section>
                    </>
                :null}

                {/* <!-- 검색 시 나오는 화면 --> */}
                {data !== null || isLoading === true ?
                    <section className="section template wrapper temp_list temp_list_type template_column" ref={contentRef}>
                        <ul className="tab_list menu_tab large">
                            <li className="color-txt-muted btn-04"><BookpalLink 
                                className={"border border-soild border-bottom-line " + (tab === "all" ? "active":"")}
                                onClick={()=>{setTab("all")}}
                                >전체</BookpalLink></li>
                            <li className="color-txt-muted btn-04"><BookpalLink 
                                className={"border border-soild border-bottom-line " + (tab === "novel" ? "active":"")}
                                onClick={()=>{setTab("novel")}}
                                >웹소설</BookpalLink></li>
                            <li className="color-txt-muted btn-04"><BookpalLink 
                                className={"border border-soild border-bottom-line " + (tab === "toon" ? "active":"")}
                                onClick={()=>{setTab("toon")}}
                                >웹툰</BookpalLink></li>
                            <li className="color-txt-muted btn-04"><BookpalLink 
                                className={"border border-soild border-bottom-line " + (tab === "epub" ? "active":"")}
                                onClick={()=>{setTab("epub")}}
                                >단행본</BookpalLink></li>
                        </ul>

                        <div className="tit_con clearfix">
                            <div className="left">
                                <label className="medium toggle_btn">
                                    <input role="switch" type="checkbox" checked={end} onChange={(e)=>{setEnd(e.target.checked);}}/>
                                    <span className="color-txt-05 btn-03">완결</span>
                                </label>
                            </div>
                            <div className="right">
                                <span className="color-btn-03-txt btn-03">검색결과 ({(paging?.total_cnt >= 10000 ? "10,000+" : paging?.total_cnt ?? 0).toLocaleString()})</span>
                            </div>
                        </div>
                        {(data && Array.isArray(data) && data?.length === 0) ? 
                            <>
                                <p className="no_data large color-txt-04 body-02-long">
                                    검색결과가 없습니다.
                                    <section className={"section wrapper s_wrap keyword_area "+ style['keyword_area'] + " " + style['s_wrap']}>
                                        <button 
                                            type="button" 
                                            className={"keyword_search_btn button btn_03 label_on large outlined round radius-full " + style['keyword_search_btn']}
                                            onClick={handlerToggleKeywordModal} 
                                            >
                                            <span className="btn-04 color-txt-04">키워드 검색하기</span>
                                        </button>
                                    </section>
                                </p> 
                            </>
                        :null }
                        
                        {isLoading || (data && Array.isArray(data) && data?.length > 0) ?
                        <>
                            <div className="list" >
                                <div className="list_1">
                                    <ul>
                                        {/* <!-- 리스트 --> */}
                                        {data && Array.isArray(data) ? 
                                            data.map((item,index)=>{
                                                return (
                                                    <li key={index} className="clearfix">
                                                        <WorkTmpl
                                                            memberInfo={memberInfo}
                                                            data={item}
                                                            />
                                                    </li>
                                                )
                                            })
                                        :null}
                                    </ul>
                                </div>
                            </div>
                            <div style={{height:"100px"}}></div>
                        </>
                        :null}
                    </section>
                :null}
            </div>
            {/* <!-- container --> */}

            <SearchKeywordModal 
                isShow={showSearchKeywordModal} 
                selectKeyword={keyword}
                onClose={()=>{setShowSearchKeywordModal(false);}} 
                action={(keyword)=>{
                    console.log(keyword)
                    setSearchText("#"+keyword);
                    setKeyword(keyword);
                }}
                />
            {/* <Footer /> */}
            <TopBtn />
        </>
    )
}

export default Search;