import { forwardRef, useState, useEffect, useRef } from 'react';
import { ImgBadge } from 'utils/Images';
import { getViewCntConvert } from 'utils/Common';
import useConfirmCustomModal from 'component/common/modal/useConfirmCustomModal';
import { ApiGenreGenreList } from 'api/Genre';
import useSelectArrModal from 'component/common/modal/useSelectArrModal';
import GenreHomeHalfBanner from 'component/common/banner/GenreHomeHalfBanner';

import useSelectModal from 'component/common/modal/useSelectModal';
import { Link } from 'react-router-dom';
import BookpalLink from 'component/common/custom/BookpalLink';
import { ImgDefault, ImgCover } from 'utils/Images';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BookpalLazyLoadImage from 'component/common/custom/BookpalLazyLoadImage';
import { useMemberInfo } from 'utils/State';
import useAlertModal from 'component/common/modal/useAlertModal';

import style from './ToonCommonSub.module.scss'


const BL = forwardRef((props, ref) =>{

    const memberInfo = useMemberInfo();

    const contentRef = useRef(null);

    // const [genre, setGenre] = useState("ro");
    const ct_type = "toon";
    const genre = "bl";
    const [options, setOptions] = useState({});
    const [mdSection, setMdSection] = useState(null);
    const [filterList, setFilterList] = useState(null);
    const [sortList, setSortList] = useState(null);

    const [ConfirmModal, confirm] = useConfirmCustomModal();
    const [AlertModal, alert] = useAlertModal();


    const [data, dataSubmit, initData, paging, setData] = ApiGenreGenreList({
        bottomRef:contentRef,
        data:{
            ...options,
            ct_type:ct_type,
            tab:genre,
        },
        onSuccess:(_data)=>{
            if(_data?.status === true){
                if(!!_data?.result?.md_section && _data?.result?.paging?.current_page === 1){
                    if(mdSection === null){
                        setMdSection(_data?.result?.md_section);
                    }
                }
                if(!!_data?.result?.selbox?.filter && _data?.result?.paging?.current_page === 1){
                    setFilterList(_data?.result?.selbox?.filter);
                }
                if(!!_data?.result?.selbox?.sort && _data?.result?.paging?.current_page === 1){
                    setSortList(_data?.result?.selbox?.sort);
                }
                return;
            }
            // console.log(data);
            if(!data){
                confirm({
                    title:"에러",
                    content:"통신에 실패하였습니다.새로고침 하시겠습니까?",
                    successBtnTitle:"새로고침",
                    cancleBtnTitle:"아니요.",
                    successAction:()=>{
                        dataSubmit();
                    }
                })
            }
        },
        onError:(e)=>{
            // console.log(e);
            if(!data){
                confirm({
                    title:"에러",
                    content:"통신에 실패하였습니다.새로고침 하시겠습니까?",
                    successBtnTitle:"새로고침",
                    cancleBtnTitle:"아니요.",
                    successAction:()=>{
                        dataSubmit();
                    }
                })
            }
        }
    });

    const handlerEndOptionToggle = (e)=>{
        const checked = e.currentTarget.checked;

        setOptions({
            ...options,
            end:(checked ? 1 : null),
        })
    }

    const [FilterModal, showFilterModal, selectKey,setSelectKey, initSelect, getValue] = useSelectArrModal(filterList, options?.filter || "", {
        valueName:"title",
        selectFn:(key)=>{
            setOptions({
                ...options,
                filter:key,
            });
        },
    });

    const [SortModal, showSortModal, selectSortKey,setSelectSortKey, initSortSelect, getSortValue] = useSelectArrModal(sortList, options?.sort || "ranking" , {
        valueName:"title",
        selectFn:(key)=>{
            setOptions({
                ...options,
                sort:key,
            });
        },
    });

    useEffect(()=>{
        initData();
        if(!!genre){
            dataSubmit();
        }
    },[options])


    return (
        <>
            {/* <!-- container --> */}
            <div id="container" className="sub_wrap wrap" ref={ref}>
                
                {/* <!-- 장르홈 배경하프배너(로맨스, 로판, BL, 판타지, 29+, 단행본) --> */}
                <GenreHomeHalfBanner item={mdSection}  />

                {/* <!-- 섹션 --> */}
                <section className="section template wrapper temp_list template_column">
                    <div className="tit_con clarfix">
                        <div className="left">
                            <label className="medium toggle_btn">
                                <input role="switch" type="checkbox" checked={options?.end ? true : false} onChange={handlerEndOptionToggle}/>
                                <span className="color-txt-05 btn-03">완결</span>
                            </label>
                        </div>
                        <div className="right" style={{cursor:"pointer"}}>
                            <a className="select_btn select_01"><span className="color-btn-03-txt btn-03" onClick={showFilterModal}>{getValue()}</span></a>
                            <a className="select_btn select_02" onClick={showSortModal}><span className="color-btn-03-txt btn-03" >{getSortValue()}</span></a>
                        </div>
                    </div>
                    <div className="list" ref={contentRef}>
                        {data && Array.isArray(data) && data?.length > 0 ?
                        <ul>
                            {/* <!-- 리스트--> */}
                            {
                                data?.map((item, index)=>{

                                    return (
                                        <li className="clearfix" key={"list_"+index}>
                                            <BookpalLink 
                                                to={"/content/"+item?.ct_type+"/"+item?.bid}
                                                check={{
                                                    view_type:memberInfo?.view_type,
                                                    work_adult:item?.adult,
                                                }}
                                                alert={alert}
                                                >
                                                <div className={"cover_img radius-xs " + style['cover_img']} >
                                                    {item?.adult ? <p className="ic_adult badge_adult_middle"><img src="../images/ic_adult.svg" /></p> : null}
                                                    {/* <img src={item?.cover} /> */}
                                                    {/* <BookpalLazyLoadImage 
                                                        placeholderSrc={ImgDefault("cover_logo")}
                                                        // effect="blur"
                                                        src={ImgCover(memberInfo?.view_type, item?.adult, ImgDefault("cover_lock"), item?.cover)}
                                                        // style={{width:"100px",height:"150px"}}
                                                        // style={defaultImageStyle}
                                                        className={style['cover_img']}
                                                        onError={(e)=>{
                                                            e.currentTarget.src = ImgDefault("cover_logo");
                                                        }}
                                                        /> */}
                                                    <BookpalLazyLoadImage 
                                                        adult={item?.adult}
                                                        adultswitch={memberInfo?.audit_switch}
                                                        defaulttype={"cover_logo"}
                                                        locktype={"cover_lock"}
                                                        src={item?.cover}
                                                        // style={defaultImageStyle}
                                                        className={style['cover_img']}
                                                    />
                                                    <p className="ic_bottom badges_box">
                                                        {item?.badge_promotion !== "" ? <span className="ic_midnight radius-xs icon_badge"><img src={ImgBadge(item?.badge_promotion)} /></span> : null}
                                                        {/* {item?.badge_new_up !== "" ? <span className="ic_up radius-xs icon_badge"><img src={ImgBadge(item?.badge_new_up)} /></span> : null} */}
                                                        {item?.badge_discount > 0 ? <span className="ic_discount radius-xs discount_10 icon_badge"><img src={ImgBadge(item?.badge_discount)} /></span> : null }
                                                    </p>
                                                </div>
                                                <div className="info_txt spacing_08">
                                                    <div className="list_tit_con">
                                                        {item?.badge_new_up !== "" ? <span className="ic_up radius-xs icon_badge"><img src={ImgBadge(item?.badge_new_up)} /></span> : null}
                                                        <span className="title color-txt-normal title-book-03-m text_overflow">{item?.title}</span>
                                                    </div>
                                                    <div className="writer color-txt-03 body-02 text_overflow">{item?.wr_nick}</div>
                                                    <div className="info">
                                                        {item?.free_chapter > 0 ? <span className="free body-01 color-txt-05">{item?.free_chapter}화무료</span> : null}
                                                        <span className="view body-01 color-txt-05">{getViewCntConvert(item?.view_cnt)}</span>
                                                    </div>
                                                    <div className="keyword color-txt-04 btn-02 text_overflow">
                                                        {/* <!-- span 태그 무조건 한줄에서 쓰기 --> */}
                                                        {Array.isArray(item?.subject_array) ? 
                                                            item?.subject_array.map((v, k)=>{
                                                                return <span key={v+"_"+k}>#{v}</span>
                                                            })
                                                        :null}
                                                        {/* <span>#키워드</span><span>#키워드</span><span>#키워드입니다~</span><span>#키워드</span><span>#키워드 들어가는자리</span><span>#키워드입니다~</span> */}
                                                    </div>
                                                </div>
                                            </BookpalLink>
                                        </li>
                                    )
                                })
                            }
                            
                        </ul>
                        :<div style={{height:"100vh"}}></div>}
                    </div>
                    <div style={{height:"100px"}}></div>
                </section>
            </div>
            <FilterModal />
            <SortModal />
            <ConfirmModal />
            <AlertModal />
            {/* <!-- container --> */}
        </>
    );
});


export default BL;