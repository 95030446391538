
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ApiCertLoading } from 'api/Cert';


function Cert(){

    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);

    const appReturnLink = queryParams.get("app_return_link");
    const webReturnPath = location.state?.return_path;

    console.log(appReturnLink);

    const certLoadingSubmit = ApiCertLoading({
        returnPath:(appReturnLink ?? webReturnPath ?? "/")
    });
    
    useEffect(()=>{
        certLoadingSubmit();
    },[]);

    return (
        <>
            Cert....
        </>
    )
}



export default Cert;
