import { Link } from 'react-router-dom';
import BookpalLink from 'component/common/custom/BookpalLink';
import { Swiper, SwiperSlide } from 'swiper/react';
import { GetState } from 'utils/State';
import style from './Alarm.module.scss';
import { isApp } from 'utils/AppUtil';
import { useIsMobile } from 'utils/Common';



function SubMenuNav(props){

    const page = props.page || "";
    const type = props.type || "all";
    const setType = props.setType || (()=>{});

    const getState = GetState();
    const dot = getState.useGetDot();

    const isMobile = useIsMobile();

    return (
        <>
            {/* <!-- subDepth --> */}
            <div id="subDepth" className={"color-bg-01 type_header " + style['type_header'] + " " + style['subDepth']} style={{position:"sticky",top:isApp()?"0px":isMobile?"48px":"64px"}}>
                <Swiper className="swiper-container txt color-txt-muted"
                    slidesPerView={'auto'}
                    preventClicks={true}
                    preventClicksPropagation={false}
                    observer={true}
                    observeParents={true}
                >
                {/* <div className="swiper-container snbSwiper wrapper"> */}
                    {/* <div className="swiper-wrapper "> */}
                        <SwiperSlide className={"swiper-slide " + (type==="all"||type===null?"on":"")}>
                            <BookpalLink 
                                // to={"/mypage_menu/alarm"}
                                onClick={(e)=>{
                                    e.preventDefault();
                                    setType('all');
                                }}
                                >
                                <span className="color-btn-01 border border-soild btn-04">전체{dot?.alarm_total ? <span className="dot color-btn-01 bg radius-full"></span>:null}</span>
                            </BookpalLink>
                        </SwiperSlide>
                        <SwiperSlide className={"swiper-slide " + (type==="event"?"on":"")}>
                            <BookpalLink 
                                // to={"/mypage_menu/alarm?type=event"}
                                onClick={(e)=>{
                                    e.preventDefault();
                                    setType('event');
                                }}
                                >
                                <span className="color-btn-01 border border-soild btn-04">이벤트{dot?.alarm_event ? <span className="dot color-btn-01 bg radius-full"></span>:null}</span>
                            </BookpalLink>
                        </SwiperSlide>
                        <SwiperSlide className={"swiper-slide " + (type==="mynews"?"on":"")}>
                            <BookpalLink 
                                // to={"/mypage_menu/alarm?type=mynews"}
                                onClick={(e)=>{
                                    e.preventDefault();
                                    setType('mynews');
                                }}
                                >
                                <span className="color-btn-01 border border-soild btn-04">내소식{dot?.alarm_mynews ? <span className="dot color-btn-01 bg radius-full"></span>:null}</span>
                            </BookpalLink>
                        </SwiperSlide>
                        <SwiperSlide className={"swiper-slide " + (type==="update"?"on":"")}>
                            <BookpalLink 
                                // to={"/mypage_menu/alarm?type=update"}
                                onClick={(e)=>{
                                    e.preventDefault();
                                    setType('update');
                                }}
                                >
                                <span className="color-btn-01 border border-soild btn-04">업데이트{dot?.alarm_update ? <span className="dot color-btn-01 bg radius-full"></span>:null}</span>
                            </BookpalLink>
                        </SwiperSlide>
                    {/* </div> */}
                {/* </div> */}
                </Swiper>
            </div>
            {/* <!-- //subDepth --> */}
        </>
    )
}




export default SubMenuNav;