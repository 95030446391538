import { Link } from "react-router-dom";


function PopularKeywordTmpl(props){

    const number = props.number || null;
    const keyword = props.keyword || null;
    const numClassName = props.numClassName || "";
    const textClassName = props.textClassName || "";
    const onClick = props.onClick || null;


    const color = (!!number && number > 4) ? "color-btn-02" : "color-btn-01";

    return (
        <Link 
            onClick={(e)=>{
                e.preventDefault();
                if(onClick){
                    onClick(keyword);
                }
            }}
        >
            <p className={"num radius-full bg " + color + " " + numClassName}><span className="white-always txt">{number}</span></p>
            <p className={"text btn-04 color-txt-normal " + textClassName}>#{keyword}</p>
        </Link>
    );
}



export default PopularKeywordTmpl;