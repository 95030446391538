import NewlineToBr from 'component/common/content/NewlineToBr';
import style from '../style/Contents.module.scss';
import { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { decryptOld } from 'utils/Crypt';
import { SetState, GetState } from 'utils/State';
import { setViewerData, getViewerData } from 'utils/Storage';

import styleVer from './ViewerHorizontal.module.scss';
import ViewerContents from './ViewerContents';

function ViewerScrollEpub({data, type, bid, cid, page, visible, setPage, setTotalPage, setVisible, alert, buyConfirm, setScrollHeight}){

    const [pages, setPages] = useState([]);
    // const [currPages, setCurrPages] = useState([]);

    const containerRef = useRef(null);
    const swiperRef = useRef(null);
    
    const setState = SetState();
    const getState = GetState();

    const [onScroll, setOnScroll] = useState(false);
    const [tmpPage, setTmpPage] = useState(null);
    const [onPage, setOnPage] = useState(false);
    const [fadeState, setFadeState] = useState(false);

    

    const viewerFont = getState.useGetViewerInfoFont();
    
    // console.log("viewerFont",viewerFont)
    const viewerFontSize = getState.useGetViewerInfoFontSizePx();
    const viewerLineHeight = getState.useGetViewerInfoLineHeightPer();
    const viewerSideMargin = getState.useGetViewerInfoSideMarginPx();
    const viewerStyle = getState.useGetViewerInfoStyle();
    const viewerTheme = getState.useGetViewerInfoTheme();
    const viewerImageNone = getState.useGetViewerInfoImageNone();
    const viewerRun = getState.useGetViewerInfoRun();

    const padding = 10;
    
    const getThemeColor = (theme)=>{
        switch(theme){
            default:
            case "white": return "viewer-black-01";
            case "gray": return "viewer-black-01";
            // case "black": return "viewer-gray";
            case "black": return "viewer-white";
            case "yellow": return "viewer-black-01";
            case "green": return "viewer-gray";
        }
    }
    let timeout = null;
    const handlerScroll = ()=>{
        
        if(containerRef.current){
            const height = containerRef.current.scrollHeight - (window.innerHeight / 2);
            const currHeight = window.scrollY;
            // console.log("currHeight => ",height, currHeight)

            setScrollHeight(height);

            let per = parseInt((currHeight / height * 100), 10);
            if(per > 100){
                per = 100;
            }

            // setOnPage(true);
            // console.log(tri
            if(timeout){
                clearTimeout(timeout);
                timeout = null;
            }
            timeout = setTimeout(()=>{
                setPage(per);
                setViewerData(type, "scroll_epub", data?.extra?.bid, cid, per);
            },10);
            // setPage(per);
        }
    }

    useEffect(()=>{
        setPage(0);
        setTotalPage(100);

        setTimeout(()=>{
            const height = containerRef.current.scrollHeight - (window.innerHeight / 2);
            const per = getViewerData(type, "scroll_epub", cid);
            // console.log(height, per, height * (per / 100));
            window.scrollTo({
                top:Math.ceil(height * (per / 100)),
                left:0,
                behavior:"instant"
            });
        },200);

        let _height = null;
        const interval = setInterval(()=>{
            try {
                const height = containerRef.current.scrollHeight - (window.innerHeight / 2);
                if(_height != height){
                    _height = height;

                    setScrollHeight(height);
                }
            } catch(e){
                clearInterval(interval);
            }
            
        },300);
        
        return ()=>{
            clearInterval(interval);
        }
    },[])

    useEffect(()=>{    
        // if(!onPage){
            window.addEventListener('scroll', handlerScroll);
        // }
        

        return () => {
            window.removeEventListener('scroll', handlerScroll);
        };

    },[onPage])

    useEffect(()=>{
        // console.log("onPage",onPage);
        if(containerRef.current){
            const height = containerRef.current.scrollHeight - (window.innerHeight / 2);
            const currHeight = window.scrollY;

            let per = parseInt((currHeight / height * 100), 10);

            // console.log("asdf per-------------------------", per);
            if(page != per){
                window.removeEventListener('scroll', handlerScroll);
                
                // timeout = setTimeout(()=>{
                const tmpPage = height * (page / 100);

                // console.log("asdf-------------------------", page,"|", height,"|", (height / page),"|", tmpPage);
                
                // setOnPage(true);
                // setTmpPage(tmpPage);
                // setPage(tmpPage)
                
                // window.scrollTo(0,tmpPage);
                window.scrollTo({
                    top: tmpPage,
                    left: 0,
                    behavior: 'auto'
                })
                if(timeout){
                    clearTimeout(timeout);
                    timeout = null;
                }
                timeout = setTimeout(()=>{
                    window.addEventListener('scroll', handlerScroll);
                    setOnPage(false);
                },50)
                // },100);
                
            }
        }
    },[page])


    // console.log("pages=>", data)
    
    return (
        <div 
            className={"view_body " + style['view_body']}
            onClick={()=>{
                setVisible(!visible);
            }}
            onTouchMove={()=>{
                setVisible(false);
            }}
            >
            <div 
                className={"area_box " + style['area_box']}
                style={{
                    display: "flex",
                    alignItems: "center",
                    //height: "100vh",
                    overflow: "hidden",
                }}
                >
                {/* <!-- 좌우모드, 역방향일때 작품소개 영역일때만 scrollmode 추가 --> */}
                <div className={"contents_box m_wrap " + style['contents_box'] +" " + style['m_wrap']}>
                    {/* <!-- // 테마 viewer-white, viewer-gray, viewer-black-02, viewer-yellow, viewer-green 클래스 추가 -->
                    <!-- // 글꼴 NanumBarunGothic, NotoSansKR, YESMyoungjo -->
                    <!-- // 좌우여백 style 변경 --> */}
                    <div 
                        ref={containerRef}
                        className={"area bg " + " " + style['area'] + " " + style['bg'] + " " + style['viewer-black-02']} 
                        style={{paddingLeft: "0", paddingRight: "0", paddingBottom:"10%",paddingTop:"10%", height:"100%"}}
                        >

                        {data?.epub_contents.map((content, index)=>{
                            const items = content?.contents;

                            return items.map((item, index)=>{
                                switch(item?.type){
                                    case "image":
                                        if(viewerImageNone){
                                            return <></>
                                        }
                                        return (
                                            <>
                                                <div 
                                                    className={"images " + style['images']}
                                                    style={{
                                                        height: "100%",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        width:"100%",
                                                        marginBottom:"10%",
                                                    }}
                                                ><img src={item?.data} 
                                                    style={{
                                                        width: "auto",
                                                        maxHeight: "100%",
                                                        maxWidth:"100%",
                                                        pointerEvents:"none",
                                                    }}
                                                    /></div>
                                            </>
                                        )
                                    case "title":
                                        return (
                                            <>
                                                {/* <div 
                                                    className={"contents txt " + getThemeColor(viewerTheme) + " " + viewerFont + " " + style['contents'] + " " + style['txt'] + " " + style[viewerFont]} 
                                                    style={{
                                                        fontSize: viewerFontSize, 
                                                        lineHeight: viewerLineHeight, 
                                                        height:"100%",
                                                        display:"flex",
                                                        paddingTop:0,
                                                        paddingBottom:0,
                                                        paddingLeft:(viewerSideMargin),
                                                        paddingRight:(viewerSideMargin),
                                                    }}
                                                    >
                                                    <b><NewlineToBr text={decryptOld(item?.data)} /></b>
                                                </div> */}
                                            </>
                                        )
                                    case "text":
                                        return (
                                            <>
                                                <div 
                                                // ref={containerRef} 
                                                className={"contents txt " + getThemeColor(viewerTheme) + " " + viewerFont + " " + style['contents'] + " " + style['txt'] + " " + style[viewerFont]} 
                                                style={{
                                                    fontSize: viewerFontSize, 
                                                    lineHeight: viewerLineHeight, 
                                                    height:"100%",
                                                    display:"flex",
                                                    paddingTop:0,
                                                    paddingBottom:0,
                                                    paddingLeft:(viewerSideMargin),
                                                    paddingRight:(viewerSideMargin),
                                                    // alignItems:"center",
                                                }}
                                                >
                                                <NewlineToBr text={decryptOld(item?.data)} />
                                                <br />
                                                <br />
                                                <br />
                                            </div>
                                            </>
                                        )
                                }
                            })
                            
                        })}
                        {/* <!-- image --> */}
                        

                        
                        


                    </div>
                    
                    {/* <!-- 작품 소개 영역 --> */}
                    <div 
                        className={"section_box m_wrap " + style['section_box'] + " " + style['m_wrap']} 
                        style={{backgroundColor:"white"}}
                        >
                    <ViewerContents 
                            className={getThemeColor(viewerTheme)} 
                            elemStyle={{
                                // marginTop:"20%",
                            }}
                            data={data?.extra}
                            next={data?.next_chapter}
                            type={type}
                            cid={cid}
                            alert={alert}
                            buyConfirm={buyConfirm}
                            />
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ViewerScrollEpub;