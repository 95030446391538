import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import BookpalLink from 'component/common/custom/BookpalLink';
import { ApiMypageBookmarkList, ApiMypageBookmark } from 'api/Mypage';

import Header from 'component/common/header/Header';
import Footer from 'component/common/footer/Footer';

import SubNav from "component/common/nav/SubNav";

import style from './Shortcut.module.scss';


import img_novel from 'assets/images/novel/thumbnail_bg2.png';
import img_thumbnail_illust2 from 'assets/images/novel/thumbnail_illust2.png';
import img_alarm from 'assets/images/small_banner_alarm_2.png'
import { useEffect, useState } from 'react';
import { useIsMobile } from 'utils/Common';
import useConfirmCustomModal from 'component/common/modal/useConfirmCustomModal';
import { SetState, useMemberInfo } from 'utils/State';
import useAlertModal from 'component/common/modal/useAlertModal';
import BigBanner from 'component/common/banner/BigBanner';

function Shortcut(){


    const navigate = useBookpalNavigate();

    const isMobile = useIsMobile();
    const location = useLocation();

    const setState = SetState();

    const memberInfo = useMemberInfo();

    const [data, setData] = useState(null);
    const [ConfirmModal, confirm] = useConfirmCustomModal();
    const [AlertModal, alert] = useAlertModal();
    
    const bookmarkSubmit = ApiMypageBookmarkList({
        onSuccess:(data)=>{
            if(data?.status === true){
                setData(data?.result);
            }
        },
        onError:(e)=>{

        }
    });

    const setBookmarkSubmit = ApiMypageBookmark({
        onSuccess:(data)=>{
            if(data?.status === true){
                // setData(data?.result);
                bookmarkSubmit();
                setState.setToast({
                    title:(data?.result?.bookmark_status === "unset" ? "즐겨찾기에서 삭제되었습니다.":"즐겨찾기에 추가되었습니다.")
                });
            }
        },
        onError:(e)=>{

        }
    });

    const handlerSetBookmark = (e)=>{
        const id = e.currentTarget.getAttribute('data-id');
        const code = e.currentTarget.getAttribute('data-code');
        const bookmark_status = e.currentTarget.getAttribute('data-bookmark');

        console.log(code)
        if(code === "toon_adult_29" || code === "novel_adult_29"){
            if(!memberInfo?.is_cert){
                alert("즐겨찾기","작품 감상을 위해 성인 인증이 필요합니다.");
                return;
            }
    
            if(!memberInfo?.is_adult){
                alert("즐겨찾기","해당 작품은 성인 작품으로 19세 미만 청소년이 이용할 수 없습니다.");
                return;
            }
        }
        

        if(bookmark_status === "true"){
            confirm({
                title:"즐겨찾기 삭제",
                content:"즐겨찾기에서 삭제하시겠습니까?",
                successAction:()=>{
                    setBookmarkSubmit({
                        menu_id:id
                    });
                }
            });
        } else {
            if(data?.bookmark?.length >= 6){
                setState.setToast({
                    title:"즐겨찾기는 최대 6개까지 설정 가능합니다."
                })
                return;
            }
            confirm({
                title:"즐겨찾기 추가",
                content:"즐겨찾기에 추가하시겠습니까?",
                successAction:()=>{
                    setBookmarkSubmit({
                        menu_id:id
                    });
                }
            });
        }
    }

    useEffect(()=>{
        if(!memberInfo?.is_login){
            alert("알림","서비스 이용을 위하여 로그인이 필요합니다.",()=>{
                // navigate("/login",{state:{return_path:location.pathname}});
                navigate(-1);
            })
            return;
        }
        bookmarkSubmit();
    },[]);


    if(data === null){
        return (
            <><AlertModal /></>
        )
    }

    return (
        <>
            <Header useMobile={false} pcTop={"-64px"} />
            <SubNav 
                name={"바로가기"}
                usePc={true} 
                useMobile={true} 
                usePcRightBtns={true}
                useBtnPrev={true}
                useBtnPcPrev={true}
                useBtnHome={true}

                setTop={'0px'} 
                setPcTop={'0px'} 
                />


            
            {/* <!-- container --> */}
            <div id="container" className={style['container']} style={{paddingBottom:(isMobile?"36px":"0px")}}>
                <section className={"section template wrapper temp_list " + style['section']}>
                    {/* <!-- 배너 --> */}
                    {/* <div className={"banner_big list " + style['banner_big'] + " " + style['list']}>
                        <a href="#">
                            <div className={"area_box radius-sm " + style['area_box']} style={{background: "#efe5ff"}}>
                                <img src={img_alarm} />
                            </div>
                        </a>
                    </div> */}
                    {data?.banner && Array.isArray(data?.banner) && data?.banner?.length > 0 ?
                        <BigBanner data={data?.banner} />
                    :<div className={"banner_big list " + style['banner_big'] + " " + style['list']}>
                        <a>
                            <div className={"area_box radius-sm " + style['area_box']} style={{background: "#ffe9e5"}}>
                                <img src={img_alarm} />
                            </div>
                        </a>
                    </div>}
                    <div className={"list " + style['list']}>
                        {/* <!-- 즐겨찾기 --> */}
                        <div className={"bookmark_box " + style['bookmark_box']}>
                            <div className={"tit_con " + style['tit_con']}>
                                <h2 className={"title left color-txt-normal title-02-b " + style['title'] + " " + style['left']}>즐겨찾기</h2>
                            </div>
                            <div className={"keyword_box " + style['keyword_box']}>
                            {!data || !data?.bookmark || !Array.isArray(data?.bookmark) || data?.bookmark?.length !== 0 ?
                                <ul className={"keyword " + style['keyword']}>
                                    {data && data?.bookmark && Array.isArray(data?.bookmark) && data?.bookmark?.length > 0 ? 
                                        data?.bookmark.map((item, index)=>{
                                            return (
                                                <li key={"bookmark_"+index} className="color-btn-01 border border-line border-soild radius-full">
                                                    <BookpalLink 
                                                        to={item?.link}
                                                        check={{
                                                            view_type:memberInfo?.view_type,
                                                            work_adult:(item?.code === "toon_adult_29" || item?.code === "novel_adult_29"),
                                                        }}
                                                        alert={alert}
                                                        >
                                                        <span className={"btn-04 color-btn-01 txt"}>{item?.name}</span>
                                                    </BookpalLink>
                                                    {/* <!-- 클릭시 click 추가 --> */}
                                                    <button 
                                                        type="button" 
                                                        className={"button icon click " + (item?.bookmark ? style['click']:"")}
                                                        data-code={item?.code}
                                                        data-id={item?.menu_id}
                                                        data-bookmark={"true"}
                                                        onClick={handlerSetBookmark}
                                                        ><span></span></button>
                                                </li>
                                            )
                                        })
                                    : null}
                                </ul>
                            : <p className="no_data tiny color-txt-04 body-02-long">최대 6개 등록이 가능합니다.</p>}
                                {/* <!-- 즐겨찾기 없을때 --> */}
                                {/* <!-- <p className="no_data tiny color-txt-04 body-02-long">최대 6개 등록이 가능합니다.</p> --> */}
                            </div>
                        </div>
                        {/* <!-- 웹소설 추천 --> */}
                        <div className={"novel_box " + style['novel_box']}>
                            <div className={"tit_con " + style['tit_con']}>
                                <h2 className="title left color-txt-normal title-02-b">웹소설 추천</h2>
                                <div className="right">
                                    {/* <BookpalLink 
                                    to={"/novel"}
                                    className="more_btn">
                                        <span className="color-btn-03-txt btn-03">더보기</span>
                                    </BookpalLink> */}
                                </div>
                            </div>
                            <div className={"keyword_box " + style['keyword_box']}>
                                <ul className={"keyword " + style['keyword']}>
                                    {data && data?.novel_recommend && Array.isArray(data?.novel_recommend) ? 
                                        data?.novel_recommend.map((item, index)=>{
                                            return (
                                                <li key={"novel_recommend_"+index} className="color-border-01 border-line border-soild radius-full">
                                                    <BookpalLink 
                                                        to={item?.link}
                                                        check={{
                                                            view_type:memberInfo?.view_type,
                                                            work_adult:(item?.code === "novel_adult_29"),
                                                        }}
                                                        alert={alert}
                                                        >
                                                        <span className={"color-txt-03 " + (item?.bookmark ? "btn-04-active" :"btn-04")}>{item?.name}</span>
                                                    </BookpalLink>
                                                    {/* <!-- 클릭시 click 추가 --> */}
                                                    <button 
                                                        type="button" 
                                                        className={"button icon click " + (item?.bookmark ? style['click']:"")}
                                                        data-id={item?.id}
                                                        data-code={item?.code}
                                                        data-bookmark={item?.bookmark ? "true":"false"}
                                                        onClick={handlerSetBookmark}
                                                        ><span></span></button>
                                                </li>
                                            )
                                        })
                                    : null}
                                </ul>
                            </div>
                        </div>
                        {/* <!-- 웹툰 추천 --> */}
                        <div className={"toon_box " + style['toon_box']}>
                            <div className={"tit_con " + style['tit_con']}>
                                <h2 className="title left color-txt-normal title-02-b">웹툰 추천</h2>
                                <div className="right">
                                    {/* <BookpalLink 
                                    to={"/toon"}
                                    className="more_btn">
                                        <span className="color-btn-03-txt btn-03">더보기</span>
                                    </BookpalLink> */}
                                </div>
                            </div>
                            <div className={"keyword_box " + style['keyword_box']}>
                                <ul className={"keyword " + style['keyword']}>
                                    {data && data?.toon_recommend && Array.isArray(data?.toon_recommend) ? 
                                        data?.toon_recommend.map((item, index)=>{
                                            return (
                                                <li key={"toon_recommend_"+index} className="color-border-01 border-line border-soild radius-full">
                                                    <BookpalLink 
                                                        to={item?.link}
                                                        check={{
                                                            view_type:memberInfo?.view_type,
                                                            work_adult:(item?.code === "toon_adult_29"),
                                                        }}
                                                        alert={alert}
                                                        >
                                                        <span className={"color-txt-03 " + (item?.bookmark ? "btn-04-active" :"btn-04")}>{item?.name}</span>
                                                        {/* "btn-04-active */}
                                                    </BookpalLink>
                                                    {/* <!-- 클릭시 click 추가 --> */}
                                                    <button 
                                                        type="button" 
                                                        className={"button icon click " + (item?.bookmark ? style['click']:"")}
                                                        data-id={item?.id}
                                                        data-code={item?.code}
                                                        data-bookmark={item?.bookmark ? "true":"false"}
                                                        onClick={handlerSetBookmark}
                                                        ><span></span></button>
                                                </li>
                                            )
                                        })
                                    : null}
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {/* <!-- container --> */}

            <Footer />
            <ConfirmModal />
            <AlertModal />
        </>
    );
}




export default Shortcut;