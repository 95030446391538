import { useApiMutation } from './common/ApiCommon';


export const ApiMainHome = (options) => {

    const subUrl = "/main/home";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiMainFreeviewMain = (options) => {

    const subUrl = "/main/freeview_main";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiMainFreeviewNovel = (options) => {

    const subUrl = "/main/freeview_novel";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiMainFreeviewToon = (options) => {

    const subUrl = "/main/freeview_toon";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};