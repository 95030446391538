import * as types from 'constant/ActionType';
import * as datas from 'constant/DataType';
import { setStorageData } from 'utils/Storage';


const data = (state = {}, action) => {
  switch (action.type) {
    case types.SET_TOKEN:
        setStorageData(datas.TOKENS, action.token);
        return {
            ...state,
            tokens: action.token,
        };
    case types.DEL_TOKEN:
        // console.log(action);
        setStorageData(datas.TOKENS, action.token);
        return {
            ...state,
            tokens: action.token,
        };
    case types.SET_MEMBER_INFO:
        // console.log(action);
        setStorageData(datas.MEMBER_INFO, action.member_info, 60 * 24 * 7);
        return {
            ...state,
            member_info: action.member_info,
        };
    case types.DEL_MEMBER_INFO:
        // console.log(action);
        setStorageData(datas.MEMBER_INFO, null, 0);
        return {
            ...state,
            member_info: null,
        };
    case types.SET_DOT:
        // console.log(action);
        setStorageData(datas.DOT, action.dot);
        return {
            ...state,
            dot: action.dot,
        };
    case types.SET_KEYWORD_SAVE_MODE:
        // console.log(action);
        setStorageData(datas.KEYWORD_SAVE_MODE, action.keyword_save_mode);
        return {
            ...state,
            keyword_save_mode: action.keyword_save_mode,
        };
    case types.SET_SYSTEM_MODE:
        // console.log(action);
        setStorageData(datas.SYSTEM_MODE, action.system_mode);
        return {
            ...state,
            system_mode: action.system_mode,
        };
    case types.SET_ADULT_SWITCH:
        // console.log(action);
        setStorageData(datas.ADULT_SWITCH, action.adult_switch);
        return {
            ...state,
            adult_switch: action.adult_switch,
        };

    case types.SET_AUTO_LOGIN:
        // console.log(action);
        setStorageData(datas.AUTO_LOGIN, action.auto_login);
        return {
            ...state,
            auto_login: action.auto_login,
        };
    case types.SET_APP_BANNER:
        // console.log(action);
        setStorageData(datas.APP_BANNER, action.app_banner, 60 * 24 * 7);
        return {
            ...state,
            app_banner: action.app_banner,
        };
    case types.SET_SAVE_ID:
        setStorageData(datas.SAVE_ID, action.saveid);
        return {
            ...state,
            saveid: action.saveid,
        };
    case types.SET_SAVE_ID_CHECK:
        setStorageData(datas.SAVE_ID_CHECK, action.saveid_check);
        return {
            ...state,
            saveid_check: action.saveid_check,
        };
    case types.SET_TOOLTIP_LOGIN:
        setStorageData(datas.TOOLTIP_LOGIN, action.tooltip_login, null);
        return {
            ...state,
            tooltip_login: action.tooltip_login,
        };
    case types.SET_TOOLTIP_SEARCH:
        setStorageData(datas.TOOLTIP_SEARCH, action.tooltip_search, null);
        return {
            ...state,
            tooltip_search: action.tooltip_search,
        };
    case types.SET_LAST_SOCIAL_LOGIN_TRY_PATH:
        setStorageData(datas.LAST_SOCIAL_LOGIN_TRY_PATH, action.last_social_login_try_path, 10);
        return {
            ...state,
            last_social_login_try_path: action.last_social_login_try_path,
        };
    case types.SET_LAST_SOCIAL_LOGIN_TRY_TYPE:
        setStorageData(datas.LAST_SOCIAL_LOGIN_TRY_TYPE, action.last_social_login_try_type);
        return {
            ...state,
            last_social_login_try_type: action.last_social_login_try_type,
        };
    case types.SET_LAST_SOCIAL_LOGIN_TYPE:
        setStorageData(datas.LAST_SOCIAL_LOGIN_TYPE, action.last_social_login_type);
        return {
            ...state,
            last_social_login_type: action.last_social_login_type,
        };
    case types.SET_LAST_SOCIAL_LOGIN_DATE:
        setStorageData(datas.LAST_SOCIAL_LOGIN_DATE, action.last_social_login_date);
        return {
            ...state,
            last_social_login_date: action.last_social_login_date,
        };
    case types.SET_CONNECT_SOCIAL_LOGIN_TYPE:
        setStorageData(datas.CONNECT_SOCIAL_LOGIN_TYPE, action.connect_social_login_type, 20);
        return {
            ...state,
            connect_social_login_type: action.connect_social_login_type,
        };

    case types.SET_CERT_RETURN_PATH:
        setStorageData(datas.CERT_RETURN_PATH, action.cert_return_path);
        return {
            ...state,
            cert_return_path: action.cert_return_path,
        };
    case types.SET_SHOW_GIFT_TOAST:
        // setStorageData(datas.SHOW_GIFT_TOAST, action.cert_return_path);
        return {
            ...state,
            show_gift_toast: action.show_gift_toast,
        };
    // -------------------------------
    // Viewer
    // -------------------------------
    case types.SET_VIEWER_INFO_FONT:
        setStorageData(datas.VIEWER_INFO_FONT, action.viewer_font);
        return {
            ...state,
            viewer_font: action.viewer_font,
        };
    case types.SET_VIEWER_INFO_FONT_SIZE:
        setStorageData(datas.VIEWER_INFO_FONT_SIZE, action.viewer_font_size);
        return {
            ...state,
            viewer_font_size: action.viewer_font_size,
        };
    case types.SET_VIEWER_INFO_LINE_HEIGHT:
        setStorageData(datas.VIEWER_INFO_LINE_HEIGHT, action.viewer_line_height);
        return {
            ...state,
            viewer_line_height: action.viewer_line_height,
        };
    case types.SET_VIEWER_INFO_SIDE_MARGIN:
        setStorageData(datas.VIEWER_INFO_SIDE_MARGIN, action.viewer_side_margin);
        return {
            ...state,
            viewer_side_margin: action.viewer_side_margin,
        };
    case types.SET_VIEWER_INFO_STYLE:
        setStorageData(datas.VIEWER_INFO_STYLE, action.viewer_style);
        return {
            ...state,
            viewer_style: action.viewer_style,
        };
    case types.SET_VIEWER_INFO_THEME:
        setStorageData(datas.VIEWER_INFO_THEME, action.viewer_thmeme);
        return {
            ...state,
            viewer_thmeme: action.viewer_thmeme,
        };
    case types.SET_VIEWER_INFO_IMAGE_NONE:
        setStorageData(datas.VIEWER_INFO_IMAGE_NONE, action.viewer_image_none);
        return {
            ...state,
            viewer_image_none: action.viewer_image_none,
        };
    case types.SET_VIEWER_INFO_RUN:
        setStorageData(datas.VIEWER_INFO_RUN, action.viewer_run);
        return {
            ...state,
            viewer_run: action.viewer_run,
        };
    default:
        return state;
  }
};

export default data;