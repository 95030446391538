import { useDispatch } from 'react-redux';
import { useLocation, useMatch, useNavigate, useParams } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import { ApiMypageCommentHistory } from 'api/Mypage';
import { ApiCommentCommentDelete, ApiCommentCommentList, ApiCommentCommentWrite, ApiCommentCommentLike } from 'api/Comment';
import useConfirmModal from 'component/common/modal/useConfirmModal';
import { actionSetShowGiftToast } from 'action';
import { SetState } from 'utils/State';
import { ApiCommonWriterReject } from 'api/Common';
import { runPopupDeclare } from 'utils/Common';
import he from 'he';

import GiftToast from 'component/common/toast/GiftToast';
import Header from 'component/common/header/Header';
import Footer from 'component/common/footer/Footer';

import SubNav from "component/common/nav/SubNav";

import style from './style/Contents.module.scss';

import img_login_naver_icon from 'assets/images/user/login_naver_icon.svg';
import img_login_kakao_icon from 'assets/images/user/login_kakao_icon.svg';
import img_login_google_icon from 'assets/images/user/login_google_icon.svg';
import img_login_facebook_icon from 'assets/images/user/login_facebook_icon.svg';
import img_login_apple_icon from 'assets/images/user/login_apple_icon.svg';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useIsMobile } from 'utils/Common';
import useAlertModal from 'component/common/modal/useAlertModal';
import HeaderComment from './viewer/HeaderComment';
import NewlineToBr from 'component/common/content/NewlineToBr';
import useConfirmCustomModal from 'component/common/modal/useConfirmCustomModal';
import { isApp, appSetDeclare } from 'utils/AppUtil';
import { useActivate } from 'react-activation';


function ViewerComment({type, bid, cid}){

    // const { type, bid, cid } = useParams();

    const isMobile = useIsMobile();
    const navigate = useBookpalNavigate();
    const dispatch = useDispatch();
    const contentRef = useRef(null);
    const setState = SetState();

    const [commentId, setCommentId] = useState(null);
    const [likeCommentId, setLikeCommentId] = useState(null);
    const [addComment, setAddComment] = useState("");

    const [title, setTitle] = useState("");

    const [rejectReplyId, setRejectReplyId] = useState(null);

    const [AlertModal, alert] = useAlertModal();
    const [ConfirmModal, confirm] = useConfirmCustomModal();
    const [ConfirmModal2, confirm2] = useConfirmCustomModal();
    
    // const handlerShowToast = (data)=>{
    //     const title = data?.title ?? null;
    //     const body = data?.body ?? null;
    //     const date = data?.date ?? "";
    //     dispatch(actionSetShowGiftToast({
    //         title:title,
    //         body:body,
    //         date:date,
    //     }));
    // }
    
    const [data, dataSubmit, dataInit, paging, setData] = ApiCommentCommentList({
        data:{
            bid:bid,
            cid:cid,
        },
        bottomRef:contentRef,
        onSuccess:(data)=>{
            // console.log(data);
            if(data?.status === true){
                setTitle(data?.result?.chapter_title);
            }
        },
        onError:(e)=>{
            console.log(e);
        }
    });


    const handlerRejectOrDeclare = (chapter_id, comment_id, reject, nickname)=>{
        confirm({
            title:"신고/차단하기",
            successBtnTitle:"신고하기",
            successBtnTitle2:reject ? "차단 해제하기" : "차단하기",
            cancleBtnTitle:"취소",
            successAction:()=>{
                // console.log(comment);
                runPopupDeclare({
                    type:5,
                    chapter_id:chapter_id,
                    reply_id:comment_id,
                    // comment_reply_id:reply_id,
                });

                if(isApp()){
                    appSetDeclare({
                        type:5,
                        chapter_id:chapter_id,
                        reply_id:comment_id,
                        // comment_reply_id:reply_id,
                    });
                }
            },

            successAction2:()=>{
                setRejectReplyId(comment_id);
                // rejectSubmit({
                //     reject_id:comment_id,
                //     reject_type:"comment",
                // });
                confirm2({
                    title:"차단하기",
                    content:"["+nickname+"]님 댓글/답글을 "+(reject ? "차단 해제":"차단")+"하시겠습니까?",
                    successBtnTitle:reject ? "차단 해제하기":"차단하기",
                    cancleBtnTitle:"취소",
                    successAction:()=>{
                        rejectSubmit({
                            reject_id:comment_id,
                            reject_type:"comment",
                        });
                    },
                })
            },
        });
    }

    const rejectSubmit = ApiCommonWriterReject({
        onSuccess:(_data)=>{
            if(_data?.status === true){
                const reject = _data?.result?.reject;

                const tmp = [];
                data.map((item,index)=>{
                    if(item?.comment_id == rejectReplyId){
                        item.reject = reject;
                    }
                    tmp.push(item);
                })
                // rejectReplyId
                setData(tmp);

                if(reject){
                    setState.setToast({
                        title:"차단하였습니다.",
                    });
                } else {
                    setState.setToast({
                        title:"차단 해제하였습니다.",
                    });
                }
            }
        },
        onError:(e)=>{

        },
    });


    const commentLikeSubmit = ApiCommentCommentLike({
        onSuccess:(_data)=>{
            if(_data?.status === true){
                const tmp = [];

                data.map((item)=>{
                    if(item?.comment_id === parseInt(likeCommentId,10)){
                        
                        item.likes = _data?.result?.likes;
                        item.like_status = _data?.result?.like_status;
                    }
                    tmp.push(item);
                })

                setData(tmp);
                setLikeCommentId(null);
            }
        },
        onError:(e)=>{

        },
    });

    const handlerCommentLike = (comment_id)=>{
        commentLikeSubmit({
            comment_id:comment_id,
        });
    }

    const replyAddCommentUpdate = (e)=>{
        const value = e.target.value;
        if(value.length > 300){
            // handlerShowToast({
            //     title:"글자수는 300자 이내로 작성해 주세요.",
            // })
            setState.setToast({
                title:"글자수는 300자 이내로 작성해 주세요."
            });
            return;
        }
        setAddComment(value);
    }

    const deleteSubmit = ApiCommentCommentDelete({
        onSuccess:(_data)=>{
            // console.log(_data);
            if(_data?.status === true){
                // handlerShowToast({
                //     title:"삭제되었습니다."
                // });

                setState.setToast({
                    title:"삭제되었습니다."
                });

                const n_data = [];
                data.map((item)=>{
                    if(item?.comment_id == commentId){
                        n_data.push({
                            ...item,
                            delete:true,
                        });
                    } else {
                        n_data.push(item);
                    }
                });
                setData(n_data);
            }
        },
        onError:(e)=>{
            console.log(e);
        }
    });

    const [Modal, showModal] = useConfirmModal({
        title:"삭제 안내",
        content:"삭제한 글은 복구할 수 없습니다.",
        btnTitle:"삭제하기",
        action:()=>{
            deleteSubmit({
                comment_id:commentId
            })
        }
    });

    const showModalWithSelectCommendId = (e)=>{
        const commendId = e.target.getAttribute('data-commend-id');
        setCommentId(commendId);
        showModal();
    }

    const handlerMoveReply = (id) => {
        // console.log(id);
        // navigate("/mypage/comment/"+id);
        //type, bid, cid
        navigate("/view/"+type+"/"+cid+"/comment/"+id);
    };

    const commentWriteSubmit = ApiCommentCommentWrite({
        onSuccess:(data)=>{
            if(data?.status === true){
                alert(null,'등록 되었습니다.',()=>{
                    // window.location.reload();
                    dataInit();
                    dataSubmit();
                    setAddComment("");
                });
                return;
            }
            switch(data?.code){
                case "403":
                    setState.setToast({title:"로그인이 필요한 서비스입니다."});
                    break;
                case "400":
                    switch(data?.code_msg){
                        case "invalid_parameter_content_length":
                            setState.setToast({title:"300글자 이내로 작성해 주세요."});
                            break;
                        case "found_forbidden_words":
                            setState.setToast({title:"비방/욕설글은 작성하실 수 없습니다."});
                            break;
                        case "breach_of_policy":
                            setState.setToast({title:"댓글 작성할 수 없습니다."});
                            break;
                        case "abusing_user":
                            setState.setToast({title:"한번에 많은 글을 작성할 수 없습니다.\n잠시 후 다시 시도해 주세요."});
                            break;
                    }
                    break;
                default:
                    setState.setToast({title:"댓글 등록에 실패했습니다.\n잠시 후 다시 시도해 주세요."});
                    break;
            }
            
        },
        onError:(e)=>{
            setState.setToast({title:"통신에 실패하였습니다."});
        }
    });

    const writeSubmit = ()=>{
        commentWriteSubmit({
            bid:bid,
            cid:cid,
            type:type,
            content:addComment
        });
    }

    useEffect(()=>{
        dataSubmit();
        
    },[]);

    useEffect(()=>{
        if(likeCommentId){
            handlerCommentLike(likeCommentId);
        }
    },[likeCommentId]);

    // console.log(data);

    return (
        <>
            
            <div className="viewer-content comment-content">
                <HeaderComment 
                    data={data}
                    type={type}
                    bid={bid}
                    chapter_title={title}
                />
                {/* <Header useMobile={false} pcTop={"-64px"} /> */}
                
                {/* <!-- container --> */}
                
                <section className={"section m_wrap content_body temp_list " + style['section'] + " " +style['m_wrap']} style={{marginTop:(isMobile?"0px":"64px")}}>
                    <SubNav 
                        name={"댓글내역"+(paging?.total_cnt ? "("+paging?.total_cnt+")" : "")+""}
                        usePc={true} 
                        useMobile={true} 
                        usePcRightBtns={true}
                        useBtnPrev={true}
                        useBtnPcPrev={true}
                        useBtnHome={false}

                        setTop={'0px'} 
                        setPcTop={'64px'} 
                        />
                    
                    {data != null && data?.length === 0 
                    ? <p className={"no_data color-txt-04 body-02-long " + (!isMobile ? "large":"")}>아직 등록된 댓글이 없습니다.<br />작품을 감상 후 댓글을 작성해보세요.</p>     
                    : <ul className={"list comment_list " + style['list'] + " " + style['comment_list']} ref={contentRef}>

                        {data && Array.isArray(data) ? data.map((item,index)=>{
                            if(item?.delete === true){
                                return (
                                    <li key={index}>
                                        <p className="delete_list color-txt-muted body-03-long">작성자가 글을 삭제하였습니다.</p>
                                    </li>
                                )
                            }
                            
                            return (
                                <li key={index}>
                                    <div className="comment_top">
                                        <div className="profile_box left">
                                            <span className="profile radius-full">
                                                <img src={item?.profile} />
                                            </span>
                                        </div>
                                        <div className={"right comment_box " +style['comment_box']}>
                                            <div className={"top_con " + style['top_con']} >
                                                <div className="badge_list">
                                                    {item?.badge?.best === true ? <p className="badge best_badge color-btn-01 bg border border-soild border-line radius-full">
                                                        <span className="white-always txt">Best</span>
                                                    </p> : null}
                                                    {item?.badge?.writer_choice === true ? <p className="badge love_badge color-state-point-01 bg border border-soild border-line radius-full">
                                                        <span className="white-always txt">작가감동♥︎</span>
                                                    </p> : null}
                                                    {item?.badge?.my === true ? <p className="badge my_badge border-line border-soild border color-btn-01 radius-full">
                                                        <span className="color-btn-01 txt">MY</span>
                                                    </p> : null}
                                                </div>
                                                <div className="name text_overflow title-01-b-long color-txt-normal">{item?.nickname}</div>
                                                <div className="date title-sub-02-m color-txt-muted">{item?.display_time}</div>
                                            </div>
                                            <div className="desc color-txt-02 body-03-long">
                                                <NewlineToBr text={item?.reject ? "차단한 사용자 입니다.":(item?.content ?? "")} />
                                            </div>
                                            <div className="info">
                                                <span className="title body-01-long color-txt-05 text_overflow">{he.decode( item?.book_title ?? "" )} {he.decode( item?.chapter_title ?? "" )}</span>
                                                <span className="body-01-long color-txt-05">- {item?.chapter_no}화</span>
                                            </div>
                                            <div className="btns bottom_con">
                                                <div className="left">
                                                    {/* <!-- 좋아요버튼 클릭시 className="click" 추가--> */}
                                                    <button 
                                                        type="button" 
                                                        className={"button btn_03 medium outlined label_on round radius-full " + (item?.like_status === true?"click":"")} 
                                                        id="likeBtn"
                                                        data-my={item?.badge?.my ? "true" : "false"}
                                                        data-comment_id={item?.comment_id}
                                                        onClick={(e)=>{
                                                            const my = e.currentTarget.getAttribute("data-my");
                                                            const comment_id = e.currentTarget.getAttribute("data-comment_id");

                                                            if(my === "true"){
                                                                setState.setToast({
                                                                    title:"자신의 글에는 좋아요를 누를 수 없습니다."
                                                                });
                                                            } else {
                                                                setLikeCommentId(comment_id)
                                                            }
                                                        }}
                                                        >
                                                        <span className="btn-03 color-txt-04 text">{item?.likes}</span>
                                                    </button>
                                                    {/* <!-- 답글 버튼 --> */}
                                                    <button 
                                                        type="button" 
                                                        onClick={()=>{handlerMoveReply(item?.comment_id)}} 
                                                        className={
                                                            item?.reply_status === true?
                                                            "button btn_01 medium outlined label_on round radius-full after"
                                                            : "button btn_03 medium outlined label_on round radius-full before"
                                                        }
                                                        id="replyBtn">
                                                        <span className="btn-03 color-txt-04 text">{item?.replies === 0 ? "답글달기" : item?.replies}</span>
                                                    </button>
                                                </div>
                                                <div className="right">
                                                    {item?.delete_flag === true ?
                                                        <button type="button" id="deleteBtn" data-commend-id={item?.comment_id} onClick={showModalWithSelectCommendId} >
                                                            <span className="body-01-long color-txt-05">삭제</span>
                                                        </button>
                                                    : 
                                                        <>
                                                            {/* <!-- 신고/차단버튼 --> */}
                                                            <button 
                                                                type="button" 
                                                                id="reportBtn"
                                                                data-comment_id={item?.comment_id}
                                                                data-chapter_id={item?.chapter_id}
                                                                data-reject={item?.reject ? "true":"false"}
                                                                data-nickname={item?.nickname}
                                                                onClick={(e)=>{
                                                                    const comment_id = e.currentTarget.getAttribute("data-comment_id");
                                                                    const chapter_id = e.currentTarget.getAttribute("data-chapter_id");
                                                                    const reject = e.currentTarget.getAttribute("data-reject");
                                                                    const nickname = e.currentTarget.getAttribute("data-nickname");
                                                                    handlerRejectOrDeclare(chapter_id, comment_id, reject === "true", nickname);
                                                                }}
                                                                ></button>
                                                        </>
                                                    }
                                                    
                                                    {/* <button type="button" id="reportBtn"></button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            )
                        }):null}
                    </ul>}

                    <div className={"write_box " + style['write_box']}>
                        <div className={"left " + style['left']}>
                            <textarea 
                                placeholder="아름다운 언어로 소통해주세요. 이모지, 이모티콘은 등록 되지 않습니다." 
                                className="body-03-long color-txt-02 radius-sm color-bg-02 color-border-01 border-line border-soild"
                                value={addComment}
                                onChange={replyAddCommentUpdate}
                                ></textarea>
                        </div>
                        <div className="right">
                        <p className="num caption-02 color-txt-04">{addComment?.length ?? 0}</p>
                        <button 
                            type="button" 
                            className="button btn_01 medium filled label_on radius-full"
                            onClick={writeSubmit}
                            >
                            <span className="txt white-always btn-03">등록</span>
                        </button>
                        </div>
                    </div>
                    <Footer />
                </section>

            </div>
            {/* <!-- container --> */}

            
            <Modal />
            <AlertModal />
            <ConfirmModal />
            <ConfirmModal2 />
        </>
    );
}




export default ViewerComment;