import * as types from 'constant/ActionType';

export const actionSetToken = token => ({ type: types.SET_TOKEN, token: token });
export const actionDelToken = token => ({ type: types.DEL_TOKEN, token: token });

export const actionSetMemberInfo = member_info => ({ type: types.SET_MEMBER_INFO, member_info: member_info });
export const actionDelMemberInfo = member_info => ({ type: types.DEL_MEMBER_INFO, member_info: member_info });

export const actionSetDot = dot => ({ type: types.SET_DOT, dot: dot });

export const actionSetViewerInfoFont = viewer_font => ({ type: types.SET_VIEWER_INFO_FONT, viewer_font: viewer_font });
export const actionSetViewerInfoFontSize = viewer_font_size => ({ type: types.SET_VIEWER_INFO_FONT_SIZE, viewer_font_size: viewer_font_size });
export const actionSetViewerInfoLineHeight = viewer_line_height => ({ type: types.SET_VIEWER_INFO_LINE_HEIGHT, viewer_line_height: viewer_line_height });
export const actionSetViewerInfoSideMargin = viewer_side_margin => ({ type: types.SET_VIEWER_INFO_SIDE_MARGIN, viewer_side_margin: viewer_side_margin });
export const actionSetViewerInfoStyle = viewer_style => ({ type: types.SET_VIEWER_INFO_STYLE, viewer_style: viewer_style });
export const actionSetViewerInfoTheme = viewer_thmeme => ({ type: types.SET_VIEWER_INFO_THEME, viewer_thmeme: viewer_thmeme });
export const actionSetViewerInfoImageNone = viewer_image_none => ({ type: types.SET_VIEWER_INFO_IMAGE_NONE, viewer_image_none: viewer_image_none });
export const actionSetViewerInfoRun = viewer_run => ({ type: types.SET_VIEWER_INFO_RUN, viewer_run: viewer_run });


export const actionKeywordSaveMode = keyword_save_mode => ({ type: types.SET_KEYWORD_SAVE_MODE, keyword_save_mode: keyword_save_mode });

export const actionSetSystemMode = system_mode => ({ type: types.SET_SYSTEM_MODE, system_mode: system_mode });

export const actionSetAdultSwitch = adult_switch => ({ type: types.SET_ADULT_SWITCH, adult_switch: adult_switch });

export const actionSetAutoLogin = auto_login => ({ type: types.SET_AUTO_LOGIN, auto_login: auto_login });

export const actionSetAppBanner = app_banner => ({ type: types.SET_APP_BANNER, app_banner: app_banner });

export const actionSetSaveId = saveid => ({ type: types.SET_SAVE_ID, saveid: saveid });
export const actionSetSaveIdCheck = saveid_check => ({ type: types.SET_SAVE_ID_CHECK, saveid_check: saveid_check });
export const actionSetLastSocialLoginTryPath = last_social_login_try_path => ({ type: types.SET_LAST_SOCIAL_LOGIN_TRY_PATH, last_social_login_try_path: last_social_login_try_path });
export const actionSetLastSocialLoginTryType = last_social_login_try_type => ({ type: types.SET_LAST_SOCIAL_LOGIN_TRY_TYPE, last_social_login_try_type: last_social_login_try_type });
export const actionSetLastSocialLoginType = last_social_login_type => ({ type: types.SET_LAST_SOCIAL_LOGIN_TYPE, last_social_login_type: last_social_login_type });
export const actionSetLastSocialLoginDate = last_social_login_date => ({ type: types.SET_LAST_SOCIAL_LOGIN_DATE, last_social_login_date: last_social_login_date });

export const actionSetConnectSocialLoginType = connect_social_login_type => ({ type: types.SET_CONNECT_SOCIAL_LOGIN_TYPE, connect_social_login_type: connect_social_login_type });

//cert
export const actionSetCertReturnPath = cert_return_path => ({ type: types.SET_CERT_RETURN_PATH, cert_return_path: cert_return_path });

//toolTip
export const actionSetToolTipLogin = tooltip_login => ({ type: types.SET_TOOLTIP_LOGIN, tooltip_login: tooltip_login });
export const actionSetToolTipSearch = tooltip_search => ({ type: types.SET_TOOLTIP_SEARCH, tooltip_search: tooltip_search });



//toast
export const actionSetShowGiftToast = show_gift_toast => ({ type: types.SET_SHOW_GIFT_TOAST, show_gift_toast: show_gift_toast });

