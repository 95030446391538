import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import NewlineToBr from '../content/NewlineToBr';

function useInfoModal(data){

    const btnTitle = data?.btnTitle || "확인";

    const [title, setTitle] = useState(false);
    const [content, setContent] = useState(false);
    const [action, setAction] = useState({});

    const [isCloseAction, setIsCloseAction] = useState(false);

    const [showModal, setShowModal] = useState(false);

    const handleClose = () => {
        setShowModal(false);
        if(action?.action){
            action?.action();
            setAction({
                ...action,
                action:null,
            });
        }
    }

    const handlerOnlyClose = ()=>{
        if(isCloseAction){
            if(action?.action){
                action?.action();
                setAction({
                    ...action,
                    action:null,
                });
            }
        }
        setShowModal(false);
    }

    const handleShow = (msg1, msg2, _action, _isCloseAction) => {

        if(_action){
            setAction({
                ...action,
                action:_action,
            });
        }
        const msg = (msg2 && msg2 != "")?msg2:msg1;
        const _title = (msg2 && msg2 != "")?msg1:false;
        
        if(_title){
            setTitle(_title);
        }

        if(msg && msg != ""){
            setContent(msg);
        } else {
            setContent(false);
        }

        if(_isCloseAction === true){
            setIsCloseAction(true);
        } else {
            setIsCloseAction(false);
        }

        setShowModal(true);
    }

    const handleSelect = () => {
        handleClose();
    }


    const ModalTmpl =()=>{ 
        return (
        <>
            <Modal 
                // className="black_opacity-60" 
                backdropClassName="black_opacity-60 opacity-10"
                dialogClassName="modal-dialog-centered"
                contentClassName="radius-xxl color-bg-modal"
                show={showModal}
                animation={false} 
                onHide={handlerOnlyClose}
                >
                <Modal.Body>
                    {title ? <div className="title title-02-b color-txt-normal">{title}</div> : null}
                    {content ? <div className="desc body-03-long" style={{textAlign:"left"}}><NewlineToBr text={content} /></div> : null}
                    {/* <div className="select_box">
                    {optionElements}
                    </div> */}
                </Modal.Body>
                <Modal.Footer>
                    {/* <button type="button" className="radius-sm btn-04 txt btn_01 large label_on filled button" onClick={handleSelect}>{btnTitle}</button> */}
                    <button type="button" className="radius-xs btn-03 txt btn_03 medium label_on filled button" onClick={handleClose}>닫기</button> 
                </Modal.Footer>
            </Modal>
        </>
    )};
    
    return [ModalTmpl, handleShow];
}


export default useInfoModal;