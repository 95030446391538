import { ApiMypageMyLevelInfo } from 'api/Mypage';
import { runPopupOrder } from 'utils/Common';
import Header from 'component/common/header/Header';
import Footer from 'component/common/footer/Footer';

import SubNav from "component/common/nav/SubNav";

import style from '../style/Mypage.module.scss';

import img_rating_label_normal from 'assets/images/rating_label_normal.svg';
import img_rating_label_vip from 'assets/images/rating_label_vip.svg';
import img_rating_label_vip_silver from 'assets/images/rating_label_vipSilver.svg';
import img_rating_label_vip_gold from 'assets/images/rating_label_vipGold.svg';
import img_rating_label_rvip from 'assets/images/rating_label_rVip.svg';
import { useEffect, useState } from 'react';


function getImgLevel(level){
    switch(level){
        default:
        case "normal": return img_rating_label_normal;
        case "vip": return img_rating_label_vip;
        case "vip silver":
        case "silver": return img_rating_label_vip_silver;
        case "vip gold":
        case "gold": return img_rating_label_vip_gold;
        case "rvip": return img_rating_label_rvip;
    }
}


function Benefit(){

    const [benefit, setBenefit] = useState(null);

    const benefitSubmit = ApiMypageMyLevelInfo({
        onSuccess:(data)=>{
            if(data?.status === true){
                setBenefit(data?.result);
            }
        },
        onError:(e)=>{

        }
    });

    useEffect(()=>{
        benefitSubmit();
    },[]);


    if(benefit === null){
        return (
            <></>
        )
    }

    return (
        <>
            <Header useMobile={false} pcTop={"-64px"}/>
            <SubNav 
                name={"등급별 혜택안내"}
                usePc={true} 
                useMobile={true} 
                usePcRightBtns={true}
                useBtnPrev={true}
                useBtnPcPrev={true}
                useBtnHome={false}

                setTop={'0px'} 
                setPcTop={'0px'} 
                />
            {/* <!-- container --> */}
            <div id="container">
                <section className={"myBenefit wrapper " + style['myBenefit'] + " " + style['wrapper'] + " " + style['section']}>
                    <div className={"tit_con section clearfix " + style['tit_con'] }>
                        <div className={"left " + style['left']}>
                            <p className={"title body-01 color-txt-muted " + style['title']}><span className="title-02-b color-txt-normal">현재 등급 </span>매월 1일, 최근 3개월 결제금액에 따라 결정됩니다.</p>
                        </div>
                    </div>
                    <div className={"my_benefit section " + style['my_benefit']} >
                        <div className={"my_grade " + style['my_grade']}>
                            <p className="title-sub-02-m color-txt-02">{(new Date()).getFullYear().toString().slice(-2)}년 {((new Date()).getMonth() + 1).toString().padStart(2, '0')}월 회원님의 등급은</p>
                            <p className={"rating_label large " +  style['rating_label'] + " " + style['large']}>
                                <span className="normal">
                                    <img src={getImgLevel(benefit?.now_level?.toLowerCase())} className="Light_label" />
                                    {/* <img src="/images/rating_label_normal-DarkMode.svg" className="Dark_label" /> */}
                                </span>
                                {/* <!-- <span className="vip">
                                    <img src="/images/rating_label_vip.svg" className="Light_label" />
                                    <img src="/images/rating_label_vip-DarkMode.svg" className="Dark_label" />
                                </span>
                                <span className="vipSilver">
                                    <img src="/images/rating_label_vipSilver.svg">
                                </span>
                                <span className="vipGold">
                                    <img src="/images/rating_label_vipGold.svg">
                                </span>
                                <span className="rVip">
                                    <img src="/images/rating_label_rVip.svg" className="Light_label">
                                    <img src="/images/rating_label_rVip-DarkMode.svg" className="Dark_label">
                                </span> --> */}
                            </p>
                            <p className="body-01 color-txt-muted">(다음달 예상 등급은 <span>{benefit?.expect_level}</span>)</p>
                        </div>
                        <div className={"grade_up top_con radius-sm red_opacity-05 bg " + style['grade_up'] + " " + style['top_con'] + " " + style['bg']}>
                            <div className={"left " + style['left']}>
                                <p className="title title-01-m-long color-txt-02"><span className="grade title-01-b-long">{benefit?.next_level}</span>로 업그레이드 하기 위해선?</p>
                                <p className={"money body-02-long color-btn-01 txt " + style['money']}>잔여 구매 금액 : <span>{benefit?.amount_diff?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>원</p>
                            </div>
                            <div className={"right " + style['right']}>
                                {/* <button 
                                    type="button" 
                                    className="button btn_01 medium label_on filled medium radius-full"
                                    onClick={runPopupOrder}
                                    ><span className="white-always txt btn-03">충전하기</span></button> */}
                            </div>
                        </div>
                    </div>
                    <div className={"benefit_list section " + style['benefit_list'] }>
                        <div className={"tit_con clearfix " + style['tit_con']}>
                            <div className={"left " + style['left']}>
                                <p className={"title body-01 color-txt-muted " + style['title']}><span className="title-02-b color-txt-normal">등급별 조건 및 혜택 </span>자동결제 금액도 포함됩니다.</p>
                            </div>
                        </div>
                        <ul className={"list " + style['list']}>
                            <li className="border-bottom-line border-soild color-border-01">
                                <div className={"grid_tit " + style['grid_tit']}>
                                    <p className={"rating_label small " + style['rating_label'] + " " + style['small']}>
                                        <span className={"vip " + style['vip']}>
                                            <img src={img_rating_label_vip} className="Light_label" />
                                            {/* <img src="/images/rating_label_vip-DarkMode.svg" className="Dark_label" /> */}
                                        </span>
                                    </p>
                                    <p className={"title title-sub-02-m color-btn-01 txt " + style['title']}>최근 3개월 결제금액 5만원 이상</p>
                                </div>
                                <div className={"grade_benefit " + style['grade_benefit']}>
                                    <p className="body-02-long color-txt-03">· 매월 무료쿠폰 10개 제공</p>
                                    <p className="body-02-long color-txt-03">· 프리미엄 앰블럼 증정(1회)</p>
                                </div>
                            </li>
                            <li className="border-bottom-line border-soild color-border-01">
                                <div className={"grid_tit " + style['grid_tit']}>
                                    <p className={"rating_label small " + style['rating_label'] + " " + style['small']}>
                                        <span className={"vipSilver " + style['vipSilver']}>
                                            <img src={img_rating_label_vip_silver} />
                                        </span>
                                    </p>
                                    <p className={"title title-sub-02-m color-btn-01 txt " + style['title']}>최근 3개월 결제금액 9만원 이상</p>
                                </div>
                                <div className={"grade_benefit " + style['grade_benefit']}>
                                    <p className="body-02-long color-txt-03">· 매월 무료쿠폰 20개 제공</p>
                                    <p className="body-02-long color-txt-03">· 프리미엄 앰블럼 증정(1회)</p>
                                </div>
                            </li>
                            <li className="border-bottom-line border-soild color-border-01">
                                <div className={"grid_tit " + style['grid_tit']}>
                                    <p className={"rating_label small " + style['rating_label'] + " " + style['small']}>
                                        <span className="vipGold">
                                            <img src={img_rating_label_vip_gold} />
                                        </span>
                                    </p>
                                    <p className={"title title-sub-02-m color-state-point-01 txt " + style['title']}>최근 3개월 결제금액 16만원 이상</p>
                                </div>
                                <div className={"grade_benefit " + style['grade_benefit']}>
                                    <p className="body-02-long color-txt-03">· 매월 무료쿠폰 40개 제공</p>
                                    <p className="body-02-long color-txt-03">· 프리미엄 앰블럼 증정(1회)</p>
                                </div>
                            </li>
                            <li>
                                <div className={"grid_tit " + style['grid_tit']}>
                                    <p className={"rating_label small " + style['rating_label'] + " " + style['small']}>
                                        <span className="rVip">
                                            <img src={img_rating_label_rvip} className="Light_label" />
                                            {/* <img src="/images/rating_label_rVip-DarkMode.svg" className="Dark_label" /> */}
                                        </span>
                                    </p>
                                    <p className={"title title-sub-02-m color-state-point-02 txt " + style['title']}>최근 3개월 결제금액 29만원 이상</p>
                                </div>
                                <div className={"grade_benefit " + style['grade_benefit']}>
                                    <p className="body-02-long color-txt-03">· 매월 무료쿠폰 70개 제공</p>
                                    <p className="body-02-long color-txt-03">· 프리미엄 앰블럼 증정(1회)</p>
                                </div> 
                            </li>
                        </ul> 
                    </div>
                    <div className={"info_txt_box section " + style['info_txt_box'] + " " + style['section']}>
                        <p className={"title title-02-b color-txt-04 " + style['title']}>북팔 VIP 제도 안내</p>
                        <ul className={"desc_list body-02-long color-txt-05 " + style['desc_list']}>
                            <li>최근 3개월 결제 금액을 바탕으로 혜택을 드립니다. </li>
                            <li>등급은 매월 1일 산정되며 1개월간 등급이 유지됩니다.</li>
                            <li>결제금액 중 취소나 환불이 발생한 경우 등급이 변동될 수 있습니다. </li>
                            <li>북팔 VIP 회원님들께 드리는 혜택은 사정에 따라 변경될 수 있습니다. </li>
                        </ul>
                    </div>
                </section>
            </div>
            {/* <!-- container --> */}

            <Footer />
        </>
    );
}




export default Benefit;