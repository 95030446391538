import { Link } from 'react-router-dom';
import BookpalLink from 'component/common/custom/BookpalLink';
import style from '../style/Promotion.module.scss';



function Tmpl17({data}){

    if(!data){
        return null;
    }

    if(!data?.item){
        return null;
    }

    if(!data?.item?.DATA || !Array.isArray(data?.item?.DATA)){
        return null;
    }

    return (
        <>
            {/* <!-- 표지 우측 --> */}
            <div className={"template temp_list temp_list_type template_column column_right " + style['template_column']}>
                <ul className={"list " + style['list']}>
                    {data?.item?.DATA.map((item, index)=>{

                        return (
                            <li key={"tmpl17_"+index} className="clearfix">
                                <BookpalLink to={"/content/"+item?.ct_type+"/"+item?.bid} className="radius-sm border-line border-soild color-border-01">
                                    <div className={"info_txt spacing_08 " + style['info_txt']}>
                                        <div className={"title color-txt-normal text_overflow title-book-05-m " + style['title']}>{item?.title}</div>
                                        <div className={"writer color-txt-03 text_overflow"}>{item?.wr_nick}</div>
                                        <div className={"desc body-03-long color-txt-05 text_overflow " + style['desc']}>{item?.md_talk}</div>
                                        <div className={"keyword color-txt-03 btn-03 text_overflow " + style['keyword']}>
                                            {/* <!-- span 태그 무조건 한줄에서 쓰기 --> */}
                                            {item?.key1 ? <span>{item?.key1}</span>:null}
                                            {item?.key2 ? <span>{item?.key2}</span>:null}
                                            {item?.key3 ? <span>{item?.key3}</span>:null}
                                        </div>
                                    </div>
                                    <div className={"cover_img border-soild border-left-line color-border-01 " + style['cover_img']}>
                                        {item?.adult ? <p className={"ic_adult badge_adult_normal"}><img src="/images/ic_adult.svg" /></p> : null}
                                        {/* <img src="../images/thumbnail.jpg" /> */}
                                        <img src={item?.cover} style={{height:"auto"}} />
                                    </div>
                                </BookpalLink>
                            </li>
                        )
                    })}
                    
                </ul>
            </div>
        </>
    )
}



export default Tmpl17;