import { Link, useLocation, useNavigate } from "react-router-dom";
import { isApp, appSetMove, isAndroidApp, isIosApp } from "utils/AppUtil";
import { useCallback } from "react";




function BookpalLink(props){
    let _to = getNbpLink(props.to);

    const navigate = useBookpalNavigate();
    const location = useLocation();
    // appSetMove("", "nbp://"+getNbpLink(to));

    const check = props?.check;
    const replace = props?.replace;
    const next = props?.next ?? false;
    const alert = props?.alert || (()=>{});

    const handlerCheck = (e)=>{
        e.preventDefault();
        e.stopPropagation();
        // console.log(check)
        if(check){
            const view_type = check?.view_type;
            const work_adult = check?.work_adult;
            if(work_adult === true){
                switch(view_type){
                    case "N":
                        alert("알림","작품 감상을 위해 로그인이 필요합니다.",()=>{
                            if(isApp()){
                                if(next && _to){
                                    appSetMove("", "nbp://login?app_return_link="+_to);    
                                    return;
                                }
                                appSetMove("", "nbp://login?app_return_link="+location.pathname);
                                return;
                            }
                            if(next && _to){
                                navigate("/login",{state:{return_path:_to}});  
                                return;
                            }
                            // navigate("/login",{state:{return_path:location.pathname}});
                            navigate("/login",{state:{return_path:location.pathname}});
                        });
                        return false;
                        break;
                    case "C":
                        //작품 감상을 위해 성인 인증이 필요합니다.
                        alert("알림","작품 감상을 위해 성인 인증이 필요합니다.",()=>{
                            if(isApp()){
                                if(next && _to){
                                    appSetMove("", "nbp://cert?app_return_link="+_to);    
                                    return;
                                }
                                appSetMove("", "nbp://cert?app_return_link="+location.pathname);
                                return;
                            }
                            if(next && _to){
                                navigate("/cert?app_return_link="+location.pathname,{state:{return_path:_to}});  
                                return;
                            }
                            navigate("/cert?app_return_link="+location.pathname, {state:{return_path:location.pathname}});
                        })
                        return false;
                        break;
                    case "D":
                        //해당 작품은 성인 작품으로 19세 미만 청소년이 이용할 수 없습니다. 
                        alert("알림","해당 작품은 성인 작품으로 19세 미만 청소년이 이용할 수 없습니다.")
                        return false;
                    default:
                    case "Y":
                        break;
                }
            }
        }
        return true;
    }

    // console.log(props)
    // isAppLink(props.to);
    if(isApp() && isAppLink(_to)){
        return (
            <Link 
                {...props} 
                to={""}
                onClick={(e)=>{
                    e.preventDefault();
                    
                    console.log("handlerCheck1")
                    if(!handlerCheck(e)){
                        console.log("handlerCheck2")
                        return;
                    }
                    // e.stopPropagation();
                    if(props?.to && props?.to != ""){
                        
                        if(isApp()){
                            appSetMove("", "nbp:/"+getNbpLink(props.to))
                            return;
                        }
                        navigate(props.to);
                        return;
                    }

                    if(props.onClick){
                        props.onClick(e);
                    }
                }}
                data-link={props.to}
                >
                {props.children}
            </Link>
        )
    }

    // console.log('asdf');
    return (
        <Link 
            {...props} 
            to={_to} 
            replace={replace}
            data-link={props.to}
            onClick={(e)=>{
                e.preventDefault();
                
                // console.log("handlerCheck1")
                if(!handlerCheck(e)){
                    // console.log("handlerCheck2")
                    return;
                }
                // e.stopPropagation();
                if(props?.to && props?.to != "" && typeof props?.to === "string"){
                    if(props?.to.startsWith("http://") || props?.to.startsWith("https://")){
                        window.location.href=props?.to;
                        return;
                    }
                    if(props?.to.startsWith("nbp://") || props?.to.startsWith("nbp://")){
                        // window.location.href=props?.to;
                        navigate(getNbpLink(props?.to));
                        return;
                    }
                    if(props?.state){
                        navigate(props.to, {state:props?.state, replace:replace});
                        return;
                    }
                    navigate(props.to, {replace:replace});
                    return;
                }

                if(props.onClick){
                    props.onClick(e);
                }
            }}
            // onClick={(e)=>{
                
            //     if(props.onClick){
            //         props.onClick(e);
            //     }
            //     e.preventDefault();
            // }}
            >
            {props.children}
        </Link>
    )
}

// const BookpalLink = ({ to, onClick, children, ...props }) => {
//     const navigate = useNavigate();
  
//     const handleClick = useCallback((event) => {
//       if (to === '/restricted') {
//         event.preventDefault(); // 이동을 막음
//         if (onClick) {
//           onClick(); // 특정 함수 실행
//         }
//       } else {
//         // Link 컴포넌트의 기본 동작을 사용하여 이동
//         // 여기서는 navigate를 직접 호출하지 않고 Link의 기본 동작을 사용
//       }
//     }, [to, onClick]);
  
//     return (
//       <Link to={to} onClick={handleClick} {...props}>
//         {children}
//       </Link>
//     );
// };




export const useBookpalNavigate = ()=>{
    const navigate = useNavigate();
    return (to, options)=>{
        if (typeof to === 'number') {
            navigate(to);
            return;
        }

        // console.log(to);
        if(isApp() && isAppLink(to)){
            // console.log(getNbpLink(to));
            appSetMove("", "nbp:/"+getNbpLink(to));
            return;
        }

        if(options){
            navigate(getNbpLink(to), options);
            return;
        }
        
        if(to){
            navigate(getNbpLink(to));
        }
    }
};



export const getNbpLink = (text) => {
    if (typeof text !== 'string') {
        return text;
    }

    const prefix = 'nbp://';
    if (text.startsWith(prefix)) {
        const _text = "/"+text.slice(prefix.length);
        switch(_text){
            case "/":
            case "/index": 
                return "/";
            default:
                return _text;
        }
    }
    const bookpalPrefix = 'bookpal://';
    if (text.startsWith(bookpalPrefix)) {
        const _text = "/"+text.slice(bookpalPrefix.length);
        switch(_text){
            case "/my_favorite/gift": 
                return "/giftbox";
            default:
                return _text;
                break;
        }
    }

    //http static
    switch(text){
        case "http://notice":
            return "/mypage_menu/help/notice";
        case "http://notice":
            return "/mypage_menu/help/notice";
        case "http://my_info:history":
            return "/mypage/coupon/charge";
        case "http://collectbook": 
            return "/promotion/collectbook";
        default:
            break;
    }

    return text;
};



const isAppLink = (link)=>{
    const _link = getNbpLink(link);

    // console.log(_link);
    if(!_link){
        return false;
    }

    if(
        isIosApp() &&
        (
            _link.startsWith("/novel")
            || _link.startsWith("/toon")
            // || _link.startsWith("/giftbox")
            // || _link.startsWith("/storage/recent")
            || _link.startsWith("/content/novel/")
            || _link.startsWith("/content/toon/")
            || _link.startsWith("/content/epub/")
            || _link.startsWith("/view/")
            || _link.startsWith("/mypage/gift/givelist")
        )
    ){
        return true;
    }

    if(
        isAndroidApp() &&
        (
            _link.startsWith("/novel")
            || _link.startsWith("/toon")
            || _link.startsWith("/giftbox")
            || _link.startsWith("/storage/recent")
            || _link.startsWith("/content/novel/")
            || _link.startsWith("/content/toon/")
            || _link.startsWith("/content/epub/")
            || _link.startsWith("/view/")
        )
    ){
        return true;
    }

    return false;
}



export default BookpalLink;