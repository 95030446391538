import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import { ApiOrderOrderHistory } from 'api/Order';

import Header from 'component/common/header/Header';
import Footer from 'component/common/footer/Footer';

import SubNav from "component/common/nav/SubNav";

import style from '../style/Order.module.scss';





function History(){

    const navigate = useBookpalNavigate();
    const { order_no } = useParams();

    const [history, setHistory] = useState(null);

    const historySubmit = ApiOrderOrderHistory({
        onSuccess:(data)=>{
            if(data?.status === true){
                setHistory(data?.result);
                return;
            }

            alert('시스템에 문제가 있습니다. 다시 시도해 주세요.');
        },
        onError:(e)=>{
            alert('통신에 문제가 있습니다. 다시 시도해 주세요.');
        }
    })

    const handlerBack = () => {
        navigate(-1);
    }

    useEffect(()=>{
        if(!order_no){
            alert('잘못된 접근 입니다.');
            navigate(-1);
            return;
        }

        historySubmit({
            order_no:order_no
        });
    },[]);


    if(history === null){
        return <></>
    }

    return (
        <>
            <Header useMobile={false} />
            <SubNav 
                name={"결제내역"}
                usePc={true} 
                useMobile={true} 
                usePcRightBtns={true}
                useBtnPrev={true}
                useBtnPcPrev={true}
                setTop={'0px'} 
                setPcTop={'64px'} 
                />

            {/* <!-- container --> */}
            <div id="container">
                <section className={"payBox wrapper " + style['payBox'] + " " + style['wrapper']}>
                    <div className={"list_box section " + style['list_box'] + " " + style['section']}>
                        <ul className={"pay_list " + style['pay_list']}>
                            <li>
                                <p className={"s_tit body-02 color-txt-muted " + style['s_tit']}>결제 내용</p>
                                <p className={"desc body-04-long color-txt-normal " + style['desc']}>{history?.order_type}</p>
                            </li>
                            <li>
                                <p className={"s_tit body-02 color-txt-muted " + style['s_tit']}>주문 번호</p>
                                <p className={"desc body-04-long color-txt-normal " + style['desc']}>{history?.oid}</p>
                            </li>
                            <li>
                                <p className={"s_tit body-02 color-txt-muted " + style['s_tit']}>상품 가격</p>
                                <p className={"desc body-04-long color-txt-normal " + style['desc']}>{history?.price}원</p>
                            </li>
                            <li>
                                <p className={"s_tit body-02 color-txt-muted " + style['s_tit']}>충전 쿠폰</p>
                                <p className={"desc body-04-long color-txt-normal " + style['desc']}>{history?.o_coupon}개</p>
                            </li>
                            <li>
                                <p className={"s_tit body-02 color-txt-muted " + style['s_tit']}>추가 쿠폰</p>
                                <p className={"desc body-04-long color-txt-normal " + style['desc']}>{history?.bonus_coupon}개</p>
                            </li>
                            <li>
                                <p className={"s_tit body-02 color-txt-muted " + style['s_tit']}>결제 수단</p>
                                <p className={"desc body-04-long color-txt-normal " + style['desc']}>{history?.type}</p>
                            </li>
                            <li>
                                <p className={"s_tit body-02 color-txt-muted " + style['s_tit']}>승인 번호</p>
                                <p className={"desc body-04-long color-txt-normal " + style['desc']}>{history?.tid}</p>
                            </li>
                            <li>
                                <p className={"s_tit body-02 color-txt-muted " + style['s_tit']}>결제 일시</p>
                                <p className={"desc body-04-long color-txt-normal " + style['desc']}>{history?.o_date_insert}</p>
                            </li>
                            {/* {history?.off_date ? 
                                <li>
                                    <p className={"s_tit body-02 color-txt-muted " + style['s_tit']}>해지 일시</p>
                                    <p className={"desc body-04-long color-btn-01 txt " + style['desc']}>{history?.off_date}</p>
                                </li>
                            :null} */}
                        </ul>
                        {history && history?.r_id ? 
                            <>
                                {/* <!-- 환불내역--> */}
                                <ul className={"refund_list radius-sm color-bg-02 " + style['refund_list']}>
                                    <li>
                                        <p className={"s_tit body-02 color-state-danger txt " + style['s_tit']}>환불 금액</p>
                                        <p className={"desc title-02-b-long color-txt-normal " + style['desc']}>{history?.r_price ?? 0}원</p>
                                    </li>
                                    <li>
                                        <p className={"s_tit body-02 color-state-danger txt " + style['s_tit']}>충전 취소 쿠폰</p>
                                        <p className={"desc title-02-b-long color-txt-normal " + style['desc']}>{history?.r_coupon ?? 0}개</p>
                                    </li>
                                    <li>
                                        <p className={"s_tit body-02 color-state-danger txt " + style['s_tit']}>환불일</p>
                                        <p className={"desc title-02-b-long color-txt-normal " + style['desc']}>{history?.r_date_insert}</p>
                                    </li>
                                </ul>
                            </>
                        : null}
                        
                    </div>
                    <div className={"button_box section " + style['button_box'] + " " + style['section']}>
                        <button 
                            type="button" 
                            onClick={handlerBack}
                            className="button btn_01 filled radius-full label_on large">확인하고 뒤로가기</button>
                    </div>
                    {/* <!-- 정기충전, 스마트충전 결제일때 해지신청 버튼 나오게--> */}
                    {/* <!-- <div className="request_box section">
                        <div className="left">
                            <p className="title title-02-b-long color-txt-normal">해지 신청</p>
                        </div>
                        <div className="right">
                            <button type="button" className="button btn_01 medium label_on outlined radius-full"><span className="btn-03 color-btn-01 txt">신청하기</span></button>
                        </div>
                    </div> --> */}
                    <div className="info_txt_box section">
                        <p className="title title-02-b color-txt-04">안내</p>
                        {/* <!-- 안내사항 --> */}
                        <ul className="desc_list body-02-long color-txt-05">
                            <li>신용카드, 계좌이체 결제 취소 : 영업일 기준 3~5일이 소요됩니다.</li>
                            <li>휴대폰 결제 취소 : 당월 결제 취소만 가능합니다.</li>
                            <li>Google Play 앱 내 결제로 충전한 쿠폰은 결제일로부터 7일 이내 고객센터에서 환불이 가능합니다.</li>
                            <li>앱 스토어에서 결제하신 경우, 애플 정책에 의해 결제 정보가 제공되지 않아 구매 확인 후 애플 측으로 직접 환불을 요청해 주셔야 합니다.</li>
                            <li>쿠폰에 관련하여 궁금하신 점은 <span className="color-txt-03">고객센터 &gt; FAQ</span>를 참고해 주세요.</li>
                        </ul>
                        {/* <!-- 정기충전일때 안내사항 --> */}
                        {/* <!-- <ul className="desc_list body-02-long color-txt-05">
                            <li>구매일로부터 1개월마다 이용권 금액이 설정된 결제수단(휴대폰/신용카드)으로 자동결제됩니다.</li>
                            <li>결제하신 북팔 쿠폰의 일부를 사용하시면 결제를 취소할 수 없습니다.</li>
                            <li>정기충전 해지가 완료되어도 결제된 내역 자동으로 환불 되지 않습니다. 환불 요청 시, 결제 관련 공지를 확인해 주시기 바랍니다.</li>
                            <li>정기충전  신청 후 14일 이후에 자동 결제 해지가 가능합니다.</li>
                            <li>결제 예정일 전날과 예정일에는 자동 결제 해지가 불가합니다.</li>
                            <li>회사 사정에 따라 추가 쿠폰은 변경, 중단될 수 있습니다.</li>
                        </ul> --> */}
                        {/* <!-- 스마트충전일때 안내사항 --> */}
                        {/* <!-- <ul className="desc_list body-02-long color-txt-05">
                            <li>신청 즉시 결제되며, 보유 쿠폰이 설정한 개수 미만일 경우 자동 충전됩니다.</li>
                            <li>회사 사정에 따라 추가쿠폰은 변경, 중단 될 수 있습니다.</li>
                            <li>결제하신 북팔 쿠폰의 일부를 사용하시면 결제를 취소할 수 없습니다.</li>
                        </ul> --> */}
                    </div>
                </section>
            </div>
            {/* <!-- container --> */}

            <Footer />
        </>
    );
}


export default History;