import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import { ApiAuthFindId } from 'api/Auth';

import SubNav from "component/common/nav/SubNav";

import style from '../style/User.module.scss';

function FindId(){

    const [isSubmit, setIsSubmit] = useState(false);
    const [errText, setErrText] = useState("");
    const [findEmail, setFindEmail] = useState("");
    const navigate = useBookpalNavigate(); 


    const handlerEmailChange = (value) => {
        if(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)){
            setIsSubmit(true);
            setErrText("");
            return;
        }
        setIsSubmit(false);
        setErrText("이메일 주소를 입력해 주세요.");
    }

    const findIdSubmit = ApiAuthFindId({
        onSuccess:(data)=>{
            console.log(data);

            if(data?.status === true){
                //alert("발송되었습니다.");
                navigate("/find_id_complate?mail=" + findEmail, {replace:true});
                return;
            }

            // alert(data?.message);
            setErrText(data?.message);
        },
        onError:()=>{
            // setErrText("통신이 실패하였습니다. 다시 시도해 주세요.");
            alert("통신이 실패하였습니다. 다시 시도해 주세요.");
        }
    });


    const handlerSubmit = async () => {
        if(!isSubmit) return;

        await findIdSubmit({
            email:findEmail,
            login_url: process.env.REACT_APP_BASE_URL + "/login",
            find_pw_url: process.env.REACT_APP_BASE_URL + "/find_pw",
        });
    }


    return (
        <>
            <SubNav 
                name="아이디 찾기" 
                usePc={false} 
                useMobile={true} 
                setTop={'0px'} 
                useBtnPrev={true} 
            />

            {/* <!-- container --> */}
            <div id="container" className={"wrapper " + style['container']}>
                <section className={"find_box find_id " + style['find_box'] + " " + style['find_id']}>
                    <p className={"title color-txt-normal title-04 " + style['title']}>아이디 찾기</p>
                    <div className={"area_box color-bg-01 radius-lg color-border-01 border-soild border-line " + style['area_box']}>
                        <div className={"logo_title " + style['logo_title']}>
                            <p className={"logo " + style['logo']}></p>
                            <p className={"body-01 color-txt-muted label_txt " + style['label_txt']}>당신의 상상이 이야기가 되는 곳</p>
                        </div>
                        <ul className={"input_box " + style['input_box']}>
                            {/* <!-- 입력한 이메일이 유효한 값이 아닐 경우 error_text 추가 --> */}
                            <li className={errText != "" ? "error_text" : ""}>
                                <div className={"text_input border-line border-soild radius-sm color-bg-01 color-border-02"}>
                                    <input 
                                        type="email" 
                                        placeholder="이메일 주소를 입력해 주세요." 
                                        className="color-txt-normal body-03" 
                                        value={findEmail}
                                        onChange={(e)=>{setFindEmail(e.target.value);handlerEmailChange(e.target.value)}}
                                    />
                                    <p className={"right_btns " + style['right_btns']}>
                                        <span className="btnClear"></span>
                                        <span className="Error"></span>
                                    </p>
                                </div>
                                <p className={"bottom_txt caption-02 color-txt-05 " + style['bottom_txt']}>{errText}</p>
                            </li>
                        </ul>
                        <p className={"button_box " + style['button_box']}>
                            {/* <!-- 비활성화일땐  disabled='disabled' --> */}
                            <button 
                                type="button" 
                                className={"radius-sm btn-04 txt btn_01 large label_on filled button " + style['button']}
                                disabled={isSubmit ? "":"disable"}
                                onClick={handlerSubmit}
                            >확인</button> 
                        </p>
                        <p className={"info_txt color-txt-muted body-01-long " + style['info_txt']}>
                            문의사항이 있으시면 아래 주소로 문의 바랍니다. <br />
                            bookpal@bookpal.co.kr
                        </p>
                    </div>        
                </section>
            </div>
            {/* <!-- container --> */}
        </>
    );
}


export default FindId;