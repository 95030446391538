import { useNavigate } from "react-router-dom";
import { setStorageData } from "utils/Storage";
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import { SetState } from "utils/State";
import { useEffect } from "react";
import { getBookpalLink } from 'utils/BookpalLinkUtil';
import { isApp, appSet, appSetMove, appSetLogout } from "utils/AppUtil";
import { ApiAuthLogout } from "api/Auth";
import useAlertModal from "component/common/modal/useAlertModal";


function Logout(){

    const setState = SetState();
    const navigate = useBookpalNavigate();

    const logoutSubmit = ApiAuthLogout({
        onSuccess:(data)=>{
            if(data?.status === true){
                setState.delToken();

                const key = "use_keyword";
                setStorageData(key, JSON.stringify([]));

                if(isApp()){
                    const appSetData = {};

                    appSet("login", appSetData);

                    // alert("로그아웃","로그아웃 되었습니다.",()=>{
                    //     appSetMove("",getBookpalLink("main"));
                    // });

                    appSetLogout();
                    appSetMove("",getBookpalLink("main"));
                } else {
                    // alert("로그아웃","로그아웃 되었습니다.",()=>{
                    //     navigate("/");
                    // });

                    navigate("/");
                }
                return;
            }
            alert("로그아웃","로그아웃에 실패하였습니다.\n다시 시도해 주세요.",()=>{
                navigate(-1);
            });
        },
        onError:(e)=>{
            alert("로그아웃","통신에 실패하였습니다.\n다시 시도해 주세요.",()=>{
                navigate(-1);
            });
        }
    });

    const [AlertModal, alert] = useAlertModal();

    useEffect(()=>{
        logoutSubmit();
    },[]);

    return (
        <>
            <AlertModal />
        </>
    );
}




export default Logout;