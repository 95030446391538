import { ApiPromotionHotdeal } from 'api/Promotion';
import { useWidth } from 'utils/Common';
import { ImgBadge, ImgDefault, ImgCover } from 'utils/Images';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BookpalLazyLoadImage from 'component/common/custom/BookpalLazyLoadImage';
import { useMemberInfo } from 'utils/State';


import Header from 'component/common/header/Header';
import MobileNav from 'component/common/nav/MobileNav';

import Footer from 'component/common/footer/Footer';
import TopBtn from 'component/common/button/top/TopBtn';

// import GenreHomeHalfBanner from 'component/common/banner/GenreHomeHalfBanner';

import useSelectModal from 'component/common/modal/useSelectModal';
import BigBanner from 'component/common/banner/BigBanner';
import SubNav from 'component/common/nav/SubNav';
import { useEffect, useRef, useState } from 'react';
import SmallBanner from 'component/common/banner/SmallBanner';
import { Link } from 'react-router-dom';
import BookpalLink from 'component/common/custom/BookpalLink';
import useAlertModal from 'component/common/modal/useAlertModal';

import img_default_banner from 'assets/images/small_banner_promotion_03.png';

function HotDeal(){

    const contentRef = useRef(null);

    const memberInfo = useMemberInfo();

    const [banner, setBanner] = useState(null);
    const [isEnd, setIsEnd] = useState(false);

    const [AlertModal, alert] = useAlertModal();

    const modalData = {
        all:"전체",
        romance:"로맨스",
        romancefantasy:"로판",
        bl:"BL",
        fantasy:"판타지",
    }
    const [Modal, modalShow, selectKey] = useSelectModal(modalData, "all");

    const sortModalData = {
        ranking:"인기순",
        update:"업데이트순",
    }
    const [SortModal, sortModalShow, sortSelectKey] = useSelectModal(sortModalData, "ranking");


    const [data, dataSubmit, initData, paging, setData] = ApiPromotionHotdeal({
        data:{
            filter:(selectKey === "all" ? null : selectKey),
            sort:sortSelectKey,
            end:(isEnd === true ? 1 : 0),
        },
        bottomRef:contentRef,
        onSuccess:(data)=>{
            if(data?.status === true){
                const result = data?.result;
                if(result?.paging?.current_page === 1){
                    setBanner(result?.banner);
                }
            }
        },
    });


    useEffect(()=>{
        initData();
        dataSubmit();
    },[selectKey, isEnd, sortSelectKey])

    useEffect(()=>{
        dataSubmit();
    },[])



    return (
        <>
            <Header useMobile={false} />
            {/* <MobileNav /> */}
            {/* <!-- 서브메뉴 --> */}
            <SubNav name="핫딜" 
                useHome={true} 
                usePc={true} 
                useMobile={true}
                useBtnHome={true}

                useBtnPrev={true}
                setTop={"0px"}
            />
            {/* <!-- //서브메뉴 --> */}

            {/* <!-- container --> */}
            <div id="container" className="sub_wrap wrap">
                
                {/* <BigBanner data={banner} /> */}
                {/* <SmallBanner data={banner} /> */}
                {banner && Array.isArray(banner) && banner?.length > 0 ?
                    <BigBanner data={banner} />
                :
                <section class="section station wrapper banner_big">
                    <div className={"banner_big list "}>
                        <a>
                            <div className={"area_box radius-sm "} style={{background: "#ffedef"}}>
                                <img src={img_default_banner} />
                            </div>
                        </a>
                    </div>
                </section>}

                {/* <!-- 섹션 --> */}
                <section ref={contentRef} className="section template wrapper temp_list template_column">
                    <div className="tit_con clarfix">
                        <div className="left">
                            <label className="medium toggle_btn">
                                <input 
                                    role="switch" 
                                    type="checkbox" 
                                    checked={isEnd}
                                    onChange={(e)=>{
                                        setIsEnd(e.currentTarget.checked);
                                    }}
                                    />
                                <span className="color-txt-05 btn-03">완결</span>
                            </label>
                        </div>
                        <div className="right">
                            <a style={{cursor:"pointer"}}
                                className="select_btn select_01"
                                onClick={modalShow}
                                ><span className="color-btn-03-txt btn-03" >{modalData[selectKey]}</span></a>
                            <a style={{cursor:"pointer"}}
                                className="select_btn select_02"
                                onClick={sortModalShow}
                                ><span className="color-btn-03-txt btn-03" >{sortModalData[sortSelectKey]}</span></a>
                        </div>
                    </div>
                    {data && Array.isArray(data) && data?.length > 0 ?
                    <div className="list">
                        <ul>
                            {/* <!-- 리스트--> */}
                            {
                                data.map((item, index)=>{

                                    return (
                                        <li key={index} className="clearfix">
                                            <BookpalLink 
                                                to={"/content/"+item?.ct_type+"/"+item?.bid}
                                                check={{
                                                    view_type:memberInfo?.view_type,
                                                    work_adult:item?.adult,
                                                }}
                                                alert={alert}
                                                >
                                                <div className="cover_img radius-xs">
                                                    {/* <p className="ic_adult badge_adult_middle"><img src="../images/ic_adult.svg" /></p> */}
                                                    {item?.adult ? <p className="ic_adult badge_adult_middle"><img src="/images/ic_adult.svg" /></p> : null}
                                                    {/* <img src="../images/thumbnail.jpg" /> */}
                                                    {/* <BookpalLazyLoadImage 
                                                        placeholderSrc={ImgDefault("cover_logo")}
                                                        // effect="blur"
                                                        src={ImgCover(memberInfo?.view_type, item?.adult, ImgDefault("cover_logo"), item?.cover)}
                                                        // style={defaultImageStyle}
                                                        onError={(e)=>{
                                                            e.currentTarget.src = ImgDefault("cover_logo");
                                                        }}
                                                    /> */}
                                                    <BookpalLazyLoadImage 
                                                        adult={item?.adult}
                                                        adultswitch={memberInfo?.audit_switch}
                                                        defaulttype={"cover_logo"}
                                                        locktype={"cover_lock"}
                                                        src={item?.cover}
                                                        // style={defaultImageStyle}
                                                        // className={style['cover_img']}
                                                    />
                                                    <p className="ic_bottom badges_box">
                                                        {/* <span className="ic_midnight radius-xs icon_badge"><img src="../images/midnight_badge.svg" /></span>
                                                        <span className="ic_discount radius-xs discount_10 icon_badge"><img src="../images/discount_10.svg" /></span> */}
                                                        {item?.badge_promotion !== "" ? <span className="ic_midnight radius-xs icon_badge"><img src={ImgBadge(item?.badge_promotion)} /></span> : null}
                                                        {/* {item?.badge_new_up !== "" ? <span className="ic_up radius-xs icon_badge"><img src={ImgBadge(item?.badge_new_up)} /></span> : null} */}
                                                        {item?.badge_discount > 0 ? <span className="ic_discount radius-xs discount_10 icon_badge"><img src={ImgBadge(item?.badge_discount)} /></span> : null }
                                                    </p>
                                                </div>
                                                <div className="info_txt spacing_08">
                                                    <div className="genre body-01 color-txt-05">{item?.genre_kor}</div>
                                                    <div className="list_tit_con">
                                                        {/* <span className="ic_up radius-xs icon_badge"><img src="../images/up_badge.svg" /></span> */}
                                                        {item?.badge_new_up !== "" ? <span className="ic_up radius-xs icon_badge"><img src={ImgBadge(item?.badge_new_up)} /></span> : null}
                                                        <span className="title color-txt-normal title-book-03-m text_overflow">{item?.title}</span>
                                                    </div>
                                                    <div className="writer color-txt-03 body-02 text_overflow">{item?.wr_nick}</div>
                                                </div>
                                            </BookpalLink>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    :<p className="no_data large color-txt-04 body-02-long">더 많은 작품을 준비 중입니다.</p>}
                    
                </section>
            </div>
            <Modal />
            <SortModal />
            <AlertModal />
            {/* <!-- container --> */}
            <Footer />
            <TopBtn />
        </>
    );
}


export default HotDeal;