import { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import BookpalLink from 'component/common/custom/BookpalLink';
import { ApiCommonHelpFaq } from 'api/Common';
import { Swiper, SwiperSlide } from 'swiper/react';
import NewlineToBr from 'component/common/content/NewlineToBr';

import Header from 'component/common/header/Header';
import Footer from 'component/common/footer/Footer';

import SubNav from "component/common/nav/SubNav";

import style from './Help.module.scss';
import { useIsMobile } from 'utils/Common';
import { isApp } from 'utils/AppUtil';


function CustomTab({ tabData, setTab, setSubTab }){

    const isMobile = useIsMobile();

    const handler = (e) => {
        e.stopPropagation();
        e.preventDefault();

        const data = e.target.getAttribute('data-tab');
        setTab(data);
        setSubTab(null);
    }
    
    return (
        <div id="subDepth" className={"color-bg-01 type_header " + style['subDepth'] + " " + style['type_header']} style={{position:"sticky",top:isApp()?"0px":isMobile?"48px":"64px"}}>
            <div className="swiper-container">
                <div className="swiper-wrapper txt color-txt-muted">
                    {/* <!-- 클릭시 className="on" 추가--> */}
                    {tabData && Array.isArray(tabData) ? tabData.map((item, index)=>(
                        <div key={index} className={"swiper-slide " + style['swiper-slide'] + " " + (item?.is_select ? "on":"")}>
                            <a data-tab={item?.name} onClick={handler}>
                                <span 
                                    data-tab={item?.name}
                                    className="color-btn-01 border border-soild btn-04"
                                    onClick={handler}
                                    >{item?.name}</span>
                            </a>
                        </div>
                    )):null}
                </div>
            </div>
        </div>
    )
}

function CustomSubTab({subTabData, setSubTab}){
    
    const handler = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const value = e.target.getAttribute("data-name");
        console.log(value);
        // setSubTab
        setSubTab(value);
    }
    return (
        <>
            <Swiper 
                className="swiper-container snbSwiper" 
                slidesPerView={'auto'}
                preventClicks={true}
                preventClicksPropagation={false}
                observer={true}
                observeParents={true}
            >
                {subTabData && Array.isArray(subTabData) ? subTabData.map((item, index)=>(
                    <SwiperSlide key={index} className={"swiper-slide on " + style['swiper-slide'] + " " + style['swiper-slide'] + " " + (item?.is_select === true ? style['on']:"")}>
                        <BookpalLink 
                            data-name={item?.name} 
                            className="button btn_05 label_on medium radius-full"
                            onClick={handler}>
                            <span 
                                className="btn-03 color-btn-03-txt"
                                data-name={item?.name} 
                                onClick={handler}
                                >{item?.name}</span>
                        </BookpalLink>
                    </SwiperSlide>
                )):null}
                
            </Swiper>
        </>
    )
}


function HelpFaq(){


    const navigate = useBookpalNavigate();
    const location = useLocation();
    const state = location.state;
    console.log(location)
    const [tab, setTab] = useState(state?.tab ?? null);
    const [subTab, setSubTab] = useState(state?.subTab ?? null);
    const [tabData, setTabData] = useState(null);
    const [subTabData, setSubTabData] = useState(null);
    const [data, setData] = useState(null);
    const [selectData, setSelectData] = useState(null);
    // const [searchKeyword, setSearchKeyword] = useState(state?.keyword ?? "");
    
    

    const loadFaq = ApiCommonHelpFaq({
        onSuccess: (data)=>{
            console.log(data);
            if(data?.status === true){
                setTabData(data?.result?.tab);
                setSubTabData(data?.result?.sub_tab);
                setData(data?.result?.data);
                setSelectData(null);

            }
            
        },
        onError:(e)=>{

        }
    });

    const handlerMoveFaqSearch = () => {
        // console.log("/mypage_menu/help/faq");
        navigate("/mypage_menu/help/faqsearch");
    };

    useEffect(()=>{
        loadFaq({
            tab:tab,
            sub_tab:subTab,
        });
    },[tab, subTab]);


    const optionsData = [];
    if(data && Array.isArray(data) && data?.length > 0){
        data.map((item, index)=>{
            const handler = (e)=>{
                const data = e.target.getAttribute("data-index");
                console.log(e.target);
                setSelectData(selectData === data ? null : data);
            }
            const tmp = (
                <li key={index} onClick={handler} className="border-line border-soild radius-sm color-border-01">
                    <button 
                        type="button" 
                        data-index={index}
                        className={"button large color-bg-01 label_on " + (selectData == index?"click":"")}
                        >
                        <span data-index={index} className="color-txt-02 title-01-m-long">{item?.q}</span>
                    </button>
                    <div 
                        className="txt_box color-txt-03 body-02-long border-soild border-top-line color-bg-02 color-border-01"
                        style={{display:(selectData == index?"block":"none")}}
                        >
                        <div className="desc body-02-long color-txt-03">
                            <NewlineToBr text={item?.a} />
                        </div>
                    </div>
                </li>
            );
            optionsData.push(tmp);
        });
    } else {
        optionsData.push((
            <p className="no_data medium color-txt-04 body-02-long">검색 결과가 없습니다.</p>
        ));
    }


    if(tabData === null){
        return (
            <></>
        )
    }


    return (
        <>
            <Header useMobile={false} pcTop={"-64px"}/>
            <SubNav 
                name={"FAQ"}
                usePc={true} 
                useMobile={true} 
                usePcRightBtns={true}
                useBtnPrev={true}
                useBtnPcPrev={true}
                useBtnHome={false}
                useBtnFaqSearch={true}
                onFaqSearch={handlerMoveFaqSearch}

                setTop={'0px'} 
                setPcTop={'0px'} 
                />

            {/* <!-- subDepth --> */}
            <CustomTab tabData={tabData} setTab={setTab} setSubTab={setSubTab}/>
            {/* <!-- //subDepth --> */}

            {/* <!-- container --> */}
            <div id="container" className={style['containerr'] + " " + style['faq_box']}>
                <section className={"history_box section wrapper " + style['history_box'] + " " + style['section']}>
                    <div className={"type_list_btns " + style['type_list_btns']}>
                        <CustomSubTab 
                            subTabData={subTabData}
                            setSubTab={setSubTab}
                            />
                    </div>
                    <ul className="toggle_list">
                         
                        {optionsData}
                    </ul>
                    {/* <!-- 리스트 없을때 --> */}
                    {/* <!-- <p className="no_data medium color-txt-04 body-02-long">검색 결과가 없습니다.</p> --> */}
                    {/* <!-- //리스트 없을때 --> */}
                </section>
            </div>
            {/* <!-- container --> */}

            <Footer />
        </>
    );
}




export default HelpFaq;