import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import BookpalLink from '../custom/BookpalLink';
import { ImgDefault } from 'utils/Images';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BookpalLazyLoadImage from '../custom/BookpalLazyLoadImage';

import style from './RecommendKeywordSection.module.scss';

function RecommendKeywordSection({title, data, image}){


    if(data === null && !Array.isArray(data)){
        return <></>
    }

    return (
        <section className={"section keyword solo_section wrapper " + style["keyword"]}>
            <div className="area_box">
                <div className="top_con clearfix">
                    <div className="title_con">
                        <h4 className="title color-txt-normal title-04 text_overflow2">가장 많이 검색된<br /><span className="color-state-point-01 txt">{title}</span> 인기 키워드는?</h4>
                        <p className="desc color-txt-04 title-02-m text_overflow">아래 #버튼을 눌러보세요♥</p>
                    </div>
                    <div className="cover_img">
                        {/* <BookpalLazyLoadImage 
                            // placeholderSrc={ImgDefault("keyword")}
                            // // effect="blur"
                            // src={image}
                            // // style={defaultImageStyle}
                            // onError={(e)=>{
                            //     e.currentTarget.src = ImgDefault("keyword");
                            // }}
                        /> */}
                        <BookpalLazyLoadImage 
                            // adult={item?.adult}
                            defaulttype={"keyword"}
                            locktype={"keyword"}
                            src={image}
                            // style={defaultImageStyle}
                        />
                        {/* <img src="./images/keyword_banner.png" /> */}
                    </div>
                </div>
                
                <Swiper className={"keyword_type swiper mySwiper " + style["keyword_type"]}
                    slidesPerView={'auto'}
                    spaceBetween={4}
                    watchOverflow={true}
                    observer={true}
                    observeParents={true}
                    
                    breakpoints={{
                        769: {
                            freeMode : true,
                        },
                    }}
                >
                    {data?.map((item, index)=>{

                        return (
                            <SwiperSlide key={index} className={style['swiper-slide']}>
                                <BookpalLink 
                                    to={"/search"}
                                    state={{keyword: item?.name}}
                                    className="color-txt-03 btn-04 color-border-01 color-bg-01 radius-full border-line border-soild button btn_03 label_on round on outlined large"
                                    >
                                    #{item?.name}
                                </BookpalLink>
                            </SwiperSlide>
                        )
                    })}
                    
                </Swiper>
            </div>
        </section>
    )
}


export default RecommendKeywordSection;