import BookpalLink from 'component/common/custom/BookpalLink';
import BookpalLazyLoadImage from 'component/common/custom/BookpalLazyLoadImage';
import style from './Tmpl21.module.scss';
import { useMemberInfo } from 'utils/State';
import useAlertModal from 'component/common/modal/useAlertModal';




function Tmpl21({data}){

    const memberInfo = useMemberInfo();

    const [AlertModal, alert] = useAlertModal();

    if(!data){
        return null;
    }

    if(!data?.item){
        return null;
    }

    if(!data?.item?.DATA || !Array.isArray(data?.item?.DATA)){
        return null;
    }

    return (
        <>
            {/* <!-- 그리드 --> */}
            <div className="template temp_list temp_list_type template_row">
                <div className="list">
                    <ul>
                        {data?.item?.DATA.map((item, index)=>{

                            return (
                                <li key={index}>
                                    <BookpalLink
                                        to={"/content/"+item?.ct_type+"/"+item?.bid} 
                                        check={{
                                            view_type:memberInfo?.view_type,
                                            work_adult:item?.adult,
                                        }}
                                        alert={alert}
                                    >
                                        <div className="cover_img radius-xs">
                                            {/* <p className="ic_adult badge_adult_normal"><img src="/images/ic_adult.svg" /></p> */}
                                            {item?.adult ? <p className="ic_adult badge_adult_normal"><img src="/images/ic_adult.svg" /></p> : null}
                                            {/* <img src={item?.cover} /> */}
                                            <BookpalLazyLoadImage 
                                                adult={item?.adult}
                                                adultswitch={memberInfo?.audit_switch}
                                                defaulttype={"cover_logo"}
                                                locktype={"cover_lock"}
                                                src={item?.cover}
                                                // style={{width:"100%"}}
                                                className={style['custom-image-wrapper']}
                                                wrapperClassName={style['custom-image-wrapper']}
                                                // style={defaultImageStyle}
                                            />
                                        </div>
                                        <div className="info_txt spacing_04">
                                            <div className="info">
                                                <span className="color-txt-05 caption-02">{item?.genre_kor}</span>
                                            </div>
                                            <div className="title color-txt-normal title-book-02-m text_overflow2">{item?.title}</div>
                                            <div className="writer color-txt-03 body-01 text_overflow">{item?.wr_nick}</div>
                                        </div>
                                    </BookpalLink>
                                </li>
                            )
                        })}
                        
                    </ul>
                </div>
            </div>
            <AlertModal />
        </>
    )
}



export default Tmpl21;