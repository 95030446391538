import Header from 'component/common/header/Header';

import Footer from 'component/common/footer/Footer';
import TopBtn from 'component/common/button/top/TopBtn';

import Novel from './component/content_optional_buy/Novel';
import Epub from './component/content_optional_buy/Epub';
import Toon from './component/content_optional_buy/Toon';

import style from './style/Contents.module.scss';
import { useEffect, useRef, useState } from 'react';
import { useIsMobile } from 'utils/Common';
import { useParams } from 'react-router-dom';
import { ApiBuyPayChapterList, ApiBuyChapterPayMulti, ApiBuyBuyBookMulti } from 'api/Buy';
import useConfirmCustomBuyModal from 'component/common/modal/useConfirmCustomBuyModal';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import useInfoModal from 'component/common/modal/useInfoModal';
import { SetState } from 'utils/State';
import { runPopupOrder } from 'utils/Common';

function ContentsOptionalBuy(){

    const isMobile = useIsMobile();
    const contentRef = useRef(null);

    const navigate = useBookpalNavigate();

    const setState = SetState();

    const { type, bid } = useParams();

    const [order, setOrder] = useState("asc");

    const [cids, setCids] = useState([]);
    const [allCheck, setAllCheck] = useState(false);

    const [InfoModal, info] = useInfoModal();

    const [BuyConfirmModal, buyConfirm] = useConfirmCustomBuyModal();

    const toggleSetCids = (cid)=>{
        let check = false;
        cids.map((item,index)=>{
            if(item+"" === cid+""){
                check = true;
            }
        });


        if(check){
            const tmp = [];
            cids.map((item,index)=>{
                if(item+"" === cid+""){
                    return;
                }
                tmp.push(item);
            });
            setCids(tmp);
        } else {
            setCids([...cids, cid]);
        }
    };

    const isGetCheck = (cid)=>{
        let check = false;
        cids.map((item,index)=>{
            if(item+"" === cid+""){
                check = true;
            }
        });

        return check;
    }

    const [data, dataSubmit, initData, paging, setData] = ApiBuyPayChapterList({
        data:{
            bid:bid,
            // viewonly:viewonly,
            // epub:_info?.epub ?? null,
            epub:(type === "epub"),
            order:order,
        },
        onSuccess:(data)=>{
            if(data?.status === true){
                // if(data?.result?.read_info){
                //     if(data?.result?.paging?.current_page === 1){
                //         setReadInfo(data?.result?.read_info);
                //     }
                // }
                if(data?.code === "204"){
                    setState.setToast({
                        title:"구매할 회차가 없습니다."
                    });
                    navigate(-1);
                }
                return;
            }

            if(data?.status === false){
                setState.setToast({
                    title:"잘못된 접근입니다."
                });
                navigate(-1);
            }
        },
        bottomRef:contentRef,
    });

    const buySubmit = ApiBuyBuyBookMulti({
        onSuccess:(data)=>{
            if(data?.status === true){
                const result = data?.result;

                if(result?.use_coupon > 0){
                    setState.setToast({
                        title:"쿠폰 "+result?.use_coupon+"장이 사용되었습니다."
                    });
                }

                if(result?.use_voucher > 0){
                    setState.setToast({
                        title:"선물소장권 "+result?.use_voucher+"개가 사용되었습니다."
                    });
                }

                if(result?.is_next_buy === true){
                    buyConfirm({
                        title:"구매 안내",
                        bookTitle:result?.title,
                        content:result?.next_chapter_cnt + "개 회차를 소장하시겠습니까?",
                        isPay:true,
                        currentConpon:result?.my_coupon_cnt + "",
                        useConpon:result?.buy_coupon,
                        isVoucher:result?.is_voucher_own,
                        voucherOwnCnt:result?.buy_voucher,
                        successBtnTitle:"소장하기",
                        success2BtnTitle:"",
                        successAction:(useVoucher)=>{
                            // console.log("useVoucher",useVoucher);
                            buySubmit({
                                bid:result?.bid,
                                cids:result?.rest_cids,
                                // epub:data?.epub,
                                buy_type:useVoucher ? "v_buy" : "buy",
                            });
                        },
                        success2Action:()=>{
                        },
                    });
                    return;
                }

                navigate("/content/"+type+"/"+bid+"/episode");

                return;
            }

            setState.setToast({
                title:data?.message ?? ""
            });
            // if(result?.status_code === "coupon_not_enough"){
            //     buyConfirm({
            //         title:"구매 안내",
            //         bookTitle:result?.title,
            //         content:result?.chapter_cnt + "화를 소장하시겠습니까?",
            //         isPay:false,
            //         currentConpon:result?.my_coupon_cnt + "",
            //         useConpon:result?.buy_coupon,
            //         useVoucher:false,
            //         successBtnTitle:"쿠폰 충전하러 가기",
            //         cancleBtnTitle:"다음에 할게요",
            //         successAction:()=>{
            //             runPopupOrder();
            //         },
            //     });
            // }
        },
        onError:(e)=>{

        }
    })

    const paySubmit = ApiBuyChapterPayMulti({
        onSuccess:(data)=>{
            console.log(data);
            if(data?.status === true){
                const result = data?.result;
                buyConfirm({
                    title:"구매 안내",
                    bookTitle:result?.title,
                    content:result?.chapter_cnt + "개 회차를 소장하시겠습니까?",
                    isPay:true,
                    currentConpon:result?.my_coupon_cnt + "",
                    useConpon:result?.buy_coupon,
                    isVoucher:result?.is_voucher_own,
                    voucherOwnCnt:result?.buy_voucher,
                    successBtnTitle:"소장하기",
                    success2BtnTitle:"",
                    successAction:(useVoucher)=>{
                        
                        
                        console.log("useVoucher",useVoucher);
                        buySubmit({
                            bid:bid,
                            cids:result?.cids,
                            // epub:data?.epub,
                            buy_type:useVoucher ? "v_buy" : "buy",
                        });
                    },
                    success2Action:()=>{
                    },
                });
                return;
            }
            const result = data?.result;
            if(result?.status_code === "coupon_not_enough"){
                buyConfirm({
                    title:"구매 안내",
                    bookTitle:result?.title,
                    content:result?.chapter_cnt + "화를 소장하시겠습니까?",
                    isPay:false,
                    currentConpon:result?.my_coupon_cnt + "",
                    useConpon:result?.buy_coupon,
                    useVoucher:false,
                    successBtnTitle:"쿠폰 충전하러 가기",
                    cancleBtnTitle:"다음에 할게요",
                    successAction:()=>{
                        runPopupOrder();
                    },
                });
                return;
            }
        },
        onError:(e)=>{

        },
    });

    const handlerPaySubmit = ()=>{

        if(cids?.length <= 0){
            return;
        }

        paySubmit({
            bid:bid,
            cids:cids,
            epub:(type === "epub"),
            ct_type:type,
        })
    }

    const checkAll = (checked)=>{
        if(checked){
            if(data && Array.isArray(data) && data?.length > 0){
                const tmp = [];
                data.map((item, index)=>{
                    tmp.push(item?.cid);
                });
                // alert(tmp)
                setCids(tmp);
                setAllCheck(checked);
            }
            return;
        }

        setCids([]);
        setAllCheck(checked);
    }

    useEffect(()=>{

        dataSubmit();

        document.body.style.background = "#f7f5f5";
        return ()=>{
            document.body.style.background = "";
        }
    },[]);

    useEffect(()=>{
        if(data && Array.isArray(data) && data?.length > 0){
            if(data?.length === cids?.length){
                setAllCheck(true);
                return;
            }
        }
        
        setAllCheck(false);
    },[data, cids]);

    useEffect(()=>{
        initData();
        dataSubmit();
    },[order]);

    return (
        <>
            <Header useMobile={false} />

            <div id="container" className={style['container']} style={{paddingBottom:"64px"}}>
                <section className={"section detail_box wrapper clearfix template temp_list template_column select_list book_list " + style['template'] + " " + style['select_list'] + " " + style['detail_box'] + " " + style['section'] + " " + style['select_list'] + " " + style['book_list'] + " " + style['episodeList']}>
                    <div className={"radius-xxl color-bg-01 area_box " + style['area_box']}>
                    <div id="top_header" className={"color-bg-01 " + style['top_header']}>
                        <button 
                            type="button" 
                            className="prev_btn"
                            data-type={type}
                            data-bid={bid}
                            onClick={(e)=>{
                                // navigate(-1);
                                const type = e.currentTarget.getAttribute("data-type");
                                const bid = e.currentTarget.getAttribute("data-bid");
                                navigate("/content/"+type+"/"+bid+"/episode");
                            }}
                            ></button>
                        <span className={"title-02-b color-txt-normal text_overflow"}>선택구매</span>
                    </div>
                    <div className={"content_body " + style['content_body']}>
                        <div className={"tit_con clearfix " + style['tit_con']}>
                        <div className={"left " + style['left']}>
                            <label 
                                htmlFor='allCheck'
                                className="checkbox_round check-type-01 small label_on"
                                >
                                <input type="checkbox" id="allCheck" checked={allCheck} onChange={(e)=>{checkAll(e.currentTarget.checked)}}/>
                                <span className="box"></span>
                                <span className="txt color-txt-04">빠른선택</span>
                            </label>
                            <button 
                                type="button" 
                                className={"button select_modal_btn " + style['button'] + " " + style['select_modal_btn']}
                                onClick={()=>{
                                    info("선택구매 유의사항",
                                        "- 구매 회차가 많을 경우, 구매 후 대기시간이 발생할 수 있습니다.\n- 구매 시에는 선택한 회차 중 앞 회차부터 소장권, 쿠폰 순으로 매칭되어 사용됩니다.\n- 소장권 사용을 선택하실 경우 잔여 소장권이 모두 선택되어 구매에 사용됩니다.\n- 구매해야 하는 회차의 쿠폰수가 부족할 경우엔 쿠폰 충전 안내가 진행됩니다.\n- 핫딜 할인가는 작품홈 '할인받기' 버튼을 통해서만 적용됩니다. 선택구매시엔 적용되지 않습니다."
                                    );
                                }}
                                >
                                <span className="color-btn-03-txt btn-03">선택구매</span>
                            </button>
                        </div>
                        <div className={"right button_dot " + style['button_dot']}>
                            {/* <!-- 클릭시 click 추가--> */}
                            <button 
                                type="button" 
                                className={"button btn-03 " + style['button'] + " " + (order === "asc"?style['click']:"")}
                                onClick={()=>{
                                    setOrder("asc")
                                }}
                                >
                                <span className="color-btn-03-txt">처음부터</span>
                            </button>
                            <button 
                                type="button" 
                                className={"button btn-03 " + style['button'] + " " + (order === "desc"?style['click']:"")}
                                onClick={()=>{
                                    setOrder("desc")
                                }}
                                >
                                <span className="color-btn-03-txt">최신순</span>
                            </button>
                        </div>
                        </div>
                        {/* <!-- 웹소설 선택구매--> */}
                        <ul 
                            ref={contentRef}
                            className={"list novel_list " + style['list'] + " " + (type == "toon" ? style['toon_list']:style['novel_list'])}
                            >
                            {(type === "novel") && data && Array.isArray(data) ? 
                                data.map((item, index)=>{
                                    // console.log(item)
                                    return <Novel 
                                                key={index} 
                                                data={item} 
                                                toggleSetCids={toggleSetCids}
                                                isGetCheck={isGetCheck}
                                                />
                                })
                            :null}

                            {(type === "toon") && data && Array.isArray(data) ? 
                                data.map((item, index)=>{
                                    // console.log(item)
                                    return <Toon 
                                                key={index} 
                                                data={item} 
                                                toggleSetCids={toggleSetCids}
                                                isGetCheck={isGetCheck}
                                                />
                                })
                            :null}

                            {/* {type === "epub" && data && Array.isArray(data) ? 
                                data.map((item, index)=>{
                                    // console.log(item)
                                    return <Epub 
                                                key={index} 
                                                data={item} 
                                                toggleSetCids={toggleSetCids}
                                                isGetCheck={isGetCheck}
                                                />
                                })
                            :null} */}
                        </ul>

                    </div>
                    <div className="fixed_button">
                        <button 
                            type="button" 
                            className="button btn_01 filled btn-05 white-always txt" 
                            disabled={cids?.length === 0}
                            onClick={()=>{
                                handlerPaySubmit();
                            }}
                            >
                        <span>{cids?.length > 0 ? cids?.length : "0"}</span>개 회차 소장하기
                        </button>
                    </div>
                    </div>
                </section>
            </div>
            <Footer />
            {isMobile ? <TopBtn />:null}
            {isMobile ? null:
            <div
                style={{paddingBottom:"4rem"}}
            ></div>
            }
            <BuyConfirmModal />
            <InfoModal />
        </>
    );
}




export default ContentsOptionalBuy;