import { useIsMobile } from 'utils/Common';
import style from '../style/Promotion.module.scss';



function Tmpl13({data}){

    const isMobile = useIsMobile();

    return (
        <>
            {/* <!-- 타이틀 --> */}
            <div className={"tit_text " + style['tit_text']} style={{padding:isMobile?"8px 10px 8px":"20px 0px 20px"}}>
                <h2 className={"title display-01 color-txt-normal text_overflow " +style['title']}>{data?.item?.TITLE}</h2>
            </div>
        </>
    )
}



export default Tmpl13;