import { useNavigate } from 'react-router-dom';

import Header from 'component/common/header/Header';
import Footer from 'component/common/footer/Footer';

import SubNav from "component/common/nav/SubNav";

import style from './style/Order.module.scss';



function OrderOverseas(){

    const handlerClose = ()=>{
        window.close();
    }

    return (
        <>
            {/* <Header useMobile={false} /> */}
            {/* <SubNav 
                name={"충전"}
                usePc={true} 
                useMobile={true} 
                usePcRightBtns={true}
                useBtnPrev={true}
                useBtnPcPrev={true}
                setTop={'0px'} 
                setPcTop={'64px'} 
                /> */}

            {/* <!-- 충전 헤더 --> */}
            <div id="TopHeader" className={"color-bg-01 title_header " + style['TopHeader'] + " " + style['title_header']}>
                <div className={"title_menu wrapper " + style['title_menu'] + " " + style['wrapper']}>
                    <div className={"btns left_btns " + style['btns'] + " " + style['left_btns']}></div>
                    <div className={"title-04 color-txt-normal text_overflow " + style['text_overflow'] + " " + style['title-04'] + " " + style['color-txt-normal']}>해외이용자 결제 안내</div>
                    <div className={"btns right_btns " + style['btns'] +" " + style['right_btns']}>
                        <button type="button" className="close_btn" onClick={handlerClose}></button>
                    </div>
                </div>
            </div>

            <div id="container" className={"s_wrap wrapper " + style['container']}>
                <section className={"overseas_box area_box " + style['overseas_box'] + " " + style['area_box']}>
                    <div className={"title_box radius-sm color-bg-02 " + style['title_box']}>
                        <div className={"title_txt " + style['title_txt']}>
                            <p className={"title title-01-b-long color-txt-01 " + style['title']}>해외에 계신 이용자께서는 페이팔을 통해 결제가 가능합니다.</p>
                            <p className={"s_title body-03-long color-txt-01 " + style['s_title']}>다음과 같은 절차를 진행하여 주시기 바랍니다. </p>
                        </div>
                        <ul className={"procedure_list body-03-long color-txt-01 " + style['procedure_list']}>
                            <li>
                                <p className={"title " + style['title']}><span className={"num " + style['num']}>1.</span><span className="color-btn-01 txt">bookpal@bookpal.co.kr </span>로 아래 사항 기재하여 메일 발송</p>
                                <p className={"info_txt " + style['info_txt']}>
                                    <span>- 북팔 아이디</span>
                                    <span>- 충전 금액(10,000원 이상 결제부터 가능)</span>
                                    <span>- 본인의 페이팔 계정</span>
                                </p>
                            </li>
                            <li>
                                <p className={"title " + style['title']}><span className={"num " + style['num']}>2.</span>요청된 결제건이 확인되는 일자 기준으로 고시된 매매기준율이 적용된 인보이스 발송</p>
                            </li>
                            <li>
                                <p className={"title " + style['title']}><span className={"num " + style['num']}>3.</span>페이팔 최종 결제 확인 후 계정에 쿠폰 충전</p>
                            </li>
                        </ul>
                        <p className={"desc body-02-long color-txt-05 " + style['desc']}>※ 충전 금액은 충전 페이지 내 결제 상품과 동일하게 맞추어 주시기 바랍니다. </p>
                    </div>
                </section>
            </div>

            {/* <Footer /> */}
        </>
    );
}


export default OrderOverseas;