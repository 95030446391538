import { useDispatch, useSelector  } from 'react-redux';
import { ApiAuthSocialLogin, ApiAuthSocialLoginUrl } from 'api/Auth';
import { actionSetLastSocialLoginTryPath, actionSetLastSocialLoginTryType } from 'action';

import SubNav from "component/common/nav/SubNav";

import style from '../style/User.module.scss';
import { Link } from "react-router-dom";
import BookpalLink from 'component/common/custom/BookpalLink';

function Signup(){

    const dispatch = useDispatch();

    const socialLoginGetUrl = ApiAuthSocialLoginUrl({
        onSuccess: (data) => {
            console.log(data);
            if(data?.status === true){
                
                // dispatch(actionSetLastSocialLoginTryPath("/signup"));
                window.location.href = data?.result?.login_url;
            } else {
                alert("시스템 문제가 발생하였습니다. 문제가 지속되면 관리자에게 문의주세요.");
            }
        },
        onError: (e) => {
            console.log(e);
            alert("통신이 실패하였습니다. 문제가 지속되면 관리자에게 문의주세요.");
        },
    });

    const handlerSocialLoginUrl = async (social_type) => {
        dispatch(actionSetLastSocialLoginTryType(social_type));
        
        await socialLoginGetUrl({
            type: social_type,
            return_url: process.env.REACT_APP_BASE_URL + "/login",
        });
        
    };
    

    return (
        <>
            <SubNav 
                name="회원가입" 
                usePc={false} 
                useMobile={true} 
                setTop={'0px'} 
                useBtnPrev={true} 
            />

            {/* <!-- container --> */}
            <div className={"sub_wrap wrapper " + style['container']}>
                <section className={"signup " + style['signup']}>
                    <p className={"title color-txt-normal title-04 " + style['title']}>회원가입</p>
                    <div className={"area_box color-bg-01 radius-lg color-border-01 border-soild border-line " + style['area_box']}>
                        <div className={"logo_title " + style['logo_title']}>
                            <p className={"logo " + style['logo']}></p>
                            <p className={"body-01 color-txt-muted label_txt " + style['label_txt']}>당신의 상상이 이야기가 되는 곳</p>
                        </div>
                        <ul className={"signup_list button_box " + style['signup_list'] + " " + style['button_box']}>
                            <li>
                                <BookpalLink to={"/join"} className={"btn_03 outlined label_on round button large radius-md " + style['button']}>
                                    <span className={"sns_logo " + style['sns_logo'] + " " + style['bookpal']} id="bookpal"></span>
                                    <span className={"btn-04 color-btn-02 txt " + style['txt']}>일반 회원 가입하기</span>
                                </BookpalLink>
                            </li>
                            <li>
                                <BookpalLink onClick={()=>{handlerSocialLoginUrl('naver');}} className={"btn_03 outlined label_on round button large radius-md " + style['button']}>
                                    <span className={"sns_logo " + style['sns_logo'] + " " + style['naver']} id="naver"></span>
                                    <span className={"btn-04 color-btn-02 txt " + style['txt']}>네이버로 가입하기</span>
                                </BookpalLink>
                            </li>
                            <li>
                                <BookpalLink onClick={()=>{handlerSocialLoginUrl('kakao');}} className={"btn_03 outlined label_on round button large radius-md " + style['button']}>
                                    <span className={"sns_logo " + style['sns_logo'] + " " + style['kakao']} id="kakao"></span>
                                    <span className={"btn-04 color-btn-02 txt " + style['txt']}>카카오로 가입하기</span>
                                </BookpalLink>
                                {/* </a> */}
                            </li>
                            <li>
                                <BookpalLink onClick={()=>{handlerSocialLoginUrl('google');}} className={"btn_03 outlined label_on round button large radius-md " + style['button']}>
                                    <span className={"sns_logo " + style['sns_logo'] + " " + style['google']} id="google"></span>
                                    <span className={"btn-04 color-btn-02 txt " + style['txt']}>구글로 가입하기</span>
                                </BookpalLink>
                            </li>
                            {/* <li>
                                <BookpalLink onClick={()=>{handlerSocialLoginUrl('facebook');}} className={"btn_03 outlined label_on round button large radius-md " + style['button']}>
                                    <span className={"sns_logo " + style['sns_logo'] + " " + style['facebook']} id="facebook"></span>
                                    <span className={"btn-04 color-btn-02 txt " + style['txt']}>페이스북으로 가입하기</span>
                                </BookpalLink>
                            </li> */}
                            <li>
                                <BookpalLink onClick={()=>{handlerSocialLoginUrl('apple');}} className={"btn_03 outlined label_on round button large radius-md " + style['button']}>
                                    <span className={"sns_logo " + style['sns_logo'] + " " + style['apple']} id="apple"></span>
                                    <span className={"btn-04 color-btn-02 txt " + style['txt']}>Apple로 가입하기</span>
                                </BookpalLink>
                            </li>
                        </ul>
                        <p className={"existing_member " + style['existing_member']}>
                            <BookpalLink to={"/login"}>
                                <span className={"btn-03 color-btn-03-txt txt-border-btn-03 border-soild border-bottom-line"}>이미 회원이신가요?</span>
                            </BookpalLink>
                        </p>
                    </div>        
                </section>
            </div>
            {/* <!-- container --> */}
        </>
    );
}



export default Signup;