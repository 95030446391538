import { useEffect, useState } from 'react';
import { ApiBookFirstView } from 'api/Book';
import { contentDecrypt, decryptOld } from 'utils/Crypt';

import style from '../style/Contents.module.scss';
import NewlineToBr from 'component/common/content/NewlineToBr';


function FirstEpisode({info}){

    const _info = info || false;
    const [data, setData] = useState(null);

    const firstSubmit = ApiBookFirstView({
        onSuccess:(data)=>{
            if(data?.status === true){
                setData(data?.result);
                return;
            }

            switch(data?.code){
                case "400": alert("책 정보가 없습니다."); break;
                default: alert("시스템 에러 입니다."); break;
            }
        },
        onError:(e)=>{
            alert("시스템 에러 입니다.");
        }
    })
    
    useEffect(()=>{
        firstSubmit({
            bid:_info?.bid
        });
    },[])

    // console.log(data)
    if(data === null){
        return null;
    }

    // if(data?.is_first_view === false){
    //     return null;
    // }

    return (
        <div className={"view_body " + style['view_body']}>
            <div className={"area_box " + style['area_box']}>
                <div className={"contents_box wrap " + style['contents_box'] + " " + style['wrap']}>
                    <div className={"area bg viewer-white NanumBarunGothic " + style['area'] + " " + style['NanumBarunGothic']} style={{paddingLeft: "7%", paddingRight: "7%"}}>
                        <div>
                            {data?.contents.map((item, index)=>{

                                switch(item?.type){
                                    case "image":
                                        return (
                                            <div key={index} className={"images " + style['images']}><img src={item?.data} /></div>
                                        )
                                    case "text":
                                        return (
                                            <div key={index} className={"contents viewer-black-01 txt " + style['contents'] + " " + style['txt']} style={{fontSize: "18px",lineHeight: "180%"}}>
                                                <NewlineToBr text={decryptOld(item?.data)} />
                                            </div>
                                        )
                                }
                            })}
                            {/* <div className={"images " + style['images']}><img src="https://image.bookpal.co.kr/img_view_big?m=0&cd=8UfLQP%2FdDPWoRvUPegueQA%3D%3D" /></div> */}
                            {/* <div className={"contents viewer-black-01 txt " + style['contents'] + " " + style['txt']} style={{fontSize: "18px",lineHeight: "180%"}}>
                                "내가?"
                                "응."

                                레비가 억울하다는 듯 나를 향해 눈을 크게 떴다.

                                "나쁜 짓? 너무하네 진짜. 나 완전 착하거든??"
                                "근데 막 근육이랑 문신 있으니까, 꼭... 조폭 같잖아." 

                                레비의 눈은 아까보다 더 크게 떠졌다. 정말 억울하다는 표정이었다. 그 모습이 너무 귀여워서 나도 모르게 먹던 고기를 뿜을 뻔했다.

                                "조폭!!!!? 진짜 너무하다. 너."
                                "조폭이란 말도 아네?"
                                "나 한국 영화 많이 봤어. 한국 SLANG 많이 알아!"
                                "크크킄, 암튼 그래서 넌 착했다 이거지?"
                                "당연하지!!"
                                "근데 왜 그렇게 근육이랑 문신 있어? 한국에서는 그거 둘 다 있으면 조폭인데."

                                레비는 이제 양손까지 으쓱 들어 보였다. 

                                "미국 고등학교에서는 운동 안하고 근육 없으면 좀 LOSER 느낌 있어. 괴롭히는 애들도 있고. 그래서 운동해서 만든 거야."
                                "옷 그래... 그럼 타투는 왜?"
                                "타투는 미국에서는 음..."

                                이건 할 말이 없는 걸까? 녀석은 다시 약간 안절부절 못하는 표정이 되었다. 와 이런 거 구경하는 거 꿀잼이구나. 나는 원래 누굴 놀리는 걸 좋아하는 편은 아니었지만, 이 녀석 놀리는 건 정말로 재미있었다.

                                "아, 아무튼 한국보다는 이상한 거 아니야!"
                                "그래 알았어. 너 완전 착한걸로."

                                레비와 나는 킥킥 웃으며 마지막으로 샤브샤브 국물을 속까지 휘저어 고기들을 건져냈다. 나는 마지막 남은 고기들 중 절반을 레비에게 건네주고 나머지는 내가 먹었다. 가만히 그것을 보고있던 레비가 웃는다.

                                "고마워. 체리는 참 친절해."
                                "이 정도가?"
                                "응. 체리 착하고 친절해."

                                이 정도로 점수를 받다니. 고기도 심지어 내 거가 약간 더 컸는데. 정말 이 녀석은 이 외모에 이 피지컬에 성격은 왜이리 순수한 거냐. 아니면 순수한 척인가? 내가 고단수 바람둥이에게 속고있는 건가? 알 수 없었다.
                                젓가락을 내려놓자마자 레비는 기지개를 켜더니 기분좋게 말했다. 

                                "그럼 갈까? 나 오늘은 오토바이 타고 왔어!"
                                "엉? 오토바이?! 차는 어따두고?"
                                "차는 오늘 친구가 갖고갔어. 오토바이랑 차 둘다 친구랑 같이 써. 가자!"

                                ***

                                막상 오토바이를 앞에서 보니 약간 떨려왔다. 오토바이를 탄 적은 한 번도 없었다. 그것도 누군가의 뒤에서. 이게 과연 괜찮을까? 내가 멍하니 오토바이를 바라보기만 하고 선뜻 움직이지 않자, 레비가 갸웃거렸다.

                                "왜?"
                                "아니 좀 떨려서... 무서울 거 같아."
                                "으응? 아냐 나 운전 잘 해. 사고난 적 한 번도 없어!"
                                "1년밖에 안 됐으니까 없는 거 아냐?"
                                "아닌데! 나 미국에서도 자동차도 오토바이도 한 번도 사고 안 났어!"

                                끙... 뭐 꼭 사고가 문제는 아니고... 그냥 난 이런 게 무섭다 이건데. 그래도 안탈수는 없겠지?
                                레비가 희희낙낙 내게 헬멧을 내밀었다.

                                "이거 나도 써야 돼?"
                                "응! 써야 돼. 안 쓰면 경찰 아저씨한테 혼나."
                                "너만 쓰는 거 아니고 나까지?"

                                나는 영 내키지 않아 헬멧을 들고 요리조리 살펴보았다. 앞머리 망가지는 건 질색이었다.

                                "응. 뒤에 타도 써야 돼. 자 이렇게..."

                                나는 할 수 없이 헬멧을 썼다. 레비는 내가 헬멧 쓴 걸 보자마자 손뼉을 치며 웃었다.

                                "와! 헬멧 써도 엄청 귀여워!"

                                나는 헬멧 속에서 중얼거렸다.

                                "끙... 빨리 타자 일단."

                                ***
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}



export default FirstEpisode;