import { Link } from "react-router-dom";
import BookpalLink from "component/common/custom/BookpalLink";
import { ImgDefault, ImgBadge, ImgCover } from "utils/Images";
import { LazyLoadImage } from "react-lazy-load-image-component";
import BookpalLazyLoadImage from "component/common/custom/BookpalLazyLoadImage";
import EmphasizedText from "component/common/content/EmphasizedText";
import { getViewCntConvert } from "utils/Common";

import style from './WorkTmpl.module.scss'
import useAlertModal from "component/common/modal/useAlertModal";
import { useMemberInfo } from "utils/State";

function getCategoryName($category){
    switch($category){
        case "novel": return "웹소설";
        case "epub": return "단행본";
        case "comic":
        case "toon": 
            return "웹툰";
    }
}

function WorkTmpl({ data, memberInfo }){

    const item = data?.info;

    // const memberInfo = useMemberInfo();

    const [AlertModal, alert] = useAlertModal();

    if(!data){
        return <></>
    }

    return (
        <>
        <BookpalLink 
            to={"/content/"+item?.ct_type+"/"+item?.bid}
            check={{
                view_type:memberInfo?.view_type,
                work_adult:item?.adult,
            }}
            next={true}
            alert={alert}
            >
            <div className={"cover_img radius-xs " + style['cover_img']}>
                {item?.adult ? <p className="ic_adult badge_adult_middle"><img src="../images/ic_adult.svg" /></p> : null}
                {/* <img src={item?.cover} /> */}
                {/* <BookpalLazyLoadImage 
                        placeholderSrc={ImgDefault("cover_logo")}
                        // effect="blur"
                        src={ImgCover(memberInfo?.view_type, item?.adult, ImgDefault("cover_lock"), item?.cover)}
                        className={style['cover_img']}
                        // style={defaultImageStyle}
                        onError={(e)=>{
                            e.currentTarget.src = ImgDefault("cover_logo");
                        }}
                    /> */}
                <BookpalLazyLoadImage 
                    adult={item?.adult}
                    adultswitch={memberInfo?.audit_switch}
                    defaulttype={"cover_logo"}
                    locktype={"cover_lock"}
                    src={item?.cover}
                    className={style['cover_img']}
                />
                <p className="ic_bottom badges_box">
                    {item?.badge_promotion !== "" ? <span className="ic_midnight radius-xs icon_badge"><img src={ImgBadge(item?.badge_promotion)} /></span> : null}
                    
                    {item?.badge_discount > 0 ? <span className="ic_discount radius-xs discount_10 icon_badge"><img src={ImgBadge(item?.badge_discount)} /></span> : null }
                </p>
            </div>
            <div className="info_txt spacing_08">
                <div className="info">
                    <span className="free body-01 color-txt-05">{getCategoryName(data?.category)}</span>
                    <span className="free body-01 color-txt-05">{item?.genre_kor}</span>
                    <span className="free body-01 color-txt-05">{item?.is_finished === true ? "완결":item?.is_rest ? "휴재":"연재"}</span>
                </div>
                <div className="list_tit_con">
                    {item?.badge_new_up !== "" ? <span className="ic_up radius-xs icon_badge"><img src={ImgBadge(item?.badge_new_up)} /></span> : null}
                    {/* <span className="ic_up radius-xs icon_badge"><img src="../images/up_badge.svg" /></span> */}
                    <span className="title color-txt-normal title-book-03-m text_overflow">
                        <EmphasizedText 
                                text={item?.title} 
                                emphasizedText={data?.highlight} 
                                emphasizedClassName={"color-btn-01 txt"} 
                                tag={({children})=><em className="color-btn-01 txt">{children}</em>}
                            />
                        {/* 책<em className="color-btn-01 txt">제목</em>영역입니다. */}
                    </span>
            </div>
                <div className="writer color-txt-03 body-02 text_overflow">
                    <EmphasizedText 
                        text={item?.wr_nick} 
                        emphasizedText={data?.highlight} 
                        emphasizedClassName={"color-btn-01 txt"} 
                        tag={({children})=><em className="color-btn-01 txt">{children}</em>}
                    />
                </div>
                <div className="info">
                    {item?.free_chapter > 0 ? <span className="free body-01 color-txt-05">{item?.free_chapter}화무료</span> : null}
                    <span className="view body-01 color-txt-05">{getViewCntConvert(item?.view_cnt)}</span>
                </div>
            </div>
        
        </BookpalLink>
        <AlertModal />
         
        </>
    )
}


export default WorkTmpl;