import { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination, Navigation, Thumbs, EffectFade, Autoplay } from 'swiper/modules';
import { Link } from 'react-router-dom';
import { ImgDefault } from 'utils/Images';
import LazyLoad from 'react-lazyload';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BookpalLazyLoadImage from '../custom/BookpalLazyLoadImage';
import BookpalLink from '../custom/BookpalLink';


import mainStyle from './MainBanner.module.scss';
import { useWidth } from 'utils/Common';
//import 'swiper/css';
//import 'swiper/css/effect-fade';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { ApiCommonBannerClick } from 'api/Common';

function getMainDefaultImgStyle(gb){
    const style = {};
    switch(gb){
        case "pc": 
            style['minWidth'] = "330px";
            style['minHeight'] = "440px";
            break;
        case "mobile": 
            style['minWidth'] = "300px";
            style['minHeight'] = "400px";
            break;
        // case ""
    }
    return style;
}

function MainBanner({ data }){

    const [key, setKey] = useState(0);
    const [thumbsSwiper, setThumbsSwiper] = useState(0);
    const [mainBannerStyle, setMainBannerStyle] = useState({});
    const [prevWidth, setPrevWidth] = useState(window.innerWidth);
    const ref = useRef();
    const width = useWidth();

    const refreshSwiper = () => {
        setKey((prevKey) => prevKey + 1);
    };

    const handleResize = () => {
        const currentWidth = window.innerWidth;
  
        if (Math.abs(currentWidth - prevWidth) > 1) {
          // 좌우로 10px 이상 변경되었을 때 동작하도록 설정
          console.log('Resized!');
          setPrevWidth(currentWidth);
          refreshSwiper();
        }
    };

    const bannerClickSubmit = ApiCommonBannerClick({
        onSuccess:(data)=>{
            console.log(data);
        },
        onError:(e)=>{

        }
    });

    const handlerBannerClick = (id)=>{
        if(id === "0" || id === null){
            return;
        }
        setTimeout(()=>{
            bannerClickSubmit({
                type:"main",
                id:id,
            });
        },10);
    }
    
    useEffect(()=>{
        if(width > 540){
            setMainBannerStyle(getMainDefaultImgStyle("pc"));
        } else {
            setMainBannerStyle(getMainDefaultImgStyle("mobile"));
        }
    },[width]);

    useEffect(()=>{
        // refreshSwiper();
        const swiper = ref.current.swiper;
        // console.log(swiper)
        swiper.slides.forEach((slide, index) => {
            if(slide.swiperSlideIndex == 0){
                if(swiper && swiper.slideTo){
                    swiper.slideTo(index, 0);
                }
            }
        });
        swiper.autoplay.start();
    },[data])

    // var bgSwiper = new Swiper('#visual .bg', {
    //     spaceBetween: 1,
    //     slidesPerView: 1,
    //     loop: true,
    //     effect: 'fade',
    // })

    useEffect(() => {
        // 컴포넌트가 마운트될 때 이벤트 리스너 추가
        // window.addEventListener('resize', refreshSwiper);
        window.addEventListener('resize', handleResize);
        // console.log("test -> use effect");

        // 컴포넌트가 언마운트될 때 이벤트 리스너 정리
        return () => {
            // window.removeEventListener('resize', refreshSwiper);
            window.removeEventListener('resize', handleResize);
        };
    }, []); // 빈 배열을 전달하여 마운트와 언마운트 시에만 실행


    if(!data || !Array.isArray(data)){
        return <></>
    }
    // console.log(data)

    return (
        <section className={mainStyle['visual']}> 
            <Swiper 
                className={"swiper mySwiper bg " + mainStyle['bg']}
                modules={[Thumbs, EffectFade]}
                spaceBetween={1}
                slidesPerView={1}
                loop={true}
                effect="fade"
                onSwiper={setThumbsSwiper}
            >
                {data.map((item, index)=>{

                    return (
                        <SwiperSlide key={index} className={mainStyle['swiper-slide']}>
                            <BookpalLazyLoadImage 
                                    placeholderSrc={<img src={ImgDefault("main_bg")} />}
                                    effect="opacity"
                                    src={item?.image}
                                    onError={(e)=>{
                                        e.currentTarget.src = ImgDefault("main_bg");
                                    }}
                                />
                        </SwiperSlide>
                    )
                })}
            </Swiper>
            <Swiper className={"swiper mySwiper banner " + mainStyle['banner']} key={key}
                effect={'coverflow'}
                centeredSlides={true}
                slidesPerView={'auto'}
                loop={true}
                // cssMode={true}
                observeParents={true}
                ref={ref}
                coverflowEffect={{
                    rotate: 0,
                    stretch: 39,
                    depth: 0,
                    modifier: 1,
                    slideShadows: false,
                    scale: .7,
                }}
                thumbs={{swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null}}
                pagination={{
                    el: '.swiper-pagination',
                    clickable: false
                }}
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: ".swiper-button-prev",
                }}
                modules={[EffectCoverflow, Pagination, Navigation, Thumbs, Autoplay]}
                onInit={(swiper) => {
                    swiper.slides.forEach((slide, index) => {
                    if(slide.swiperSlideIndex == 0){
                        if(swiper && swiper.slideTo){
                        swiper.slideTo(index, 0);
                        }
                    }
                    });
                }}
                autoplay={{
                    delay: 4000,
                    disableOnInteraction: false,
                }}
            >
                {data.map((item, index)=>{
                    return (
                        <SwiperSlide 
                            key={index} 
                            className={mainStyle['swiper-slide']}
                            
                            >
                            <BookpalLink to={item?.link}>
                                <BookpalLazyLoadImage 
                                        placeholderSrc={ImgDefault("main_light")}
                                        // effect="opacity"
                                        src={item?.image ?? ImgDefault("main_light")}
                                        className={mainStyle['img']}
                                        style={mainBannerStyle}
                                        onError={(e)=>{
                                            e.currentTarget.src = ImgDefault("main_light");
                                        }}
                                        background={"#ffffff00"}
                                        data-id={item?.id}
                                        onClick={(e)=>{
                                            const id = e.currentTarget.getAttribute("data-id");
                                            handlerBannerClick(id)
                                        }}
                                    />
                            </BookpalLink>
                        </SwiperSlide>
                    )
                })}
                {/* <SwiperSlide className={mainStyle['swiper-slide']}>
                    <a href="#">
                        <img src="./images/banner.png" className={mainStyle['img']} />
                    </a>
                </SwiperSlide>
                <SwiperSlide className={mainStyle['swiper-slide']}>
                    <a href="#">
                        <img src="./images/banner2.png" className={mainStyle['img']} />
                    </a>
                </SwiperSlide>
                <SwiperSlide className={mainStyle['swiper-slide']}>
                    <a href="#">
                        <img src="./images/banner3.png" className={mainStyle['img']} />
                    </a>
                </SwiperSlide>
                <SwiperSlide className={mainStyle['swiper-slide']}>
                    <a href="#">
                        <img src="./images/banner4.png" className={mainStyle['img']} />
                    </a>
                </SwiperSlide> */}
                <div className={"arrow_btns " + mainStyle['arrow_btns'] + " " + mainStyle['div']}>
                    <div className={"swiper-button-next color-bg-01 box-shadow-xs color-border-01 btn_03 medium label_off radius-full round button " + mainStyle['swiper-button-next']}><img src="./images/chevron_right.svg" /></div>
                    <div className={"swiper-button-prev color-bg-01 box-shadow-xs color-border-01 btn_03 medium label_off radius-full round button " + mainStyle['swiper-button-prev']}><img src="./images/chevron_left.svg" /></div>
                </div>
                <div className={"swiper-pagination " + mainStyle['swiper-pagination']}></div>
            </Swiper>
        </section>
    )
}



export default MainBanner;