import { useApiMutation } from './common/ApiCommon';

export const ApiFreePointparkReady = (options) => {

    const subUrl = "/free/pointpark_ready";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};