import { useState } from 'react';
import { ApiBookGetMidnight } from 'api/Book';
import { ApiPromotionGetMidnight } from 'api/Promotion';


import style from '../../style/Contents.module.scss';
import { useMemberInfo } from 'utils/State';
import { useLocation } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import useAlertModal from 'component/common/modal/useAlertModal';


function Midnight(props){


    const memberInfo = useMemberInfo();
    const location = useLocation();
    const navigate = useBookpalNavigate();

    const [data, setData] = useState(props?.data);
    const [useGift, setUseGift] = useState(false);
    const [AlertModal, alert] = useAlertModal();
    const bid = props?.bid || null;
    const onAction = props?.onAction || (()=>{});



    const [timeTxt, setTimeTxt] = useState(data?.get_left_time_txt ?? null);

    // status	            Boolean	상태		
    // pid	                Int	프로모션 ID		
    // p_type	            Int	이벤트 타입 1, 벌써12시 2.연재의참맛,3묶음,4선물 		
    // date_start	        String	이벤트 시작시간		
    // date_end	        String	이벤트 종료 시간		
    // first_gift_cnt	    Int	첫만남 이용권 개수  1   ( 기본 개수 + 추가 이용권)		
    // no_use	            Int	이용권 사용제한 챕터 수 		
    // first_gift_get_yn	Boolean	첫 이용권 받았냐 ?  받음 true ,  안받음 false		
    // get_yn	            Boolean	지금 받을수 있느냐 없느냐 		
    // get_left_time	    String	남은 이용권 받을 시간 datetime 		
    // get_left_time_txt	String	남은 시간 ( 11시간 남음, 5분남음)		

    const midnightGetSubmit = ApiPromotionGetMidnight({
        onSuccess:(_data)=>{
            if(_data?.status){
                // alert(data?.message);
                onAction(_data?.message);
                setUseGift(true);
                setTimeTxt(_data?.result?.get_left_time_txt);
                return;
            }
        },
        onError:(e)=>{

        }
    });

    const handlerSubmit = ()=>{

        if(!memberInfo?.is_login){
            alert("알림","로그인 해주세요.",()=>{
                navigate("/login",{state:{return_path:location.pathname}});
            });
            return;
        }

        // onAction("test");
        midnightGetSubmit({
            bid:bid,
            pid:data?.pid,
        })
    }



    if(data === null){
        return <></>
    }

    return (
        <>
            <div className={"left " + style['left']}>
                <p className={"icon " + style['icon'] + " " + style['icon_midnight']} id="icon_midnight"></p>
                <p className={"title info plus_info " + style['title'] + " " + style['info'] + " " + style['plus_info']}>
                    <span className="color-txt-01 body-03">벌써열두시?!</span> 
                    {!data?.first_gift_get_yn && data?.first_gift_cnt > 0 ?
                        <span className="color-btn-01 txt body-03">첫만남이용권 {data?.first_gift_cnt}장</span>
                    :null}
                </p>
            </div>
            <p className={"right " + style['right']}>
                {!data?.get_yn && !useGift ? 
                <button 
                    type="button" 
                    className={"button btn_03 round radius-full small label_on outlined " + style['button']}
                    onClick={handlerSubmit}
                    ><span className="btn-02 color-txt-04">선물받기</span></button>
                :<p className="title-sub-02-m color-btn-01 txt">{timeTxt}</p>}
                
                {/* <!-- 선물받기 후 --> */}
                {/* <p className="title-sub-02-m color-btn-01 txt">12시간뒤 충전</p> */}
            </p>
            <AlertModal />
        </>
    )
}



export default Midnight;