import { Link } from 'react-router-dom';
import BookpalLink from 'component/common/custom/BookpalLink';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useIsMobile } from 'utils/Common';
import { isApp } from 'utils/AppUtil';

function SubMenuNav(props){

    const page = props.page || "";

    const isMobile = useIsMobile();
    

    return (
        <div id="navMenu" className="color-bg-01 type_header" style={{top:isApp()?"0px":isMobile?"48px":"64px"}}>
            <Swiper className="swiper-container snbSwiper wrapper btn-04 txt color-txt-muted"
                slidesPerView={'auto'}
                preventClicks={true}
                preventClicksPropagation={false}
                observer={true}
                observeParents={true}
            >
            {/* <div className="swiper-container snbSwiper wrapper"> */}
                {/* <div className="swiper-wrapper "> */}
                    <SwiperSlide className={"swiper-slide " + (page=="/storage/recent"?"on":"")}>
                        <BookpalLink to={"/storage/recent"}>
                            <span className="color-btn-01 border border-soild">최근 본</span>
                        </BookpalLink>
                    </SwiperSlide>
                    <SwiperSlide className={"swiper-slide " + (page=="/storage/interest"?"on":"")}>
                        <BookpalLink to={"/storage/interest"}>
                            <span className="color-btn-01 border border-soild">관심작품</span>
                        </BookpalLink>
                    </SwiperSlide>
                    <SwiperSlide className={"swiper-slide " + (page=="/storage/purchase"?"on":"")}>
                        <BookpalLink to={"/storage/purchase"}>
                            <span className="color-btn-01 border border-soild">구매작품</span>
                        </BookpalLink>
                    </SwiperSlide>
                {/* </div> */}
            {/* </div> */}
            </Swiper>
        </div>
    );
}



export default SubMenuNav;