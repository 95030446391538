import { Link } from "react-router-dom";
import BookpalLink from "component/common/custom/BookpalLink";
import { useIsMobile } from "utils/Common";

import style from './Help.module.scss';
import { isApp } from "utils/AppUtil";
import { GetState } from "utils/State";

function HelpDeclareSubMenuNav({ to }){

    const isMobile = useIsMobile();
    // const isApp = isApp();
    const getState = GetState();

    const declareDot = getState.useGetDot()?.help_declare ?? false;
    
    return (
        <div id="subDepth" className={"color-bg-01 type_header " + style['subDepth'] + " " + style['type_header']} style={{position:"sticky",top:(isApp()?"0px":isMobile?"48px":"64px")}}>
            <div className="swiper-container">
                <div className="swiper-wrapper txt color-txt-muted">
                    {/* <!-- 클릭시 className="on" 추가--> */}
                    <div className={"swiper-slide " + (to == "/mypage_menu/help/declare"?"on":"")}>
                        <BookpalLink to={"/mypage_menu/help/declare"} replace={true}>
                            <span className="color-btn-01 border border-soild btn-04">신고하기</span>
                        </BookpalLink>
                    </div>
                    <div className={"swiper-slide " + (to == "/mypage_menu/help/declare_history"?"on":"")}>
                        <BookpalLink to={"/mypage_menu/help/declare_history"} replace={true}>
                            <span className="color-btn-01 border border-soild btn-04">신고내역 {declareDot ? <span className="dot color-btn-01 bg radius-full"></span> : null}</span>
                        </BookpalLink>
                    </div>
                </div>
            </div>
        </div>
    )
}



export default HelpDeclareSubMenuNav;