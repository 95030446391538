import { useEffect, useRef, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import BookpalLink from 'component/common/custom/BookpalLink';
import { ApiMypageEvent } from 'api/Mypage';
import useSelectModal from 'component/common/modal/useSelectModal';

import Header from 'component/common/header/Header';
import Footer from 'component/common/footer/Footer';

import SubNav from "component/common/nav/SubNav";

import SubMentNav from './SubMentNav';

import style from './Event.module.scss';


import img_novel from 'assets/images/novel/thumbnail_bg2.png';
import img_thumbnail_illust2 from 'assets/images/novel/thumbnail_illust2.png';

function CustomSubNav(){
    const navigate = useBookpalNavigate();
    return (
        <SubNav 
                name={"이벤트"}
                usePc={true} 
                useMobile={true} 
                usePcRightBtns={true}
                useBtnPrev={true}
                useBtnPcPrev={true}
                useBtnHome={true}

                onPrev={()=>{
                    navigate("/mypage");
                }}

                setTop={'0px'} 
                setPcTop={'64px'} 
                />
    );
}

function CustomSubMenuNav(){
    return (
        <SubMentNav to={"/mypage_menu/event/bookterview"} />
    )
}

function CustomFilter(props){
    return (
        <></>
    )
    // const data = props.data || [];
    // const selectData = props.selectData || null;
    // const show = props.show || (()=>{});
    // return (
    //     <div className="tit_con clarfix">
    //         <div className="left"></div>
    //         <div className="right">
    //             {/* @param exhibit 노출관 
    //             all:전체, novel:웹소설, toon:웹툰, bookterview:북터뷰

    //             @param filter 필터
    //             all:전체, romance:로맨스, romancefantasy:로맨스판타지, fantasy:판타지, bl:BL */}
    //             <Link className="select_btn select_01" onClick={show} ><span className="color-btn-03-txt btn-03">{data[selectData] ?? null}</span></Link>
    //         </div>
    //     </div>
    // )
}

function CustomTmpl(props){
    return (
        <>
            <Header useMobile={false} />
            <CustomSubNav />
            <CustomSubMenuNav />
            {/* <!-- container --> */}
            {props.children ?? <div id="container" className={style['containerr']}></div>}
            {/* <!-- container --> */}
            <Footer />
        </>
    )
}

function EventBookterview(){
    const navigate = useBookpalNavigate();
    const contentRef = useRef();

    // @param exhibit 노출관 
    // all:전체, novel:웹소설, toon:웹툰, bookterview:북터뷰

    // @param filter 필터
    // all:전체, romance:로맨스, romancefantasy:로맨스판타지, fantasy:판타지, bl:BL 
    const modalData = {
        all:"전체",
        romance:"로맨스",
        romancefantasy:"로맨스판타지",
        fantasy:"판타지",
        bl:"BL",
    }
    
    // const [data, setData] = useState(null);
    // let page = 1;
    // const perPage = 1;
    const [filter, setFilter] = useState(null);
    const [data, dataSubmit, dataInit] = ApiMypageEvent({
        data:{
            filter:filter,
            exhibit:"bookterview",
        },
        bottomRef:contentRef,
        onSuccess:(data)=>{
            console.log(data);
        },
        onError:(e)=>{
            console.log(e);
        }
    });

    const [Modal, modalShow, selectKey] = useSelectModal(modalData, "all", (key)=>{
        dataInit();
        setFilter(key);
        dataSubmit();
    });

    useEffect(()=>{
        dataSubmit();
    },[]);

    //로딩 전
    if(data === null ){
        return (
            <>
                <CustomTmpl>
                    <div id="container" className={style['containerr']}>

                        <section className={"section wrapper temp_list genre_banner " + style['genre_banner']}>
                            {/* <!-- 북터뷰일때는 제외 --> */}
                            <CustomFilter show={modalShow} data={modalData} selectData={selectKey}/>
                        </section>

                    </div>
                </CustomTmpl>
            </>
        );
    }

    // console.log("page => ", page);
    console.log("event data ==========>", data);
    // console.log("isNextPage", isNextPage);

    //데이터 없을경우
    if(data?.length === 0){
        return (
            <>
                <CustomTmpl>
                
                {/* <!-- container --> */}
                <div id="container" className={style['containerr']}>

                    <section className={"section wrapper temp_list genre_banner " + style['genre_banner']}>
                        {/* <!-- 북터뷰일때는 제외 --> */}
                        <CustomFilter show={modalShow} data={modalData} selectData={selectKey}/>
                        <div className={"list " + style['list']}>
                            <div className="list_1">
                                {/* <!-- 리스트 없을때 --> */}
                                <p className="no_data large color-txt-04 body-02-long">이벤트를 준비하고 있습니다. <br />회원님, 조금만 기다려주세요!</p>
                                {/* <!-- //리스트 없을때 --> */}
                            </div>
                        </div>
                    </section>

                </div>
                {/* <!-- container --> */}

                </CustomTmpl>
                <Modal />
            </>
        );
    }

    return (
        <>
            <Header useMobile={false} />
            <CustomSubNav />
            <CustomSubMenuNav />
            
            {/* <!-- container --> */}
            <div id="container" className={style['containerr']} ref={contentRef}>

                <section className={"section wrapper temp_list genre_banner " + style['genre_banner']}>
                    {/* <!-- 북터뷰일때는 제외 --> */}
                    <CustomFilter show={modalShow} data={modalData} selectData={selectKey}/>
                    <div className={"list " + style['list']}>
                        <div className="list_1"  >
                            <ul>
                                {/* <!-- 리스트 --> */}
                                {data && Array.isArray(data) ? data.map((item, index) => (
                                    <li key={index}>
                                        <BookpalLink to={item?.link}>
                                            <div className={"cover_img radius-sm " + style['cover_img']} style={{backgroundImage: "url("+item?.bg_image+")"}}>
                                                <img src={item?.image} />
                                                {/* <label className="label white-always txt btn-02" style={{background: item?.bg_color}}>3화 무료</label> */}
                                                {item?.badge_txt ? 
                                                <label className="label white-always txt btn-02" style={{background: item?.bg_color}}>{item?.badge_txt}</label>
                                                :null}
                                            </div>
                                            <div className={"info_txt spacing_04 content_box radius-sm " + style['info_txt'] + " " + style['content_box']}>
                                                <div className={"title white-always title-01-b-long txt text_overflow " + style['title'] + " " + style['text_overflow'] + " " + style['txt']}>{item?.title}</div>
                                                <div className={"desc white-always txt body-01 text_overflow " + style['desc']}>{item?.description}</div>
                                            </div>
                                        </BookpalLink>
                                    </li>
                                )) : null}
                                {/* <li>
                                    <a href="#">
                                        <div className={"cover_img radius-sm " + style['cover_img']} style={{backgroundImage: "url("+img_novel+")"}}>
                                            <img src={img_thumbnail_illust2} />
                                            <label className="label white-always txt btn-02" style={{background: "orange"}}>3화 무료</label>
                                        </div>
                                        <div className={"info_txt spacing_04 content_box radius-sm " + style['info_txt'] + " " + style['content_box']}>
                                            <div className={"title white-always title-01-b-long txt text_overflow " + style['title'] + " " + style['text_overflow'] + " " + style['txt']}>제목들어가는자리</div>
                                            <div className={"desc white-always txt body-01 text_overflow " + style['desc']}>내용들어가는자리</div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <div className={"cover_img radius-sm " + style['cover_img']} style={{backgroundImage: "url("+img_novel+")"}}>
                                            <img src={img_thumbnail_illust2} />
                                            <label className="label white-always txt btn-02" style={{background: "orange"}}>3화 무료</label>
                                        </div>
                                        <div className={"info_txt spacing_04 content_box radius-sm " + style['info_txt'] + " " + style['content_box']}>
                                            <div className={"title white-always title-01-b-long txt text_overflow " + style['title'] + " " + style['text_overflow'] + " " + style['txt']}>제목들어가는자리</div>
                                            <div className={"desc white-always txt body-01 text_overflow " + style['desc']}>내용들어가는자리</div>
                                        </div>
                                    </a>
                                </li> */}
                            </ul>
                            {/* <!-- 리스트 없을때 --> */}
                            {/* <!-- <p className="no_data large color-txt-04 body-02-long">이벤트를 준비하고 있습니다. <br>회원님, 조금만 기다려주세요!</p> --> */}
                            {/* <!-- //리스트 없을때 --> */}
                        </div>
                    </div>
                </section>

            </div>
            {/* <!-- container --> */}

            <Footer />
            <Modal />
        </>
    );
}




export default EventBookterview;