import { useNavigate } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import { ApiCommonQnaList } from 'api/Common';

import Header from 'component/common/header/Header';
import Footer from 'component/common/footer/Footer';

import SubNav from "component/common/nav/SubNav";
import HelpQnaSubMenuNav from './HelpQnaSubMenuNav';

import style from './Help.module.scss';


import img_faq_search_img from 'assets/images/help/faq_search_img.png';
import { useEffect, useState } from 'react';
import NewlineToBr from 'component/common/content/NewlineToBr';
import useAlertModal from 'component/common/modal/useAlertModal';


function HelpQnaHistory(){


    const navigate = useBookpalNavigate();

    const [selectHistory, setSelectHistory] = useState(null);
    const [AlertModal, alert] = useAlertModal();

    const [history, handelrQnaHistory, initHistory, paging] = ApiCommonQnaList({
        onSuccess:(data)=>{
            console.log(data);
            if(data?.status === true){
                // setHistory(data?.result);
                return;
            }
            switch(data?.code){
                case "not_found_user": alert("문의내역","로그인 해주세요."); navigate(-1); break;
                default: alert("문의내역","시스템에 문제가 있습니다. 다시 시도해주세요."); break;
            }
        },
        onError:(e)=>{
            alert("문의내역","통신에 실패하였습니다. 문제가 지속될 경우 관리자에게 문의해 주시기 바랍니다.");
        }
    });
    // const [history, setHistory] = useState(null);
    
    // const handelrQnaHistory = ApiCommonQnaList({
    //     onSuccess:(data)=>{
    //         if(data?.status === true){
    //             console.log(data);
    //             setHistory(data?.result);
    //             return;
    //         }
    //         alert("시스템에 문제가 있습니다. 다시 시도해주세요.");
    //     },
    //     onError:(e)=>{
    //         alert("통신에 실패하였습니다. 문제가 지속될 경우 관리자에게 문의해 주시기 바랍니다.");
    //     }
    // });

    const handelrSelectHistory = (e) => {
        console.log(e.target)
        const index = e.target.getAttribute("data-index");
        console.log(selectHistory === index ? null : index)
        setSelectHistory(selectHistory === index ? null : index);
    }

    // const handlerMoveEditSns = () => {
    //     navigate("/mypage/editsns");
    // };

    useEffect(()=>{
        handelrQnaHistory();
    },[]);

    if(history === null){
        return (
            <></>
        )
    }

    return (
        <>
            <Header useMobile={false} pcTop={"-64px"}/>
            <SubNav 
                name={"1:1 문의"}
                usePc={true} 
                useMobile={true} 
                usePcRightBtns={true}
                useBtnPrev={true}
                useBtnPcPrev={true}
                useBtnHome={false}

                setTop={'0px'} 
                setPcTop={'0px'} 
                />


            
                {/* <!-- subDepth --> */}
                <HelpQnaSubMenuNav 
                    to={"/mypage_menu/help/qna_history"}
                />
                {/* <!-- //subDepth --> */}
                
                <div id="container" className={"wrapper " + style['containerr'] + " " + style['wrapper']}>
                    <section className={"history_box section " + style['history_box'] + " " + style['section']}>
                        <ul className={"toggle_list " + style['toggle_list']}>
                            {history && Array.isArray(history) && history?.length === 0 ? <p className="no_data large color-txt-04 body-02-long">문의하신 내역이 없습니다.</p>
                                : history.map((item, index)=>(
                                    <li key={index} className="border-line border-soild radius-sm color-border-01">
                                        <button 
                                            type="button" 
                                            className={"button large color-bg-01 label_on " + style['button'] + " " + (selectHistory == index ? " click":"")}
                                            onClick={handelrSelectHistory}
                                            data-index={index}
                                            >
                                            <p className={"answer_label " + style['answer_label']} data-index={index}>
                                                <span 
                                                    data-index={index}
                                                    className={
                                                        Array.isArray(item?.answer) && item?.answer?.length === 0 ?
                                                        "answer_waiting color-txt-05 title-tiny-01 radius-full border border-line border-soild " + style['answer_waiting']
                                                        : "answer_completed color-btn-01 txt title-tiny-01 radius-full border border-line border-soild " + style['answer_completed']
                                                    }
                                                    >{Array.isArray(item?.answer) && item?.answer?.length === 0 ? "답변대기" : "답변완료"}</span>
                                                {/* <!-- <span className="answer_completed color-btn-01 txt title-tiny-01 radius-full border border-line border-soild">답변완료</span> --> */}
                                            </p> 
                                            <div data-index={index} className={"info_txt " + style['info_txt']}>
                                                <p data-index={index} className="color-txt-02 title-01-m-long">{item?.title}</p>
                                                <p data-index={index} className="data body-01 color-txt-05">{item?.reg_date}</p>
                                            </div>
                                        </button>
                                        <div 
                                            className={"txt_box color-txt-03 body-02-long border-soild border-top-line color-bg-02 color-border-01 " + style['txt_box']}
                                            style={{display:(selectHistory == index ? "block":"none")}}
                                            >
                                            <div className={"desc body-02-long color-txt-03 " + style['desc']}>
                                                <NewlineToBr text={item?.content}/>
                                            </div>
                                            {/* <!-- 담당자 답변--> */}
                                            {!(Array.isArray(item?.answer) && item?.answer?.length === 0)
                                            ?
                                            item?.answer.map((_item, _index)=>{

                                                return (
                                                    <div key={_index} className={"answer_box color-bg-03 " + style['answer_box']}>
                                                        <div className={"title_box color-border-01 border-bottom-line border-soild " + style['title_box']}>
                                                            <p className={"person title-book-02-b color-txt-normal " + style['person']}>담당자 답변</p>
                                                            <p className={"data body-01 color-txt-05 " + style['data']}>{_item?.reg_date}</p>
                                                        </div>
                                                        <div className={"desc_box body-02-long color-txt-normal " + style['desc_box']}>
                                                            <NewlineToBr text={_item?.content} />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                                
                                            : null}
                                        </div>
                                        
                                    </li> 
                                ))
                            }
                            
                        </ul>
                        {/* <!-- 리스트 없을때 --> */}
                        {/* <!-- <p className="no_data large color-txt-04 body-02-long">신고하신 내역이 없습니다.</p> --> */}
                        {/* <!-- //리스트 없을때 --> */}
                    </section>
                </div>
                {/* <!-- container --> */}

            <Footer />
            <AlertModal />
        </>
    );
}




export default HelpQnaHistory;