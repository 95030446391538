import { Link } from 'react-router-dom';
import BookpalLink from 'component/common/custom/BookpalLink';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useLocation } from 'react-router-dom';

import style from '../style/GiftBox.module.scss';
import { useIsMobile } from 'utils/Common';
import { isApp } from 'utils/AppUtil';

function SubMenuNav(props){

    const { pathname, key } = useLocation();
    const page = pathname;

    const isMobile = useIsMobile();

    return (
        <div id="navMenu" className={"color-bg-01 type_header " + style['type_header']} style={{top:isApp()?"0px":isMobile?"48px":"64px"}}>
            <Swiper className="swiper-container snbSwiper wrapper btn-04 txt color-txt-muted"
                slidesPerView={'auto'}
                preventClicks={true}
                preventClicksPropagation={false}
                observer={true}
                observeParents={true}
            >
            {/* <div className="swiper-container snbSwiper wrapper"> */}
                {/* <div className="swiper-wrapper "> */}
                    <SwiperSlide className={"swiper-slide " + (page=="/giftbox"?"on":"")}>
                        <BookpalLink to={"/giftbox"}>
                            <span className="color-btn-01 border border-soild">전체</span>
                        </BookpalLink>
                    </SwiperSlide>
                    <SwiperSlide className={"swiper-slide " + (page=="/giftbox/novel"?"on":"")}>
                        <BookpalLink to={"/giftbox/novel"}>
                            <span className="color-btn-01 border border-soild">웹소설</span>
                        </BookpalLink>
                    </SwiperSlide>
                    <SwiperSlide className={"swiper-slide " + (page=="/giftbox/toon"?"on":"")}>
                        <BookpalLink to={"/giftbox/toon"}>
                            <span className="color-btn-01 border border-soild">웹툰</span>
                        </BookpalLink>
                    </SwiperSlide>
                {/* </div> */}
            {/* </div> */}
            </Swiper>
        </div>
    );
}



export default SubMenuNav;