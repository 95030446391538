import { useEffect, useState } from 'react';
import { ApiBookBookIntro } from 'api/Book';
import VerticalSection from 'component/common/section/VerticalSection';
import style from '../style/Contents.module.scss';

import img_medal_color from 'assets/images/contents/medal_color.png';
import NewlineToBr from 'component/common/content/NewlineToBr';
import { Link, useNavigate } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import BookpalLink from 'component/common/custom/BookpalLink';
import { ImgBadge, ImgCover, ImgDefault } from 'utils/Images';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BookpalLazyLoadImage from 'component/common/custom/BookpalLazyLoadImage';
import { useMemberInfo } from 'utils/State';
import { getViewCntConvert } from 'utils/Common';

function getRankCtName(ct_type){
    switch(ct_type){
        case "novel": return "웹소설";
        case "toon": return "웹툰";
        default: return null;
    }
}

function Introduction({ info, ct_type, setInfo }){

    const navigate = useBookpalNavigate();
    const memberInfo = useMemberInfo();

    const _info = info || false;
    const [data, setData] = useState(null);
    const [isViewIntroduce, setIsViewIntroduce] = useState(false);

    const introSubmit = ApiBookBookIntro({
        onSuccess:(data)=>{
            if(data?.status === true){
                setData(data?.result);
                return;
            }

            switch(data?.code){
                case "400": alert("책 정보가 없습니다."); break;
                default: alert("시스템 에러 입니다."); break;
            }
        },
        onError:(e)=>{
            alert("시스템 에러 입니다.");
        }
    });

    const getTypeName = (type) =>{
        switch(type){
            case "novel": return "웹소설";
            case "epub": return "단행본";
            case "toon": return "웹툰";
            case "comic": return "웹툰";
        }
    }
    
    useEffect(()=>{
        introSubmit({
            bid:_info?.bid,
            epub:ct_type === "epub"?true:false,
        });
    },[])

    if(data === null){
        return <>
        <div style={{height:"300px"}}></div>
        </>
    }
    

    return (
        <>
            {/* <!-- 연관작품 보러가기 --> */}
            {data?.related_info && Array.isArray(data?.related_info) && data?.related_info?.length > 0 ? 
            <article className={"template_column temp_list related_box " + style['related_box']}>
                <div className={"tit_con " + style['tit_con']}>
                    <div className="left text_overflow">
                        <span className="color-txt-normal title-01-b">연관작품 보러가기</span>
                    </div>
                </div>
                <ul className={"list " + style['list']}>
                    {data?.related_info.map((item, index)=>{

                        return (
                            <li key={index}>
                                <BookpalLink onClick={()=>{
                                    setInfo(null);
                                    navigate("/content/"+item?.ct_type+"/"+item?.bid+"/first");
                                }} >
                                    <div className="cover_img border-soild border-right-line color-border-01 radius-xs">
                                        {item?.adult ? <p className="ic_adult badge_adult_middle"><img src="/images/ic_adult.svg" /></p> : null}
                                        
                                        {/* <img src={item?.cover} /> */}
                                        <BookpalLazyLoadImage 
                                            placeholderSrc={ImgDefault("cover_logo")}
                                            // effect="blur"
                                            src={ImgCover(memberInfo?.view_type, item?.adult, ImgDefault("cover_lock"), item?.cover)}
                                            // style={defaultImageStyle}
                                            onError={(e)=>{
                                                e.currentTarget.src = ImgDefault("cover_logo");
                                            }}
                                        />
                                        <p className="ic_bottom badges_box">
                                            {item?.badge_promotion !== "" ? <span className="ic_midnight radius-xs icon_badge"><img src={ImgBadge(item?.badge_promotion)} /></span> : null}
                                            {item?.badge_new_up !== "" ? <span className="ic_up radius-xs icon_badge"><img src={ImgBadge(item?.badge_new_up)} /></span> : null}
                                            {item?.badge_discount > 0 ? <span className="ic_discount radius-xs discount_10 icon_badge"><img src={ImgBadge(item?.badge_discount)} /></span> : null }
                                        </p>
                                    </div>
                                    <div className="info_txt spacing_08">
                                        <div className="info">
                                            {item?.ct_type !== "" ? <span className="body-01 color-txt-05">{getTypeName(item?.ct_type)}</span> : null}
                                            {item?.genre_kor !== "" ? <span className="body-01 color-txt-05">{item?.genre_kor}</span> : null}
                                        </div> 
                                        {/* <div className="title color-txt-normal title-book-03-m text_overflow">{item?.title}</div> */}
                                        {/* {item?.wr_nick !== "" ? <div className="writer color-txt-03 body-01 text_overflow">{item?.wr_nick}</div> : ""} */}
                                        <div className={"list_tit_con " + style['list_tit_con']}>
                                            <span className="title color-txt-normal title-book-03-m text_overflow">{item?.title}</span>
                                        </div>
                                        {item?.wr_nick !== "" ? <div className="writer body-02 color-txt-03 text_overflow">{item?.wr_nick}</div>: ""}
                                        <div className="info">
                                            {item?.free_chapter ? <span className="body-01 color-txt-05">{item?.free_chapter}화무료</span> : null}
                                            <span className="body-01 color-txt-05">{getViewCntConvert(item?.view_cnt)}</span>
                                        </div>
                                    </div>
{/* 
                                    <div className="cover_img border-soild border-right-line color-border-01 radius-xs">
                                        <p className="ic_adult badge_adult_middle"><img src="../images/ic_adult.svg" /></p>
                                        <img src="../images/thumbnail.jpg" />
                                    </div>
                                    <div className="info_txt spacing_08">
                                        <div className="info">
                                            <span className="body-01 color-txt-05">웹툰</span>
                                            <span className="body-01 color-txt-05">로맨스</span>
                                        </div>
                                        <div className={"list_tit_con " + style['list_tit_con']}>
                                            <span className="title color-txt-normal title-book-03-m text_overflow">책제목입니다.</span>
                                        </div>
                                        <div className="writer body-02 color-txt-03 text_overflow">작가명</div>
                                        <div className="info">
                                            <span className="body-01 color-txt-05">3화무료</span>
                                            <span className="body-01 color-txt-05">61.4만</span>
                                        </div>
                                    </div> */}
                                </BookpalLink>
                            </li>
                        )
                    })}
                </ul>
            </article>
            :null}
            {/* <!-- 랭킹 바로가기 // 랭킹 100위안에 드는 작품일 때만 보이게 --> */}
            {data?.ranking_info ? 
            <article className={"ranking_box " + style['ranking_box']}>
                <button 
                    type="button" 
                    className={"button radius-sm color-border-01 " + style['button']}
                    onClick={()=>{
                        navigate(data?.ranking_info?.more_link);
                    }}
                    >
                    <img src={img_medal_color} />
                    <span className="color-txt-02 title-01-m-long">{getRankCtName(data?.ranking_info?.ct_type)} 랭킹 {data?.ranking_info?.rank_num}위</span>
                </button>
            </article>
            :null}
            
            {/* <!-- 키워드 --> */}
            <article className={"keyword_box " + style['keyword_box']}>
                <div className={"tit_con " + style['tit_con']}>
                    <div className={"left text_overflow " + style['left'] + " " + style['text_overflow']}>
                        <span className="color-txt-normal title-01-b">키워드</span>
                    </div>
                </div>
                <div className={"keyword " + style['keyword']}>
                    {data?.keyword ? 
                        data?.keyword.map((item,index)=>{
                            return (
                                <button 
                                    key={index}
                                    type="button" 
                                    className="button medium radius-full border-line border-soild color-border-01 color-bg-01 label_on"
                                    data-keyword={item?.title}
                                    onClick={(e)=>{
                                        const keyword = e.currentTarget.getAttribute("data-keyword");
                                        navigate("/keyword",{state:{keyword:keyword}});
                                    }}
                                    ><span className="color-txt-02 btn-03">#{item?.title}</span></button>
                            )
                        })
                    :null}
                    {/* <button type="button" className="button medium radius-full border-line border-soild color-border-01 color-bg-01 label_on"><span className="color-txt-02 btn-03">#키워드</span></button>
                    <button type="button" className="button medium radius-full border-line border-soild color-border-01 color-bg-01 label_on"><span className="color-txt-02 btn-03">#키워드</span></button>
                    <button type="button" className="button medium radius-full border-line border-soild color-border-01 color-bg-01 label_on"><span className="color-txt-02 btn-03">#키워드</span></button>
                    <button type="button" className="button medium radius-full border-line border-soild color-border-01 color-bg-01 label_on"><span className="color-txt-02 btn-03">#키워드</span></button> */}
                </div>
            </article>
            {/* <!-- 작품 소개 및 줄거리 --> */}
            <article className={"introduction_box " + style['introduction_box']} >
                <div className={"tit_con " + style['tit_con']} >
                    <div className={"left text_overflow " + style['left'] + " " + style['text_overflow']}>
                        <span className="color-txt-normal title-01-b">작품 소개 및 줄거리</span>
                    </div>
                </div>
                <div className={"text_box gold_opacity-10 radius-lg color-border-01 border-line border-soild " + style['text_box'] + " " + (isViewIntroduce ? style['show']:"")}>
                    <div id="inputbox" className={style['inputbox']}>
                        <span 
                            className={"text body-01-long color-txt-03 " + style['text']} 
                            // style={{maxHeight:(isViewIntroduce ? "auto":"100px")}}
                            >
                                <NewlineToBr text={data?.introduce} />
                                {/* <div dangerouslySetInnerHTML={{ __html: data?.introduce }} /> */}
                            </span>
                    </div>
                    {/* {data?.introduce && data?.introduce != "" && data?.introduce?.includes('\n') ? */}
                    <button 
                        type="button" 
                        id="moreBtn" 
                        className={style['moreBtn']} style={{display:"block"}}
                        onClick={()=>{
                            setIsViewIntroduce(!isViewIntroduce);
                        }}
                        ></button>
                    {/* :null} */}
                    
                </div>      

            </article>
            {/* <!-- 작가의 다른 작품--> */}
            {data?.other_work && Array.isArray(data?.other_work) && data?.other_work?.length > 0 ?
            <article className="other_box vertical_list template_row">
                <VerticalSection 
                    sectionType={"small"}
                    title={"작가의 다른 작품"}
                    moreTxt={"작가소개"}
                    classNameSection={style['section']}
                    classNameTotCon={style['tit_con']}
                    classNameList={style['list']}
                    useInfo={true}
                    data={data?.other_work}
                />
            </article>
            :null}
            
            {/* <!-- 독자들이 선택한 다음 작품 --> */}
            {data?.recommend_info && Array.isArray(data?.recommend_info) && data?.recommend_info?.length > 0 ?
            <article className={"reader_box template_row vertical_list " + style['reader_box'] + " " + style['vertical_list']}>
                <VerticalSection 
                    sectionType={"small"}
                    title={"독자들이 선택한 다음 작품!"}
                    moreTxt={"작가소개"}
                    classNameSection={style['section']}
                    classNameTotCon={style['tit_con']}
                    classNameList={style['list']}
                    useWriter={true}
                    data={data?.recommend_info}
                />
            </article>
            :null}
        </>
    )
}



export default Introduction;