import { useNavigate, useLocation } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import { ApiCommonAlarm } from 'api/Common';
import { Link } from 'react-router-dom';
import BookpalLink from 'component/common/custom/BookpalLink';
import { GetState } from 'utils/State';

import Header from 'component/common/header/Header';
import Footer from 'component/common/footer/Footer';

import SubNav from "component/common/nav/SubNav";

import BigBanner from 'component/common/banner/BigBanner';

import SubMenuNav from './SubMenuNav';

import style from './Alarm.module.scss';


import img_alarm from 'assets/images/alarm.png';


import img_promotion from 'assets/images/mypage/event_img_promotion.png';
import img_reply_alarm from 'assets/images/mypage/event_img_reply_alarm.png';
import img_reply_like from 'assets/images/mypage/event_img_reply_like.png';
import img_close from 'assets/images/mypage/event_img_gift_close_01.png';

import { useEffect, useRef, useState } from 'react';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Alarm(){

    const navigate = useBookpalNavigate();

    const contentRef = useRef();

    const query = useQuery();

    const [type, setType] = useState(query.get("type") ?? "all");

    const [banner, setBanner] = useState(null);

    const [data, dataSubmit, initData, paging, setData] = ApiCommonAlarm({
        onSuccess:(data)=>{
            if(data?.status === true){
                if(banner === null){
                    setBanner(data?.result?.banner);
                }
            }
        },
        data:{
            type:type === "all" ? null: type,
        },
        bottomRef:contentRef,
    })
    

    const handlerBack = () => {
        navigate(-1);
    }

    useEffect(()=>{
        dataSubmit();
    },[])

    useEffect(()=>{
        initData();
        dataSubmit();
    },[type]);

    console.log(banner)
    return (
        <>
            <Header useMobile={false} top={"-64px"}/>
            <SubNav 
                name={"알림"}
                usePc={true} 
                useMobile={true} 
                usePcRightBtns={true}
                useBtnPrev={true}
                useBtnPcPrev={true}
                setTop={'0px'} 
                setPcTop={'0px'} 
                />

            <SubMenuNav type={type} setType={setType}/>

            

            {/* <!-- container --> */}
            <div id="container" className={style["containerr"]}>
                <section className={"section template wrapper temp_list " + style['section'] + " " + style['template'] + " " + style['temp_list']}>
                    {/* <!-- 배너 --> */}
                    {/* <div className={"banner_big list " + style['banner_big'] + " " + style['list']}>
                        <a>
                            <div className={"area_box radius-sm " + style['area_box']} style={{background: "#efe5ff"}}>
                                <img src={img_alarm} />
                            </div>
                        </a>
                    </div> */}
                    {banner && Array.isArray(banner) && banner?.length > 0 ?
                        <BigBanner data={banner} />
                    :<div className={"banner_big list " + style['banner_big'] + " " + style['list']}>
                        <a>
                            <div className={"area_box radius-sm " + style['area_box']} style={{background: "#efe5ff"}}>
                                <img src={img_alarm} />
                            </div>
                        </a>
                    </div>}
                    <div className={"list " + style['list']}>
                        <div className={"list_1 " + style['list_1']} ref={contentRef}>
                            {data && Array.isArray(data) && data?.length > 0 ?
                            <ul>
                                {/* <!-- 리스트 --> */}
                                
                                    {data.map((item, index)=>{
                                        return (
                                            <li key={index}>
                                                <BookpalLink
                                                    data-ct_type={item?.ct_type ?? ""}
                                                    data-bid={item?.bid ?? ""}
                                                    data-sub_type={item?.sub_type ?? ""}
                                                    data-url_link={item?.link ?? ""}
                                                    onClick={(e)=>{
                                                        const ct_type = e.currentTarget.getAttribute("data-ct_type");
                                                        const bid = e.currentTarget.getAttribute("data-bid");
                                                        const sub_type = e.currentTarget.getAttribute("data-sub_type");
                                                        const link = e.currentTarget.getAttribute("data-url_link");

                                                        if(bid !== ""){
                                                            navigate("/content/"+ct_type+"/"+bid);
                                                            return;
                                                        };

                                                        if(sub_type === "cmt_reply" || sub_type === "cmt_fav"){
                                                            navigate("/mypage/comment");
                                                            return;
                                                        }

                                                        if(sub_type === "promotion"){
                                                            navigate(link);
                                                            return;
                                                        }
                                                    }}
                                                >
                                                    <div className={"icon_box left " + style['icon_box'] + " " + style['left']}>
                                                        <span className="radius-full">
                                                            {item?.sub_type === "promotion" ? <img src={img_promotion} />:null}
                                                            {item?.sub_type === "gift" ? <img src={img_close} />:null}
                                                            {item?.sub_type === "cmt_reply" ? <img src={img_reply_alarm} />:null}
                                                            {item?.sub_type === "cmt_fav" ? <img src={img_reply_like} />:null}
                                                            {item?.type === "update" ? <img src={item?.cover} />:null}
                                                            {/* <img src="/images/thumbnail.jpg" /> */}
                                                        </span>
                                                    </div>
                                                    <div className={"text_box right " + style['text_box'] + " " + style['right']}>
                                                        <p className={"title title-01-b-long color-txt-normal " + style['title']}>
                                                            {item?.type === "event" ? "[ad] "+item?.title:item?.title}
                                                            {/* {item?.type === "event" ? "[광고]":""} */}
                                                            
                                                            {item?.dot ? 
                                                            <span className="dot color-btn-01 bg radius-full"></span>
                                                            :null}
                                                            
                                                        </p>
                                                        <p className="desc text_overflow2 body-03-long color-txt-02">
                                                            {item?.content}
                                                        </p>
                                                        <p className="time body-01-long color-txt-05">{item?.time_txt}</p>
                                                    </div>
                                                </BookpalLink>
                                            </li>
                                        )
                                    })}
                            </ul>
                            :<p className="no_data medium color-txt-04 body-02-long">받은 알림이 없습니다. <br />알림 수신 상태를 확인하세요.</p>}

                            {/* {data && Array.isArray(data) && data?.length > 0 ? */}
                            <p className="no_data tiny color-txt-04 body-02-long">알림 메시지는 1개월 동안 보관되며 <br />확인 여부와 상관 없이 자동 삭제됩니다.</p>
                            {/* :null} */}
                            {/* <!-- 리스트 없을때 --> */}

                        </div>
                    </div>
                </section>

            </div>
            {/* <!-- container --> */}

            <Footer />
        </>
    );
}


export default Alarm;