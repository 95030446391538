import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import NewlineToBr from '../content/NewlineToBr';
import BookpalLink from '../custom/BookpalLink';
import { ApiCommonBannerClick } from 'api/Common';

function usePopupModal(data){

    const btnTitle = data?.btnTitle || "확인";

    const [image, setImage] = useState(false);
    const [link, setLink] = useState(null);
    const [action, setAction] = useState({});

    const [id, setId] = useState(false);

    const [showModal, setShowModal] = useState(false);

    const bannerClickSubmit = ApiCommonBannerClick({
        onSuccess:(data)=>{
            console.log(data);
        },
        onError:(e)=>{

        }
    });

    const handlerBannerClick = (id)=>{
        if(id === "0" || id === null){
            return;
        }
        bannerClickSubmit({
            type:"popup",
            id:id,
        });
    }

    const handleClose = () => {
        setShowModal(false);
        if(action?.action){
            setAction({
                ...action,
                action:null,
            });
        }
    }
    const handleActionClose = () => {
        setShowModal(false);
        if(action?.action){
            action?.action();
            setAction({
                ...action,
                action:null,
            });
        }
        setLink(null);
    }
    const handleShow = (image, link, id, _action) => {

        if(_action){
            setAction({
                ...action,
                action:_action,
            });
        }

        setLink(link);
        setImage(image);
        setId(id)
        // const msg = (msg2 && msg2 != "")?msg2:msg1;
        // const _title = (msg2 && msg2 != "")?msg1:false;
        
        // if(_title){
        //     setTitle(_title);
        // }

        // if(msg && msg != ""){
        //     setContent(msg);
        // } else {
        //     setContent(false);
        // }
        setShowModal(true);
    }

    const handleSelect = () => {
        handleClose();
    }


    const ModalTmpl =()=>{ 
        return (
        <>
            <Modal 
                id="popupModal"
                className="black_opacity-60" 
                // backdropClassName="black_opacity-60 opacity-10"
                dialogClassName="modal-dialog-centered"
                contentClassName="radius-xxl color-bg-modal"
                show={showModal}
                animation={false} 
                // onHide={handleClose}
                >
                <Modal.Body>
                    {/* {title ? <div className="title title-02-b color-txt-normal">{title}</div> : null}
                    {content ? <div className="desc body-03-long"><NewlineToBr text={content} /></div> : null} */}
                    {/* <div className="select_box">
                    {optionElements}
                    </div> */}
                    <BookpalLink
                        to={link}
                    >
                        <p className="images"
                            data-id={id}
                            onClick={(e)=>{
                                const id = e.currentTarget.getAttribute("data-id");
                                handlerBannerClick(id)
                            }}
                            ><img src={image} /></p>
                    </BookpalLink>
                    <div className="foot_button clearfix"> 
                        <button 
                            type="button" 
                            className="button left white-always txt title-book-02-m"
                            onClick={handleActionClose}
                            >이 소식 보지 않기</button>
                        <button 
                            type="button" 
                            className="button right white-always txt title-book-02-m" 
                            data-bs-dismiss="modal"
                            onClick={handleClose}
                            >닫기</button>
                    </div>
                    
                </Modal.Body>
                {/* <Modal.Footer> */}
                    {/* <button type="button" className="radius-sm btn-04 txt btn_01 large label_on filled button" onClick={handleSelect}>{btnTitle}</button> */}
                    {/* <button type="button" className="radius-xs btn-03 txt btn_03 medium label_on filled button" onClick={handleClose}>취소</button>  */}
                {/* </Modal.Footer> */}
            </Modal>
        </>
    )};
    
    return [ModalTmpl, handleShow];
}


export default usePopupModal;