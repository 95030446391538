import { useEffect, useRef, useState } from 'react';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import { useIsMobile } from 'utils/Common';
import he from 'he';

import style from '../style/Contents.module.scss';

import img_icon_minus from 'assets/images/contents/icon_minus.svg';
import img_icon_plus from 'assets/images/contents/icon_plus.svg';
import img_icon_refresh from 'assets/images/icon_refresh.svg';

import style_fade from './FooterHorizontal.module.scss'
import { useNavigate } from 'react-router-dom';

function HeaderComment({data, type, bid, chapter_title}){
    
    const ref = useRef(null);

    const isMobile = useIsMobile();
    const navigate = useBookpalNavigate();

    if(isMobile){
        return null;
    }

    return (
        <div className={"view_header " + style['view_header'] + " "}>
            <div className={"area_box " + style['area_box']}>
                <div className={"m_wrap clearfix " + style['m_wrap']}>
                    <div className={"left " + style['left']}>
                        <button 
                            type="button" 
                            className={"home_btn button " + style['home_btn'] + " " + style['button']} 
                            onClick={()=>{
                                navigate("/content/"+type+"/"+bid);
                            }}
                            ></button>
                        <p className={"title text_overflow " + style['title'] + " " + style['text_overflow']}>
                            {/* {data?.chapter_no}. {data?.chapter_title} */}
                            {he.decode( chapter_title ?? "" )}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default HeaderComment;