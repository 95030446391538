

export function getBookpalLink(page){
    const baseLink = "nbp://";
    switch(page){
        case "main": return baseLink + "index";
        default: break;
    }
}


