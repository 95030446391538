import { useEffect, useState } from 'react';
import { ApiPayPayBookpalBank } from 'api/Pay';
import { useLocation } from 'react-router-dom';
import style from './style/Order.module.scss'
import useSelectModal from 'component/common/modal/useSelectModal';

function OrderBookpalBankInfo(){

    return (
        <>
            {/* <!-- 충전 헤더 --> */}
            <div id="TopHeader" className={"color-bg-01 title_header " + style['TopHeader'] + " " + style['title_header']}>
                <div className={"title_menu wrapper " + style['title_menu']}>
                    <div className={"btns left_btns " + style['btns'] + " " + style['left_btns']}></div>
                    <div className={"title-04 color-txt-normal text_overflow " + style['text_overflow'] + " " + style['title-04']}>계좌 정보</div>
                    <div className={"btns right_btns " + style['btns'] + " " + style['right_btns']}></div>
                </div>
            </div>

            <div id="container" className={"s_wrap wrapper " + style['container'] + " " + style['s_wrap'] + " " + style['wrapper']} style={{margin:"0 auto", padding:0}}>
                <section className={"account_box area_box " + style['account_box'] + " " + style['area_box']}>
                    <div className={"section " + style['section']} style={{padding:0}}>
                        <div className={"tit_con " + style['tit_con']}>
                            <h2 className={"title left text_overflow title-01-b color-txt-normal " + style['title'] + " " + style['left']}>북팔 입금 계좌</h2>
                        </div>
                        {/* <!-- 클릭시 클립보드 복사 --> */}
                        <div className={"deposit_num color-bg-select-01 radius-sm " + style['deposit_num']}>
                            <p className="color-txt-01 title-sub-02-b">국민은행 : 423701-01-132478 (주)북팔</p>
                        </div>
                    </div>
                    <div className={"notice_box_desc section " + style['notice_box_desc'] + " " + style['section']} style={{paddingTop:0, paddingBottom:80}}>
                        <p className="title title-02-b color-txt-04">안내</p>
                        <ul className="desc_list body-02-long color-txt-05">
                            <li>입금 처리시간 : 10:00, 14:00, 17:00 (주말 및 공휴일 : 12:00 ~ 13:00)</li>
                            <li>등록 후 3일 이내에 계좌이체를 하시지 않으시면 신청 목록에서 제외됩니다.</li>
                            <li>입금자명과 신청자명이 같지 않을 경우, 본인확인 전까지 쿠폰이 지급되지 않습니다. 꼭 동일하게 작성해 주세요.</li>
                        </ul>
                    </div>
                </section>
            </div>
        </>
    )
}




export default OrderBookpalBankInfo;