import { ApiPayPayVbank } from 'api/Pay';

import style from '../style/Order.module.scss'

import img_vip_notice from 'assets/images/order/img_vip_notice.png'
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';

function OrderVBank({oid, msg}){

    const [data, setData] = useState(null);
    const navigate = useBookpalNavigate();

    const payResultSubmit = ApiPayPayVbank({
        onSuccess:(data)=>{
            if(data?.status === true){
                setData(data?.result);
            }
        },
        onError:(e)=>{

        }
    });

    useEffect(()=>{
        payResultSubmit({
            oid:oid,
        })
    },[])

    if(data === null){
        return <></>
    }

    return (
        <>
            {/* <!-- 충전 헤더 --> */}
            <div id="TopHeader" className={"color-bg-01 title_header " + style['TopHeader'] + " " + style['title_header']}>
                <div className={"title_menu wrapper " + style['title_menu']}>
                    <div className={"btns left_btns " + style['btns'] + " " + style['left_btns']}></div>
                    <div className={"title-04 color-txt-normal text_overflow " + style['text_overflow'] + " " + style['title-04']}>결제</div>
                    <div className={"btns right_btns " + style['btns'] + " " + style['right_btns']}></div>
                </div>
            </div>

            <div id="container" className={"s_wrap wrapper " + style['s_wrap'] + " " + style['container']} style={{margin:"0px auto"}}>
                <section className={"area_box color-bg-01 radius-lg color-border-01 border-soild border-line " + style['area_box']}>
                    <div className={"complete_box " + style['complete_box']}>
                    <p className={"title title-03 color-txt-normal " + style['title']}>
                        <span>계상계좌 번호</span> 발급되었습니다.
                    </p>
                    <ul className={"desc_box section " + style['desc_box']}>
                        <li>
                        <p className="s_title body-02 color-txt-muted">입금은행</p>
                        <p className={"s_desc body-04-long color-txt-normal " + style['s_desc']}>{data?.vbank_nm}</p>
                        </li>
                        <li>
                        <p className="s_title body-02 color-txt-muted">계좌 번호</p>
                        <p className={"s_desc body-04-long color-txt-normal " + style['s_desc']}>{data?.vbank_num}</p>
                        </li>
                        <li>
                        <p className="s_title body-02 color-txt-muted">예금주</p>
                        <p className={"s_desc body-04-long color-txt-normal " + style['s_desc']}>{data?.vbank_company_nm}</p>
                        </li>
                        <li>
                        <p className="s_title body-02 color-txt-muted">입금 금액</p>
                        <p className={"s_desc body-04-long color-txt-normal " + style['s_desc']}>{data?.vbank_price}</p>
                        </li>
                        <li>
                        <p className="s_title body-02 color-txt-muted">입금 기한</p>
                        <p className={"s_desc body-04-long color-txt-normal " + style['s_desc']}>
                            {data?.vbank_expire}
                        </p>
                        </li>
                    </ul>
                    <div className={"button_box section " + style['button_box']}>
                        <button 
                            type="button" 
                            className={"button btn_01 outlined label_on large btn-04 color-btn-01 txt radius-sm " + style['button']}
                            onClick={()=>{
                                navigate("/order/general");
                            }}
                            >
                        돌아가기
                        </button>
                    </div>
                    </div>
                </section>
            </div>
        </>
    )
}



export default OrderVBank;