import { Link } from 'react-router-dom';
import { ApiEventEventBind } from 'api/Event';
import { ApiPromotionGetBindGift } from 'api/Promotion';
import BookpalLink from 'component/common/custom/BookpalLink';
import { useMemberInfo } from 'utils/State';

import Header from 'component/common/header/Header';
import MobileNav from 'component/common/nav/MobileNav';

import Footer from 'component/common/footer/Footer';
import TopBtn from 'component/common/button/top/TopBtn';

import useSelectModal from 'component/common/modal/useSelectModal';
import SubNav from 'component/common/nav/SubNav';


import style from './style/Promotion.module.scss';


import img_promotion_event from 'assets/images/promotion/event.png';
import img_promotion_download from 'assets/images/promotion/download.svg';
import { useEffect, useState } from 'react';
import useAlertModal from 'component/common/modal/useAlertModal';



function BundleEventGift({ id }){

    const modalData = {
        all:"전체",
        select1:"select option 01",
        select2:"select option 02",
    }
    const [Modal, modalShow, selectKey] = useSelectModal(modalData, "all");

    const [data, setData] = useState(null);

    const [AlertModal, alert] = useAlertModal();
    const memberInfo = useMemberInfo();


    const eventSubmit = ApiEventEventBind({
        onSuccess:(data)=>{
            if(data?.status===true){
                setData(data?.result);
            }
        },
        onError:(e)=>{

        },
    });

    const getEventSubmit = ApiPromotionGetBindGift({
        onSuccess:(data)=>{
            console.log(data);
            if(data?.status === true){
                alert("이벤트",data?.result?.message, ()=>{
                    eventSubmit({
                        gift_id:id,
                    });
                })
            }
        },
        onError:(e)=>{

        },
    });

    const handlerGetEventSubmit = ()=>{
        getEventSubmit({
            pid:id,
        })
    }

    useEffect(()=>{
        eventSubmit({
            gift_id:id,
        });
    },[]);

    if(data === null){
        return null;
    }


    return (
        <>
            <Header 
                useMobile={false}
            />
            <MobileNav />
            {/* <!-- 서브메뉴 --> */}
            <SubNav 
                name="묶음이벤트" 
                useHome={true} 
                usePc={true}
                useMobile={true}
                useBtnPrev={true}
                useBtnHome={true}
                setPcTop={"64px"}
                setTop={"0px"}
                 />
            {/* <!-- //서브메뉴 --> */}

            {/* <!-- container --> */}
            <div id="container" className={style['container']}>
 
                {/* <!-- 섹션 --> */}
                <section className={"section wrapper event_temp " + style['section']}>
                    <div className={"event_header " + style['event_header']}>

                        <p 
                            className={"images"}
                            style={{
                                backgroundColor:data?.bind_info?.paper_bgcolor,
                            }}
                            ><img 
                                src={data?.bind_info?.paper_img} 
                                style={{width:"100%"}}
                                /></p>
                        

                        {/* <!-- 묶음이벤트 버튼--> */}
                        <div className={"btns " + style['btns']} style={{backgroundColor: data?.bind_info?.paper_bgcolor}}>

                            {(()=>{
                                switch(data?.status){
                                    //받기가능
                                    case 1:
                                        return (
                                            <button 
                                                type="button" 
                                                style={{
                                                    backgroundColor: data?.bind_info?.paper_btn_bgcolor,
                                                    color: data?.bind_info?.paper_btn_color,
                                                }} 
                                                className={"white-always txt radius-full button " + style['button'] + " " + style['txt']}
                                                onClick={()=>{
                                                    handlerGetEventSubmit()
                                                }}
                                                >
                                                <p>선물이용권 <span>{data?.bind_info?.bind_gift_cnt}</span>장 받기!</p>
                                                <img src={img_promotion_download} />
                                            </button>
                                        )
                                    //받기완료
                                    case 2:
                                        return (
                                            <button type="button" className="white-always txt radius-full">
                                                <p>선물이용권 받기 완료</p>
                                            </button>
                                        )
                                    //선착순만료
                                    case 3:
                                        return (
                                            <button type="button" className="white-always txt radius-full">
                                                <p>선물소장권 선착순 만료</p>
                                            </button>
                                        )
                                    //종료
                                    case 4:
                                        return (
                                            <button type="button" className="white-always txt radius-full">
                                                <p>이벤트 종료</p>
                                            </button>
                                        )
                                }
                            })()}
                        </div>

                    </div>
                    <div className={"typeList " + style['typeList']}>

                        {/* <!-- 타이틀 --> */}
                        <div className={"tit_text " + style['tit_text']}>
                            <h2 className={"title display-01 color-txt-normal text_overflow " +style['title']}>
                                {data?.bind_info?.title}
                            </h2>
                        </div>

                        {/* <!-- 그리드 --> */}
                        <div className="template temp_list temp_list_type template_row">
                            <div className="list">
                                <ul>
                                    {data?.item && Array.isArray(data?.item) && data?.item?.length > 0 ?
                                        data.item.map((item, index)=>{

                                            return (
                                                <li>
                                                    <BookpalLink 
                                                        to={"/content/"+item?.ct_type+"/"+item?.bid}
                                                        check={{
                                                            view_type:memberInfo?.view_type,
                                                            work_adult:item?.adult,
                                                        }}
                                                        alert={alert}
                                                        >
                                                        <div className="cover_img radius-xs">
                                                            {item?.adult ? <p className="ic_adult badge_adult_normal"><img src="/images/ic_adult.svg" /></p> : null}
                                                            <img src={item?.cover} />
                                                        </div>
                                                        <div className="info_txt spacing_04">
                                                            <div className="info">
                                                                <span className="color-txt-05 caption-02">{item?.genre_kor}</span>
                                                            </div>
                                                            <div className="title color-txt-normal title-book-02-m text_overflow2">{item?.title}</div>
                                                            <div className="writer color-txt-03 body-01 text_overflow">{item?.wr_nick}</div>
                                                        </div>
                                                    </BookpalLink>
                                                </li>
                                            )
                                        })
                                    :null}
                                    
                                </ul>
                            </div>
                        </div>

                    </div>
                </section>

            </div>
            <Modal />
            <AlertModal />
            {/* <!-- container --> */}
            <Footer />
            <TopBtn />
        </>
    );
}


export default BundleEventGift;