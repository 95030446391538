import { useEffect, useRef, useState } from 'react';
import {Routes, Route, Navigate, useLocation} from 'react-router-dom';

import { ApiGenreGenreList } from 'api/Genre';

import Header from 'component/common/header/Header';
import MobileNav from 'component/common/nav/MobileNav';

import Footer from 'component/common/footer/Footer';
import TopBtn from 'component/common/button/top/TopBtn';

import SubMenuNav from './nav/SubMenuNav';

import NovelCommonSub from './sub/NovelCommonSub';
import Romance from "./sub/Romance";
import RoFan from "./sub/RoFan";
import BL from "./sub/BL";
import Fantasy from "./sub/Fantasy";
import HighLevel from "./sub/HighLevel";
import Ranking from "./sub/Ranking";
import Freedom from "./sub/Freedom";
import Episode from "./sub/Episode";
import useConfirmCustomModal from 'component/common/modal/useConfirmCustomModal';
import BookpalKeepAlive from 'component/common/custom/BookpalKeepAlive';




function NovelSub(){

    // const location = useLocation();

    // const genrePathList = {
    //     "/novel/romance":"romance",
    //     "/novel/rofan":"romancefantasy",
    //     "/novel/bl":"bl",
    //     "/novel/fantasy":"fantasy",
    //     "/novel/highlevel":"29plus",
    //     "/novel/freedom":"freedom",
    //     "/novel/episode":"epub",
    // };

    // const contentRef = useRef(null);

    // const [genre, setGenre] = useState(null);
    // const [options, setOptions] = useState({});
    // const [mdSection, setMdSection] = useState(null);
    // const [filterList, setFilterList] = useState(null);
    // const [sortList, setSortList] = useState(null);

    // const [ConfirmModal, confirm] = useConfirmCustomModal();


    // const [data, dataSubmit, initData, paging, setData] = ApiGenreGenreList({
    //     bottomRef:contentRef,
    //     data:{
    //         ...options,
    //         ct_type:"novel",
    //         tab:genre,
    //     },
    //     onSuccess:(_data)=>{
    //         if(_data?.status === true){
    //             if(!!_data?.result?.md_section && _data?.result?.paging?.current_page === 1){
    //                 setMdSection(_data?.result?.md_section);
    //             }
    //             if(!!_data?.result?.selbox?.filter && _data?.result?.paging?.current_page === 1){
    //                 setFilterList(_data?.result?.selbox?.filter);
    //             }
    //             if(!!_data?.result?.selbox?.sort && _data?.result?.paging?.current_page === 1){
    //                 setSortList(_data?.result?.selbox?.sort);
    //             }
    //             return;
    //         }
    //         // console.log(data);
    //         if(!data){
    //             confirm({
    //                 title:"에러",
    //                 content:"통신에 실패하였습니다.새로고침 하시겠습니까?",
    //                 successBtnTitle:"새로고침",
    //                 cancleBtnTitle:"아니요.",
    //                 successAction:()=>{
    //                     dataSubmit();
    //                 }
    //             })
    //         }
    //     },
    //     onError:(e)=>{
    //         // console.log(e);
    //         if(!data){
    //             confirm({
    //                 title:"에러",
    //                 content:"통신에 실패하였습니다.새로고침 하시겠습니까?",
    //                 successBtnTitle:"새로고침",
    //                 cancleBtnTitle:"아니요.",
    //                 successAction:()=>{
    //                     dataSubmit();
    //                 }
    //             })
    //         }
    //     }
    // });
    

    // useEffect(()=>{
    //     initData();
    //     setMdSection(null);
    //     // setFilterList(null);
    //     // setSortList(null);
    //     setOptions({});
    //     if(!!genre){
    //         dataSubmit();
    //     }
    // },[genre]);

    // useEffect(()=>{
    //     initData();
    //     if(!!genre){
    //         dataSubmit();
    //     }
    // },[options])

    // useEffect(()=>{
    //     initData();
    //     setGenre(genrePathList[location?.pathname]);
    // },[location?.pathname]);


    // const Sub = ()=>{
    //     return (
    //         <NovelCommonSub 
    //             ref={contentRef} 
    //             md={mdSection} 
    //             data={data} 
    //             filterList={filterList} 
    //             sortList={sortList} 
    //             options={options} 
    //             setOptions={setOptions}
    //             />
    //     )
    // }

    return (
        <>
            <Header title={"웹소설"} />
            <MobileNav />
            {/* <!-- 서브메뉴 --> */}
            <SubMenuNav page="/novel"/>
            {/* <!-- //서브메뉴 --> */}
            <Routes>
                <Route path='/romance' exact element={
                    <BookpalKeepAlive>
                        <Romance />
                    </BookpalKeepAlive>
                } />
                <Route path='/rofan' exact element={
                    <BookpalKeepAlive>
                        <RoFan />
                    </BookpalKeepAlive>
                } />
                <Route path='/bl' exact element={
                    <BookpalKeepAlive>
                        <BL />
                    </BookpalKeepAlive>
                } />
                <Route path='/fantasy' exact element={
                    <BookpalKeepAlive>
                        <Fantasy />
                    </BookpalKeepAlive>
                } />
                <Route path='/highlevel' exact element={
                    <BookpalKeepAlive>
                        <HighLevel />
                    </BookpalKeepAlive>
                } />

                {/* <!-- 자유연재 md섹션위치 개발 요망, 디자인 다름 --> */}
                <Route path='/freedom' exact element={
                    <BookpalKeepAlive>
                        <Freedom />
                    </BookpalKeepAlive>
                } /> 
                <Route path='/episode' exact element={
                    <BookpalKeepAlive>
                        <Episode />
                    </BookpalKeepAlive>
                } />
                <Route path='/ranking' exact element={(
                    <BookpalKeepAlive>
                        <Ranking />
                    </BookpalKeepAlive>
                )} />
                <Route path="*" element={<Navigate to="romance" replace={true} />} />
            </Routes>

            {/* <Footer /> */}
            <TopBtn />
            {/* <ConfirmModal /> */}
        </>
    )
}



export default NovelSub;