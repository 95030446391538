import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import { ApiAuthFindPwReset, ApiAuthFindPwResetId } from 'api/Auth';
import SubNav from "component/common/nav/SubNav";

import style from '../style/User.module.scss';
import useAlertModal from 'component/common/modal/useAlertModal';


function FindPwReset(){

    const location = useLocation();
    const navigate = useBookpalNavigate();

    const queryParams = new URLSearchParams(location.search);

    const code = queryParams.get("code");
    
    const [AlertModal, alert] = useAlertModal();

    const [initLoading, setInitLoading] = useState(true);
    const [id, setId] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [submit, setSubmit] = useState(false);

    const [errorCheck, setErrorCheck] = useState({
        password:false,
        passwordConfirm:false,
    });

    const [error, setError] = useState({
        password:"",
        passwordConfirm:"",
    });
    

    const checkPassword = (password) => {
        if(password == ""){
            setError({
                ...error,
                password:"비밀번호를 입력해주세요."
            });
            return;
        }
        console.log(password.length)
        if(password.length < 8 || password.length > 16){
            
            setError({
                ...error,
                password:"비밀번호는 8~20자로 입력해주세요."
            });
            return;
        }

        // 패턴 체크: 영문 대문자, 소문자, 숫자, 특수문자 중 3종류 이상
        const regexUpperCase = /[A-Z]/;
        const regexLowerCase = /[a-z]/;
        const regexNumber = /[0-9]/;
        const regexSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

        let typesCount = 0;
        if (regexUpperCase.test(password)) typesCount++;
        if (regexLowerCase.test(password)) typesCount++;
        if (regexNumber.test(password)) typesCount++;
        if (regexSpecialChar.test(password)) typesCount++;

        if (typesCount < 3) {
            setError({
                ...error,
                password:"비밀번호는 8~20자의 영문 대/소문자, 숫자, 특수문자 등 3종류 이상으로 조합해 주세요."
            });
            return;
        }


        setError({
            ...error,
            password:""
        });
        setErrorCheck({
            ...errorCheck,
            password:true
        });
        // checkSubmit();
    }

    const checkPasswordConfirm = (password_confirm) => {
        if(password != password_confirm){
            // setErrLoginPasswordConfirm("비밀번호가 일치하지 않습니다.");
            setError({
                ...error,
                passwordConfirm:"비밀번호가 일치하지 않습니다."
            });
            return;
        }
        setError({
            ...error,
            passwordConfirm:""
        });
        setErrorCheck({
            ...errorCheck,
            passwordConfirm:true
        });
        // checkSubmit();
    }

    const submitId = ApiAuthFindPwResetId({
        onSuccess:(data)=>{
            console.log(data);

            if(data?.status === true){
                setId(data?.result?.mb_id);
                setInitLoading(false);
                return;
            }

            alert('알림','잘못된 접근입니다.',()=>{
                navigate(-1);
            });
            
        },
        onError:(e)=>{
            alert("에러","통신에 실패하였습니다.\n다시 시도해 주세요.",()=>{
                navigate(-1);
            });
        }
    });

    const submitReset = ApiAuthFindPwReset({
        onSuccess:(data)=>{
            // console.log(data);
            if(data?.status !== true){
                setError({
                    ...error,
                    password:data?.message
                })
                // alert(data?.message);
                return;
            }

            alert("비밀번호 변경","비밀번호가 변경되었습니다.",()=>{
                navigate("/", {replace:true});
            });
        },
        onError:(e)=>{
            alert("비밀번호 변경","통신에 실패하였습니다.\n다시 시도해 주세요.",()=>{
                navigate(-1);
            });
        }
    });

    const handlerSubmitReset = () => {
        submitReset({
            code:code,
            password:password,
            password_confirm:passwordConfirm,
        });
    };

    const handlerChangePassword = (e)=>{
        e.preventDefault();
        setPassword(e.target.value);
        checkPassword(e.target.value);
    }

    const handlerChangePasswordConfirm = (e)=>{
        e.preventDefault();
        setPasswordConfirm(e.target.value);
        checkPasswordConfirm(e.target.value);
    }


    useEffect(()=>{
        submitId({
            code:code
        })
    },[]);

    useEffect(()=>{
        if(errorCheck.password == false || errorCheck.passwordConfirm == false){
            console.log(false);
            setSubmit(false);
            return;
        }

        if(error.password == "" && error.passwordConfirm == ""){
            console.log(true);
            setSubmit(true);
            return;
        }
        setSubmit(false);
    },[error])

    if(initLoading){
        return (
            <>
                <AlertModal />
            </>
        );
    }

    return (
        <>
            <SubNav 
                name="비밀번호 재설정" 
                usePc={false} 
                useMobile={true} 
                setTop={'0px'} 
                useBtnPrev={true} 
            />
            
            {/* <!-- container --> */}
            <div id="container" className={"s_wrap wrapper " + style['s_wrap'] + " " + style['wrapper'] + " " + style['container']}>
                <section className={"find_box " + style['find_box']} style={{margin:"0"}}>
                    <p className={"title color-txt-normal title-04 " + style['title']}>비밀번호 재설정</p>
                    <div className={"area_box color-bg-01 radius-lg color-border-01 border-soild border-line " + style['area_box']}>
                        <div className={"logo_title " + style['logo_title']}>
                            <p className={"logo " + style['logo']}></p>
                            <p className={"body-01 color-txt-muted label_txt " + style['label_txt']}>당신의 상상이 이야기가 되는 곳</p>
                        </div>
                        <ul className={"input_box " + style['input_box']}>
                            <li className="readonly_txt">
                                <p className={"input_txt body-01 color-txt-01 " + style['input_txt']}>아이디</p>
                                <div className="text_input border-line border-soild radius-sm color-bg-02 color-border-01">
                                    <input type="text" className="color-txt-muted body-03" value={id} readOnly={true} disabled={true} />
                                </div>
                            </li>
                            {/* <!-- 잘못되었을때 error_text 추가 --> */}
                            <li className={error?.password ? "error_text" : ""}>
                                <p className={"input_txt body-01 color-txt-01 " + style['input_txt']}>새 비밀번호</p>
                                <div className="text_input border-line border-soild radius-sm color-bg-01 color-border-02">
                                    <input 
                                        type="password" 
                                        placeholder="8~20자리 영문 대/소문자, 숫자, 특수문자" 
                                        className="color-txt-normal body-03" 
                                        value={password}
                                        onChange={handlerChangePassword}
                                        />
                                    <p className="right_btns">
                                        <span className="btnClear"></span>
                                        <span className="Error"></span>
                                    </p>
                                </div>
                                <p className="bottom_txt caption-02 color-txt-05">{error?.password}</p>
                            </li>
                            <li className={error?.passwordConfirm ? "error_text" : ""}>
                                <p className={"input_txt body-01 color-txt-01 " + style['input_txt']}>비밀번호 확인</p>
                                <div className="text_input border-line border-soild radius-sm color-bg-01 color-border-02">
                                    <input 
                                        type="password" 
                                        placeholder="재입력" 
                                        className="color-txt-normal body-03" 
                                        value={passwordConfirm}
                                        onChange={handlerChangePasswordConfirm}
                                        />
                                    <p className="right_btns">
                                        <span className="btnClear"></span>
                                        <span className="Error"></span>
                                    </p>
                                </div>
                                <p className="bottom_txt caption-02 color-txt-05">{error?.passwordConfirm}</p>
                            </li> 
                        </ul>
                        <p className={"button_box " + style['button_box']}>
                            {/* <!-- 비활성화일땐  disabled='disabled' --> */}
                            <button 
                                type="button" 
                                className={"radius-sm btn-04 txt btn_01 large label_on filled button " + style['button']} 
                                disabled={!submit}
                                onClick={handlerSubmitReset}
                                >변경하기</button> 
                        </p>
                        <p className={"info_txt color-txt-muted body-01-long " + style['info_txt']}>
                            문의사항이 있으시면 아래 주소로 문의 바랍니다. <br />
                            bookpal@bookpal.co.kr
                        </p>
                    </div>
                </section>
            </div>
            <AlertModal />
            {/* <!-- container --> */}
        </>
    )
}




export default FindPwReset;