import { useEffect, useRef, useState } from 'react';
import { ApiBookChapterList } from 'api/Book';


import style from '../style/Contents.module.scss';

import img_badge_free from 'assets/images/contents/badge_free-LightMode.svg';
import img_badge_plusfree from 'assets/images/contents/badge_plusfree.svg';

import img_toon_thumbnail from 'assets/images/contents/toon_thumbnail.png';
import Novel from './episode_list/Novel';
import Epub from './episode_list/Epub';
import Toon from './episode_list/Toon';
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';

function EpisodeList({ type, info, alert, buyConfirm }){

    const _info = info || false;
    const contentRef = useRef(null);

    const navigate = useBookpalNavigate();

    const [viewonly, setViewonly] = useState(false);
    const [epub, setEpub] = useState(false);
    const [order, setOrder] = useState(null);

    const [readInfo, setReadInfo] = useState(null);
    

    const [isInit, setIsInit] = useState(false);
    

    // const [data, setData] = useState(null);

    const [data, dataSubmit, initData, paging, setData] = ApiBookChapterList({
        data:{
            bid:_info?.bid,
            viewonly:viewonly,
            // epub:_info?.epub ?? null,
            epub:(type === "epub"),
            order:order,
        },
        onSuccess:(data)=>{
            if(data?.status === true){
                if(data?.result?.read_info){
                    if(data?.result?.paging?.current_page === 1){
                        setReadInfo(data?.result?.read_info);
                    }
                }
            }
        },
        bottomRef:contentRef,
    })

    
    useEffect(()=>{
        dataSubmit();
    },[])

    useEffect(()=>{
        initData();
        dataSubmit();
    },[viewonly, epub, order])



    // console.log(data);
    if(data === null){
        return <>
        <div style={{height:"300px"}}></div>
        </>
    }


    return (
        <>
            <div className={"tit_con clearfix " + style['tit_con']}>
                <div className={"left " + style['left']}>
                    {_info?.ct_type !== "epub" ? 
                    <label className={"medium toggle_btn " + style['medium'] + " " + style['toggle_btn']}>
                        <input 
                            role="switch" 
                            type="checkbox" 
                            checked={viewonly}
                            onChange={(e)=>{
                                setViewonly(e.target.checked);
                            }}
                            />
                        <span className="color-txt-05 btn-03">열람회차</span>
                    </label>
                    :null}
                    {_info?.ct_type !== "epub" ? 
                    <button 
                        type="button" 
                        className={"button " + style['button'] + " " + style['selectChoose']} id="selectChoose" 
                        data-ct_type={_info?.ct_type}
                        data-bid={_info?.bid}
                        onClick={(e)=>{
                            const type = e.currentTarget.getAttribute("data-ct_type");
                            const bid = e.currentTarget.getAttribute("data-bid");
                            navigate("/content_buy/"+type+"/"+bid);
                        }}
                        ><span className="btn-03 color-btn-03-txt">선택구매</span></button>
                    :null}
                </div>
                <div className={"right button_dot " + style['right'] + " " + style['button_dot']}>
                    {/* <!-- 클릭시 click 추가--> */}
                    <button 
                        type="button" 
                        className={"button btn-03 " + style['button'] + " " + (order === "asc" || (order === null && readInfo?.my_last_cid === 0) ? style['click'] : "")}
                        onClick={()=>{
                            setOrder("asc");
                        }}
                        ><span className="color-btn-03-txt">처음부터</span></button>
                    <button 
                        type="button" 
                        className={"button btn-03 click " + style['button'] + " " + (order === "desc" || (order === null && readInfo?.my_last_cid !== 0) ? style['click'] : "")}
                        onClick={()=>{
                            setOrder("desc");
                        }}
                        ><span className="color-btn-03-txt">최신순</span></button>
                </div>
            </div>
            <div ref={contentRef} className={"temp_list template_column book_list " + style['book_list']}>
                {/* <!-- 웹소설 회차--> */}
                {data && Array.isArray(data) && data?.length > 0 ?
                <ul className={"list novel_list " + style['list'] + " " + (type === "toon" ? style['toon_list'] : style['novel_list'])}>

                    {(info?.ct_type === "novel") && data && Array.isArray(data) ? 
                        data.map((item, index)=>{
                            // console.log(item)
                            return <Novel key={index} data={item} alert={alert} buyConfirm={buyConfirm}/>
                        })
                    :null}

                    {(info?.ct_type === "toon") && data && Array.isArray(data) ? 
                        data.map((item, index)=>{
                            // console.log(item)
                            return <Toon key={index} data={item} alert={alert} buyConfirm={buyConfirm}/>
                        })
                    :null}

                    {info?.ct_type === "epub" && data && Array.isArray(data) ? 
                        data.map((item, index)=>{
                            // console.log(item)
                            return <Epub key={index} bid={_info?.bid} data={item} alert={alert} buyConfirm={buyConfirm}/>
                        })
                    :null}
                    
                </ul>
                :<div className="no_data small color-txt-04 body-02-long">{viewonly ? "열람한 회차가 없습니다.":""}</div>}
            </div>
        </>
    )
}



export default EpisodeList;