// 데이터를 저장할 때 유효기간을 설정하는 함수
const setStorageData = (key, data, expirationMinutes = null) => {
    const now = new Date();
    if(expirationMinutes && expirationMinutes !== 0 && (typeof expirationMinutes) === 'number'){
        const expirationTime = now.getTime() + (expirationMinutes * 60 * 1000); // 현재 시간에 유효기간(분)을 더함
        const item = {
            data: data,
            expires: expirationTime,
        };
        localStorage.setItem(key, JSON.stringify(item)); // JSON 형태로 저장
    } else {
        const item = {
            data: data,
            expires: null,
        };
        localStorage.setItem(key, JSON.stringify(item)); // JSON 형태로 저장
    }
};
  
  // 데이터를 가져올 때 유효기간을 체크하여 만료된 데이터는 제거하는 함수
const getStorageData = (key, expirationMinutes = null) => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
        return null;
    }
    try {
        const item = JSON.parse(itemStr);
        const now = new Date().getTime();
        if (item.expires != null && now > item.expires) {
            // 만료된 데이터는 제거하고 null 반환
            localStorage.removeItem(key);
            return null;
        }
        if(expirationMinutes != null && (typeof expirationMinutes) === 'number'){
            const extendedExpiration = now + (expirationMinutes * 60 * 1000);
            item.expires = extendedExpiration;
            localStorage.setItem(key, JSON.stringify(item));
        }
        return item.data; // 만료되지 않은 경우 데이터 반환
    } catch (error) {
        console.error('Error parsing localStorage data:', error);
        return null;
    }
};

const removeStorageData = (key) => {
    localStorage.removeItem(key);
};

const setKeywordData = (keyword, maxLength = 10)=>{
    try {
        const key = "use_keyword";
        let _data = JSON.parse(getStorageData(key));
        const _tmp_data = [];

        if(!_data){
            _data = [];
        }

        _tmp_data.push(keyword);
        
        _data.map((item)=>{
            if(_tmp_data.length >= maxLength){
                return null;
            }
            if(item == keyword){
                return null;
            }
            _tmp_data.push(item);
            return null;
        })
        
        setStorageData(key, JSON.stringify(_tmp_data));
    } catch (error) {
        console.error('Error parsing localStorage data:', error);
        return null;
    }
}

const delKeywordData = (keyword)=>{
    try {
        const key = "use_keyword";
        let _data = JSON.parse(getStorageData(key));
        const _tmp_data = [];

        if(!_data){
            _data = [];
        }
        
        _data.map((item)=>{
            if(item == keyword){
                return null;
            }
            _tmp_data.push(item);
            return null;
        })
        
        setStorageData(key, JSON.stringify(_tmp_data));
    } catch (error) {
        console.error('Error parsing localStorage data:', error);
        return null;
    }
}

const initKeywordData = ()=>{
    try {
        const key = "use_keyword";
        setStorageData(key, JSON.stringify([]));
    } catch (error) {
        console.error('Error parsing localStorage data:', error);
        return null;
    }
}

const getKeywordData = ()=>{
    try {
        const key = "use_keyword";
        const _data = JSON.parse(getStorageData(key));

        return _data;
    } catch (error) {
        console.error('Error parsing localStorage data:', error);
        return null;
    }
}

const setPopupData = (id, expirationMinutes)=>{
    try {
        const key = "use_popup";
        const now = new Date().getTime();
        let _data = JSON.parse(getStorageData(key));
        let _expirationMinutes = expirationMinutes;
        if( ! ( expirationMinutes && expirationMinutes !== 0 && (typeof expirationMinutes) === 'number' ) ){
            _expirationMinutes = 60 * 24;
        }
        if(!_data){
            _data = [];
        }

        const extendedExpiration = now + (_expirationMinutes * 60 * 1000);

        _data.push({
            id:id,
            expire:extendedExpiration,
        });
        
        setStorageData(key, JSON.stringify(_data));
    } catch (error) {
        console.error('Error parsing localStorage data:', error);
        return null;
    }
}

const checkPopupData = (id)=>{
    try {
        const key = "use_popup";
        const now = new Date().getTime();
        const _data = JSON.parse(getStorageData(key));
        const _new_data = [];
        const _check = [];
        
        if(!_data){
            return false;
        }

        _data.map((item)=>{
            if(now > item.expire){
                
            } else {
                if(item?.id == id){
                    _check.push(true);
                }
                _new_data.push(item);
            }
            return null;
        })
        
        setStorageData(key, JSON.stringify(_new_data));
        if(_check.length > 0){
            return true;
        }
        return false;
    } catch (error) {
        console.error('Error parsing localStorage data:', error);
        return null;
    }
}



export const setViewerData = (ct_type, mode, bid, cid, per, maxLength = 1000)=>{
    try {
        const key = "use_viewer";
        const data_key = ct_type + "_" + cid + "_" + mode;
        let _data = JSON.parse(getStorageData(key));
        const _tmp_data = [];

        if(!_data){
            _data = [];
        }

        // _tmp_data.push(keyword);
        let check = false;

        _data.map((item)=>{
            if(_tmp_data.length >= maxLength){
                return;
            }
            if(item?.data_key == data_key){
                item.per = per;
                check = true;
            } else if(item?.ct_type == ct_type && item?.cid == cid){
                return;
            } else if(item?.bid == bid){
                return;
            }
            _tmp_data.push(item);
            return;
        });

        if(!check){
            _tmp_data.unshift({
                data_key:data_key, 
                ct_type:ct_type,
                per:per,
                bid:bid,
                cid:cid,
            });
        }
        
        setStorageData(key, JSON.stringify(_tmp_data));
    } catch (error) {
        console.error('Error parsing localStorage data:', error);
        return null;
    }
}

export const getViewerData = (ct_type, mode, cid)=>{
    try {
        const key = "use_viewer";
        const _data = JSON.parse(getStorageData(key));
        const data_key = ct_type + "_" + cid + "_" + mode;
        let per = 0; 

        if(!_data){
            _data = [];
        }
        _data.map((item)=>{
            if(item?.data_key == data_key){
                per = item?.per ?? 0;
            }
            return;
        });

        return per;
    } catch (error) {
        console.error('Error parsing localStorage data:', error);
        return null;
    }
}

export const setViewerToastData = (mode)=>{
    try {
        const key = "use_viewer_toast";
        let _data = JSON.parse(getStorageData(key));
        const _tmp_data = [];

        if(!_data){
            _data = [];
        }

        let check = false;
        _data.map((item)=>{
            if(item == mode){
                check = true;
            }
            _tmp_data.push(item);
        });

        if(!check){
            _tmp_data.push(mode);
        }

        setStorageData(key, JSON.stringify(_tmp_data));
    } catch (error) {
        console.error('Error parsing localStorage data:', error);
        return null;
    }
}

export const getViewerToastData = (mode)=>{
    try {
        const key = "use_viewer_toast";
        const _data = JSON.parse(getStorageData(key));
        let check = false;

        if(!_data){
            _data = [];
        }
        _data.map((item)=>{
            if(item == mode){
                check = true;
            }
        });

        return check;
    } catch (error) {
        console.error('Error parsing localStorage data:', error);
        return null;
    }
}



export const setHistoryData = (history, maxLength = 1000)=>{
    try {
        const key = "use_history";
        let _data = JSON.parse(getStorageData(key));
        const _tmp_data = [];
        let check = false;

        if(!_data){
            _data = [];
        }
        _tmp_data.push(history);
        _data.map((item)=>{
            if(_tmp_data.length >= maxLength){
                return;
            }
            // if(item == history){
            //     check = true;
            //     return;
            // }
            _tmp_data.push(item);
        })

        // if(!check){
            
        // }
        
        setStorageData(key, JSON.stringify(_tmp_data));
    } catch (error) {
        console.error('Error parsing localStorage data:', error);
        return null;
    }
}

export const isHistoryData = (history)=>{
    try {
        const key = "use_history";
        let _data = JSON.parse(getStorageData(key));
        let check = false;

        if(!_data){
            _data = [];
        }

        _data.map((item)=>{
            if(item == history){
                check = true;
            }
        })

        return check;
    } catch (error) {
        console.error('Error parsing localStorage data:', error);
        return null;
    }
}


export {
    setStorageData,
    getStorageData,
    removeStorageData,
    setPopupData,
    checkPopupData,
    setKeywordData,
    getKeywordData,
    delKeywordData,
    initKeywordData
};