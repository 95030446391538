import { useEffect } from 'react';
import { useDispatch, useSelector  } from 'react-redux';
import SubNav from "component/common/nav/SubNav";

import style from '../style/User.module.scss';
import { useNavigate } from "react-router-dom";
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';

import useAgreeModal from 'component/common/modal/useAgreeModal';
import { useState } from "react";

import { getAppsDataWithUserAgent, getTodayDate } from 'utils/Common';
import { appSet } from 'utils/AppUtil';
import { ApiAuthJoinAgree, ApiAuthJoinValidator, ApiAuthJoin } from 'api/Auth';
import { actionSetToken, actionSetLastSocialLoginType, actionSetLastSocialLoginDate } from 'action';



function Join(){

    const [loading, setLoading] = useState(true);
    const [agreeTxt, setAgreeTxt] = useState({});
    const [agreeAll, setAgreeAll] = useState(false);
    const [agree, setAgree] = useState({
        fourteen:false,
        service:false,
        pay:false,
        privacy:false,
        event:false,
    });

    const [AgreeModal, AgreeModalShow] = useAgreeModal("");

    const [isSubmit, setIsSubmit] = useState(false);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);

    const navigator = useBookpalNavigate();
    const dispatch = useDispatch();

    const agree_1 = agreeTxt['service'] ?? "";
    const agree_2 = agreeTxt['pay'] ?? "";
    // const agree_3 = agreeTxt['privacy'] ?? "";
    const agree_3 = ' \
            <table className="table_box color-border-01 border-top-line border-soild"> \
                <thead className="thead color-bg-02 color-txt-01 title-sub-02-b"> \
                    <tr> \
                        <th>수집목적</th> \
                        <th>수집항목</th> \
                        <th>보유기간</th> \
                    </tr> \
                </thead> \
                <tbody className="tbody"> \
                    <tr className="color-border-01 border-top-line border-bottom-line border-soild body-01-long color-txt-03"> \
                        <td>회원가입 및 로그인</td> \
                        <td rowspan="3">아이디, 비밀번호, 별명(닉네임), 이메일, 휴대전화번호, 디바이스번호(모바일기기정보)</td> \
                        <td rowspan="3">부정이용 방지 보존 목적으로 1년 보관 후 파기</td> \
                    </tr> \
                    <tr className="color-border-01 border-top-line border-bottom-line border-soild body-01-long color-txt-03"> \
                        <td>본인의사 확인 및 고객상담 처리</td> \
                    </tr> \
                    <tr className="color-border-01 border-top-line border-bottom-line border-soild body-01-long color-txt-03"> \
                        <td>부정이용 방지, 비인가 사용방지 및 서비스 제공</td> \
                    </tr> \
                    <tr className="color-border-01 border-top-line border-bottom-line border-soild body-01-long color-txt-03"> \
                        <td>콘텐츠 결제 확인 및 환불 처리</td> \
                        <td>휴대전화번호, 신용카드번호, 은행계좌번호 등</td> \
                        <td>관련법령에 의해 5년 보관 후 파기</td> \
                    </tr> \
                    <tr className="color-border-01 border-top-line border-bottom-line border-soild body-01-long color-txt-03"> \
                        <td>개인화된 맞춤추천 기능 제공 목적</td> \
                        <td>단말기 모델, 하드웨어 ID, 운영체제 버전정보, 서점 및 애플리케이션 이용 정보</td> \
                        <td>회원탈퇴 시 부정이용 방지 보존 목적으로 5년 보관 후 파기</td> \
                    </tr> \
                    <tr className="color-border-01 border-top-line border-bottom-line border-soild body-01-long color-txt-03"> \
                        <td>서비스 방문 통계 처리 및 부정이용 방지 목적</td> \
                        <td>IP주소, 쿠키, 접속로그, 결제기록, 서비스 이용 기록, 불량이용 기록</td> \
                        <td>관련법령에 의해 3개월 보관 후 파기</td> \
                    </tr> \
                </tbody> \
            </table> \
            <p className="terms_txt body-02-long color-txt-01">이용자는 개인정보의 수집 및 이용 동의를 거부할 수 있습니다. 단, 회원가입 시 수집하는 최소한의 개인정보, 즉 필수 항목에 대한 수집 및 이용 동의를 거부하실 경우, 회원가입이 제한됩니다.</p> \
        \
    ';
    const agree_4 = agreeTxt['event'] ?? "";

    const [loginId, setLoginId] = useState("");
    const [loginPassword, setLoginPassword] = useState("");
    const [loginPasswordConfirm, setLoginPasswordConfirm] = useState("");
    const [nickname, setNickname] = useState("");
    const [email, setEmail] = useState("");

    const [errLoginId, setErrLoginId] = useState("");
    const [errNickname, setErrNickname] = useState("");
    const [errLoginPassword, setErrLoginPassword] = useState("");
    const [errLoginPasswordConfirm, setErrLoginPasswordConfirm] = useState("");
    const [errEmail, setErrEmail] = useState("");

    const bookpalAgentData = getAppsDataWithUserAgent();

    const checkSubmit = () => {
        setIsSubmit(false);
        if(loginId.trim() == "") return;
        if(loginPassword.trim() == "") return;
        if(loginPasswordConfirm.trim() == "") return;
        if(nickname.trim() == "") return;
        if(email.trim() == "") return;

        if(errLoginId != "") return;
        if(errNickname != "") return;
        if(errLoginPassword != "") return;
        if(errLoginPasswordConfirm != "") return;
        if(errEmail != "") return;

        if(!agree['fourteen']) return;
        if(!agree['service']) return;
        if(!agree['pay']) return;
        if(!agree['privacy']) return;
        setIsSubmit(true);
    };

    useEffect(()=>{
        checkSubmit();
    },[loginId, loginPassword, loginPasswordConfirm, nickname, email, errLoginId, errNickname, errLoginPassword, errLoginPasswordConfirm, errEmail, agree]);

    useEffect(()=>{
        if(
            agree['fourteen']
            && agree['service']
            && agree['pay']
            && agree['privacy']
            && agree['event']
        ){
            setAgreeAll(true);
        } else {
            setAgreeAll(false);
        }
    },[agree]);

    const getJoinAgree = ApiAuthJoinAgree({
        onSuccess:(data)=>{
            console.log(data);
            if(data?.status === true){
                setAgreeTxt({
                    ...data?.result
                });

                setLoading(false);
                return;
            }

            if(data?.code == "already_login"){
                alert('이미 로그인 되어있습니다.');
                navigator("/");
                return;
            }

            alert('서비스에 문제가 발생하였습니다. 문제가 지속될시 관리자에게 문의해 주시기 바랍니다.');

        },
        onError:(e)=>{
            alert('통신에 실패하였습니다.');
        },
    })

    const handlerGetJoinAgree = async () => {
        await getJoinAgree({});
    };

    const handlerAgreeAll = (agree) => {
        console.log(agree);
        if(agree){
            setAgree({
                fourteen:true,
                service:true,
                pay:true,
                privacy:true,
                event:true,
            });
        } else {
            setAgree({
                fourteen:false,
                service:false,
                pay:false,
                privacy:false,
                event:false,
            });
        }
    }

    const handlerAgree = (data) => {
        console.log(data);

        const setAgreeData = {
            ...agree,
            ...data
        }

        setAgree(setAgreeData);

        if(
            setAgreeData['fourteen'] 
            && setAgreeData['service']
            && setAgreeData['pay']
            && setAgreeData['privacy']
            && setAgreeData['event']
        ){

        } else {
            setAgreeAll(false);
        }
    }

    useEffect(()=>{
        handlerGetJoinAgree();
    },[]);

    

    const checkIdSubmit = ApiAuthJoinValidator({
        onSuccess:(data)=>{
            if(data.status == false && data.code == "201"){
                setErrLoginId(data?.message);
                return;
            }
            setErrLoginId("");
        },
        onError:()=>{}
    });
    const checkNicknameSubmit = ApiAuthJoinValidator({
        onSuccess:(data)=>{
            if(data.status == false && data.code == "201"){
                setErrNickname(data?.message);
                return;
            }
            setErrNickname("");
        },
        onError:()=>{}
    });
    const checkEmailSubmit = ApiAuthJoinValidator({
        onSuccess:(data)=>{
            if(data.status == false && data.code == "201"){
                setErrEmail(data?.message);
                return;
            }
            setErrEmail("");
        },
        onError:()=>{}
    })

    

    const checkId = (id) => {
        if(id == ""){
            setErrLoginId("아이디를 입력해주세요.");
            return;
        }
        if(!/^[a-z0-9_@.]+$/.test(id)){
            setErrLoginId("5글자 이상, 30자리 미만의 영문소문자/숫자로 입력해주세요.");
            return;
        }
        if(id.length < 5 || id.length >= 30){
            setErrLoginId("5글자 이상, 30자리 미만의 영문소문자/숫자로 입력해주세요.");
            return;
        }
        setErrLoginId("");
        checkIdSubmit({
            operation:"id",
            value:id,
        });
    }

    const checkNickname = (nickname) => {
        if(nickname == ""){
            setErrNickname("닉네임을 입력해주세요.");
            return;
        }
        if(!/^[a-zA-Z가-힣0-9_-]+$/.test(nickname)){
            console.log(1);
            setErrNickname("2글자 이상, 8자리 미만의 한글/영문/숫자로 입력해주세요.");
            return;
        }
        if(nickname.length < 2 || nickname.length >= 8){
            console.log(2);
            setErrNickname("2글자 이상, 8자리 미만의 한글/영문/숫자로 입력해주세요.");
            return;
        }
        setErrNickname("");
        checkNicknameSubmit({
            operation:"nickname",
            value:nickname,
        });
    }

    const checkPassword = (password) => {
        if(password == ""){
            setErrLoginPassword("비밀번호를 입력해주세요.");
            return;
        }
        if(password.length < 8 || password.length > 20){
            setErrLoginPassword("비밀번호는 8~20자로 입력해주세요.");
            return;
        }
        // 패턴 체크: 영문 대문자, 소문자, 숫자, 특수문자 중 3종류 이상
        const regexUpperCase = /[A-Z]/;
        const regexLowerCase = /[a-z]/;
        const regexNumber = /[0-9]/;
        const regexSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

        let typesCount = 0;
        if (regexUpperCase.test(password)) typesCount++;
        if (regexLowerCase.test(password)) typesCount++;
        if (regexNumber.test(password)) typesCount++;
        if (regexSpecialChar.test(password)) typesCount++;

        if (typesCount < 3) {
            setErrLoginPassword("비밀번호는 8~20자의 영문 대/소문자, 숫자, 특수문자 등 3종류 이상으로 조합해 주세요.");
            return;
        }

        setErrLoginPassword("");
    }

    const checkPasswordConfirm = (password_confirm) => {
        if(loginPassword != password_confirm){
            setErrLoginPasswordConfirm("비밀번호가 일치하지 않습니다.");
            return;
        }
        setErrLoginPasswordConfirm("");
    }

    const checkEmail = (email) => {
        if(email == ""){
            setErrEmail("이메일을 입력해주세요.");
            return;
        }
        if(!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)){
            setErrEmail("이메일 형식이 잘못되었어요.");
            return;
        }
        setErrEmail("");
        checkEmailSubmit({
            operation:"email",
            value:email,
        });
    }

    const joinSubmit = ApiAuthJoin({
        onSuccess: (data)=>{
            if(data?.status === true){
                dispatch(actionSetToken(data.result));
                dispatch(actionSetLastSocialLoginType(data.result?.login_type));
                dispatch(actionSetLastSocialLoginDate(getTodayDate()));

                const appSetData = {
                    ...data.result,
                    login_date:getTodayDate()
                }

                appSet("login", appSetData);

                navigator("/join_complete", {replace: true});
                
            } else {
                alert(data?.message);
            }
            setIsSubmitLoading(false);
        },
        onError:()=>{
            setIsSubmitLoading(false);
        }
    })

    const handlerSubmit = async () => {
        if(!isSubmit) return;

        if(isSubmitLoading) return;

        setIsSubmitLoading(true);

        await joinSubmit({
            login_id:loginId,
            login_password:loginPassword,
            nickname:nickname,
            email:email,
            is_receive:(agree['event'] ? 1 : 0),
            apps:bookpalAgentData,
        });
    };

    if(loading){
        return (
            <>
                Loading...
            </>
        )
    }

    return (
        <>
            <SubNav 
                name="회원가입" 
                usePc={false} 
                useMobile={true} 
                setTop={'0px'} 
                useBtnPrev={true} 
            />

            {/* <!-- container --> */}
            <div id="container" className={"sub_wrap wrapper " + style['container'] + " " + style['s_wrap']}>
                <section className={"join_box " + style['join_box']}>
                <p className={"title color-txt-normal title-04 " + style['title']}>회원가입</p>
                    <div className={"area_box color-bg-01 radius-lg color-border-01 border-soild border-line " + style['area_box']}>
                        <div className={"logo_title " + style['logo_title']}>
                            <p className={"logo " + style['logo']}></p>
                            <p className={"body-01 color-txt-muted label_txt " + style['label_txt']}>당신의 상상이 이야기가 되는 곳</p>
                        </div>
                        <ul className={"input_box " + style['input_box']}>
                            {/* <!-- 잘못되었을때 error_text 추가 --> */}
                            <li className={errLoginId !== "" ? 'error_text' : ''}>
                                <p className="input_txt body-01 color-txt-01">아이디</p>
                                <div className="text_input border-line border-soild radius-sm color-bg-01 color-border-02">
                                    <input 
                                        type="text" 
                                        placeholder="5글자 이상의 문자 및 숫자 " 
                                        className="color-txt-normal body-03" 
                                        value={loginId} 
                                        onChange={(e)=>{setLoginId(e.target.value);checkId(e.target.value);}} 
                                        onFocus={(e)=>{checkId(e.target.value);}}
                                    />
                                    <p className="right_btns">
                                        <span className="btnClear"></span>
                                        <span className="Error"></span>
                                    </p>
                                </div>
                                <p className="bottom_txt caption-02 color-txt-05">{errLoginId}</p>
                                {/* <!-- <p className="bottom_txt caption-02 color-txt-05">5글자 이상, 30자리 미만의 영문/숫자로 입력해주세요.</p> -->
                                <!-- <p className="bottom_txt caption-02 color-txt-05">아이디가 중복되었어요.</p> --> */}
                            </li>
                            <li className={errNickname !== "" ? 'error_text' : ''}>
                                <p className={"input_txt body-01 color-txt-01 "  + style['input_box']}>닉네임</p>
                                <div className="text_input border-line border-soild radius-sm color-bg-01 color-border-02">
                                    <input 
                                        type="text" 
                                        placeholder="2글자 이상" 
                                        className="color-txt-normal body-03" 
                                        value={nickname} 
                                        onChange={(e)=>{setNickname(e.target.value);checkNickname(e.target.value);}} 
                                        onFocus={(e)=>{checkNickname(e.target.value);}}
                                    />
                                    <p className="right_btns">
                                        <span className="btnClear"></span>
                                        <span className="Error"></span>
                                    </p>
                                </div>
                                <p className="bottom_txt caption-02 color-txt-05">{errNickname}</p>
                            </li>
                            <li className={errLoginPassword !== "" ? 'error_text' : ''}>
                                <p className="input_txt body-01 color-txt-01">비밀번호</p>
                                <div className="text_input border-line border-soild radius-sm color-bg-01 color-border-02">
                                    <input 
                                        type="password" 
                                        placeholder="8~20자리 영문 대/소문자, 숫자, 특수문자" 
                                        className="color-txt-normal body-03" 
                                        value={loginPassword} 
                                        onChange={(e)=>{setLoginPassword(e.target.value);checkPassword(e.target.value);}} 
                                        onFocus={(e)=>{checkPassword(e.target.value);}}
                                    />
                                    <p className="right_btns">
                                        <span className="btnClear"></span>
                                        <span className="Error"></span>
                                    </p>
                                </div>
                                <p className="bottom_txt caption-02 color-txt-05">{errLoginPassword}</p>
                            </li>
                            <li className={errLoginPasswordConfirm != "" ? "error_text" : ""}>
                                <p className="input_txt body-01 color-txt-01">비밀번호 확인</p>
                                <div className="text_input border-line border-soild radius-sm color-bg-01 color-border-02">
                                    <input 
                                        type="password" 
                                        placeholder="재입력" 
                                        className="color-txt-normal body-03" 
                                        value={loginPasswordConfirm} 
                                        onChange={(e)=>{setLoginPasswordConfirm(e.target.value);checkPasswordConfirm(e.target.value);}} 
                                        onFocus={(e)=>{checkPasswordConfirm(e.target.value);}}
                                    />
                                    <p className="right_btns">
                                        <span className="btnClear"></span>
                                        <span className="Error"></span>
                                    </p>
                                </div>
                                <p className="bottom_txt caption-02 color-txt-05">{errLoginPasswordConfirm}</p>
                            </li>
                            <li className={errEmail != "" ? "error_text" : ""}>
                                <p className="input_txt body-01 color-txt-01">이메일</p>
                                <div className="text_input border-line border-soild radius-sm color-bg-01 color-border-02">
                                    <input 
                                        type="email" 
                                        placeholder="사용중인 이메일 주소를 입력해주세요." 
                                        className="color-txt-normal body-03" 
                                        value={email} 
                                        onChange={(e)=>{setEmail(e.target.value);checkEmail(e.target.value);}} 
                                        onFocus={(e)=>{checkEmail(e.target.value);}}
                                    />
                                    <p className="right_btns">
                                        <span className="btnClear"></span>
                                        <span className="Error"></span>
                                    </p>
                                </div>
                                <p className="bottom_txt caption-02 color-txt-05">{errEmail}</p>
                            </li>
                        </ul>
                        <div className={"check_event " + style['check_event']}>
                            <label htmlFor="agree" className="checkbox_round check-type-01 medium label_on">
                                <input 
                                    type="checkbox" 
                                    id="agree"
                                    checked={agreeAll}
                                    onChange={(e)=>{setAgreeAll(e.target.checked);handlerAgreeAll(e.target.checked);}}
                                />
                                <span className="box"></span>
                                <span className="txt color-txt-04">모두 동의</span>
                            </label>
                            <ul className={"check_list " + style['check_list']}>
                                <li>
                                    <label htmlFor="a1" className="check check-type-01 small label_on">
                                        <input 
                                            type="checkbox" 
                                            id="a1" 
                                            checked={agree['fourteen']} 
                                            onChange={(e)=>{setAgree({...agree, fourteen: e.target.checked})}} 
                                            onClick={(e)=>{handlerAgree({fourteen: e.target.checked})}} 
                                        />
                                        <span className="box"></span>
                                        <span className="txt color-txt-04">14세 이상입니다. (필수)</span>
                                    </label>
                                </li>
                                <li>
                                    <label htmlFor="a2" className="check check-type-01 small label_on">
                                        <input 
                                            type="checkbox" 
                                            id="a2" 
                                            checked={agree['service']} 
                                            onChange={(e)=>{setAgree({...agree, service: e.target.checked})}} 
                                            onClick={(e)=>{handlerAgree({service: e.target.checked})}} 
                                        />
                                        <span className="box"></span>
                                        <span className="txt color-txt-04">서비스 이용약관 동의 (필수)</span>
                                    </label>
                                    <button type="button" className="button btn-03 color-btn-03-txt" onClick={()=>{AgreeModalShow("서비스 이용약관",agree_1);}}><span>보기</span></button>
                                </li>
                                <li>
                                    <label htmlFor="a3" className="check check-type-01 small label_on">
                                        <input 
                                            type="checkbox" 
                                            id="a3" 
                                            checked={agree['pay']} 
                                            onChange={(e)=>{setAgree({...agree, pay: e.target.checked})}}
                                            onClick={(e)=>{handlerAgree({pay: e.target.checked})}} 
                                        />
                                        <span className="box"></span>
                                        <span className="txt color-txt-04">유료 이용약관 동의 (필수)</span>
                                    </label>
                                    <button type="button" className="button btn-03 color-btn-03-txt" onClick={()=>{AgreeModalShow("유료 이용약관",agree_2);}}><span>보기</span></button>
                                </li>
                                <li>
                                    <label htmlFor="a4" className="check check-type-01 small label_on">
                                        <input 
                                            type="checkbox" 
                                            id="a4" 
                                            checked={agree['privacy']} 
                                            onChange={(e)=>{setAgree({...agree, privacy: e.target.checked})}} 
                                            onClick={(e)=>{handlerAgree({privacy: e.target.checked})}} 
                                        />
                                        <span className="box"></span>
                                        <span className="txt color-txt-04">개인정보 수집 및 이용 동의 (필수)</span>
                                    </label>
                                    <button type="button" className="button btn-03 color-btn-03-txt" onClick={()=>{AgreeModalShow("개인정보 수집 및 이용동의",agree_3);}}><span>보기</span></button>
                                </li>
                                <li>
                                    <label htmlFor="a5" className="check check-type-01 small label_on">
                                        <input 
                                            type="checkbox" 
                                            id="a5" 
                                            checked={agree['event']} 
                                            onChange={(e)=>{setAgree({...agree, event: e.target.checked})}} 
                                            onClick={(e)=>{handlerAgree({event: e.target.checked})}} 
                                            />
                                        <span className="box"></span>
                                        <span className="txt color-txt-04">이벤트, 혜택 알림 수신 동의 (선택)</span>
                                    </label>
                                    {/* <button type="button" className="button btn-03 color-btn-03-txt" onClick={()=>{AgreeModalShow(agree_4);}}><span>보기</span></button> */}
                                </li>
                            </ul>
                        </div>
                        {/* <p className={"button_box " + style['button_box']}> */}
                            {/* <!--  정상적 입력값 모두 입력상태 disabled="disabled" 제거 --> */}
                            {/* <button 
                                type="button" 
                                className={"radius-sm btn-04 txt btn_01 large label_on filled button " + style['button']} 
                                disabled={isSubmit ?"":"disabled"} 
                                onClick={handlerSubmit}
                                >회원가입하기</button>  */}
                        {/* </p> */}
                        <div className="fixed_button">
                            {/* <!-- 정상적 입력값 모두 입력상태 disabled="disabled" 제거 --> */}
                            <button 
                                type="button" 
                                className="button btn_01 filled btn-05 white-always txt" 
                                disabled={isSubmit ?"":"disabled"} 
                                onClick={handlerSubmit}
                                >회원가입하기</button>
                        </div>
                    </div>        
                </section>
            </div>
            {/* <!-- container --> */}
            <AgreeModal />
        </>
    );
}


export default Join;