import { forwardRef, useState, useEffect } from 'react';
import { ImgBadge, ImgDefault, ImgCover } from 'utils/Images';
import { useMemberInfo } from 'utils/State';
import { getViewCntConvert } from 'utils/Common';
import useSelectArrModal from 'component/common/modal/useSelectArrModal';
import GenreHomeHalfBanner from 'component/common/banner/GenreHomeHalfBanner';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BookpalLazyLoadImage from 'component/common/custom/BookpalLazyLoadImage';
import useSelectModal from 'component/common/modal/useSelectModal';
import { Link } from 'react-router-dom';
import BookpalLink from 'component/common/custom/BookpalLink';
import { useWidth } from 'utils/Common';


function getDefaultImgStyle(gb){
    const style = {};
    switch(gb){
        case "pc": 
            style['minWidth'] = "100px";
            style['minHeight'] = "150px";
            break;
        case "mobile": 
            style['minWidth'] = "90px";
            style['minHeight'] = "135px";
            break;
        // case ""
    }
    return style;
}


const ToonCommonSub = forwardRef((props, ref) =>{

    const memberInfo = useMemberInfo();
    const width = useWidth();
    
    const md = props?.md || null;
    const data = props?.data || null;
    const filterList = props?.filterList || [];
    const sortList = props?.sortList || [];
    const options = props?.options || {};
    const setOptions = props?.setOptions || (()=>{});

    const [defaultImageStyle, setDefaultImageStyle] = useState({});
    
    const handlerEndOptionToggle = (e)=>{
        const checked = e.currentTarget.checked;

        setOptions({
            ...options,
            end:(checked ? 1 : null),
        })
    }

    // const modalData = {
    //     all:"전체",
    //     select1:"select option 01",
    //     select2:"select option 02",
    // }

    const [FilterModal, showFilterModal, selectKey,setSelectKey, initSelect, getValue] = useSelectArrModal(filterList, options?.filter || "", {
        valueName:"title",
        selectFn:(key)=>{
            setOptions({
                ...options,
                filter:key,
            });
        },
    });

    const [SortModal, showSortModal, selectSortKey,setSelectSortKey, initSortSelect, getSortValue] = useSelectArrModal(sortList, options?.sort || "ranking" , {
        valueName:"title",
        selectFn:(key)=>{
            setOptions({
                ...options,
                sort:key,
            });
        },
    });

    useEffect(()=>{
        if(width > 540){
            setDefaultImageStyle(getDefaultImgStyle("pc"));
        } else {
            setDefaultImageStyle(getDefaultImgStyle("mobile"));
        }
    },[width])


    return (
        <>
            {/* <!-- container --> */}
            <div id="container" className="sub_wrap wrap">
                
                {/* <!-- 장르홈 배경하프배너(로맨스, 로판, BL, 판타지, 29+, 단행본) --> */}
                <GenreHomeHalfBanner item={md}  />

                {/* <!-- 섹션 --> */}
                <section className="section template wrapper temp_list template_column">
                    <div className="tit_con clarfix">
                        <div className="left">
                            <label className="medium toggle_btn">
                                <input role="switch" type="checkbox" checked={options?.end ? true : false} onChange={handlerEndOptionToggle}/>
                                <span className="color-txt-05 btn-03">완결</span>
                            </label>
                        </div>
                        <div className="right">
                            <a href="#none" className="select_btn select_01"><span className="color-btn-03-txt btn-03" onClick={showFilterModal}>{getValue()}</span></a>
                            <a className="select_btn select_02" onClick={showSortModal}><span className="color-btn-03-txt btn-03" >{getSortValue()}</span></a>
                        </div>
                    </div>
                    <div className="list" ref={ref}>
                        <ul>
                            {/* <!-- 리스트--> */}
                            {
                                data?.map((item, index)=>{

                                    return (
                                        <li className="clearfix" key={index}>
                                            <BookpalLink to={"/content/"+item?.ct_type+"/"+item?.bid}>
                                                <div className="cover_img radius-xs">
                                                    {item?.adult ? <p className="ic_adult badge_adult_middle"><img src="../images/ic_adult.svg" /></p> : null}
                                                    {/* <img src={item?.cover} style={{width:"100px",height:"150px"}}/> */}
                                                    <BookpalLazyLoadImage 
                                                        placeholderSrc={ImgDefault("cover_logo")}
                                                        // effect="blur"
                                                        src={ImgCover(memberInfo?.view_type, item?.adult, ImgDefault("cover_lock"), item?.cover)}
                                                        // style={{width:"100px",height:"150px"}}
                                                        style={defaultImageStyle}
                                                        onError={(e)=>{
                                                            e.currentTarget.src = ImgDefault("cover_logo");
                                                        }}
                                                        />
                                                    <p className="ic_bottom badges_box">
                                                        {item?.badge_promotion !== "" ? <span className="ic_midnight radius-xs icon_badge"><img src={ImgBadge(item?.badge_promotion)} /></span> : null}
                                                        {item?.badge_new_up !== "" ? <span className="ic_up radius-xs icon_badge"><img src={ImgBadge(item?.badge_new_up)} /></span> : null}
                                                        {item?.badge_discount > 0 ? <span className="ic_discount radius-xs discount_10 icon_badge"><img src={ImgBadge(item?.badge_discount)} /></span> : null }
                                                    </p>
                                                </div>
                                                <div className="info_txt spacing_08">
                                                    <div className="list_tit_con">
                                                        {item?.badge_new_up !== "" ? <span className="ic_up radius-xs icon_badge"><img src={ImgBadge(item?.badge_new_up)} /></span> : null}
                                                        <span className="title color-txt-normal title-book-03-m text_overflow">{item?.title}</span>
                                                    </div>
                                                    <div className="writer color-txt-03 body-02 text_overflow">{item?.wr_nick}</div>
                                                    <div className="info">
                                                        {item?.free_chapter > 0 ? <span className="free body-01 color-txt-05">{item?.free_chapter}화무료</span> : null}
                                                        <span className="view body-01 color-txt-05">{getViewCntConvert(item?.view_cnt)}</span>
                                                    </div>
                                                    <div className="keyword color-txt-04 btn-02 text_overflow">
                                                        {/* <!-- span 태그 무조건 한줄에서 쓰기 --> */}
                                                        {Array.isArray(item?.subject_array) ? 
                                                            item?.subject_array.map((v, k)=>{
                                                                return <span key={v+"_"+k}>#{v}</span>
                                                            })
                                                        :null}
                                                        {/* <span>#키워드</span><span>#키워드</span><span>#키워드입니다~</span><span>#키워드</span><span>#키워드 들어가는자리</span><span>#키워드입니다~</span> */}
                                                    </div>
                                                </div>
                                            </BookpalLink>
                                        </li>
                                    )
                                })
                            }
                            
                        </ul>
                    </div>
                    <div style={{height:"100px"}}></div>
                </section>
            </div>
            <FilterModal />
            <SortModal />
            {/* <!-- container --> */}
        </>
    );
});


export default ToonCommonSub;