import { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Thumbs, EffectFade, Autoplay } from 'swiper/modules';
import FreeBannerSub from './FreeBannerSub';

function FreeBanner({data}){

    const [key, setKey] = useState(0);
    const [prevWidth, setPrevWidth] = useState(window.innerWidth);
    

    // const refreshSwiper = () => {
    //     setKey((prevKey) => prevKey + 1);
    // };

    // const handleResize = () => {
    //     const currentWidth = window.innerWidth;
  
    //     if (Math.abs(currentWidth - prevWidth) > 1) {
    //       // 좌우로 10px 이상 변경되었을 때 동작하도록 설정
    //       console.log('Resized!');
    //       setPrevWidth(currentWidth);
    //       refreshSwiper();
    //     }
    // };
    // useEffect(() => {
    //     window.addEventListener('resize', handleResize);
    //     console.log("test -> use effect");

    //     return () => {
    //         window.removeEventListener('resize', handleResize);
    //     };
    // }, []);

    if(!data){
        return null;
    }
    // console.log(data)

    return (
        <section className="section template wrapper temp_type_list_bg temp_list_type template_row">
            <div className="area_box color-bg-free-section radius-sm">
                <div className="tit_con">
                    <h2 className="title left title-02-b text_overflow color-txt-normal">{data?.title}</h2>
                </div>
                <div className="type_list">
                    <ul className="tab_list">
                        {data?.tabs && Array.isArray(data?.tabs) && data?.tabs?.length > 0 ?
                            data?.tabs.map((item,index)=>{
                                return (
                                    <li key={index} className="color-txt-04 btn-03">
                                        <a 
                                            style={{cursor:"pointer"}} 
                                            className={"button btn_05 medium radius-full label_on " + (key===index ? "active":"")}
                                            key={index}
                                            data-key={index}
                                            onClick={(e)=>{
                                                const key = e.currentTarget.getAttribute("data-key");
                                                setKey(parseInt(key,10));
                                            }}
                                            >{item?.title}</a>
                                    </li>
                                );
                            })
                        :null}
                        {/* <li className="color-txt-04 btn-03">
                            <a href="#none" className="button btn_05 medium radius-full label_on">로판</a>
                        </li>
                        <li className="color-txt-04 btn-03">
                            <a href="#none" className="button btn_05 medium radius-full label_on">BL</a>
                        </li>
                        <li className="color-txt-04 btn-03">
                            <a href="#none" className="button btn_05 medium radius-full label_on">판타지</a>
                        </li> */}
                    </ul>
                    <div className="list_box">
                        <div className="list vertical_list">
                            {data?.tabs && Array.isArray(data?.tabs) && data?.tabs?.length > 0 ?
                                data.tabs.map((_item,_index)=>{

                                    console.log(_item)
                                    if(key !== parseInt(_index,10)){
                                        return <></>;
                                    }

                                    return (
                                        <div className="list_1" key={key}>
                                            <FreeBannerSub data={_item?.items} title={_item?.title} />
                                        </div>
                                    )
                                })
                            
                            :null}
                            {/* <div className="list_2"></div>
                            <div className="list_3"></div>
                            <div className="list_4"></div> */}
                        </div>
                        <div className="arrow_btns">
                            <div className="swiper-button-next color-bg-01 box-shadow-xs color-border-01 btn_03 medium label_off radius-full round button"><img src="/images/chevron_right.svg" /></div>
                            <div className="swiper-button-prev color-bg-01 box-shadow-xs color-border-01 btn_03 medium label_off radius-full round button"><img src="/images/chevron_left.svg" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}



export default FreeBanner;