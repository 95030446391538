import { Link } from "react-router-dom";
import BookpalLink from "component/common/custom/BookpalLink";

function GiftDetailTab(props) {

    const to = props?.to || false;

    return (
        <ul className="tab_list menu_tab large">
            <li className="color-txt-muted btn-04"><BookpalLink to={"/detail/gift/receive"} className={"border border-soild border-bottom-line " + (to=="receive"?"active":"")}>받은내역</BookpalLink></li>
            <li className="color-txt-muted btn-04"><BookpalLink to={"/detail/gift/use"} className={"border border-soild border-bottom-line " + (to=="use"?"active":"")}>사용내역</BookpalLink></li>
        </ul>
    );
}




export default GiftDetailTab;