import { useState } from 'react';
import { Modal } from 'react-bootstrap';

function useConfirmSnsModel(data){

    const sns = data?.sns || "";
    const title = data?.title || "본인 확인";
    const content = data?.content || "개인정보 보호를 위해 가입하신 \nSNS 계정 확인 후 이용하실 수 있습니다.";
    const action = data?.action || (()=>{});

    const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const handleSelect = () => {
        // if(!noneActive) setSelectKey(key);
        // if(selectFn) selectFn(key);
        action();
        handleClose();
    }


    const ModalTmpl =()=>{ 
        return (
        <>
            <Modal 
                // className="black_opacity-60" 
                backdropClassName="black_opacity-60 opacity-10"
                dialogClassName="modal-dialog-centered"
                contentClassName="radius-xxl color-bg-modal"
                show={showModal}
                animation={false} 
                onHide={handleClose}
                >
                <Modal.Body>
                    <div className="title title-02-b color-txt-normal">{title}</div>
                    <div className="desc body-03-long color-txt-02">{content}</div>
                    <div className="button_box">
                        <ul className="signup_list">
                            {sns === "kakao" ? 
                            <li>
                                <a 
                                    
                                    className="color_btn label_on button large radius-md" 
                                    style={{backgroundColor: "#FEE500", cursor:"pointer"}}
                                    onClick={handleSelect}
                                    >
                                    <span className="sns_logo" id="kakao"></span>
                                    <span className="btn-04 black-always txt">카카오톡 계정으로 진행하기</span>
                                </a>
                            </li> : null}

                            {sns === "apple" ? 
                            <li>
                                <a 
                                    // href="#" 
                                    className="label_on button large radius-md color-btn-02 bg" 
                                    style={{cursor:"pointer"}}
                                    onClick={handleSelect}
                                    >
                                    <span className="sns_logo" id="apple"></span>
                                    <span className="btn-04 color-txt-strong-reverse txt">Apple 계정으로 진행하기</span>
                                </a>
                            </li> : null}
                            {/* <li>
                                <a 
                                    href="#" 
                                    className="color_btn label_on button large radius-md" 
                                    style={{backgroundColor: "#03CF5D"}}
                                    onClick={handleSelect}
                                    >
                                    <span className="sns_logo" id="naver"></span>
                                    <span className="btn-04 white-always txt">네이버 계정으로 진행하기</span>
                                </a>
                            </li> */}
                            {/* <!-- <li>
                                <a href="#" className="color_btn label_on button large radius-md" style="background-color: #FEE500;">
                                    <span className="sns_logo" id="kakao"></span>
                                    <span className="btn-04 black-always txt">카카오톡 계정으로 진행하기</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" className="color_btn label_on button large radius-md border-line border-soild color-border-02 white-always bg">
                                    <span className="sns_logo" id="google"></span>
                                    <span className="btn-04 black-always txt">Google 계정으로 진행하기</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" className="color_btn label_on button large radius-md color-btn-02 bg">
                                    <span className="sns_logo" id="apple"></span>
                                    <span className="btn-04 color-txt-strong-reverse txt">Apple 계정으로 진행하기</span>
                                </a>
                            </li> --> */}
                        </ul>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {/* <button type="button" className="radius-sm btn-04 txt btn_01 large label_on filled button" onClick={handleSelect}>삭제하기</button> */}
                    <button type="button" className="radius-xs btn-03 txt btn_03 medium label_on filled button" onClick={handleClose}>취소</button> 
                </Modal.Footer>
            </Modal>
        </>
    )};
    
    return [ModalTmpl, handleShow];
}


export default useConfirmSnsModel;