import { ApiAuthCert } from "./Auth";
import { useDispatch } from "react-redux";
import { actionSetCertReturnPath } from "action/index";


export const ApiCertLoading = (options = {}) => {

    const returnPath = options?.returnPath;
    const success = options?.onSuccess;
    const error = options?.onError;
    const dispatch = useDispatch();

    const certSubmit = ApiAuthCert({
        onSuccess:(data)=>{
            // console.log(data);
            if(data?.status === true){

                if(!!success) success(data);

                if(returnPath){
                    dispatch(actionSetCertReturnPath(returnPath));
                } else {
                    dispatch(actionSetCertReturnPath(null));
                }

                const form = document.createElement('form');
                form.setAttribute('method', 'post');
                form.setAttribute('action', 'https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb');
    
                const m = document.createElement('input');
                m.setAttribute('type', 'text');
                m.setAttribute('name', 'm');
                m.setAttribute('value', 'checkplusSerivce');
    
                const encodeData = document.createElement('input');
                encodeData.setAttribute('type', 'text');
                encodeData.setAttribute('name', 'EncodeData');
                encodeData.setAttribute('value', data?.result?.enc_data);
    
                const param_r1 = document.createElement('input');
                param_r1.setAttribute('type', 'text');
                param_r1.setAttribute('name', 'param_r1');
                param_r1.setAttribute('value', process.env.REACT_APP_BASE_URL + "/cert_return");
    
                const param_r2 = document.createElement('input');
                param_r2.setAttribute('type', 'text');
                param_r2.setAttribute('name', 'param_r2');
                param_r2.setAttribute('value', data?.result?.param_r2);
    
    
                form.appendChild(m);
                form.appendChild(encodeData);
                form.appendChild(param_r1);
                form.appendChild(param_r2);
    
                document.body.appendChild(form);
                form.submit();
    
                document.body.removeChild(form);
                // navigate(-1,{replace:true});
                return;
            }

            error('data', data);
    
            // alert(data?.status);
            // navigate(-1);
        },
        onError:(e)=>{
            if(!!error) error('error', e);
        }
    });

    return async (param_r1) => {
        await certSubmit({
            param_r1:param_r1
        });
    };
}
