import style from './style/Order.module.scss'

import img_failurl from 'assets/images/order/failure.png'

import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import { appSetClose, isApp } from 'utils/AppUtil';

function OrderPointParkResult(){

    const navigate = useBookpalNavigate();

    return (
        <>
            {/* <!-- 충전 헤더 --> */}
            <div id="TopHeader" className={"color-bg-01 title_header " + style['TopHeader'] + " " + style['title_header']}>
                <div className={"title_menu wrapper " + style['title_menu']}>
                    <div className={"btns left_btns " + style['btns'] + " " + style['left_btns']}></div>
                    <div className={"title-04 color-txt-normal text_overflow " + style['text_overflow'] + " " + style['title-04']}>포인트 파크</div>
                    <div className={"btns right_btns " + style['btns'] + " " + style['right_btns']}></div>
                </div>
            </div>

            <div id="container" className={"s_wrap wrapper " + style['container'] + " " + style['s_wrap'] + " " + style['wrapper']} style={{marginTop:"26px", padding:0}}>
                <section className={"area_box color-bg-01 radius-lg color-border-01 border-soild border-line " + style['area_box']}>
                    <div className={"infoBox " + style['infoBox']}>
                        <img src={img_failurl} />
                        {/* <!-- 간편결제 일때 --> */}
                        {/* <!-- <p className="title title-02-b-long color-txt-normal">결제가 취소되었습니다.</p> --> */}
                        {/* <!-- 일반결제 일때 --> */}
                        {/* <p className={"title title-02-b-long color-txt-normal " + style['title']}>주문하신 결제가 실패하였습니다.</p>
                        <p className={"desc body-03-long color-txt-normal " + style['desc']}>다시 시도해주세요.</p> */}

                        <button 
                            type="button" 
                            className={"button btn_01 large label_on outlined radius-sm color-btn-01 txt btn-04 " + style['button']}
                            onClick={()=>{
                                // navigate("/order/general");
                                if(isApp()){
                                    appSetClose();
                                    return;
                                }
                                
                                navigate("/");
                            }}
                            >북팔로 이동</button>
                    </div>
                </section>
            </div>
        </>
    );
}



export default OrderPointParkResult;