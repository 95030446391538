import React from 'react';
import EmphasizedText from './EmphasizedText';

function NewlineToBr({ text, emphasizedText, emphasizedClassName }) {
    const renderText = () => {

        if(typeof text !== "string"){
            return null;
        }
        // 문자열에서 \n을 <br> 태그로 변경
        return text?.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {/* {line} */}
                <EmphasizedText text={line} emphasizedText={emphasizedText} emphasizedClassName={emphasizedClassName} />
                {index !== text.split('\n').length - 1 && <br />}
            </React.Fragment>
        ));
    };

    return (
        <>
            {renderText()}
        </>
    );
}

export default NewlineToBr;
