import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useBookpalNavigate } from 'component/common/custom/BookpalLink';
import { isApp, appSetMove } from 'utils/AppUtil';
import { getBookpalLink } from 'utils/BookpalLinkUtil';
import { ApiCertLoading } from "api/Cert";

import SubNav from "component/common/nav/SubNav";

import style from '../style/User.module.scss';



function JoinComplete(){

    const [delay, setDelay] = useState(false);

    const [showKey, setShowKey] = useState(null);
    const navigate = useBookpalNavigate();

    const info_data = [];

    const certLoadingSubmit = ApiCertLoading();

    const handlerCert = ()=>{        
        certLoadingSubmit(process.env.REACT_APP_BASE_URL + "/join");
    }

    info_data.push({
        "title":"해외 거주자 본인 인증 방법",
        "info":"본인 인증이 가능한 생년월일이 포함된 신분증을 북팔 아이디와 함께 메일(bookpal@bookpal.co.kr)로 \
        보내주시면 인증 및 보상 지급 처리해드리도록 하겠습니다.\n\n \
        ※인증 완료 후 해당 정보는 즉시 파기됩니다.",
    },{
        "title":"선물소장권 지급 대상 안내",
        "info":"선물권 혜택은 본인인증 후 받으실 수 있습니다.\n본인당 한 개의 아이디에만 적용이 되며 인증이 불가능할 경우 고객센터 1:1 문의로 요청 부탁드립니다.",
    });

    const moveMainHandelr = () => {
        if(isApp()){
            appSetMove("",getBookpalLink("main"));
            return;
        }

        navigate("/");
    }

    const closeHandler = () => {
        moveMainHandelr();
    };

    const infoDataElements = [];
    Object.keys(info_data).forEach(key => {
        const objKey = key;
        const value = info_data[key];

        const equals = showKey === key;

        const info = [];

        const handler = () => {
            if(objKey == showKey){
                setShowKey(null);
            } else {
                setShowKey(objKey);
            }
        }

        value['info'].split('\n').map((line, index) =>{ 
            info.push(
                <>
                    {line}<br />
                </>
            );
        });

        console.log(objKey);
        infoDataElements.push(
            <li  className="border-line border-soild radius-sm color-border-01">
                <button type="button" className={"button large color-bg-01 label_on " + (equals ? "click":"")} onClick={handler}>
                    <span className="color-txt-02 title-01-m-long">
                        {value['title']}
                    </span>
                </button>
                <div className="txt_box color-txt-03 body-02-long border-soild border-top-line color-bg-02 color-border-01" style={{display:(equals ? "block":"none")}}>
                    {info}
                </div>
            </li>
        );
    });

    useEffect(()=>{
        setTimeout(()=>{
            setDelay(true);
        },500);
    },[])

    if(delay === false){
        return <></>;
    }

    return (
        <>
            <SubNav 
                name="회원가입" 
                usePc={false} 
                useMobile={true} 
                setTop={'0px'} 
                useBtnPrev={false} 
                useBtnClose={true}
                onClose={closeHandler}
            />

            {/* <!-- container --> */}
            <div id="container" className={"sub_wrap wrapper " + style['container']}>
                <section className={"complete " + style['complete']}>
                    <p className={"title color-txt-normal title-04 " + style['title']}>회원가입 완료</p>
                    <div className={"area_box color-bg-01 radius-lg color-border-01 border-soild border-line " + style['area_box']}>
                        <div className={"logo_title " + style['logo_title']}>
                            <p className={"logo " + style['logo']}></p>
                            <p className={"body-01 color-txt-muted label_txt " + style['label_txt']}>당신의 상상이 이야기가 되는 곳</p>
                        </div>
                        <div className={"text_box " + style['text_box']}>
                            <p className="body-03-long color-txt-normal">본인 인증 완료시 <br /><span className="color-btn-01 txt">선물소장권 5장</span>을 지급합니다.</p>
                            <p className={"button_box " + style['button_box']}>
                                <button 
                                    type="button" 
                                    className={"radius-sm btn-04 txt btn_01 large label_on filled button " + style['button']}
                                    onClick={handlerCert}
                                >휴대폰 본인인증하기</button> 
                            </p>
                        </div>
                        <div className={"text_box " + style['text_box']}>
                            <p className="body-03-long color-txt-normal">본인인증 확인은 <span className="color-btn-01 txt">나이스평가정보(주)</span>에서 <br />제공하는 안심본인인증 서비스로 <br /><span className="color-btn-01 txt">휴대폰 인증</span>으로만 가능합니다. </p>
                            <p className={"button_box " + style['button_box']}>
                                <button 
                                    type="button" 
                                    className={"radius-sm btn-04 txt btn_01 large label_on outlined button " + style['button']}
                                    onClick={moveMainHandelr}
                                >메인으로 이동</button> 
                            </p>
                        </div>
                        <ul className={"toggle_list " + style['toggle_list']}>
                            {infoDataElements}
                        </ul>
                    </div>        
                </section>
            </div>
            {/* <!-- container --> */}
        </>
    );
}


export default JoinComplete;