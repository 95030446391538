// src/checkForUpdates.js

import React, { useEffect } from 'react';
import { setStorageData } from 'utils/Storage';


function CheckForUpdates() {
  useEffect(() => {

    if(process.env.NODE_ENV === 'development'){
      return;
    }
    const currentVersion = "1.0.9";
    
    const interval = setInterval(() => {
      
      const time = getCurrentTimeString();

      fetch('/version.txt?t='+time)
        .then(response =>  {
          if (!response.ok) {
            // 서버 응답이 200-299가 아닐 경우
            throw new Error('서버에서 버전 정보를 불러오는 데 실패했습니다.');
          }
          return response.text();
        })
        .then(latestVersion => {

          // console.log(latestVersion, currentVersion)
          if (latestVersion.trim() !== currentVersion) {
            // if (window.confirm('새 버전이 있습니다. 페이지를 새로고침 하시겠습니까?')) {
            //   window.location.reload(true); // 캐시를 무시하고 새로고침
            // }
            console.log('new version',latestVersion.trim(), currentVersion, latestVersion.trim() !== currentVersion);
            setStorageData("update_check","true");
          }
        })
        .catch(error => {
          console.error('버전 확인 실패:', error);
          // alert('버전 정보를 확인하는 중 오류가 발생했습니다. 나중에 다시 시도해주세요.');
          // currentVersion을 빈값으로 설정하는 로직은 제거 또는 수정
        });
    }, 60000); // 1분마다 버전 확인

    return () => clearInterval(interval);
  }, []);

  return null; // 렌더링할 내용이 없는 경우
}

function getCurrentTimeString() {
  const now = new Date();

  // 년, 월, 일, 시, 분, 초를 2자리 수로 포맷팅
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  // 모두 숫자 형식으로 이어 붙임
  return `${year}${month}${day}${hours}${minutes}${seconds}`;
}

export default CheckForUpdates;
