

function ExclusiveTypeTwoSection(){


    return (
        <section className="section solo_section wrapper solo_type2">
            <div className="area_box">
                <a href="#">
                    <div className="top_con clearfix">
                        <div className="title_con">
                            <h4 className="title color-txt-normal title-04 text_overflow2">오늘 반짝 무료!<br />단 하루만, 50%</h4>
                            <p className="desc color-txt-04 title-02-m text_overflow">로맨스 · 탐해린 · 야하게 연애</p>
                        </div>
                        <div className="cover_img border-soild border-bottom-line color-border-01 radius-xs">
                            <p className="ic_adult badge_adult_middle"><img src="../images/ic_adult.svg" /></p>
                            <img src="./images/thumbnail.jpg" className="radius-xs" />
                        </div>
                    </div>
                </a>
            </div>
        </section>
    )
}


export default ExclusiveTypeTwoSection;