import { Link } from "react-router-dom";
import BookpalLink from "component/common/custom/BookpalLink";
import { useIsMobile } from "utils/Common";

import style from './Help.module.scss';
import { isApp } from "utils/AppUtil";
import { GetState } from "utils/State";

function HelpQnaSubMenuNav({ to, isLogin, loginSubmit }){

     console.log('isLogin', isLogin)
    const isMobile = useIsMobile();
    const _isLogin = isLogin ?? false;

    const getState = GetState();

    const qnaDot = getState.useGetDot()?.help_one_qna ?? false;

    return (
        <div id="subDepth" className={"color-bg-01 type_header " + style['subDepth'] + " " + style['type_header']} style={{position:"sticky",top:(isApp()?"0px":isMobile?"48px":"64px")}}>
            <div className="swiper-container">
                <div className="swiper-wrapper txt color-txt-muted">
                    {/* <!-- 클릭시 className="on" 추가--> */}
                    <div className={"swiper-slide " + style['swiper-slide'] + " " + (to === "/mypage_menu/help/one_qna" ? "on":"")}>
                        <BookpalLink to={"/mypage_menu/help/one_qna"} replace={true}>
                            <span className="color-btn-01 border border-soild btn-04">문의하기</span>
                        </BookpalLink> 
                    </div>
                    <div className={"swiper-slide " + style['swiper-slide'] + " "  + (to === "/mypage_menu/help/qna_history" ? "on":"")}>
                        {_isLogin ? 
                        <BookpalLink to={"/mypage_menu/help/qna_history"} replace={true}>
                            <span className="color-btn-01 border border-soild btn-04">문의내역 {qnaDot ? <span className="dot color-btn-01 bg radius-full"></span> : null}</span>
                        </BookpalLink>
                        : 
                        <BookpalLink onClick={loginSubmit} replace={true}>
                            <span className="color-btn-01 border border-soild btn-04">문의내역 {qnaDot ? <span className="dot color-btn-01 bg radius-full"></span> : null}</span>
                        </BookpalLink>
                        }
                        
                    </div>
                </div>
            </div>
        </div>
    )
}



export default HelpQnaSubMenuNav;