import { useRef, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Grid } from 'swiper/modules';
import { Link } from 'react-router-dom';
import BookpalLink from '../custom/BookpalLink';
import { getViewCntConvert } from 'utils/Common';
import { ImgBadge, ImgDefault, ImgCover } from 'utils/Images';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BookpalLazyLoadImage from '../custom/BookpalLazyLoadImage';
import { useWidth } from 'utils/Common';
import { useMemberInfo } from 'utils/State';
import useAlertModal from '../modal/useAlertModal';

import 'swiper/css/grid';

function getDefaultImgStyle(gb){
    const style = {};
    switch(gb){
        case "pc": 
            style['minWidth'] = "80px";
            style['minHeight'] = "120px";
            break;
        case "mobile": 
            style['minWidth'] = "68px";
            style['minHeight'] = "102px";
            break;
        // case ""
    }
    return style;
}

function RankingThreeLineSubSection({ tabs, data }) {

    const memberInfo = useMemberInfo();
    // console.log(memberInfo)

    const width = useWidth();

    const [defaultImageStyle, setDefaultImageStyle] = useState({});
    const [AlertModal, alert] = useAlertModal();

    useEffect(()=>{
        if(width > 540){
            setDefaultImageStyle(getDefaultImgStyle("pc"));
        } else {
            setDefaultImageStyle(getDefaultImgStyle("mobile"));
        }
    },[width])

    if(data === null || !Array.isArray(data)){
        return <></>
    }

    return (
        <>
            <Swiper className="swiper mySwiper"
                modules={[Navigation, Pagination, Grid]}
                slidesPerView={'auto'}
                spaceBetween={4}
                watchOverflow={true}
                observer={true}
                observeParents={true}
                grid={{
                    fill: 'column',
                    rows: 3
                }}
                navigation={{
                    nextEl : '.grid_row3 .swiper-button-next',
                    prevEl : '.grid_row3 .swiper-button-prev',
                }}
                breakpoints={{
                    769: {
                        freeMode : true
                    }
                }}
            >
                {/* <!-- 리스트--> */}
                {
                    data.map((item, index)=>{

                        return (
                            <SwiperSlide key={"RankingThreeLineSubSection_"+tabs+"_"+index}>
                                <BookpalLink 
                                    to={"/content/"+item?.ct_type+"/"+item?.bid}
                                    check={{
                                        view_type:memberInfo?.view_type,
                                        work_adult:item?.adult,
                                    }}
                                    alert={alert}
                                    >
                                    <div className="cover_img radius-xs">
                                        {item?.adult ? <p className="ic_adult badge_adult_small"><img src="../images/ic_adult.svg" /></p> : null}
                                        {/* <img src={item?.cover} /> */}
                                        {/* <BookpalLazyLoadImage 
                                                placeholderSrc={ImgDefault("cover_logo")}
                                                // effect="blur"
                                                src={ImgCover(memberInfo?.view_type, item?.adult, ImgDefault("cover_lock"), item?.cover)}
                                                style={defaultImageStyle}
                                                onError={(e)=>{
                                                    e.currentTarget.src = ImgDefault("cover_logo");
                                                }}
                                            /> */}
                                        <BookpalLazyLoadImage 
                                                adult={item?.adult}
                                                adultswitch={memberInfo?.audit_switch}
                                                defaulttype={"cover_logo"}
                                                locktype={"cover_lock"}
                                                src={item?.cover}
                                                style={defaultImageStyle}
                                            />
                                        <p className="ic_bottom badges_box">
                                            {item?.badge_promotion !== "" ? <span className="ic_midnight radius-xs icon_badge sm"><img src={ImgBadge(item?.badge_promotion)} /></span> : null}
                                            {item?.badge_new_up !== "" ? <span className="ic_up radius-xs icon_badge sm"><img src={ImgBadge(item?.badge_new_up)} /></span> : null}
                                            {item?.badge_discount > 0 ? <span className="ic_discount radius-xs discount_10 icon_badge sm"><img src={ImgBadge(item?.badge_discount)} /></span> : null }
                                            {/* <span className="ic_midnight radius-xs icon_badge sm"><img src="../images/midnight_badge.svg" /></span> */}
                                        </p>
                                    </div>
                                    <div className="ranking_number left title-01-b color-txt-normal">{item?.ranking}</div>
                                    <div className="info_txt spacing_04">
                                        <div className="info color-txt-05 caption-02 text_overflow">{item?.is_finished ? "완결":"연재"}</div>
                                        <div className="title color-txt-normal title-book-02-m text_overflow text_overflow">{item?.title}</div>
                                        <div className="writer color-txt-03 body-01 text_overflow text_overflow">{item?.wr_nick}</div>
                                        <div className="view color-txt-05 caption-02 text_overflow">{getViewCntConvert(item?.view_cnt)}</div>
                                    </div>
                                </BookpalLink>
                            </SwiperSlide>
                        )
                    })
                }
                
            </Swiper>
            <div className="arrow_btns">
                <div className="swiper-button-next color-bg-01 box-shadow-xs color-border-01 btn_03 medium label_off radius-full round button"><img src="/images/chevron_right.svg" /></div>
                <div className="swiper-button-prev color-bg-01 box-shadow-xs color-border-01 btn_03 medium label_off radius-full round button"><img src="/images/chevron_left.svg" /></div>
            </div>
            <AlertModal />
        </>
    );
}



export default RankingThreeLineSubSection;