import { useState } from 'react';
import style from '../../style/Contents.module.scss';


function PlusOne(props){

    const data = props?.data || null;
    const bid = props?.bid || null;
    const onAction = props?.onAction || (()=>{});

    const [toast, setToast] = useState(false);


    const handlerToast = ()=>{
        setToast(true);
        setTimeout(()=>{
            setToast(false);
        },2000)
    }

    if(data === null){
        return <></>
    }

    return (
        <>
            <div className={"left " + style['left']}>
                <p className={"icon " + style['icon'] + " " + style['icon_flag']} id="icon_flag"></p>
                <p className={"title info " + style['title'] + " " + style['info']}>
                    <span className="color-txt-01 body-03">정주행의맛 이벤트 작품</span> 
                    <span className="title-01-b color-txt-02">{data?.my_plusone_cnt}/{data?.get_interval}</span>
                </p>
            </div>
            <p className={"right " + style['right']}>
                <button 
                    type="button" 
                    className={"button " + style['button'] + " " + style['infoBtn']} 
                    id="infoBtn" 
                    onClick={handlerToast}
                    />
            </p>
            <div
            className="toast message_toast black_opacity-80 bg radius-xs"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            style={{opacity:(toast ? 1:0)}}
            >
            <div className="toast-body">
                <div className="area">
                <p className="text color-btn-02-txt body-02-long">
                    해당 작품 {data?.get_interval}개 회차 쿠폰 열람시<br />선물이용권 1장 지급!
                </p>
                </div>
            </div>
            </div>
        </>
    )
}



export default PlusOne;