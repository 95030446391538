import React, { useState, forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ko } from 'date-fns/locale/ko'; // 한국어 로케일

import './dateTimePicker.css';

const DateTimePicker = ({setDate, setTime, customType, dateFormat}) => {
    const setDataFn = setDate || (()=>{});
    const setTimeFn = setTime || (()=>{});
    const [startDate, setStartDate] = useState(null);
    const selectDateTime = (currentDate) => {
        // console.log(currentDate);
        setStartDate(currentDate);
        // 날짜 가져오기 (0000-00-00)
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;

        setDataFn(formattedDate);

        // 시간 가져오기 (00:00)
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const formattedTime = `${hours}:${minutes}`;

        setTimeFn(formattedTime);
    }
    const ExampleCustomInput = forwardRef(({ className, spanClassName, value, onClick }, ref) => {

        if(customType === "select"){
            return (
                <button type="button" className={className} onClick={onClick} ref={ref}>
                    <span className={"body-03 " + ( value=="" ? "color-txt-muted":"color-txt-normal")}>{value=="" ? "00.00.00" : value}</span>
                </button>
            )
            // <button type="button" className="text_input select_box button color-bg-01 radius-sm color-border-02"><span className="body-02 color-txt-normal">24.03.23</span></button>
        }

        return (
            <button className={className} onClick={onClick} ref={ref}>
                <span className={"body-03 " + ( value=="" ? "color-txt-muted":"color-txt-normal")}>{value=="" ? "0000년 00월 00년 00:00" : value}</span>
            </button>
        )
    });
    return (
        <DatePicker
            selected={startDate}
            onChange={selectDateTime}
            dateFormat={dateFormat ?? "yyyy년 MM월 dd일 HH:mm"}
            locale={ko}
            timeZone="Asia/Seoul"
            timeFormat="HH:mm"
            timeIntervals={5}
            maxDate={new Date()}
            timeCaption="시간"
            showTimeSelect
            customInput={<ExampleCustomInput />}
        />
    );

    // return [datePicker, toggleDatePicker, startDate];
};

export default DateTimePicker;
