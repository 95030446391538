import { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import BookpalLink from '../custom/BookpalLink';
import { Swiper, SwiperSlide } from 'swiper/react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BookpalLazyLoadImage from '../custom/BookpalLazyLoadImage';

import { ImgBadge, ImgDefault, ImgCover } from 'utils/Images';
import { useMemberInfo } from 'utils/State';


import style from './HorizontalSection.module.scss';
import NewlineToBr from '../content/NewlineToBr';
import useAlertModal from '../modal/useAlertModal';


function HorizontalSection({title, moreLink, data}){
    const swiperRef = useRef(null);

    const memberInfo = useMemberInfo();
    const [AlertModal, alert] = useAlertModal();
    const [prevBtn, setPrevBtn] = useState(false);
    const [nextBtn, setNextBtn] = useState(false);

    useEffect(()=>{

        const swiper = swiperRef.current.swiper;

        const updateNavButtons = () => {
            if (swiper) {
                const isBeginning = swiper.isBeginning;
                const isEnd = swiper.isEnd;
                // setShowNavButtons(!(isBeginning && isEnd));
                // setUseNavigateBtn(!(isBeginning && isEnd))

                setPrevBtn(!isBeginning);
                setNextBtn(!isEnd);
                // console.log("isEnd",isEnd);
            }
        };

        if(swiper){
            swiper.on('slideChange', updateNavButtons);
            updateNavButtons();
        }

        return () => {
            if(swiper){
                swiper.off('slideChange', updateNavButtons);
            }
        };

    },[])

    if(!data || !Array.isArray(data)){
        return <></>
    }

    return (
        <>
        <section className="section temp_02 template wrapper template_row">
            <div className="tit_con clearfix">
                <h2 className="title left color-txt-normal title-02-b text_overflow">{title}</h2>
                {moreLink ? 
                <div className="right">
                    <BookpalLink to={moreLink} className='more_btn'>
                        <span className="color-btn-03-txt btn-03">더보기</span>
                    </BookpalLink>
                </div>
                :null}
            </div>
            <div className="list">
                <Swiper className="swiper mySwiper" 
                    ref={swiperRef}
                    slidesPerView={'auto'}
                    spaceBetween={4}
                    watchOverflow={true}
                    observer={true}
                    observeParents={true}
                    
                    breakpoints={{
                        769: {
                            freeMode : true,
                        },
                    }}
                >
                    {data.map((item, index)=>{

                        return (
                            <SwiperSlide key={index} className="radius-xs">
                                <BookpalLink 
                                    to={"/content/"+item?.ct_type+"/"+item?.bid}
                                    check={{
                                        view_type:memberInfo?.view_type,
                                        work_adult:item?.adult,
                                    }}
                                    alert={alert}
                                    >
                                    <div className="cover_img">
                                        {item?.adult ? <p className="ic_adult badge_adult_normal"><img src="./images/ic_adult.svg" /></p> : null}
                                        {/* <img src={item?.image} /> */}
                                        {/* <BookpalLazyLoadImage 
                                                placeholderSrc={ImgDefault("cover_logo")}
                                                // effect="blur"
                                                src={ImgCover(memberInfo?.view_type, item?.adult, ImgDefault("cover_lock"), item?.image)}
                                                // style={defaultImageStyle}
                                                onError={(e)=>{
                                                    e.currentTarget.src = ImgDefault("cover_logo");
                                                }}
                                            /> */}
                                        <BookpalLazyLoadImage 
                                                adult={item?.adult}
                                                adultswitch={memberInfo?.audit_switch}
                                                defaulttype={"hori_cover_logo"}
                                                locktype={"hori_cover_lock"}
                                                src={item?.image}
                                                // style={defaultImageStyle}
                                            />
                                        <p className="ic_bottom badges_box">
                                            {item?.badge_promotion !== "" ? <span className="ic_midnight radius-xs icon_badge"><img src={ImgBadge(item?.badge_promotion)} /></span> : null}
                                            {item?.badge_new_up !== "" ? <span className="ic_up radius-xs icon_badge"><img src={ImgBadge(item?.badge_new_up)} /></span> : null}
                                            {item?.badge_discount > 0 ? <span className="ic_discount radius-xs discount_10 icon_badge"><img src={ImgBadge(item?.badge_discount)} /></span> : null }
                                        </p>
                                    </div>
                                    <div className="info_txt spacing_04 border-line border-soild color-border-01 box-shadow-sm radius-xs">
                                        <div className="md_ment color-txt-05 body-01 text_overflow">{item?.md_content === "" ? (<>&nbsp;</>) : item?.md_content}</div>
                                        <div className="title color-txt-normal title-book-02-m text_overflow">{item?.title}</div>
                                        <div className="writer color-txt-03 body-01 text_overflow">{item?.wr_nick}</div>
                                    </div>
                                </BookpalLink>
                            </SwiperSlide>
                        )
                    })}
                    
                </Swiper>
                <div className={"arrow_btns " + style['arrow_btns']}>
                    {nextBtn ? 
                    <div 
                        className="swiper-button-next button btn_03 color-bg-01 box-shadow-xs color-border-01"
                        onClick={() => swiperRef.current.swiper.slideNext()}
                    ><img src="./images/chevron_right.svg" /></div>
                    :null}
                    {prevBtn ? 
                    <div 
                        className="swiper-button-prev button btn_03 color-bg-01 box-shadow-xs color-border-01"
                        onClick={() => swiperRef.current.swiper.slidePrev()}
                    ><img src="./images/chevron_left.svg" /></div>
                    :null}
                </div>
            </div>
        </section>
        <AlertModal />
        </>
    )
}



export default HorizontalSection;