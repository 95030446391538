import { useDispatch, useSelector } from "react-redux";
import { 
    actionSetToken,
    actionDelToken,
    actionSetMemberInfo,
    actionSetDot,
    actionKeywordSaveMode,
    actionSetAppBanner,
    actionSetSaveId,
    actionSetSaveIdCheck,
    actionSetAdultSwitch,
    actionSetAutoLogin,
    actionSetLastSocialLoginType,
    actionSetLastSocialLoginDate,
    actionSetLastSocialLoginTryPath,
    actionSetLastSocialLoginTryType,
    actionSetToolTipSearch,
    actionSetShowGiftToast,
    actionSetSystemMode,
    actionDelMemberInfo,

    actionSetViewerInfoFont,
    actionSetViewerInfoFontSize,
    actionSetViewerInfoLineHeight,
    actionSetViewerInfoSideMargin,
    actionSetViewerInfoStyle,
    actionSetViewerInfoTheme,
    actionSetViewerInfoImageNone,
    actionSetViewerInfoRun,
} from "action/index";
import { decrypt, encrypt } from "./Crypt";



export function SetState() {
    const dispatch = useDispatch();

    return {
        setToken:(token)=>{
            dispatch(actionSetToken(token));
        },
        delToken:()=>{
            dispatch(actionDelToken({}));
        },
        setMemberInfo:(data)=>{
            const _data = encrypt(JSON.stringify(data));
            dispatch(actionSetMemberInfo(_data));
        },
        delMemberInfo:()=>{
            dispatch(actionDelMemberInfo(null));
        },
        setDot:(dot)=>{
            dispatch(actionSetDot(dot));
        },
        setKeywordSaveMode:(data)=>{
            // console.log(data)
            dispatch(actionKeywordSaveMode(data));
        },
        setAppBanner:(data)=>{
            dispatch(actionSetAppBanner(data));
        },
        setSaveId:(data)=>{
            dispatch(actionSetSaveId(data));
        },
        setSaveIdCheck:(data)=>{
            dispatch(actionSetSaveIdCheck(data));
        },
        setSystemMode:(data)=>{
            dispatch(actionSetSystemMode(data));
        },
        setAdultSwitch:(data)=>{
            dispatch(actionSetAdultSwitch(data));
        },
        setAutoLogin:(data)=>{
            dispatch(actionSetAutoLogin(data));
        },
        setLastSocialLoginDate:(data)=>{
            dispatch(actionSetLastSocialLoginDate(data));
        },
        setLastSocialLoginType:(data)=>{
            dispatch(actionSetLastSocialLoginType(data));
        },
        setLastSocialLoginTryPath:(data)=>{
            dispatch(actionSetLastSocialLoginTryPath(data));
        },
        setLastSocialLoginTryType:(data)=>{
            dispatch(actionSetLastSocialLoginTryType(data));
        },
        setTooltipSearch:(data)=>{
            dispatch(actionSetToolTipSearch(data));
        },
        setToast:(data)=>{
            dispatch(actionSetShowGiftToast({
                title:data?.title,
                body:data?.body,
                date:data?.date,
            }));
        },
        // -----------------------------
        // Viewer
        // -----------------------------
        setViewerInfoFont:(data)=>{
            switch(data){
                case "NanumBarunGothic":
                case "NotoSansKR":
                case "YESMyoungjo":
                    break;
                case "init":
                    data = "NanumBarunGothic";
                    break;
                default:
                    return;
            }
            dispatch(actionSetViewerInfoFont(data));
        },
        setViewerInfoFontSize:(data)=>{
            switch(data){
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                    break;
                case "init":
                    data = 5;
                    break;
                default:
                    return;
            }
            dispatch(actionSetViewerInfoFontSize(data));
        },
        setViewerInfoLineHeight:(data)=>{
            switch(data){
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                    break;
                case "init":
                    data = 4;
                    break;
                default:
                    return;
            }
            dispatch(actionSetViewerInfoLineHeight(data));
        },
        setViewerInfoSideMargin:(data)=>{
            switch(data){
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                case "init":
                    data = 3;
                    break;
                default:
                    return;
            }
            dispatch(actionSetViewerInfoSideMargin(data));
        },
        setViewerInfoStyle:(data)=>{
            switch(data){
                case "scroll":
                case "page":
                case "reverse":
                    break;
                case "init":
                    data = "scroll";
                    break;
                default:
                    return;
            }
            dispatch(actionSetViewerInfoStyle(data));
        },
        setViewerInfoTheme:(data)=>{
            switch(data){
                case "white":
                case "gray":
                case "black":
                case "yellow":
                case "green":
                    break;
                case "init":
                    data = "white";
                    break;
                default:
                    return;
            }
            dispatch(actionSetViewerInfoTheme(data));
        },
        setViewerInfoImageNone:(data)=>{
            if(data === true){}
            else if(data === false){}
            else {data = false};
            dispatch(actionSetViewerInfoImageNone(data));
        },
        setViewerInfoRun:(data)=>{
            if(data === true){}
            else if(data === false){}
            else {data = false};
            dispatch(actionSetViewerInfoRun(data));
        },
    }
}

export function GetState() {
    return {
        useGetTokens:()=>{
            return useSelector((state) => state.data.tokens);
        },
        useGetDot:()=>{
            try{
                const data = useSelector((state) => state.data);
                const dot = data?.dot;
                // console.log(data)
                if(!!data?.member_info){
                    const _data = decrypt(data.member_info);
                    const __data =  JSON.parse(_data);
                    // console.log(_data)
                    if(__data['is_login'] ?? false){
                        return dot;
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            }
            catch(e){
                return null;
            }
            // return useSelector((state) => state.data.dot);
        },
        useGetAdultSwitch:()=>{
            return useSelector((state) => state.data.adult_switch);
        },
        useGetMemberInfo:()=>{
            try{
                const data = useSelector((state) => state.data.member_info);
                if(!!data){
                    const _data = decrypt(data);
                    return JSON.parse(_data);
                } else {
                    return null;
                }
            }
            catch(e){
                return null;
            }
        },
        useGetKeywordSaveMode:()=>{
            return useSelector((state) => state.data.keyword_save_mode);
        },
        useGetAppBanner:()=>{
            return useSelector((state) => state.data.app_banner);
        },
        useGetSaveIdCheck:()=>{
            return useSelector((state) => state.data.saveid_check);
        },
        useGetSystemMode:()=>{
            return useSelector((state) => state.data.system_mode);
        },
        useGetAutoLogin:()=>{
            return useSelector((state) => state.data.auto_login);
        },
        useGetAdultSwitch:()=>{
            return useSelector((state) => state.data.adult_switch);
        },
        useGetTooltipSearch:()=>{
            return useSelector((state) => state.data.tooltip_search);
        },
        //-----------------------------
        // Viewer
        //-----------------------------
        useGetViewerInfoFont:()=>{
            return useSelector((state) => state.data.viewer_font);
        },
        useGetViewerInfoFontSize:()=>{
            return useSelector((state) => state.data.viewer_font_size);
        },
        useGetViewerInfoFontSizePx:()=>{
            const data = useSelector((state) => state.data.viewer_font_size);
            // console.log("fontsize=>",data)
            switch(data){
                case 1: return "14px";
                case 2: return "15px";
                case 3: return "16px";
                case 4: return "17px";
                default:
                case 5: return "18px";
                case 6: return "19px";
                case 7: return "20px";
                case 8: return "23px";
                case 9: return "25px";
                case 10: return "28px";
            }
        },
        useGetViewerInfoLineHeight:()=>{
            return useSelector((state) => state.data.viewer_line_height);
        },
        useGetViewerInfoLineHeightPer:()=>{
            const data = useSelector((state) => state.data.viewer_line_height);
            switch(data){
                case 1: return "150%";
                case 2: return "160%";
                case 3: return "170%";
                default:
                case 4: return "180%";
                case 5: return "200%";
                case 6: return "220%";
                case 7: return "240%";
                case 8: return "260%";
            }
        },
        useGetViewerInfoSideMargin:()=>{
            return useSelector((state) => state.data.viewer_side_margin);
        },
        useGetViewerInfoSideMarginPx:()=>{
            const data = useSelector((state) => state.data.viewer_side_margin);
            switch(data){
                case 1: return "2%";
                case 2: return "4%";
                default:
                case 3: return "7%";
                case 4: return "10%";
                case 5: return "12%";
            }
        },
        useGetViewerInfoStyle:()=>{
            return useSelector((state) => state.data.viewer_style);
        },
        useGetViewerInfoTheme:()=>{
            return useSelector((state) => state.data.viewer_thmeme);
        },
        useGetViewerInfoImageNone:()=>{
            return useSelector((state) => state.data.viewer_image_none);
        },
        useGetViewerInfoRun:()=>{
            return useSelector((state) => state.data.viewer_run);
        },
        
    }
}




export function useMemberInfo(){
    const getState = GetState();
    const memberInfo = getState.useGetMemberInfo();
    const auditSwitch = getState.useGetAdultSwitch();

    const _mb_id = memberInfo?.mb_id ?? false;
    const _is_login = memberInfo?.is_login ?? false;
    const _is_adult = memberInfo?.is_adult ?? false;
    const _is_cert = memberInfo?.is_cert ?? false;
    const _view_type = _memberInfoViewType(_is_login,_is_adult,_is_cert);
    const _audit_switch = auditSwitch;

    return {
        mb_id:_mb_id,
        is_login:_is_login,
        is_adult:_is_adult,
        is_cert:_is_cert,
        view_type:_view_type,
        audit_switch:_audit_switch,
    }
}


function _memberInfoViewType(is_login,is_adult,is_cert){
    if(!is_login){
        return 'N';
    }
    if(!is_cert){
        return 'C';
    }
    if(!is_adult){
        return 'D';
    }
    return 'Y';
}