import Header from 'component/common/header/Header';
import MobileNav from 'component/common/nav/MobileNav';

import Footer from 'component/common/footer/Footer';
import TopBtn from 'component/common/button/top/TopBtn';

import SubNav from 'component/common/nav/SubNav';
// import SubMenuNav from '../nav/SubMenuNav';
import useSelectModal from 'component/common/modal/useSelectModal';

import GiftDetailTab from '../tab/GiftDetailTab';

import img_gift from 'assets/images/gift/gift_img.png';

import style from '../style/DetailGift.module.scss';
import { useState } from 'react';

function Receive(){

    const modalData = {
        all:"전체",
        select1:"select option 01",
        select2:"select option 02",
    }
    const [Modal, modalShow, selectKey] = useSelectModal(modalData, "all");

    const [toolTip, setToolTip] = useState(false);

    return (
        <>
            <Header />
            <MobileNav />
            {/* <!-- 서브메뉴 --> */}
            {/* <SubMenuNav page="/detail/gift/receive"/> */}
            <SubNav 
                name="선물내역" 
                useHome={true} 
                usePc={true}
                useMobile={true}
                useBtnPrev={true}
                setTop={"0px"}
                setPcTop={"64px"} />
            {/* <!-- //서브메뉴 --> */}

            {/* <!-- container --> */}
            <div id="container" className={style['container']}>
                

                <section className={"section wrapper history_list " + style['section']}>
                    <GiftDetailTab to={"use"}/>
                    <div className="area_box">
                        
                        {/* <!-- 리스트 --> */}
                        <ul className="list">
                            <li className="border-soild border-bottom-line color-border-01">
                                <a href="#">
                                    <div className="left info_txt spacing_08">
                                        <div className="list_tit_con">
                                            <span className="title-01-b color-txt-normal text_overflow">결혼지옥</span>
                                            <span className="icon"></span>
                                        </div>
                                        <div className="category body-02 color-txt-03">3화 대여</div>
                                        <div className="date body-01 color-txt-05">YY.MM.DD 00:00:00</div>
                                    </div>
                                    <div className="right">
                                        <div className="title title-01-m color-txt-normal">선물이용권</div>
                                        <div className="data body-01 color-txt-05">[웹소설]</div>
                                    </div>
                                </a>
                            </li>
                            <li className="border-soild border-bottom-line color-border-01">
                                <a href="#">
                                    <div className="left info_txt spacing_08">
                                        <div className="list_tit_con">
                                            <span className="title-01-b color-txt-normal text_overflow">결혼지옥</span>
                                            <span className="icon"></span>
                                        </div>
                                        <div className="category body-02 color-txt-03">3화 대여</div>
                                        <div className="date body-01 color-txt-05">YY.MM.DD 00:00:00</div>
                                    </div>
                                    <div className="right">
                                        <div className="title title-01-m color-txt-normal">선물이용권</div>
                                        <div className="data body-01 color-txt-05">[웹소설]</div>
                                    </div>
                                </a>
                            </li>
                            <li className="border-soild border-bottom-line color-border-01">
                                <a href="#">
                                    <div className="left info_txt spacing_08">
                                        <div className="list_tit_con">
                                            <span className="title-01-b color-txt-normal text_overflow">결혼지옥</span>
                                            <span className="icon"></span>
                                        </div>
                                        <div className="category body-02 color-txt-03">3화 대여</div>
                                        <div className="date body-01 color-txt-05">YY.MM.DD 00:00:00</div>
                                    </div>
                                    <div className="right">
                                        <div className="title title-01-m color-txt-normal">선물이용권</div>
                                        <div className="data body-01 color-txt-05">[웹소설]</div>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        {/* <!-- 더보기 버튼 클릭시 하단으로 리스트 30개 추가 노출 30개미만일때는 더보기버튼 없음 --> */}
                        <div className="more_btn color-bg-01">
                            <button type="button" className="button btn_03 medium outlined label_on round radius-full color-txt-04 btn-03">
                                <span>더보기 (1/20)</span>
                            </button>
                        </div>
                        {/* <!-- 내역 없을때 -->
                        <!-- <div className="no_data large color-txt-04 body-02-long">최근 90일 동안 사용한 내역이 없습니다.</div> --> */}
                    </div>
                </section>

            </div>
            <Modal />
            {/* <!-- container --> */}
            <Footer />
            <TopBtn />
        </>
    );
}


export default Receive;