import Header from 'component/common/header/Header';

import Footer from 'component/common/footer/Footer';
import TopBtn from 'component/common/button/top/TopBtn';

import { useEffect } from 'react';

import style from './style/Contents.module.scss';
import { useIsMobile } from 'utils/Common';


function ContentsWriter(){

    const isMobile = useIsMobile();

    useEffect(()=>{

        // dataSubmit();

        if(isMobile){
            document.body.style.background = "";
            return;
        }
        document.body.style.background = "#f7f5f5";
        return ()=>{
            document.body.style.background = "";
        }
    },[isMobile]);




    return (
        <>
            <Header useMobile={false} />

            <div id="container" className={style['container']} style={{paddingBottom:"0px"}}>
                <section className={"section detail_box wrapper clearfix template temp_list template_column " + style['section'] + " " + style['wrapper'] + " " + style['detail_box'] + " " + style['temp_list']}>
                    <div className={"radius-xxl color-bg-01 area_box " + style['area_box']}>
                    <div id="top_header" className={"color-bg-01 " + style['top_header']}>
                        <button type="button" className="prev_btn" ></button>
                        <span className="title-02-b color-txt-normal text_overflow">작가소개</span>
                    </div>
                    <div className={"content_body author_box " + style['content_body'] + " " + style['author_box']}>
                        <div className={"author_info " + style['author_info']}>
                            <div className={"info_txt " + style['info_txt']}>
                                <p className={"title-sub-02-b color-txt-muted "}>필명</p>
                                <p className="writer title-02-m-long color-txt-normal">
                                작가명 진짜진짜 긴 경우가 있습니다, 그럴땐 이렇게 표시될 수
                                있도록 작업해주시면 됩니다. 아마 최대 네다섯줄까지 나올 수도
                                있을것 같습니다. 이렇게 영역 늘어나면 됩니다. 감사합니다.
                                작가명 진짜진짜 긴 경우가 있습니다, 그럴땐 이렇게 표시될 수
                                있도록 작업해주시면 됩니다. 아마 최대 네다섯줄까지 나올 수도
                                있을것 같습니다. 이렇게 영역 늘어나면 됩니다.
                                감사합니다.작가명 진짜진짜 긴 경우가 있습니다, 그럴땐 이렇게
                                표시될 수 있도록 작업해주시면 됩니다. 아마 최대 네다섯줄까지
                                나올 수도 있을것 같습니다. 이렇게 영역 늘어나면 됩니다.
                                감사합니다.
                                </p>
                            </div>
                        </div>
                        <div className={"status_info " + style['status_info']}>
                            <div className={"top_con " + style['top_con']}>
                                <div className={"left text_overflow " + style['left']}>
                                    <span className="color-txt-normal title-01-b">작품 현황</span>
                                </div>
                                <div className="right">
                                    <span className="btn-03 color-btn-03-txt">전체 작품 (4)</span>
                                </div>
                            </div>
                            <div className={"radius-sm gold_opacity-10 status_box clearfix " + style['status_box']}>
                                <div className="left color-border-thumbnail border-right-line border-soild">
                                    <p>
                                        <span className={"title-01-m color-txt-04 stit " + style['stit']}>로맨스</span>
                                        <span className={"title-01-m color-txt-normal num " + style['num']}>1</span>
                                    </p>
                                    <p>
                                        <span className={"title-01-m color-txt-04 stit " + style['stit']}>로맨스판타지</span>
                                        <span className={"title-01-m color-txt-normal num " + style['num']}>2</span>
                                    </p>
                                </div>
                                <div className="right">
                                    <p>
                                        <span className={"title-01-m color-txt-04 stit " + style['stit']}>판타지</span>
                                        <span className={"title-01-m color-txt-normal num " + style['num']}>1</span>
                                    </p>
                                    <p>
                                        <span className={"title-01-m color-txt-04 stit " + style['stit']}>BL</span>
                                        <span className={"title-01-m color-txt-normal num " + style['num']}>0</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={"author_list " + style['author_list']}>
                            <div className={"top_con " + style['top_con']}>
                                <div className="left text_overflow">
                                    <p className={"writer_name color-txt-normal title-01-b " + style['writer_name']}>
                                        [<span className="text_overflow">작가명들어가는자리인데 작가명 진짜진짜 긴 경우가
                                        있습니다. 그럴땐 이렇게 나오게</span>] 작품
                                    </p>
                                </div>
                            </div>
                            <div className="list">
                                <ul>
                                    <li className="clearfix">
                                        <a href="#">
                                        <div className="cover_img radius-xs">
                                            <p className="ic_adult badge_adult_middle">
                                            <img src="../images/ic_adult.svg" />
                                            </p>
                                            <img src="../images/thumbnail.jpg" />
                                            <p className="ic_bottom badges_box">
                                            <span className="ic_midnight radius-xs icon_badge"><img src="../images/midnight_badge.svg" /></span>
                                            <span className="ic_discount radius-xs discount_10 icon_badge"><img src="../images/discount_10.svg" /></span>
                                            </p>
                                        </div>
                                        <div className="info_txt spacing_08">
                                            <div className="info">
                                            <span className="body-01 color-txt-05">장르</span>
                                            <span className="body-01 color-txt-05">3화무료</span>
                                            <span className="body-01 color-txt-05">81.4만</span>
                                            </div>
                                            <div className="list_tit_con">
                                            <span className="ic_up radius-xs icon_badge"><img src="../images/up_badge.svg" /></span>
                                            <span className="title color-txt-normal title-book-03-m text_overflow">책제목입니다.</span>
                                            </div>
                                            <div className="keyword color-txt-04 btn-02 text_overflow">
                                            {/* <!-- span 태그 무조건 한줄에서 쓰기 --> */}
                                            <span>#키워드</span><span>#키워드</span><span>#키워드입니다~</span><span>#키워드</span><span>#키워드 들어가는자리</span><span>#키워드입니다~</span>
                                            </div>
                                        </div>
                                        </a>
                                    </li>
                                    <li className="clearfix">
                                        <a href="#">
                                        <div className="cover_img radius-xs">
                                            <p className="ic_adult badge_adult_middle">
                                            <img src="../images/ic_adult.svg" />
                                            </p>
                                            <img src="../images/thumbnail.jpg" />
                                            <p className="ic_bottom badges_box">
                                            <span className="ic_midnight radius-xs icon_badge"><img src="../images/midnight_badge.svg" /></span>
                                            <span className="ic_discount radius-xs discount_10 icon_badge"><img src="../images/discount_10.svg" /></span>
                                            </p>
                                        </div>
                                        <div className="info_txt spacing_08">
                                            <div className="info">
                                            <span className="body-01 color-txt-05">장르</span>
                                            <span className="body-01 color-txt-05">3화무료</span>
                                            <span className="body-01 color-txt-05">81.4만</span>
                                            </div>
                                            <div className="list_tit_con">
                                            <span className="ic_up radius-xs icon_badge"><img src="../images/up_badge.svg" /></span>
                                            <span className="title color-txt-normal title-book-03-m text_overflow">책제목입니다.</span>
                                            </div>
                                            <div className="keyword color-txt-04 btn-02 text_overflow">
                                            {/* <!-- span 태그 무조건 한줄에서 쓰기 --> */}
                                            <span>#키워드</span><span>#키워드</span><span>#키워드입니다~</span><span>#키워드</span><span>#키워드 들어가는자리</span><span>#키워드입니다~</span>
                                            </div>
                                        </div>
                                        </a>
                                    </li>
                                    <li className="clearfix">
                                        <a href="#">
                                        <div className="cover_img radius-xs">
                                            <p className="ic_adult badge_adult_middle">
                                            <img src="../images/ic_adult.svg" />
                                            </p>
                                            <img src="../images/thumbnail.jpg" />
                                            <p className="ic_bottom badges_box">
                                            <span className="ic_midnight radius-xs icon_badge"><img src="../images/midnight_badge.svg" /></span>
                                            <span className="ic_discount radius-xs discount_10 icon_badge"><img src="../images/discount_10.svg" /></span>
                                            </p>
                                        </div>
                                        <div className="info_txt spacing_08">
                                            <div className="info">
                                            <span className="body-01 color-txt-05">장르</span>
                                            <span className="body-01 color-txt-05">3화무료</span>
                                            <span className="body-01 color-txt-05">81.4만</span>
                                            </div>
                                            <div className="list_tit_con">
                                            <span className="ic_up radius-xs icon_badge"><img src="../images/up_badge.svg" /></span>
                                            <span className="title color-txt-normal title-book-03-m text_overflow">책제목입니다.</span>
                                            </div>
                                            <div className="keyword color-txt-04 btn-02 text_overflow">
                                            {/* <!-- span 태그 무조건 한줄에서 쓰기 --> */}
                                            <span>#키워드</span><span>#키워드</span><span>#키워드입니다~</span><span>#키워드</span><span>#키워드 들어가는자리</span><span>#키워드입니다~</span>
                                            </div>
                                        </div>
                                        </a>
                                    </li>
                                    <li className="clearfix">
                                        <a href="#">
                                        <div className="cover_img radius-xs">
                                            <p className="ic_adult badge_adult_middle">
                                            <img src="../images/ic_adult.svg" />
                                            </p>
                                            <img src="../images/thumbnail.jpg" />
                                            <p className="ic_bottom badges_box">
                                            <span className="ic_midnight radius-xs icon_badge"><img src="../images/midnight_badge.svg" /></span>
                                            <span className="ic_discount radius-xs discount_10 icon_badge"><img src="../images/discount_10.svg" /></span>
                                            </p>
                                        </div>
                                        <div className="info_txt spacing_08">
                                            <div className="info">
                                            <span className="body-01 color-txt-05">장르</span>
                                            <span className="body-01 color-txt-05">3화무료</span>
                                            <span className="body-01 color-txt-05">81.4만</span>
                                            </div>
                                            <div className="list_tit_con">
                                            <span className="ic_up radius-xs icon_badge"><img src="../images/up_badge.svg" /></span>
                                            <span className="title color-txt-normal title-book-03-m text_overflow">책제목입니다.</span>
                                            </div>
                                            <div className="keyword color-txt-04 btn-02 text_overflow">
                                            {/* <!-- span 태그 무조건 한줄에서 쓰기 --> */}
                                            <span>#키워드</span><span>#키워드</span><span>#키워드입니다~</span><span>#키워드</span><span>#키워드 들어가는자리</span><span>#키워드입니다~</span>
                                            </div>
                                        </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    </div>
                </section>
            </div>

            <Footer style={{display:"block"}} />
            <TopBtn />
        </>
    );
}




export default ContentsWriter;