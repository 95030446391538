import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import NewlineToBr from '../content/NewlineToBr';

function useConfirmModal(data){

    const title = data?.title || false;
    const content = data?.content || false;
    const btnTitle = data?.btnTitle || "확인";
    const btnCancleTitle = data?.btnCancleTitle || "취소";
    const action = data?.action || (()=>{});

    const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const handleSelect = () => {
        // if(!noneActive) setSelectKey(key);
        // if(selectFn) selectFn(key);
        action();
        handleClose();
    }


    const ModalTmpl =()=>{ 
        return (
        <>
            <Modal 
                // className="black_opacity-60" 
                backdropClassName="black_opacity-60 opacity-10"
                dialogClassName="modal-dialog-centered"
                contentClassName="radius-xxl color-bg-modal"
                show={showModal}
                animation={false} 
                onHide={handleClose}
                >
                <Modal.Body>
                    {title ? <div className="title title-02-b color-txt-normal">{title}</div> : null}
                    {content ? <div className="desc body-03-long"><NewlineToBr text={content} /></div> : null}
                    {/* <div className="select_box">
                    {optionElements}
                    </div> */}
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="radius-sm btn-04 txt btn_01 large label_on filled button" onClick={handleSelect}>{btnTitle}</button>
                    <button type="button" className="radius-xs btn-03 txt btn_03 medium label_on filled button" onClick={handleClose}>{btnCancleTitle}</button> 
                </Modal.Footer>
            </Modal>
        </>
    )};
    
    return [ModalTmpl, handleShow];
}


export default useConfirmModal;