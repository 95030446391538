import { GetState } from './State';

import imgBadgeGift from 'assets/images/badges/badge_01_gift.svg';
import imgBadgeMidnight from 'assets/images/badges/badge_01_midnight.svg';
import imgBadgePlusFree from 'assets/images/badges/badge_01_plus_free.svg';
import imgBadgeRelay from 'assets/images/badges/badge_01_relay.svg';
import imgBadgeNew from 'assets/images/badges/badge_02_new.svg';
import imgBadgeUp from 'assets/images/badges/badge_02_up.svg';
import imgBadgeDis10 from 'assets/images/badges/badge_03_deal_10.svg';
import imgBadgeDis15 from 'assets/images/badges/badge_03_deal_15.svg';
import imgBadgeDis20 from 'assets/images/badges/badge_03_deal_20.svg';
import imgBadgeDis25 from 'assets/images/badges/badge_03_deal_25.svg';
import imgBadgeDis30 from 'assets/images/badges/badge_03_deal_30.svg';
import imgBadgeDis35 from 'assets/images/badges/badge_03_deal_35.svg';
import imgBadgeDis40 from 'assets/images/badges/badge_03_deal_40.svg';
import imgBadgeDis45 from 'assets/images/badges/badge_03_deal_45.svg';
import imgBadgeDis50 from 'assets/images/badges/badge_03_deal_50.svg';
import imgBadgeDis55 from 'assets/images/badges/badge_03_deal_55.svg';
import imgBadgeBlind from 'assets/images/badges/badge_blind.svg';
import imgBadgeStop from 'assets/images/badges/badge_stop.svg';


import imgDefaultMainBannerLight from 'assets/images/default/main_banner_light.png';
import imgDefaultMainBannerDark from 'assets/images/default/main_banner_dark.png';
import imgDefaultMainBannerBg from 'assets/images/default/main_banner_bg.png';
import imgDefaultQuickMenu from 'assets/images/default/quick_menu.png';

import imgDefaultCoverLogo from 'assets/images/default/book_cover_logo.png';
import imgDefaultCoverLock from 'assets/images/default/book_cover_lock.png';
import imgDefaultCoverLongLogo from 'assets/images/default/bookpal_only_logo.png';
import imgDefaultCoverLongLock from 'assets/images/default/bookpal_only_lock.png';

import imgDefaultHoriCoverLogo from 'assets/images/default/horizontal_section.png';
import imgDefaultHoriCoverLock from 'assets/images/default/horizontal_section_lock.png';

import imgDefaultKeyword from 'assets/images/default/keyword_section.png';
import imgDefaultGenreBanner from 'assets/images/default/genre_banner.png';
import imgDefaultGenreBannerBg from 'assets/images/default/genre_banner_bg.png';

import imgDefaultSmallBanner from 'assets/images/default/small_banner.png';

import imgDefaultGiftCoverNovel from 'assets/images/gift/gift_cover_novel.png';
import imgDefaultGiftCoverToon from 'assets/images/gift/gift_cover_toon.png';


export const ImgBadge = (gb) =>{
    
    switch(gb + ""){
        case "gift": return imgBadgeGift;
        case "midnight": return imgBadgeMidnight;
        case "plusone": return imgBadgeRelay;
        case "chapterfree": return imgBadgePlusFree;
        case "plusfree": return imgBadgePlusFree;

        case "new": return imgBadgeNew;
        case "up": return imgBadgeUp;

        case "10": return imgBadgeDis10;
        case "15": return imgBadgeDis15;
        case "20": return imgBadgeDis20;
        case "25": return imgBadgeDis25;
        case "30": return imgBadgeDis30;
        case "35": return imgBadgeDis35;
        case "40": return imgBadgeDis40;
        case "45": return imgBadgeDis45;
        case "50": return imgBadgeDis50;
        case "55": return imgBadgeDis55;

        case "blind": return imgBadgeBlind;
        case "stop": return imgBadgeStop;
        default: return null;
    }
}




export const ImgDefault = (gb) =>{
    
    switch(gb + ""){
        case "main_light": return imgDefaultMainBannerLight;
        case "main_dark": return imgDefaultMainBannerDark;
        case "main_bg": return imgDefaultMainBannerBg;
        case "quick_menu": return imgDefaultQuickMenu;
        case "cover_logo": return imgDefaultCoverLogo;
        case "cover_lock": return imgDefaultCoverLock;
        case "cover_long_logo": return imgDefaultCoverLongLogo;
        case "cover_long_lock": return imgDefaultCoverLongLock;
        case "hori_cover_logo": return imgDefaultHoriCoverLogo;
        case "hori_cover_lock": return imgDefaultHoriCoverLock;
        case "keyword": return imgDefaultKeyword;
        case "genre_banner": return imgDefaultGenreBanner;
        case "genre_banner_bg": return imgDefaultGenreBannerBg;
        case "small_banner": return imgDefaultSmallBanner;
        case "gift_novel": return imgDefaultGiftCoverNovel;
        case "gift_toon": return imgDefaultGiftCoverToon;
        
        
        default: return null;
    }
}


export const ImgCover = (memberType, adult, defaultCover, cover)=>{

    // const getState = GetState();
    
    if(adult){
        if(memberType === "Y"){
            // if(getState.useGetAdultSwitch() === false){
            //     return defaultCover;
            // }
            return cover;
        } else {
            return defaultCover;
        }
    }
    return cover;
}