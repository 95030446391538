import { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import style from './VerticalLongSection.module.scss';
import { Link } from 'react-router-dom';
import BookpalLink from '../custom/BookpalLink';
import { useWidth } from 'utils/Common';
import { ImgBadge, ImgDefault, ImgCover } from 'utils/Images';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BookpalLazyLoadImage from '../custom/BookpalLazyLoadImage';
import { useMemberInfo } from 'utils/State';
import useAlertModal from '../modal/useAlertModal';

function getDefaultImgStyle(gb){
    const style = {};
    switch(gb){
        case "pc": 
            style['minWidth'] = "180px";
            style['minHeight'] = "315px";
            break;
        case "mobile": 
            style['minWidth'] = "148px";
            style['minHeight'] = "259px";
            break;
        // case ""
    }
    return style;
}


function VerticalLongSection({title, moreLink, data}){
    const swiperRef = useRef(null);
    const memberInfo = useMemberInfo();

    const width = useWidth();

    const [defaultImageStyle, setDefaultImageStyle] = useState({});
    const [AlertModal, alert] = useAlertModal();

    // const [useNavigateBtn, setUseNavigateBtn] = useState((props?.useNavigateBtn === false ? false : true));
    const [prevBtn, setPrevBtn] = useState(false);
    const [nextBtn, setNextBtn] = useState(false);

    useEffect(()=>{
        if(width > 540){
            setDefaultImageStyle(getDefaultImgStyle("pc"));
        } else {
            setDefaultImageStyle(getDefaultImgStyle("mobile"));
        }
    },[width]);

    useEffect(()=>{

        const swiper = swiperRef.current.swiper;

        const updateNavButtons = () => {
            if (swiper) {
                const isBeginning = swiper.isBeginning;
                const isEnd = swiper.isEnd;
                // setShowNavButtons(!(isBeginning && isEnd));
                // setUseNavigateBtn(!(isBeginning && isEnd))

                setPrevBtn(!isBeginning);
                setNextBtn(!isEnd);
                // console.log("isEnd",isEnd);
            }
        };

        if(swiper){
            swiper.on('slideChange', updateNavButtons);
            updateNavButtons();
        }

        return () => {
            if(swiper){
                swiper.off('slideChange', updateNavButtons);
            }
        };

    },[])

    if(!data || !Array.isArray(data)){
        return <></>
    }

    return (
        <>
        <section className="section temp_03 template wrapper template_row">
            <div className="tit_con clearfix">
                <h2 className="title left color-txt-normal title-02-b text_overflow">{title}</h2>
                {moreLink ? 
                <div className="right">
                    <BookpalLink to={moreLink} className='more_btn'>
                        <span className="color-btn-03-txt btn-03">더보기</span>
                    </BookpalLink>
                </div>
                :null}
            </div>
            <div className="list">
                <Swiper className="swiper mySwiper" 
                    ref={swiperRef}
                    slidesPerView={'auto'}
                    spaceBetween={4}
                    watchOverflow={true}
                    observer={true}
                    observeParents={true}
                    
                    breakpoints={{
                        769: {
                            freeMode : true,
                        },
                    }}
                >

                    {
                        data.map((item, index)=>{

                            return (
                                <SwiperSlide key={index}>
                                    <BookpalLink 
                                        to={"/content/"+item?.ct_type+"/"+item?.bid}
                                        check={{
                                            view_type:memberInfo?.view_type,
                                            work_adult:item?.adult,
                                        }}
                                        alert={alert}
                                        >
                                    {/* <a href="#"> */}
                                        <div className="cover_img radius-xs">
                                            {item?.adult ? <p className="ic_adult badge_adult_normal"><img src="./images/ic_adult.svg" /></p> : null}
                                            {/* <img src={item?.image} /> */}
                                            {/* <BookpalLazyLoadImage 
                                                placeholderSrc={ImgDefault("cover_long_logo")}
                                                // effect="blur"
                                                src={ImgCover(memberInfo?.view_type, item?.adult, ImgDefault("cover_long_lock"), item?.image)}
                                                style={defaultImageStyle}
                                                onError={(e)=>{
                                                    e.currentTarget.src = ImgDefault("cover_long_logo");
                                                }}
                                            /> */}
                                            <BookpalLazyLoadImage 
                                                adult={item?.adult}
                                                adultswitch={memberInfo?.audit_switch}
                                                defaulttype={"cover_long_logo"}
                                                locktype={"cover_long_lock"}
                                                src={item?.image}
                                                style={defaultImageStyle}
                                            />
                                        </div>
                                    {/* </a> */}
                                    </BookpalLink>
                                </SwiperSlide>
                            )
                        })
                    }
                    
                </Swiper>
                <div className={"arrow_btns " + style['arrow_btns']}>
                    {nextBtn ? 
                    <div 
                        className="swiper-button-next button btn_03 color-bg-01 box-shadow-xs color-border-01"
                        onClick={() => swiperRef.current.swiper.slideNext()}
                    ><img src="./images/chevron_right.svg" /></div>
                    :null}
                    {prevBtn ? 
                    <div 
                        className="swiper-button-prev button btn_03 color-bg-01 box-shadow-xs color-border-01"
                        onClick={() => swiperRef.current.swiper.slidePrev()}
                    ><img src="./images/chevron_left.svg" /></div>
                    :null}
                </div>
            </div>
        </section>
        <AlertModal />
        </>
    )
}



export default VerticalLongSection;