import { useApiMutation } from './common/ApiCommon';

export const ApiMypocketDeviceList = (options) => {

    const subUrl = "/mypocket/device_list";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};

export const ApiMypocketDeviceDel = (options) => {

    const subUrl = "/mypocket/device_del";
    const successFn = options?.onSuccess || (()=>{});
    const errorFn = options?.onError || (()=>{});

    const mutation = useApiMutation(subUrl, successFn, errorFn);
    return mutation;
};