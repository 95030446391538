import { useEffect, useState } from 'react';
import { ApiCommonNotice } from 'api/Common';

import style from '../style/Contents.module.scss';
import NewlineToBr from 'component/common/content/NewlineToBr';


function News({info}){

    const _info = info || false;
    const [selectData, setSelectData] = useState(null);
    const [data, dataSubmit, initData, paging, setData] = ApiCommonNotice({
        data:{
            bid:_info?.bid,
        }
    })

    const handlerSelect = (e) => {
        const index = e.currentTarget.getAttribute("data-index");
        setSelectData(selectData == index ? null : index);
    }
    
    useEffect(()=>{
        dataSubmit();
    },[])

    if(data === null){
        return <>
        <div style={{height:"300px"}}></div>
        </>;
    }

    return (
        <>
            <ul className={"toggle_list " + style['toggle_list']}>
                {data && Array.isArray(data) && data?.length > 0 ?
                <>
                {data.map((item, index)=>{

                    return (
                        <li key={index} data-index={index} className="border-line border-soild radius-sm color-border-01">
                            <button 
                                type="button" 
                                className={"button large color-bg-01 label_on " + style['button'] + " " + (selectData == index ? "click":"")}
                                data-index={index}
                                onClick={handlerSelect}
                                >
                                {item?.step === "소식" ? <p className={"badge radius-full title-tiny-01 color-state-point-02 bg " + style['badge'] +  " " + style['bg']}><span className={"white-always txt " + style['txt']}>소식</span></p>:null}
                                {item?.step === "공지" ? <p className={"badge radius-full title-tiny-01 color-btn-01 bg " + style['badge'] +  " " + style['bg']}><span className={"white-always txt " + style['txt']}>공지</span></p>:null}
                                <div className={"text " + style['text']} data-index={index}>
                                    <p data-index={index} className="title color-txt-02 title-01-m-long">{item?.title}</p>
                                    <p data-index={index} className={"date color-txt-05 body-01 " + style['date']}>{item?.post_date}</p>
                                </div>
                                {/* <span data-index={index} className="color-txt-02 title-01-m-long">{item?.title}</span> */}
                            </button>
                            <div 
                                className="txt_box color-txt-03 body-02-long border-soild border-top-line color-bg-02 color-border-01"
                                style={{display:(selectData==index ? "block":"none")}}
                                >
                                <div className="desc body-02-long color-txt-03">
                                    {/* <NewlineToBr text={item?.content?.split("<br />").join("").split("<br/>").join("").split("<br>").join("") ?? ""} /> */}
                                    {/* <div dangerouslySetInnerHTML={{ __html: item?.contents }}>
                                    </div> */}
                                    <NewlineToBr text={item?.contents} />
                                </div>
                            </div>
                        </li> 
                    );
                })}
                
                </>
                :<p className="no_data sm color-txt-04 body-02-long">아직 등록된 소식이 없습니다.</p>}
{/*                 
                <li className="color-bg-01 border-line border-soild radius-sm color-border-01">
                    <button type="button" className={"button large color-bg-01 label_on click " + style['button'] + " " + style['label_on']}>
                        <p className={"badge radius-full title-tiny-01 color-btn-01 bg " + style['badge'] +  " " + style['bg']}><span className={"white-always txt " + style['txt']}>공지</span></p>
                        <div className={"text " + style['text']}>
                            <p className="color-txt-02 title-01-m-long">해외 거주자 본인 인증 방법</p>
                            <p className={"date body-01 color-txt-05 " + style['date']}>24.05.13</p>
                        </div>
                    </button>
                    <div className="txt_box color-txt-03 body-02-long border-soild border-top-line color-bg-02 color-border-01">
                        본인 인증이 가능한 생년월일이 포함된 신분증을 북팔 아이디와 함께 메일(bookpal@bookpal.co.kr)로 
                        보내주시면 인증 및 보상 지급 처리해드리도록 하겠습니다.<br /><br />※인증 완료 후 해당 정보는 즉시 파기됩니다.
                    </div>
                </li> */}
            </ul>
            {/* <!-- 리스트가 없을때 -->
            <!-- <p className="no_data sm color-txt-04 body-02-long">아직 소식이 등록되지 않았어요.</p> --> */}
        </>
    )
}



export default News;